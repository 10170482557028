// import { createSlice } from "@reduxjs/toolkit"
// import { GET_DYNAMIC_ROUTES } from "../Queries/options"
// import { client } from ".."
// const initialState={
//     reupdate:false,
//     empRoute:[]
// }

// const Route=createSlice({
//     initialState,
//     name:"Route",
//     reducers:{
//         setreupdate(state ,action){
//             console.log("ascaczcz",action.payload)
//             state.reupdate=action.payload;
//             console.log("ascaczczdfd",state.reupdate)
//         },
//         setempRoute(state,action){
//             state.empRoute=action.payload;
//         }
//     }
// })
// export const {setreupdate,setempRoute}=Route.actions
// export default Route.reducer

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_DYNAMIC_ROUTES } from "../Queries/options";
import { client } from "..";

// Initial state
const initialState = {
  reupdate: false,
  empRoute: [],
  loading: false,
  error: null,
};

// Async thunk to fetch dynamic routes using Apollo Client
export const fetchDynamicRoutes = createAsyncThunk(
  "Route/fetchDynamicRoutes", // Action name
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await client.query({
        query: GET_DYNAMIC_ROUTES,
      });

      // Return the dynamic routes data to be stored in the state
      return data.getSideMenu; 
    } catch (error) {
      // In case of error, reject with value and return error message
      return rejectWithValue(error.message);
    }
  }
);

const Route = createSlice({
  initialState,
  name: "Route",
  reducers: {
    setreupdate(state, action) {
      state.reupdate = action.payload;
    },
    setempRoute(state, action) {
      state.empRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDynamicRoutes.pending, (state) => {
        // Set loading state to true while the request is in progress
        state.loading = true;
        state.error = null; // Clear any previous errors
      })
      .addCase(fetchDynamicRoutes.fulfilled, (state, action) => {
        // Update empRoute with fetched data
        state.empRoute = action.payload;
        state.loading = false; // Set loading state to false after success
      })
      .addCase(fetchDynamicRoutes.rejected, (state, action) => {
        // Handle error case
        state.loading = false; // Set loading state to false after failure
        state.error = action.payload; // Store the error message
      });
  },
});

export const { setreupdate, setempRoute } = Route.actions;

export default Route.reducer;
