// ** Graphql
import { gql } from '@apollo/client';

export const GET_ALL_ORDERS = gql`
  query GetAllOrders($page: Int, $pageSize: Int,$filters: filtersInputData,$fromDate:String,$toDate:String,$isAllOrders:Boolean) {
    getAllOrders(page: $page, pageSize:$pageSize, filters: $filters,fromDate:$fromDate,toDate:$toDate,isAllOrders:$isAllOrders) {
      status
      message
      totalRecords
      
      response{
        id
      from
      to
      subject
      TotalTime
      body
      html
      date
      emailNeeded
      createdBy
      emailCompleted
      hold
      taskComplete
      fromPending
      instructions
      requestStatus
      requestId
      quoteType
      department
      insuredName
      noofQuotes
      holderName
      insuredDelivery
      holderDelivery
      accountManagerDelivery
      otherDelivery
      radioValue
      priority
      processType
      assignedTo
      assignTo
      startDate
      dueDate
      completedDate
      requestStatus
      Assign
      radioInfo
      Auto
      MotorCycle
      Boat
      DP3
      HO3
      HO4
      HO5
      HO6
      comments
      emailSummary
      requestor
     
      sendTo
      client
      clientName
      newQuoteInformation {
        policy
        insuranceCarrier
        eligibility
        quotedPremium
        remarks
      }
      existingPolicyInformation{
        currentCarrier 
        propertyType
        propertyNew
        policyNumber
        effectiveDate
        currentPremium
      }
    }
      summary {
        inbox {
          getInboxOnDemand
          getAvailableOnDemand
          getInprogressOnDemand
          getOnholdOnDemand
          getInboxRush
          getAvailableRush
          getInprogressRush
          getOnholdRush
          getInboxBulk
          getAvailableBulk
          getInprogressBulk
          getOnholdBulk

          getInboxOnDemandWeekly
          getAvailableOnDemandWeekly
          getInprogressOnDemandWeekly
          getOnholdOnDemandWeekly
          getInboxRushWeekly
          getAvailableRushWeekly
          getInprogressRushWeekly
          getOnholdRushWeekly
          getInboxBulkWeekly
          getAvailableBulkWeekly
          getInprogressBulkWeekly
          getOnholdBulkWeekly

          getInboxOnDemandMonthly
          getAvailableOnDemandMonthly
          getInprogressOnDemandMonthly
          getOnholdOnDemandMonthly
          getInboxRushMonthly
          getAvailableRushMonthly
          getInprogressRushMonthly
          getOnholdRushMonthly
          getInboxBulkMonthly
          getAvailableBulkMonthly
          getInprogressBulkMonthly
          getOnholdBulkMonthly
        }
        emailNeeded {
          getEmailneeded
          getEmailneededOnDemand
          getEmailneededRush
          getEmailneededBulk

          getEmailneededOnDemandWeekly
          getEmailneededRushWeekly
          getEmailneededBulkWeekly

          getEmailneededOnDemandMonthly
          getEmailneededRushMonthly
          getEmailneededBulkMonthly
        }
        pending {
          getPendings
          getPendingsOnDemand
          getPendingsRush
          getPendingsBulk

          getPendingsOnDemandWeekly
          getPendingsRushWeekly
          getPendingsBulkWeekly

          getPendingsOnDemandMonthly
          getPendingsRushMonthly
          getPendingsBulkMonthly
        }
        discard{
          getDiscardOnDemandMonthly
          getDiscardRushMonthly
          getDiscardBulkMonthly
        }
        completed {
          getCompletedOnDemand
          getCompletedRush
          getCompletedBulk
          getCompletedTodayOnDemand
          getCompletedThisMonthOnDemand
          getCompletedThisYearOnDemand
          getCompletedTodayRush
          getCompletedThisMonthRush
          getCompletedThisYearRush
          getCompletedTodayBulk
          getCompletedThisMonthBulk
          getCompletedThisYearBulk
        }
      }
      
    }
  }
`;





export const CREATE_REQUEST = gql`
  mutation (
    $from:String
    $to:String
    $subject:String
    $body:String
    $html:String
    $date:String
    $emailNeeded: Boolean,
    $emailCompleted: Boolean,
    $hold: Boolean,
    $taskComplete: Boolean,
    $fromPending:Boolean,
    $instructions:Boolean,
    $quoteType: String
    $department:String
    $insuredName:String
    $noofQuotes:Int
    $holderName:String
    $insuredDelivery:String
    $holderDelivery:String
    $accountManagerDelivery:String
    $otherDelivery:String
    $radioValue:String
    $priority:String
    $processType:String
    $assignedTo:String
    $assignTo:String
    $startDate:String
    $dueDate:String
    $completedDate:String
    $requestStatus:String
    $Assign:Boolean
    $radioInfo:String
    $Auto: Boolean,
    $MotorCycle: Boolean,
    $Boat: Boolean,
    $DP3: Boolean,
    $HO3: Boolean,
    $HO4: Boolean,
    $HO5: Boolean,
    $HO6: Boolean,
    $comments:String
    $emailSummary:String
    $requestor:String
    $sendTo:String
    $newQuoteInformation:[NewQuoteInformation]
    $existingPolicyInformation:[ExistingPolicyInformation]
    $client: String
    $clientName: String
    $createdBy: String
    ) {
      createRequest(
      from:$from
      to:$to
      subject:$subject
      body:$body
      html:$html
      date:$date
      emailNeeded: $emailNeeded,
      emailCompleted: $emailCompleted,
      hold: $hold,
      taskComplete: $taskComplete,
      fromPending:$fromPending,
      instructions: $instructions,
      quoteType: $quoteType
      department: $department
      insuredName: $insuredName
      noofQuotes: $noofQuotes
      
      holderName:$holderName
      insuredDelivery:$insuredDelivery
      holderDelivery:$holderDelivery
      accountManagerDelivery:$accountManagerDelivery
      otherDelivery:$otherDelivery
      radioValue:$radioValue
      priority:$priority
      processType:$processType
      assignedTo:$assignedTo
      assignTo:$assignTo
      startDate:$startDate
      dueDate:$dueDate
      completedDate:$completedDate
      requestStatus:$requestStatus
      Assign:$Assign
      radioInfo:$radioInfo
      Auto:$Auto
      MotorCycle:$MotorCycle
      Boat:$Boat
      DP3:$DP3
      HO3:$HO3
      HO4:$HO4
      HO5:$HO5
      HO6:$HO6
      comments:$comments
      emailSummary:$emailSummary
      requestor:$requestor
      sendTo:$sendTo
      newQuoteInformation:$newQuoteInformation
      existingPolicyInformation:$existingPolicyInformation
      client: $client
      clientName: $clientName
      createdBy: $createdBy
      ) {
        id
        requestId
        from
        to
        subject
        body
        html
        date
        dueDate
        emailNeeded
        emailCompleted
        hold
        taskComplete
        fromPending
        instructions
        requestStatus
        createdBy
        quoteType
        department
        insuredName
        noofQuotes
        holderName
        insuredDelivery
        holderDelivery
        accountManagerDelivery
        otherDelivery
        radioValue
        priority
        processType
        assignedTo
        assignTo
        startDate
        dueDate
        completedDate
        requestStatus
        Assign
        radioInfo
        Auto
        MotorCycle
        Boat
        DP3
        HO3
        HO4
        HO5
        HO6
        comments
        emailSummary
        requestor
        sendTo
        newQuoteInformation {
          policy
          insuranceCarrier
          eligibility
          quotedPremium
          remarks
        }
        existingPolicyInformation{
          currentCarrier 
          propertyType
          propertyNew
          policyNumber
          effectiveDate
          currentPremium
        }
        client
        clientName
    }
  }
`;

export const UPDATE_REQUEST = gql`
  mutation (
    $id: String!, 
    $emailNeeded: Boolean,
    $emailCompleted: Boolean,
    $hold: Boolean,
    $subject:String,
    $taskComplete: Boolean,
    $fromPending:Boolean,
    $instructions:Boolean,
    

    $quoteType: String
    $department:String
    $insuredName:String
    $noofQuotes:Int
    $holderName:String
    $insuredDelivery:String
    $holderDelivery:String
    $accountManagerDelivery:String
    $otherDelivery:String
    $radioValue:String
    $priority:String
    $processType:String
    $assignedTo:String
    $assignTo:String
    $startDate:String
    $dueDate:String
    $completedDate:String
    $requestStatus:String
    $Assign:Boolean
    $radioInfo:String
    $Auto: Boolean,
    $MotorCycle: Boolean,
    $Boat: Boolean,
    $DP3: Boolean,
    $HO3: Boolean,
    $HO4: Boolean,
    $HO5: Boolean,
    $HO6: Boolean,
    $comments:String
    $emailSummary:String
    $requestor:String
    $sendTo:String
    $clientName: String
    $newQuoteInformation:[NewQuoteInformation]
    $existingPolicyInformation:[ExistingPolicyInformation]
    
    ) {
    updateRequest(
      id: $id, 
      emailNeeded: $emailNeeded,
      emailCompleted: $emailCompleted,
      hold: $hold,
      taskComplete: $taskComplete,
      fromPending:$fromPending,
      instructions: $instructions,
      subject:$subject,
      quoteType: $quoteType
      department: $department
      insuredName: $insuredName
      noofQuotes: $noofQuotes
      holderName:$holderName
      insuredDelivery:$insuredDelivery
      holderDelivery:$holderDelivery
      accountManagerDelivery:$accountManagerDelivery
      otherDelivery:$otherDelivery
      radioValue:$radioValue
      priority:$priority
      processType:$processType
      assignedTo:$assignedTo
      assignTo:$assignTo
      startDate:$startDate
      dueDate:$dueDate
      completedDate:$completedDate
      requestStatus:$requestStatus
      Assign:$Assign
      radioInfo:$radioInfo
      Auto:$Auto
      MotorCycle:$MotorCycle
      Boat:$Boat
      DP3:$DP3
      HO3:$HO3
      HO4:$HO4
      HO5:$HO5
      HO6:$HO6
      comments:$comments
      emailSummary:$emailSummary
      requestor:$requestor
      sendTo:$sendTo
      clientName: $clientName
      newQuoteInformation:$newQuoteInformation
      existingPolicyInformation:$existingPolicyInformation
      ) {
        id
        from
        to
        subject
        body
        html
        date
        dueDate
        emailNeeded
        emailCompleted
        hold
        taskComplete
        fromPending
        instructions
        requestStatus
        quoteType
        department
        insuredName
        noofQuotes
        holderName
        insuredDelivery
        holderDelivery
        accountManagerDelivery
        otherDelivery
        radioValue
        priority
        processType
        assignedTo
        assignTo
        startDate
        dueDate
        completedDate
        requestStatus
        Assign
        radioInfo
        Auto
        MotorCycle
        Boat
        DP3
        HO3
        HO4
        HO5
        HO6
        comments
        emailSummary
        requestor
        sendTo
        clientName
        newQuoteInformation {
          policy
          insuranceCarrier
          eligibility
          quotedPremium
          remarks
        }
        existingPolicyInformation{
          currentCarrier 
          propertyType
          propertyNew
          policyNumber
          effectiveDate
          currentPremium
        }
        
    }
  }
`;

export const CREATE_EMPLOYEE = gql`
  mutation (
    $id: String,
    $gender: String,
    $role: String,
    $employeestatus: String,
    $firstname: String,
    $lastname: String,
    $email: String,
    $mobile: String,
    $address: String,
    $token: String,
    $password: String,
    $isPasswordGenerated: Boolean,
    $department: String,
    $clients: String
    ) {
    createemployee(
      id: $id, 
      gender: $gender, 
      role: $role, 
      employeestatus: $employeestatus,
      firstname: $firstname,
      lastname: $lastname,
      email: $email,
      mobile: $mobile,
      address: $address,
      token: $token,
      password: $password,
      isPasswordGenerated: $isPasswordGenerated,
      department: $department,
      clients: $clients
      ) {
        status
        message
        token
    }
  }
`;


export const GET_REQUESTS_BY_ID = gql`
  mutation ($id: String!) {
    getRequestById(id: $id) {
        id
        from
        to
        subject
        body
        html
        date
        dueDate
        emailNeeded
        emailCompleted
        hold
        createdBy
        taskComplete
        fromPending
        instructions
        requestStatus
        requestId
        quoteType
        department
        insuredName
        noofQuotes
        holderName
        insuredDelivery
        holderDelivery
        accountManagerDelivery
        otherDelivery
        radioValue
        priority
        processType
        assignedTo
        assignTo
        startDate
        dueDate
        completedDate
        requestStatus
        Assign
        radioInfo
        Auto
        MotorCycle
        Boat
        DP3
        HO3
        HO4
        HO5
        HO6
        comments
        emailSummary
        requestor
        sendTo
        client
        clientName
        newQuoteInformation {
          policy
          insuranceCarrier
          eligibility
          quotedPremium
          remarks
        }
        existingPolicyInformation{
          currentCarrier 
          propertyType
          propertyNew
          policyNumber
          effectiveDate
          currentPremium
        }
    }
  }
`;





export const INSERT_ORDERS = gql`
  mutation CreateRequests(
    $email: String
    $subject:String
    $body:String) {
      createRequests(
        email: $email
        subject: $subject
        body: $body) {
          email
          subject
          body
        }
      }
    `;



export const CREATE_LOGINWORKHISTORY = gql`
    mutation CreateLoginWorkHistory(
      $userId: String!
      $title: String
      $description: String
      $companyname: String
      $department: String
      $task: String
      $startTime: String!
      $date: String!
      $endTime: String!
    ) {
        createLoginWorkHistory(
          userId: $userId
          title: $title
          description: $description
          companyname: $companyname
          department: $department
          task: $task
          date: $date
          startTime: $startTime
          endTime: $endTime
        ) {
            id
            userId
            title
            description
            companyname
            department
            task
            startTime
            endTime
            date
        }
    }
`;

export const GET_LOGINWORKHISTORY_BY_ID = gql`
  mutation ($userId: String!) {
    getLoginWorkHistoryID(userId: $userId) {
      id
      userId
      title
      description
      startTime
      endTime
      companyname
      department
      task
    }
  }
`;

export const UPDATE_LOGINWORKHISTORY = gql`
mutation (
  $id: String!
  $endTime: String!) {
    updateLoginWorkHistory(
    id: $id
    endTime: $endTime) {
      id
      endTime
    }
  }
`;

export const CREATE_WORKHISTORY = gql`
  mutation (
    $userId: String!,
    $emailId: String,
    $requestId: String,
    $clientName: String,
    $processType: String,
    $taskType: String,
    $email: String,
    $subject:String,
    $body:String,
    $startTime: String!,
    $endTime: String!) {
      createWorkHistory(
        userId: $userId
        emailId: $emailId
        requestId: $requestId,
        clientName: $clientName,
        processType: $processType,
        taskType: $taskType,
        email: $email
        subject: $subject
        body: $body
        startTime: $startTime
        endTime: $endTime) {
          id
          userId
          emailId
          requestId
          clientName
          processType
          taskType
          email
          subject
          body
          startTime
          endTime
        }
      }
    `;


export const UPDATE_WORKHISTORY = gql`
    mutation (
    $userId: String!,
    $emailId: String,
    $requestId: String,
    $clientName: String,
    $processType: String,
    $taskType: String,
    $email: String,
    $subject:String,
    $body:String,
    $startTime: String,
    $endTime: String!,) {
        updateWorkHistory(
          userId: $userId
        emailId: $emailId
        requestId: $requestId,
        clientName: $clientName,
        processType: $processType,
        taskType: $taskType,
        email: $email
        subject: $subject
        body: $body
        startTime: $startTime
        endTime: $endTime) {
          id
          userId
          emailId
          requestId
          clientName
          processType
          taskType
          email
          subject
          body
          startTime
          endTime
        }
      }
    `;

export const GET_WORKHISTORY_BY_USER_ID = gql`
  mutation ($userId: String!) {
    getWorkHistoryID(userId: $userId) {
      id
      userId
      emailId
      email
      subject
      body
      startTime
      endTime
      taskType
    }
  }
`;

export const GET_WORKHISTORY = gql`
  query {
    getAllWorkHistory {
          id
          userId
          emailId
          email
          subject
          body
          startTime
          endTime
     
    }
  }
`;

export const GET_WORKHISTORY_EQUALS_TIME = gql`
  query {
    getAllWorkHistoryEqualsTime {
          id
          userId
          emailId
          email
          subject
          body
          startTime
          endTime
     
    }
  }
`;


export const GET_WORKHISTORY_BY_ID = gql`
  mutation ($userId: String!) {
    getWorkHistoryID(userId: $userId) {
          id
          userId
          emailId
          email
          subject
          body
          startTime
          endTime
    }
  }
`;
export const GET_ALLWORKHISTORY_BY_ID = gql`
  mutation ($emailId: String!) {
    getAllWorkHistoryByID(emailId: $emailId) {
          id
          userId
          emailId
          email
          subject
          body
          startTime
          endTime
          taskType
    }
  }
`;


export const CREATE_STAUS_WORKHISTORY = gql`
  mutation (
    $userId: String!
    $emailId: String!
    $oldStatus: String!
    $newStatus:String!
    $changeTime:String!
    ) {
      createStatusWorkHistory(
        userId: $userId
        emailId: $emailId
        oldStatus: $oldStatus
        newStatus: $newStatus
        changeTime: $changeTime) {
          id
          userId
          emailId
          oldStatus
          newStatus
          changeTime
        }
      }
    `;


export const GET_ALLSTATUSWORKHISTORY_BY_ID = gql`
    mutation ($emailId: String!) {
      getAllStatusWorkHistoryByID(emailId: $emailId) {
        id
        userId
        emailId
        oldStatus
        newStatus
        changeTime
      }
    }
  `;

export const CREATE_ATTACHEMNTS = gql`
  mutation (
    $userId: String!
    $emailId: String!

    $data: String!
    $filename: String!
    $mimetype: String!
    $addDate: String!
    $updateDate: String!
    $status: Boolean!) {
      createAttachments(
        userId: $userId
        emailId: $emailId
        data: $data
        filename: $filename
        mimetype: $mimetype
        addDate: $addDate
        updateDate: $updateDate
        status: $status) {
          id
          userId
          emailId
          data
          filename
          mimetype
          addDate
          updateDate
          status
        }
      }
    `;

export const GET_ALLATTACHMENTS_BY_ID = gql`
    mutation ($emailId: String!) {
      getAllAttachmentsByID(emailId: $emailId) {
        id
        userId
        emailId
        data
        filename
        mimetype
        addDate
        updateDate
        status
        attachmentHistory{
        emailId
         userId
        filename
        addDate
        updateDate
        mimetype
        status
        }
      }
    }
  `;
// export const GET_ALLATTACHMENTS_BY_ID = gql`
//   mutation ($emailId: String!) {
//     getAllAttachmentsByID(emailId: $emailId) {
//       id
//       userId
//       emailId
//       data
//       filename
//       mimetype
//       addDate
//       updateDate
//       status
//     }
//   }
// `;
export const GET_ALL_MODIFIED_ATTACHMENTS_BY_ID = gql`
  mutation ($emailId: String!) {
    getAllModifiedAttachmentsByID(emailId: $emailId) {
      id
      userId
      emailId
      data
      filename
      mimetype
      addDate
      updateDate
      status
    }
  }
`;

export const GET_ALLATTACHMENT_HISTORY_BY_ID = gql`
  mutation ($userId: String!, $emailId: String!) {
    getAllAttachmentHistoryByID(userId: $userId, emailId: $emailId) {
      id
      userId
      emailId
      data
      filename
      mimetype

      addDate
      updateDate

      status
    }
  }
`;


export const UPDATE_ATTACHMENTS = gql`
mutation (
  $id: String!
  $data: String!
  $filename: String!
  $mimetype: String!
  $userId: String!
  $updateDate: String!) {
    updateAttachments(
    id: $id
    data: $data
    filename: $filename
    mimetype: $mimetype
    userId: $userId
    updateDate: $updateDate) {
      emailId
      userId
      data
      filename
      mimetype
      addDate
      updateDate
    }
  }
`;

export const DELETE_ATTACHMENTS = gql`
mutation ($id: String! $updateDate: String! $userId: String!) {
    deleteAttachments(id: $id updateDate: $updateDate userId:$userId) {
      id
      userId
      status
      filename
      mimetype
      addDate
      updateDate
    }
  }
`;


export const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      fileName
      filePath
      mimeType
    }
  }
`;
export const UPLOAD_FILE_OLD = gql`
  mutation uploadFile($file: Upload!) {
    uploadFile(file: $file) {
      url
    }
  }
`;

export const UPLOAD_FILES_MUTATION = gql`
  mutation UploadFiles($file: [Upload!]!) {
    uploadFiles(file: $file) {
      filename
      mimetype
      path
    }
  }
`;


export const STD_INPUT = gql`
mutation InsertStudents($stds: [StudentInput]!) {
  insertStudents(stds: $stds) {
    status
    message
  }
}
`;

export const CREATE_STUDENT = gql`
  mutation (
    $name: String!
    $email: String!) {
      createStudents(
        name: $name
        email: $email) {
          id
          name
          email
        }
      }
    `;

export const CREATE_CLIENT = gql`
  mutation (
      $id: String
      $clientName: String!
      $emailId: String!
      $mobileNo: String
      $timeZone: String!
      $department: String!
      $status: String!
      $userId: String!
      $extension:String!) {
      createclient(
        id: $id,
        extension:$extension
        clientName: $clientName
        emailId: $emailId,
        mobileNo: $mobileNo,
        timeZone: $timeZone,
        department: $department,
        status: $status,
        userId: $userId) {
          status
          message,
          id
        }
      }
    `;


export const GET_CLIENTDETAILS_BY_ID = gql`
    mutation ($id: String!){
     getClientsDetails(id: $id){
       id
      clientName
      emailId
      mobileNo
      department
      timeZone
      status
     }
    }
    `

export const UPDATE_EMPLOYEE = gql`
  mutation (
    $id: String,
    $gender: String,
    $role: String,
    $employeestatus: String,
    $firstname: String,
    $lastname: String,
    $email: String,
    $mobile: String,
    $address: String,
    $token: String,
    $isPasswordGenerated: Boolean,
    $department: String,
    $clients: String
    ) {
    updateemployee(
      id: $id, 
      gender: $gender, 
      role: $role, 
      employeestatus: $employeestatus,
      firstname: $firstname,
      lastname: $lastname,
      email: $email,
      mobile: $mobile,
      address: $address,
      token: $token,
      isPasswordGenerated: $isPasswordGenerated,
      department: $department,
      clients: $clients
      ) {
        status
        message
        token
    }
  }
`;


export const UPDATE_CLIENT = gql`
mutation (
    $id: String
    $clientName: String!
    $emailId: String!
    $mobileNo: String
    $timeZone: String!
    $department: String!
    $status: String!
    $userId: String) {
    updateclient(
      id: $id,
      clientName: $clientName
      emailId: $emailId,
      mobileNo: $mobileNo,
      timeZone: $timeZone,
      department: $department,
      status: $status,
      userId: $userId) {
        status
        message,
        id
      }
    }
  `;


export const CREATE_TIMERHISTORY = gql`
  mutation (
    $userId: String,
    $emailId: String,
    $requestId: String,
    $clientName: String,
    $processType: String,
    $taskType: String,
    $email: String,
    $subject:String,
    $body:String,
    $startTime: String,
    $endTime: String,
    $department: String,
    ) {
      createTimerHistory(
        userId: $userId
        emailId: $emailId
        requestId: $requestId,
        clientName: $clientName,
        processType: $processType,
        taskType: $taskType,
        email: $email
        subject: $subject
        body: $body
        startTime: $startTime
        endTime: $endTime
        department: $department) {
          id
          userId
          emailId
          requestId
          clientName
          processType
          taskType
          email
          subject
          body
          startTime
          endTime
          status
          department
        }
      }
    `;

export const GET_TIMERWORKHISTORY_BY_ID = gql`
  mutation ($userId: String!) {
    getTimerWorkHistoryID(userId: $userId) {
      id
      userId
      emailId
      requestId
      clientName
      processType
      taskType
      email
      subject
      body
      startTime
      endTime
      department
    }
  }
`;

export const CREATE_CURRENT_CARRIER = gql`
mutation (
    $id: String
    $carrierName: String!
    $status: String!
    $userId: String!
    $flag: Boolean) {
      createCurrentCarrier(
      id: $id,
      carrierName: $carrierName
      status: $status,
      userId: $userId
      flag: $flag) {
        status
        message,
       
      }
    }
  `;

//   export const GET_WORKHOURS_BY_IDS = gql`
// mutation ($emailIds: [String!]) {
//   getWorkHours(emailIds: $emailIds) {
//     id
//     hours
//   }
// }
// `;

export const UPDATE_CURRENT_CARRIER = gql`
  mutation (
    $id: String!
    $carrierName: String!
    $status: String!
    $userId: String!
    $flag: Boolean) {
      updateCurrentCarrier(
      id: $id,
      carrierName: $carrierName
      status: $status,
      userId: $userId
      flag: $flag) {
        status
        message
      }
    }
  `;

export const CREATE_INSURANCE_CARRIER = gql`
mutation (
    $id: String
    $carrierName: String!
    $status: String!
    $userId: String!) {
      createInsuranceCarrier(
      id: $id,
      carrierName: $carrierName
      status: $status,
      userId: $userId) {
        status
        message,
        id
      }
    }
  `;

export const UPDATE_INSURANCE_CARRIER = gql`
  mutation (
    $id: String!
    $carrierName: String!
    $status: String!
    $userId: String!) {
      updateInsuranceCarrier(
      id: $id,
      carrierName: $carrierName
      status: $status,
      userId: $userId) {
        status
        message,
        id
      }
    }
  `;