import React from 'react';
import { useState, useEffect, useRef } from 'react'
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MdCloudDownload, MdMoreVert } from 'react-icons/md';
import dayjs from 'dayjs';
import { Spinner } from 'react-bootstrap';
import { setLoader } from '../../../Slices/loaderSlice';
import {
  MODIFY_ATTACHMENT_DETAILS,
  REMOVE_ATTACHMENT,
  ALL_ATTACHMENTS,
  CREATE_PROFILE_ATTACHMENTA,
  INSERT_ATTACHMENT,
   GET_ALL_PROFILE_ATTACHMENTS
} from '../../../Queries/Employees';
import { useErrorLogger } from '../../../utils';
import { useQuery, useMutation } from '@apollo/client';
import axios from 'axios';
import { SERVER_URL } from '../../../databucket';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Link from 'antd/es/typography/Link';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { Loader } from 'react-feather';
export default function AttachmentsHistory() {
  const [Attachments, setAttachments] = useState([]);
  const { logError } = useErrorLogger();
  const [allHistoryRecords, setAttachmentsHistory] = useState([]);// Use props directly without renaming
  const fileInputRef = useRef(null);
  const fileInputRefSingle = useRef(null);
  // const [attachmentStatus, setAttachmentStatus] = useState([]);
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [createProfileAttachment] = useMutation(CREATE_PROFILE_ATTACHMENTA)
  const [loadings, setLoading] = useState(false);
  const [insertAttachment] = useMutation(INSERT_ATTACHMENT);
  const dispatch = useDispatch();
 
  const { Empid } = useParams();
  const token = localStorage.getItem("token");
  const fileInputRefUpdate = useRef(null);

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const id = parseJwt(token)?.id;
  const ids = Empid || id;
  const details = useSelector((state) => state.employee.AllEmployees);
  const employee = details?.find((emp) => emp?.id === id);
  const { data, loading, error, refetch } = useQuery(ALL_ATTACHMENTS, {
    variables: { ID: ids }, // Pass the ID here 
    onCompleted: (data) => {
      // Flatten all attachment histories
      const allAttachments = data?.FetchAttachments?.response?.attachemnts.flatMap((employee) =>
        Array.isArray(employee?.attachmetHistory) ? employee.attachmetHistory : []
      );
  
      console.log("Fetched initial attachments data:", data?.FetchAttachments?.response?.attachemnts);
      console.log("Flattened attachment histories:", allAttachments);
  
      // Update states
      setAttachments(data?.FetchAttachments?.response?.attachemnts || []);
      setAttachmentsHistory(allAttachments || []);
    },
  });
  
  // Use useEffect to watch for changes in data and update state accordingly
  useEffect(() => {
    if (data?.FetchAttachments?.response?.attachemnts) {
      const attachments = data.FetchAttachments.response.attachemnts;
  
      // Flatten all attachment histories
      const allAttachments = attachments.flatMap((employee) =>
        Array.isArray(employee?.attachmetHistory) ? employee.attachmetHistory : []
      );
  
      console.log("Updated attachments data after refetch:", attachments);
      console.log("Flattened attachment histories:", allAttachments);
  
      // Update states
      setAttachments(attachments);
      setAttachmentsHistory(allAttachments);
    }
  }, [data]);
  



  // Mutations


  const [removeAttachment] = useMutation(REMOVE_ATTACHMENT);


  const [modifyAttachmentDetails] = useMutation(MODIFY_ATTACHMENT_DETAILS)

  // Handle single file upload
  // Handle single file upload
  const handleSingleAttachment = async (event) => {
    dispatch(setLoader(true));
    const selectedFile = event.target.files[0];
    const formData = new FormData();
    formData.append('files', selectedFile);

    try {
      const res = await axios.post(`${SERVER_URL}/upload`, formData);
      const savedFile = res.data.files[0];

      const valuesObject = {
        userId: parseJwt(token)?.id,
        emailId: Empid ? Empid : parseJwt(token)?.id,
        data: savedFile.url,
        replacedFile: null,
        userName: `${employee?.firstname}${employee?.lastname}`,
        filename: savedFile.originalName,
        mimetype: savedFile.mimeType,
        addDate: Date.now().toString(),
        updateDate: null,
        status: true,
        attachmetHistory: [  // Add the history here
          {
            userId: parseJwt(token)?.id,
            emailId: Empid ? Empid : parseJwt(token)?.id,
            replacedFile: null,
            data: savedFile.url,
            userName: `${employee?.firstname}${employee?.lastname}`,
            filename: savedFile.originalName,
            mimetype: savedFile.mimeType,
            addDate: Date.now().toString(),
            updateDate: null,
            status: true,
            deleteDate: null,
          }
        ]
      };

      // Update backend after updating Redux
      await insertAttachment({ variables: { id: ids, data: valuesObject } });
      refetch();
     
    } catch (error) {
      console.error(error);
         await logError("AttachmentHistory", "handleSingleAttachment", error.message);
    } finally {
      dispatch(setLoader(false));
    }
  };

  const handleMultipleAttachment = async (event) => {

    const multipleFiles = Array.from(event.target.files);
    const formData = new FormData();
    multipleFiles.forEach(file => formData.append('files', file));

    try {
      dispatch(setLoader(true));
      const res = await axios.post(`${SERVER_URL}/upload`, formData);
      const uploadedFiles = res.data.files.map(uploadedItem => ({
        userId: parseJwt(token)?.id,
        emailId: Empid ? Empid : parseJwt(token)?.id,
        data: uploadedItem.url,
        replacedFile: null,
        userName: `${employee?.firstname}${employee?.lastname}`,
        filename: uploadedItem.originalName,
        mimetype: uploadedItem.mimeType,
        addDate: Date.now().toString(),
        updateDate: null,
        status: true,
        deleteDate: null,
        attachmetHistory: [  // Add the history here
          {
            userId: parseJwt(token)?.id,
            emailId: Empid ? Empid : parseJwt(token)?.id,
            replacedFile: null,
            data: uploadedItem.url,
            userName: `${employee?.firstname}${employee?.lastname}`,
            filename: uploadedItem.originalName,
            mimetype: uploadedItem.mimeType,
            addDate: Date.now().toString(),
            updateDate: null,
            status: true,
            deleteDate: null,
          }
        ]
      }));
      
      // Push the data to the mutation
      await insertAttachment({ variables: { id: ids, data: uploadedFiles } });
      
      refetch();
     
    } catch (error) {
      console.error(error);
      await logError("AttachmentHistory","handleMultipleAttachment", error.message);
    } finally {
      dispatch(setLoader(false));
    }
  };

  // Handle updating an attachment
  // Handle updating an attachment
  const handleUpdateAttachment = async (row) => {
    const selectedFile = await new Promise((resolve) => {
      const inputElement = fileInputRefUpdate.current;
      if (!inputElement) return;
      inputElement.onchange = (event) => resolve(event.target.files[0]);
      inputElement.click();
    });

    if (!selectedFile) return;

    const formData = new FormData();
    formData.append('files', selectedFile);

    try {
      dispatch(setLoader(true));
      const res = await axios.post(`${SERVER_URL}/upload`, formData);
      const savedFile = res.data.files[0];

      const valuesObject = {
        userId: parseJwt(token)?.id,
        oldfile: row.oldfile,
        emailId: Empid ? Empid : parseJwt(token)?.id,
        data: savedFile.url,
        userName: `${employee?.firstname}${employee?.lastname}`,
        filename: savedFile.originalName,
        mimetype: savedFile.mimeType,
        addDate: null,
        updateDate: Date.now().toString(),
        status: true,
        deleteDate:null
      };

      // Update specific index in Redux


      // Update backend
      await modifyAttachmentDetails({
        variables: {
          id:row.id,
          input: {
           userId: row.userId,
            emailId: Empid ? Empid : parseJwt(token)?.id,
            data: savedFile.url,
            userName: `${employee?.firstname}${employee?.lastname}`,
            replacedFile: row.filename,
            filename: valuesObject.filename,
            addDate: row.addDate,
            mimetype: valuesObject.mimetype,
            updateDate: Date.now().toString(),
            deleteDate:null,
            status:true
            
          }
        }
      });
      refetch();
      
    } catch (error) {
      console.error('Error updating attachment:', error);
      await logError("AttachmentHistory", "handleUpdateAttachments", error.message);
    } finally {
      dispatch(setLoader(false));
    }
  };

  // Handle deleting an attachment
  const deleteHandler = async (attachmentId) => {
    try {
      console.log("Deleting attachment with ID:", attachmentId);
  
      await removeAttachment({
        variables: {
          id: Empid || parseJwt(token)?.id,
          userId: attachmentId?.id,
          input: {
            emailId: attachmentId.emailId,
            userId: attachmentId.userId,
            userName: `${employee?.firstname} ${employee?.lastname}`,
            data: attachmentId?.data,
            addDate:attachmentId?.addDate,
            replacedFile: attachmentId.oldfile,
            filename: attachmentId?.filename,
            mimetype: attachmentId?.mimetype,
            deleteDate: Date.now().toString(),
            status:false,
          },
        },
      });
  
      console.log("Attachment deleted successfully");
      refetch(); // Refresh data
    } catch (error) {
      console.error("Error deleting attachment:", error);
      await logError("AttachmentHistory", "Delelte", error.message);
    }
  };
  const handleDownload = async (fileUrl, fileName) => {
    try {
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading file:', error);
      await logError("AttachmentHistory", "handleDownload", error.message);
    }
  };
  function addSpaceBeforeCapitalLetters(userName) {
    if (!userName) return "";
  
    // Use regex to add a space before each capital letter
    return userName.replace(/([A-Z])/g, ' $1').trim();
  }
  
  
  if (loading) return <p>Loading...</p>;

  return (
    <>
      <>
        <div className="col-md-12 d-flex overflow-hidden" >
          <div className="card profile-box flex-fill">
            <div className="card-body">
              <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                Attachments

                <div style={{ display: 'flex', gap: '1rem' }}>
                  <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
                    <input
                      type="file"
                      ref={fileInputRefSingle}
                      onChange={handleSingleAttachment}
                      style={{ display: 'none' }}
                    />
                    <Link onClick={() => fileInputRefSingle.current && fileInputRefSingle.current.click()}>
                    <i
                    className="fa-solid fa-file"
                    style={{
                        fontSize: "20px",  
                    }}
                    aria-label="Add Single Attachment"
                />
                    </Link>
                  </Col>
                  <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
                    <input
                      type="file"
                      multiple
                      ref={fileInputRef}
                      onChange={handleMultipleAttachment}
                      style={{ display: 'none' }}
                    />
                    <Link onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                    <i
                className="fa-regular fa-folder-open"
                style={{
                    fontSize: "20px",
                   
                }}
                aria-label="Add Multiple Attachments"
            />
                    </Link>
                  </Col>


                </div>
              </h3>


              {/* {loadingAttachments && <p>Loading attachments...</p>}
        {errorAttachments && <p>Error fetching attachments: {errorAttachments.message}</p>} */}

              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'start' }}>Attachments</th>
                    <th style={{ textAlign: 'center' }}>Add Date</th>
                    <th style={{ textAlign: 'center' }}>User Name</th>
                    <th style={{ textAlign: 'center' }}>Actions</th>
                    <th style={{ textAlign: 'center' }}>Options</th>
                  </tr>
                </thead>
                {loadings ? (
                  <tbody>
                    <tr>
                      <td colSpan="5" style={{ textAlign: 'center', padding: '50px 0' }}>
                        <div className="d-flex justify-content-center align-items-center">
                          <Spinner animation="border" role="status" variant="primary" className="text-primary">
                            <span className="visually-hidden">Uploading...</span>
                          </Spinner>

                        </div>
                      </td>
                    </tr>
                  </tbody>
                ) : (
                  <tbody>
                  {[...Attachments]?.reverse()?.map((row, key) => (
                      row.status && (
                        <tr key={key}>
                          <td>
                            <a href={row.data} target="_blank" rel="noopener noreferrer">
                              {row.filename}
                            </a>
                          </td>

                          <td align="center">{ !row.updateDate ? dayjs(parseInt(row.addDate)).format("DD/MM/YYYY hh:mm:ss a"):dayjs(parseInt(row.updateDate)).format("DD/MM/YYYY")}</td>
                          <td align="center">{addSpaceBeforeCapitalLetters(row.userName)}
                          </td>
                          <td align="center">
                            <Button variant="primary" onClick={() => handleDownload(row.data, row.filename)}>
                              <MdCloudDownload /> Download
                            </Button>
                          </td>
                          <td align="center">
                            <Dropdown drop="end">
                              <Dropdown.Toggle as="span" style={{ cursor: 'pointer' }}>
                                <MdMoreVert size={24} />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item onClick={() => handleUpdateAttachment(row)}>
                                  Replace
                                </Dropdown.Item>
                                <input
                                  type="file"
                                  ref={fileInputRefUpdate}
                                  style={{ display: 'none' }}
                                />
                                <Dropdown.Item onClick={() => deleteHandler(row)}>
                                  Delete
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </td>
                        </tr>
                      )
                    ))}
                  </tbody>
                )}
              </Table>

            </div>
          </div>
        </div>
        <div className="col-md-12 d-flex overflow-hidden" >
          <div className="card profile-box flex-fill">
            <div className="card-body">

              <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
                Attachments History
              </h3>
              <Table responsive>
                <thead>
                  <tr>
                    <th style={{ textAlign: 'center' }}>Sno</th>
                    <th style={{ textAlign: 'center' }}>Attachments</th>
                    <th style={{ textAlign: 'center' }}>User Name</th>
                    <th style={{ textAlign: 'center' }}>Actions</th>
                    <th style={{ textAlign: 'center' }}>Action Date</th>
                  </tr>
                </thead>
                <tbody>
                  {allHistoryRecords?.reverse()?.map((row, key) => (
                    <tr key={key}>
                      <td align="center">{key + 1}</td>
                      <td align="center">
                        <a rel="noopener noreferrer">
                          {row.updateDate ? row.filename +"--->"+row.replacedFile :row.filename }
                        </a>
                      </td>
                      <td align="center">{addSpaceBeforeCapitalLetters(row.userName)}
                      </td>
                      {/* <td align="center">
                        {dayjs(parseInt(row.addDate)).format('DD/MM/YYYY hh:mm:ss:a')}
                      </td> */}
                      <td align="center">
                        {row.status ? row.updateDate ? "Updated" : "created" : "Deleted"}
                      </td>
                      <td align="center">{row.updateDate ? dayjs(parseInt(row.updateDate)).format("DD/MM/YYYY hh:mm:ss a") : !row.deleteDate ?dayjs(parseInt(row.addDate)).format("DD/MM/YYYY hh:mm:ss a") : dayjs(parseInt(row.deleteDate)).format("DD/MM/YYYY hh:mm:ss a") }</td>
                    </tr>

                  ))}
                </tbody>
              </Table>
            </div>
          </div>

        </div>
      </>
    </>

  );
}
