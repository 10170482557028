import { from, useMutation } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import CreateOrder from '../../../components/CreateOrder';
import { Starttask } from '../../../Slices/timerslice';
import { useDispatch } from 'react-redux';
import { UPDATE_WORKHISTORY } from '../../../Queries/Orders';
import { useParams } from "react-router-dom";
import RequestDetails from "./RequestDetails";
import AttachmentHistory from "../Requests/AttachmentHistory";
import { GET_REQUESTS_BY_ID } from "../../../Queries/Orders";
import moment from "moment";
import RequestWorkHistory from "../Requests/RequestWorkHistory";
import RequestProfileModelPopup from "../../../components/modelpopup/RequestProfileModelPopup";

import { useSelector } from "react-redux";
import { Modal } from 'bootstrap';

import dayjs from "dayjs";
const RequestProfile = () => {
    const { id } = useParams();
    const [isModalVisible, setModalVisible] = useState(false);
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState("request_details");
    const [timestamp, setTimestamp] = useState();
    const [ReqDue, setReqDue] = useState(true);

    const requestProfile = useSelector((state) => state.orders.requestInfo);
    console.log("requestProfile", requestProfile);

    const [userData, setUserData] = useState({
        id: '',
        requestId: '',
        date: '',
        dueDate: '',
        startDate: '',
        priority: '',
        processType: '',
        assignedTo: '',
        assignTo: '',
        completedDate: '',
        requestStatus: '',
        Assign: false,
        radioInfo: '',
        fromPending: false,
        instructions: false,
        from: '',
        to: '',
        subject: '',
        body: '',
        clientName: '',
    });
    console.log("userdata", userData);
    useEffect(() => {
        if (requestProfile) {
            setUserData({
                id: requestProfile?.id,
                requestId: requestProfile?.requestId || '',
                date: requestProfile?.date || '',
                dueDate: requestProfile?.dueDate || '',
                startDate: requestProfile?.startDate || '',
                priority: requestProfile?.priority || '',
                processType: requestProfile?.processType || '',
                assignedTo: requestProfile?.assignedTo || '',
                requestStatus: requestProfile?.requestStatus || '',
                assignTo: requestProfile?.assignTo || '',
                Assign: requestProfile?.Assign || '',
                completedDate: requestProfile?.completedDate || '',
                radioInfo: requestProfile?.radioInfo || '',
                fromPending: requestProfile?.fromPending || '',
                instructions: requestProfile?.instructions || '',
                from: requestProfile?.from || '',
                to: requestProfile?.to || '',
                subject: requestProfile?.subject || '',
                body: requestProfile?.body || '',
                clientName: requestProfile?.clientName || '',
            })
        }
    }, [requestProfile])

    useEffect(() => {
        const modalElement = document.getElementById('request_profile');
        if (modalElement) {
            // Initialize Bootstrap modal when component mounts
            const modal = new Modal(modalElement);
            // You can also manually control modal.show() and modal.hide() if needed
        }
    }, []);
    // const [getRequestById] = useMutation(GET_REQUESTS_BY_ID, {
    //     onCompleted(data) {
    //         console.log("getrequest", data)
    //         setUserData(data?.getRequestById[0])
    //     }
    // });
    // useEffect(() => {
    //     const valuesObject = {
    //         id: id
    //     }
    //     getRequestById({ variables: valuesObject })
    // if (data) {
    //   setEmployeeData(data?.SingleEmployee?.response); // Update employee data when data is available
    // }
    // }, [getRequestById, id])

    const getDateTime = (timeValue) => {
        console.log('getDateTime', timeValue)
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }
    const handleStart = () => {
        setModalVisible(true);
    };
    const handleClose = () => {
        setModalVisible(false);
    };
    const closeModal = () => {
        setModalVisible(false);
    };
    const getDueTime = (timeValue, typeValue) => {

        const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

        const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');
        console.log('getDateTime1', newDateTime, timeValue, typeValue, userData.date)

        return newDateTime;
    }

    const getDueTimePriority = (emailText) => {
        let hours = 24;
        const hasRushOrUrgent = emailText.toLowerCase().includes("rush") || emailText.toLowerCase().includes("urgent");

        if (hasRushOrUrgent) {
            hours = 2;
            // }else if(){

        }

        return hours;
    }

    const GetDueDateTemp = (userData) => {
        let dateValue = userData?.date;
        console.log('value.date', dateValue)
        if (userData?.priority == null) {
            getDateTime(getDueTime(parseInt(userData?.date), getDueTimePriority(userData?.subject + ' ' + userData?.html)))
        }
        else {

            if (userData?.priority == 'Rush') {

                dateValue = getDateTime(getDueTime(parseInt(userData?.date), 2))


            } else if (userData?.priority == 'On Demand') {
                dateValue = getDateTime(getDueTime(parseInt(userData?.date), 24));

            } else if (userData?.priority == 'Bulk') {
                dateValue = getDateTime(getDueTime(parseInt(userData?.date), 48));

            }
        }

        console.log('priorityChangeHandler', parseInt(dateValue));
        //return dayjs(parseInt(dateValue)).format(' DD/MM/YYYY  HH:mm:a');
        return dateValue
    }

    const GetDueDateTempOverried = (userData, date) => {
        let dateValue = userData?.date;
        if (userData?.priority == null) {
            getDateTime(getDueTime(parseInt(userData.date), getDueTimePriority(userData?.subject + ' ' + userData.html)))
        }
        else {

            if (userData?.priority == 'Rush') {

                dateValue = getDateTime(getDueTime(parseInt(date), 2))


            } else if (userData?.priority == 'On Demand') {
                dateValue = getDateTime(getDueTime(parseInt(date), 24))

            } else if (userData?.priority == 'Bulk') {
                dateValue = getDateTime(getDueTime(parseInt(date), 48));

            }
        }
        //setTimestamp(dateValue);
        console.log('priorityChangeHandler', dateValue);
        //setDueDate(dateValue);
        return dateValue


    }

    const convertToFormattedStringAndSeconds = (dateString) => {
        // Step 1: Parse the date string into a moment object
        const date = moment(dateString, 'MM/DD/YYYY hh:mm A', true);

        // Check for invalid date
        if (!date.isValid()) {
            console.error("Invalid date format");
            return null;
        }

        // Step 2: Format the date into "dd/mm/yyyy hh:mm:ss a"
        const day = String(date.date()).padStart(2, '0');
        const month = String(date.month() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.year();

        // Format hours, minutes, and seconds
        const formattedHours = date.hours() % 12 || 12; // Convert to 12-hour format
        const minutes = String(date.minutes()).padStart(2, '0');
        const seconds = String(date.seconds()).padStart(2, '0');
        const ampm = date.hours() < 12 ? 'am' : 'pm';

        const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;


        return {
            formattedDate,
            timestamp: date.valueOf() // Get Unix timestamp in milliseconds
        };
    };

    const AutoDateChanger = async (userData) => {
        console.log('ReqDue:', ReqDue);
        console.log('values.startDate:', userData.dueDate);



        if (!userData?.dueDate) {
            let dateString = '';

            if (userData.startDate) {
                const times = convertToFormattedStringAndSeconds(userData?.startDate);
                const value = times?.timestamp;
                const formate = GetDueDateTempOverried(userData, value);
                console.log('formate:', formate);
                dateString = formate;
            } else {
                console.log('Entering else block');
                dateString = GetDueDateTemp(userData);
                console.log('dateString:', dateString);
            }

            // Split and parse the dateString
            //const dateParts = dateString ? dateString.split(/[\s/:]+/) : [];
            const dateParts = dateString;
            const date = new Date(
                dateParts[2], // Year
                dateParts[1] - 1, // Month (0-based index)
                dateParts[0], // Day
                dateParts[3] % 12 + (dateParts[4] === "PM" ? 12 : 0), // Hours
                dateParts[4], // Minutes
                dateParts[5] // Seconds
            );

            const timestamp = date.getTime();
            setTimestamp(timestamp);
            const dueVal = dayjs(parseInt(timestamp));
            console.log('dueVal:', dueVal);

            //setDueDate('dueDate', dueVal);
        }
    };

    useEffect(() => {
        console.log('ReqDue100', ReqDue);

        console.log('duedate', userData.dueDate)
        //setDueDate('dueDate', employeeInfo?.dueDate);
        AutoDateChanger(userData);

    }, [userData?.priority, userData?.startDate, userData]);


    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <div className="card mb-3">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="d-flex justify-content-between">
                                        <h4>Request Information</h4>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <button onClick={handleStart} style={{
                                                fontSize: '1rem', color: 'white', border: 'none', borderRadius: '4px', cursor: 'pointer', transition: 'background-color 0.3s', backgroundColor: '#55ce63', padding: '2px 8px'
                                            }}>
                                                Start
                                            </button></div>
                                        {/* <div className="profile-view">
                                        <ul className="personal-info"> */}
                                        {/* <li> */}
                                        {/* <div className="title">clientName</div> */}
                                        <p className="text" style={{ marginRight: "20px" }}>clientName:<span style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "sans-serif" }}>{userData?.clientName}</span></p>
                                        {/* </li> */}
                                        {/* </ul>
                                    </div> */}
                                    </div>
                                    <div className="mb-3">
                                        <hr />
                                    </div>
                                    <CreateOrder handleClose={handleClose} isVisible={isModalVisible} onClose={closeModal} setModalVisible={setModalVisible} userData={userData}/>
                                    <div className="profile-view">
                                        <div className="row">
                                            <div className="col-md-5">
                                                <ul className="personal-info">
                                                    <li>
                                                        <div className="title">Request#</div>
                                                        <div className="text">
                                                            {userData?.requestId}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Request Received Date:</div>
                                                        <div className="text">
                                                            {getDateTime(parseInt(userData.date))}
                                                            {/* {userData?.date
                                                                ? new Date(Number(userData.date)).toLocaleDateString()
                                                                : ""} */}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Due Date:</div>
                                                        <div className="text">
                                                            {GetDueDateTemp(userData)}
                                                            {/* {userData?.dueDate ? new Date(Number(userData.dueDate)).toLocaleDateString() : ""} */}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Priority:</div>
                                                        <div className="text">
                                                            {userData?.priority}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Process Type:</div>
                                                        <div className="text">
                                                            {userData?.processType || "N/A"}
                                                        </div>
                                                    </li>

                                                    <li>
                                                        <div className="title">Assign to:</div>
                                                        <div className="text">
                                                            {userData?.assignTo || "N/A"}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Request Status:</div>
                                                        <div className="text">
                                                            {userData?.requestStatus || "N/A"}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="col-md-7">
                                                <ul className="personal-info">
                                                    <li>
                                                        <div className="title">Assigned To:</div>
                                                        <div className="text">
                                                            {userData?.assignedTo || "N/A"}
                                                        </div>
                                                    </li>
                                                    {/* <li>
                                                        <div className="title">Assign:</div>
                                                        <div className="text">
                                                            {userData?.Assign || "N/A"}
                                                        </div>
                                                    </li> */}
                                                    <li>
                                                        <div className="title">Start Date Override:</div>
                                                        <div className="text">
                                                            {getDateTime(parseInt(userData?.date))}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Due Date Override:</div>
                                                        <div className="text">
                                                            {GetDueDateTemp(userData) || parseInt(timestamp)}
                                                            {/* {GetDueDateTemp(userData?.dueDate)} */}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Completed Date:</div>
                                                        <div className="text">
                                                            {userData?.completedDate && !isNaN(new Date(userData.completedDate)) ? getDateTime(userData.completedDate) : ""}
                                                            {/* {userData?.completedDate ? new Date(Number(userData?.completedDate)).toLocaleDateString() : "N/A"} */}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">radioInfo:</div>
                                                        <div className="text">
                                                            {userData?.radioInfo || "N/A"}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">From Pendings:</div>
                                                        <div className="text">
                                                            {userData?.fromPending || "N/A"}
                                                        </div>
                                                    </li>
                                                    <li>
                                                        <div className="title">Instructions:</div>
                                                        <div className="text">
                                                            {userData?.instructions || "N/A"}
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="pro-edit">
                                            <Link
                                                data-bs-target="#request_profile"
                                                data-bs-toggle="modal"
                                                className="edit-icon"
                                                to="#"
                                            >
                                                <i className="fa-solid fa-pencil"></i>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12 d-flex overflow-hidden">
                        <div className="card profile-box flex-fill">
                            <div className="card-body">
                                <div className="row">

                                    <div className="col-md-4">
                                        <ul className="personal-info">
                                            <li>
                                                <div className="title">Email Summary</div>
                                                <div className="text">{userData?.subject || 'N/A'}</div>
                                            </li>
                                        </ul>

                                    </div>
                                    <div className="col-md-4">
                                        <ul className="personal-info">
                                            <li>
                                                <div className="title">Requestor</div>
                                                <div className="text">{userData?.from || 'N/A'}</div>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <ul className="personal-info">
                                            <li>
                                                <div className="title">Send to</div>
                                                <div className="text">{userData?.to || 'N/A'}</div>
                                            </li>
                                        </ul>
                                    </div>


                                </div>
                                <div className="card mt-3">
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <div className="m-2">
                                                    <div>
                                                        {userData?.subject}
                                                    </div>
                                                    <br />
                                                    <div>
                                                        {userData?.body}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* <div className="card tab-box"> */}
                    {/* <div className="row user-tabs"> */}
                    <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                        <ul className="nav nav-tabs nav-tabs-bottom">
                            <li className="nav-item">
                                <Link
                                    to="#request_details"
                                    data-bs-toggle="tab"
                                    className={`nav-link ${activeTab === "request_details" ? "active" : ""}`}
                                    onClick={() => setActiveTab("request_details")}
                                >
                                    Request Details
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#attachment_history"
                                    data-bs-toggle="tab"
                                    className={`nav-link ${activeTab === "attachment_history" ? "active" : ""}`}
                                    onClick={() => setActiveTab("attachment_history")}
                                >
                                    Attachments History
                                </Link>
                            </li>
                            <li className="nav-item">
                                <Link
                                    to="#work_history"
                                    data-bs-toggle="tab"
                                    className={`nav-link ${activeTab === "work_history" ? "active" : ""}`}
                                    onClick={() => setActiveTab("work_history")}
                                >
                                    Work History
                                </Link>
                            </li>

                        </ul>

                    </div>
                    <div className="tab-content">
                        {activeTab === "request_details" && <RequestDetails />}
                        {activeTab === "work_history" && <RequestWorkHistory />}
                        {activeTab === "attachment_history" && <AttachmentHistory />}
                    </div>

                    {/* </div> */}

                    {/* </div> */}

                    {/* Tab Contents */}
                    {/* <div className="tab-content">
                        {activeTab === "request_details" && <RequestDetails employeeInfo={userData} />}
                        {activeTab === "work_history" && <WorkHistory employeeInfo={userData} />}
                        {activeTab === "attachment_history" && <AttachmentHistory employeeInfo={userData} />}
                        Add additional components for other tabs here
                    </div> */}
                </div>
                <RequestProfileModelPopup />
                {/* <EmailsModelPopup employeeInfo={userData}/> */}
                {/* <RequestDetailsModelPopup/> */}
            </div>

        </>
    );
};

export default RequestProfile;