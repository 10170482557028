import React, { useEffect, useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { Table, Button, Modal, Input, Collapse, Row, Col, Select } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { GET_FIELDS, UPDATE_FIELD, ADD_NEW_FIELD } from '../../../Queries/options';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from '../../../Slices/loaderSlice';
import { setNewObj, setDataBucket } from '../../../Slices/dataBucket';
import Swal from 'sweetalert2';
 
const { Option } = Select;
const { Panel } = Collapse;
 
const DocumentManager = () => {
  const { data, loading ,refetch } = useQuery(GET_FIELDS); // Fetch fields
  const [updateField] = useMutation(UPDATE_FIELD); // Update field mutation
  // const [addField, { error, data:newField }] = useMutation(ADD_NEW_FIELD, {
  //   onCompleted: (data) => {
  //     console.log("Mutation completed successfully!", data);
  //     // You can handle additional logic here like showing a success message
  //     alert("New field added successfully!");
  //   },
  //   onError: (error) => {
  //     console.error("Error occurred during mutation:", error);
  //     // Handle error case, maybe show an error message to the user
  //   }
  // });
  const [addField, { error, data: newField }] = useMutation(ADD_NEW_FIELD, {
    onCompleted: (data) => {
      console.log("Mutation completed successfully!", data);
  
      // Check if the response contains the error message indicating that the field already exists
      if (data.CreateDynamicField.status === false) {
        // Show an alert with the error message if the field already exists
        alert(data.CreateDynamicField.message);  // "Dynamic field 'STATS' already exists."
      } else {
        // Success case: Show a success alert
        alert("New field added successfully!");
      }
    },
    onError: (error) => {
      console.error("Error occurred during mutation:", error);
      // You can handle additional error case if needed
      alert("An error occurred. Please try again.");
    }
  });
  
 
  const dispatch = useDispatch();
 
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState('create'); // 'create', 'edit', or 'createobj'
  const [currentField, setCurrentField] = useState(null);
  const [currentDocument, setCurrentDocument] = useState(null);
  const [formValues, setFormValues] = useState({ name: '', status: 'active' });
 
  const DataBucket = useSelector((state) => state.dataBucket.databucket);
  useEffect(() => {
    dispatch(setDataBucket(data?.GetDynamicField)); // Update Redux with initial data from query
  }, [ refetch()]);
  // Open modal and set form values
  const openModal = (mode, field = null, document = null) => {
    setModalMode(mode);
    setCurrentField(field);
    setCurrentDocument(document);
    setFormValues(document || { name: '', status: 'active' });
    setIsModalOpen(true);
  };
  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setCurrentField(null);
    setCurrentDocument(null);
    setFormValues({ name: '', status: 'active' });
  };
  // Handle form input changes
  const handleInputChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };
  // Save handler (create, edit, and createobj modes)
  const handleSave = async () => {
    try {
      if (!formValues.name || !formValues.status) {
        console.error('Form values are incomplete.');
        return;
      }
      closeModal(); // Close modal before saving
      if (modalMode === 'edit') {
        if (currentField && currentDocument) {
          const updatedData = {
            name: formValues.name,
            status: formValues.status,
          };
          await updateField({
            variables: {
              id: currentField?._id,
              obj: currentDocument?._id,
              newData: updatedData,
              key: false,
            },
          });
          // dispatch(setDataBucket({ id: currentField?._id, objId: currentDocument?._id, newData: updatedData }));
        }
      } else if (modalMode === 'createobj') {
        if (currentField) {
          const newFieldData = {
            name: formValues.name,
            status: formValues.status,
            flag: true,
          };
          await updateField({
            variables: {
              id: currentField?._id,
              newData: newFieldData,
            },
          });
          refetch()
          // dispatch(setDataBucket({ id: currentField?._id, newData: newFieldData }));
        }
      } else if (modalMode === 'create') {
        if (formValues.name) {
          const newCreatedData = await addField({
            variables: {
              name: formValues.name?.toUpperCase().trim(),
            },
          });
          refetch()
          dispatch(setNewObj(newCreatedData.data.CreateDynamicField.response)); // Update Redux with new data
        }
      }
    } catch (error) {
      console.error('Error saving document:', error);
    }
  };
  // Delete field or document
  const handleDelete = async (fieldId, docId) => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: 'Do you really want to delete this field/document?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, cancel!',
      });
      if (result.isConfirmed) {
        if (!docId) {
          await updateField({
            variables: {
              id: fieldId,
              obj: null,
              newData: {},
              key: true,
            },
          });
          // dispatch(setNewObj({ id: fieldId, key: true }));
        } else {
          await updateField({
            variables: {
              id: fieldId,
              obj: docId,
              newData: {},
              key: true,
            },
          });
          // dispatch(setNewObj({ id: fieldId, objId: docId, key: true }));
        }
       
        Swal.fire('Deleted!', 'The document/field has been deleted.', 'success');
 
      }
      refetch();
    } catch (error) {
      console.error('Error deleting document/field:', error);
      Swal.fire('Error!', 'There was an error deleting the field/document.', 'error');
    }
  };
 
 
  // Dispatch loader state
  dispatch(setLoader(loading));
  const formatFieldName = (fieldName) => {
    return fieldName
      .toLowerCase() // Convert the string to lowercase
      .replace(/_/g, " ") // Replace underscores with spaces
      .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize the first letter of each word
  };
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div id="document_manager" style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
          <div className="card-body" style={{ margin: '0 auto', padding: '20px', background: '#fff', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', maxWidth: '100%', width: '90%' }}>
            <h2 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Data Bucket</h2>
            <Button
              type="primary"
              icon={<PlusOutlined />}
              style={{ marginBottom: '16px', display: 'block', marginLeft: 'auto' }}
              onClick={() => openModal('create')}
             >
              Add New Field
            </Button>
            <Collapse accordion>
              {DataBucket?.filter((field) => field?.status).map((field) => {
                const filteredData = (field?.data || []).filter((doc) => doc?.flag !== false);
                return (
                  <Panel
                    header={
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                       {formatFieldName(field.name)}{' '}
                        <DeleteOutlined
                          style={{ color: '#ff4d4f', cursor: 'pointer' }}
                          onClick={() => handleDelete(field._id)}
                        />
                      </div>
                    }
                    key={field._id}
                  >
                    <Row gutter={[10, 10]} style={{ marginBottom: '16px' }}>
                      {filteredData.map((record) => (
                        <Col span={8} key={record._id}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '8px', border: '1px solid #f0f0f0', borderRadius: '4px' }}>
                            <div>
                              <div><strong>Name:</strong> {record.name}</div>
                              <div><strong>Status:</strong> {record.status}</div>
                            </div>
                            <div style={{ display: 'flex', gap: '8px' }}>
                              <EditOutlined
                                style={{ color: '#1890ff', cursor: 'pointer' }}
                                onClick={() => openModal('edit', field, record)}
                              />
                              <DeleteOutlined
                                style={{ color: '#ff4d4f', cursor: 'pointer' }}
                                onClick={() => handleDelete(field._id, record._id)}
                              />
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                    <Button
                      type="dashed"
                      icon={<PlusOutlined />}
                      onClick={() => openModal('createobj', field)}
                      style={{ marginTop: '16px' }}
                    >
                      Add New Document
                    </Button>
                  </Panel>
                );
              })}
            </Collapse>
 
            <Modal
              title={modalMode === 'edit' ? 'Edit Document' : 'Add New Field'}
              visible={isModalOpen}
              onCancel={closeModal}
              onOk={handleSave}
              bodyStyle={{ padding: '24px' }}
            >
              <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                  <Input
                    name="name"
                    placeholder="Name"
                    value={modalMode != "create" ? formValues.name :formValues.name?.toUpperCase().trim()}
                    onChange={handleInputChange}
                  />
                </Col>
                <Col xs={24} md={12}>
                  {modalMode !== 'create' && (
                    <Select
                      name="status"
                      placeholder="Select Status"
                      value={formValues.status}
                      onChange={(value) => setFormValues({ ...formValues, status: value })}
                      style={{ width: '100%' }}
                    >
                      <Option value="active">Active</Option>
                      <Option value="inactive">Inactive</Option>
                    </Select>
                  )}
                </Col>
              </Row>
            </Modal>
          </div>
        </div>
      </div>
    </div>
   
     
  );
};
 
export default DocumentManager;