import React, { useState } from "react";
import { useMutation } from "@apollo/client";
import { Button, Input, Typography, Spin, Card, Divider } from "antd";
import { SendOutlined, UserOutlined } from "@ant-design/icons";
import { CHAT_GPT_MUTATION } from "../../../Queries/Leads";

function FetchWithAi() {
  const [companyName, setCompanyName] = useState("");
  const [location, setLocation] = useState("");
  const [messages, setMessages] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  function renderData(data) {
    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };

    if (typeof data === "object" && data !== null) {
      if (Array.isArray(data)) {
        return (
          <ul>
            {data.map((item, idx) => (
              <li key={idx}>{renderData(item)}</li>
            ))}
          </ul>
        );
      } else {
        return (
          <div style={{ marginLeft: "20px" }}>
            {Object.entries(data).map(([key, value], idx) => (
              <div key={idx}>
                <strong style={{ fontWeight: "bold", color: "#34444c" }}>
                  {capitalizeFirstLetter(key)}:
                </strong>{" "}
                {typeof value === "object" ? renderData(value) : value}
              </div>
            ))}
          </div>
        );
      }
    }
    return data; // For simple data types like string or number
  }

  {
    messages.map((item, index) => (
      <div
        key={index}
        style={{
          textAlign: item.sender === "user" ? "right" : "left",
          marginBottom: "10px",
        }}
      >
        <Typography.Text>
          {item.sender === "user" ? <UserOutlined /> : "Bot"}
        </Typography.Text>{" "}
        {item.sender === "user" ? (
          <span>{item.text}</span>
        ) : (
          renderData(item.text)
        )}
      </div>
    ));
  }

  let prompt = `Please search the internet and extract publicly available information  in json format and return information directly in json format about the agency or company using the following details:

Company Name: ${companyName}
Location: ${location}
Please gather the following information:

Agency Name: Confirm the official name of the company or agency.
Description: Provide a brief description of the company's services, mission, or business focus.
Locations: Identify all physical office locations or headquarters of the company, including addresses or city names.
Website: Find the official website URL.
Social Media Links: Gather any available social media profiles (LinkedIn, Facebook, Twitter, Instagram, etc.).
Products/Services Offered: List the products and services the company offers.
Key Personnel: Identify key individuals within the agency (e.g., CEO, founders, directors).
Contact Information:
Individual emails and phone numbers for key personnel (if available).
Main agency email address and phone number.
Sources of Links: Provide the URLs or references from which the information was sourced (official websites, news articles, public directories, etc.).
Please ensure the information is publicly available and trustworthy from reputable sources.`;

  const [sendMessage, { data, loading, error }] = useMutation(
    CHAT_GPT_MUTATION,
    {
      variables: { prompt: prompt },
      onCompleted: (data) => {
        if (data && data.ChatGPTQuery && data.ChatGPTQuery.response) {
          let botResponse = data.ChatGPTQuery.response.content;

          try {
            const parsedResponse = JSON.parse(botResponse);
            console.log("parsedResponse", parsedResponse);
            // Create a structured message to display the parsed response

            setMessages((prevMessages) => [
              ...prevMessages,
              { text: parsedResponse, sender: "bot" },
            ]);
          } catch (e) {
            console.error("Error parsing response as JSON:", e);
            setMessages((prevMessages) => [
              ...prevMessages,
              { text: botResponse, sender: "bot" },
            ]);
          }
        }
      },
      onError: (err) => {
        console.error("Error fetching data:", err);
      },
    }
  );

  const handleSend = () => {
    if (companyName.trim() && location.trim()) {
      setMessages((prevMessages) => [
        ...prevMessages,
        { text: `${companyName} in ${location}`, sender: "user" },
      ]);
      sendMessage();
      setCompanyName("");
      setLocation("");
    }
  };

  return (
    <div
      style={{
        // padding: "50px",
        // maxWidth: "800px",
        // margin: "0 auto",
        textAlign: "center",
        boxShadow:
          "0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)", // Shadow on top and bottom
        transition: "box-shadow 0.3s ease",
      }}
    >
      <Button
        type={isVisible ? "link" : "primary"} // Use primary type when not visible, link type when visible
        onClick={() => setIsVisible(!isVisible)}
        style={{
          fontSize: isVisible ? "20px" : "", // Apply font size only when isVisible is true
          padding: "0 20px", // Padding is still applied in both states
          float: "right",
          marginRight: "10px",
          marginTop: isVisible ? "0" : "10px", // Add margin-top when visible for proper layout
          color: isVisible ? "red" : "", // Set color to red when isVisible is true
        }}
      >
        {isVisible ? "x" : "Fetch With AI"}
      </Button>

      {isVisible && (
        <Card
          bordered={false}
          style={{
            marginTop: "20px",
            padding: "20px",
            backgroundColor: "#f0f0f0",
            height: "auto",
            overflow: "hidden",
            //         boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1), 0 -4px 8px rgba(0, 0, 0, 0.1)", // Shadow on top and bottom
            // transition: "box-shadow 0.3s ease",
          }}
        >
          <div
            style={{
              maxHeight: "300px",
              overflowY: "auto",
              marginBottom: "10px",
            }}
          >
            <div>
              {messages.map((item, index) => (
                <div
                  key={index}
                  style={{
                    textAlign: item.sender === "user" ? "right" : "left",
                    marginBottom: "10px",
                  }}
                >
                  <Typography.Text>
                    {item.sender === "user" ? <UserOutlined /> : "Bot"}
                  </Typography.Text>{" "}
                  {item.sender === "user" ? (
                    <span>{item.text}</span>
                  ) : (
                    renderData(item.text)
                  )}
                </div>
              ))}
            </div>
          </div>

          {loading && (
            <div style={{ textAlign: "center", marginTop: "20px" }}>
              <Spin size="large" />
            </div>
          )}

          <div style={{ display: "flex", marginTop: "10px" }}>
            <Input
              placeholder="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              style={{ width: "45%", marginRight: "5px" }}
            />
            <Input
              placeholder="Location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              style={{ width: "45%", marginRight: "5px" }}
            />
            <Button
              type="secondary"
              onClick={handleSend}
              disabled={loading}
              style={{ padding: "0", width: "40px", height: "40px" }}
              icon={<SendOutlined />}
            />
          </div>

          {error && (
            <Typography.Text type="danger">Error fetching data</Typography.Text>
          )}
        </Card>
      )}
    </div>
  );
}

export default FetchWithAi;
