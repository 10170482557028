import React, { useEffect, useState } from 'react';
import { Form, Row, Col, Select, Input, Button, DatePicker, Space } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { FieldArray, Field } from 'formik'; // Assuming you're still using Formik
import dayjs from 'dayjs';
import * as DataBucket from "../../../databucket";
import { useQuery } from '@apollo/client';
import { GET_ALL_CARRIERS, GET_ALL_CURRENT_CARRIERS } from '../../../Queries/Users';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;

const ExistingPolicyInformation = (props) => {
  const { handleChange, values, RequestInfo, errors, touched, handleBlur } = props;
  
  const [policyNumber, setPolicyNumber] = useState([]);
  
  const [currentPremium, setCurrentPremium] = useState([]);
 
  const [carrierFlag,setCarrierFlag] = useState([]);

  const {data:carrierData} = useQuery(GET_ALL_CARRIERS,{
    fetchPolicy: 'no-cache',
  });

  useEffect(()=>{
    if(carrierData){
      setCarrierFlag(carrierData?.getAllCarriers?.response)
    }
  },[carrierData])

  useEffect(() => {
    if (values.existingPolicyInformation) {
      setPolicyNumber(values.existingPolicyInformation.map(policy => policy.policyNumber));
    }
  }, [values.existingPolicyInformation]);

  useEffect(() => {
    if (values.existingPolicyInformation) {
      setCurrentPremium(values.existingPolicyInformation.map(policy => policy.currentPremium));
    }
  }, [values.existingPolicyInformation]);

  // const carrierFlag = carrierData?.getAllCarriers?.response; 
  //console.log("carrierFlag",carrierFlag)
  // const { data } = useQuery(carrierFlag ? GET_ALL_CARRIERS : GET_ALL_CURRENT_CARRIERS);
  // let currentCarriers = data?.getAllCurrentCarriers?.response || [];
  // console.log("currentCarriers",currentCarriers)

  const options = useSelector((state) => state?.dataBucket?.databucket)
  console.log("frfsvds", options)

  const handleDeleteRow = (index, arrayHelpers) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this existingPolicyInformation?");
    if (confirmDelete) {
      arrayHelpers.remove(index);
      setPolicyNumber((prevPolicyNumbers) => prevPolicyNumbers.filter((_, i) => i !== index));
      setCurrentPremium((prevCurrentPremium) => prevCurrentPremium.filter((_, i) => i !== index));
    }
  };

  const handleAddRow = (arrayHelpers) => {
    arrayHelpers.push({
      currentCarrier: '',
      propertyType: '',
      propertyNew: '',
      policyNumber: '',
      effectiveDate: null,
      currentPremium: ''
    });
    // Add an empty value to the policyNumber state to match the new row
    setPolicyNumber((prevPolicyNumbers) => [...prevPolicyNumbers, '']);
    setCurrentPremium((prevCurrentPremium) => [...prevCurrentPremium, '']);
  };

  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters
 
    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }
 
    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)
 
    e.target.value = value;
  };

  return (
    <div className='row'>
      <Form layout="vertical">
        <h3 className="card-title" style={{ fontWeight: 'bold' }}>Existing Policy Information</h3>
        <div className="mb-3">
          <hr />
        </div>
        <FieldArray
          name="existingPolicyInformation"
          render={(arrayHelpers) => (
            <>
              {values?.existingPolicyInformation?.map((policy, index) => (
                <Row key={index} gutter={16}>
                  <Col span={5}>
                    <Form.Item
                      label="Current Carrier"
                    //name="currentCarrier"
                    //validateStatus={touched?.existingPolicyInformation?.[index]?.currentCarrier && errors?.existingPolicyInformation?.[index]?.currentCarrier ? 'error' : ''}
                    //help={touched?.existingPolicyInformation?.[index]?.currentCarrier && errors?.existingPolicyInformation?.[index]?.currentCarrier}
                    >
                      <Select
                        //placeholder="select current carrier"
                        //showSearch
                        name={`existingPolicyInformation.${index}.currentCarrier`}
                        onChange={(value) => handleChange({ target: { name: `existingPolicyInformation.${index}.currentCarrier`, value } })}
                        value={policy.currentCarrier}
                        onBlur={handleBlur}
                      >
                        {carrierFlag?.filter(item => item?.status === 'Active' && item?.flag === true)
                          .map((carrierItem, key) => (
                            <Option key={key} value={carrierItem?.carrierName}>
                              {carrierItem?.carrierName}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Policy Type"
                    // name="policyType"
                    //validateStatus={touched?.existingPolicyInformation?.[index]?.propertyType && errors?.existingPolicyInformation?.[index]?.propertyType ? 'error' : ''}
                    //help={touched?.existingPolicyInformation?.[index]?.propertyType && errors?.existingPolicyInformation?.[index]?.propertyType}
                    >
                      <Select
                        //placeholder="select policy"
                        //showSearch
                        name={`existingPolicyInformation.${index}.propertyType`}
                        onChange={(value) => handleChange({ target: { name: `existingPolicyInformation.${index}.propertyType`, value } })}
                        value={policy.propertyType}
                      >
                        {(values.processType == 'Policy Quoting' && values.department == 'Commercial Lines')? renderOptions(options, { name: "PROPERTY_TYPE_CONFIG_POLICY_QUOTE" }, DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE) : renderOptions(options, { name: "PROPERTY_TYPE_CONFIG" }, DataBucket.PROPERTY_TYPE_CONFIG)}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col span={5} style={{ width: '80%' }}>
                    <Form.Item label="Policy Number"
                    //name="policyNumber"
                    //validateStatus={touched?.existingPolicyInformation?.[index]?.policyNumber && errors?.existingPolicyInformation?.[index]?.policyNumber ? 'error' : ''}
                    //help={touched?.existingPolicyInformation?.[index]?.policyNumber && errors?.existingPolicyInformation?.[index]?.policyNumber}
                    >
                      <Input
                        //name={`existingPolicyInformation.${index}.policyNumber`}
                        value={policyNumber[index] || ''}
                        onChange={(e) => {
                          const updatedPolicyNumbers = [...policyNumber];
                          updatedPolicyNumbers[index] = e.target.value;
                          setPolicyNumber(updatedPolicyNumbers);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `existingPolicyInformation[${index}].policyNumber`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Effective Date"
                    //name="effectiveDate"
                    //validateStatus={touched?.existingPolicyInformation?.[index]?.effectiveDate && errors?.existingPolicyInformation?.[index]?.effectiveDate ? 'error' : ''}
                    //help={touched?.existingPolicyInformation?.[index]?.effectiveDate && errors?.existingPolicyInformation?.[index]?.effectiveDate}
                    >
                      <Field name={`existingPolicyInformation.${index}.effectiveDate`}>
                        {({ field }) => (
                          <DatePicker
                            name={`existingPolicyInformation.${index}.effectiveDate`}
                            onChange={(date) => field.onChange({ target: { value: date, name: field.name } })}
                            value={field.value ? dayjs(field.value) : null}
                            allowClear={false}
                            format="DD/MM/YYYY"
                            onKeyDown={handleKeyDown}
                          />
                        )}
                      </Field>
                      {/* <DatePicker
                      format="DD/MM/YYYY"
                      name={`existingPolicyInformation.${index}.effectiveDate`}
                      onChange={(date) => handleChange({ target: { name: `existingPolicyInformation.${index}.effectiveDate`, value: date } })}
                      value={dayjs(parseInt(policy?.effectiveDate))}
                    /> */}
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item label="Current Premium"
                    //name="currentPremium"
                    //validateStatus={touched?.existingPolicyInformation?.[index]?.currentPremium && errors?.existingPolicyInformation?.[index]?.currentPremium ? 'error' : ''}
                    //help={touched?.existingPolicyInformation?.[index]?.currentPremium && errors?.existingPolicyInformation?.[index]?.currentPremium}
                    >
                      <Input
                        //name={`existingPolicyInformation.${index}.currentPremium`}
                        value={currentPremium[index] || ''}
                        onChange={(e) => {
                          const updatedPolicyNumbers = [...currentPremium];
                          updatedPolicyNumbers[index] = e.target.value;
                          setCurrentPremium(updatedPolicyNumbers);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `existingPolicyInformation[${index}].currentPremium`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={2} style={{ marginTop: "25px", display: 'flex', alignItems: 'center' }}>
                    <Form.Item>
                      <Link to="#" className="delete-icon" onClick={() => handleDeleteRow(index, arrayHelpers)}>
                        <i className="fa-regular fa-trash-can" />
                      </Link>
                    </Form.Item>
                    {/* <Button
                    type="danger"
                    icon={<MinusCircleOutlined />}
                    onClick={() => handleDeleteRow(index, arrayHelpers)}
                  >
                    Delete
                  </Button> */}
                  </Col>
                </Row>
              ))}

              <Form.Item>
                <Link to="#" onClick={() => handleAddRow(arrayHelpers)}>
                  <i className="fa-solid fa-plus-circle" /> Add More
                </Link>
                {/* <Button
                type="dashed"
                onClick={() => handleAddRow(arrayHelpers)}
                icon={<PlusOutlined />}
              >
                Add Row
              </Button> */}
              </Form.Item>
            </>
          )}
        />
      </Form>
    </div>
  );
};

export default ExistingPolicyInformation;

