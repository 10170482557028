
import AdminLeave from "../../views/pages/Employees/AdminLeave";
import AttendenceAdmin from "../../views/pages/Employees/Attendenceadmin";
import React, { useEffect, useState } from "react";
import { Outlet, Route, Routes, Navigate } from "react-router-dom";
import Header from "../../views/layout/Header";
import Sidebar from "../../views/layout/Sidebar";
import { SetAllEmployees } from "../../Slices/employeeslice";
import AllEmpoyee from "../../views/pages/Employees/AllEmpoyee";
import { useSelector, useDispatch } from "react-redux";
import { fetchClients } from "../../Slices/clientSlice";
import { setLoginUserDetails, setUserStatus, setAllUsers } from "../../Slices/loginUserSlice";
import Clients from "../../views/pages/Clients/Clients";
import Error404 from "../../views/pages/Error/Error404";
import { SidebarProject } from "../../views/pages/Employees/Projects/SidebarProject";
import ClientProfile from "../../views/pages/Clients/ClientProfile";
import Profile from "../../views/pages/Profile/Profile";
import AllSales from "../../views/pages/Sales/AllSales";
import Sales from "../../views/pages/Sales1";
// import OffCanvas from "../../components/OffCanvas";
import SalesInformation from "../../views/pages/Sales/SalesInformation";
import SaleDetails from "../../views/pages/Sales1/SaleDetails";
import CustomiseRequestOptions from "../../views/pages/CustomiseRequestOptions/index";
import Report from "../../views/pages/Report/Reports";
import { fetchDynamicRoutes } from "../../Slices/DyanamicRoute";
//import Request from "../../views/pages/Requests/Request";
import RequestProfile from "../../views/pages/Requests/RequestProfile";
import Requests from "../../views/pages/Requests1/Requests";
import LeaveSettingCustomPolicy from "../../views/pages/Employees/LeaveSettingCustomPolicy";
import RequestDetails from "../../views/pages/Requests1/RequestDetails";
import EmployeeReport from "../../views/pages/Designs/employeereport";
import ClientReport from "../../views/pages/Designs/clientreport";
import ClientList from "../../views/pages/Clients/ClientList";
import EmployeeListFilter from "../../components/EmployeeListFilter";
import BulkEmail from "../../views/pages/Mail/BulkEmail";
import IDCard from "../../components/IdCardgenerater";
//import Loader from "../../views/pages/Loader/Loader";
import WebScrapingConfig from "../../views/webScrepping";
import FieldManager from "../../views/pages/Profile/options";
import ConditionMenu from "../../views/layout/conditionMenu";
// import IDCard from "../../components/IdCardgenerater";
import { fetchAllUsers } from "../../Slices/loginUserSlice";
import { GET_USER_DETAILS, FETCH_ALL_USERS } from "../../Queries/Users";
import Loader from "../../views/pages/Loader/Loader";
import ScrapeDataComponent from "../../views/pages/datascraping/ScrapeDataComponent";
import OverwritingHistory from "../../views/pages/Designs/OverwritingHistory";
import { setDataBucket } from "../../Slices/dataBucket";
import { GET_FIELDS } from "../../Queries/options";
import { fetchData } from "../../Slices/dataBucket";
import { useMutation, useLazyQuery, useQuery } from "@apollo/client";
import LeaveSettings from "../../views/pages/Employees/LeaveSetting";
import { GET_ALL_CLIENTS } from "../../Queries/Users";
import { setClients } from "../../Slices/clientSlice";
import { elements } from "chart.js";
import Dashboard from "../../views/pages/Dashboards/Dashboard";
import { setLoader } from "../../Slices/loaderSlice";
import ConditionMenuRoute from "../../views/layout/daynamicRoutes";
import { GET_DYNAMIC_ROUTES } from "../../Queries/options";
import { setempRoute } from "../../Slices/DyanamicRoute";
import AttendanceEmployeeFilter from "../../components/AttendanceEmployeeFilter";
import { fetchUserDetails } from "../../Slices/loginUserSlice";
import AttendanceEmployee from "../../views/pages/Employees/AttendenceEmployee";
import RequestsStats from "../../views/pages/Dashboards/RequestsStats";
import SalesStats from "../../views/pages/Dashboards/SalesStats";
import EmployeeLeave from "../../views/pages/Employees/EmployeeLeave";
import Holidays from "../../views/pages/Employees/Holidays";
import { GET_LAST_ATTENDENCE_RECORD } from "../../Queries/attendence";
import PayslipReport from "../../views/pages/PaySlips/Payslips";
import ResetToken from "../../views/pages/ResetToken/resetToken"

import { setAttendence, setLastRecord } from "../../Slices/attendenceSlice";
import { ATTENDENCE } from "../../Queries/attendence";
import Swal from "sweetalert2";
const token = localStorage.getItem('token');
const parseJwt = (token) => {
    try {
        return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
        return null;
    }
};

const AppContainer = () => {
    //const [isSidebarVisible, setIsSidebarVisible] = useState(true);
    useEffect(() => {
        localStorage.setItem("colorschema", "blue");
        localStorage.setItem("layout", "vertical");
        localStorage.setItem("layoutwidth", "fixed");
        localStorage.setItem("layoutpos", "fluid");
        localStorage.setItem("topbartheme", "light");
        localStorage.setItem("layoutSized", "lg");
        localStorage.setItem("layoutStyling", "default");
        localStorage.setItem("layoutSidebarStyle", "dark");
        dispatch(fetchData());
        dispatch(fetchDynamicRoutes());
        dispatch(fetchClients());
    
    }, []);
    const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);

    // const [getUserDetails, { }] = useMutation(GET_USER_DETAILS, {
    //     onCompleted(data) {
    //         console.log('getUserDetails-->', data.getUserDetails[0],)
    //         dispatch(setLoginUserDetails(data?.getUserDetails[0]))
    //         dispatch(setUserStatus(true))
    //     },
    // });
   const superAdminRoutes = [
             {
            id: 1,
            path: "request",
            element: <Requests />,
        },
        {
            id: 2,
            path: "request/:id",
            element: <Requests />
        },
        {
              id: 39,
      path: "adminattendance",
      element: <AttendenceAdmin />,
    },
        {
            id: 3,
            path: "employees",
            element: <AllEmpoyee />,
        },
        {
            id: 4,
            path: "employees/:Empid",
            element: <Profile key={Math.random()} />,
        },
        {
            id: 5,
            path: "employees-list",
            element: <EmployeeListFilter />
        },
        {
            id: 6,
            // path: "profile",
            path: "profile",
            element: <Profile />,
        },
        {
            id: 7,
            path: "clients",
            element: <Clients />,
        },
        {
            id: 8,
            path: "clients/:id",
            element: <ClientProfile />,
        },
        {
            id: 9,
            path: "clients-list",
            element: <ClientList />,
        },
        {
            id: 10,
            path: "sales",
            element: <Sales />,
        },
        {
            id: 11,
            path: "sales/:id",
            element: <SaleDetails />,
        },
        // {
        //     id: 14,
        //     path: "sales",
        //     element: <AllSales />,
        // },
        // {
        //     id: 15,
        //     path: "sales/:id",
        //     element: <SalesInformation />,
        // },
        // {
        //     id: 12,
        //     path: "reports",
        //     element: <Report />,
        // },
        {
            id: 13,
            path: "CustomiseRequestOptions",
            element: <CustomiseRequestOptions />,
        },
        {
            id: 14,
            path: "EmployeeReport",
            element: <EmployeeReport />,
        },
        {
            id: 15,
            path: "ClientReport",
            element: <ClientReport />,
        },
        {
            id: 16,
            path: "container",
            element: <ConditionMenu />
        },
        {
            id: 17,
            path: "Mail",
            element: <BulkEmail />,
        },

        {
            id: 19,
            path: "dataBucket",
            element: <FieldManager />
        },
        {
            id: 32,
            path: "WebScrapingConfig",
            element: <WebScrapingConfig />
        },
        {
            id: 21,
            path: "OverwritingHistory",
            element: <OverwritingHistory />
        },
        {
            id: 5,
            path: "WebScrapingConfig",
            element: <WebScrapingConfig />
        },
        {
            id: 22,
            path: "dashboard",
            element: <Dashboard />,
        },
        {
            id: 22,
            path: "DaynamicRoute",
            element: <ConditionMenuRoute />,
        },
        {
            id: 23,
            path: 'SalesStats',
            element: <SalesStats />,
        },
        {
            id: 23,
            path: 'RequestStats',
            element: <RequestsStats />,
        },
        {
            id: 22,
            path: "adminleaves",
            element: <AdminLeave />,
        },
        {
            id: 24,
            path: "holidays",
            element: <Holidays />
        },
        {
            id: 56,
            path: "leave-settings",
            element: <LeaveSettings />
        },
            {
            id: 22,
            path: "employee-leaves",
            element: <EmployeeLeave/>,
        },
        {
            id: 22,
            path: "employeeattendance",
            element: <AttendanceEmployee/>,
        },
        {
            id: 22,
            path: "SalesReport",
            element: <ClientReport />,
        },
        {
            id: 25,
            path: "payslips",
            element: <PayslipReport/>,
        },
        {
            id: 26,
            path: "reset-token",
            element: <ResetToken/>,
        },

    ];
    const filteredSuperAdminRoutes = [];

    loginUser?.menu?.forEach((menuItem) => {
        if (!menuItem.status) return;
        const cleanedMenuName = menuItem.name.replace(/^\//, "").toLowerCase();
        // Ensure the main menu item includes its respective list route
        if (cleanedMenuName === "employees") {
            filteredSuperAdminRoutes.push({
                id: 5,
                path: "employees-list",
                element: <EmployeeListFilter />
            });
        }
        if (cleanedMenuName === "clients") {
            filteredSuperAdminRoutes.push({
                id: 9,
                path: "clients-list",
                element: <ClientList />
            });
        }

        menuItem.Subfields?.forEach((subfield) => {
            if (!subfield.status) return; // Skip if status is false

            const cleanedSubfieldName = subfield.name.replace(/^\//, "").toLowerCase();

            console.log("Checking match:", subfield.name, "vs", superAdminRoutes.map(r => r.path));

            const matchedRoutes = superAdminRoutes?.filter(route =>
                route.path.toLowerCase() === cleanedSubfieldName ||
                route.path.toLowerCase().startsWith(`${cleanedSubfieldName}/`) ||
                route.path.toLowerCase() === "profile"
            );

            if (matchedRoutes.length > 0) {
                matchedRoutes.forEach(route => {
                    console.log("Matched Route Found:", route);
                    filteredSuperAdminRoutes.push(route);
                });
            }
        });
    });



filteredSuperAdminRoutes.push(
    // {
    //     id: 22,
    //     path: "LeaveDataBucket",
    //     element: < LeaveDataBucket/>,
    // },
    {   
        id:56,
        path:"leave-settings",
        element:<LeaveSettings/>
    },
    {
        id: 22,
        path: "attendence",
        element: <EmployeeLeave/>,
    },
    {
        id: 22,
        path: "profile",
        element: <Profile/>,
    },
        
   
    )
console.log("Final Filtered Routes:", filteredSuperAdminRoutes);


    const SidebarLayout = () => (

        

        <>
            {/* <Header isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/>
         <Sidebar isSidebarVisible={isSidebarVisible} setIsSidebarVisible={setIsSidebarVisible}/> */}
            <Header />
            <Sidebar />
            {/* <OffCanvas /> */}
            <Outlet />
        </>
    );

    const mobileResponsive = (event) => {
        const excludedHeader = document.querySelector(".header");
        const excludedSidebar = document.querySelector(".sidebar");

        if (
            !excludedHeader.contains(event.target) &&
            !excludedSidebar.contains(event.target)
        ) {
            document.body.classList.remove("slide-nav");
        }
    };

    const routes = filteredSuperAdminRoutes // role == 'User' ? userRoutes : role == 'Admin' ? AdminRoutes : superAdminRoutes;

    const dispatch = useDispatch();
    // const {data:AttendenceLastRecord}=useQuery(GET_LAST_ATTENDENCE_RECORD ,{
    //     variables: { id: loginUser?.id },
    //       fetchPolicy: "network-only",
    //         onCompleted(AttendenceLastRecord){
    //             console.log("AttendenceLastRecord",AttendenceLastRecord,AttendenceLastRecord?.LastAttendence.response.status)
    //             dispatch(setLastRecord(AttendenceLastRecord?.LastAttendence))
    //             if(AttendenceLastRecord?.LastAttendence.response.status)
    //             {
    //                     dispatch(setAttendence(AttendenceLastRecord?.LastAttendence))
    //             }
                              
                  
    //         }
    //     })
    // const { data, loading, error } = useQuery(GET_DYNAMIC_ROUTES, {
    //     onCompleted: (data) => {

    //         dispatch(setempRoute(data?.getSideMenu));
    //     },

    //     onError: (error) => {
    //         console.error("Error fetching data:", error);
    //     },
    // });
    const userStatus = useSelector((state) => state?.loginUserInfo?.userStatus)

    const userId = parseJwt(token)?.id;
    console.log("userId123",userId)
    // const { data: Data, loading: loadings, refetch } = useQuery(GET_FIELDS);
    // dispatch(setDataBucket(Data?.GetDynamicField));

    // const { data: clientData } = useQuery(GET_ALL_CLIENTS);
    // dispatch(setClients(clientData?.getAllClients?.response));

    // const [fetchAllUsers, { loading: Loading }] = useMutation(FETCH_ALL_USERS, {
    //     onCompleted: (data) => {
    //         if (data) {
  
    //             dispatch(SetAllEmployees(data.fetchAllUsers.response))
    //             dispatch(setAllUsers(data.fetchAllUsers.response));
    //             dispatch(setUserStatus(true))
    //         }
    //     },
    // });
    // dispatch(setLoader(Loading))


    if (!userStatus && token) {
        // getUserDetails({ variables: { id: userId } });
        dispatch(fetchUserDetails( userId));
        dispatch(fetchAllUsers());
    }
    // console.log(routes, "hello");


    return (
        <>
          {/* {loading ? ( */}
            {/* <Loader /> */}
        {/* // ) : routes.length === 0 ? ( */}
        {/* //      <Route path="*" element={<Error404 />} />         */}
        {/* ) : (  */}
            <Loader />
            <div className="main-wrapper" onClick={mobileResponsive}>
              <Routes>
                <Route path="/*" element={<SidebarLayout />}>
                  {routes.map((item) => (
                    <Route key={item.id} path={item.path} element={item.element} />
                  ))}
      
                  {routes.length > 0 && <Route path="*" element={<Error404 />} />}
                </Route>
              </Routes>
            </div>
        {/* //   )} */}
        </>
      );

}
export default AppContainer;
