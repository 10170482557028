import { useEffect, useState } from 'react';
import { Button, Row, Col, Table, Card } from 'antd';
import { useQuery } from '@apollo/client';
import CreateCurrentCarrier from './CreateCurrentCarrier';
import { GET_ALL_CARRIERS, GET_ALL_CURRENT_CARRIERS } from '../../../Queries/Users';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

const CurrentCarrier = () => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false); 
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const [rowInfo, setRowInfo] = useState({});
    const [clientReport,setClientReport] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);

    // const clientReport = useSelector((state)=>state.reports.clietReportOptions);
    // console.log("clientReport",clientReport)
    const {data,loading, refetch} = useQuery(GET_ALL_CARRIERS,{
        fetchPolicy: "no-cache",
    })
    // const { data, refetch, loading, error } = useQuery(GET_ALL_CURRENT_CARRIERS, {
    //     variables: { page: page, pageSize: pageSize },
    //     fetchPolicy: "cache-and-network"
    // });

    //const datacontent = data;

    useEffect(()=>{
        if(data){
            setClientReport(data.getAllCarriers.response)
            // dispatch(data.getAllCarriers.response)
        }
    },[data])

    const handleTableChange = (pagination) => {
        setPage(pagination);
        //console.log("pagination", pagination);
    };

    // Open form for creating a new carrier
    const handleOpen = () => {
        setRowInfo({});
        setOpen(true);
    };

    // Handle editing an existing carrier
    const handleEdit = (row) => {
        setRowInfo(row);
        setOpen(true);
    };

    // Close modal and refetch carriers
    const handleClose = (shouldRefetch = false) => {
        setOpen(false);
        // if(shouldRefetch){
        //     refetch();
        // }
    };

    // Toggle the dropdown open/close


    // Define table columns
    const columns = [
        {
            title: "Name",
            dataIndex: "carrierName",
            key: "carrierName",
            width: "33%",
        },
        {
            title: "Status",
            dataIndex: "status",
            width: "33%",
        },
        {
            title: "Is Current",
            dataIndex: "flag",
            render: (text) => (text === true ? "Yes" : "No"),
            width: "33%",
        },
        {
            title: "Action",
            key: "action",
            width: "33%",
            render: (text, row) => (
                <Button type="link" onClick={() => handleEdit(row)}>
                    Edit
                </Button>
            )
        }
    ];

    let currentCarriers = data?.getAllCarriers?.response  || [];
    console.log("currentCarriers",currentCarriers)

    return (
        <div >


            {/* Card wrapper for the content */}
            <Card bordered={false} className='card-responsive card-fill'>

                {/* Title and Create Button in the same row */}
                <Row className="m-2" align="middle" justify="space-between">
                    <Col xs={20} sm={20} md={20} lg={20}>
                        <h5
                            className='p-2' align="left" justify="space-between"
                            style={{ cursor: 'pointer' }}

                        >
                            Carriers
                        </h5>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={handleOpen}>
                            ADD
                        </Button>
                    </Col>
                </Row>

                {/* Conditionally render the table based on dropdown state */}
                {dropdownOpen && (
                    <div className="row">
                        <div className="col-24">
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={clientReport || []}
                                    pagination={{
                                        page: page,
                                        pageSize: pageSize,
                                        total: data?.count || 0,
                                        onChange: handleTableChange,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        showQuickJumper: false,
                                        showSizeChanger: false
                                    }}
                                    loading={loading}
                                    rowKey="id"
                                    
                                    rowClassName={() => "single-row-color"}
                                    style={{ backgroundColor: '#f9f9f9', marginTop: '20px' }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for creating/updating a carrier */}
                {open && (
                    <CreateCurrentCarrier
                        open={open}
                        handleClose={handleClose}
                        data={rowInfo}
                        refetch={refetch}
                    />
                )}
            </Card>

            <style jsx>{`
    .ant-pagination-total-text {
        color: #333333;
    }
`}</style>
        </div>

    );
};

export default CurrentCarrier;
