import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import { Button, Row, Col } from "antd"; // Import Ant Design components
import moment from "moment"; // Import moment.js for date formatting

import { Link } from "react-router-dom";
//import { Button } from "antd";
import { useDispatch } from "react-redux";
import { useLazyQuery } from "@apollo/client";
import { GET_LEAVE_REQUESTS } from "../Leaves/Leaves";
import { getAdminLeaves } from "../Slices/adminLeaveSlice";
import { useSelector } from "react-redux";
import { GET_ADMIN_LEAVES } from "../Queries/leaves";

const LeaveFilter = () => {
  const [employeeName, setEmployeeName] = useState("");
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState("");
  const today = new Date().toISOString().split("T")[0];
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const maxFromDate = twoMonthsAgo.toISOString().split("T")[0];
  const [selectedDate, setSelectedDate] = useState(today);
  console.log("selectedDate", selectedDate)
  const [selectedDateTwo, setSelectedDateTwo] = useState(today);

  const Employee = useSelector((state) => state.loginUserInfo.allUsers);
  const activeEmployees = Employee.filter((emp) => emp.employeestatus === "Active");

  console.log("Active Employees", activeEmployees);

  const dispatch = useDispatch();

  const [fetchLeaveRequests, { data, loading, error }] = useLazyQuery(
    GET_ADMIN_LEAVES,
    {
      onCompleted: (data) => {
        dispatch(getAdminLeaves(data.getLeaveRequests));
      },
    }
  );

  const leaveOptions = [
    { value: "", label: "Select" },
    { value: "Casual Leave", label: "Casual Leave" },
    { value: "Sick Leave", label: "Sick Leave" },
    { value: "Paid Leave", label: "Paid Leave" },
  ];

  const leaveStatusOptions = [
    { value: "", label: "Select" },
    { value: "Approved", label: "Approved" },
    { value: "Pending", label: "Pending" },
    { value: "Declined", label: "Declined" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters

    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }

    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)

    e.target.value = value;
  };

  const handleSearch = () => {
    // Prepare the variables object for the query
    const variables = {};
    if (employeeName) variables.employeeId = employeeName;
    if (selectedLeaveType) variables.leaveType = selectedLeaveType.value;
    if (selectedLeaveStatus) variables.leaveStatus = selectedLeaveStatus.value;
    if (selectedDate)
      variables.fromDate = selectedDate;
    if (selectedDate) {
      const dateTwo = new Date(selectedDate);
      //dateTwo.setDate(dateTwo.getDate() + 1); 
      variables.fromDate = dateTwo.toISOString().split('T')[0];
    }
    // Ensure date format is YYYY-MM-DD
    if (selectedDateTwo)
      variables.toDate = selectedDateTwo;
    if (selectedDateTwo) {
      const dateTwo = new Date(selectedDateTwo); // Convert to Date object
      //dateTwo.setDate(dateTwo.getDate() + 1); // Add one day
      variables.toDate = dateTwo.toISOString().split('T')[0]; // Convert back to string in YYYY-MM-DD format
    }
    console.log("variables", variables)
    fetchLeaveRequests({ variables });
  };

  const handleClear = () => {
    // Clear all the filter state
    setEmployeeName('');
    setSelectedLeaveType('');
    setSelectedLeaveStatus('');
    setSelectedDate(today);
    setSelectedDateTwo(today);

    fetchLeaveRequests({ variables: {} });
  };
  console.log("employeeName", employeeName)
  return (
    <Row gutter={16} className="filter-row">
      {/* Employee Name */}
      <Col span={4}>
        <label className="focus-label">Employee Name</label>
        <select
          className="form-control floating"
          value={employeeName}
          onChange={(e) => setEmployeeName(e.target.value)}  // Update the selected userId
        >
          <option value="">Select Employee</option>
          {activeEmployees.map((employee) => (
            <option key={employee.id} value={employee.id}>
              {employee.firstname} {employee.lastname}
            </option>
          ))}
        </select>

      </Col>

      {/* Leave Type */}
      <Col span={4}>
        <label className="focus-label">Leave Type</label>
        <Select
          options={leaveOptions}
          value={selectedLeaveType} // Bind to state
          onChange={setSelectedLeaveType} // Handle selection change
          styles={customStyles}
        />
      </Col>

      {/* Leave Status */}
      <Col span={4}>
        <label className="focus-label">Leave Status</label>
        <Select
          options={leaveStatusOptions}
          value={selectedLeaveStatus}
          onChange={setSelectedLeaveStatus}
          styles={customStyles}
        />
      </Col>

      {/* From Date */}
      <Col span={4}>
        <label className="focus-label">From</label>
        <DatePicker
          max={today}
          min={maxFromDate}
          className="form-control floating datetimepicker"
          selected={selectedDate}
          onChange={(date) => setSelectedDate(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="From Date"
          onKeyDown={handleKeyDown}
        />
      </Col>

      {/* To Date */}
      <Col span={4}>
        <label className="focus-label">To</label>
        <DatePicker
          max={today}
          min={maxFromDate}
          className="form-control floating datetimepicker"
          selected={selectedDateTwo}
          onChange={(date) => setSelectedDateTwo(date)}
          dateFormat="dd/MM/yyyy"
          placeholderText="To Date"
          onKeyDown={handleKeyDown}
        />
      </Col>

      {/* Search and Clear Buttons */}
      <Col span={4}>
        <div className="d-flex justify-content-between mt-3">
          <Button className="btn btn-success" onClick={handleSearch}>
            Search
          </Button>
          <Button className="btn btn-secondary"
            onClick={handleClear} // Clear functionality
          >
            Clear
          </Button>
        </div>
      </Col>
    </Row>
  );
};

export default LeaveFilter;
