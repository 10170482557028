// import { createSlice } from '@reduxjs/toolkit';
// import { client } from '..';
// import { GET_FIELDS } from '../Queries/options';
// // Initial state
// const initialState = {
//   databucket: [],
// };

// // Slice
// const DataBucket = createSlice({
//   name: 'DataBucket',
//   initialState,
//   reducers: {
//     // Set the databucket with new data
//     setDataBucket(state, action) {
//         console.log("eqfewfewffew",action.payload);
//       state.databucket = action.payload;
//       console.log("fvfvf",state.databucket)
//     },
//     // Set a new object in the databucket
//     setNewObj(state, action) {
//       const  newData  = action.payload?.data?.CreateDynamicField?.response;
//       console.log("sfsvsv",newData)
//           state.databucket=[newData , ...state.databucket]
//           // console.log("fvfvf",state.databucket)
//         },
//     // Update a field in the databucket
//     updateField(state, action) {
//       const { id, objId, newData } = action.payload;
//       if (objId) {
//         // Update specific data in the array
//         const field = state.databucket.find(item => item._id === id);
//         if (field) {
//           const element = field.data.find(item => item._id === objId);
//           if (element) {
//             element.status = newData.status;
//             element.name = newData.name;
//           }
//         }
//       } else {
//         // If objId is not provided, create a new object with status true
//         const field = state.databucket.find(item => item._id === id);
//         if (field) {
//           field.data.push({ ...newData, status: true });
//         }
//       }
//     },
//   },
// });

// export const { setDataBucket, setNewObj, updateField } = DataBucket.actions;
// export default DataBucket.reducer;


import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { client } from '..'; // Import your Apollo Client instance
import { GET_FIELDS } from '../Queries/options'; // Your GraphQL query

// Initial state
const initialState = {
  databucket: [],
  loading: false,
  error: null,
};

// Async Thunk to fetch data using GraphQL
export const fetchData = createAsyncThunk(
  'dataBucket/fetchData', // This is the name of the thunk action
  async (_, { rejectWithValue }) => {
    try {
      // Apollo Client GraphQL query
      const { data } = await client.query({
        query: GET_FIELDS, // Your GraphQL query
      });
      
      return data.GetDynamicField; // Return the fetched data
    } catch (error) {
      // If an error occurs, pass the error message to rejectWithValue
      return rejectWithValue(error.message);
    }
  }
);

// Slice
const DataBucket = createSlice({
  name: 'DataBucket',
  initialState,
  reducers: {
    // Set the databucket with new data
    setDataBucket(state, action) {
      console.log('Data received:', action.payload);
      state.databucket = action.payload;
    },
    // Set a new object in the databucket
    setNewObj(state, action) {
      const newData = action.payload?.data?.CreateDynamicField?.response;
      console.log('New object added:', newData);
      state.databucket = [newData, ...state.databucket];
    },
    // Update a field in the databucket
    updateField(state, action) {
      const { id, objId, newData } = action.payload;
      if (objId) {
        const field = state.databucket.find(item => item._id === id);
        if (field) {
          const element = field.data.find(item => item._id === objId);
          if (element) {
            element.status = newData.status;
            element.name = newData.name;
          }
        }
      } else {
        const field = state.databucket.find(item => item._id === id);
        if (field) {
          field.data.push({ ...newData, status: true });
        }
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchData.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchData.fulfilled, (state, action) => {
        state.loading = false;
        state.databucket = action.payload; // Store the fetched data in databucket
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload; // Store any error message
      });
  },
});

export const { setDataBucket, setNewObj, updateField } = DataBucket.actions;
export default DataBucket.reducer;
