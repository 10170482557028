import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Tooltip } from 'antd';
import AddCommentBox from './CommentBox';
import SalesInformationPopup from '../../../components/modelpopup/SalesInformationPopup';

const renderWithTooltip = (text, maxLength) => {
  // Check if text is valid and not null or undefined
  if (!text) return null;

  return (
    <Tooltip title={text}>
      {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
    </Tooltip>
  );
};

const OTCTrack = ({ otcTrackInfo }) => {
 
  return (
    <>
    <div className="d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            OTC Tracking{" "}
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#otc_tracking_modal"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Call Type</th>
                  <th>Emails</th>
                  <th>Phone Number</th>
                  <th>Call Scheduled By</th>
                  <th>Remarks</th>
                  <th>Call Connected</th>
                  <th>Client Attendees</th>
                  <th>BluePlanit Attendees</th>
                  <th>AMS Used by Client</th>
                  <th>Client Requirements</th>
                  <th>Pros</th>
                  <th>Cons</th>
                  <th>Email Sent</th>
                </tr>
              </thead>
              <tbody>
                {otcTrackInfo?.map((item, index) => (
                  <tr key={index}>
                   <td>{item?.date ? dayjs(Number(item.date)).format('DD/MM/YYYY') : 'N/A'}</td>
                   <td>{item?.time ? dayjs(item.time, 'HH:mm:ss').format('hh:mm A') : 'N/A'}</td>
                    <td>{item?.callType || 'N/A'}</td>
                    <td>{renderWithTooltip(item?.emails, 10)}</td>
                    <td>{renderWithTooltip(item?.phonenumber, 10)}</td>
                    <td>{renderWithTooltip(item?.callScheduledBy, 15)}</td>
                    <td>{item?.remarks || 'N/A'}</td>
                    <td>{item?.callConnected ? 'Yes' : 'No'}</td>
                    <td>{item?.clientAttendees || 'N/A'}</td>
                    <td>{item?.blueplanitAttendees || 'N/A'}</td>
                    <td>{item?.amsusedbyClient || 'N/A'}</td>
                    <td>{item?.clientRequirements || 'N/A'}</td>
                    <td>{item?.pros || 'N/A'}</td>
                    <td>{item?.cons || 'N/A'}</td>
                    <td>{item?.emailSent ? 'Sent' : 'Not Sent'}</td>
                  </tr>
                
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddCommentBox name={'opsTeamComments'}/>
     <SalesInformationPopup  name={'opsTeamComments'}/>
    </>
    
  );
};

export default OTCTrack;
