import React from "react";
import { Button } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";

const Error404 = () => {
  const navigate = useNavigate();
  const location = useLocation(); 

  const handleBackToHome = () => {
    // const baseRoute = location.pathname.replace("-list", ""); 
    navigate(-1); 
  };

  return (
    <>
      {/* Main Wrapper */}
      <div className="error-page">
        <div className="main-wrapper">
          <div className="error-box">
            <h1>Oops!</h1>
            <h4>
              <i className="fa-solid fa-triangle-exclamation" /> 404-PAGE NOT FOUND
            </h4>
            <p>The page you requested was not found.</p>
            <button onClick={handleBackToHome} className="btn btn-custom">
              Back to Home
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Error404;
