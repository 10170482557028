import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Row, Col, Space, AutoComplete, DatePicker, Divider, } from 'antd';
import { FilterOutlined, PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_LEADS, LEADS_FILTERS, CREATE_LEAD_FILTER, GET_ALL_LEAD_FILTERS_BY_ID } from '../../../Queries/Leads';
import dayjs from 'dayjs';
import { setDivVisibility, setleadfilterObjectt, setleadfilterObjectTempp, setSortObjectt, setFilterSearchh } from "../../../Slices/salesSlice";
import { setIsRefetch, setSelectedFilterName, setOpen, setPage, setPageCount, setRefreshStatus, getAllSales, updateSales, setTodayStats, setMonthlyStats, setFollowupStats, setTotalStats, setSavedFilters, } from "../../../Slices/salesSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import { message } from "antd";
import SearchInput from './SearchInput';
import './Frames.scss'
const SalesFilters = (props) => {

  // const { isRefetch, setIsRefetch } = props;
  const isRefetch = useSelector((state) => state.sales.isRefetch);
  const [isDisabled, setIsDisabled] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [saveDisable, setSaveDisable] = useState(true);
  const dispatch = useDispatch();

  const [filterSno, setFilterSno] = useState('');
  const [filterAgencyName, setFilterAgencyName] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterCity, setFilterCity] = useState([]);
  const [filterChannel, setFilterChannel] = useState([]);
  const [filterAssignedTo, setFilterAssignedTo] = useState([]);
  const [filterTimeZone, setFilterTimeZone] = useState([]);
  const [filterFollowup, setFilterFollowup] = useState([]);
  const [filterSuccessfull, setFilterSuccessfull] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterPriority, setFilterPriority] = useState([]);
  const [filterStage, setFilterStage] = useState([]);
  const [filterScheduledOPSStatus, setFilterScheduledOPSStatus] = useState([]);

  const leadfilterObject = useSelector((state) => state.sales.leadfilterObject)
  const [leadfilterObjectTemp, setleadfilterObjectTemp] = useState({});
  const sortObject = useSelector((state) => state.sales.sortObject)

  const refreshStatus = useSelector((state) => state.sales.refreshStatus)
  const page = useSelector((state) => state.sales.page)
  const open = useSelector((state) => state.sales.open)
  const { data: leadsData, refetch: Refetch, loading, error } = useQuery(GET_ALL_LEADS, {
    variables: { page, pageSize: 50, filters: leadfilterObject, sort: sortObject },
    fetchPolicy: 'no-cache', skip: refreshStatus,
  });
  dispatch(setLoader(loading))
  const datacontent = leadsData ? leadsData.getAllLeads : [];


  const { data: filterdata, refetch } = useQuery(LEADS_FILTERS, {
    variables: { filters: leadfilterObject },
    fetchPolicy: 'network-only', // Ensure the server is queried each time
  });

  let leadfilterdata = filterdata ? filterdata.getLeadFilters : [];

  useEffect(() => {
    if (leadfilterdata?.responsestatus == 200) {
      const filterAgencyName = leadfilterdata?.agencyName?.filter(item => item?.trim() !== "");
      const filterState = leadfilterdata?.state?.filter(item => item?.trim() !== "");
      const filterCity = leadfilterdata?.city?.filter(item => item?.trim() !== "");
      const filterChannel = leadfilterdata?.channel?.filter(item => item?.trim() !== "");
      const filterAssignedTo = leadfilterdata?.assignedTo?.filter(item => item?.trim() !== "");
      const filterTimeZone = leadfilterdata?.timeZone?.filter(item => item.trim() !== "");
      const filterFollowup = leadfilterdata?.followup?.filter(item => item?.trim() !== "");
      const filterSuccessfull = leadfilterdata?.successfull?.filter(item => item?.trim() !== "");
      const filterStatus = leadfilterdata?.status?.filter(item => item?.trim() !== "");
      const filterPriority = leadfilterdata?.priority?.filter(item => item?.trim() !== "");
      const filterStage = leadfilterdata?.stage?.filter(item => item?.trim() !== "");
      const filterScheduledOPSStatus = leadfilterdata?.callscheduledwithOPs?.filter(item => item?.trim() !== "");
      setFilterAgencyName(filterAgencyName)
      setFilterState(filterState)
      setFilterCity(filterCity)
      setFilterSno(leadfilterdata?.leadID)
      setFilterChannel(filterChannel)
      setFilterTimeZone(filterTimeZone)
      setFilterAssignedTo(filterAssignedTo)
      setFilterFollowup(filterFollowup)
      setFilterSuccessfull(filterSuccessfull)
      setFilterStatus(filterStatus)
      setFilterPriority(filterPriority)
      setFilterStage(filterStage)
      setFilterScheduledOPSStatus(filterScheduledOPSStatus)
      // setFilterCity(filterdata?.city?.map((str, index) => ({ value: str })))
    }

  }, [leadfilterdata]);

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const userid = parseJwt(token)?.id;

  const fetchData = () => {

    if (datacontent?.response) {
      dispatch(setPageCount(datacontent?.count))
      dispatch(getAllSales(datacontent?.response))
      dispatch(setTodayStats(datacontent?.stats?.todayStats))
      dispatch(setMonthlyStats(datacontent?.stats?.monthStats))
      dispatch(setFollowupStats(datacontent?.stats?.followUpsStats))
      dispatch(setTotalStats(datacontent?.stats?.totalStats))
    }
  };

  useEffect(() => {
    fetchData()

  }, [datacontent?.response]);

  useEffect(() => {
    if (Object.keys(leadfilterObjectTemp).length === 0 || Object.values(leadfilterObjectTemp).every(value => value === undefined || value === '')) {
      setSaveDisable(true)
    }
  }, [leadfilterObjectTemp])


  const selectedFilterName = useSelector((state) => state.sales.selectedFilterName)
  const [saveFilterCheck, setSaveFilterCheck] = useState(false);
  const [filterName, setFilterName] = useState(false);

  const prevVisibility = useSelector((state) => state.sales.prevVisibility)
  const handleFilters = (event) => {
    dispatch(setDivVisibility(!prevVisibility));
    if (!prevVisibility && leadfilterObject) {
      setleadfilterObjectTemp(leadfilterObject);  // Store current filter object in temp
    } else if (!prevVisibility && leadfilterObjectTemp) {
      setleadfilterObjectTemp(leadfilterObjectTemp);
    }
    setSaveFilterCheck(false)
    setFilterName('')
  };

  const resetFilters = () => {
    dispatch(setRefreshStatus(null))
    dispatch(setleadfilterObjectt({}))
    setleadfilterObjectTemp({})
    dispatch(setSelectedFilterName({}))
    // setFilterSearch('')
    setSaveFilterCheck(false)
    dispatch(setPage(1));

  }

  const handleCloseFilter = () => {
    dispatch(setDivVisibility(false))
    setleadfilterObjectTemp(leadfilterObject)
  }

  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters

    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }

    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)

    e.target.value = value;
  };

  useEffect(() => {
    if (isRefetch) {
      refetch();
      Refetch();
      resetFilters()
    }
    setleadfilterObjectTemp(leadfilterObject);
    dispatch(setIsRefetch(false));
  }, [isRefetch]);

  const loadAgencyNames = (event, newValue) => {
    if (event?.target?.value.length > 2) {
      let AllAgencyNames = leadfilterdata?.agencyName
      const searchedAgencyNames = AllAgencyNames.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterAgencyName(searchedAgencyNames)
    } else {
      setFilterAgencyName(leadfilterdata?.agencyName?.filter(item => item?.trim() !== "") || []);
    }
  };
  const loadStates = (event, newValue) => {

    if (event?.target?.value.length > 2) {
      let AllStates = leadfilterdata?.state
      const searchedStates = AllStates.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterState(searchedStates)
    } else {
      setFilterState(leadfilterdata?.state?.filter(item => item?.trim() !== "") || [])
    }
  };
  const loadCities = (event, newValue) => {
    if (event?.target?.value.length > 2) {
      let AllCities = leadfilterdata?.city
      const searchedCities = AllCities.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterCity(searchedCities)
    } else {
      setFilterCity(leadfilterdata?.city?.filter(item => item?.trim() !== "") || [])
    }
  };

  const handleSavedFiltersChange = (filterName) => {
    // Find the selected filter object based on filterName
    dispatch(setRefreshStatus(null))
    const selectedFilter = allFilters.find((filter) => filter.filterName === filterName);

    if (selectedFilter) {
      try {
        dispatch(setleadfilterObjectt(JSON.parse(selectedFilter.filterString)))
        dispatch(setSelectedFilterName(selectedFilter));  // Set the selected filter object
        setleadfilterObjectTemp(JSON.parse(selectedFilter.filterString))

      } catch (error) {
        console.error("Failed to parse filterString:", error);
      }
    } else {
      dispatch(setleadfilterObjectt({}))
      dispatch(setSelectedFilterName({}));
    }

    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleClear = () => {
    dispatch(setleadfilterObjectt({}));
    setleadfilterObjectTemp({})
    dispatch(setSelectedFilterName({}))
    setSaveFilterCheck(false)
  };

  const handleFilterSnoChange = (event, newValue) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, leadID: event.target.value }));
    setSaveFilterCheck(false)
    setFilterName('')

  };

  const handleFilterAgencyChange = (value) => {

    setleadfilterObjectTemp((prvState) => ({ ...prvState, agencyName: value }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterStateChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, state: value }));
    // setFilterState([])
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterCityChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, city: value }));
    // setFilterCity([])
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterChannelChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, channel: value }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterAssignedToChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, assignedTo: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterTimezoneChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, timeZone: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  const handleFilterFollowUpChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, followup: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterSuccessfullChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, successfull: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterStageChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, stage: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterOPSCallChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, callscheduledwithOPs: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterStatusChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, status: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  const handleFilterPriorityChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, priority: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };

  //updated
  const handleFilterLastUpdatedFromChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastUpdatedFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastUpdatedToChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastUpdatedTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  //contacted
  const handleFilterLastContactedFromChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastContactedFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastContactedToChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastContactedTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  //followup
  const handleFilterLastFollowupFromChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastFollowupFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastFollowupToChange = (date) => {
    const formattedDate = date ? dayjs(date).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastFollowupTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };

  const setFilters = () => {
    dispatch(setRefreshStatus(null))
    if (
      !leadfilterObjectTemp ||
      Object.keys(leadfilterObjectTemp).length === 0 ||
      Object.values(leadfilterObjectTemp).every(value => value === undefined || value === '')
    ) {
      startTimer();
      message.error('Please enter filter criteria before applying.');
      return; // Exit if no criteria are provided
    }
    dispatch(setDivVisibility(false));
    dispatch(setPage(1));
    dispatch(setleadfilterObjectt(leadfilterObjectTemp))
    setSaveDisable(false)
  };

  const userID = parseJwt(token)?.id;
  const { data } = useQuery(GET_ALL_LEAD_FILTERS_BY_ID, {
    variables: { userID },
    onCompleted(data) {
      console.log("data", data)
      if (data?.getAllLeadFilterByID) {
        dispatch(setSavedFilters(data.getAllLeadFilterByID));
      }
    },
  });

  const existingFilters = useSelector((state) => state?.sales?.savedFilters);

  const [createLeadFilters] = useMutation(CREATE_LEAD_FILTER, {
    onCompleted(data) {
      if (data?.createLeadFilters) {
        const newFilter = data.createLeadFilters;
        dispatch(setSavedFilters([...existingFilters, newFilter]))
      }
    },
  });
  const allFilters = useSelector((state) => state?.sales?.savedFilters);

  const saveFilterCheckHandler = () => {
    // Check if leadfilterObject is empty
    if (!leadfilterObject || Object.keys(leadfilterObject).length === 0 || Object.values(leadfilterObjectTemp).every(value => value === undefined || value === '')) {
      // startSaveTimer();
      message.error('Please enter filter criteria before saving.');
      return; // Exit the function if the object is empty
    }
    setSaveFilterCheck(!saveFilterCheck)
    setFilterName('')
  }

  const handlefilterNameChange = (e) => {
    setFilterName(e.target.value)
  }
  const handleSaveFilter = () => {

    const filterObject = {
      userID: parseJwt(token)?.id,
      filterName: filterName,
      filterString: JSON.stringify(leadfilterObject),
      filterStatus: true,
    };

    // Check if filterName is also provided
    if (!filterName) {
      startBtnTimer();
      message.error('Please enter a name for the filter.');
      return;
    }

    // Check for duplicate filter name in allFilters
    const isDuplicate = allFilters.some(
      (filter) => filter.filterName.toLowerCase() === filterName.toLowerCase()
    );
    if (isDuplicate) {
      startBtnTimer();
      message.error({ content: "A filter with the same name already exists. Please choose a different name." });
      return;
    }

    createLeadFilters({ variables: filterObject });
    message.success({
      content: "Filter saved successfully!",
      duration: 5,
      className: 'custom-toast-center',
    });

    setFilterName('');
  };

  useEffect(() => {
    let salesSort = sessionStorage.getItem('salesSort');
    if (salesSort) {
      salesSort = JSON.parse(salesSort);
      dispatch(setSortObjectt(salesSort));

      sessionStorage.removeItem('salesSort');
    }
  }, [dispatch]);
  useEffect(() => {
    try {
      let salesFilter = sessionStorage.getItem('salesFilter');
      if (salesFilter) {
        salesFilter = JSON.parse(salesFilter);
        dispatch(setleadfilterObjectt(salesFilter));
        setleadfilterObjectTemp(salesFilter);
        sessionStorage.removeItem('salesFilter');
      }
    } catch (error) {
      console.error('Failed to parse salesFilter from sessionStorage:', error);
    }
  }, [dispatch]);


  const handleOpen = () => {
    dispatch(setOpen(true));
    // sessionStorage.setItem('salesFilter', JSON.stringify(leadfilterObject));
    // sessionStorage.setItem('salesSort', JSON.stringify(sortObject));
  };

  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != "") {
      if (typeof datevalue === "string" && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue));
      } else if (typeof datevalue === "object") {
        finalDate = datevalue;
      }
    }
    return finalDate;
  };

  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 3000);
  };

  const startBtnTimer = () => {
    setDisabled(true); // Disable the button
    setTimeout(() => {
      setDisabled(false); // Enable the button after 5 seconds
    }, 3000);
  };

  // const startSaveTimer = () => {
  //   setSaveDisable(true); // Disable the button
  //   setTimeout(() => {
  //     setSaveDisable(false); // Enable the button after 5 seconds
  //   }, 3000);
  // };

  const [Error, setError] = useState("");


  console.log("leadfilterObjectTemp", leadfilterObjectTemp)
  return (
    <div>
      <Form
        layout="inline"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          gap: '0px',
          // marginTop: '15px',
          marginBottom: '20px',
        }}
      >
        <SearchInput />
        <Form.Item>
          <Select
            value={selectedFilterName?.filterName || undefined}  // Use filterName for the value
            onChange={handleSavedFiltersChange}
            style={{ backgroundColor: '#0000000a', width: 200 }}
            placeholder="Saved Filters"
            showSearch
            allowClear
            onClear={handleClear}
          >
            {allFilters?.map((option) => (
              <Select.Option key={option?.id} value={option.filterName}>
                {option.filterName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button color='primary' variant='solid'
            // type="primary"
            style={{ borderRadius: '20px', color: 'white' }}
            icon={<FilterOutlined />}
            onClick={handleFilters}
          >
            Filter
          </Button>
        </Form.Item>
        <Form.Item>
          <Button color='primary' variant='solid'
            // type="primary"
            style={{
              // backgroundColor: "#007bff",
              borderRadius: "20px",
              color: "white",
            }}
            icon={<PlusOutlined />} // Add a "+" icon
            onClick={handleOpen}
          >
            Create Sale
          </Button>
        </Form.Item>

      </Form>

      {prevVisibility && (
        <div>
          <div
            className="sales-card-container"
          >
            <button
              onClick={handleCloseFilter}
              style={{
                background: "none",
                border: "none",
                fontSize: "22px",
                color: "black",
                position: "absolute",
                top: "-5px",
                right: "-20px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
            <Form layout="vertical" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Row className="custom-scrollbar" gutter={[24, 0]} style={{ marginTop: '10px', height: '73vh', overflow: 'auto', flex: 1, marginTop: '20px', marginBottom: '15px' }}>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="S.NO">
                    <Input allowClear placeholder="Enter S.no" value={leadfilterObjectTemp?.leadID} onChange={handleFilterSnoChange} />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Agency Name">
                    <AutoComplete
                      popupClassName="custom-dropdown"
                      value={leadfilterObjectTemp?.agencyName || ''}
                      onChange={handleFilterAgencyChange}
                      options={filterAgencyName?.map(name => ({ label: name, value: name }))}
                      onSearch={loadAgencyNames}
                      placeholder="Search Agency"
                      allowClear
                      style={{ width: '100%' }}
                    >
                      <Input
                        // style={{ backgroundColor: leadfilterObjectTemp.agencyName ? '#d0e8ff' : '' }} 
                        onChange={loadAgencyNames}
                      />
                    </AutoComplete>
                  </Form.Item>
                  {/* <h1>hii</h1> */}
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="State">
                    <AutoComplete
                      value={leadfilterObjectTemp?.state || ''}
                      options={filterState?.map(state => ({ label: state, value: state }))}
                      onChange={handleFilterStateChange}
                      onSearch={loadStates}
                      placeholder="Search State"
                      allowClear
                      style={{ width: '100%' }}
                    >
                      <Input onChange={loadStates}
                      // style={{ backgroundColor: leadfilterObjectTemp?.state ? '#d0e8ff' : '' }} 
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="City">
                    <AutoComplete
                      value={leadfilterObjectTemp?.city || ''}
                      options={filterCity?.map(city => ({ label: city, value: city }))}
                      onChange={handleFilterCityChange}
                      onSearch={loadCities}
                      placeholder="Search City"
                      allowClear
                      style={{ width: '100%' }}
                    >
                      <Input onChange={loadCities}
                      // style={{ backgroundColor: leadfilterObjectTemp?.city ? '#d0e8ff' : '' }} 
                      />
                    </AutoComplete>
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Channel">
                    <Select
                      allowClear
                      placeholder="Select Channel"
                      value={leadfilterObjectTemp?.channel} // Set selected value
                      onChange={handleFilterChannelChange} // Handle change event
                      options={filterChannel?.map(channel => ({ label: channel, value: channel }))} // Map options
                      style={{ backgroundColor: leadfilterObjectTemp?.channel ? '#d0e8ff' : '' }}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Time Zone">
                    <Select
                      allowClear
                      placeholder="Select Time Zone"
                      value={leadfilterObjectTemp?.timeZone} // Change
                      onChange={handleFilterTimezoneChange}
                      options={filterTimeZone?.map(zone => ({ label: zone, value: zone }))}
                    // style={{
                    //   backgroundColor: leadfilterObjectTemp?.timeZone ? '#d0e8ff' : 'inherit',
                    // }}
                    />
                  </Form.Item>
                </Col>


                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Assigned To">
                    <Select
                      allowClear
                      placeholder="Select Assigned to"
                      value={leadfilterObjectTemp?.assignedTo}
                      onChange={handleFilterAssignedToChange}
                      options={filterAssignedTo?.map(person => ({ label: person, value: person }))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Call with OPS Status">
                    <Select
                      allowClear
                      placeholder="Select Call with OPS"
                      value={leadfilterObjectTemp?.callscheduledwithOPs}
                      onChange={handleFilterOPSCallChange}
                      options={filterScheduledOPSStatus?.map(status => ({ label: status, value: status }))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Follow Up">
                    <Select
                      allowClear
                      placeholder="Select Follow Up"
                      value={leadfilterObjectTemp?.followup}
                      onChange={handleFilterFollowUpChange}
                      options={filterFollowup?.map(followup => ({ label: followup, value: followup }))}
                    />
                  </Form.Item>
                </Col>


                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Lead Successful">
                    <Select
                      allowClear
                      placeholder="Select Successful"
                      value={leadfilterObjectTemp?.successfull}
                      onChange={handleFilterSuccessfullChange}
                      options={filterSuccessfull?.map(success => ({ label: success, value: success }))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Lead Stage">
                    <Select
                      allowClear
                      placeholder="Select Stage"
                      value={leadfilterObjectTemp?.stage}
                      onChange={handleFilterStageChange}
                      options={filterStage?.map(stage => ({ label: stage, value: stage }))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Lead Status">
                    <Select
                      allowClear
                      placeholder="Select Status"
                      value={leadfilterObjectTemp?.status}
                      onChange={handleFilterStatusChange}
                      options={filterStatus?.map(status => ({ label: status, value: status }))}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Priority">
                    <Select
                      allowClear
                      placeholder="Select Priority"
                      value={leadfilterObjectTemp?.priority}
                      onChange={handleFilterPriorityChange}
                      options={filterPriority?.map(priority => ({ label: priority, value: priority }))}
                    />
                  </Form.Item>
                </Col>

                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item
                    label="Last Updated From"
                  // help={Error}
                  // validateStatus={Error ? 'error' : ''}
                  >
                    <DatePicker
                      value={leadfilterObjectTemp.lastUpdatedFrom ? dayjs(leadfilterObjectTemp.lastUpdatedFrom) : null}
                      placeholder="DD/MM/YYYY"
                      onChange={handleFilterLastUpdatedFromChange}
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Last Updated To">
                    <DatePicker
                      value={leadfilterObjectTemp.lastUpdatedTo ? dayjs(leadfilterObjectTemp.lastUpdatedTo) : null}
                      placeholder="DD/MM/YYYY"
                      onChange={handleFilterLastUpdatedToChange}
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Last Contacted From">
                    <DatePicker
                      value={leadfilterObjectTemp.lastContactedFrom ? dayjs(leadfilterObjectTemp.lastContactedFrom) : null}
                      placeholder="DD/MM/YYYY"
                      onChange={handleFilterLastContactedFromChange}
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />

                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Last Contacted To">
                    <DatePicker
                      value={leadfilterObjectTemp.lastContactedTo ? dayjs(leadfilterObjectTemp.lastContactedTo) : null}
                      placeholder="DD/MM/YYYY"
                      onChange={handleFilterLastContactedToChange}
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Follow-up From">
                    <DatePicker
                      value={leadfilterObjectTemp.lastFollowupFrom ? dayjs(leadfilterObjectTemp.lastFollowupFrom) : null}
                      placeholder="DD/MM/YYYY" onChange={handleFilterLastFollowupFromChange} style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Col>
                <Col style={{ marginBottom: '-10px' }} span={24}>
                  <Form.Item label="Follow-up To">
                    <DatePicker
                      value={leadfilterObjectTemp.lastFollowupTo ? dayjs(leadfilterObjectTemp.lastFollowupTo) : null} // Bind value
                      placeholder="DD/MM/YYYY" onChange={handleFilterLastFollowupToChange} style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      onKeyDown={handleKeyDown}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row style={{ marginTop: 'auto' }}>
                <Col span={24}>
                  <div className="d-flex justify-content-center" style={{ gap: '5px' }}>
                    <Button
                      type="default"
                      style={{ backgroundColor: '#837F7F', borderRadius: '20px', color: 'white', }}
                      icon={<ReloadOutlined />}
                      onClick={resetFilters}
                    >
                      Reset
                    </Button>
                    <Button color='primary' variant='solid' disabled={isDisabled}
                      // type="primary"
                      style={{ borderRadius: '20px', }}
                      onClick={setFilters}
                    >
                      Apply
                    </Button>
                    <Button color='primary' variant='solid' disabled={saveDisable}
                      // type="primary"
                      style={{
                        // backgroundColor: '#00c5fb',
                        color: 'white',
                        borderRadius: '20px',
                      }}
                      onClick={saveFilterCheckHandler}
                    >
                      Save
                    </Button>
                  </div>
                </Col>
              </Row>

              {saveFilterCheck && (
                <Row justify="center" style={{ textAlign: 'center' }}>
                  <Col span={24}>
                    <Divider style={{ borderColor: '#1890ff' }} />
                  </Col>
                  <Col span={24}>
                    {/* <Row gutter={8} align="middle"> */}
                    <Col flex="auto" span={24}>
                      <Input
                        name="filterName"
                        id="filterName"
                        value={filterName}
                        onChange={handlefilterNameChange}
                        autoFocus={!saveFilterCheck}
                        placeholder="Filter Name"
                        disabled={!saveFilterCheck}
                      />
                    </Col>
                    <Col span={24}>
                      <Button
                        disabled={!saveFilterCheck || disabled}
                        type="primary"
                        style={{
                          backgroundColor: disabled ? 'transparent' : '#0253cc',
                          borderRadius: '20px',
                          color: 'white',
                          marginTop: '15px'
                        }}
                        onClick={handleSaveFilter}
                      >
                        Save Filter
                      </Button>
                    </Col>
                    {/* </Row> */}
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </div>
      )
      }
    </div>
  );
};

export default SalesFilters;