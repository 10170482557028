import React, { useState, useEffect, useCallback } from 'react';
import { Formik } from 'formik';
import { GET_ALL_CLIENTS, GET_USER_DETAILS, GET_ALL_USERS } from '../../../Queries/Users';
//import { updateOrders } from '../../../Slices/orderSlice';
//import { useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { Modal, Button, Form, Input, Select, Typography, Row, Col, Card, Space, AutoComplete, Switch, Checkbox, Tabs } from 'antd';
//import { useNavigate } from 'react-router-dom';
import * as DataBucket from '../../../databucket.js';
import { useErrorLogger } from '../../../utils.js';
import RequestForm from './RequestForm.jsx';
import AttachemntsHistory from './AttachmentsHistory.jsx';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { submitRefreshPage } from '../../../Slices/orderSlice.jsx'
import { setRefreshStatus, } from '../../../Slices/salesSlice.js';
import { getAllClientsData } from '../../../Slices/loginUserSlice.jsx';
import { useDispatch } from 'react-redux';
import { GET_EMPLOYEE_CLIENTS } from '../../../Queries/EmployeeClients.js';
// material-ui
const { Option } = Select;
const { TextArea } = Input;
const { TabPane } = Tabs;
const { Text } = Typography;

const validationSchema = Yup.object({
    // priority: Yup.string().required('Priority is required'),
    // requestStatus: Yup.string().required('Request Status is required'),
    from: Yup.string()
    .email('Invalid email format')
    .required('From Email is required')
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/,
        'Please enter a valid email address with one of the allowed domains (.com, .info, .net, .org, .co)'
    ),

to: Yup.string()
    .email('Invalid email format')
    .required('To Email is required')
    .matches(
       /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/,
        'Please enter a valid email address with one of the allowed domains (.com, .info, .net, .org, .co)'
    )
    .notOneOf([Yup.ref('from'), null], 'From and To emails cannot be the same'),

    subject: Yup.string().trim().required('Subject is required'),
    // clientName: Yup.string().required('Client Name is required'),
    body: Yup.string().trim().required('Body is required'),
    // existingPolicyInformation: Yup.array().of(
    //     Yup.object().shape({
    //         currentCarrier: Yup.string().required('Current Carrier is required'),
    //         propertyType: Yup.string().required('Policy Type is required'),
    //         policyNumber: Yup.string().required('Policy Number is required'),
    //         effectiveDate: Yup.date().required('Effective Date is required'),
    //         currentPremium: Yup.string().required('Current Premium is required'),
    //     })
    // ),
});

const AddRequest = (props) => {
    const { handleClose, requestSubmit, RequestInfo, popupStatus, submitStatus, setSetPopupStatus, handleDialogClose, isDisabled } = props;
    const { logError } = useErrorLogger();
    const dispatch = useDispatch();
    const requestId = useSelector((state) => state.orders.order.requestId);
    console.log("requestId", requestId)
    const assignToUsers = useSelector((state) => state.loginUserInfo.allUsers)
    const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails)
    const clientsData = useSelector((state) => state?.loginUserInfo?.getAllClients)
    //const [clientsData,setClientsData] = useState([])
    console.log("clientsData", clientsData)
    const [clients, setClients] = useState([]);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");
    const [subject, setSubject] = useState("")
    const [body, setBody] = useState("")
    const [employeeDetails, setEmployeeDetails] = useState({});
    const [activeTab, setActiveTab] = useState("request_details");
    //const navigate = useNavigate();
    const token = localStorage.getItem('token');
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token).id;
    const onTabChange = (key) => {
        setActiveTab(key);
    };
    const options = useSelector((state) => state.dataBucket.databucket);

    const renderOptions = (options, criteria, fallbackData = []) => {
        console.log("fallbackData", fallbackData)

        return options?.reduce((acc, item) => {
            // Check if the item name matches the criteria
            if (item.name === criteria.name && item.data) {
                // Filter subItems based on active status and flag === true
                const filteredSubItems = item.data.filter(
                    (subItem) => subItem.status === "active" && subItem.flag === true
                );
                // Accumulate valid subItems into the result array
                acc.push(...filteredSubItems);
            }
            return acc;
        }, []).map((subItem, index) => (
            <Option key={index} value={subItem?.name}>
                {subItem?.name}
            </Option>
        )) ||
            fallbackData?.map((item, index) => (
                <Option key={index} value={item}>
                    {item}
                </Option>
            ));
    };

    const { data } = useQuery(GET_EMPLOYEE_CLIENTS, {
        variables: {
            id: userid
        },
        fetchPolicy: 'no-cache',
        // onCompleted: (data) => {
        //     console.log("data",data)
        //     if(data?.getEmployeeClients?.response.length >= 0){
        //         //setClientsData(data?.getEmployeeClients?.response.client)
        //       //dispatch(getAllClientsData(data?.getEmployeeClients?.response?.client))
        //       console.log("data",data?.getEmployeeClients?.response?.client)
        //     }
        // }
    })
    useEffect(() => {
        if (data) {
            dispatch(getAllClientsData(data?.getEmployeeClients?.response[0]?.client))
        }
    }, [data, dispatch])
    const clientData = data?.getEmployeeClients?.response[0]?.client
    console.log("clientData", clientData)
    // useEffect(() => {

    //     const employeeObj = {
    //       id: parseJwt(token)?.id,
    //     }
    //     getAllClientsData({ variables: employeeObj })
    //   }, []);

    const getAssignToUsers = (usersList) => {
        console.log("usersList", usersList)
        const usersListFormatted = [];
        for (let i = 0; i < usersList?.length; i++) {
            usersListFormatted.push({
                key: i,
                value: usersList[i].firstname + " " + usersList[i].lastname, // Concatenating firstname and lastname
            });
        }
        return usersListFormatted;
    }
    //const dispatch = useDispatch();
    const initialValues = {
        id: RequestInfo?.id,
        requestId: RequestInfo?.requestId,
        priority: "On Demand",
        processType: "",
        assignedTo: loginUser?.firstname + " " + loginUser?.lastname,
        assignTo: "",
        requestStatus: "Open",
        Assign: true,
        radioInfo: "Inbox",
        fromPending: false,
        instructions: false,
        from: "",
        to: "",
        subject: "",
        body: "",
        date: "",
        client: "",
        clientName: "",
        quoteType: "",
        department: "",
        insuredName: "",
        noofQuotes: "",
        holderName: "",
        insuredDelivery: "",
        holderDelivery: "",
        accountManagerDelivery: "",
        otherDelivery: "",
        radioValue: "",
        Auto: "" || false,
        MotorCycle: "" || false,
        Boat: "" || false,
        DP3: "" || false,
        HO3: "" || false,
        HO4: "" || false,
        HO5: "" || false,
        HO6: "" || false,
        emailSummary: "",
        requestor: "",
        sendTo: "",
        newQuoteInformation: [{
            policy: "",
            insuranceCarrier: "",
            eligibility: "",
            quotedPremium: "",
            remarks: "",
        }],
        existingPolicyInformation: [{
            currentCarrier: "",
            propertyType: "",
            propertyNew: "",
            policyNumber: "",
            effectiveDate: null,
            currentPremium: ""
        }]
    }
    const priorityHandler = (values, setFieldValue) => {
        let priority = values.priority;
        const { body, subject } = values;
        
        // Check if "rush" or "urgent" is in the subject or body, and set priority accordingly
        if (!priority && (body?.toLowerCase().includes("urgent") || body?.toLowerCase().includes("rush") || subject?.toLowerCase().includes("urgent") || subject?.toLowerCase().includes("rush"))) {
            priority = 'Rush';
        }
    
        setFieldValue('priority', priority);
        return priority;
    };
    
    // const priorityHandler = (values, setFieldValue) => {
    //     let priority = '';
    //     const { body, subject } = values;
    //     if (body?.toLowerCase().includes("urgent") || body?.toLowerCase().includes("rush") || subject?.toLowerCase().includes("urgent") || subject?.toLowerCase().includes("rush")) {
    //         priority = 'Rush';
    //     }
    //     setFieldValue('priority', priority);
    //     return priority;
    // };
    const refreshStatus = useSelector((state) => state.sales.refreshStatus)
    console.log("refreshStatus", refreshStatus)

    const cancelHandelar = () => {
        handleClose()
        //   if (refreshStatus == null) {
        // Dispatch action to set refreshStatus to true
        dispatch(submitRefreshPage(false));
        // }
        //navigate("/request1");

        // console.log("workHistoryDetails22", workHistoryDetails)
    };
    const handleDialogCancelClose = () => {
        setSetPopupStatus(false)
    }

    const handleFromChange = useCallback((e) => {
        setFrom(e.target.value);
    }, []);
    // const handleDialogClose = () => {
    //     setSetPopupStatus(false)
    // }
    return (
        <>
            <Modal
                title="Submit Status"
                visible={popupStatus}
                onCancel={handleDialogCancelClose}
                footer={[
                    <Button key="ok" type="primary" onClick={handleDialogClose}>OK</Button>
                ]}
            >
                <p>{submitStatus}</p>
            </Modal>
            <div style={{ width: '100%', marginBottom: "5px" }}>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={requestSubmit}
                >
                    {({ handleChange, handleSubmit, values, setFieldValue, errors, touched }) => (
                        <Form onFinish={handleSubmit} layout='vertical'>
                            <Row gutter={16} style={{ marginBottom: "7px" }}>
                                <Col span={24} style={{ marginTop: 16 }}>
                                    <Space>
                                        <Button color='primary' variant='solid' htmlType="submit" disabled={isDisabled}>
                                            Submit
                                        </Button>
                                        <Button color='danger' variant='solid' onClick={cancelHandelar}>
                                            Close
                                        </Button>
                                    </Space>
                                </Col>
                            </Row>
                            <Card style={{ marginBottom: "7px" }} title={
                                <Space direction="horizontal" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography.Title level={5} style={{ marginBottom: 0, flex: 1, textAlign: 'left', fontWeight: "bold" }}>Add Request</Typography.Title>
                                </Space>}>
                                <Row gutter={16}>
                                    {/* <Col span={2}>
                                        <Form.Item
                                            label="Request Id"
                                            labelCol={{ span: 24 }} // Ensures the label takes full width
                                            wrapperCol={{ span: 24 }}
                                        >
                                            <Text style={{ fontWeight: 'bold' }}>{RequestInfo?.requestId || requestId || '-'}</Text>
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={5}>
                                        <Form.Item
                                            label="Priority"
                                            validateStatus={touched.priority && errors.priority ? 'error' : ''} help={touched.priority && errors.priority}
                                        >
                                            <Select
                                                name="priority"
                                                value={values?.priority || undefined}
                                                //onChange={(value) => handleChange({ target: { name: 'priority', value } })}
                                                onChange={(value) => {
                                                    setFieldValue('priority', value); 
                                                }}
                                                placeholder="Enter priority value"
                                            >
                                                {renderOptions(options, { name: "PRIORITY_CONFIG" }, DataBucket.PRIORITY_CONFIG)}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col span={5}>
                                        <Form.Item
                                            label="Process Type"
                                            validateStatus={touched.processType && errors.processType ? 'error' : ''} help={touched.processType && errors.processType}>
                                            {/* <Select name="processType" value={values.processType || undefined} placeholder="enter process type" onChange={(value) => handleChange({ target: { name: 'processType', value } })}>
                                                {DataBucket.PROCESS_TYPE_CONFIG?.map((Item, key) => (
                                                    <Option key={key} value={Item}>
                                                        {Item}
                                                    </Option>
                                                ))} */}
                                            <Select value={values.processType || undefined} name="processType" placeholder="enter process type" onChange={(value) => handleChange({ target: { name: 'processType', value } })}>
                                                {renderOptions(options, { name: "PROCESS_TYPE_CONFIG" }, DataBucket.PROCESS_TYPE_CONFIG)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>
                                        <Form.Item
                                            label="Assigned to"
                                        >
                                            <Input
                                                name="assignedTo"
                                                value={values.assignedTo}
                                                disabled={true}
                                            //onChange={handleChange}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={4}>

                                        <Form.Item
                                            label="Assign to">
                                            <Select
                                                showSearch
                                                options={getAssignToUsers(assignToUsers)}
                                                value={values.assignTo}
                                                onChange={(value) => {
                                                    setFieldValue('assignTo', value);
                                                    setFieldValue('assignedTo', value);
                                                }}
                                                style={{ width: '100%' }}
                                            />
                                            {/* <AutoComplete
                                                name="assignTo"
                                                value={values.assignTo}
                                                onChange={(value) => {
                                                    setFieldValue('assignTo', value);
                                                    setFieldValue('assignedTo', value);
                                                }}
                                                //onChange={(value) => handleChange({ target: { name: 'assignTo', value } })}
                                                filterOption={(inputValue, option) =>
                                                    option?.value?.toLowerCase().includes(inputValue?.toLowerCase())
                                                }
                                                placeholder="Assign to"
                                                style={{ width: '100%' }}
                                            >
                                                {getAssignToUsers(assignToUsers)?.map((item, key) => (
                                                    <AutoComplete.Option key={key} value={item}>
                                                        {item}
                                                    </AutoComplete.Option>
                                                ))}
                                            </AutoComplete> */}
                                        </Form.Item>

                                    </Col>
                                    {/* <Col span={4}>
                                        <Form.Item
                                            label="Request Status"
                                            validateStatus={touched.requestStatus && errors.requestStatus ? 'error' : ''} help={touched.requestStatus && errors.requestStatus}>
                                            <Select value={values?.requestStatus} name="requestStatus"
                                                onChange={(value) => handleChange({ target: { name: "requestStatus", value } })}
                                            >
                                                {DataBucket.REQUEST_STATUS_CONFIG?.filter(item => item !== 'In Progress' && item !== 'On Hold' && item !== 'Completed')
                                                    .map((Item, key) => (
                                                        <Option key={key} value={Item}>
                                                            {Item}
                                                        </Option>
                                                    ))}
                                            </Select>
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={3}>
                                        <Form.Item
                                            label={values?.Assign ? "UnAssign" : "Assign"}
                                        >
                                            <Switch
                                                checked={values.Assign}
                                                onChange={(isChecked) => {
                                                    //const isChecked = event.target.checked;

                                                    if (isChecked) {
                                                        setFieldValue('requestStatus', 'In Progress');
                                                        setFieldValue('assignedTo', loginUser?.firstname + " " + loginUser?.lastname);
                                                        setFieldValue('Assign', true);
                                                    } else {
                                                        setFieldValue('requestStatus', 'Open');
                                                        setFieldValue('assignedTo', "");
                                                        setFieldValue("Assign", false);
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                    {/* <Col span={4}>
                                        <Form.Item
                                            label="Request Stage"
                                            validateStatus={touched.radioInfo && errors.radioInfo ? 'error' : ''} help={touched.radioInfo && errors.radioInfo}>
                                            <Select
                                                onChange={(value) => handleChange({ target: { name: "radioInfo", value } })}
                                                //onChange={radiohandleChange} 
                                                value={values.radioInfo}>
                                                <Select.Option value="Inbox">Inbox</Select.Option>
                                                <Select.Option value="Email Needed">Email Needed</Select.Option>
                                                <Select.Option value="Pending">Pending</Select.Option>
                                                <Select.Option value="Discard">Discard</Select.Option>
                                                <Select.Option value="Completed">Completed</Select.Option>
                                            </Select>
                                        </Form.Item>

                                    </Col> */}
                                    {/* <Col span={2}>
                                        <Form.Item style={{ marginTop: 'auto' }} label={<span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>Pending</span>}>
                                            <Switch name="fromPending" checked={values.fromPending} disabled />
                                        </Form.Item>
                                    </Col> */}
                                    <Col span={3}>
                                        <Form.Item label="Instructions">
                                            <Switch name="instructions" checked={values.instructions} onChange={(checkId) => setFieldValue("instructions", checkId)} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="From Email"
                                            validateStatus={touched.from && errors.from ? 'error' : ''}
                                            help={touched.from && errors.from}>
                                            <Input
                                                type="email"
                                                id="from"
                                                name="from"
                                                placeholder='Enter From Email'
                                                value={values.from}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="To Email"
                                            validateStatus={touched.to && errors.to ? 'error' : ''}
                                            help={touched.to && errors.to}>
                                            <Input
                                                type="email"
                                                id="to"
                                                name="to"
                                                placeholder='Enter To Email'
                                                value={values.to}
                                                onChange={handleChange}
                                                onBlur={handleChange}
                                                autoComplete="off"
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={6}>
                                        <Form.Item
                                            label="Subject"
                                            validateStatus={touched.subject && errors.subject ? 'error' : ''} help={touched.subject && errors.subject}>
                                            <Input
                                                id='subject'
                                                name="subject"
                                                placeholder='Enter Subject value'
                                                value={subject}
                                                onChange={(e) => {
                                                    setSubject(e.target.value);  // Update the subject state
                                                    handleChange(e);  // Trigger formik's handleChange
                                                    priorityHandler({ ...values, subject: e.target.value }, setFieldValue);  // Update priority based on subject
                                                }}
                                                autoComplete='off' />
                                        </Form.Item>
                                    </Col>
                                    <Col span={6}>
                                        <Form.Item
                                            label="Client Name"
                                        //</Col>validateStatus={touched.clientName && errors.clientName ? 'error' : ''} help={touched.clientName && errors.clientName}
                                        >
                                            <Select
                                                style={{ width: 200 }}
                                                name="clientName"
                                                value={values.clientName}
                                                onChange={(value) => handleChange({ target: { name: "clientName", value } })}
                                                placeholder="Select client's name"
                                                showSearch
                                                filterOption={(inputValue, option) =>
                                                    option.children.toLowerCase().includes(inputValue.toLowerCase())
                                                }
                                            >
                                                {clientsData?.map((item, key) => (
                                                    <Select.Option key={key} value={item.NameOfClient}>
                                                        {item.NameOfClient}
                                                    </Select.Option>
                                                ))}
                                            </Select>

                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="Body"
                                            validateStatus={touched.body && errors.body ? 'error' : ''} help={touched.body && errors.body}>
                                            <TextArea
                                                id='body'
                                                name='body'
                                                placeholder='Enter Body value'
                                                value={body}
                                                onChange={(e) => {
                                                    setBody(e.target.value);  // Update the body state
                                                    handleChange(e);  // Trigger formik's handleChange
                                                    priorityHandler({ ...values, body: e.target.value }, setFieldValue);  // Update priority based on body
                                                }}  />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Card>
                            {/* <Tabs
                                activeKey={activeTab}
                                onChange={onTabChange}
                                type="card"
                            >
                                <Tabs.TabPane tab="Request Details" key="request_details"> */}
                            <RequestForm
                                initialValues={initialValues}
                                employeeDetails={employeeDetails}
                                setEmployeeDetails={setEmployeeDetails}
                                //RequestInfo={RequestInfo}
                                handleChange={handleChange}
                                values={values}
                                errors={errors}
                                touched={touched}
                            //handleBlur={handleBlur}
                            />
                            {/* </Tabs.TabPane> */}

                            {/* <Tabs.TabPane
                                    tab="Attachments History"
                                    key="attachment_history"
                                >
                                    <AttachemntsHistory
                                        initialValues={initialValues}
                                        employeeDetails={employeeDetails}
                                        setEmployeeDetails={setEmployeeDetails}
                                        RequestInfo={RequestInfo}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                </Tabs.TabPane> */}

                            {/* <Tabs.TabPane tab="Work History" key="work_history">
                                    <RequestWorkHistory
                                        initialValues={initialValues}
                                        // employeeDetails={employeeDetails}
                                        // setEmployeeDetails={setEmployeeDetails}
                                        // RequestInfo={RequestInfo}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                </Tabs.TabPane> */}
                            {/* </Tabs> */}
                        </Form>

                    )}
                </Formik>
            </div>
        </>
    )
}
export default AddRequest;