import React, { useEffect, useState } from "react";
import axios from "axios";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AttendanceEmployeeFilter from "../../../components/AttendanceEmployeeFilter";
import { base_url } from "../../../base_urls";
import { ATTENDENCE, UPDATE_PUNCHOUT, GET_LAST_ATTENDENCE_RECORD, GET_ALL_RECORDS } from "../../../Queries/attendence";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { setAllRecords, setAttendence, setLastRecord } from "../../../Slices/attendenceSlice";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Slices/loaderSlice";
import moment from "moment";
import { UPDATE_EMPLOYEE } from "../../../Queries/Employees";
import { setLoginUserDetails } from "../../../Slices/loginUserSlice";
const useElapsedTime = (elapsedTimeMs, lastRecord) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
  useEffect(() => {
    if (!lastRecord || typeof elapsedTimeMs !== "number" || elapsedTimeMs < 0) {
     
      setIsRunning(false); // Stop the timer if status is false or invalid data
      return ;
    }

    setElapsedTime(Math.floor(elapsedTimeMs / 1000)); // Convert milliseconds to seconds
    setIsRunning(true);
  }, [elapsedTimeMs, lastRecord]); // React to status changes

  useEffect(() => {
    if (!isRunning) return;

    const interval = setInterval(() => {
      setElapsedTime((prev) => prev + 1); // Increment every second
    }, 1000);

    return () => clearInterval(interval);
  }, [isRunning]);

  const formatTime = (totalSeconds) => {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}`;
  };

  return formatTime(elapsedTime);
};
const AttendanceEmployee = () => {
  
  const [users, setUsers] = useState([]);
  const [activity, setActivity] = useState([]);
  const [data, setData] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const AllEmployees = useSelector((state) => state.employee.AllEmployees);
  const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
  const allRecords = useSelector((state) => state.Attendence.AllRecords)
  const lastAttendenceRecord = useSelector((state) => state.Attendence.attendence)
  const currectrecord=useSelector((state) => state.Attendence.lastRecord)
  const dispatch = useDispatch();
  const { data: Data, loading, error, refetch: Refetch } = useQuery(GET_ALL_RECORDS, {
    skip: !loginUser?.id,
    variables: { id: loginUser.id },
    fetchPolicy: 'network-only',
    onCompleted: (Data) => {
      dispatch(setAllRecords(Data?.AllAttendence?.response))
     
    }
  });

  const [AttendenceLogin] = useMutation(ATTENDENCE)
  const [Attendenceupdate] = useMutation(UPDATE_PUNCHOUT)
  const { data: AttendenceLastRecord,loading:Loading, refetch: refetchLastRecord } = useQuery(GET_LAST_ATTENDENCE_RECORD, {
    variables: { id: loginUser.id }, // Only pass variables if id is available
    skip: !loginUser?.id, // Prevent query execution if id is missing
    fetchPolicy: "network-only",
    onCompleted(AttendenceLastRecord) {
      dispatch(setAttendence(AttendenceLastRecord?.LastAttendence));
      dispatch(setLastRecord(AttendenceLastRecord?.LastAttendence));
  
      if (loginUser?.punchInStatus) {
        setStartTime(Date.now());
        setElapsedTime(0);
        setIsRunning(true);
      }
    }
  });
  
  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 1000);
    } else {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, startTime]);


  const formatAttendanceData = (allRecords) => {
    let formattedRecords = []; // Initialize an empty array

    allRecords?.forEach((acc) => {
      formattedRecords.push({
        Date: moment(parseInt(acc.punchIn)).format("DD MMM YYYY"),
        PunchIn: moment(parseInt(acc.punchIn)).format("hh:mm A"),
        PunchOut: moment(parseInt(acc.punchOut)).format("hh:mm A"),
      });
    });

 
    return formattedRecords; // ✅ Return the processed array
  };
  const token = localStorage.getItem("token");
  const parseJwt = (token) => {
    try {
      return token ? JSON.parse(atob(token.split(".")[1])) : null;
    } catch (e) {
      return null;
    }
  };
  const userid = parseJwt(token)?.id;
  const column = [
    { title: "#", dataIndex: "key", key: "key", render: (_, __, index) => index + 1 },
    { title: "Date", dataIndex: "Date", key: "Date" },
    { title: "Punch In", dataIndex: "PunchIn", key: "PunchIn" },
    { title: "Punch Out", dataIndex: "PunchOut", key: "PunchOut" },
    { title: "Break", dataIndex: "Break", key: "Break", render: (text) => text || "--" },
    { title: "Overtime", dataIndex: "Overtime", key: "Overtime", render: (text) => text || "--" },
  ];
  
  
  const userElements = formatAttendanceData(allRecords)?.map((user, index) => ({
    key: index,
    id: user?.id || index,
    Date: user?.Date,
    PunchIn: user?.PunchIn,
    PunchOut: user?.PunchOut || "-",
    // Production: user?.Production,
    Break: user?.Break || "-",
    Overtime: user?.Overtime || "-",
  }));
 
  const columns = [
    {
      title: "#",
      dataIndex: "key",  // Using key instead of id
      key: "key",
      render: (text, record, index) => index + 1, // Auto-increment row numbers
    },
    {
      title: "Date",
      dataIndex: "Date", // ✅ Case-sensitive fix
      key: "Date",
    },
    {
      title: "Punch In",
      dataIndex: "PunchIn", // ✅ Case-sensitive fix
      key: "PunchIn",
    },
    {
      title: "Punch Out",
      dataIndex: "PunchOut", // ✅ Case-sensitive fix
      key: "PunchOut",
    },
    {
      title: "Break",
      dataIndex: "Break",
      key: "Break",
      render: (text) => text || "--", // Show "--" if undefined
    },
    {
      title: "Overtime",
      dataIndex: "Overtime",
      key: "Overtime",
      render: (text) => text || "--", // Show "--" if undefined
    },
  ];
  
  // useEffect(() => {
  //   axios
  //     .get(base_url + "/attendenceemployeedatatable.json")
  //     .then((res) => setData(res.data));
  // }, []);

  
  const timeStringToSeconds = (timeString) => {
    if (!timeString) return 0;
    const [hours, minutes, seconds] = timeString.split(":").map(Number);
    return hours * 3600 + minutes * 60 + seconds;
  };
  
  const calculateProgress = (elapsedTime, totalHours) => {
    const elapsedHours = elapsedTime / 3600; // Convert seconds to hours
    return Math.min((elapsedHours / totalHours) * 100, 100).toFixed(2) + "%"; // Cap at 100%
  };
  const getFilteredRecords = (allRecords, days) => {
    const now = new Date();
    return allRecords.filter((record) => {
      const punchInDate = new Date(record.punchIn);
      return (now - punchInDate) / (1000 * 60 * 60 * 24) <= days;
    });
  };
  const generateProgressData = (allRecords, currectAttendenceRecord) => {
    const lastRecord = allRecords.length > 0 ? allRecords[allRecords.length - 1] : null;
  
    const todayElapsed = timeStringToSeconds(currectAttendenceRecord);
  
    const weekRecords = getFilteredRecords(allRecords, 7);
    const monthRecords = getFilteredRecords(allRecords, 30);
  
    const weekElapsed = weekRecords.reduce((sum, record) => sum + record.elapsedTime, 0);
    const monthElapsed = monthRecords.reduce((sum, record) => sum + record.elapsedTime, 0);
  
    return [
      {
        title: "Today",
        value: (todayElapsed / 3600).toFixed(2), // Convert to hours
        valuespan: "/ 8 hrs",
        progressWidth: calculateProgress(todayElapsed, 8),
        progressBarColor: "bg-primary",
      },
      {
        title: "This Week",
        value: (weekElapsed / 3600).toFixed(2),
        valuespan: "/ 40 hrs",
        progressWidth: calculateProgress(weekElapsed, 40),
        progressBarColor: "bg-warning",
      },
      {
        title: "This Month",
        value: (monthElapsed / 3600).toFixed(2),
        valuespan: "/ 160 hrs",
        progressWidth: calculateProgress(monthElapsed, 160),
        progressBarColor: "bg-success",
      },
      {
        title: "Remaining",
        value: (160 - monthElapsed / 3600).toFixed(2),
        valuespan: "/ 160 hrs",
        progressWidth: calculateProgress(160 - monthElapsed / 3600, 160),
        progressBarColor: "bg-danger",
      },
      {
        title: "Overtime",
        value: ((monthElapsed / 3600) > 160 ? (monthElapsed / 3600 - 160).toFixed(2) : "0"),
        valuespan: "",
        progressWidth: calculateProgress(Math.max(monthElapsed / 3600 - 160, 0), 40),
        progressBarColor: "bg-info",
      }
    ];
  };
  
  const progressData = generateProgressData(allRecords,useElapsedTime(AttendenceLastRecord?.LastAttendence?.response?.elapsedTime, loginUser?.punchInStatus));

  useEffect(() => {

    setUsers(progressData);
  
}, [progressData]);

  // useEffect(() => {
  //   axios
  //     .get(base_url + "/attendenceemployeeactivity.json")
  //     .then((res) => setActivity(res.data));
  // }, []);
  useEffect(() => {
    const activitys = formatAttendanceData(allRecords)
      .slice(-3) // Get last 4 records
      .flatMap((record) => [
        { title: "Punch In at", time: record.PunchIn },
        { title: "Punch Out at", time: record.PunchOut || "pending" }
      ]);

  
    setActivity(activitys)
  }, [allRecords])

  const ID = lastAttendenceRecord?.response?.id

  const handleSubmitPunch = async () => {
    dispatch(setLoader(true));
    if (!loginUser?.punchInStatus) {
      const response = await AttendenceLogin({
        variables: {
          input: {
            empId: loginUser?.id,
            punchIn: Date.now().toString(),
            status: true,
            elapsedTime: 0
          }
        }
      }); await updateEmployee({
        variables: {
          id: loginUser.id,
          input: {    
        punchInStatus: true,
          }
        }
      });
      dispatch(setLoginUserDetails({
        ...loginUser, // Assuming loginUserDetails is available in Redux state
        punchInStatus: true
      }));

    } else {
      Attendenceupdate({
        variables: {
          id: ID,  // Ensure id is passed
          input: {
            punchOut: Date.now().toString(),  // Use ISO format for consistency
            status: false,
            elapsedTime
          },
        },
      })
      await updateEmployee({
        variables: {
          id: loginUser.id,
          input: {    
        punchInStatus: false,
          }
        }
      });
      dispatch(setLoginUserDetails({
        ...loginUser, // Assuming loginUserDetails is available in Redux state
        punchInStatus: false
      }));
      
      setIsRunning(false);
    }
  await Refetch();
  await  refetchLastRecord();
  
    dispatch(setLoader(false));
  }
  
  return (
    <>
      <div className="page-wrapper">
        <div id="Attendence" className="card profile-box flex-fill">
          {/* /Page Header */}
          <div className="content container-fluid">
            {/* <Breadcrumbs
            maintitle="Attendance"
            title="Dashboard"
            subtitle="Attendance"
          /> */}

            {/* /Page Header */}
            <div className="row">
              <div className="col-md-4">
                <div className="card punch-status">
                  <div className="card-body">
                    <h5 className="card-title">
                      Timesheet <small className="text-muted">{moment(Date.now()).format("DD MMM YYYY ")}</small>
                    </h5>
                    <div className="punch-det">
                      <h6>Punch In at</h6>
                      <p>{moment(parseInt(lastAttendenceRecord?.response?.punchIn)).format("DD MMM YYYY hh:mm A")}</p>
                    </div>
                    <div className="punch-info">
                      <div className="punch-hours">
                        <span>{useElapsedTime(lastAttendenceRecord?.response?.elapsedTime, loginUser?.punchInStatus)} hrs</span>
                      </div>
                    </div>
                    <div className="punch-btn-section">
                      <button type="button" onClick={handleSubmitPunch} className="btn btn-primary punch-btn">
                        {loginUser?.punchInStatus ? "Punch Out" : "punch In"}
                      </button>
                    </div>
                    <div className="statistics">
                      <div className="row">
                        <div className="col-md-6 col-6 text-center">
                          <div className="stats-box">
                            <p>Break</p>
                            <h6>1.21 hrs</h6>
                          </div>
                        </div>
                        <div className="col-md-6 col-6 text-center">
                          <div className="stats-box">
                            <p>Overtime</p>
                            <h6>3 hrs</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card att-statistics">
                  <div className="card-body">
                    <h5 className="card-title">Statistics</h5>
                    <div className="stats-list">
                      {Array.isArray(users) && users.length > 0 ? (
                        users.map((data, index) => (
                          <div className="stats-info" key={index}>
                            <p>
                              {data.title}{" "}
                              <strong>
                                {data.value} <small>{data.valuespan}</small>
                              </strong>
                            </p>
                            <div className="progress">
                              <div
                                className={`progress-bar ${data.progressBarColor}`}
                                role="progressbar"
                                style={{ width: data.progressWidth }}
                                aria-valuenow={data.progressWidth}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No data availble</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card recent-activity">
                  <div className="card-body">
                    <h5 className="card-title">Today Activity</h5>
                    <ul className="res-activity-list">
                      {Array.isArray(activity) && activity.length > 0 ? (
                        activity.map((activity, index) => (
                          <li key={index}>
                            <p className="mb-0">{activity.title}</p>
                            <p className="res-activity-time">
                              <i className="fa-regular fa-clock"></i>{" "}
                              {activity.time}
                            </p>
                          </li>
                        ))
                      ) : (
                        <p>No activities available.</p>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <AttendanceEmployeeFilter />
              <div className="row">
                <div className="col-lg-12">
                  <div className="table-responsive">
                    <Table
                      columns={column}
                      dataSource={userElements?.length > 0 ? userElements : []}
                      className="table-striped"
                      rowKey={(record) => record.id}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AttendanceEmployee;
