import React, { useState } from "react";

import { Table, Tooltip, Row, Col, Card, Typography, Empty } from 'antd';
import dayjs from "dayjs";
import { CREATE_LEAD } from "../../../Queries/Leads";
import { useMutation } from '@apollo/client';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { setDivVisibility, setOpen, setPage, setRefreshStatus, updateSales, setSortObjectt, setSale, setIsRefetch } from "../../../Slices/salesSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import { addLastContactedDate } from '../../../Slices/lastContactedDatesSlice';
import { useErrorLogger } from "../../../utils";
import { message } from "antd";

import './Frames.scss'
import AddSales from "./AddSales";
import SaleDetails from "./SaleDetails";
import SalesFilters from "./SalesFilters";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";

const { Title, Text } = Typography;

const Sales = () => {

  const navigate = useNavigate();
  const { id } = useParams()
  const { logError } = useErrorLogger();
  const dispatch = useDispatch()
  // const [isRefetch, setIsRefetch] = useState(false);
  const isRefetch = useSelector((state) => state.sales.isRefetch);

  const open = useSelector((state) => state.sales.open)
  const page = useSelector((state) => state.sales.page)
  const assignToUsers = useSelector((state) => state.employee.AllEmployees);
  const leadfilterObject = useSelector((state) => state.sales.leadfilterObject)
  const existingSales = useSelector((state) => state.sales.sales || [])


  const tableData = useSelector((state) => state?.sales?.sales);
  const pageCount = useSelector((state) => state?.sales?.pageCount);

  const sortObject = useSelector((state) => state.sales.sortObject)
  // const selectedFilterName = useSelector((state) => state.sales.selectedFilterName)
  const prevVisibility = useSelector((state) => state.sales.prevVisibility)
  const handleDetailPage = (rowid) => {
    dispatch(setDivVisibility(prevVisibility))
    sessionStorage.setItem('salesFilter', JSON.stringify(leadfilterObject));
    sessionStorage.setItem('salesSort', JSON.stringify(sortObject));
    // sessionStorage.removeItem('selectedFilterName', JSON.stringify(selectedFilterName));
    navigate(`/sales/${rowid}`)
  }

  function formatDateTime(timestamp) {
    if (timestamp != null) {
      const date = new Date(parseInt(timestamp));
      const formattedDate = date.toLocaleString("en-IN", {
        // timeZone: 'UTC',
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        // second: 'numeric',
        hour12: true,
      });
      return formattedDate;
    } else {
      return "";
    }
  }

  const columns = [
    {
      title: "S.No",
      dataIndex: "leadID",
      key: "leadID",
      ellipsis: {
        showTitle: false,
      },
      width: 70, // Adjust column width
      renderCell: (params) => parseInt(params.value),
      sorter: true,
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Agency Name",
      dataIndex: "agencyName",
      key: "agencyName",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Follow-Up",
      dataIndex: "followupDate",
      key: "followupDate",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={formatDateTime(text)} // Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Time Zone",
      dataIndex: "timeZone",
      key: "timeZone",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdatedDate",
      key: "lastUpdatedDate",
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={formatDateTime(text)} // Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Last Contacted",
      dataIndex: "lastContactedDate",
      key: "lastContactedDate",
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={formatDateTime(text)} // Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Channel",
      dataIndex: "channel",
      key: "channel",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Assigned To",
      dataIndex: "assignedTo",
      key: "assignedTo",
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span
            style={{
              fontSize: "12px", // Add font size
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: "hidden",
            }}
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, record) => (
        <button
          onClick={() => handleDetailPage(record?.id)}
          className="fa fa-pencil"
          style={{
            fontSize: "10px",
            padding: "4px 8px",
            color: "white",
            backgroundColor: "#1677ff",
            border: "1px solid #1677ff",
            borderRadius: "4px",
            cursor: "pointer",
          }}
        ></button>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "",
          color: "black",
        },
      }),
    },
  ];


  const handleTableChange = (pagination, filters, sorter) => {
    dispatch(setRefreshStatus(null));
    // Update sortObject when the sorting changes
    const newSortObject = sorter?.order
      ? { [sorter.field]: sorter.order === "ascend" ? "asc" : "desc" }
      : {};
    dispatch(setSortObjectt(newSortObject));

    dispatch(setPage(pagination.current));
  };

  const CustomNoRowsOverlay = () => (
    <div style={{ padding: "20px", textAlign: "center" }}>
      <Empty description="No rows found" />
    </div>
  );

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const getLoginUser = () => {
    let username = "";
    let userObject = assignToUsers?.filter(
      (key) => key?.id == parseJwt(token)?.id
    );
    if (userObject.length > 0) {
      username = userObject[0]?.firstname + " " + userObject[0]?.lastname;
    }
    return username;
  };

  const getTimeZoneCode = (timezone) => {

    if (timezone?.length === 3) {
      return timezone;
    } else {
      switch (timezone) {
        case "Eastern Time":
          return "EST";
        case "Central Time":
          return "CST";
        case "Mountain Time":
          return "MST";
        case "Pacific Time":
          return "PST";
        case "Hawaii Time":
          return "HST";
        default:
          return '';
      }
    }
  };

  const [newLead, setNewLead] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 5000);
  };

  const requestSubmit = async (values) => {

    const { createdDate, lastUpdatedDate, ...newObject } =
      newLead;
    const {
      createdDate: date,
      lastUpdatedDate: last,
      // lastContactedDate: contact,
      ...createdObject
    } = values;

    if (JSON.stringify(newObject) === JSON.stringify(createdObject)) {
      startTimer();
      message.error({
        content:
          "You haven't changed anything compared to the last lead. Please modify a value before submitting.",
        duration: 3,
        className: "custom-toast-center", // Custom warning styling
      });
      return;
    }
    startTimer();
    setNewLead(values);

    try {

      dispatch(setLoader(true))// Show loader when starting the submission

      const valuesObject = {
        createdDate: Date.now() + "" || "",
        createdBy: getLoginUser() || "",
        lastUpdatedDate: Date.now() + "" || "",
        lastContactedDate: values?.lastContactedDate || Date.now() + "",
        followup: values?.followup || false,
        // followupDate: (values?.followup == true && values?.followupDate == null) ? new Date().toISOString() : values?.followupDate,
        followupDate: values?.followupDate || "",
        callscheduledwithOPs: values?.callscheduledwithOPs || false,
        assign: values?.assign || false,
        assignedTo: values?.assignedTo || "",
        assignTo: values?.assignTo || "",
        priority: values?.priority || "",
        status: values?.status || "",
        stage: values?.stage || "",
        channel: values?.channel || "",
        successfull: values?.successfull || false,
        agencyName: values.agencyName || "",
        address: values.address || "",
        city: values.city || "",
        state: values.state || "",
        timeZone: getTimeZoneCode(values?.timeZone) || "",
        webURL: values?.webURL || "",
        contactInfoURL: values?.contactInfoURL || "",
        linkedinURL: values?.linkedinURL || "",
        contactInfoComments: values?.contactInfoComments || "",
        callingComments: values?.callingComments || "",
        emailComments: values?.emailComments || "",
        linkedinComments: values?.linkedinComments || "",
        scheduledCallComments: values?.scheduledCallComments || "",
        opsTeamComments: values?.opsTeamComments || "",
        leadsContacts:
          values?.leadsContacts?.map(({ __typename, ...rest }) => rest) || [],
        leadsCalls:
          values?.leadsCalls?.map(({ __typename, ...rest }) => rest) || [],
        leadsEmails:
          values?.leadsEmails?.map(({ __typename, ...rest }) => rest) || [],
        leadsLinkedins:
          values?.leadsLinkedins?.map(({ __typename, ...rest }) => rest) || [],
        leadsScheduledCalls:
          values?.leadsScheduledCalls?.map(({ __typename, ...rest }) => rest) ||
          [],
        leadsOPS:
          values?.leadsOPS?.map(({ __typename, ...rest }) => rest) || [],
      };

      await createLead({ variables: valuesObject });
    } catch (error) {
      dispatch(setLoader(false)); // Hide loader
      await logError("SalesInformationPopup", "requestSubmit", error.message);

      console.error('Error creating lead:', error);
      message.error({
        content: "Failed to create lead. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center",
      });
    }
  };

  const [createLead] = useMutation(CREATE_LEAD, {
    onCompleted: async (data) => {
      dispatch(setIsRefetch(true));
      dispatch(setRefreshStatus(false));
      dispatch(setDivVisibility(false));
      dispatch(setSale(data.createLead));
      message.success({
        content: `Lead created successfully! with lead Id ${data?.createLead?.leadID}`,
        duration: 5,
        className: "custom-toast-center", // Custom success styling
      });
      dispatch(setLoader(false)); // Hide loader
      dispatch(updateSales(data.createLead, ...existingSales));
      //  handleCloseFilter();
    },

    onError(error) {
      dispatch(setLoader(false))

    },
  });

  const handleClose = () => {
    dispatch(setOpen(false));
  };

  return (
    <>
      {id != undefined ? (
        <SaleDetails
          handleClose={handleClose}
          SalesInfo={tableData.filter((item) => item?.id === id)[0]}
          isCreate={false}
        />
      ) : (
        <>
          {open ? (
            <div className="page-wrapper">
              <div className="content container-fluid">
                <AddSales
                  isDisabled={isDisabled}
                  open={open}
                  handleClose={handleClose}
                  requestSubmit={requestSubmit}
                  assignToUsers={assignToUsers}
                  isCreate={true}
                />
              </div>
            </div>
          ) : (
            <>
              <div className="page-wrapper">
                <div style={{ paddingLeft: '10px', paddingRight: '0px', marginRight: '5px' }} className="content container-fluid">
                  <SalesFilters
                    isRefetch={isRefetch}
                    setIsRefetch={setIsRefetch}
                  />

                  <div className={`row ${prevVisibility ? "partial-width" : "full-width"}`}>
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          rowKey={(record) => record?.id}
                          columns={columns}
                          dataSource={tableData?.length > 0 ? tableData : []}
                          pagination={{
                            current: page,
                            pageSize: 50,
                            total: pageCount || 0,
                            showTotal: (total, range) =>
                              `${range[0]}-${range[1]} of ${total} items`,
                            onChange: handleTableChange,
                            showQuickJumper: true,
                            showSizeChanger: false,
                          }}
                          // loading={loading}
                          style={{ maxWidth: "100%", tableLayout: "fixed" }}
                          size="small"
                          onChange={handleTableChange}
                          locale={{
                            emptyText: <CustomNoRowsOverlay />,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TicketModelPopup />
              <DeleteModal Name="Delete Ticket" />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Sales;
