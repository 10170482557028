
import React from "react";
import { useQuery } from "@apollo/client";
import { GET_SALES_STATS } from "../../../Queries/Leads";
import { useDispatch } from "react-redux";
import { setLoader } from "../../../Slices/loaderSlice";
import ReactECharts from 'echarts-for-react';
import { Typography } from "antd";

const SalesStats = () => {

    const dispatch=useDispatch()

    const { data: salesData,loading } = useQuery(GET_SALES_STATS, {
        fetchPolicy: 'no-cache',
    });

    dispatch(setLoader(loading))

    const statsData = salesData?.getSalesStats?.response?.last12MonthsStats

    const months = statsData?.map(stat => `${stat.month}/${stat.year}`);

    const callsData = statsData?.map(stat => stat?.calls);
    const emailsData = statsData?.map(stat => stat?.emails);
    const linkedInData = statsData?.map(stat => stat?.linkedIn);
    const leadsGeneratedData = statsData?.map(stat => stat?.leadsGenerated);
    const callWithOpsData = statsData?.map(stat => stat.callWithOps);

    const todayStatss = salesData?.getSalesStats?.response?.todayStats
    const followUpsStatss = salesData?.getSalesStats?.response?.followUpsStats
    const totalStatss = salesData?.getSalesStats?.response?.totalStats
    const monthlyStatss = salesData?.getSalesStats?.response?.monthStats

    // Function to generate bar chart for monthly stats
    const generateMonthStatsChart = (data) => ({
        title: {
            text: `${new Date().toLocaleString("default", { month: "long", })} - ${new Date().getFullYear()} Stats Overview`, // Chart Title
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['Calls', 'Emails', 'LinkedIn', 'Leads Generated', 'Call With Ops'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    data?.calls,
                    data?.emails,
                    data?.linkedIn,
                    data?.leadsGenerated,
                    data?.callWithOps
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#3498db' // Blue color for bars
                }
            }
        ]
    });


    // Function to generate bar chart
    const generateTotalStatsChart = (data) => ({
        title: {
            text: 'Total Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['Scheduled Calls', 'Leads Closed', 'Connected With Ops', 'Leads Generated', 'Total Leads'],
            axisLabel: {
                rotate: 27,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    data?.callsScheduledForOps,
                    data?.leadsClosed,
                    data?.leadsConnectedWithOps,
                    data?.leadsGenerated,
                    data?.totalLeads
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#ff9933' // Orange color for bars
                }
            }
        ]
    });

    // Function to generate bar chart
    const generateFollowUpsChart = (data) => ({
        tooltip: {
            trigger: 'axis'
        },
        title: {
            text: 'Followup Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        xAxis: {
            type: 'category',
            data: ['Today', 'With Ops', 'This Month', 'Total'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [data?.todayFollowUps, data?.followUpsWithOps, data?.monthFollowUps, data?.totalFollowUps],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#9ec5fe' // Blue color for bars
                }
            }
        ]
    });

    // Function to generate bar chart
    const generateTodayStatsChart = (data) => ({
        tooltip: {
            trigger: 'axis'
        },
        title: {
            text: 'Today Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        xAxis: {
            type: 'category',
            data: ['Calls', 'Emails', 'LinkedIn', 'Leads Generated', 'Call with Ops'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [data?.calls, data?.emails, data?.linkedIn, data?.leadsGenerated, data?.callWithOps],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#FF5733'
                }
            }
        ]
    });


    // Function to generate a bar chart for each category
    const generateBarChart = (title, data, color) => {
        return {
            title: {
                text: title,
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: months,
                axisLabel: {
                    rotate: 45, // Rotate the labels to avoid overlap
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => Math.round(value), // Ensures no decimals
                },
                minInterval: 1, // Ensures only whole numbers are used
            },
            series: [
                {
                    data: data,
                    type: 'bar',
                    itemStyle: {
                        color: color,
                    },
                },
            ],
        };
    };
    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <Typography style={{ marginBottom: "20px", fontWeight: 'bold' }}>Sales Dashboard</Typography>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Calls', callsData, '#FF5733')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Emails', emailsData, '#17a6df')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('LinkedIn', linkedInData, '#0512bf')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Leads Generated', leadsGeneratedData, '#FF33A6')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Call with OPS', callWithOpsData, '#55ce63')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateTodayStatsChart(todayStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateFollowUpsChart(followUpsStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateTotalStatsChart(totalStatss)} style={{ height: "400px", width: "100%", }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateMonthStatsChart(monthlyStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SalesStats