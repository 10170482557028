import React from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem, ProjectDetails } from "./ProfileContent";
import EmergencyContacts from "./emergencyContact";
import AddNoteBox from "./addNote";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const FamilyInfo = () => {
     const { Empid } = useParams();
    const familyInfoData =useSelector((state)=>state.employee.familyMembers)|| [];
    console.log("rvwv",familyInfoData)
  return (
    <>
    <div id="family_info" className="card profile-box flex-fill">
      <div className="profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Family Information
           {Empid ? <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#family_info_modal"
            >
              <i className="fa fa-pencil" />
            </Link> : null}
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th className="text-center">Name</th>
                  <th className="text-center">Relationship</th>
                  <th className="text-center">Date of Birth</th>
                  <th className="text-center">Age</th>
                  <th className="text-center">Dependent</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {familyInfoData?.map((item, index) => (
                  <tr key={index}>
                    <td className="text-center">{item.name}</td>
                    <td className="text-center">{item.relationship}</td>
                    <td className="text-center">
                      {dayjs(item?.dob).format('DD/MM/YYYY')}
                    </td>
                    <td className="text-center">{item.age}</td>
                    <td className="text-center">{item.dependent}</td>
                    <td className="text-end">
                      {/* Actions (Edit/Delete) can be added here */}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>    
    <PersonalInformationModelPopup name={"familyNote"} />
     <EmergencyContacts/>
     <AddNoteBox name={"familyNote"} />
     </>
  );
};

export default FamilyInfo;
