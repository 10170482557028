import React, { useState,useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  Avatar_19,
} from "../../../Routes/ImagePath";
import { useMutation } from "@apollo/client";
import { GET_CLIENTDETAILS_BY_ID } from "../../../Queries/Orders";
import { AllClientEditPopup } from "../../../components/modelpopup/UpdateClientModal";
import { addClient } from "../../../Slices/clientSlice";
import { useErrorLogger } from "../../../utils";

const ClientProfile = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [clientProfileData, setClientProfileData] = useState({});
  const { logError } = useErrorLogger();

  const clientData = useSelector(state =>
    state.clients.clients?.find(client => client?.id === id)
  );
  const [getClientsDetails] = useMutation(GET_CLIENTDETAILS_BY_ID, {
    fetchPolicy: "network-only",
    onCompleted(data) {
      const fetchedClient = data?.getClientsDetails[0];
      console.log("fetchingdata", fetchedClient)
      if (fetchedClient) {
        dispatch(addClient(fetchedClient));
        setClientProfileData(fetchedClient);
        console.log("clientprofiledata is11", clientProfileData)
      }
    },
    onError(error) {
      logError("clients", "Fetch Client Details - GraphQL", error.message);
    }
  });
  const countryCode = clientProfileData?.mobileNo?.match(/^\+(\d{1,4})/); // Match the country code
  const localNumber = clientProfileData?.mobileNo?.replace(/^\+\d{1,4}/, '');
  useEffect(() => {
    if (!clientData) {
      getClientsDetails({ variables: { id } });
    } else {
      setClientProfileData(clientData);
    }
  }, [clientData, getClientsDetails, id]);
  useEffect(() => {
    if (clientData) {
      setClientProfileData(clientData);
    }
  }, [clientData]);

  const isActive = clientProfileData?.status === "Active";
  const getInitials = (name) => {

    if (!name || typeof name !== 'string' || name.trim() === '') {
      return 'N/A';
    }

    const names = name.split(' ');

    if (names.length > 1) {
      return `${names[0].charAt(0).toUpperCase()}${names[1].charAt(0).toUpperCase()}`;
    }


    return names[0] ? names[0].charAt(0).toUpperCase() : 'N/A';
  };

  const handleGo = ()=>{
    navigate('/clients')
  }

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
        <div>
{/* <Button type="primary" size="small" onClick={handleGo}>Back</Button> */}
<Button onClick={handleGo}
             style={{ marginBottom:"3px"}}
            > ←Back</Button>

</div>
          <div className="card mb-0">
            <div
              style={{
                position: "absolute",
                top: "10px",
                right: "10px",
              }}
            >
              <Link
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit_client"
              >
                <i className="fa fa-pencil m-r-5" />

              </Link>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div className="profile-img-wrap">
                        <div
                          className="profile-img"
                          style={{
                            position: "relative",
                            width: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            backgroundColor: "#ccc",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            fontSize: "40px",
                            color: "white",
                          }}
                        >

                          {clientProfileData?.avatar ? (
                            <img
                              src={clientProfileData?.avatar}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (

                            <span>
                              {getInitials(clientProfileData?.clientName)}
                            </span>
                          )}


                          <div
                            style={{
                              width: "20px",
                              height: "20px",
                              borderRadius: "50%",
                              backgroundColor: isActive ? "green" : "red",
                              position: "absolute",
                              bottom: "6px",
                              right: "6px",
                              border: "2px solid white",
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-basic">
                      <div className="row">
                     
                        <div className="col-md-5">
                        
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0">
                              {clientProfileData?.clientName}
                            </h3>
                            <h5 className="company-role m-t-0 mb-0">
                              {clientProfileData?.department}
                            </h5>
                            {/* <div className="staff-msg">
                              <Link to="/call/chat" className="btn btn-custom">
                                Send Message
                              </Link>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <span className="title">Phone:</span>
                              <span className="text">
                                {clientProfileData?.mobileNo}
                              </span>
                            </li>
                            <li>
                              <span className="title">Email:</span>
                              <span className="text">
                                {clientProfileData?.emailId}
                              </span>
                            </li>
                            <li>
                              <span className="title">TimeZone:</span>
                              <span className="text">
                                {clientProfileData?.timeZone}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AllClientEditPopup clientData={clientProfileData} />
      </div>
    </div>
  );
};

export default ClientProfile;
