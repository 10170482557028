import React, { useState,useEffect } from "react";
import Select from "react-select";
// import DatePicker from "react-datepicker";
import { DatePicker } from "antd";
import * as dataBucket from "../../../../databucket";
import { GET_ALL_USERS } from "../../../../Queries/Users";
import { CREATE_LEAD } from "../../../../Queries/Leads";
import { useQuery , useMutation} from "@apollo/client";
import { getAllSales,updateSales } from "../../../../Slices/salesSlice";
import { useDispatch,useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { TimePicker } from "antd";
import Swal from "sweetalert2";

import CreateCall from "./CreatCall";
import CreateEmail from "./CreateEmail";
import CreateLinkedin from "./CreateLinkedin";
import CreateScheduledCall from "./CreateScheduledCall";
import CreateOPS from "./CreateOPS";

const CreateSales = ({onClose,fetchData}) => {

  const [activeTab, setActiveTab] = useState('create_call');
    
  const [formData, setFormData] = useState({});
  const [leadsContacts, setLeadsContacts] = useState([]);
  const [leadsCalls, setLeadsCalls] = useState([]);
  const [leadsEmails,setLeadsEmails]=useState([])
  const [leadsLinkedins,setLeadsLinkedins]=useState([])
  const [leadsScheduledCalls, setLeadsScheduledCalls]=useState([])
  const [leadsOPS, setLeadsOPS]=useState([])
  
  // const [assignToUsers, setAssignToUsers] = useState([]);
  const assignToUsers=useSelector((state)=>state.loginUserInfo.allUsers)
  
  const [current, setCurrent] = useState(true)
  const [close, setClose] = useState(formData.lastContactedDate ? true : false);

  const dispatch=useDispatch();
  const existingSales=useSelector((state)=>state.sales.sales || [])

  const [createLead,] = useMutation(CREATE_LEAD, {
    onCompleted: async (data) => {
      console.log('createLeaddd', data)
      
      console.log("existingSales",existingSales)
      dispatch(updateSales(data.createLead,...existingSales)); 

    },
    onError(error) {
      // setSetPopupStatus(true);
      // setSubmitStatus(error.message); // Set your error message
      console.log('errorr',error)
    },
  });


  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
      try {
          return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
          return null;
      }
  };

  const userid = parseJwt(token)?.id;

  // const { data, } = useQuery(GET_ALL_USERS,{
  //     variables: { loginUserId:userid },
  //   });

  // let userscontent = data ? data.getAllUsers : [];

  // useEffect(() => {
  //     if (userscontent.status == 200) {
  //         if (userscontent.response?.length > 0) {
  //             const usersList=userscontent.response
  //             setAssignToUsers(usersList)
  //             // console.log("filtered data",usersList)

  //         }
  //     }

  // }, [userscontent]);

  const userNames = assignToUsers?.map(user => `${user.firstname} ${user.lastname}`);
  const loginUser=assignToUsers.filter(user=>user?.id===userid)[0]

  
    

  const getLoginUser = () => {
    let username = ''
    let userObject = assignToUsers?.filter((key) => key?.id == parseJwt(token)?.id);
    if (userObject.length > 0) {
        username = userObject[0]?.firstname + ' ' + userObject[0]?.lastname
    }
    console.log('assignToUsers', username, assignToUsers, parseJwt(token)?.id, userObject)
    return username;

}


  const getBy = (userby) => {
    // console.log('callbycallby', userby, userby == undefined, userby == null, userby == '')
    let callbyuser = '';
    if (userby == '') {
        return callbyuser;
    } else if (userby == undefined) {
        let calluser = assignToUsers.filter(item => item?.id == parseJwt(token)?.id)
        // console.log('callbycallby', calluser, assignToUsers, parseJwt(token)?.id)
        if (calluser?.length > 0) {
            callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
        }
        // console.log('callbycallby', callbyuser)
        return callbyuser
    } else {
        // let calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
        let calluser = assignToUsers.filter(item => (item.firstname.toLowerCase() + " " + item.lastname.toLowerCase()) == userby?.toLowerCase())
        if (calluser?.length == 0) {
            calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
        }
        if (calluser?.length > 0) {
            callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
        }
        // console.log('callusercalluser', calluser, userby, callbyuser, parseJwt(token))

        return callbyuser
    }
}


const [errors, setErrors] = useState({});
const [isFormValid, setIsFormValid] = useState(false);

// Validate a single field
const validateField = (key, value) => {
  const newErrors = { ...errors };

  switch (key) {
    case "priority":
      newErrors.priority = value.trim() ? null : "Priority is required.";
      break;
    case "status":
      newErrors.status = value.trim() ? null : "Status is required.";
      break;
    case "stage":
      newErrors.stage = value.trim() ? null : "Stage is required.";
      break;
    case "channel":
      newErrors.channel = value.trim() ? null : "Channel is required.";
      break;
    case "agencyName":
      if (!value.trim()) {
        newErrors.agencyName = "Agency Name is required.";
      // } else if (!/^[a-zA-Z\s]+$/.test(value)) {
      //   newErrors.agencyName =
      //     "Agency Name must contain only letters and spaces.";
      // } else {
        newErrors.agencyName = null;
      }
      break;
    case "address":
      newErrors.address = value.trim() ? null : "Address is required.";
      break;
    case "city":
      newErrors.city = value.trim() ? null : "City is required.";
      break;
      case "state":
        newErrors.state = value.trim() ? null : "state is required.";
        break;
    case "timeZone":
      newErrors.timeZone = value.trim() ? null : "Time Zone is required.";
      break;
    default:
      break;
  }

  setErrors(newErrors);
};

// Update field and validate
const handleChange = (key, value) => {
  setFormData((prev) => ({
    ...prev,
    [key]: value,
  }));

  validateField(key, value);
};

// Check if the form is valid
useEffect(() => {
  const hasErrors = Object.values(errors).some((err) => err);
  const allFieldsFilled = Object.values(formData).every((value) =>
    typeof value === 'string' ? value.trim() : value !== undefined && value !== null
  );

  setIsFormValid(!hasErrors && allFieldsFilled);
}, [formData, errors]);

  const handleLastContactedDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      lastContactedDate: date,
    }));
    setClose(false);
    setCurrent(true);
  };

  const handleContactsInputChange = (index, e) => {
    const { name, value } = e.target;
    setLeadsContacts((prevContacts) => {
      const updatedContacts = [...prevContacts];
      updatedContacts[index][name] = value;
      return updatedContacts;
    });
  };

  const handleAddNewContact = () => {
    const newContact = { contactPerson: '', email: '', phone: '', remarks: '', title: '' };
    setLeadsContacts((prev) => [...prev, newContact]);
  };

  const handleDeleteContact = (index) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this contact?');
    if (confirmDelete) {
      setLeadsContacts((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleDeleteCall = (index) => {
    // Show confirmation popup
    const confirmDelete = window.confirm('Are you sure you want to delete this call?');
    if (confirmDelete) {
      // Proceed with deletion if confirmed
      setLeadsCalls((prev) => prev.filter((_, i) => i !== index));
    }
  };

  const handleCallsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsCalls((prev) => {
      const updatedCalls = [...prev];
      updatedCalls[index] = { ...updatedCalls[index], [name]: value };
      return updatedCalls;
    });
  };

  const handleAddNewCall = () => {
    setLeadsCalls((prev) => [
      ...prev,
      {
        date:  dayjs(new Date()) || '',
        time: dayjs().format('HH:mm:ss'),
        status: 'Attempt',
        spokeWith: '',
        callby: getBy(null),
        emailSent: false,
        remarks: '',
      },
    ]);
  };

  const handleDeleteEmail = (index) => {
    // Show confirmation popup
    const confirmDelete = window.confirm("Are you sure you want to delete this email?");
    if (confirmDelete) {
      // Proceed with deletion if confirmed
      setLeadsEmails((prev) => prev.filter((_, i) => i !== index));
  
    
    }
  };

  const handleEmailsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsEmails((prev) => {
      const updatedEmails = [...prev];
      updatedEmails[index] = { ...updatedEmails[index], [name]: value };
      return updatedEmails;
    });
  };

  const handleAddNewEmail = () => {
    setLeadsEmails((prev) => [
      ...prev,
      {
        date: dayjs(new Date()) || '',
        time:  dayjs().format('HH:mm:ss'),
        status: 'Sent',
        emailedto: '',
        emailedy: getBy(null),
        remarks: ''
      },
    ]);
  
  };

  const handleDeleteLinkedin = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this LinkedIn record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the record from leadsLinkedins if confirmed
        setLeadsLinkedins((prev) => prev.filter((_, i) => i !== index));
      }
    });
  };
  
  

  const handleAddNewLinkedin = () => {
    setLeadsLinkedins((prev) => [
      ...prev,
      {
        date:  dayjs(new Date()) || '',
        time: dayjs().format('HH:mm:ss'),
        status: 'Request Sent',
        requestTo: '',
        requestBy: getBy(null),
        remarks: '',
      },
    ]);
  
  };
  
  const handleLinkedinsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsLinkedins((prev) => {
      const updatedLinkedins = [...prev];
      updatedLinkedins[index] = { ...updatedLinkedins[index], [name]: value };
      return updatedLinkedins;
    });
  };

  const handleDeleteScheduledCall = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this scheduled call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the record from leadsScheduledCalls if confirmed
        setLeadsScheduledCalls((prev) => prev.filter((_, i) => i !== index));
      }
    });
  };

  const handleScheduledCallsInputChange = (index, e) => {
    const { name, value } = e.target;
    setLeadsScheduledCalls((prev) => {
      const updatedCalls = [...prev];
      updatedCalls[index] = { ...updatedCalls[index], [name]: value };
      return updatedCalls;
    });
  };

  const handleAddNewScheduledCall = () => {
  const newScheduledCall = {
    date: dayjs(new Date()) || '',
    time:dayjs().format('HH:mm:ss'),
    callType: '',
    emails: '',
    phonenumber: '',
    callScheduledBy: getBy(null),
    timeZone: null,
    remarks: '',
  };
  setLeadsScheduledCalls((prev) => [...prev, newScheduledCall]);

};

const handleDeleteOPS = (index) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you really want to delete this OPS record?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      // Remove the record from leadsOPS if confirmed
      setLeadsOPS((prev) => prev.filter((_, i) => i !== index));
    }
  });
};

const handleOPSInputChange = (index, event) => {
  const { name, value } = event.target;
  setLeadsOPS((prev) => {
    const updatedOPS = [...prev];
    updatedOPS[index] = { ...updatedOPS[index], [name]: value };
    return updatedOPS;
  });
};

const handleAddNewOPS = () => {
  setLeadsOPS((prev) => [
    ...prev,
    {
      date: dayjs(new Date()) || '',
      time:dayjs().format('HH:mm:ss'),
      callType: '',
      emails: '',
      phonenumber: '',
      callScheduledBy: getBy(null),
      remarks: '',
      callConnected: false,
      clientAttendees: '',
      blueplanitAttendees: '',
      amsusedbyClient: '',
      clientRequirements: '',
      pros: '',
      cons: '',
      emailSent: false,
    }
  ]);
  
};

  const handleSubmit =async (e) => {
    e.preventDefault();

    const newErrors = {};

    // Validate Priority, Status, Stage, and Channel
    if (!formData.priority) newErrors.priority = "Priority is required";
    if (!formData.status) newErrors.status = "Status is required";
    if (!formData.stage) newErrors.stage = "Stage is required";
    if (!formData.channel) newErrors.channel = "Channel is required";

    // Validate text and dropdown fields
    if (!formData.agencyName) newErrors.agencyName = "Agency Name is required";
    if (!formData.address) newErrors.address = "Address is required";
    if (!formData.city) newErrors.city = "City is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.timeZone) newErrors.timeZone = "Time Zone is required";
  
    
  
    // Set errors
    setErrors(newErrors);
  
    // If any errors exist, stop the submission
    if (Object.values(newErrors).some((error) => error)) {
      return; // Show errors, don't submit
    }
  
    // If no errors, mark form as valid and proceed
    const noErrors = Object.keys(newErrors).length === 0;
    setIsFormValid(noErrors);

    console.log("Submitted Data:", formData);

    const sanitizedLeadsContacts = leadsContacts?.map(({ __typename, ...contact }) => contact);
    const sanitizedLeadsCalls = leadsCalls?.map(({ __typename, ...call }) => call);
    const sanitizedLeadsEmails = leadsEmails?.map(({ __typename, ...email }) => email);
    const sanitizedLeadsLinkedins = leadsLinkedins?.map(({ __typename, ...linkedin }) => linkedin);
    const sanitizedLeadsScheduledCalls = leadsScheduledCalls?.map(({ __typename, ...call }) => call);
    const sanitizedLeadsOPS = leadsOPS?.map(({ __typename, ...call }) => call);

    try {
      if(isFormValid){
      const { data } = await createLead({
        variables: {
          createdBy: getLoginUser() || '',
          createdDate: Date.now() + '',
          lastContactedDate:Date.now() + '',
          lastUpdatedDate: Date.now() + '',
          ...formData,
          sanitizedLeadsContacts,
          sanitizedLeadsCalls,
          sanitizedLeadsEmails,
          sanitizedLeadsLinkedins,
          sanitizedLeadsScheduledCalls,
          sanitizedLeadsOPS
        },
      });
      console.log("Lead created successfully:", data);
      await fetchData();
      onClose();

    }
      
      // Optionally, redirect or display a success message here
    } catch (error) {
      console.error("Error creating lead:", error);
      // Optionally, show error message to the user here
    }
  };

  


  const handleAssignChange = (isChecked) => {
    if (isChecked) {
      // Set 'assignedTo' and 'assignTo' to the logged-in user's full name when checked
      setFormData((prevData) => ({
        ...prevData,
        assign: true,
        assignedTo: loginUser?.firstname + " " + loginUser?.lastname,
        assignTo: loginUser?.firstname + " " + loginUser?.lastname,
      }));
    } else {
      // Reset 'assignedTo' and 'assignTo' when unchecked
      setFormData((prevData) => ({
        ...prevData,
        assign: false,
        assignedTo: "",
        assignTo: "",
      }));
    }
  };
  

  const priorityOptions = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
  ];
  
  const statusOptions = [
    { value: 'New', label: 'New' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Closed', label: 'Closed' },
  ];
  
  const stageOptions = [
    { value: 'Operation team', label: 'Operation team' },
    { value: 'Sales team', label: 'Sales team' },
  ];
  
  const channelOptions = [
    { value: 'Email', label: 'Email' },
    { value: 'Phone', label: 'Phone' },
    { value: 'In-person', label: 'In-person' },
    {value: 'Cold Calling', label:'Cold Calling'}
  ];

  const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const timeZoneOptions = dataBucket.SALES_TIMEZONES?.map((timeZone) => ({
    value: timeZone,
    label: timeZone,
  }));

  return (
    <form onSubmit={handleSubmit}>
      <div className="row">
       {/* Lead Information Section */}
        <div className="col-md-12 mb-3">
          <div
            className="p-3 rounded shadow-sm"
            style={{ backgroundColor: "#fff", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
          >
            <h5 className="mb-3">Lead Information</h5>
            <div className="row">
              
              {/* Last Contacted Date */}
              <div className="col-md-3 mb-3">
              <div className="d-flex justify-content-between align-items-center mb-2">
                <label className="col-form-label mb-0">Last Contacted Date</label>
                <div>
                  {close ? (
                    <button
                      className="btn btn-link p-0"
                      style={{ textDecoration: "underline", fontSize: "12px" }}
                      onClick={() => {
                        handleChange("lastContactedDate", dayjs(new Date()));
                        setClose(false);
                        setCurrent(false);
                      }}
                    >
                      Current
                    </button>
                  ) : (
                    <>
                      {current && (
                        <button
                          className="btn btn-link p-0"
                          style={{ textDecoration: "underline", fontSize: "12px" }}
                          onClick={() => {
                            handleChange("lastContactedDate", dayjs(new Date()));
                            setCurrent(false);
                          }}
                        >
                          Current
                        </button>
                      )}
                      <button
                        className="btn btn-link p-0 mx-2"
                        style={{ textDecoration: "none", fontSize: "12px" }}
                        onClick={() => {
                          handleChange("lastContactedDate", null);
                          setClose(true);
                        }}
                      >
                        Clear
                      </button>
                    </>
                  )}
                </div>
              </div>
              <DatePicker
                value={formData.lastContactedDate ? dayjs(formData.lastContactedDate) : null}
                onChange={(date) => handleLastContactedDateChange(date)}
                className="form-control"
                format="DD/MM/YYYY hh:mm A" // Custom format
                placeholder="Select date"
                showTime
              />
            </div>


              {/* Follow Up */}
              <div className="col-md-1 mb-3">
                <label className="col-form-label">Follow Up</label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    checked={formData.followup || false}
                    onChange={(e) => handleChange("followup", e.target.checked)}
                  />
                </div>
              </div>

              {/* Follow Up Date */}
              <div className="col-md-3 mb-3">
                <label className="col-form-label">Follow Up Date</label>
                <DatePicker
              value={formData.followupDate ? dayjs(formData.followupDate) : null} // Convert value to dayjs object if it exists
              onChange={(date) => handleChange("followupDate", date)} // Handle date change
              className="form-control"
              format="DD/MM/YYYY hh:mm A" // Custom format for date and time
              placeholder="Select date"
              showTime // Show time selector
              disabled={!formData.followup} // Disable if followup is false or undefined
            />
              </div>

              {/* Call Scheduled with Ops */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Scheduled with Ops</label>
                <Select
                  options={yesNoOptions}
                  value={yesNoOptions.find(
                    (option) => option.value === formData.callscheduledwithOPs
                  )}
                  onChange={(option) =>
                    handleChange("callscheduledwithOPs", option.value)
                  }
                />
              </div>

              {/* Assign */}
              <div className="col-md-1 mb-3">
                <label className="col-form-label">Assign</label>
                <input
                  type="checkbox"
                  className="form-check-input"
                  checked={formData.assign || false}
                  onChange={(e) => handleAssignChange(e.target.checked)}
                />
              </div>

              {/* Assigned To */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Assigned To</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.assignedTo || ""}
                  onChange={(e) => handleChange("assignedTo", e.target.value)}
                  disabled
                />
              </div>

              {/* Assign To */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Assign To</label>
                <select
                  className="form-control"
                  value={formData.assignTo || ""}
                  onChange={(e) => handleChange("assignTo", e.target.value)}
                >
                  <option value="">Select an option</option>
                  {userNames?.map((name, index) => (
                    <option key={index} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>


              {/* Priority */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Priority</label>
                <Select
                  options={priorityOptions}
                  value={priorityOptions.find(
                    (option) => option.value === formData.priority
                  )}
                  onChange={(option) => handleChange("priority", option.value)}
                />
                {errors.priority && (
                  <small className="text-danger mt-1 d-block">{errors.priority}</small>
                )}
              </div>

              {/* Status */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Status</label>
                <Select
                  options={statusOptions}
                  value={statusOptions.find(
                    (option) => option.value === formData.status
                  )}
                  onChange={(option) => handleChange("status", option.value)}
                />
                {errors.status && (
            <small className="text-danger mt-1 d-block">{errors.status}</small>
          )} 
              </div>

              {/* Stage */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Stage</label>
                <Select
                  options={stageOptions}
                  value={stageOptions.find(
                    (option) => option.value === formData.stage
                  )}
                  onChange={(option) => handleChange("stage", option.value)}
                />
                {errors.stage && (
            <small className="text-danger mt-1 d-block">{errors.stage}</small>
          )}
              </div>

              {/* Channel */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Channel</label>
                <Select
                  options={channelOptions}
                  value={channelOptions.find(
                    (option) => option.value === formData.channel
                  )}
                  onChange={(option) => handleChange("channel", option.value)}
                />
                {errors.channel && (
            <small className="text-danger mt-1 d-block">{errors.channel}</small>
          )}
              </div>

              {/* Successful */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Successful</label>
                <Select
                  options={yesNoOptions}
                  value={yesNoOptions.find(
                    (option) => option.value === formData.successfull
                  )}
                  onChange={(option) => handleChange("successfull", option.value)}
                />
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 mb-3 mt-4">
        <div
          className="p-3 rounded shadow-sm"
          style={{ backgroundColor: "#fff", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
        >
          <h5 className="mb-3">Agency Information</h5>
          <div className="row">

        {/* Agency Information */}
        {["agencyName", "address", "city", "state"]?.map((field, idx) => (
          <div className="col-md-3 mb-3" key={idx}>
            <label className="col-form-label">
              {field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <input
              type="text"
              name={field}
              className="form-control"
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
            />
            {errors[field] && (
      <small className="text-danger mt-1 d-block">{errors[field]}</small>
    )}
          </div>
        ))}

         {/* Time Zone (Dropdown) */}
         <div className="col-md-3 mb-3">
          <label className="col-form-label">Time Zone</label>
          <Select
            options={timeZoneOptions} // Use the mapped options
            value={timeZoneOptions.find((option) => option.value === formData.timeZone)}
            onChange={(option) => handleChange("timeZone", option.value)}
          />
          {errors.timeZone && (
      <small className="text-danger mt-1 d-block">{errors.timeZone}</small>
    )}
        </div>

        {/* Web & LinkedIn URLs */}
        {["webURL", "contactInfoURL", "linkedinURL"]?.map((field, idx) => (
          <div className="col-md-3 mb-3" key={idx}>
            <label className="col-form-label">
              {field.charAt(0).toUpperCase() + field.slice(1)}
            </label>
            <input
              type="url"
              name={field}
              className="form-control"
              value={formData[field] || ""}
              onChange={(e) => handleChange(field, e.target.value)}
            />
          </div>
        ))}
      </div>
      </div>
    </div>
  </div>

        {/* Agency Information Heading */}
        <div className="col-md-12 mb-3 mt-4">
          <h5>Contact Information</h5>
        </div>
      {leadsContacts?.map((contact, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h1 className="card-title">
              Lead Contact {index + 1}
              <Link to="#" className="delete-icon" onClick={() => handleDeleteContact(index)}>
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h1>
            <div className="row">
              {['contactPerson', 'email', 'phone', 'title']?.map((field) => (
                <div className="col-md-3 mb-3" key={field}>
                  <label className="col-form-label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                  <input
                    type={field === 'email' ? 'email' : 'text'}
                    name={field}
                    className='form-control'
                    value={contact[field] || ''}
                    onChange={(e) => handleContactsInputChange(index, e)}
                  />
                  
                </div>
              ))}
              <div className="col-md-12 mb-3">
                <label className="col-form-label">Remarks</label>
                <textarea
                  name="remarks"
                  className="form-control"
                  value={contact.remarks || ''}
                  onChange={(e) => handleContactsInputChange(index, e)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="mb-3">
        <Link to="#" onClick={handleAddNewContact}>
          <i className="fa-solid fa-plus-circle" /> Add Contacts
        </Link>
      </div>
      {/* Contact Info Comments Section */}
      <div className="mb-3">
        <label className="col-form-label">Contact Info Comments</label>
        <textarea
          name="contactInfoComments"
          className="form-control"
          value={formData.contactInfoComments || ''}
          onChange={(e) => handleChange("contactInfoComments", e.target.value)}
        ></textarea>
      </div>     
          <div className="card tab-box mb-0 pb-0">
            <div className="row user-tabs">
              <div className="col-12">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    <a
                      href="#create_call"
                      className={`nav-link ${activeTab === 'create_call' ? 'active' : ''}`}
                      onClick={() => setActiveTab('create_call')}
                    >
                      Call Track
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#create_emial"
                      className={`nav-link ${activeTab === 'create_emial' ? 'active' : ''}`}
                      onClick={() => setActiveTab('create_emial')}
                    >
                      Email Track
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#create_linkedin"
                      className={`nav-link ${activeTab === 'create_linkedin' ? 'active' : ''}`}
                      onClick={() => setActiveTab('create_linkedin')}
                    >
                      Linkedin Track
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#create_schedule"
                      className={`nav-link ${activeTab === 'create_schedule' ? 'active' : ''}`}
                      onClick={() => setActiveTab('create_schedule')}
                    >
                      Scheduling Track
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      href="#create_otc"
                      className={`nav-link ${activeTab === 'create_otc' ? 'active' : ''}`}
                      onClick={() => setActiveTab('create_otc')}
                    >
                      Operations Team Communication
                    </a>
                  </li>
                  {/* Add more tabs here if necessary */}
                </ul>
              </div>
            </div>
          </div>


                {/* Tab Contents */}
                <div className="tab-content mt-0 pt-0">
                  {activeTab === 'create_call' && (
                    <div className="tab-pane fade show active" id="create_call">
                      <CreateCall
                        leadsCalls={leadsCalls}
                        handleDeleteCall={handleDeleteCall}
                        handleAddNewCall={handleAddNewCall}
                        formData={formData}
                        handleChange={handleChange}
                        handleCallsInputChange={handleCallsInputChange}
                        dataBucket={dataBucket}
                        userNames={userNames}
                      />
                    </div>
                  )}
                  {activeTab === 'create_emial' && (
                    <div className="tab-pane fade show active" id="email_track">
                      <CreateEmail
                        leadsEmails={leadsEmails}
                        handleDeleteEmail={handleDeleteEmail}
                        handleEmailsInputChange={handleEmailsInputChange} 
                        handleAddNewEmail={handleAddNewEmail}
                        formData={formData} 
                        handleChange={handleChange}
                        dataBucket={dataBucket}
                        userNames={userNames} 
                      />

                    </div>
                  )}
                  {activeTab === 'create_linkedin' && (
                    <div className="tab-pane fade show active" id="linkedin_track">
                      <CreateLinkedin
                        leadsLinkedins={leadsLinkedins}
                        handleDeleteLinkedin={handleDeleteLinkedin}
                        handleLinkedinsInputChange={handleLinkedinsInputChange} 
                        handleAddNewLinkedin={handleAddNewLinkedin}
                        formData={formData} 
                        handleChange={handleChange}
                        dataBucket={dataBucket}
                        userNames={userNames} 
                      />
                    </div>
                  )}
                  {activeTab === 'create_schedule' && (
                    <div className="tab-pane fade show active" id="create_schedule">
                      <CreateScheduledCall
                        leadsScheduledCalls={leadsScheduledCalls}
                        handleDeleteScheduledCall={handleDeleteScheduledCall}
                        handleScheduledCallsInputChange={handleScheduledCallsInputChange}
                        handleAddNewScheduledCall={handleAddNewScheduledCall}
                        formData={formData}
                        handleChange={handleChange}
                        dataBucket={dataBucket}
                        userNames={userNames}
                      />
                    </div>
                 )}
                {activeTab === 'create_otc' && (
                  <div className="tab-pane fade show active" id="create_otc">
                    <CreateOPS
                      leadsOPS={leadsOPS}
                      handleDeleteOPS={handleDeleteOPS}
                      handleOPSInputChange={handleOPSInputChange}
                      handleAddNewOPS={handleAddNewOPS}
                      formData={formData}
                      handleChange={handleChange}
                      dataBucket={dataBucket}
                      userNames={userNames}
                    />
                  </div>
                )}


                  {/* Add more tab contents here if necessary */}
                </div>
                <button type="submit" className="btn btn-primary">
                  Submit
                </button>
    </form>
  );
};

export default CreateSales;
