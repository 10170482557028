/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { Table, Row, Col, Button, message } from "antd";
import Swal from "sweetalert2";
import adminLeave, { getAdminLeaves, updateAdminRequest } from "../../../Slices/adminLeaveSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { AdminLeaveAddModelPopup } from "../../../components/modelpopup/AdminLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import LeaveFilter from "../../../components/LeaveFilter";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { GET_ADMIN_LEAVES, UPDATE_LEAVE_STATUS } from "../../../Queries/leaves";

const AdminLeave = () => {
  const dispatch = useDispatch()
  const employees = useSelector((state) => state.loginUserInfo.allUsers);
  console.log("employees", employees);
  const adminLeaves = useSelector((state) => state?.adminLeave?.leaves)
  console.log("adminLeaves",adminLeaves.remarks)
  const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails)
  console.log("loginUser", loginUser)
  const [originalLeaves, setOriginalLeaves] = useState([]);

  const sortedLeaves = [...adminLeaves]?.sort((a, b) => Number(b.createdAt) - Number(a.createdAt));

  const updatedAdminLeaves = sortedLeaves.map(leave => {
    const employee = employees.find(emp => emp.id === leave.employeeId);
    return { ...leave, username: employee ? employee.firstname + employee.lastname : null };
  }).sort((a, b) => b.createdAt - a.createdAt);
 const pendingleaves =  updatedAdminLeaves.filter((leave)=>leave.leaveStatus === "Pending")
  console.log("updatedAdminLeaves", pendingleaves)
  // const [adminLeave, setAdminLeave] = useState([]);
  const [isEditingBulk, setIsEditingBulk] = useState(false);

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const userId = parseJwt(token)?.id;

  const formatDate = (timestamp) => {
    if (!timestamp) return null;
    const d = new Date(Number(timestamp)); // Convert the timestamp to a Date object
    const day = String(d.getDate()).padStart(2, '0'); // Day as two digits
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Month (0-based, so add 1) as two digits
    const year = d.getFullYear(); // Full year
    return `${day}/${month}/${year}`; // Return in dd/mm/yyyy format
  };

  const currentDate = new Date();
  const currentStartDate = formatDate(currentDate); // Get current date in dd/mm/yyyy format
  const currentEndDate = formatDate(currentDate);
  const { data, loading, error, refetch } = useQuery(GET_ADMIN_LEAVES, {
    // variables: {
    //   employeeId: userId,
    // },
    fetchPolicy: "no-cache",
  });
  dispatch(setLoader(loading));
  const [updateLeaveStatus] = useMutation(UPDATE_LEAVE_STATUS, {
    onCompleted: (data) => {
      refetch();
    },
    onError: (error) => {
      // Handle error here
      console.error("Error updating leave status", error);
    }
  });

  const adminLea = data?.getLeaveRequests
  console.log("adminLea", adminLea)

  useEffect(() => {
    if (data?.getLeaveRequests) {
      const formattedLeaveRequests = data?.getLeaveRequests.map((request) => {
        //const status = request.endDate ? "Approved" : "Pending";
        return {
          ...request,
          //status,
          startingTime: formatDate(request?.startDate),
          endingTime: formatDate(request?.endDate),
        };

      });

      // Dispatch or set your formatted leave requests data
      dispatch(getAdminLeaves(formattedLeaveRequests));
    }
  }, [data, dispatch]);

  const handleEditClick = (record) => {
    setSelectedLeave(record); // Set selected leave data for editing
    setIsModalOpen(true); // Open the modal
  };
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedLeave(null); // Clear selected leave data
  };
  console.log("selectedLeave", selectedLeave)
  const today = new Date();
  const todayFormatted = `${String(today.getDate()).padStart(2, '0')}/${String(today.getMonth() + 1).padStart(2, '0')}/${today.getFullYear()}`;
  const convertTimestampToDate = (timestamp) => {
    const date = new Date(Number(timestamp));
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const approvedLeaveEmployees = updatedAdminLeaves.filter((leave) => {
    const leaveDate = convertTimestampToDate(leave.startDate);
    return leave.leaveStatus === "Approved" && leaveDate === todayFormatted;
  });

  const approvedEmployeeIds = [...new Set(approvedLeaveEmployees.map((leave) => leave.employeeId))];
  const numberOfEmployeesWithApprovedLeave = approvedEmployeeIds.length;
  const totalEmployees = employees.length;
  const remainingEmployees = totalEmployees - numberOfEmployeesWithApprovedLeave;
  const plannedLeaves = adminLeaves.filter((leave) => {
    const leaveDate = convertTimestampToDate(leave.startDate);
    return leave.leaveStatus === 'Approved' && leaveDate === todayFormatted;
  }).length;

  const unplannedLeaves = adminLeaves.filter((leave) => {
    const leaveDate = convertTimestampToDate(leave.startDate);
    return leave.leaveStatus === 'Declined' && leaveDate === todayFormatted;
  }).length;
  const pendingRequests = updatedAdminLeaves.filter((leave) => leave.leaveStatus === 'Pending').length;

  const toggleBulkEdit = () => {
    if (isEditingBulk) {
      const updatedRecords = adminLeaves
        .filter((admin) => admin.isUpdated)
        .map((admin) => ({
          leaveId: admin._id,
          newStatus: admin.leaveStatus,
          remarks: admin.remarks,
          approvedBy: loginUser?.firstname + " " + loginUser?.lastname
        }));

      console.log("Updated Records for leave Edit", updatedRecords);

      if (updatedRecords.length > 0) {
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to submit these changes.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, submit it!",
          cancelButtonText: "No, cancel",
        }).then(async (result) => {
          if (result.isConfirmed) {
            try {
              //   const approvedBy = loginUser?.firstname && loginUser?.lastname
              //   ? `${loginUser.firstname} ${loginUser.lastname}`
              //   : 'Unknown';

              // console.log("Approved By:", approvedBy); 
              await updateLeaveStatus({
                variables: {
                  leaveUpdates: updatedRecords,
                  //approvedBy
                },
              });
              console.log("Leave status updates submitted successfully!");
              message.success("Leave changes have been submitted!");
              dispatch(updateAdminRequest(adminLeaves.map(leave => ({
                ...leave,
                isUpdated: false
              }))));
            } catch (error) {
              console.error("Error submitting leave status updates", error);
              message.error("An error occurred while submitting the changes.");
            }
          } else {
            console.log("Submission canceled by user.");
          }
        });
      } else {
        message.info("No changes to submit.");
      }
    }

    setIsEditingBulk(!isEditingBulk); // Toggle the bulk edit mode
  };

  const handleClear = () => {
    setIsEditingBulk(false);
  
    // Reset leaveStatus to its original value (from originalLeaves)
    const resetLeaves = adminLeaves.map(leave => {
      const originalLeave = originalLeaves.find(orig => orig._id === leave._id);
      return {
        ...leave,
        leaveStatus: originalLeave ? originalLeave.leaveStatus : leave.leaveStatus,
        //remarks: originalLeave ? originalLeave.remarks : leave.remarks,
        isUpdated: false
      };
    });
  
    // Dispatch the reset leaves state
    dispatch(updateAdminRequest(resetLeaves));
    message.info("Changes have been cleared");
  };
  
  const handleStatusChange = (key, field, value) => {
    // Store the initial leave statuses when editing starts
    if (originalLeaves.length === 0) {
      setOriginalLeaves(adminLeaves.map(leave => ({
        _id: leave._id,
        leaveStatus: leave.leaveStatus
      })));
    }
  
    const updatedLeave = adminLeaves.map((leave) =>
      leave._id === key
        ? { ...leave, [field]: value, isUpdated: true }
        : leave
    );
  
    console.log("Updated Leave", updatedLeave);
    dispatch(updateAdminRequest(updatedLeave));
  };
  const handleChange = (key, field, value) => {
    // Store the initial leave statuses when editing starts
    if (originalLeaves.length === 0) {
      setOriginalLeaves(adminLeaves.map(leave => ({
        _id: leave._id,
        remarks: leave.remarks
      })));
    }
  
    const updatedLeave = adminLeaves.map((leave) =>
      leave._id === key
        ? { ...leave, [field]: value, isUpdated: true }
        : leave
    );
  
    console.log("Updated Leave", updatedLeave);
    dispatch(updateAdminRequest(updatedLeave));
  };
  
  const columns = [
    {
      title: "Employee",
      dataIndex: "username",
      // render: (text, record) => (
      //   <span className="table-avatar">
      //     <Link to="/profile" className="avatar">
      //       <img alt="" src={record.image} />
      //     </Link>
      //     <span className="table-avatar">
      //       <Link to="#" className="avatar"></Link>
      //       {text}
      //     </span>
      //   </span>
      // ),
      //sorter: (a, b) => a?.name.length - b.name.length,
    },

    {
      title: "Leave Type",
      dataIndex: "leaveType",
      render: (text) => <span>{text}</span>,

      //sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },

    {
      title: "From",
      dataIndex: "startDate",
      render: (text) => {
        const startDate = moment(parseInt(text)).format('DD/MM/YYYY');
        return <span>{startDate}</span>;
      },
      //sorter: (a, b) => a.startDate.length - b.startDate.length,
    },

    {
      title: "To",
      dataIndex: "endDate",
      render: (text) => {
        const endDate = moment(parseInt(text)).format('DD/MM/YYYY');
        return <span>{endDate}</span>;
      },
      //sorter: (a, b) => a.endDate.length - b.endDate.length,
    },

    {
      title: "No Of Days",
      dataIndex: "leaveDays",
      // render: (text, record) => {
      //   const startDate = moment(parseInt(record.startDate));
      //   const endDate = moment(parseInt(record.endDate));
      //   const noOfDays = endDate.diff(startDate, 'days');
      //   return <span>{noOfDays}</span>;
      // },
      //sorter: (a, b) => a.noofdays - b.noofdays,
    },
    {
      title: "Reason",
      dataIndex: "leaveReason",
      render: (text) => <span>{text}</span>,
      //sorter: (a, b) => a.reason.length - b.reason.length,
    },
    {
      title: "Status",
      dataIndex: "leaveStatus",
      render: (text, record) => {
        console.log("record", record)
        if (record.status === "false") {
          return "Deleted";
        }
        if (isEditingBulk) {
          return (
            <div style={{ width: "150px" }}>
              <select
                style={{
                  border: "none",
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                value={record.leaveStatus}
                onChange={(e) =>
                  handleStatusChange(record._id, "leaveStatus", e.target.value)
                }
                disabled={record.leaveStatus === "Approved" || !isEditingBulk}
              >
                <option value="Pending">Pending</option>
                <option value="Approved">Approved</option>
                <option value="Declined">Declined</option>
              </select>
            </div>
          );
        }
        return text;
      }
    },
    // {
    //   title: "Action",
    //   render: () => (
    //     <div className="dropdown dropdown-action text-end">
    //       <Link
    //         to="#"
    //         className="action-icon dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         <i className="material-icons">more_vert</i>
    //       </Link>
    //       <div className="dropdown-menu dropdown-menu-right">
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#edit_leave"
    //         >
    //           <i className="fa fa-pencil m-r-5" /> Edit
    //         </Link>
    //         <Link
    //           className="dropdown-item"
    //           to="#"
    //           data-bs-toggle="modal"
    //           data-bs-target="#delete"
    //         >
    //           <i className="fa-regular fa-trash-can m-r-5" /> Delete
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    //   sorter: true,
    // },
    {
      title: "Remarks",
      dataIndex: "remarks",
      render: (text, record) => {
        if (isEditingBulk) {
          return (
            <div style={{ width: "150px" }}>
              <textarea style={{
                  border: "none",
                  outline: "none",
                  padding: "5px",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                value={record.remarks || ""}
                onChange={(e) =>
                  handleChange(record._id, "remarks", e.target.value)
                }
                rows={2}
              />
            </div>
          );
        }
        return text;
      },
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, record) => {
        if (record.status === "false" || record.leaveStatus === "Declined") {
          return null;
        }

        return (
          <button
            className="btn btn-primary"
            // data-bs-toggle="modal"
            // data-bs-target="#edit_leave"
            onClick={() => handleEditClick(record)}
            disabled={record.leaveStatus !== 'Pending' && record.leaveStatus !== 'Declined'}
          >
            Edit
          </button>
        );
      },
    }

  ];

  const statsData = [
    {
      id: 1,
      title: "Today Presents",
      value: remainingEmployees, // This is the remaining employees
      subText: `Out of ${totalEmployees} employees`,
    },
    {
      id: 2,
      title: "Planned Leaves",
      value: plannedLeaves,
      subText: "Today",
    },
    {
      id: 3,
      title: "Unplanned Leaves",
      value: unplannedLeaves,
      subText: "Today",
    },
    {
      id: 4,
      title: "Pending Requests",
      value: pendingRequests,
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          {/* <Breadcrumbs
            maintitle="Leaves"
            title="Dashboard"
            subtitle="Leaves"
            // modal="#add_leave"
            name="Add Leave"
          /> */}
          {/* /Page Header */}
          {/* Leave Statistics */}
          <div className="row">
            {statsData.map((stat, index) => (
              <div className="col-md-3 d-flex" key={index}>
                <div className="stats-info w-100">
                  <h6>{stat.title}</h6>
                  {stat.subText ? (
                    <h4>
                      {stat.value} <span>{stat.subText}</span>
                    </h4>
                  ) : (
                    <h4>{stat.value}</h4>
                  )}
                </div>
              </div>
            ))}
          </div>
          <LeaveFilter />
          {/* /Leave Statistics */}
          <div className="row mt-3">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                <Row justify="end" style={{ marginBottom: 16 }}>
                  <Col>
                    <Button
                      onClick={toggleBulkEdit}
                      type="primary"
                      style={{ marginRight: 8 }}
                    >
                      {isEditingBulk ? "Submit" : "Edit "}
                    </Button>
                  </Col>
                  {isEditingBulk && (
                    <Col>
                      <Button
                        style={{ marginRight: 8 }} onClick={handleClear}>
                        Clear
                      </Button>
                    </Col>
                  )}
                </Row>
                <Table
                  columns={columns}
                  dataSource={updatedAdminLeaves}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>

        <AdminLeaveAddModelPopup
          leaveData={selectedLeave}
          isOpen={isModalOpen}
          onClose={handleModalClose}
          refetch={refetch} />

        {/* Delete Modal */}
        <DeleteModal Name="Delete Leaves" />
        {/* Delete Modal */}
      </div>
    </>
  );
};

export default AdminLeave;
