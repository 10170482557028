import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import DatePicker from "react-datepicker";
import { Select, message } from "antd";
import { useMutation } from "@apollo/client";
import { GET_HOLIDAY, CREATE_HOLIDAY, UPDATE_HOLIDAY } from "../../Queries/Holiday";
import { HOLIDAYS_DEPARTMENT } from "../../databucket";
import { renderOptionsformultiple } from "../../utilsdata";
import { useDispatch } from "react-redux";
import { addHoliday } from "../../Slices/holidaySlice";

export const AddHoliday = (props) => {
  const { setUsers, users, selectedHoliday, setSelectedHoliday, isOpen, onClose, refetch } = props
  console.log("selectedHoliday",selectedHoliday)
  const dispatch = useDispatch();
  const [form, setForm] = useState({
    name: "",
    department: [],
    date: ""
  });
console.log("form",form)

const [isDisabled,setIsDisabled] = useState(false);

const startTimer = ()=>{
  setIsDisabled(true)
  setTimeout(()=>{
    setIsDisabled(false)
  },5000)
}
  // const [CreateHoliday] = useMutation(CREATE_HOLIDAY, {
  //   refetchQueries: [{ query: GET_HOLIDAY }]
  // });
  const [CreateHoliday] = useMutation(CREATE_HOLIDAY, {
    onCompleted: (data) => {
      refetch();
      dispatch(addHoliday(data?.CreateHoliday))
      message.success("Holiday created successfully!");
      resetForm();
    },
    onError: (error) => {
      console.error("Error submitting leave request:", error);
      message.error("Failed to submit holiday. Please try again.");
    },
  })
  // const [UpdateHoliday] = useMutation(UPDATE_HOLIDAY, {
  //   refetchQueries: [{ query: GET_HOLIDAY }],
  // });
  const [UpdateHoliday] = useMutation(UPDATE_HOLIDAY, {
    onCompleted: (data) => {
      startTimer()
      console.log("response", data);
      refetch(); 
    
      onClose()
      //setForm({ name: "", department: [], date: "" });
    },
    onError: (error) => {
      console.error("Error updating holiday:", error);
      message.error("Failed to update holiday. Please try again.");
    },
  });


  useEffect(() => {
    if (selectedHoliday) {
      setForm({
        name: selectedHoliday.name,
        department: selectedHoliday.department ? selectedHoliday.department.split(',') : [], // Ensure it's an array
        date: selectedHoliday.date,
      });
    }
  }, [selectedHoliday]);
  const handleDepartmentChange = (selectedValues) => {
    setForm({
      ...form,
      department: selectedValues
    });
  };

  const handleChange = (e) => {
    setForm({ ...form, name: e.target.value });
  };

  const handleDateChange = (date) => {
    setForm({
      ...form, date
    });
  };


  let lastSubmitTime = 0; // Variable to track the last time submission was attempted

  const handleSubmit = async (e) => {
    e.preventDefault()
    // Get the current time
    const currentTime = new Date().getTime();
  
    // If less than 3 seconds have passed since the last submit, return early
    if (currentTime - lastSubmitTime < 3000) {
      return; // Prevent the submission if it happens before 3 seconds
    }
  
    // Update last submit time to prevent further submissions in the next 3 seconds
    lastSubmitTime = currentTime;
  
    // Check if all fields are filled
    if (!form.name || !form.department || !form.date) {
      startTimer()
      message.error("Please fill all fields before submitting.");
      return;
    }
    startTimer()
    // Check for holidays with the same name but a different date
    const existingHoliday = users.find(
      (holiday) => holiday.name === form.name && holiday.date !== form.date
    );
    if (existingHoliday) {
      message.error("A holiday with the same name but a different date already exists.");
      return;
    }
  
    // Check if there are no changes (if selectedHoliday exists, compare it with form values)
    if (selectedHoliday) {
      const selectedDepartment = Array.isArray(selectedHoliday.department)
        ? selectedHoliday.department
        : selectedHoliday.department.split(','); // If it's a string, split it into an array
  
      const isNoChange =
        selectedHoliday.name === form.name &&
        selectedDepartment.join(',') === form.department.join(',') &&
        selectedHoliday.date === form.date;
  
      if (isNoChange) {
        message.error("No changes detected.");
        return;
      }
    }
  
    // Prevent showing success toast again if already processed
  
    try {
      if (selectedHoliday) {
        // Update holiday if selectedHoliday exists
        const { data } = await UpdateHoliday({
          variables: {
            id: selectedHoliday._id,
            name: form.name,
            department: form.department.join(','), // Make sure department is an array
            date: form.date,
          },
        });
  
        message.success("Holiday Updated   successfully!");
        
      } else {
        // Create new holiday if selectedHoliday does not exist
        const { data } = await CreateHoliday({
          variables: {
            name: form.name,
            department: form.department.join(','), // Make sure department is an array
            date: form.date,
          },
        });
   
      
      
    
      }
  
      // Only show success message if it's a valid response
    
       
  
      onClose();
      // setForm({ name: "", department: [], date: "" });
    } catch (error) {
      console.error("Error during holiday operation:", error);
      message.error("Failed to process holiday request. Please try again.");
    }
  };

  

  const resetForm = () => {
    setForm({
      name: "",
      department: null,
      date: null
    })
    onClose()
  }

  const options = useSelector((state) => state.dataBucket.databucket);


  const handleEditSubmit = async () => {
    if (!selectedHoliday?.id || !selectedHoliday?.name || !selectedHoliday?.department || !selectedHoliday?.date) {
      message.error("Please fill all fields before submitting.");
      return;
    }
    try {
      const { data } = await UpdateHoliday({
        variables: {
          id: selectedHoliday.id,
          name: selectedHoliday.name,
          department: selectedHoliday.department,
          date: selectedHoliday.date,
        },
      });

      if (data?.UpdateHoliday?.status === "success") {
        message.success("Holiday updated successfully!");
        setUsers(users.map((holiday) =>
          holiday.id === selectedHoliday.id ? { ...selectedHoliday } : holiday
        ));
      } else {
        message.error(data?.UpdateHoliday?.message || "Failed to update holiday.");
      }

      setSelectedHoliday(null); // Reset selected holiday after update
    } catch (error) {
      message.error("Failed to update holiday. Please try again.");
    }
  };

  return (
    <div>
      <div id="add_holiday" className={`modal custom-modal fade ${isOpen ? "show" : ""}`}
        role="dialog"
        style={{ display: isOpen ? "block" : "none" }}>
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {selectedHoliday ? "Edit Holiday" : "Add Holiday"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={resetForm}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-block mb-3" style={{ width: "100%" }}>
                <label
                  htmlFor="department"
                  style={{ display: "block", marginBottom: "5px", width: "100%" }}
                >
                  DEPARTMENT
                </label>
                <Select
                  mode="multiple" 
                  allowClear
                  options={renderOptionsformultiple(options, { name: "HOLIDAYS_DEPARTMENT" }, HOLIDAYS_DEPARTMENT)}
                  placeholder="Select"
                  onChange={handleDepartmentChange}
                  value={form.department}
                  style={{ width: "100%" }}
                  menuPlacement="auto"
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Holiday Name <span className="text-danger">*</span>
                </label>
                <input className="form-control" type="text" name="name" value={form.name} onChange={handleChange} />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">
                  Holiday Date <span className="text-danger">*</span>
                </label>
                <div className="cal-icon">
                  <DatePicker
                    onChange={handleDateChange}
                    selected={form.date}
                    className="form-control datetimepicker"
                    type="date"
                    dateFormat="dd-MM-yyyy"
                  />
                </div>
              </div>

              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={handleSubmit}
                  disabled={isDisabled}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};