import React from "react";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Tooltip,message } from "antd";
import { useQuery,useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';  
import { setClients, addClient, updateClient } from "../../../Slices/clientSlice"; 
import {
  Avatar_06,
  Avatar_07,
  Avatar_14,
  Avatar_18,
  Avatar_19,
  Avatar_22,
  Avatar_26,
  Avatar_29,
} from "../../../Routes/ImagePath";
import { AllClientEditPopup } from "../../../components/modelpopup/UpdateClientModal";
import SearchBox from "../../../components/SearchBox";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ClientsFilter from "../../../components/ClientsFilter";
import { render } from "@testing-library/react";
import { GET_ALL_CLIENTS } from "../../../Queries/Users";
import { AllClientAddPopup } from "../../../components/modelpopup/AddClientModal";
import { useErrorLogger } from "../../../utils";
import { Ellipsis } from "react-bootstrap/esm/PageItem";


const ClientList = () => {
  const [clientsId,setClientsId] = useState()
  const dispatch = useDispatch();  
  const [open, setOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  const { logError } = useErrorLogger();
  const { data } = useQuery(GET_ALL_CLIENTS);
  console.log("client data",data)
  const clients = useSelector(state => state.clients.clients);  

  useEffect(() => {
    try{
    if (data) {
      if(clients.length < 2)
        {
          dispatch(setClients(data.getAllClients.response)); 
        }
    }}
    catch (error) {
      logError("ClientList","useEffect-dataFetching",error.message)
    }
  }, [data, dispatch]);

  const handleEdit = (row) => {
    setRowInfo(row);
    setOpen(true);
  };

  const renderWithTooltip = (text, maxLength) => {

    if (!text) return null;

    return (
      <Tooltip title={text}>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "clientName",
      ellipsis : {
        showTitle:false,
      },
      render: (text, record) => (
        <span className="table-avatar">
          {/* <Link to="/profile" className="avatar">
            <img alt="" src={record.Image} />
          </Link> */}
          {text}
        </span>
      ),
      sorter: (a, b) => a.clientName.length - b.clientName.length,
    },
    
    {
      title: "Contact Person",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      width:'20%',
    },
    {
      title: "Email",
      dataIndex: "emailId",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: (a, b) => a.emailId.length - b.emailId.length,
    },

    {
      title: "Mobile",
      dataIndex: "mobileNo",
      sorter: (a, b) => a.mobileNo.length - b.mobileNo.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text) => (
        <div className="dropdown">
          <div
            className="btn btn-white btn-sm btn-rounded"
            aria-expanded="false"
          >
            <i
              className={
                text === "Active"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          </div>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      render: (record) => (
        
          
            <Link
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_client"
              onClick={()=>handleEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> edit
            </Link>
          
        
      ),
      
      // sorter: true,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Clients"
            title="Dashboard"
            subtitle="Clients"
            modal="#add_client"
            name="Add Client"
            Linkname="/clients"
            Linkname1="/clients-list"
             view="list"
          />
          {/* /Page Header */}
          {/* <ClientsFilter /> */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                    <Table
                    className="table-striped"
                    style={{ maxWidth:'100%',tableLayout:'fixed' }}
                    columns={columns}
                    dataSource={clients}
                    pagination={{
                      showQuickJumper: true,
                    showSizeChanger: false,
                    }}
                    rowKey={(record) => record.id}
                    size="small"  
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AllClientAddPopup />
      <AllClientEditPopup clientData = {rowInfo}/>
      
    </>
  );
};

export default ClientList;
