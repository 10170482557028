import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal } from 'bootstrap';

const RequestDetails = () => {
    const requestDetails = useSelector((state) => state.orders.requestDetails);
    console.log("requestDe", requestDetails)
    const [requestDetailsData, setRequestDetailsData] = useState({
        id: '',
        quoteType: '',
        department: '',
        insuredName: '',
        noofQuotes: '',
        Auto: false,
        MotorCycle: false,
        Boat: false,
        DP3: false,
        HO3: false,
        HO4: false,
        HO5: false,
        HO6: false,
        existingPolicyInformation: [{
            currentCarrier: '',
            propertyType: '',
            propertyNew: '',
            policyNumber: '',
            effectiveDate: '',
            currentPremium: ''
        }],
        newQuoteInformation: [{
            policy: '',
            insuranceCarrier: '',
            eligibility: '',
            quotedPremium: '',
            remarks: ''
        }]
    })

    useEffect(() => {
        if (requestDetails) {
            setRequestDetailsData({
                id: requestDetails?.id || '',
                quoteType: requestDetails?.quoteType || '',
                department: requestDetails?.department || '',
                insuredName: requestDetails?.insuredName || '',
                noofQuotes: parseInt(requestDetails?.noofQuotes) || '',
                Auto: requestDetails?.Auto || '',
                MotorCycle: requestDetails?.MotorCycle || '',
                Boat: requestDetails?.Boat || '',
                DP3: requestDetails?.DP3 || '',
                HO3: requestDetails?.HO3 || '',
                HO4: requestDetails?.HO4 || '',
                HO5: requestDetails?.HO5 || '',
                HO6: requestDetails?.HO6 || '',
                existingPolicyInformation: requestDetails?.existingPolicyInformation || [],
                newQuoteInformation: requestDetails?.newQuoteInformation || []
            })
        }
    },[requestDetails])

    useEffect(() => {
        const modalElement = document.getElementById('request_details');
        if (modalElement) {
          // Initialize Bootstrap modal when component mounts
          const modal = new Modal(modalElement);
          // You can also manually control modal.show() and modal.hide() if needed
        }
      }, []);

    return (
        <>
            <div className="col-md-12 d-flex overflow-hidden">
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <div className="row">
                            <div className="pro-edit mb-2">
                                <Link
                                    data-bs-target="#request_details"
                                    data-bs-toggle="modal"
                                    className="edit-icon"
                                    to="#" 
                                >
                                    <i className="fa-solid fa-pencil"></i>
                                </Link>
                            </div>
                            <div className="col-md-6">
                                {/* <label htmlFor="" className="form-label">Request Type</label>
                                <input type="text" className="form-control"  value={employeeInfo?.quoteType || 'N/A'}/> */}
                                <ul className="personal-info">
                                    <li>
                                        <div className="title">Request Type</div>
                                        <div className="text">{requestDetailsData?.quoteType || 'N/A'}</div>
                                    </li>
                                    <li>
                                        <div className="title">Department</div>
                                        <div className="text">{requestDetailsData?.department || 'N/A'}</div>
                                    </li>

                                </ul>
                            </div>
                            <div className="col-md-6">
                                {/* <label htmlFor="" className="form-label">Request Type</label>
                                <input type="text" className="form-control"  value={employeeInfo?.quoteType || 'N/A'}/> */}
                                <ul className="personal-info">
                                    <li>
                                        <div className="title">Insured</div>
                                        <div className="text">{requestDetailsData?.insuredName || 'N/A'}</div>
                                    </li>
                                    <li>
                                        <div className="title">NoOf Quotes</div>
                                        <div className="text">{requestDetailsData?.noofQuotes || 'N/A'}</div>
                                    </li>

                                </ul>
                            </div>

                        </div>
                        {(requestDetailsData?.department == 'Personal Line') && <>
                            <div className="row mb-3 d-flex">
                                <div className="col-md-3">
                                    <ul className="personal-info">
                                        <li>
                                            <div className="text">{requestDetailsData?.Auto || "Auto"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.MotorCycle || "MotorCycle"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.Boat || "Boat"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.DP3 || "(DP3) Dwelling Fire"}</div>
                                        </li>

                                    </ul>
                                </div>

                            </div>
                            <div className="row mb-3">
                                <div className="col-md-3">
                                    <ul className="personal-info">
                                        <li>
                                            <div className="text">{requestDetailsData?.HO3 || "(HO3) Home Owners"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.HO4 || "(HO4) Renters"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.HO5 || "(HO5) Home Owners with higher Dwelling Limit"}</div>
                                        </li>
                                        <li>
                                            <div className="text">{requestDetailsData?.HO6 || "(HO6) Condo"}</div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </>
                        }
                        {(requestDetailsData?.department == "Personal Line") && <>
                            <div className="row">
                                <h5>Existing Policy Information</h5>
                                <div className="col-md-12 mt-3">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center",fontSize:"15px" }}>Current Carrier</th>
                                                <th style={{ textAlign: "center" }}>Policy Type</th>
                                                <th style={{ textAlign: "center" }}>Policy Number</th>
                                                <th style={{ textAlign: "center" }}>Effective Date</th>
                                                <th style={{ textAlign: "center" }}>Current Premium</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestDetailsData?.existingPolicyInformation?.length > 0 ? (
                                                requestDetailsData?.existingPolicyInformation.map((policy, index) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>{policy.currentCarrier}</td>
                                                        <td style={{ textAlign: "center" }}>{policy.propertyType}</td>
                                                        <td style={{ textAlign: "center" }}>{policy.policyNumber}</td>
                                                        <td style={{ textAlign: "center" }}>{policy.effectiveDate}</td>
                                                        <td style={{ textAlign: "center" }}>{policy.currentPremium}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">No existing policy information available.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </>}
                        {(requestDetailsData?.department == "Personal Line") && <>
                            <div className="row">
                                <h5>New Quote Information</h5>
                                <div className="col-md-12 mt-3">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>Policy</th>
                                                <th style={{ textAlign: "center" }}>Insurance Carrier</th>
                                                <th style={{ textAlign: "center" }}>Eligibility</th>
                                                <th style={{ textAlign: "center" }}>Quoted Premium</th>
                                                <th style={{ textAlign: "center" }}>Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {requestDetailsData?.newQuoteInformation?.length > 0 ? (
                                                requestDetailsData?.newQuoteInformation.map((quote, index) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>{quote.policy}</td>
                                                        <td style={{ textAlign: "center" }}>{quote.insuranceCarrier}</td>
                                                        <td style={{ textAlign: "center" }}>{quote.eligibility}</td>
                                                        <td style={{ textAlign: "center" }}>{quote.quotedPremium}</td>
                                                        <td style={{ textAlign: "center" }}>{quote.remarks}</td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="5">No new quote information available.</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </>}

                        {/* <div className="col-md-12">
                                <div className="card">
                                   <h5>Comments</h5>
                                    <div className="card-body">
                                        <div>
                                            <span>{employeeInfo?.comments}</span>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default RequestDetails;
