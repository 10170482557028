// Timer.js
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Starttask,
  startTimer,
  stopTimer,
  incrementTimer,
} from "../Slices/timerslice";
import { UPDATE_LOGINWORKHISTORY, UPDATE_WORKHISTORY } from "../Queries/Orders";
import { useMutation } from "@apollo/client";
import { addLatestReports } from "../Slices/employeereportslice";
import { useNavigate } from "react-router-dom";
function formatDateTime(receivedDate) {
  // Ensure the input is a valid Date object
  const now = new Date(receivedDate);

  // Format the date as DD/MM/YYYY
  const day = String(now.getDate()).padStart(2, "0");
  const month = String(now.getMonth() + 1).padStart(2, "0"); // Months are zero-based
  const year = now.getFullYear();

  // Format the time as HH:mm:ss AM/PM
  let hours = now.getHours();
  const minutes = String(now.getMinutes()).padStart(2, "0");
  const seconds = String(now.getSeconds()).padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";

  if (hours > 12) {
    hours -= 12; // Convert to 12-hour format
  } else if (hours === 0) {
    hours = 12; // Handle midnight
  }

  hours = String(hours).padStart(2, "0");

  // Combine everything into the desired format
  return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
}
const formatTime = (timestamp) => {
  const totalSeconds = Math.floor((Date.now() - timestamp) / 1000);
  const days = Math.floor(totalSeconds / (3600 * 24));
  const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  return (
    <span>
      {days > 0 && <span>{days}d </span>}
      {hours.toString().padStart(2, "0")}:{minutes.toString().padStart(2, "0")}:
      {seconds.toString().padStart(2, "0")}
    </span>
  );
};

const formatloginTime = (timeString) => {
  // console.log("loginTime",timeString)
  if (typeof timeString === "string") {
    const startTime = new Date(timeString).getTime();
    const currentTime = new Date().getTime();
    const totalSeconds = Math.floor((currentTime - startTime) / 1000);

    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return (
      <span>
        {days > 0 && <span>{days}d </span>}
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  } else {
    const startTime = timeString;
    const currentTime = new Date().getTime();
    const totalSeconds = Math.floor((currentTime - startTime) / 1000);

    const days = Math.floor(totalSeconds / (3600 * 24));
    const hours = Math.floor((totalSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    return (
      <span>
        {days > 0 && <span>{days}d </span>}
        {hours.toString().padStart(2, "0")}:
        {minutes.toString().padStart(2, "0")}:
        {seconds.toString().padStart(2, "0")}
      </span>
    );
  }

  return timeString;
};

const Timer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [updateWorkHistory, { data, loading, error }] =useMutation(UPDATE_WORKHISTORY);
  const [updateTimerHistory] = useMutation(UPDATE_WORKHISTORY);
  const [updateLoginWorkHistory] = useMutation(UPDATE_LOGINWORKHISTORY);
  const { isRunning, time, department, task, loginTime, requestId } = useSelector((state) => state.timer);
  const employeesData = useSelector((state) => state.employee.AllEmployees);
  const token = localStorage.getItem("token");
  const parseJwt = (token) => {
    try {
      return token ? JSON.parse(atob(token.split(".")[1])) : null;
    } catch (e) {
      return null;
    }
  };
  const userid = parseJwt(token)?.id;
  const specificEmployee = employeesData?.find(employee => employee.id === userid);
  const handleStartButtonClick = async () => {
    const payload = {
      task: "Available",
      time: Date.now(),
      department: "",
      requestId: "",
    };

    try {
      const Reportpayload = {
        userId: parseJwt(token).id,
        employeeName:specificEmployee.firstname + '' + specificEmployee.lastname,
        emailId: specificEmployee.email,
        requestId: 'N/A',
        reportingTo:specificEmployee.specificEmployee || 'N/A',
        clientName: 'Bluepalnit',
        requestIds:'N/A',
        processType: '',
        taskType: "Available",
        billable:"N/A",
        service:"N/A",
        requestCompleted:"N/A",
        requestId:"N/A",
        startTime: Date.now().toString(),
        startingTime: formatDateTime(Date.now()),
        status: 'Pending',
        endTime: '',
        overrideHistory:[],
    };
      const response = await updateWorkHistory({
        variables: {
          userId: parseJwt(token).id,
          emailId: "",
          requestId: "",
          clientName: "Bluepalnit",
          processType: "",
          taskType: "Available",
          email: "",
          subject: "",
          body: "",
          startTime: Date.now().toString(),
          endTime: "",
        },
      }).then(()=>{
        dispatch(addLatestReports(Reportpayload))
      });
      localStorage.removeItem("department");
      localStorage.removeItem("task");

      localStorage.removeItem("requestId");
    } catch (err) {
      console.error("Error updating work history:", err);
    }
    // localStorage.setItem('task', task);
    localStorage.setItem("time", Date.now());
    // localStorage.setItem('task', "Available");
    // dispatch(updateAReport(Reportpayload));
    dispatch(Starttask(payload));
  };
 

  useEffect(() => {
    let interval = null;
    if (loginTime) {
      // console.log("loginTime",loginTime)
      const startTime = new Date(loginTime).getTime();
      const currentTime = new Date().getTime();
      const totalSeconds = Math.floor((currentTime - startTime) / 1000);
      // console.log("totalSeconds",totalSeconds)
      if (totalSeconds >= 9 * 3600) {
        // if (totalSeconds >=  120) {
        updateLoginWorkHistory({
          variables: {
            id: userid,
            endTime: new Date().toISOString(),
          },
        });
        const logoutUpdatePayload = {
          userId: userid,
          endTime: Date.now().toString(),

          emailId: "",
          requestId: "",
          clientName: "Bluepalnit",
          processType: "",
          taskType: "Loggingout",
          email: "",
          subject: "",
          body: "",
          startTime: "",
        };

        // Call updateTimerHistory mutation before navigating to login
        updateTimerHistory({ variables: logoutUpdatePayload });
        localStorage.clear();
        alert("You Have Already Reached Maximum Time!");
        navigate("/");
      }
    }
    if (isRunning) {
      interval = setInterval(() => {
        dispatch(incrementTimer());
      }, 1000);
    } else if (!isRunning && time !== 0) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
  }, [isRunning, time, dispatch]);

  return (
    <div style={styles.container}>
      <div style={styles.timeDisplay}>
        <p style={{ fontSize: "10px", margin: "0" }}>Login Time</p>
        {formatloginTime(loginTime)}
      </div>
      {requestId && (
        <div style={styles.task}>
          <h6 style={{ color: "white", fontSize: "10px" }}>Working Id</h6>
          <p style={{ fontSize: "1rem" }}>{requestId}</p>
        </div>
      )}
      {department && (
        <div style={styles.task}>
          <h6 style={{ color: "white", fontSize: "10px" }}>Department</h6>
          <p style={{ fontSize: "1rem" }}>{department}</p>
        </div>
      )}
      <div style={styles.task}>
        <h6 style={{ color: "white", fontSize: "10px" }}>Task</h6>
        <p style={{ fontSize: "1rem" }}>{task}</p>
      </div>

      <div style={styles.timeDisplay}>
        <p style={{ fontSize: "10px", margin: "0" }}>{task} Time</p>
        {formatTime(time)}
      </div>

      <div style={styles.buttonContainer}>
        <button
          style={{ ...styles.button, ...styles.startButton }}
          onClick={() => dispatch(stopTimer())}
        >
          Start
        </button>
        {task !== "Available" && (
          <button
            style={{ ...styles.button, ...styles.stopButton }}
            onClick={() => handleStartButtonClick()}
            disabled={task == "Available"}
          >
            Stop
          </button>
        )}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    // width:'300px',
    color: "white",
  },
  timeDisplay: {
    fontSize: "1rem",
    width: "100px",
  },
  buttonContainer: {
    display: "flex",
    gap: "10px",
    margin: "0px 10px",
  },
  button: {
    fontSize: "1rem",
    color: "white",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
    transition: "background-color 0.3s",
    padding: "2px 8px",
  },
  startButton: {
    backgroundColor: "#4BB543",
    lineHeight: "16px",
    fontSize: "14px",
    padding: "6px 10px",
  },

  stopButton: {
    backgroundColor: "#fe3c3c",
  },
  task: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    margin: "0px 10px",
    justifyContent: "center",
  },
};

export default Timer;
