import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    leaves: [],
    adminLeave: null,
  };

const adminLeaveRequest = createSlice({
    name: "leaves",
    initialState,
    reducers: {
        getAdminLeaves: (state,action)=>{
            console.log("payload",action.payload)
            state.leaves = action.payload
        },
        updateAdminRequest: (state, action) => {
            console.log("leave",action.payload)
            state.leaves = action.payload;
        },
    }
});

export const {getAdminLeaves,updateAdminRequest} = adminLeaveRequest.actions;

export default adminLeaveRequest.reducer;