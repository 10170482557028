import React, { useEffect, useState,useRef } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { useNavigate, useParams } from "react-router-dom";
import { Table, Tooltip, message } from "antd";
import moment from 'moment';
import { GET_ALL_ORDERS } from "../../../Queries/Orders";
import { useDispatch, useSelector } from "react-redux";
import { Form } from 'react-bootstrap';
import { useErrorLogger } from "../../../utils";

import { FaCloudDownloadAlt } from "react-icons/fa";
import { getReports, getReportData } from "../../../Slices/reportSice";

const Report = ({ sidebarOpen }) => {
  const id = useParams();
  const dispatch = useDispatch();
  const { logError } = useErrorLogger();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [exportType, setExportType] = useState();
  const [exportOption, setExportOption] = useState('Select');
  const [fromDate, setFromDate] = useState('');
  const [toDate, setToDate] = useState('');
  const [tempFromDate, setTempFromDate] = useState('');
  const [tempToDate, setTempToDate] = useState('');
  const dropdownRef = useRef(null);

  const today = new Date();
  const twoYearsAgo = new Date(today);
  twoYearsAgo.setFullYear(today.getFullYear() - 2);
  const minFromDate = twoYearsAgo.toISOString().split('T')[0];


  const maxToDate = today.toISOString().split('T')[0];
  const orders = useSelector((state) => state?.reports?.reports);
  const TotalCount = useSelector((state) => state?.reports?.TotalCount);
  const [filteredOrders, setFilteredOrders] = useState([...orders]);
  const [isAllOrders, setIsAllOrders] = useState(false);
  const [loader, setLoader] = useState(true);
  const [filter, setFilter] = useState({ filter: "Inbox" });

  const { data, refetch, loading, error } = useQuery(GET_ALL_ORDERS, {
    variables: { page: page, pageSize: pageSize, isAllOrders: isAllOrders, filters: filter, fromDate: fromDate, toDate: toDate },
    fetchPolicy: "cache-and-network"
  });

  const datacontent = data && data.getAllOrders
  const response = datacontent?.response

  useEffect(() => {
    try{
    if (data && data.getAllOrders) {
      const { message: responseMessage, status, response } = data.getAllOrders;

      if (status === 200 && response.length === 0) {
        message.info({
          content: responseMessage,
          duration: 5,
          className: 'custom-toast-center',
        });
      }
    }
  }

    catch (error) {
      message.error({
        content: 'An error occurred while fetching the data.',
        duration: 5,
        className: 'custom-toast-center',
      });
      logError("Reports","use-effect data fetching",error.message);
    }
  }, [data, error]);
  const renderWithTooltip = (text, maxLength) => {

    if (!text) return null;

    return (
      <Tooltip title={text}>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </Tooltip>
    );
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setDropdownOpen(false); // Close dropdown if click is outside of the dropdown
    }
  };

  useEffect(() => {
    if (dropdownOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownOpen]);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const statusHandler = (event) => {
    const selectedStatus = event.target.value;
    setFilter({ filter: selectedStatus })
    setFilteredStatus(selectedStatus)
    setExportOption('Select')
    setIsAllOrders(false)
    setFromDate('')
    setToDate('')
    setTempFromDate('')
    setTempToDate('')
    setPage(1);
    setTimeout(() => {
      refetch();
    }, 0)

  }

  const [filteredStatus, setFilteredStatus] = useState("Inbox");

  useEffect(() => {

    const fetchData = async () => {
      setLoader(true)
      if (datacontent?.response?.length > 0) {
        dispatch(getReports(datacontent.response));
        dispatch(getReportData(datacontent.response));
      }
      setLoader(false)
    };
    fetchData();
  }, [datacontent, dispatch])

  useEffect(() => {
    setFilteredOrders(orders);
  }, [orders])

  useEffect(() => {
    try{
    if (data && data?.message && data?.response && data?.response?.length === 0) {
      message.info({
        content: data?.message,
        duration: 5,
        className: 'custom-toast-center',
      });
    }
  }
    catch(error)
    {
      logError("Reports","use-effect data fetching",error.message);
    }
  }, [data]);

  const handleTableChange = (pagination) => {
    setPage(pagination);

  };

  const handleDropdownChange = (event) => {
    setExportOption(event.target.value);
    setPage(1);

    if (event.target.value === "All") {
      setTempFromDate('');
      setTempToDate('');
      setFromDate('');
      setToDate('');
      setIsAllOrders(true);

    } else if (event.target.value === "Range") {
      setIsAllOrders(false);
      setTempFromDate('')
      setTempToDate('')

    } else {
      setTempFromDate('');
      setTempToDate('');
      setFromDate('');
      setToDate('');
      setIsAllOrders(false);
    }
    refetch();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (exportOption === 'Range') {
      if (!tempFromDate || !tempToDate) {
        message.info({
          content: 'please select both from and to Dates.',
          duration: 5,
          className: 'custom-toast-center',
        });;
        return;
      }
    }
    setFromDate(tempFromDate)
    setToDate(tempToDate)
    setIsAllOrders(true)
  };


  function formatDateTime(timestamp) {
    const date = new Date(parseInt(timestamp));
    const options = {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
      timeZone: 'Asia/Kolkata',
    };
    return date.toLocaleString('en-IN', options);
  }

  const getDateTime = (timeValue) => {

    const startDate = new Date(timeValue).toLocaleDateString();
    const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

    const dateTimeString = `${startDate} ${startTimeString}`;
    console.log("after converting", dateTimeString)

    return dateTimeString;
  }
  const getDueTime = (timeValue, typeValue) => {

    const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

    console.log("foramatted start date", formattedDateTime)

    const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');

    console.log("time aDDED", newDateTime);
    return newDateTime;
  }
  const GetDueDateTemp = (obj) => {
   let dateValue;
    try{
      let priority = obj?.priority ? obj?.priority : 'On Demand';
      let date = obj?.date;
    if (obj?.body?.toLowerCase().includes("urgent") || obj?.body?.toLowerCase().includes("rush") || obj?.subject?.toLowerCase().includes("urgent") || obj?.subject?.toLowerCase().includes("rush")) {
      priority = 'Rush';
    }

    if (priority == 'Rush') {

      dateValue = getDateTime(getDueTime(parseInt(date), 2))

    } else if (priority == 'Bulk') {
      dateValue = getDateTime(getDueTime(parseInt(date), 48));
    } else {
      dateValue = getDateTime(getDueTime(parseInt(date), 24));
    }
  }
  catch(error)
  {
    logError("Reports","GetDueDateTemp",error.message);
  }
    return dateValue
  }

  const priorityHandler = (record) => {
    let priority = 'On Demand';
    try{
    const { body, subject } = record;
    if (body?.toLowerCase().includes("urgent") ||
      body?.toLowerCase().includes("rush") ||
      subject?.toLowerCase().includes("urgent") ||
      subject?.toLowerCase().includes("rush")) {

      priority = 'Rush';
    }
  }
  catch(error)
  {
    logError("Reports","priorityHandler",error.message);
  }
    return priority;
  };


  const getTimeToString = (diff) => {
    const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 30);
    const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((diff / (1000 * 60)) % 60);
    const seconds = Math.floor((diff / 1000) % 60);

    const formattedTime = [

      months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '',
      days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
      hours > 0 ? `${hours % 24} hr${hours > 1 ? 's' : ''}` : '',
      minutes > 0 ? `${minutes % 60} min${minutes > 1 ? 's' : ''}` : '',
      seconds > 0 ? `${seconds % 60} sec${seconds > 1 ? 's' : ''}` : '',

    ].filter(Boolean).join(' ');
    return formattedTime;
  }



  let rowsWithSerialNumber = [];

  if (response && response?.length > 0) {


    rowsWithSerialNumber = filteredOrders.map((row, index) => {

      return {
        sno: (page - 1) * pageSize + index + 1,
        dateRecieved: formatDateTime(row.date),
        CompletedDate: row.completedDate?formatDateTime(row.completedDate):" ",
        Totaltime: getTimeToString(row.TotalTime),
        Priority: row.priority ? row.priority : priorityHandler(row),
        DueDate: row.dueDate ? formatDateTime(row.dueDate) : GetDueDateTemp(row),
        Processedby:row.pb?row.pb:" ",
        ProcessType:row.processType?row.processType:" ",
        RequestStatus:row.requestStatus?row.requestStatus:" ",
        RequestId:row.requestId?row.requestId:" ",
        fromPendingValue: (row.fromPending === true) ? 'Yes' : 'No',
        instructionsValue: (row.instructions === true) ? 'Yes' : 'No',
        QuoteType :row.quoteType?row.quoteType:" ",
        Department :row.department?row.department:" ",
        InsuredName : row.insuredName?row.insuredName: " ",
        NoofQuotes : row.noofQuotes?row.noofQuotes:" ",
        TrueValues : row.trueValues?row.trueValues: " ",
        Comments:row.comments?row.comments:" ",
        ...row,
      }
    });
  }



  const columns = [
    {
      title: "#",
      dataIndex: "sno",
    },

    {
      dataIndex: "requestId",
      title: "Request #",
    },
    {
      title: "Recieved Date",
      dataIndex: "date",
      width: 300,

      render: (text) => formatDateTime(text)
    },
    {
      dataIndex: "dueDate",
      title: "Due Date",
      render: (value, record) => value ? formatDateTime(value) : GetDueDateTemp(record),
    },
    {
      dataIndex: "completedDate",
      title: "Completed date",

      render: (value, record) => value ? formatDateTime(value) : "",
      // render: (text) => {
      //   if (!text) return "";
      //   const formattedDate = formatDateTime(text);
      //   return formattedDate !== "Invalid Date" ? formattedDate : "";
      // }

    },
    {
      dataIndex: "priority",
      title: "Priority",
      render: (value, record) => {
        const priorityValue = value != null ? value : priorityHandler(record);


        let backgroundColor = ' #32cd32';
        if (value === 'Rush') {
          backgroundColor = ' #ff0000';
        } else if (value === 'On Demand') {
          backgroundColor = '#f59a73';
        } else if (value === 'Bulk') {
          backgroundColor = ' #add8e6';
        }


        return (
          <div style={{
            backgroundColor: backgroundColor,
            border: '1px solid #d6d6d6',
            paddingRight: '8px',
            display: 'flex',
            alignItems: 'center',
            color: 'black',
            borderRadius: 20,
            paddingLeft: '8px',
            paddingTop: '4px',
            paddingBottom: '4px',
          }}>

            <div style={{
              backgroundColor: backgroundColor,
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              marginRight: '5px'
            }}></div>
            {priorityValue}
          </div>
        );
      }

    },
    {
      dataIndex: "processType",
      title: "Process",


    },
    {
      dataIndex: 'pb',
      title: "Processed By",


    },
    {
      dataIndex: "requestStatus",
      title: "Request Status",


    },
    {
      dataIndex: "radioInfo",
      title: "Request Current Folder",


    },
    {
      dataIndex: "fromPendingValue",
      title: "From Pending",

    },

    {
      dataIndex: "instructionsValue",
      title: "Instructions",


    },

    {
      dataIndex: "subject",
      title: "Email Summary",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: false,
    },
    {
      dataIndex: "from",
      title: "Received From",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: false,


    },
    {
      dataIndex: "to",
      title: "Send To",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: false,


    },
    {
      dataIndex: "quoteType",
      title: "Quote Type",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: false,


    },

    {
      dataIndex: "department",
      title: "Department",


    },
    {
      dataIndex: "insuredName",
      title: "Insured Name",

    },
    {
      dataIndex: "noofQuotes",
      title: "No of Quotes",

    },

    {
      dataIndex: "trueValues",
      title: "Check",
    },
    {
      dataIndex: "comments",
      title: "Comments",
      render: (text) => (
        <span style={{ fontSize: '12px' }}>
          {renderWithTooltip(text, 8)}
        </span>
      ),
      sorter: false,

    },

    {
      dataIndex: "TotalTime",
      title: "Total Hours",
      render: (text) => getTimeToString(text)
    },

  ];

  const simulatePrintDialog = (dataToExport, selectedColumns) => {

    try{
    const printableContent = document.createElement("div");
    printableContent.style.width = "100%";
    printableContent.style.margin = "0 auto";
    printableContent.style.padding = "5px";
    printableContent.style.fontFamily = "Arial, sans-serif";
    printableContent.style.fontSize = "5px";


    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.border = "1px solid #000";
    table.style.borderCollapse = "collapse";


    const thead = document.createElement("thead");
    const headerRow = document.createElement("tr");

    selectedColumns.forEach(col => {
      const th = document.createElement("th");
      th.style.padding = "2px";
      th.style.textAlign = "left";
      th.style.border = "1px solid #ddd";
      th.innerText = col.toUpperCase();
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);


    const tbody = document.createElement("tbody");
    dataToExport.forEach(row => {
      const rowElement = document.createElement("tr");
      selectedColumns.forEach(col => {
        const td = document.createElement("td");
        td.style.padding = "2px";
        td.style.textAlign = "left";
        td.style.border = "1px solid #ddd";
        td.innerText = row[col];
        rowElement.appendChild(td);
      });
      tbody.appendChild(rowElement);
    });

    table.appendChild(tbody);
    printableContent.appendChild(table);


    const printStyle = document.createElement("style");
    printStyle.innerHTML = `
      body * {
        visibility: hidden;
      }
      #print-content, #print-content * {
        visibility: visible;
      }
      #print-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
      table {
        margin-top: 5px;
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
      }
      th, td {
        padding: 2px;
        text-align: left;
        border: 1px solid #ddd;
      }
    `;
    document.head.appendChild(printStyle);


    printableContent.id = "print-content";
    document.body.appendChild(printableContent);


    window.print();


    document.body.removeChild(printableContent);
    document.head.removeChild(printStyle);}
    catch(error)
    {
      logError("Reports","simulatePrintDialog",error.message);
    }
  };



  const handleExport = (fileFormat) => {
    const selectedColumns = [
      "sno",
      "RequestId",
      "dateRecieved",
      "DueDate",
      "CompletedDate",
      "Priority",
      "ProcessType",
      "Processedby",
      "RequestStatus",
      "radioInfo",
      "fromPendingValue",
      "instructionsValue",
      "subject",
      "from",
      "to",
      "QuoteType",
      "Department",
      "InsuredName",
      "NoofQuotes",
      "TrueValues",
      "Comments",
      "Totaltime",
    ];

    setDropdownOpen(false);
    let dataToExport;


    dataToExport = rowsWithSerialNumber;

    if (dataToExport.length > 0) {
      if (dataToExport.length > 0) {
        if (fileFormat === "CSV") {

          const csvData = convertToCSV(dataToExport, selectedColumns);
          downloadCSV(csvData, `Report.csv`);
        } else if (fileFormat === "PDF") {

          simulatePrintDialog(dataToExport, selectedColumns);
        }

      } else {
        message.info({
          content: 'No data available for export.',
          duration: 5,
          className: 'custom-toast-center',
        });
      }
    };


  }



  const convertToCSV = (responseArray, selectedColumns) => {
    try{
    if (!responseArray || responseArray.length === 0) return '';

    const headers = selectedColumns.join(",") + "\n";

    const rows = responseArray.map(row => {
      return selectedColumns
        .map(column => {
          let value = row[column];


          if (typeof value === 'number' || (typeof value === 'string' && value.length > 10)) {
            value = value.toString().padEnd(15, ' ');
          }

          const escapedValue = ('' + value).replace(/"/g, '""');
          return `"${escapedValue}"`;
        })
        .join(",");
    }).join("\n");

    return headers + rows;
  }
  catch(error)
  {
    logError("Reports","convertToCSv",error.message);
  }
  };



  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };


  return (
    <div className={`page-wrapper ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="content container-fluid">

        <div>
          <div>
            <div className="d-flex justify-content-between align-items-center mb-3">

              <div className="d-flex gap-2 flex-grow-1 align-items-center">
                <div className="form-group mt-1">
                  <Form.Select
                    size="sm"
                    style={{ width: '150px', height: "39px" }}
                    value={filteredStatus}
                    onChange={statusHandler}
                    displayEmpty
                  >
                    <option value="Inbox">Inbox</option>
                    <option value="Email Needed">Email Needed</option>
                    <option value="Discard">Discard</option>
                    <option value="Pending">Pending</option>
                    <option value="Completed">Completed</option>
                    <option value="Instructions">Instructions</option>
                    <option value="">All</option>
                  </Form.Select>
                </div>

                <div className="form-group mt-1">
                  <select
                    id="exportDropdown"
                    className="form-select"
                    value={exportOption}
                    onChange={handleDropdownChange}
                  >
                    <option value="Select">Select</option>
                    <option value="All">All Records</option>
                    <option value="Range">Range</option>
                  </select>
                </div>

                {exportOption === 'Range' && (
                  <div className="d-flex gap-2">
                    <div style={{ marginBottom: '25px' }}>
                      <label htmlFor="fromDate" style={{ display: 'block', marginBottom: '5px' }}>
                        From Date 121:
                      </label>
                      <input
                        type="date"
                        id="fromDate"
                        value={tempFromDate}
                        onChange={(e) => setTempFromDate(e.target.value)}
                        min={minFromDate}
                        max={tempToDate || maxToDate}
                        style={{ padding: '10px', width: '100%', borderRadius: '5px', border: '1px solid #ccc', height: '39px' }}
                      />
                    </div>
                    <div style={{ marginBottom: '25px' }}>
                      <label htmlFor="toDate" style={{ display: 'block', marginBottom: '5px' }}>
                        To Date:
                      </label>
                      <input
                        type="date"
                        id="toDate"
                        value={tempToDate}
                        onChange={(e) => setTempToDate(e.target.value)}
                        min={tempFromDate}
                        max={maxToDate}
                        style={{ padding: '10px', width: '100%', borderRadius: '5px', border: '1px solid #ccc', height: '39px' }}
                      />
                    </div>
                    <button className="bg-primary mb-1.75"
                      onClick={handleSubmit}
                      style={{
                        marginTop: '27px',
                        padding: '10px 15px',
                        borderRadius: '5px',
                        border: '1px solid #ccc',
                        color: '#fff',
                        cursor: 'pointer',
                        height: '39px',
                        flexShrink: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      Apply
                    </button>
                  </div>
                )}
              </div>


              <div className="d-flex align-items-center">
  <div className="btn-group" ref={dropdownRef}>
    <button
      className="btn btn-primary mb-2"
      type="button"
      onClick={toggleDropdown}
      style={{ paddingRight: '1.5rem', borderRadius: '6px' }}
    >
      <FaCloudDownloadAlt /> Export
    </button>

    {dropdownOpen && (
      <ul className="dropdown-menu show" style={{ display: 'block', position: 'absolute' }}>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleExport('CSV');
            }}
          >
            CSV
          </a>
        </li>
        <li>
          <a
            className="dropdown-item"
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleExport('PDF');
            }}
          >
            PDF
          </a>
        </li>
      </ul>
    )}
  </div>

  <style jsx>{`
    .dropdown-menu {
      list-style-type: none;
      padding: 0;
      margin: 0;
      position: absolute;
      background-color: #fff;
      border: 1px solid #ccc;
      width: auto; /* Adjusted width to auto */
      min-width: 120px; /* Set a minimum width to match the button */
      z-index: 1;
      box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
      top: 35px; /* Adjust this value to move the dropdown further down */
      right: 0; /* Align dropdown to the button */
    }

    .dropdown-menu li {
      padding: 8px;
      cursor: pointer;
    }

    .dropdown-menu li:hover {
      background-color: #f0f0f0;
    }
  `}</style>
</div>



            </div>
          </div>


          <div className="row">
            <div className="col-md-12">


              <div className="table-responsive" style={{ width: "100%", overflowX: "auto", backgroundColor: "#f9f9f9" }}>
                <Table
                  rowKey={(record) => record.id}
                  style={{ width: "100%", tableLayout: "auto" }}
                  columns={columns}
                  dataSource={rowsWithSerialNumber}
                  pagination={{
                    current: page,
                    pageSize: pageSize,
                    total: datacontent?.totalRecords || 0,
                    onChange: handleTableChange,
                    showQuickJumper: true,
                    showSizeChanger: false,
                    count: TotalCount,
                    showTotal: (count, range) => `${range[0]}-${range[1]} of ${count}`
                  }}
                  loading={loading}
                  scroll={{ x: false }}
                  size="small"
                  rowClassName={() => "single-row-color"}
                />
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>

  );

};

export default Report;