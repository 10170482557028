import React, { useState, useEffect } from 'react';
import ReusableTable from '../../../components/ReusableTable';
import { useSelector,useDispatch } from 'react-redux';
import { selectoverRideData } from '../../../Slices/employeereportslice';
import { fetchOverrideData } from '../../../Slices/employeereportslice';


const OverwritingHistory = () => {
  const data = useSelector(selectoverRideData);
  const dispatch = useDispatch();
  console.log("data",data)
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  useEffect(() => {
    dispatch(fetchOverrideData()); 
  }, [ ])


  const columns = [
    {
      title: 'S No',
      dataIndex: 'sno',
      key: 'sno',
    },
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'OverRidden Task',
      dataIndex: 'taskChange',
      key: 'taskChange',
    },
    {
      title: 'Start Date Change',
      dataIndex: 'startDateChange',
      key: 'startDateChange',
      render: (text) => text ? new Date(text).toLocaleDateString() : '-', // Format date if present
    },
    {
      title: 'Due Date Change',
      dataIndex: 'endDateChange',
      key: 'endDateChange',
      render: (text) => text ? new Date(text).toLocaleDateString() : '-', // Format date if present
    },
    {
      title: 'Updated By',
      dataIndex: 'username',
      key: 'username',
    },
    
  ];

  // Example of data, you would usually fetch this from an API
  const fetchData = (page, pageSize) => {
    setLoading(true);
    // Simulating an API call with setTimeout
    setTimeout(() => {
      const newData = Array.from({ length: pageSize }, (_, index) => ({
        key: index + 1 + (page - 1) * pageSize,
        id: `${index + 1 + (page - 1) * pageSize}`,
        taskChange: `Task ${index + 1}`,
        startDateChange: new Date().toISOString(),
        dueDateChange: new Date().toISOString(),
        username: `User ${index + 1}`,
        dateTime: new Date().toISOString(),
      }));

      setDataSource(newData);
      setTotalRecords(100); // Assume there are 100 records in total
      setLoading(false);
    }, 1000);
  };

  // Handle page change
  const onPageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
    fetchData(page, pageSize);
  };

  useEffect(() => {
    fetchData(currentPage, pageSize);
  }, []); // Empty dependency array means it runs once on mount

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <h1>Overwriting History</h1>
        <ReusableTable
          columns={columns}
          dataSource={data}
          loading={loading}
          currentPage={currentPage}
          pageSize={pageSize}
          // totalRecords={totalRecords}
          onPageChange={onPageChange}
          rowKey="key" // Ensure 'key' matches the row key in the dataSource
          // showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
          // isPaginationEnabled={true}
        />
      </div>
    </div>
  );
};

export default OverwritingHistory;
