

import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    page: {
        padding: 20,
        fontSize: 10,
    },
    headerContainer: {
        textAlign: "center",
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 10,
        backgroundColor: '#DDEBF6',
        padding: 5,
    },
    fullWidthRow: {
        width: '100%',
        backgroundColor: '#DDEBF6',
        borderWidth: 1,
        borderColor: '#000',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 5,
        marginBottom: 1,
    },
    titleText: {
        fontWeight: 'bold',
        fontSize: 12,
    },
    columnsContainer: {
        flexDirection: 'row',
        marginBottom: 5,
    },
    column: {
        width: '32.83%',
        borderWidth: 1,
        borderColor: '#000',
        padding: 5,
        flex: 1,
        justifyContent: 'space-between',  
    },
    columnSpacer: {
        width: '0.5%',
    },
    columnHeader: {
        backgroundColor: '#f0f0f0',
        justifyContent: 'center',
        alignItems: 'center',
        paddingVertical: 3,
        borderBottomWidth: 1,
        borderColor: '#000',
        backgroundColor: '#DDEBF6',
    },
    columnHeaderText: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 2,
    },
    textRow: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 2,
    },
    labelText: {
        fontWeight: 'normal',
    },
    amountText: {
        fontWeight: 'bold',
    },
    horizontalLine: {
        borderBottomWidth: 1,
        borderBottomColor: '#000',
        marginTop: 5,
        marginBottom: 2,
    },
    totalRow: {
        fontWeight: 'bold',
        marginTop: 2,
    },
    columnContent: {
        flex: 1,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 2,
    }
});

const Payslip = ({ employee, totals, year, month }) => (
    <Document>
        <Page size="A4" style={styles.page}>

            <View style={styles.headerContainer}>
                <Text>PAYSLIP - {month}, {year}</Text>
            </View>

            <View style={styles.fullWidthRow}>
                <Text style={styles.titleText}>Employee Information</Text>
            </View>

            <View style={styles.columnsContainer}>
                <View style={styles.column}>
                    <Text style={styles.label}>Employee ID:</Text>
                    <Text>{employee.employeeId}</Text>

                    {/* <Text style={styles.label}>Date of Joining:</Text>
                    <Text>01-Jan-2023</Text>

                    <Text style={styles.label}>PAN:</Text>
                    <Text>ABCDE1234F</Text>

                    <Text style={styles.label}>LWP:</Text>
                    <Text>0 Days</Text> */}
                </View>

                <View style={styles.columnSpacer} />

                <View style={styles.column}>
                    <Text style={styles.label}>Employee Name:</Text>
                    <Text>{employee.employeeName}</Text>
                </View>

                <View style={styles.columnSpacer} />

                <View style={styles.column}>
                    <Text style={styles.label}>Designation:</Text>
                    <Text>{employee.designation}</Text>
                </View>
            </View>

            <View style={styles.columnsContainer}>
                <View style={styles.column}>
                    <View style={styles.columnHeader}>
                        <Text style={styles.columnHeaderText}>CTC Rates (₹)</Text>
                    </View>
                    {/* <Text style={styles.textRow}>Basic: {employee.ctcBasic}</Text> */}
                    <View style={styles.row}>
                        <Text>Basic:</Text>
                        <Text>{employee.ctcBasic}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>HRA:</Text>
                        <Text>{employee.ctcHRA}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Medical Allowance:</Text>
                        <Text>{employee.ctcMedical}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Special Allowance:</Text>
                        <Text>{employee.ctcSpecial}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Incentives:</Text>
                        <Text>{employee.ctcIncentives}</Text>
                    </View>
                    <View style={styles.row}>
                        <Text>Night Shift Allowance:</Text>
                        <Text>{employee.ctcNightShift}</Text>
                    </View>
                    <View style={styles.horizontalLine} />
                    <View style={styles.row}>
                        <Text style={styles.totalRow}>Total:</Text>
                        <Text style={styles.totalRow}>₹ {totals.totalCTC}</Text>
                    </View>
                </View>

                <View style={styles.columnSpacer} />

                <View style={styles.column}>
                    <View style={styles.columnHeader}>
                        <Text style={styles.columnHeaderText}>Earnings (₹)</Text>
                    </View>
                    {/* <Text style={styles.textRow}>Basic: {employee.earningBasic}</Text> */}
                    <View style={styles.row}><Text>Basic:</Text><Text>{employee.earningBasic}</Text></View>
                    <View style={styles.row}><Text>HRA:</Text><Text>{employee.earningHRA}</Text></View>
                    <View style={styles.row}><Text>Medical:</Text><Text>{employee.earningMedical}</Text></View>
                    <View style={styles.row}><Text>Special Allowance:</Text><Text>{employee.earningSpecial}</Text></View>
                    <View style={styles.row}><Text>Incentives:</Text><Text>{employee.earningIncentives}</Text></View>
                    <View style={styles.row}><Text>Night Shift:</Text><Text>{employee.earningNightShift}</Text></View>
                    <View style={styles.row}><Text>Performance Bonus:</Text><Text>{employee.performanceBonus}</Text></View>
                    <View style={styles.horizontalLine} />
                    <View style={styles.row}><Text style={styles.totalRow}>Total:</Text><Text style={styles.totalRow}>₹ {totals.totalEarnings}</Text></View>
                </View>

                <View style={styles.columnSpacer} />

                <View style={styles.column}>
                    <View style={styles.columnHeader}>
                        <Text style={styles.columnHeaderText}>Deductions (₹)</Text>
                    </View>
                    <View style={styles.row}><Text>Professional Tax:</Text><Text>{employee.professionalTax}</Text></View>
                    <View style={styles.row}><Text>Tardy Arrival:</Text><Text>{employee.tardyArrival}</Text></View>
                    <View style={styles.horizontalLine} />
                    <View style={styles.row}><Text style={styles.totalRow}>Total:</Text><Text style={styles.totalRow}>₹ {totals.totalDeductions}</Text></View>
                </View>
            </View>

            <View style={styles.fullWidthRow}>
                <Text style={styles.titleText}>
                    Net Payment = ₹ {totals.totalEarnings - totals.totalDeductions}
                </Text>
            </View>

        </Page>
    </Document>
);

export default Payslip;
