// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sales-card-container {
  background-color: #efefef;
  border-right: 1px solid transparent;
  position: fixed;
  font-size: 20px;
  font-weight: bold;
  top: 60px;
  bottom: 0;
  left: 0;
  width: 250px;
  z-index: 0;
  transition: all 0.2s ease;
  padding: 15px;
  overflow-x: hidden;
  max-height: calc(100vh - 60px);
  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, 0.06);
  color: #f5f5f5;
  border: none;
}

.full-width {
  width: 100%;
}

.partial-width {
  width: 82%;
  margin-left: auto;
}

@media screen and (min-width: 1150px) and (max-width: 1500px) {
  .partial-width {
    width: 82%;
    margin-left: auto;
  }
}
@media screen and (min-width: 1501px) and (max-width: 1799px) {
  .partial-width {
    width: 85%;
    margin-left: auto;
  }
}
@media screen and (min-width: 1800px) {
  .partial-width {
    width: 88%;
    margin-left: auto;
  }
}
.custom-scrollbar::-webkit-scrollbar {
  width: 5px;
  /* Default width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Default thumb color */
  border-radius: 4px;
}

.filter-drop {
  background-color: #d0e8ff;
}

.item-label {
  margin-bottom: -5px;
}`, "",{"version":3,"sources":["webpack://./src/views/pages/Sales1/Frames.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,mCAAA;EAEA,eAAA;EACA,eAAA;EACA,iBAAA;EACA,SAAA;EACA,SAAA;EACA,OAAA;EACA,YAAA;EACA,UAAA;EACA,yBAAA;EACA,aAAA;EACA,kBAAA;EACA,8BAAA;EACA,2CAAA;EACA,cAAA;EACA,YAAA;AAAF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,UAAA;EACA,iBAAA;AADF;;AAIA;EACE;IACE,UAAA;IACA,iBAAA;EADF;AACF;AAIA;EACE;IACE,UAAA;IACA,iBAAA;EAFF;AACF;AAKA;EACE;IACE,UAAA;IACA,iBAAA;EAHF;AACF;AAMA;EACE,UAAA;EACA,kBAAA;AAJF;;AAOA;EACE,sBAAA;EACA,wBAAA;EACA,kBAAA;AAJF;;AAOA;EACE,yBAAA;AAJF;;AAOA;EACE,mBAAA;AAJF","sourcesContent":[".sales-card-container {\n  background-color: #efefef;\n  border-right: 1px solid transparent;\n  // margin-top: 0;\n  position: fixed;\n  font-size: 20px;\n  font-weight: bold;\n  top: 60px;\n  bottom: 0;\n  left: 0;\n  width: 250px;\n  z-index: 0;\n  transition: all 0.2s ease;\n  padding: 15px;\n  overflow-x: hidden;\n  max-height: calc(100vh - 60px);\n  box-shadow: 0 1px 8px 0 rgba(0, 0, 0, .06);\n  color: #f5f5f5;\n  border: none;\n  //position: relative;\n}\n\n.full-width {\n  width: 100%;\n}\n\n.partial-width {\n  width: 82%;\n  margin-left: auto;\n}\n\n@media screen and (min-width: 1150px) and (max-width: 1500px) {\n  .partial-width {\n    width: 82%;\n    margin-left: auto;\n  }\n}\n\n@media screen and (min-width: 1501px) and (max-width: 1799px) {\n  .partial-width {\n    width: 85%;\n    margin-left: auto;\n  }\n}\n\n@media screen and (min-width: 1800px) {\n  .partial-width {\n    width: 88%;\n    margin-left: auto;\n  }\n}\n\n.custom-scrollbar::-webkit-scrollbar {\n  width: 5px;\n  /* Default width */\n}\n\n.custom-scrollbar::-webkit-scrollbar-thumb {\n  background-color: #888;\n  /* Default thumb color */\n  border-radius: 4px;\n}\n\n.filter-drop {\n  background-color: #d0e8ff;\n}\n\n.item-label {\n  margin-bottom: -5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
