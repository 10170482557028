import React, { useState, useRef } from 'react';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from "file-saver";
import { useSelector } from 'react-redux';
import { blue } from '@ant-design/colors';
const IDCard = ({ img }) => {
  const userData = useSelector((state) => state.employee.userData);
  const [formData, setFormData] = useState({

    firstName: userData?.firstname,
    lastName: userData?.lastName,
    id: '',
    role: '',
    phone: userData.mobile,
    EmergencyContacts: userData?.alternativeNumber,
    bloodGroup: '',
    lastName: userData.lastname,
    profileImage: userData?.image, // Create URL if image exists
    Profile: '',
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const frontRef = useRef(null);
  const backRef = useRef(null);
  const fields = [

    { name: 'file', label: '', type: 'file' },
    { name: 'Profile', label: 'Enter vertical', type: 'text' },
    { name: 'phone', label: 'Enter Phone', type: 'text' },
    { name: 'Profile', label: 'Enter Profile', type: 'text' },
    { name: 'bloodGroup', label: 'Enter Blood Group', type: 'text' },
    { name: 'verticalName', label: 'enter vertical name', type: 'text' }
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prevData) => ({
        ...prevData,
        profileImage: URL.createObjectURL(file),

      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setIsFormSubmitted(true);
  };


  const handleDownload = async () => {
    const backSideElement = backRef.current;
    const frontElement = frontRef.current;
    const options = {
      scale: 5,
      useCORS: true,
    };
    // Save original styles to restore later
    const originalBackTransform = backSideElement.style.transform;

    // Remove transformations for proper capture
    backSideElement.style.transform = "none";

    // Temporarily hide specific elements
    // const mainImg = frontElement.querySelector(".mainImg");
    const horizontalText = frontElement.querySelector(".horizontal-text");
    if (horizontalText) horizontalText.style.display = "none";

    const line = frontElement.querySelector(".line");
    if (line) line.style.display = "none";
    const profile = frontElement.querySelector('.profile')
    if (profile) profile.style.display = "none";
    // Capture the front and back content as canvases (scale 5 for high quality)
    const frontCanvas = await html2canvas(frontElement, { scale: 5 });
    // mainImg.style.display = "none";
    const backCanvas = await html2canvas(backSideElement, { scale: 5 });
    // Restore hidden elements
    if (horizontalText) horizontalText.style.display = "block";
    if (line) line.style.display = "block";
    // Restore original styles
    // mainImg.style.display = "block";
    backSideElement.style.transform = originalBackTransform;
    // Create a PDF
    const pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [600, 400], // Customize for your card dimensions
    });
    // Convert canvases to high-quality image data URLs
    const frontImg = frontCanvas.toDataURL("image/png");
    const backImg = backCanvas.toDataURL("image/png");
    // Add images to the PDF
    pdf.addImage(frontImg, "PNG", 10, 10, 280, 380);
    pdf.addImage(backImg, "PNG", 310, 10, 280, 380);
    // Add vertical text to the PDF
    const nameText = formData?.firstName?.toUpperCase();
    const profileText = formData?.Profile?.toUpperCase();
    if (profileText) {
      pdf.setFontSize(34);
      pdf.setFont("helvetica", "bold");
      pdf.setTextColor("gray");

      // Get the dimensions of the PDF
      const pageHeight = pdf.internal.pageSize.height; // Height of the PDF
      const pageWidth = pdf.internal.pageSize.width;  // Width of the PDF
      const textWidth = pdf.getTextWidth(nameText);
      // Calculate the horizontal center
      const x = 22; // Center horizontally
      const y = pageHeight / 2 + textWidth / 2; // Fixed position vertically (adjust as needed)

      // Draw the text at the center with rotation
      pdf.text(nameText, x, y, {
        angle: 90, // Rotate the text
        align: "left",
        justifyContent: "center" // Center alignment
      });
    }
   
    if (formData?.role) {
      pdf.setFontSize(14);
      pdf.setFont("helvetica", "bold");
      pdf.setTextColor("black");
      const x = 310;
      const y = 180;
      pdf.text(formData?.role, x, y, {
        angle: 90,
        align: "right",
      });
    }
    // Add a line to the PDF
    pdf.setLineWidth(1);
    const lineX = 240;
    const lineY = 200;
    pdf.line(lineX, lineY, lineX, lineY + 100);
    // Save the PDF using FileSaver.js
    const pdfBlob = pdf.output("blob");
    saveAs(pdfBlob, "id_card.pdf");
    // Create a single high-quality image by combining front and back canvases
    const combinedCanvas = document.createElement("canvas");
    combinedCanvas.width = frontCanvas.width + backCanvas.width;
    combinedCanvas.height = Math.max(frontCanvas.height, backCanvas.height);
    const ctx = combinedCanvas.getContext("2d");

    // Draw front and back canvases onto the combined canvas
    ctx.drawImage(frontCanvas, 0, 0);
    ctx.drawImage(backCanvas, frontCanvas.width, 0);

    if (profileText) {
      ctx.font = "bold 120px Helvetica";
      ctx.fillStyle = "gray";
      ctx.save();

      // Calculate text width
      const textWidth = ctx.measureText(profileText).width;

      // Calculate position for centering
      const leftSpace = 90 // Center horizontally
      const topSpace = combinedCanvas.height / 2 + textWidth / 2; // Center vertically
      // Adjust the canvas for rotated text
      ctx.translate(leftSpace, topSpace);
      ctx.rotate(-Math.PI / 2); // Rotate text 90 degrees counterclockwise

      // Draw the text
      ctx.fillText(profileText, 0, 0);

      ctx.restore();
    }

    if (formData?.role) {
      ctx.font = "bold 65px Helvetica";
      ctx.fillStyle = "rgba(4, 16, 58, 0.77)";
      ctx.save();
      const textWidth = ctx.measureText(formData?.role?.toLocaleUpperCase()).width; // Measure the width of the text
      const textHeight = 60; // Set height of the text (approximate based on font size)

      const centerX = (combinedCanvas.width - textWidth) / 2; // Center horizontally
      const centerY = (combinedCanvas.height + textHeight) / 2; // Center vertically (adjusting for text height)

      ctx.translate(1250, 880); // Translate to the center of the canvas
      ctx.rotate(-Math.PI / 2); // Rotate text 90 degrees
      ctx.fillText(formData?.role?.toLocaleUpperCase(), 0, 0); // Draw text at the rotated position
      ctx.restore();
    }

    // Add a line to the image (same as PDF)
    const marginRight = 1300;
    const marginBottom = 50;

    // Set line to the right and towards the bottom side with margins
    // Assuming combinedCanvas is the canvas you are working with
    const canvasWidth = combinedCanvas.width;
    const canvasHeight = combinedCanvas.height;

    ctx.beginPath();

    // Start point: 10px from the right edge, 200px from the top
    ctx.moveTo(canvasWidth - 1780, 1000);
    // End point: 40px from the bottom edge, same x position
    ctx.lineTo(canvasWidth - 1780, canvasHeight - 400);

    // Set line width and color
    ctx.lineWidth = 4;
    ctx.strokeStyle = "black";
    ctx.stroke();

    // Convert the combined canvas to a Blob for download
    combinedCanvas.toBlob(
      (blob) => {
        saveAs(blob, "id_card.jpg");
      },
      "image/jpeg",
      1.0 // High-quality JPEG
    );
  };

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div
          style={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              justifyContent: 'space-evenly',
              gap: '20px',
              fontFamily: 'Arial, sans-serif',
            }}
          >
            {/* Form Section */}
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                width: '300px',
                height: '500px',
                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', // Added box shadow
                padding: '20px',
                backgroundColor: '#fff',
                borderRadius: '10px',
              }}
            >
              <form onSubmit={handleFormSubmit}>
                {fields.map((field) => (
                  <input
                    key={field.name}
                    type={field.type}
                    name={field.name}
                    placeholder={field.label}
                    value={formData[field.name]}
                    onChange={field.type == 'file' ? handleImageChange : handleInputChange}
                    required
                    style={{
                      marginBottom: '10px',
                      width: '100%',
                      padding: '8px',
                      border: '1px solid #ccc',
                      borderRadius: '4px',
                    }}
                  />
                ))}
                <select name="role" id="team-select" class="form-control" onChange={handleInputChange}>
                  <option value="">SELECT TEAM</option>
                  <option value="OPERATIONS">OPERATIONS</option>
                  <option value="SALES">SALES</option>
                  <option value="TECH_TEAM">TECH TEAM</option>
                </select>
                <button
                  type="submit"
                  style={{
                    marginTop: '10px',
                    padding: '10px 20px',
                    backgroundColor: '#007bff',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                  }}
                >
                  Submit
                </button>
              </form>
            </div>

            {/* ID Card Section */}
            <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
              <div
                className="id-card"
                style={{
                  perspective: '1000px',
                  width: '300px',
                  height: '400px',
                  position: 'relative',
                }}
              >
                <div
                  className="card-inner"
                  style={{
                    width: '100%',
                    height: '100%',
                    position: 'absolute',
                    transformStyle: 'preserve-3d',
                    transition: 'transform 0.8s',
                  }}
                >
                  {/* Front Side */}
                  <div
                    ref={frontRef}
                    className="front-side"
                    style={{
                      width: '100%',
                      height: '100%',
                       position: 'absolute',
                      backfaceVisibility: 'hidden',
                      backgroundColor: '#fff',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '10px',
                    }}
                  >
                    <div>
                      <h6
                        className="horizontal-text"
                        style={{
                          fontSize: '26px',
                          fontWeight: 'bold',
                          color: "gray",
                          display: "flex",
                        
                          textAlign: 'center', // Centers vertically
                          writingMode: 'vertical-rl', // Makes text vertical, flowing top to bottom, right to left
                          transform: 'rotate(180deg)', // Optional: Adjusts orientation if needed
                          // Ensures the parent container allows horizontal centering
                        }}
                       >
                        {formData?.Profile?.toUpperCase() || formData.firstName?.toUpperCase()}
                      </h6>
                    </div>
                    <div style={{
                      alignItems: "center",
                      justifyContent: 'center',
                    }}
                    >
                      <div style={{ width: "120px", height: "200px", overflow: "hidden", marginLeft: "130px", marginBottom: "100px", backgroundColor: "rgba(108, 206, 255, 0.77)", }}>
                        <img
                          src="/blueplanit.png"
                          alt="Profile"
                          style={{
                          // Makes the image circular  
                          // Make sure it stays proportional within the circle
                            marginLeft:'38px',
                            marginTop: "20px",
                            objectFit: "cover",
                            display: "flex",
                            width: '55px',
                            height: '55px',
                            margin: "auto",
                            // Ensures the image doesn't distort
                          }}
                        />
                        
                          <p className='profile' style={{
                            marginLeft: "96px", // Adjust left margin
                            writingMode: 'vertical-rl',
                            fontWeight:550,
                            transform: 'rotate(180deg)',
                            padding: "auto",
                            color:'rgba(4, 16, 58, 0.77)'
                          
                          }}
                          >{formData?.role?.toUpperCase()}</p>
                       
                      </div>

                      <img
                        src={formData?.profileImage || 'https://cdn-icons-png.flaticon.com/512/6069/6069202.png'}
                        alt="Profile"
                        style={{
                          width: '200px',
                          height: '200px',
                          borderRadius: '50%',
                          marginTop: '-200px',
                         
                          // position:'absolute',
                          marginLeft:'10px',
                          marginBottom: '10px',
                          zIndex: 1,
                        }}
                      // crossOrigin="anonymous"
                      // onError={(e) => {
                      //   e.target.src = 'https://cdn-icons-png.flaticon.com/512/6069/6069202.png';
                      //   console.error('Image failed to load, fallback used');
                      // }}
                      // onLoad={() => console.log('Image loaded successfully')}
                      />

                      <div style={{ padding: "0 60px 2px 30px" }}>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          ID: <span style={{ fontWeight: "300" }} >{formData.id || 'ID'}</span>
                        </p>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          FULL NAME: <span style={{ fontWeight: "300", width: "100px", overflow: "hidden" }}>{`${formData.firstName?.toUpperCase()} ${formData.lastName?.toUpperCase()}`}</span>
                        </p>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          ROLE: <span style={{ fontWeight: "300" }}>{formData.role || 'Role'}</span>
                        </p>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          PHONE: <span style={{ fontWeight: "300" }}>{formData.phone || 'Phone'}</span>
                        </p>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          EMERGENCY CONTACT:{' '}
                          <span style={{ fontWeight: "300" }}>{formData.EmergencyContacts || 'N/A'}</span>
                        </p>
                        <p style={{ fontSize: '8px', margin: 0, fontWeight: "550" }}>
                          BLOOD:{' '}
                          <span style={{ fontWeight: "300" }}>{formData.bloodGroup || 'N/A'}</span>
                        </p>
                      </div>
                    </div>
                    <div>
                      <h6
                        className="line"
                        style={{
                          fontSize: '10px',
                          textAlign: "end",
                          marginBottom: "80px",
                          marginTop: "55px",
                          writingMode: 'vertical-rl', // Makes text vertical, flowing top to bottom, right to left
                          transform: 'rotate(180deg)', // Optional: Adjusts orientation if needed
                          position: 'absolute', // Allow positioning within a container
                          right: '10px', // Aligns the element to the right side
                          top: '50%', // Vertically centers the text
                          transform: 'translateY(-50%) rotate(180deg)', // Keeps it vertically centered and rotated
                        }}
                      >
                        ━━━━━━━━━━━
                      </h6>
                    </div>
                  </div>
                  {/* Back Side */}
                  <div
                    ref={backRef}
                    className="back-side"
                    style={{
                      width: '100%',
                      height: '100%',
                      position: 'absolute',
                      backgroundColor: "rgba(108, 206, 255, 0.77)",
                      backfaceVisibility: 'hidden',
                      borderRadius: '10px',
                      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                      display: 'flex',
                      flexDirection: 'column',
                      transform: 'rotateY(180deg)',
                    }}
                  >

                    <h5 style={{ textAlign: "center", marginTop: "35px" }}>━━━   BLUEPLANIT   ━━━</h5>

                    <h6 style={{ fontSize: '10px', textAlign: 'center', marginTop: "10px" }}>TERMS & CONDITIONS</h6>
                    <p >
                      The card is the property of the company and must be returned upon request or termination of employment.
                    </p>
                    <p >
                      The card is for the use of the individual named on the front only and is not transferable.
                    </p>
                    <p >
                      The card must be used in accordance with the company's policies and procedures.
                    </p>
                    <p>
                      The card must be carried at all times while on company premises.
                    </p>
                    <p >
                      The card must be surrendered immediately upon request by any authorized representative of the company or any law enforcement officer.
                    </p>

                    <div style={{
                      backgroundColor: "rgb(255, 255, 255)",
                      borderBottomLeftRadius: '10px',
                      borderBottomRightRadius: '10px', width: '100%',
                      height: '100%', border: "black sloid 2px"
                    }}>
                      <p >
                        The card must not be altered or defaced in any way.
                      </p>
                      <p>
                        The card may be deactivated or reissued at any time by the company.
                      </p>
                      <p >
                        The cardholder is responsible for reporting any loss or theft of the card to the company immediately.
                      </p>
                      <p >
                        The cardholder is responsible for any misuse of the card.
                      </p>
                      <p>
                        The company may monitor and record the use of the card for security and other purposes.
                      </p>

                      <h6 style={{ fontSize: '12px', textAlign: 'center', fontWeight: 'bold' }}>CONTACT ADDRESS</h6>
                      <p style={{ fontSize: '8px', textAlign: 'center' }}>
                        Blueplanit Services Private Limited
                      </p>
                      <p style={{ fontSize: '8px', textAlign: 'center', marginTop: "-8px", padding: "0 0 -1px 0 " }}>
                        D.no: 50-49-32, TPT colony
                      </p>
                      <p style={{ fontSize: '8px', textAlign: 'center', marginTop: "-8px", padding: "0 0 -2px 0 " }}>
                        Seethamadhara, Visakhapatnam-13
                      </p>
                    </div>
                  </div>

                </div>
              </div>

              <button
                onClick={handleDownload}
                disabled={!isFormSubmitted}
                style={{
                  padding: '10px 20px',
                  backgroundColor: isFormSubmitted ? '#007bff' : '#ccc',
                  color: '#fff',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: isFormSubmitted ? 'pointer' : 'not-allowed',
                }}
              >
                Download PDF/Img
              </button>
            </div>

            {/* CSS for rotation */}
            <style jsx>
              {`
    .id-card:hover .card-inner {
      transform: rotateY(180deg);
    }
   
      .back-side p {
        margin: 4px;
        font-size: 7px;
        padding:0 8px 2px 8px;
      }
 
  `}
            </style>

          </div>
        </div>
      </div>
    </div>
  );
};

export default IDCard;
