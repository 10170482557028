import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALLWORKHISTORY_BY_ID, GET_ALLSTATUSWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, UPLOAD_FILE, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS } from "../../../Queries/Orders.js";

import { GET_USER_DETAILS, GET_ALL_USERS } from '../../../Queries/Users.js';
import { useSelector } from 'react-redux';


function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
}
const requestWorkHistory1 = [
    createData('01/01/2023 10:35:00 A.M.', 'Open Request', 'User Name'),
    createData('01/01/2023 10:25:00 A.M.', 'Email', 'User Name'),

];


export default function RequestWorkHistory(props) {

    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    const { RequestInfo, refetchVal } = props;
    console.log("refetchval", refetchVal,RequestInfo);

    const [workHistoryDetails, setWorkHistoryDetails] = useState([])
    console.log("workHistoryDetails", workHistoryDetails)
    const [statusWorkHistoryDetails, setStatusWorkHistoryDetails] = useState([])
    console.log("statusworkhisr", statusWorkHistoryDetails)



    const workHistoryObj = {
        emailId: RequestInfo?.id,
    }


    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token).id;

    // const { data, } = useQuery(GET_ALL_USERS, {
    //     variables: { loginUserId: userid },
    // });

    // let userscontent = data ? data.getAllUsers : [];

    // console.log('userscontent', userscontent)

    const [getAllWorkHistoryByID, { refetch }] = useMutation(GET_ALLWORKHISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllWorkHistoryByID-->', data)
            setWorkHistoryDetails(data.getAllWorkHistoryByID)
        },

    });

    // useEffect(() => {
    //     if (refetchVal == true) {
    //         refetch();
    //     }
    // }, [])


    const [getAllStatusWorkHistoryByID, { }] = useMutation(GET_ALLSTATUSWORKHISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllStatusWorkHistoryByID-->', data)
            setStatusWorkHistoryDetails(data?.getAllStatusWorkHistoryByID)
        },
    });

    // const [getUserDetails, { }] = useMutation(GET_USER_DETAILS, {
    //     onCompleted(data) {
    //         // console.log(data.getUserDetails[0], 'getUserDetails-->')
    //         // setLoginUser(data?.getUserDetails[0])
    //     },
    // });

    const [getUserDetails] = useMutation(GET_USER_DETAILS);



    const fetchUserDetails = async (userID) => {
        try {
            const employeeObj = {
                id: userID,
            }
            // console.log('employeeObj', employeeObj)
            getUserDetails({ variables: employeeObj })


            const { data } = await getUserDetails();

            const userDetails = data?.getUserDetails[0];
            console.log('employeeObj-->', userDetails);

            // Handle userDetails as needed
        } catch (error) {
            console.error('Error fetching user details:', error);
        }
        return "BHanu"

    };


    // useEffect(() => {
    //     if (workHistoryObj?.emailId) {
    //       getAllWorkHistoryByID({ variables: workHistoryObj });
    //       getAllStatusWorkHistoryByID({ variables: workHistoryObj });
    //     }
    //   }, [workHistoryObj.emailId, getAllWorkHistoryByID, getAllStatusWorkHistoryByID]);
    
      // Refetch data every time refetchVal changes
      useEffect(() => {
        if (workHistoryObj?.emailId) {
          getAllWorkHistoryByID({ variables: workHistoryObj });
          getAllStatusWorkHistoryByID({ variables: workHistoryObj });
        }
      }, [refetchVal, getAllWorkHistoryByID, getAllStatusWorkHistoryByID]);

      const getDateTime = (timeValue) => {
        if (!timeValue) {
            return ''; // Return an empty string if no date is provided
        }
    
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });
    
        const dateTimeString = `${startDate} ${startTimeString}`;
    
        return dateTimeString;
    }
    
    const getTotalWorkingHours = (workHistoryDetails) => {
        let diff = 0;

        workHistoryDetails.map((row) => {
            diff = diff + (parseInt(row.endTime) - parseInt(row.startTime))
        })
        let formattedTime = getTimeToString(diff)

        return formattedTime;
    }
    const calculateWorkingHours = (startTime, endTime) => {
        const start = new Date(startTime);
        const end = new Date(endTime);
        const diff = end - start;
        let formattedTime = getTimeToString(diff)

        return formattedTime;
        // return ` ${months}, days, hours, minutes, seconds `;
        // const workingHours = Math.floor(diff / 3600000); // 1 hour = 3600000 milliseconds
        // return workingHours;
    };

    const getTimeToString = (diff) => {
        const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
        const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 30);
        const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((diff / (1000 * 60)) % 60);
        const seconds = Math.floor((diff / 1000) % 60);

        const formattedTime = [

            months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '',
            days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
            hours > 0 ? `${hours % 24} hr${hours > 1 ? 's' : ''}` : '',
            minutes > 0 ? `${minutes % 60} min${minutes > 1 ? 's' : ''}` : '',
            seconds > 0 ? `${seconds % 60} sec${seconds > 1 ? 's' : ''}` : '',

        ].filter(Boolean).join(' ');
        return formattedTime;
    }

    const assignToUsers=useSelector((state)=>state.loginUserInfo.allUsers)
    console.log("assignToUsers",assignToUsers)
    //const loginUser=useSelector((state)=>state?.loginUserInfo?.loginUserDetails)
    // console.log("usercontent",userscontent?.response,assignToUsers);
    const getUserName = (userid) => {
        let userName = 'anonymous'
        assignToUsers?.map((userdetails) => {
            if (userdetails?.id == userid) {
                userName = userdetails?.firstname + ' ' + userdetails?.lastname;
            }
        })
        return userName;
    }

    // const getUserNameFromEmail = (emailid) => {
    //     let userName = ''
    //     userscontent?.response.map((userdetails) => {
    //         if (userdetails?.email == emailid) {
    //             userName = userdetails?.firstname
    //         }
    //     })
    //     return userName;
    // }
    const getUserNameFromEmail = (emailid) => {
        let userName = '';
        if (assignToUsers.length>0) {
            assignToUsers.forEach((userdetails) => {
                if (userdetails?.email === emailid) {
                    userName = userdetails?.firstname + ' ' + userdetails?.lastname;
                    //userName = userdetails?.firstname;
                }
            });
        }

        if (!userName) {
            userName = emailid ? emailid.split('@')[0] : [];
        }

        return userName;
    };
    // const calculateWorkingDuration = (startTime, endTime) => {
    //     const start = new Date(startTime);
    //     const end = new Date(endTime);
    //     const diff = Math.abs(end - start);

    //     const months = Math.floor(diff / (1000 * 60 * 60 * 24 * 30));
    //     const days = Math.floor((diff / (1000 * 60 * 60 * 24)) % 30);
    //     const hours = Math.floor((diff / (1000 * 60 * 60)) % 24);
    //     const minutes = Math.floor((diff / (1000 * 60)) % 60);
    //     const seconds = Math.floor((diff / 1000) % 60);

    //     return { months, days, hours, minutes, seconds };
    // };

    // const workingDuration = calculateWorkingDuration(
    //     parseInt(row.startTime),
    //     parseInt(row.endTime)
    // );
    const sortedData = [
        ...statusWorkHistoryDetails.map((row) => ({
            key: row.changeTime,
            changeTime: row.changeTime,
            oldStatus: row.oldStatus,
            newStatus: row.newStatus,
            userId: row.userId,
        })),
        {
            key: RequestInfo?.date,
            changeTime: RequestInfo?.date,
            oldStatus: 'Start',
            newStatus: 'Start',
            userId: RequestInfo?.createdBy ? RequestInfo?.createdBy : RequestInfo?.from,
        },
    ];
    sortedData.sort((a, b) => b.changeTime - a.changeTime);

    return (
        <>
            <div className="col-md-12 d-flex overflow-hidden" >
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', fontWeight:'bold' }}>
                            Request Work History
                        </h3>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Date</th>
                                    <th style={{ textAlign: 'center' }}>Old Folder</th>
                                    <th style={{ textAlign: 'center' }}>New Folder</th>
                                    <th style={{ textAlign: 'center' }}>UserName</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr key={RequestInfo?.date}>
                                    <td style={{ textAlign: "center" }}>{getDateTime(parseInt(RequestInfo?.date))}</td>
                                    <td style={{ textAlign: "center" }}>Start</td>
                                    <td style={{ textAlign: "center" }}>Start</td>
                                    <td style={{ textAlign: "center" }}>{RequestInfo?.createdBy ? getUserName(RequestInfo?.createdBy) : getUserNameFromEmail(RequestInfo?.from)}</td>
                                </tr>
                                {statusWorkHistoryDetails?.map((row) => (
                                    <tr key={row?.changeTime}>
                                        <td style={{ textAlign: "center" }}>{getDateTime(parseInt(row.changeTime))}</td>
                                        <td style={{ textAlign: "center" }}>{row.oldStatus}</td>
                                        <td style={{ textAlign: "center" }}>{row.newStatus}</td>
                                        <td style={{ textAlign: "center" }}>{getUserName(row.userId)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="col-md-12 d-flex overflow-hidden" >
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', fontWeight:'bold' }}>
                            Request Time Records({getTotalWorkingHours(workHistoryDetails)})
                        </h3>

                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Total Time</th>
                                    <th style={{ textAlign: 'center' }}>Start Time</th>
                                    <th style={{ textAlign: 'center' }}>End Time</th>
                                    <th style={{ textAlign: 'center' }}>Task Description</th>
                                    <th style={{ textAlign: 'center' }}>User Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                {workHistoryDetails?.map((row, key) => (
                                    <tr key={key}>
                                        <td style={{ textAlign: "center" }}>{calculateWorkingHours(parseInt(row.startTime), parseInt(row.endTime))}</td>
                                        <td style={{ textAlign: "center" }}>{getDateTime(parseInt(row.startTime))}</td>
                                        <td style={{ textAlign: "center" }}>{getDateTime(parseInt(row.endTime))}</td>
                                        <td style={{ textAlign: "center" }}>{row?.taskType}</td>
                                        <td style={{ textAlign: "center" }}>{getUserName(row.userId)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}