import { useState, useEffect } from "react";
import * as React from "react";
import { DateTime } from "luxon";
import dayjs from "dayjs";
import { Starttask } from "../../../Slices/timerslice";
import {
  Form,
  Input,
  Button,
  Tabs,
  Tab,
  Row,
  Col,
  Space,
  Typography,
  Stack,
  Modal,
  message,
  Spin,
} from "antd";
import { Formik } from "formik";
// import { DatePicker, LocalizationProvider, AdapterDayjs } from '@mui/x-date-pickers';
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import { editOrder, submitRefreshPage } from "../../../Slices/orderSlice";
import {
  UPDATE_REQUEST,
  GET_ALL_ORDERS,
  GET_REQUESTS_BY_ID,
  INSERT_ORDERS,
  UPDATE_WORKHISTORY,
  GET_WORKHISTORY,
  GET_WORKHISTORY_BY_ID,
  GET_ALLWORKHISTORY_BY_ID,
  CREATE_STAUS_WORKHISTORY,
} from "../../../Queries/Orders";
// import RequestFormFinal from './RequestFormFinal';
import * as DataBucket from "../../../databucket";
import { useErrorLogger } from "../../../utils";

import RequestForm from "./RequestForm";
import RequestInformation from "./RequestInformation";
import AttachemntsHistory from "./AttachmentsHistory";
import RequestWorkHistory from "./RequestWorkHistory";
import CreateOrder from "./CreateOrder";

import { useNavigate, useParams } from "react-router-dom";
//tab importss
import {
  timerHistoryPayloadChange,
  settaskStartTime,
} from "../../../Slices/user";
import { getSingleOrder, updateRequestInfo } from "../../../Slices/orderSlice";
import * as Yup from "yup";
//import { setLoader } from "../../../Slices/loaderSlice";
import Swal from "sweetalert2";
import { setRefreshStatus } from "../../../Slices/salesSlice";

const { TabPane } = Tabs;

// const validationSchema = Yup.object({
//     // priority: Yup.string().required('Priority is required'),
//     // requestStatus: Yup.string().required('Request Status is required'),
//     from: Yup.string()
//         .email('Invalid email format')
//         .required('From Email is required')
//         .matches(
//             /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/,
//             'Please enter a valid email address with .com domain'
//         ),
//     to: Yup.string()
//         .email('Invalid email format')
//         .required('To Email is required')
//         .matches(
//             /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/,
//             'Please enter a valid email address with .com domain'
//         )
//         .notOneOf([Yup.ref('from'), null], 'From and To emails cannot be the same'),
//     subject: Yup.string().trim().required('Subject is required'),
//     // clientName: Yup.string().required('Client Name is required'),
//     body: Yup.string().trim().required('Body is required'),
//     // existingPolicyInformation: Yup.array().of(
//     //     Yup.object().shape({
//     //         currentCarrier: Yup.string().required('Current Carrier is required'),
//     //         propertyType: Yup.string().required('Policy Type is required'),
//     //         policyNumber: Yup.string().required('Policy Number is required'),
//     //         effectiveDate: Yup.date().required('Effective Date is required'),
//     //         currentPremium: Yup.string().required('Current Premium is required'),
//     //     })
//     // ),
// });
// const validationSchema = (department) => {
//   if (department === "Personal Line") {
//     return Yup.object().shape({
//       quoteType: Yup.string().required("Request Type is required"),
//       department: Yup.string().required("Department is required"),
//       insuredName: Yup.string().required("Insured Name is required"),
//       noofQuotes: Yup.number()
//         .typeError("Number of Quotes must be a number")
//         .min(1, "Number of Quotes must be at least 1")
//         .required("Number of Quotes is required"),
//       existingPolicyInformation: Yup.array().of(
//         Yup.object().shape({
//           currentCarrier: Yup.string().required("Current Carrier is required"),
//           propertyType: Yup.string().required("Property Type is required"),
//           policyNumber: Yup.string().required("Policy Number is required"),
//           effectiveDate: Yup.date()
//             .required("Effective Date is required")
//             .nullable(),
//           currentPremium: Yup.number()
//             .required("Current Premium is required")
//             .positive("Premium must be a positive number"),
//         })
//       ),
//       newQuoteInformation: Yup.array().of(
//         Yup.object().shape({
//           quoteAmount: Yup.number()
//             .required("Quote Amount is required")
//             .positive("Quote Amount must be a positive number"),
//           expirationDate: Yup.date()
//             .required("Expiration Date is required")
//             .nullable(),
//         })
//       ),
//     });
//   } else {
//     return Yup.object().shape({
//       quoteType: Yup.string().required("Request Type is required"),
//       department: Yup.string().required("Department is required"),
//       insuredName: Yup.string().required("Insured Name is required"),
//       noofQuotes: Yup.number()
//         .typeError("Number of Quotes must be a number")
//         .min(1, "Number of Quotes must be at least 1")
//         .required("Number of Quotes is required"),
//     });
//   }
// };

const RequestDetails = () => {
  const { id } = useParams();
  const [refetchVal, setRefetchVal] = useState(false);
  const requestId = useSelector((state) => state.timer.requestId);
  const { logError } = useErrorLogger();
  const dispatch = useDispatch();

  const RequestInfo = useSelector((state) => state?.orders?.singleOrder);
  console.log("RequestInfo",RequestInfo)
  // const [instructionVal, setInstructionVal] = useState(
  //   RequestInfo?.instructions ? RequestInfo?.instructions : false
  // );
  // const [fromPendingVal, setfromPendingVal] = useState(
  //   RequestInfo?.fromPending ? RequestInfo?.fromPending : false
  // );

  const [age, setAge] = useState("");
  const [value, setValue] = useState("");
  const [employeeDetails, setEmployeeDetails] = React.useState({});

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const initialTime = 0;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [workHistoryId, setWorkHistoryId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [open, setOpen] = useState(false);
  let intervalId = null;

  const [tabValue, setTabValue] = useState(0);
  const [activeTab, setActiveTab] = useState("request_details");

  // Handle tab change
  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const [getRequestById] = useMutation(GET_REQUESTS_BY_ID, {
    onCompleted(data) {
      //setRequestInfo(data?.getRequestById?.[0])
      dispatch(getSingleOrder(data?.getRequestById?.[0]));
    },
  });

  useEffect(() => {
    // getWorkHistoryID({ variables: workHistoryObj });
    const valuesObject = {
      id: id,
    };
    getRequestById({ variables: valuesObject });
    //dispatch(setRefreshStatus())
  }, []);

  // const { handleStop: mainTimerStop } = HeaderContent();

  // const headerContent = HeaderContent();

  // const { handleStop: mainTimerStop } = headerContent;
  const tabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const navigate = useNavigate();
  const workHistoryObj = {
    userId: RequestInfo?.id,
  };

  const [updateWorkHistory, { }] = useMutation(UPDATE_WORKHISTORY, {
    onCompleted(data) {
      //setRefetchVal(prev => !prev);  
      //refetch()
      setIsRunning(false);
    },
  });

  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const [getWorkHistoryID, { }] = useMutation(GET_WORKHISTORY_BY_ID, {
    onCompleted(data) {
      let datacontent = data.getWorkHistoryID;
      if (
        datacontent.length > 0 &&
        datacontent[0]?.userId == parseJwt(token)?.id
      ) {
        setWorkHistoryId(datacontent[0].id);
        setStartTime(datacontent[0].startTime);
        setIsRunning(true);
      }
    },
    onError() {
      logError(
        "RequetDetails",
        "get workhistory based on id",
        "error while getWorkhistory based on id"
      );
    },
  });

  const [createStatusWorkHistory, { }] = useMutation(CREATE_STAUS_WORKHISTORY, {
    onCompleted(data) {
      console.log(data?.createStatusWorkHistory?.id, "data");
    },
  });

//   useEffect(() => {
//     getWorkHistoryID({ variables: workHistoryObj });

// }, []);

  useEffect(() => {
    try {
      if (RequestInfo?.id) {
        const workHistoryObj = {
          userId: RequestInfo?.id, 
        };
        getWorkHistoryID({ variables: workHistoryObj });
      } else {
        console.error("userId is missing or undefined");
      }
    } catch (error) {
      logError("RequestDtails", "useEffect-getWorkhistoryId", error.message);
    }
  }, [RequestInfo?.id]);

  useEffect(() => {
    try {
      let intervalId;
      if (isRunning) {
        intervalId = setInterval(() => {
          const currentTime = new Date().getTime();
          const timeDifference = currentTime - parseInt(startTime);
          setElapsedTime(timeDifference);
        }, 1000);
      }

      return () => {
        clearInterval(intervalId);
      };
    } catch (error) {
      logError("RequestDetails", "useEffect-get timer function", error.message);
    }
  }, [isRunning, startTime]);
  // const { data, } = useQuery(GET_WORKHISTORY);

  // const datacontent = data ? data.getAllWorkHistory : [];

  const formatTime = (time) => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const formattedTime = [
      years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "",
      months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "",
      days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "",
      `${hours % 24}:${minutes % 60}:${seconds % 60}`,
    ]
      .filter(Boolean)
      .join(" ");

    return formattedTime;
  };

  const handleStart = () => {
    setIsRunning(true);
    setStartTime(Date.now().toString());
    // mainTimerStop();
  };

  const handleStop = async () => {
    setIsRunning(false);
    setElapsedTime(initialTime);
    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),
      endTime: Date.now().toString(),
      requestId: "",
    };
    //setRefetch(true);
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
    await updateWorkHistory({
      variables: {
        userId: parseJwt(token).id,
        emailId: "",
        requestId: "",
        clientName: "Bluepalnit",
        processType: "",
        taskType: "Available",
        email: "",
        subject: "",
        body: "",
        startTime: Date.now().toString(),
        endTime: "",
      },
    });
    const payload = {
      department:'',
      requestId:'',
      task: "Available",
      time: Date.now(),
    };
    localStorage.setItem("StartTime", new Date().getTime());
    localStorage.removeItem("department");
    localStorage.removeItem("requestId");
    localStorage.removeItem("task");
    dispatch(settaskStartTime(new Date().getTime()));
    dispatch(Starttask(payload));
    setRefetchVal((prev) => !prev);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const steps = ["Request Details", "Attachment History", "Work History"];

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };
  const refreshStatus = useSelector((state) => state.orders.refreshPage)
  console.log("refreshStatus", refreshStatus)

  const cancelHandelar = () => {
    dispatch(submitRefreshPage(false));
    //dispatch(getSingleOrder({}));
    navigate("/request");
  };



  // React.useEffect(() => {
  //     employeeDetails.QuoteType = RequestInfo.quoteType;
  //     setEmployeeDetails({ ...employeeDetails, 'QuoteType': RequestInfo.quoteType })

  // }, []);
  const getLocalTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };
  //     const utcToLocal = (utcDateTimeStr) => {
  // //         const dateString=utcDateTimeStr
  // //         const dateParts = dateString.split(/[\s/:]+/);
  // //         const date = new Date(
  // //         dateParts[2], // Year
  // //         dateParts[1] - 1, // Month (0-based index)
  // //         dateParts[0], // Day
  // //         dateParts[3] % 12 + (dateParts[4] === "PM" ? 12 : 0), // Hours
  // //         dateParts[4], // Minutes
  // //         dateParts[5] // Seconds
  // //         )
  //     const timeZone = getLocalTimeZone();
  //     try {
  //         // Parse the UTC datetime string and set the zone to UTC
  //         const utcDateTime = DateTime.fromISO(date, { zone: 'utc' });
  //         // Convert to local time
  //         const localDateTime = utcDateTime.setZone(timeZone);
  //         return localDateTime.toISO(); // Return as ISO string
  //     } catch (error) {
  //         console.error(`Error converting UTC to local time: ${error.message}`);
  //         return null;
  //     }
  // };
  // const TimestampFormatter = ( unixTimestamp ) => {
  //     // Convert the Unix timestamp to a Date object
  //     const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert seconds to milliseconds

  //     // Format the date to ISO 8601 string
  //     const isoString = date.toISOString(); // Outputs in UTC

  //     // Adjust to include the timezone offset
  //     const formattedDate = isoString.replace('Z', '+00:00');
  //     const timeZone = getLocalTimeZone();
  //     try {
  //         // Parse the UTC date-time string
  //         const utcDateTime = DateTime.fromISO(formattedDate, { zone: 'utc' });
  //         // Convert to local time
  //         const localDateTime = utcDateTime.setZone(timeZone);
  //        // return localDateTime.toISO(); // Return as ISO string

  //     const dates = new Date(localDateTime.toISO());

  //     // Get the Unix timestamp in seconds
  //     const unixTimestamps = Math.floor(dates.getTime() / 1000);

  //      console.log('unixTimestamps',unixTimestamps);
  //     } catch (error) {
  //         console.error(`Error converting UTC time to local: ${error.message}`);
  //         return null;
  //     }// Replace 'Z' with '+00:00'

  //     }

  const TimestampFormatter = (unixTimestamp) => {
    const date = DateTime.fromMillis(unixTimestamp);
    const isoString = date.toISO(); 
    const localDateTime = date.setZone(DateTime.local().zoneName);
    const localUnixTimestamp = localDateTime.toMillis();
    return localUnixTimestamp.toString();
  }

  const initialValues = {
    id: RequestInfo?.id,
    from: RequestInfo?.from,
    to: RequestInfo?.to,
    requestId: RequestInfo?.requestId,
    subject: RequestInfo?.subject,
    emailNeeded: RequestInfo?.emailNeeded,
    emailCompleted: RequestInfo?.emailCompleted,
    hold: RequestInfo?.hold,
    date: parseInt(RequestInfo?.date),
    taskComplete: RequestInfo?.taskComplete,
    priority: RequestInfo?.priority,
    Assign: RequestInfo?.Assign,
    request_type: RequestInfo?.request_type,
    instructions: RequestInfo?.instructions,
    fromPending: RequestInfo?.fromPending,
    quoteType: RequestInfo?.quoteType,
    department: RequestInfo?.department,
    insuredName: RequestInfo?.insuredName,
    noofQuotes: RequestInfo?.noofQuotes,
    holderName: RequestInfo?.holderName,
    insuredDelivery: RequestInfo?.insuredDelivery,
    holderDelivery: RequestInfo?.holderDelivery,
    accountManagerDelivery: RequestInfo?.accountManagerDelivery,
    otherDelivery: RequestInfo?.otherDelivery,
    radioValue: RequestInfo?.radioValue,
    Auto: RequestInfo?.Auto || false,
    MotorCycle: RequestInfo?.MotorCycle || false,
    Boat: RequestInfo?.Boat || false,
    DP3: RequestInfo?.DP3 || false,
    HO3: RequestInfo?.HO3 || false,
    HO4: RequestInfo?.HO4 || false,
    HO5: RequestInfo?.HO5 || false,
    HO6: RequestInfo?.HO6 || false,
    comments: RequestInfo?.comments,
    // priority: RequestInfo.priority,
    processType: RequestInfo?.processType,
    assignedTo: RequestInfo?.assignedTo,
    assignTo: RequestInfo?.assignTo,
    startDate: parseInt(RequestInfo?.startDate),
    dueDate: parseInt(RequestInfo?.dueDate),
    completedDate: parseInt(RequestInfo?.completedDate),
    //startDate: RequestInfo?.startDate ? TimestampFormatter(Number(RequestInfo?.startDate)) : TimestampFormatter(Number(RequestInfo?.date)),
    //dueDate: RequestInfo?.dueDate ? TimestampFormatter(Number(RequestInfo?.dueDate)) : null,
    // //dueDate: RequestInfo?.dueDate,
    // completedDate: RequestInfo?.completedDate ? TimestampFormatter(Number(RequestInfo?.completedDate)): null,
    requestStatus: RequestInfo?.requestStatus,
    radioInfo: RequestInfo?.radioInfo,
    emailSummary: RequestInfo?.emailSummary,
    requestor: RequestInfo?.requestor,
    sendTo: RequestInfo?.sendTo,
    clientName: RequestInfo?.clientName,
    newQuoteInformation: (RequestInfo?.newQuoteInformation?.length > 0) ? RequestInfo?.newQuoteInformation : [{
      policy: '',
      insuranceCarrier: '',
      eligibility: '',
      quotedPremium: '',
      remarks: '',

    }],
    existingPolicyInformation: (RequestInfo?.existingPolicyInformation?.length > 0) ? RequestInfo?.existingPolicyInformation : [{
      currentCarrier: '',
      propertyType: '',
      propertyNew: '',
      policyNumber: '',
      effectiveDate: null,
      currentPremium: ''
    }]
    // newQuoteInformation: [{ policy: '', insuranceCarrier: '', quotedPremium: '' }]
    // attachmentsURLs: RequestInfo.attachmentsURLs,
  };

  // Function to convert local time to UTC
  const localToUtc = (localDateTimeStr) => {
    const timeZone = getLocalTimeZone(); // Get the local timezone (assumed function)
    try {
        // Convert dayjs object to native JavaScript Date
        const localDateTime = DateTime.fromJSDate(localDateTimeStr.toDate(), { zone: timeZone });

        // Convert local time to UTC
        const utcDateTime = localDateTime.toUTC();
        return utcDateTime.toISO();  // Return ISO string in UTC
    } catch (error) {
        console.error(`Error converting local time to UTC: ${error.message}`);
        return null;
    }
};

  const [newRequest, setNewRequest] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  console.log("isRunning || requestId == RequestInfo?.requestId",isRunning || requestId == RequestInfo?.requestId)

  const startTimer = () => {
    setIsDisabled(true); 
    setTimeout(() => {
      setIsDisabled(false); 
    }, 3000);
  };

  const submit = async (values) => {
    try {
      if (JSON.stringify(newRequest) === JSON.stringify(values)) {
        startTimer();
        message.error({
          content: "No changes detected. Please update the fields before submitting.",
          duration: 3,
          className: 'custom-toast-center',
        });
        return;
      }


      startTimer();
      setNewRequest(values)
      const valuesObject = {
        id: RequestInfo?.id,
        emailNeeded: values?.emailNeeded,
        emailCompleted: values?.emailCompleted,
        hold: values?.hold,
        subject: values?.subject,
        taskComplete: values?.taskComplete,
        priority: values.priority,
        request_type: values.request_type,
        instructions: values.instructions,
        fromPending: values.fromPending,
        quoteType: values.quoteType,
        department: values.department,
        insuredName: values.insuredName,
        noofQuotes: parseInt(values.noofQuotes),
        holderName: values.holderName,
        insuredDelivery: values.insuredDelivery,
        holderDelivery: values.holderDelivery,
        accountManagerDelivery: values.accountManagerDelivery,
        otherDelivery: values.otherDelivery,
        radioValue: values.radioValue,
        Auto: values.Auto,
        MotorCycle: values.MotorCycle,
        Boat: values.Boat,
        DP3: values.DP3,
        HO3: values.HO3,
        HO4: values.HO4,
        HO5: values.HO5,
        HO6: values.HO6,
        comments: values.comments,
        // priority: values.priority,
        processType: values.processType,
        assignedTo: values.assignedTo,
        assignTo: values.assignTo,
        //startDate: values.startDate,
        startDate: localToUtc(dayjs(values.startDate)) || dayjs(parseInt(RequestInfo.date)).toISOString(),
        dueDate: localToUtc(dayjs(values.dueDate)) || dayjs(parseInt(RequestInfo.dueDate)).toISOString(),
        //dueDate: localToUtc(values.dueDate) || RequestInfo.dueDate,
        completedDate: dayjs(parseInt(values.completedDate)),
        //startDate: values.startDate,
        // dueDate: values.dueDate,
        // completedDate: localToUtc(values.completedDate) || RequestInfo?.completedDate,
        //completedDate: values.completedDate,
        requestStatus: values.requestStatus,
        Assign: values.Assign,
        radioInfo: values.radioInfo,
        emailSummary: values.emailSummary,
        requestor: values.requestor,
        sendTo: values.sendTo,
        clientName: values.clientName,
        newQuoteInformation: values.newQuoteInformation.map(
          ({ __typename, ...rest }) => rest
        ),
        existingPolicyInformation: values.existingPolicyInformation.map(
          ({ __typename, ...rest }) => rest
        ),
        // attachments: values.attachments || [], data.map(({ __typename, ...rest }) => rest)
        // quotes: [{ policy: '', insuranceCarrier: '', quotedPremium: '' }]
        // newQuoteInformation: [values.newQuoteInformation],
        // newQuoteInformation: [{ policy: '', insuranceCarrier: '', quotedPremium: '' }]
      };
      if (JSON.stringify(values) === JSON.stringify(initialValues)) {
        message.error({
          content:
            "No changes detected. Please update the fields before submitting.",
          duration: 3,
          className: "custom-toast-center",
        });
        return;
      }
      // const userConfirmed = await Swal.fire({
      //   title: 'Are you sure?',
      //   text: 'Do you want to update this Request?',
      //   icon: 'question',
      //   showCancelButton: true,
      //   confirmButtonColor: '#3085d6',
      //   cancelButtonColor: '#d33',
      //   confirmButtonText: 'Yes, update!',
      //   cancelButtonText: 'Cancel',
      // });

      // if (!userConfirmed.isConfirmed) {
      //   return;
      // }
      // //setSetPopupStatus(false);
      // dispatch(setLoader(true))
      const historyObject = {
        userId: parseJwt(token)?.id,
        emailId: RequestInfo.id,
        oldStatus: RequestInfo?.radioInfo || "",
        newStatus: values.radioInfo || "",
        changeTime: Date.now().toString(),
      };
      createStatusWorkHistory({ variables: historyObject });
      // }
      console.log(
        valuesObject,
        "valuesObject",
        RequestInfo.radioInfo != values.radioInfo,
        RequestInfo.radioInfo,
        values.radioInfo
      );
      
      updateRequest({ variables: valuesObject });
      //dispatch(editOrder(valuesObject));
    } catch (error) {
      // dispatch(setLoader(false))
      // Swal.close();
      await logError("RequestDetails", "submit", error.message);
      console.error("Error updating request:", error);
    }

    // const updatedObject = DataBucket.REQUEST_SUBMIT_KEYS.reduce((acc, key) => {
    //     let finalKey = key.charAt(0).toLowerCase() + key.slice(1);
    //     // let finalValue = (employeeDetails[key] != undefined) ? employeeDetails[key] : ""
    //     let finalValue;

    //     if (finalKey == 'existingPolicyInformation') {

    //         const policyNumberKeys = Object.keys(employeeDetails).filter((key) => key.startsWith("Policy_Number_"));
    //         let existingPolicyInformationData = []
    //         for (let count = 1; count <= policyNumberKeys.length; count++) {
    //             let existingPolicyInformationObject;

    //             let Current_Carrier = employeeDetails['Current_Carrier_' + count]
    //             let Property_Type = employeeDetails['Property_Type_' + count]
    //             let Property_New = employeeDetails['Property_New_' + count]
    //             let Policy_Number = employeeDetails['Policy_Number_' + count]
    //             let Effective_Date = employeeDetails['Effective_Date_' + count]
    //             let Current_Premium = employeeDetails['Current_Premium_' + count]
    //             existingPolicyInformationObject = {
    //                 currentCarrier: Current_Carrier,
    //                 propertyType: Property_Type,
    //                 propertyNew: Property_New,
    //                 policyNumber: Policy_Number,
    //                 effectiveDate: Effective_Date,
    //                 currentPremium: parseInt(Current_Premium)
    //             }
    //             existingPolicyInformationData.push(existingPolicyInformationObject)

    //         }

    //         finalValue = existingPolicyInformationData;

    //     } else if (finalKey == 'newQuoteInformation') {

    //         const policyNumberKeys = Object.keys(employeeDetails).filter((key) => key.startsWith("Insurance_Carrier_"));
    //         let newQuoteInformationData = []
    //         for (let count = 1; count <= policyNumberKeys.length; count++) {
    //             let newQuoteInformationObject;

    //             let Policy = employeeDetails['Policy_' + count]
    //             let Insurance_Carrier = employeeDetails['Insurance_Carrier_' + count]
    //             let Quoted_Premium = employeeDetails['Quoted_Premium_' + count]

    //             newQuoteInformationObject = {
    //                 policy: Policy,
    //                 insuranceCarrier: Insurance_Carrier,
    //                 quotedPremium: parseInt(Quoted_Premium),
    //             }
    //             newQuoteInformationData.push(newQuoteInformationObject)
    //         }

    //         finalValue = newQuoteInformationData;

    //         // } else {
    //         //     finalValue = (employeeDetails[key] != undefined) ? employeeDetails[key] : ""
    //     }

    //     return { ...acc, [finalKey]: finalValue };
    // }, {});

    // const workHistoryObj = { workHistory: [workHistoryDetails] } //...workHistoryObj

    // const mergedObj = { ...valuesObject, ...updatedObject, };

    // if (RequestInfo.radioInfo != values.radioInfo) {
  };

  const [popupStatus, setSetPopupStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");

  const [updateRequest] = useMutation(UPDATE_REQUEST, {
    onCompleted(data) {
      dispatch(submitRefreshPage(true));  
      dispatch(editOrder(data.updateRequest)); 
      message.success({
        content: "Request updated successfully!",
        duration: 3,
        className: "custom-toast-center",
      });
    },
    onError(error) {
      console.error('Error updating request:', error);
      message.error("An error occurred while updating the request.");
    }
  });


  const handleDialogClose = () => {
    setSetPopupStatus(false);
    //navigate('/request1') 
  };
  const handleDialogCancelClose = () => {
    setSetPopupStatus(false);
    //navigate('/request1') 
  };

  // const renderStepComponent = (handleChange, values) => {
  //   switch (activeStep) {
  //     case 0:
  //       return (
  //         <RequestForm
  //           initialValues={initialValues}
  //           employeeDetails={employeeDetails}
  //           setEmployeeDetails={setEmployeeDetails}
  //           RequestInfo={RequestInfo}
  //           handleChange={handleChange}
  //           values={values}
  //         />
  //       );
  //     case 1:
  //       return (
  //         <AttachemntsHistory
  //           initialValues={initialValues}
  //           employeeDetails={employeeDetails}
  //           setEmployeeDetails={setEmployeeDetails}
  //           RequestInfo={RequestInfo}
  //           handleChange={handleChange}
  //           values={values}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <RequestWorkHistory
  //           initialValues={initialValues}
  //           employeeDetails={employeeDetails}
  //           setEmployeeDetails={setEmployeeDetails}
  //           RequestInfo={RequestInfo}
  //           handleChange={handleChange}
  //           values={values}
  //         />
  //       );
  //     default:
  //       return null;
  //   }
  // };

  return (
    <>
      <Modal
        title="Submit Status"
        visible={popupStatus} 
        onCancel={handleDialogCancelClose} 
        footer={[
          <Button key="ok" type="primary" onClick={handleDialogClose}>
            OK
          </Button>,
        ]}
      >
        <p>{submitStatus}</p>
      </Modal>

      <div style={{ width: "100%", marginBottom: "5px" }}>
        {open && (
          <CreateOrder
            open={open}
            handleClose={handleClose}
            handleStart={handleStart}
            RequestInfo={RequestInfo}
            setWorkHistoryId={setWorkHistoryId}
          />
        )}

        <Formik
          onSubmit={submit}
          initialValues={initialValues}
          enableReinitialize={true}
          //validationSchema={validationSchema}
        >
          {({
            errors,
            handleBlur,
            handleSubmit,
            handleChange,
            isSubmitting,
            touched,
            setFieldValue,
            values,
          }) => (
            <form noValidate onSubmit={handleSubmit}>
              <Row gutter={16} style={{ marginBottom: "7px" }}>
                <Col span={24} style={{ marginTop: 16 }}>
                  <Space>
                    <Button color='primary' variant='solid' htmlType="submit" disabled={isDisabled}>
                      Save
                    </Button>
                    <Button
                      color="danger"
                      variant="solid"
                      onClick={cancelHandelar}
                    >
                      Close
                    </Button>
                  </Space>
                  <Space style={{ float: "right" }}>
                    {requestId == RequestInfo?.requestId ? (
                      <Button
                      color="danger"
                      variant="solid" 
                      // className="btn btn-info" 
                      onClick={handleStop}>
                        Stop
                      </Button>
                    ) : (
                      <Button color='primary' variant='solid'
                        // className="btn btn-info"
                        style={{ float: "right" }}
                        onClick={handleOpen}
                      >
                        Start
                      </Button>
                    )}
                  </Space>
                </Col>
              </Row>

              <RequestInformation
                initialValues={initialValues}
                employeeDetails={employeeDetails}
                setEmployeeDetails={setEmployeeDetails}
                RequestInfo={RequestInfo}
                handleChange={handleChange}
                values={values}
                setFieldValue={setFieldValue}
                errors={errors}
                touched={touched}
              />

              <Tabs
                activeKey={activeTab}
                onChange={onTabChange}
                type="card"
              >
                <Tabs.TabPane tab="Request Details" key="request_details">
                  <RequestForm
                    initialValues={initialValues}
                    employeeDetails={employeeDetails}
                    setEmployeeDetails={setEmployeeDetails}
                    RequestInfo={RequestInfo}
                    handleChange={handleChange}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane
                  tab="Attachments History"
                  key="attachment_history"
                >
                  <AttachemntsHistory
                    initialValues={initialValues}
                    employeeDetails={employeeDetails}
                    setEmployeeDetails={setEmployeeDetails}
                    RequestInfo={RequestInfo}
                    handleChange={handleChange}
                    values={values}
                  />
                </Tabs.TabPane>

                <Tabs.TabPane tab="Work History" key="work_history">
                  <RequestWorkHistory
                    initialValues={initialValues}
                    employeeDetails={employeeDetails}
                    setEmployeeDetails={setEmployeeDetails}
                    RequestInfo={RequestInfo}
                    // setRefetch={setRefetch}
                    refetchVal={refetchVal}
                    handleChange={handleChange}
                    values={values}
                  />
                </Tabs.TabPane>
              </Tabs>
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};

export default RequestDetails;

{
  /* <TabPane tab="Request Details" key="0">
                                    <RequestForm
                                        initialValues={initialValues}
                                        employeeDetails={employeeDetails}
                                        setEmployeeDetails={setEmployeeDetails}
                                        RequestInfo={RequestInfo}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                </TabPane>
                                <TabPane tab="Attachment History" key="1">
                                    <AttachemntsHistory
                                        initialValues={initialValues}
                                        employeeDetails={employeeDetails}
                                        setEmployeeDetails={setEmployeeDetails}
                                        RequestInfo={RequestInfo}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                </TabPane>
                                <TabPane tab="Work History" key="2">
                                    <RequestWorkHistory
                                        initialValues={initialValues}
                                        employeeDetails={employeeDetails}
                                        setEmployeeDetails={setEmployeeDetails}
                                        RequestInfo={RequestInfo}
                                        handleChange={handleChange}
                                        values={values}
                                    />
                                </TabPane>
           

              {/* <RequestFormFinal initialValues={initialValues} employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} RequestInfo={RequestInfo} submit={submit} /> */
}

{
  /* 
            <MainCard title="Request Details">

                <Grid container spacing={2}>

                     <Grid item xs={12} >
                        <MainCard title={RequestInfo.subject}>
                            <Typography variant="body2" dangerouslySetInnerHTML={{ __html: RequestInfo.html }} />
                        </MainCard>
                    </Grid> 

                     <Grid item xs={12} mt={2}>
                        <MainCard>
                            <Formik
                                initialValues={initialValues}
                                onSubmit={submit}

                            // onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
                            //     console.log(values,'values')
                            //     try {
                            //         setStatus({ success: false });
                            //         setSubmitting(false);
                            //     } catch (err) {
                            //         setStatus({ success: false });
                            //         setErrors({ submit: err.message });
                            //         setSubmitting(false);
                            //     }
                            // }}
                            >
                                {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                                    <form noValidate onSubmit={handleSubmit}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth>
                                                    <InputLabel id="demo-simple-select-label">Priority</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.priority}
                                                        label="Priority"
                                                        onChange={handleChange}
                                                        name='priority'
                                                    >
                                                        <MenuItem value={'Rush'}>Rush</MenuItem>
                                                        <MenuItem value={'On Demand'}>On Demand</MenuItem>
                                                        <MenuItem value={'Bulk'}>Bulk</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <FormControl fullWidth >
                                                    <InputLabel id="demo-simple-select-label">Request Type</InputLabel>
                                                    <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        value={values.request_type}
                                                        label="Request Type"
                                                        onChange={handleChange}
                                                        name="request_type"
                                                    >
                                                        <MenuItem value={'Type 1'}>Type 1</MenuItem>
                                                        <MenuItem value={'Type 2'}>Type 2</MenuItem>
                                                        <MenuItem value={'Type 3'}>Type 3</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12} mt={2}>

                                            <FormControlLabel

                                                control={
                                                    <Checkbox
                                                        checked={values.emailNeeded}
                                                        onChange={handleChange}
                                                        name='emailNeeded'
                                                        color="primary"
                                                        size="small"
                                                    // value={'jdj'}
                                                    />
                                                }
                                                label={<Typography variant="h6">Email Needed</Typography>}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.emailCompleted}
                                                        onChange={handleChange}
                                                        name="emailCompleted"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label={<Typography variant="h6">Email Completed</Typography>}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.hold}
                                                        onChange={handleChange}
                                                        name="hold"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label={<Typography variant="h6">Hold</Typography>}
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={values.taskComplete}
                                                        onChange={handleChange}
                                                        name="taskComplete"
                                                        color="primary"
                                                        size="small"
                                                    />
                                                }
                                                label={<Typography variant="h6">Task Completed</Typography>}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Stack spacing={1}>
                                                <FormControlLabel control={<Switch checked={instructionVal} name="instructions" onClick={handleSwitchChange} />} label="Instructions" />
                                            </Stack>
                                        </Grid>
                                        <Grid item xs={12} mt={2}>
                                            <Stack direction="row" spacing={2}>
                                                <Button variant="outlined" >
                                                    Cancel
                                                </Button>
                                                <Button variant="contained" type="submit">
                                                    Save
                                                </Button>
                                            </Stack>
                                        </Grid>
                                    </form>
                                )}
                            </Formik>
                        </MainCard>
                    </Grid> 

                </Grid>
            </MainCard> */
}
