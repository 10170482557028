import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import CurrentCarrier from './CurrentCarrier';
import InsuranceCarrier from './InsuranceCarrier';

const CustomiseRequestOptions = ({ sidebarOpen }) => {
    return (
        <div className={`page-wrapper ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
            <div className="content container-fluid"> {/* Bootstrap container-fluid for full-width layout */}
                <div className="py-4"> {/* Adds vertical padding */}
                    <div className="row"> {/* Bootstrap row to hold columns */}
                        <div className="col-md-12 mb-4"> 
                            <CurrentCarrier />
                        </div>
                        {/* <div className="col-md-6 mb-4"> 
                            <InsuranceCarrier />
                        </div> */}
                    </div>
                </div>
            </div>

            <style jsx>{`
                /* Ensures equal padding on all sides of the container */
                .content {
                    padding-left: 20px;
                    padding-right: 20px;
                    transition: all 0.3s ease-in-out;
                }

                .sidebar-open .content {
                    padding-left: 40px;
                    padding-right: 40px;
                }

                .sidebar-closed .content {
                    padding-left: 20px;
                    padding-right: 20px;
                }
            `}</style>
        </div>
    );
};

export default CustomiseRequestOptions;
