import { useState, useEffect, memo } from 'react';
// import MainCard from 'components/MainCard';
import * as React from 'react';
import dayjs from 'dayjs';
import { settaskStartTime } from '../../../Slices/user';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import AgencyInformationForm from './AgencyInformationForm';
import ContactInformationForm from './ContactInformationForm';
import CommentField from './CommentField';
import { useMutation, useQuery } from '@apollo/client';
import { FieldArray, Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import * as DataBucket from '../../../databucket';
import Swal from 'sweetalert2';
import { UPDATE_WORKHISTORY } from '../../../Queries/Orders';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Starttask } from '../../../Slices/timerslice';
import { renderOptions } from '../../../utilsdata';

import { GET_USER_DETAILS, GET_ALL_USERS } from '../../../Queries/Users';
import { enUS } from 'date-fns/locale';

// import { DatePicker, LocalizationProvider, AdapterDayjs } from '@mui/x-date-pickers';
import { Form, Input, Button, Select, Switch, DatePicker, Checkbox, Typography, Row, Col, AutoComplete, Divider, Space } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import { input } from '@testing-library/user-event/dist/cjs/event/input.js';
import { timerHistoryPayloadChange } from '../../../Slices/user';
const { Option } = Select;
const { Text } = Typography

const SaleInformation2 = memo((props) => {
    const options = useSelector((state) => state.dataBucket.databucket);
    const options1 = null;
    console.log("qefrwgrdrd", options)

    // const renderOptions = (options, criteria, fallbackData = []) => {
    //     console.log("fallbackData",fallbackData)

    //     return options?.reduce((acc, item) => {
    //         // Check if the item name matches the criteria
    //         if (item.name === criteria.name && item.data) {
    //             // Filter subItems based on active status and flag === true
    //             const filteredSubItems = item.data.filter(
    //                 (subItem) => subItem.status === "active" && subItem.flag === true
    //             );
    //             // Accumulate valid subItems into the result array
    //             acc.push(...filteredSubItems);
    //         }
    //         return acc;
    //     }, []).map((subItem, index) => (
    //         <Option key={index} value={subItem?._id}>
    //             {subItem?.name}
    //         </Option>
    //     )) ||
    //      fallbackData?.map((item, index) => (
    //         <Option key={index} value={item}>
    //             {item}
    //         </Option>
    //     ));
    // };

    // console.log("qefrwgrdrd",options);
    console.log('lag', 1);
    dayjs.extend(timezone);
    dayjs.extend(utc);
    const { SalesInfo, initialValues, salesLeadId, salesDates, assignToUsers, id } = props;
    const task = useSelector((state) => state.timer.task);
    const dispatch = useDispatch();
    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    const [isRunning, setIsRunning] = useState(false);
    const CurrentTask = "Data Processing";
    const [updateWorkHistory, { data, loading, error }] =
        useMutation(UPDATE_WORKHISTORY);
    // console.log('SalesInfo-->', employeeDetails, values, SalesInfo, initialValues)
    const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext();

    const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails)
    console.log("getUserDetails-->", loginUser)
    console.log("DataBucket", DataBucket)
    const Timefield = null;
    const [age, setAge] = useState('');
    const [current, setCurrent] = useState(true)
    const [close, setClose] = useState(!values?.lastContactedDate ? true : false);

    useEffect(() => {
        setClose(values?.lastContactedDate ? false : true);
    }, [values?.lastContactedDate]);

    console.log("close", values?.lastContactedDate)
    console.log("close", close)
    // const classes = useStyles();

    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const isAssigned = null;
    const getDateTime = (timeValue) => {
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }
    function formatDateTime(timestamp) {
        const date = new Date(parseInt(timestamp));
        const formattedDate = date.toLocaleString('en-IN', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true,
            // timeZone: 'UTC',
        });

        let datee = formattedDate.replace(/\b(am|pm)\b/, match => match.toUpperCase());
        let finalDate = null;
        if (datee === "Invalid Date") {
            finalDate = "-"
        } else {
            finalDate = datee
        }

        return finalDate;
    }

    const getDueTimeOld = (timeValue, typeValue) => {

        const date = new Date(timeValue).toLocaleDateString();

        if (typeValue == 1) { // 2 hours
            date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
        } else if (typeValue == 2) { // 24 hours
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        } else if (typeValue == 3) { // 48 hours
            date.setTime(date.getTime() + 48);
        }
        return date;
    }
    const getDueTime = (timeValue, typeValue) => {

        const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

        const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');

        return newDateTime;
    }

    const getDueTimePriority = (emailText) => {
        let hours = 24;
        const hasRushOrUrgent = emailText.toLowerCase().includes("rush") || emailText.toLowerCase().includes("urgent");

        if (hasRushOrUrgent) {
            hours = 2;

        }

        return hours;
    }

    const userid = parseJwt(token)?.id


    useEffect(() => {
        setFieldValue('lastContactedDate', values.lastContactedDate)
    }, [values.lastContactedDate])

    // const usersList=useSelector((state) => state.employee.AllEmployees);
    // const assignToUsers = usersList.filter(user => user.employeestatus === "Active");
    // console.log("usersList",assignToUsers)
    const assignedToHandler = (event) => {
        const checkedId = event.target.value;

        setFieldValue('assignedTo', checkedId);
        if (checkedId == loginUser?.firstname) {
            setFieldValue('requestStatus', "In Progress");
        }
    }


    const emailSummaryChange = (event) => {
        setFieldValue('subject', event.target.value);

    }

    // const [isAssigned, setIsAssigned] = useState(false);
    const assignHandler = (checked) => {
        // setIsAssigned(checked);
        setFieldValue('assign', checked);

        if (checked) {
            setFieldValue('assignedTo', loginUser?.firstname + " " + loginUser?.lastname);
            setFieldValue('assignTo', loginUser?.firstname + " " + loginUser?.lastname);
        } else {
            setFieldValue('assignedTo', '');
            setFieldValue('assignTo', '');
        }

    }

    const radiohandleChange = (event) => {
        const checkedId = event.target.value;
        const isChecked = event.target.checked;

        setFieldValue('radioInfo', checkedId);

        if (checkedId == 'Completed') {
            if (isChecked) {
                setFieldValue('completedDate', dayjs(new Date()));
                setFieldValue('requestStatus', 'Completed');

            }

        }


    }

    const getAssignToUsers = (usersList) => {
        console.log("usersList", usersList)
        const usersListFormatted = [];
        for (let i = 0; i < usersList?.length; i++) {
            usersListFormatted.push({
                key: i,
                value: usersList[i].firstname + " " + usersList[i].lastname, // Concatenating firstname and lastname
            });
        }
        return usersListFormatted;
    }

    const startDateHandler = (date) => {
        setFieldValue('followupDate', date);

    };
    // console.log('getDateTime1', values.date, values)
    const handleStartButtonClick = async () => {

        const payload = {
            department: "Sales",
            task: "Data Processing",
            time: Date.now(),
            requestId: SalesInfo.leadID,
        };

        try {
            const response = await updateWorkHistory({
                variables: {
                    userId: parseJwt(token).id,
                    emailId: "",
                    requestId: SalesInfo?.leadID?.toString(),
                    clientName: "Bluepalnit",
                    processType: "",
                    taskType: "Data Processing",
                    email: "",
                    subject: "",
                    body: "",
                    startTime: Date.now().toString(),
                    endTime: "",
                },
            });
            // dispatch(addLatestReports(Reportpayload))
        } catch (err) {
            console.error("Error updating work history:", err);
        }
        localStorage.setItem("task", "Data Processing");
        localStorage.setItem("department", "Sales");
        localStorage.setItem("time", Date.now());
        localStorage.setItem("requestId", SalesInfo?.leadID || salesLeadId);
        // dispatch(updateAReport(Reportpayload));
        dispatch(Starttask(payload));
    };
    const handleStop = async () => {
        setIsRunning(false);

        const valuesObject = {
            id: Math.random().toString(),
            userId: parseJwt(token)?.id,
            title: "",
            description: "",
            clientName: "Blueplaint",
            department: "Operations",
            taskType: "Available",
            startTime: Date.now().toString(),
            endTime: Date.now().toString(),
            requestId: "",
        };
        //setRefetch(true);
        dispatch(timerHistoryPayloadChange({ data: valuesObject }));
        await updateWorkHistory({
            variables: {
                userId: parseJwt(token).id,
                emailId: "",
                requestId: "",
                clientName: "Bluepalnit",
                processType: "",
                taskType: "Available",
                email: "",
                subject: "",
                body: "",
                startTime: Date.now().toString(),
                endTime: "",
            },
        });
        const payload = {
            // department: values.processType,
            department: "",
            requestId: "",
            task: "Available",
            time: Date.now(),
        };
        localStorage.setItem("StartTime", new Date().getTime());
        localStorage.removeItem("department");
        localStorage.removeItem("requestId");
        dispatch(settaskStartTime(new Date().getTime()));
        dispatch(Starttask(payload));
    };

    const handleAddRow = (arrayHelpers) => {
        // setFieldValue('lastContactedDate', dayjs(new Date()));
        arrayHelpers.push({
            contactPerson: '',
            title: '',
            phone: '',
            email: '',
            remarks: '',
            status: false,
        });
    };

    const handleDeleteRow = (index, arrayHelpers) => {
        arrayHelpers.remove(index);
    };
    const getTimeZoneFinal = (timezonecode) => {
        let finalTimeZone = timezonecode;
        DataBucket.SALES_TIMEZONES?.map((SALES_TIMEZONE) => {
            if (SALES_TIMEZONE?.toLowerCase() == timezonecode?.toLowerCase()) {
                finalTimeZone = SALES_TIMEZONE;
            }
        })
        return finalTimeZone;
    }
    const getTimeZone = (timezonecode) => {
        if (timezonecode?.length == 3) {
            switch (timezonecode) {
                case 'EST':
                    return "Eastern Time";
                case 'CST':
                    return "Central Time";
                case 'MST':
                    return "Mountain Time";
                case 'PST':
                    return "Pacific Time";
                case 'HST':
                    return "Hawaii Time";
            }
        } else {
            return getTimeZoneFinal(timezonecode);
        }
    }

    const getDateTimeValue = (datevalue) => {
        let finalDate = null;
        if (datevalue != undefined || datevalue != '') {
            if (typeof datevalue === 'string' && datevalue?.length == 13) {
                finalDate = dayjs(parseInt(datevalue))
            } else if (typeof datevalue === 'object') {
                finalDate = datevalue
            }
        }
        return finalDate;
    }
    useEffect(() => {
        if (!values.followup) {
            setFieldValue('followupDate', '');
        }
    }, [values.followup]);

    console.log("final values", salesLeadId)
    const open = useSelector((state) => state.sales.open)

    return (
        <>
            <div style={{ paddingRight: '30px', marginTop: '15px', padding: '20px', backgroundColor: "#fff", borderRadius: '8px', boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}>
                <Typography.Title level={5}>Lead Information</Typography.Title>

                <Row gutter={16}>
                    {/* {!open && */}
                    <Col xs={24} sm={12} md={6} lg={2}>
                        <Form.Item
                            label="Lead ID#"
                            labelCol={{ span: 24 }} // Ensures the label takes full width
                            wrapperCol={{ span: 24 }}
                        >
                            {/* <Text style={{ fontWeight: 'bold' }}>{SalesInfo?.leadID || salesLeadId || '-'}</Text> */}
                            <Text style={{ fontWeight: 'bold' }}>{SalesInfo?.leadID || '-'}</Text>
                        </Form.Item>
                    </Col>
                    {/* } */}
                    {/* {!open && */}
                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Form.Item
                            label="Lead Created Date"
                            labelCol={{ span: 24 }} // Ensures the label takes full width
                            wrapperCol={{ span: 24 }}
                        >
                            {/* <Text style={{ fontWeight: 'bold' }}>{(SalesInfo?.createdDate && formatDateTime(SalesInfo?.createdDate))|| (formatDateTime(salesDates?.createdDate) || "-")}</Text> */}
                            <Text style={{ fontWeight: 'bold' }}>{(SalesInfo?.createdDate && formatDateTime(SalesInfo?.createdDate)) || "-"}</Text>
                        </Form.Item>
                    </Col>
                    {/* } */}
                    {/* {!open && */}
                    <Col xs={24} sm={12} md={6} lg={4}>
                        <Form.Item
                            label="Last Updated"
                            labelCol={{ span: 24 }} // Ensures the label takes full width
                            wrapperCol={{ span: 24 }}
                        >
                            {/* <Text style={{ fontWeight: 'bold' }}>{(SalesInfo?.lastUpdatedDate && formatDateTime(SalesInfo?.lastUpdatedDate)) || (formatDateTime(salesDates?.lastUpdatedDate) || "-")}</Text> */}
                            <Text style={{ fontWeight: 'bold' }}>{(SalesInfo?.lastUpdatedDate && formatDateTime(SalesInfo?.lastUpdatedDate)) || "-"}</Text>
                        </Form.Item>
                    </Col>
                    {/* } */}
                    <Col xs={24} sm={12} md={8} lg={5}>
                        <div className="d-flex justify-content-between align-items-center mb-2">
                            <label className="col-form-label mb-0">Last Contacted Date</label>
                            {close ? (
                                <Button
                                    type="text"
                                    size="small"
                                    style={{ color: '#00c5fb', padding: '0px', textDecoration: 'none', fontSize: '12px' }}
                                    onClick={() => {
                                        setFieldValue('lastContactedDate', dayjs(new Date()));
                                        setClose(false);
                                        setCurrent(false);
                                    }}
                                >
                                    Current
                                </Button>
                            ) : (
                                <>
                                    {current && (
                                        <Button
                                            type="text"
                                            size="small"
                                            style={{ color: '#00c5fb', padding: '0px', textDecoration: 'none', fontSize: '12px' }}
                                            onClick={() => {
                                                setFieldValue('lastContactedDate', dayjs(new Date()));
                                                setCurrent(false);
                                            }}
                                        >
                                            Current
                                        </Button>
                                    )}
                                    <Button
                                        type="text"
                                        size="small"
                                        style={{ color: '#00c5fb', padding: '0px', textDecoration: 'none', fontSize: '12px' }}
                                        onClick={() => {
                                            setFieldValue('lastContactedDate', null);
                                            setClose(true);
                                        }}
                                    >
                                        Clear
                                    </Button>
                                </>
                            )}

                        </div>
                        <Field name="lastContactedDate">
                            {({ field }) => (
                                <DatePicker
                                    showTime
                                    inputReadOnly={true}
                                    format="DD/MM/YYYY hh:mm A"
                                    value={close ? null : getDateTimeValue(field?.value)}
                                    onChange={(date) =>
                                        field.onChange({ target: { value: date, name: field.name } }, setClose(false), setCurrent(true))
                                    }
                                    disabledDate={(current) => current && current >= moment().endOf('day')}
                                    allowClear={false}
                                    style={{ width: '100%', minWidth: '200px' }}
                                />
                            )}
                        </Field>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Form.Item label="Follow-up" labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }}
                            style={{ marginLeft: '80px' }} >
                            <Switch
                                onChange={(checked) => handleChange({ target: { name: 'followup', value: checked } })}
                                checked={values.followup}
                            />
                        </Form.Item>
                    </Col>
                    {/* <Col xs={24} sm={12} md={12} lg={4}>
                        <Form.Item
                            label="Follow-up Date"
                            labelCol={{ span: 24 }} // Ensures the label takes full width
                            wrapperCol={{ span: 24 }} // Ensures the field takes full width
                        >
                        <Switch
                            onChange={(checked) => handleChange({ target: { name: 'followup', value: checked } })}
                            checked={values.followup}
                        />
                    </Form.Item>
                </Col> */}
                    <Col xs={24} sm={12} md={12} lg={4} style={{ marginRight: '15px' }}>
                        <Form.Item
                            label="Follow-up Date"
                            labelCol={{ span: 24 }} // Ensures the label takes full width
                            wrapperCol={{ span: 24 }} // Ensures the field takes full width
                        >
                            <Field name="followupDate">
                                {({ field }) => (
                                    <DatePicker
                                        inputReadOnly={true}
                                        format="DD/MM/YYYY"
                                        value={values.followup ? getDateTimeValue(field.value) : null}
                                        onChange={(date) =>
                                            field.onChange({ target: { value: date, name: field.name } })
                                        }
                                        disabled={!values.followup}
                                        disabledDate={(current) => {
                                            const today = dayjs().startOf('day');
                                            const oneYearLater = dayjs().add(1, 'year').endOf('day');
                                            return current && (current < today || current > oneYearLater);
                                        }}
                                        style={{ width: '100%', minWidth: '200px' }}
                                    />
                                )}
                            </Field>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={6}>
                        <Form.Item label="Call scheduled with Ops. Team" labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }} >
                            <Switch
                                onChange={(checked) => handleChange({ target: { name: 'callscheduledwithOPs', value: checked } })}
                                checked={values.callscheduledwithOPs}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={2}>
                        <Form.Item
                            label={values.assign ? "UnAssign" : "Assign"}
                            name="assign"
                            // valuePropName="checked" 
                            labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }}
                        >
                            <Switch
                                checked={values.assign}
                                onChange={(checked) => assignHandler(checked)}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Form.Item
                            label="Assigned to"
                            labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }} // Field takes full width
                        >
                            <Input value={values.assignedTo} disabled />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Form.Item
                            label="Assign to"
                            labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }} // Field takes full width
                        >
                            <Select
                                showSearch
                                // disabled={values.assign===false}
                                options={getAssignToUsers(assignToUsers)}
                                value={values.assignTo || initialValues.assignTo}
                                onChange={(value) => {
                                    setFieldValue('assignTo', value);
                                    setFieldValue('assignedTo', value);
                                    setFieldValue('assign', true)
                                }}
                                style={{ width: '100%' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={3}>
                        <Form.Item
                            label="Priority"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        // help={touched.priority && errors.priority}
                        // validateStatus={touched.priority && errors.priority ? "error" : ""}
                        >
                            <Field name="priority">
                                {({ field, form }) => (
                                    <Select
                                        value={field.value}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        style={{ width: "100%" }}
                                    >
                                        {renderOptions(options, { name: "SALES_PRIORITY" }, DataBucket.SALES_PRIORITY)}
                                    </Select>
                                )}
                            </Field>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={3}>
                        <Form.Item
                            label="Lead Status"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        // help={touched.status && errors.status}
                        // validateStatus={touched.status && errors.status ? "error" : ""}
                        >
                            <Field name="status">
                                {({ field, form }) => (
                                    <Select
                                        value={field.value}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        style={{ width: "100%" }}
                                    >
                                        {renderOptions(options, { name: "SALES_STATUS" }, DataBucket.SALES_STATUS)}
                                    </Select>
                                )}
                            </Field>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={3}>
                        <Form.Item
                            label="Lead Stage"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 24 }}
                        // help={touched.stage && errors.stage}
                        // validateStatus={touched.stage && errors.stage ? "error" : ""}
                        >
                            <Field name="stage">
                                {({ field, form }) => (
                                    <Select
                                        value={field.value}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        style={{ width: "100%" }}
                                    >
                                        {renderOptions(options, { name: "SALES_STAGE" }, DataBucket.SALES_STAGE)}
                                    </Select>
                                )}
                            </Field>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={3}>
                        <Form.Item
                            label="Lead Channel"
                            labelCol={{ span: 24 }}
                        // wrapperCol={{ span: 24 }}
                        // help={touched.channel && errors.channel}
                        // validateStatus={touched.channel && errors.channel ? "error" : ""}
                        >
                            <Field name="channel">
                                {({ field, form }) => (
                                    <Select
                                        value={field.value}
                                        onChange={(value) => form.setFieldValue(field.name, value)}
                                        style={{ width: "100%" }}
                                    >
                                        {renderOptions(options, { name: "SALES_CHANNAL" }, DataBucket.SALES_CHANNAL)}
                                    </Select>
                                )}
                            </Field>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={12} lg={4}>
                        <Form.Item label="Lead Successful" labelCol={{ span: 24 }}  // Label takes full width
                            wrapperCol={{ span: 24 }} >
                            <Switch
                                onChange={(checked) => handleChange({ target: { name: 'successfull', value: checked } })}
                                checked={values.successfull}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </div>




            <AgencyInformationForm
                values={values}
                errors={errors}
                touched={touched}
                handleChange={handleChange}
                handleStartButtonClick={handleStartButtonClick}
                handleStop={handleStop}
                isRunning={isRunning}
                task={task}
                CurrentTask={CurrentTask}
                id={id}
                leadID={SalesInfo?.leadID}
            />
            <div
                style={{
                    marginTop: '15px',
                    backgroundColor: '#fff',
                    padding: '20px',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '8px',
                }}
            >
                <Typography.Title level={5}>Contact Information</Typography.Title>
                <ContactInformationForm
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleAddRow={handleAddRow}
                />

                <CommentField label="Comments" name="contactInfoComments" />
            </div>
        </>
    );

})

export default SaleInformation2;