// timerSlice.js
import { createSlice,createAsyncThunk } from '@reduxjs/toolkit';
import { GET_WORKHISTORY_BY_USER_ID } from '../Queries/Orders';
import { client } from '..';
import { useMutation } from '@apollo/client';


export const fetchWorkHistoryAndStartTask = createAsyncThunk(
  'timer/fetchWorkHistoryAndStartTask', // action type
  async (userId, { rejectWithValue }) => {
    try {
      // Perform the GraphQL mutation
      const { data } = await client.mutate({
        mutation: GET_WORKHISTORY_BY_USER_ID,
        variables: { userId },
      });

      // Assuming data.getWorkHistoryID is the result of the mutation
      const taskData = data.getWorkHistoryID[0];
      const payload = {
        task: taskData.taskType,
        time: Number(taskData.startTime),
      };

      // Save data to localStorage
      localStorage.setItem('task', taskData.taskType);
      localStorage.setItem('time', taskData.startTime);

      // Return the payload to be used in the reducer
      return payload;
    } catch (error) {
      console.error('Error fetching work history:', error);
      return rejectWithValue('Error fetching work history');
    }
  }
);

const timerSlice = createSlice({
  name: 'timer',
  initialState: {
    task:localStorage.getItem('task') ||  'Available',
    loginTime:Number(localStorage.getItem('LoginTime')) || '0',
    isRunning: true,
    time:Number(localStorage.getItem('time')) || 0,
    taskUpdate: false,
    requestId:localStorage.getItem('requestId') || '',
    department:localStorage.getItem('department') || '',
  },
  reducers: {
    startTimer(state) {
      state.isRunning = true;
    },
    stopTimer(state) {
      state.isRunning = false;
      state.taskUpdate = true;

    },
    Starttask(state, action) {
    
      const { department, task,time,requestId } = action.payload;
      console.log("department, task,time,requestId",department, task,time,requestId)
      if (task === '') {
        state.task = localStorage.getItem('task');
      }else if (task) {
        state.taskUpdate = false;
        state.task = task; 
        state.time = time ; 
        state.isRunning = true;
    
      }
      if (department === '') {
        state.department = '';
      } else if (department) {
        state.department = department;
      }
    
      if (requestId === '') {
        state.requestId = '';
      } else if (requestId) {
        state.requestId = requestId;
      }
    },
    Endtask(state) {
    
      state.taskUpdate = false;
    },
    incrementTimer(state) {
      state.time += 1;
    },
    UpdateLoginTime(state,action) {
      state.loginTime = action.payload;
    },
    Updatetime(state,action) {
      state.time = action.payload;
    },
    Updatetask(state,action) {
      console.log("action.payload",action.payload)
      state.task = action.payload;
    },
    CloseTask(state, action) {
      state.taskUpdate = false; 
      state.isRunning = true;
     
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchWorkHistoryAndStartTask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Handle the action payload returned by the async thunk
        const { task, time } = action.payload;
        state.task = task;
        state.time = time;
        state.isRunning = true;
        state.taskUpdate = false;
      })

  },
});

export const { startTimer, stopTimer, incrementTimer,Updatetask, Starttask, Endtask,UpdateLoginTime,Updatetime,CloseTask } = timerSlice.actions;

export default timerSlice.reducer;
