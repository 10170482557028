import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AddCommentBox from './CommentBox';
import SalesInformationPopup from '../../../components/modelpopup/SalesInformationPopup';

const SchedulingTrack = ({ schedulingTrackInfo }) => {
  return (
    <>
    <div className="d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Scheduling Tracking{" "}
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#scheduling_tracking_modal"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Call Type</th>
                  <th>Emails</th>
                  <th>Phone Number</th>
                  <th>Call Scheduled By</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
              {schedulingTrackInfo?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.date ? dayjs(Number(item.date)).format('DD/MM/YYYY') : 'N/A'}</td>
                  <td>{item?.time ? dayjs(item.time, 'HH:mm:ss').format('hh:mm A') : 'N/A'}</td>
                  <td>{item?.callType || 'N/A'}</td>
                  <td>{item?.emails || 'N/A'}</td>
                  <td>{item?.phonenumber || 'N/A'}</td>
                  <td>{item?.callScheduledBy || 'N/A'}</td>
                  <td>{item?.remarks || 'N/A'}</td>
                </tr>
              ))}

              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddCommentBox name={'scheduledCallComments'}/>
     <SalesInformationPopup  name={'scheduledCallComments'}/>
    </>
    
  );
};

export default SchedulingTrack;
