// queries.js
import { gql } from '@apollo/client';

// Query to get the current fields
export const GET_FIELDS = gql`
  query mutation {
    GetDynamicField {
      _id
      status
      flag
      name
      data {
        _id
        status
        flag
        name
      }
    }
  }
`;

// Mutation to update a field
export const UPDATE_FIELD = gql`
  mutation UpdateDynamicField($id: String, $obj: String, $newData: DynamicFieldInputType!, $key: Boolean) {
    UpdateDynamicField(id: $id, objId: $obj, newData: $newData, key: $key) {
      status
      message
      response {
        _id
        status
        name
        data {
          _id
          status
          name
          flag 
        }
      }
    }
  }
`;
export const GET_DYNAMIC_ROUTES=gql`
query {
  getSideMenu {
    name
    department
    fields {
      name
      status
      Subfields{
      displayName
        name
        status
      } 
    }
  }
}
`
export const DAYNAMIC_ROUTES=gql`
mutation SideMENU($input: SideMenuInputType!) { 
  SideMENU(input: $input) { 
    status 
    message 
  }
}`

// Mutation to add a new field
export const ADD_NEW_FIELD = gql`
  mutation AddNewField($name: String) {
    CreateDynamicField(name: $name) {
      status
      message
      response {
        _id
        status
        name
        data {
          _id
          status
          name
          flag
        }
      }
    }
  }
`;

// Mutation to delete a field
// It looks like the mutation to delete a field is incomplete. You might want to add that as well.
