import React from "react";
import ReactDOM from "react-dom/client";
import Approuter from "./Routes/Approuter";
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.js";
import "@fortawesome/fontawesome-free/css/fontawesome.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/line-awesome.min.css";
import "./assets/scss/main.scss";
import "./assets/css/material.css";
import { ApolloClient, InMemoryCache, ApolloProvider ,createHttpLink} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { SERVER_URL } from "./databucket";



// Configure the HTTP link
const httpLink = createHttpLink({
  uri: SERVER_URL + '/graphql/',
  //uri: "http://localhost:5001/graphql", // Use "http" unless SSL is configured
});

// Add token to headers using authLink
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  console.log("Token being sent:", token); // Log the token
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});
//handle token errors
// const errorLink = onError(({ networkError }) => {
//   if (networkError && networkError.statusCode === 401) {
//     // Token expired or invalid
//     localStorage.removeItem("token");  // Clear token
//     window.location.href = "/login";   // Redirect to login page
//   }
// });

// export const client = new ApolloClient({
//   //link:authLink.concat(errorLink).concat(httpLink),
//   uri: SERVER_URL + '/graphql/',
//   //uri: 'http://localhost:5001/graphql/',
//   cache: new InMemoryCache(),
//   credentials: "include",
// });

// Apollo Client configuration
export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  credentials: "include", // Sends cookies or credentials with requests
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ApolloProvider client={client}>
      <Approuter/>
  </ApolloProvider>
);
