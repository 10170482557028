import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Endtask, Starttask } from '../Slices/timerslice';
import { Button } from 'react-bootstrap';
import { UPDATE_WORKHISTORY } from '../Queries/Orders';
import { useMutation,useQuery } from '@apollo/client';
import { addLatestReports } from '../Slices/employeereportslice';
import { useSelector } from 'react-redux';
import  {DEPARTMENTS,TIMER_TASKS}  from '../databucket';
import { renderOptions } from '../utilsdata';

const TaskComponent = ({ onToggleModal }) => {
    const [department, setDepartment] = useState('');
    const [task, setTask] = useState('');
    const [updateWorkHistory, { data, loading, error }] = useMutation(UPDATE_WORKHISTORY);
    const dispatch = useDispatch();
    const employeesData = useSelector((state) => state.employee.AllEmployees);
   console.log("employeesData",employeesData)
    function formatDateTime(receivedDate) {
        // Ensure the input is a valid Date object
        const now = new Date(receivedDate);
      
        // Format the date as DD/MM/YYYY
        const day = String(now.getDate()).padStart(2, '0');
        const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const year = now.getFullYear();
      
        // Format the time as HH:mm:ss AM/PM
        let hours = now.getHours();
        const minutes = String(now.getMinutes()).padStart(2, '0');
        const seconds = String(now.getSeconds()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
      
        if (hours > 12) {
          hours -= 12; // Convert to 12-hour format
        } else if (hours === 0) {
          hours = 12; // Handle midnight
        }
      
        hours = String(hours).padStart(2, '0');
      
        // Combine everything into the desired format
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
      }
     
      
    // const departments = ['Sales', 'Operations'];
    // const tasks = [
    //     'Available',
    //     'Break',
    //     'Meeting',
    //     'Admin',
    //     'Management',
    //     'Training',
    //     'Cross-Training',
    //     'No-work Assigned'
    // ];
    
    const handleDepartmentChange = (e) => {
        setDepartment(e.target.value);
    };
    const options = useSelector((state) => state.dataBucket.databucket);
    console.log("gygy",DEPARTMENTS);

    const handleTaskChange = (e) => {
        setTask(e.target.value);
    };

    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const  userId=  parseJwt(token).id

    const specificEmployee = employeesData?.find(employee => employee.id === userId);
  console.log("specificEmployee",specificEmployee)

    const handleStartButtonClick = async() => {
        const payload = {
            department,
            task,
            time: Date.now(),
            requestId: "",
        };
        const Reportpayload = {
            userId: parseJwt(token).id,
            employeeName:specificEmployee.firstname + '' + specificEmployee.lastname,
            emailId: specificEmployee.email,
            requestId: 'N/A',
            reportingTo:specificEmployee.specificEmployee || 'N/A',
            clientName: 'Bluepalnit',
            requestIds:'N/A',
            processType: '',
            taskType: task,
            billable:"N/A",
            service:"N/A",
            requestCompleted:"N/A",
            requestId:"N/A",
            startTime: Date.now().toString(),
            startingTime: formatDateTime(Date.now()),
            status: 'Pending',
            endTime: '',
            overrideHistory:[],
        };
        try {
            const response = await updateWorkHistory({
                variables: {
                    userId: parseJwt(token).id,
                    emailId: '',
                    requestId: '',
                    clientName: 'Bluepalnit',
                    processType: '',
                    taskType: task,
                    email: '',
                    subject: '',
                    body: '',
                    startTime: Date.now().toString(),
                    endTime: '',
                },
            });
            dispatch(addLatestReports(Reportpayload))
            console.log('Update successful:', response.data.updateWorkHistory);
        } catch (err) {
            console.error('Error updating work history:', err);
        }
        localStorage.setItem('task', task);
        localStorage.setItem('time', Date.now());
        // dispatch(updateAReport(Reportpayload));
        dispatch(Starttask(payload));
    };



    const styles = {
        container: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '20px',
            fontFamily: 'Arial, sans-serif',
            position: 'relative', // Added relative positioning for the close button
        },
        closeButton: {
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: '#ff4d4d',
            border: 'none',
            borderRadius: '50%',
            padding: '5px 10px',
            color: 'white',
            fontSize: '16px',
            cursor: 'pointer',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.2)',
            transition: 'background-color 0.3s',
        },
        dropdown: {
            margin: '10px',
            width: '200px',
        },
        label: {
            marginBottom: '5px',
            fontWeight: 'bold',
            fontSize: '14px',
            color: '#333',
        },
        select: {
            width: '100%',
            padding: '10px',
            borderRadius: '5px',
            border: '1px solid #ccc',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)',
            fontSize: '14px',
            transition: 'border-color 0.3s',
        },
        buttonContainer: {
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'center',
        },
        button: {
            margin: '0 10px',
        },
    };

    return (
        <>
            <div style={styles.container}>
              

                <div style={styles.dropdown}>
                    <label htmlFor="department" style={styles.label}>Department:</label>
                    <select
                        id="department"
                        value={department}
                        onChange={handleDepartmentChange}
                        style={styles.select}
                    >
                        <option value="">Select a department</option>
                        {renderOptions(options, { name: "DEPARTMENTS" },DEPARTMENTS)}
                        
                    </select>
                </div>

                <div style={styles.dropdown}>
                    <label htmlFor="task" style={styles.label}>Task:</label>
                    <select
                        id="task"
                        value={task}
                        onChange={handleTaskChange}
                        style={styles.select}
                    >
                        <option value="">Select a task</option>
                        {(renderOptions(options, { name: "TIMER_TASKS" },TIMER_TASKS))}
                    </select>
                </div>
            </div>
            <div style={styles.buttonContainer}>
                <Button variant="primary" onClick={handleStartButtonClick}>
                    Start
                </Button>
            </div>
        </>
    );
};

export default TaskComponent;
