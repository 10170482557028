import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import notifications from "../../assets/json/notifications";
import message from "../../assets/json/message";
import Timer from "../../components/Timer";
import { useNavigate } from "react-router-dom";
import { setId } from "../../Slices/employeeslice.js";

import Swal from "sweetalert2";
import { UPDATE_PUNCHOUT } from "../../Queries/attendence.js";
import { ATTENDENCE } from "../../Queries/attendence.js";
import {
  logout,
  timerHistoryPayloadChange,
  timerPayloadUpdate,
} from "../../Slices/user";
import {
  CREATE_TIMERHISTORY,
  UPDATE_WORKHISTORY,
  GET_TIMERWORKHISTORY_BY_ID,
  GET_WORKHISTORY_BY_USER_ID,
} from "../../Queries/Orders.js";
import { UPDATE_LOGINWORKHISTORY } from "../../Queries/Orders.js";
import { useMutation } from "@apollo/client";
import { Modal, Button } from "react-bootstrap";
import { Starttask, Endtask } from "../../Slices/timerslice.jsx";
import { useQuery } from "@apollo/client";
import { SINGLE_USER } from "../../Queries/Employees.js";

//import timerHistoryPayloadChange from
import {
  Applogo,
  Avatar_02,
  headerlogo,
  lnEnglish,
  lnFrench,
  lnGerman,
  lnSpanish,
} from "../../Routes/ImagePath";
import { UPDATE_EMPLOYEE } from "../../Queries/Employees.js";
import { FaRegBell, FaRegComment } from "react-icons/fa";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import { setAllNote, setProfileData } from "../../Slices/employeeslice.js";
import i18n from "../../i18n";
import { CloseTask } from "../../Slices/timerslice.jsx";
import TaskComponent from "../../components/TaskComponent.jsx";
import { setLoginUserDetails } from "../../Slices/loginUserSlice.jsx";
import { setLoader } from "../../Slices/loaderSlice.jsx";
import { fetchWorkHistoryAndStartTask } from "../../Slices/timerslice.jsx";
import { useDispatch } from "react-redux";
const Header = (props) => {
  const { isSidebarVisible, setIsSidebarVisible } = props;
  const data = notifications.notifications;
   const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
  const datas = message.message;
  const [notification, setNotifications] = useState(false);
  const [loginWorkHistoryId, setLoginWorkHistoryId] = useState("");
  const [flag, setflag] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [profile, setProfile] = useState(false);
  const [startTime, setStartTime] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const [Attendenceupdate] = useMutation(UPDATE_PUNCHOUT)
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const [timerData, setTimerData] = useState({});
  const [flagImage, setFlagImage] = useState(lnEnglish);
  const { timerHistoryPayload, previousTimerHistoryPayload } = useSelector(
    (state) => state.user
  );
  const lastAttendance = useSelector((state) => state.Attendence.lastRecord);
  console.log("Redux Attendance State:", lastAttendance);
  const ID = lastAttendance?.response?.id
  console.log("ewffd", ID, lastAttendance)
  const taskUpdate = useSelector((state) => state.timer.taskUpdate);
  const [getTimerWorkHistoryById] = useMutation(GET_TIMERWORKHISTORY_BY_ID);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [showDialog, setShowDialog] = useState(false);
  const detail = useSelector((state) => state.employee.AllEmployees);
  const [getWorkHistory, { loading, error }] = useMutation(
    GET_WORKHISTORY_BY_USER_ID
  );
  //const [createTimerHistory] = useMutation(CREATE_TIMERHISTORY);

  const [slide, setSlide] = useState(false);
  const navigate = useNavigate();
  const initialTime = 0;
  const handlesidebar = () => {
    setSlide(!slide);
    console.log("slide", slide);
    document.body.classList.toggle("mini-sidebar");
  };
  const handleMouseEnter = () => {
    document.body.classList.remove("mini-sidebar");
    //setIsSidebarVisible(true);
  };
  // const toggleSidebar = () => {
  //   setIsSidebarVisible(prevState => !prevState);  // Toggle the visibility
  // };
  const onMenuClik = () => {
    document.body.classList.toggle("slide-nav");
  };
  const dispatch = useDispatch();
  dispatch(setAllNote());
  const themes = localStorage.getItem("theme");
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setNotifications(false);
    setProfile(false);
    setflag(false);
  };

  const handleNotification = () => {
    setNotifications(!notification);
    setflag(false);
    setIsOpen(false);
    setProfile(false);
  };
  const handleProfile = () => {
    setProfile(!profile);
    setNotifications(false);
    setflag(false);
    setIsOpen(false);
  };

  const location = useLocation();
  let pathname = location.pathname;
  const Credencial = localStorage.getItem("credencial");
  const Value = JSON.parse(Credencial);
  const UserName = Value?.email?.split("@")[0];
  const ProfileName = UserName?.charAt(0).toUpperCase() + UserName?.slice(1);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    // Debugging statement
    i18n.changeLanguage(lng);
    setFlagImage(
      lng === "en"
        ? lnEnglish
        : lng === "fr"
          ? lnFrench
          : lng === "es"
            ? lnSpanish
            : lnGerman
    );
  };

  // Logout function
  const token = localStorage.getItem("token");

  const loginTime = localStorage.getItem("LoginTime");
  const parseJwt = (token) => {
    try {
      return token ? JSON.parse(atob(token.split(".")[1])) : null;
    } catch (e) {
      return null;
    }
  };

  const userid = parseJwt(token)?.id;
  const profileDate = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
  const loginWorkHistoryObj = token ? { userId: parseJwt(token)?.id } : null;
  const [updateLoginWorkHistory] = useMutation(UPDATE_LOGINWORKHISTORY);
  const UpdateOnLogout = (userid) => {
    console.log("userid", userid);
    updateLoginWorkHistory({
      variables: {
        id: userid,
        endTime: new Date().toISOString(),
      },
    });
  };
  const Id = useSelector((state) => state.employee.id);
  // useEffect(() => {
  //   const userId = userid?.toString();
  //   const entries = performance.getEntriesByType("navigation");

  //   if (entries.length > 0 && entries[0].type === "reload") {
  //     // const fetchWorkHistory = async () => {
  //     //   console.log("segsgdsdsdbbf")
  //     //   try {
  //     //     const response = await getWorkHistory({ variables: { userId } });
  //     //     console.log(
  //     //       "response.data.getWorkHistoryID",
  //     //       response.data.getWorkHistoryID[0].taskType
  //     //     );
  //     //     const payload = {
  //     //       task: response.data.getWorkHistoryID[0].taskType,
  //     //       time: Number(response.data.getWorkHistoryID[0].startTime),
  //     //     };
  //     //     localStorage.setItem(
  //     //       "task",
  //     //       response.data.getWorkHistoryID[0].taskType
  //     //     );
  //     //     localStorage.setItem(
  //     //       "time",
  //     //       response.data.getWorkHistoryID[0].startTime
  //     //     );
  //     //     dispatch(Starttask(payload));
  //     //   } catch (error) {

  //     //     alert("You Have Already Loggedout!");

  //     //     localStorage.removeItem("token");
  //     //     navigate("/");
  //     //     console.log("error", error);
  //     //   }
  //     // };

  //     // fetchWorkHistory();
  //   dispatch(fetchWorkHistoryAndStartTask(userId))
  //   }
  // }, [userid]);
  const userId = userid?.toString();
  useEffect(() => {
    // Check if the page has been refreshed
    if (!sessionStorage.getItem("isRefreshed")) {

      dispatch(fetchWorkHistoryAndStartTask(userId));
     
      sessionStorage.setItem("isRefreshed", "true");
    }
    
    
    return () => {
     
    };
  }, [dispatch, userId])
  const handleClose = () => {
    dispatch(CloseTask());
  };

  const [updateTimerHistory] = useMutation(UPDATE_WORKHISTORY, {
    onCompleted(data) {
      if (data?.updateWorkHistory?.status === "200") {
        if (timerHistoryPayload?.logOut) {
          dispatch(timerPayloadUpdate());
          // localStorage.removeItem("token");
          localStorage.clear();
          navigate("/login");
        }
        //  else {
        //   createTimerHistory({ variables: timerHistoryPayload });
        // }
      } else {
        alert("An Error Occurred while Updating Timer");
      }
    },
  });

  // Handle Logout with Timer Update
  const HandleLogout = async () => {
    
    
    if (!loginUser?.punchInStatus) {
      // If status is false, log out directly without showing Swal
      console.log("Directly logging out...");
      const val = await performLogout();
      dispatch(setLoader(val));
      return;
    }

    // Otherwise, show Swal popup for options
    const swalOptions = {
      title: "Choose an Action",
      text: "If you want to punch out, click Punch Out.\nIf you want to log out, click Logout.\nIf you want to do both, click Both.",
      icon: "warning",
      showDenyButton: true, // Always show Logout button
      denyButtonText: "Logout", // Logout button text
      allowOutsideClick: false,
      allowEscapeKey: false,
      showCloseButton: true, // Show X (Close) button
      customClass: {
        closeButton: "swal-no-border", // Remove X button border
      },
      confirmButtonText: "Punch Out",
      showConfirmButton: true, // Show Punch Out button
      showCancelButton: true, // Show Both button
      cancelButtonText: "Both",
    };

    // Show Swal popup
    Swal.fire(swalOptions).then((result) => {
      console.log("result", result);

      if (result.isConfirmed) {
        dispatch(setLoader(true));
  
        console.log("User chose Punch Out!", ID);
        Attendenceupdate({
          variables: {
            id: ID,
            input: {
              punchOut: Date.now().toString(),
              status: false,
            },
          },
        });
         updateEmployee({ variables:{
          id: loginUser?.id,
          input:{
              punchInStatus:false,
          }
      } });
        dispatch(setLoginUserDetails({
          ...profileDate, // Assuming loginUserDetails is available in Redux state
          punchInStatus:false
        }));

        console.log("User punched out!");
        dispatch(setLoader(false));
      } else if (result.isDenied) {
        console.log("User chose Logout!");
        const val = performLogout();
        dispatch(setLoader(val));
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        console.log("User chose Both (Punch Out & Logout)!");
        Attendenceupdate({
          variables: {
            id: ID,
            input: {
              punchOut: Date.now().toString(),
              status: false,
            },
          },
        });
        updateEmployee({ variables:{
          id: loginUser?.id,
          input:{
              punchInStatus:false,
          }
      } });
        dispatch(setLoginUserDetails({
          ...profileDate, // Assuming loginUserDetails is available in Redux state
          punchInStatus: false
        }));
        const val = performLogout();
        dispatch(setLoader(val));
      }
    });
  };


  // Function to handle logout process
  const performLogout = async () => {
    const Logoutpayload = {
      department: "",
      task:"",
      time:"",
      requestId: "",
  };
    dispatch(setId(false));
    const payload = { logOut: true };
    UpdateOnLogout(userid);

    dispatch(timerHistoryPayloadChange({ data: payload }));
    dispatch(timerPayloadUpdate({ data: payload }));

    const logoutUpdatePayload = {
      userId: userid,
      endTime: Date.now().toString(),
      emailId: "",
      requestId: "",
      clientName: "Bluepalnit",
      processType: "",
      taskType: "Loggingout",
      email: "",
      subject: "",
      body: "",
      startTime: "",
    };

    await updateTimerHistory({
      variables: logoutUpdatePayload,
      onCompleted(data) {
        // localStorage.removeItem("loginUserDetails");
        dispatch(Starttask(Logoutpayload))
        console.log("Logout successful, clearing storage...");
        localStorage.clear();
        // Prevent unnecessary navigation or re-mounting
        navigate("/");
        // dispatch(setLoginUserDetails(""));// Navigate only if not already on "/"

      },
    });

    return false;
  };



  const handleStart = () => {
    setIsRunning(true);
  };

  const handleStop = () => {
    setIsRunning(false);
    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),

      endTime: Date.now().toString(),
    };
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
  };

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      intervalId = setInterval(() => {
        const currentTime = new Date().getTime();
        const timeDifference = currentTime - parseInt(startTime);
        setElapsedTime(timeDifference);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isRunning, startTime]);

  const username = `${profileDate?.firstname} ${profileDate?.lastname}`
  // const id = parseJwt(token)?.id;
  // const { data:singleData} = useQuery(SINGLE_USER, {
  //   variables: { id: id },
  //   fetchPolicy: "network-only", // Always fetch from the server
  //   onCompleted: (singleData) => {
  //    // const employees = data.SingleEmployee.response;
  //     console.log("wrgf",singleData);
  //     setProfileData(singleData.SingleEmployee.response)
  //   },
  // }); praveen comment this

  return (
    <div className="header" style={{ right: "0px" }}>
      {/* Logo */}
      <div className="header-left">
        <Link to="/admin-dashboard" className="logo"></Link>
        <Link to="/admin-dashboard" className="logo2">
          {/* <img src={Applogo} width={40} height={40} alt="img" /> */}
        </Link>
      </div>
      {/* /Logo */}
      <Link
        id="toggle_btn"
        to="#"
        style={{
          display: pathname.includes("tasks")
            ? "none"
            : pathname.includes("compose")
              ? "none"
              : "",
        }}
        onMouseEnter={handleMouseEnter}
        //onClick={toggleSidebar}
        onClick={handlesidebar}
      >
        <span className="bar-icon">
          <span />
          <span />
          <span />
        </span>
      </Link>
      {/* Header Title */}
      <div className="page-title-box">
        <h3>BluePlanIt</h3>
      </div>
      {/* /Header Title */}
      <Link
        id="mobile_btn"
        className="mobile_btn"
        to="#"
        onClick={() => onMenuClik()}
      >
        <i className="fa fa-bars" />
      </Link>
      {/* Header Menu */}
      <ul className="nav user-menu">
        {/* Search */}
        <li className="nav-item">
          <div className="top-nav-search">
            <Link to="#" className="responsive-search">
              <i className="fa fa-search" />
            </Link>
            {/*<form>
              <input
                className="form-control"
                type="text"
                placeholder="Search here"
              />
              <button className="btn" type="submit">
                <i className="fa fa-search" />
              </button>
            </form>*/}
          </div>
        </li>

        <Timer
          isRunning={isRunning}
          onStart={handleStart}
          onStop={handleStop}
        //resetTimer={handelReset}
        />
        <Modal show={taskUpdate} style={{ top: '10%' }}>
          <Modal.Header style={{ position: "relative" }}>
            <Modal.Title>Update Task</Modal.Title>
            <button
              onClick={handleClose}
              style={{
                background: "none",
                border: "none",
                fontSize: "20px",
                color: "black",
                position: "absolute",
                top: "10px",
                right: "10px",
                cursor: "pointer",
              }}
            >
              &times;
            </button>
          </Modal.Header>
          <Modal.Body>
            <TaskComponent />
          </Modal.Body>
        </Modal>

        <li className="nav-item dropdown has-arrow main-drop">
          <Link
            to="#"
            className="dropdown-toggle nav-link"
            data-bs-toggle="dropdown"
            onClick={handleProfile}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center", // Ensure the name and image are centered
              gap: "8px", // Add spacing between image and name
            }}
          >
            <span className="user-img me-1" style={{ display: "flex", alignItems: "center" }}>
              {profileDate?.image ? (
                <img
                  src={profileDate?.image}
                  alt="User"
                  style={{
                    width: "30px",
                    height: "30px",
                    borderRadius: "50%",
                    backgroundColor: "#ccc",
                    color: "#333",
                    border: "2px solid #ddd",
                    objectFit: "cover", // Ensures the image doesn't stretch
                  }}
                />
              ) : (
                <img src={Avatar_02} alt="img" style={{ width: "30px", height: "30px", borderRadius: "50%" }} />
              )}
              <span className="status online" />
            </span>
            <span>
              {
                // slide
                // ? 
                `${username?.length > 20
                  ? `${username.slice(0, 20)}...`
                  : username
                }`
              }
            </span>
          </Link>

          <div
            className={`dropdown-menu dropdown-menu-end ${profile ? "show" : ""
              }`}
          >
            <Link
              className="dropdown-item"
              to="/profile"
              onClick={() => {
                dispatch(setId(true));
              }}
            >
              My Profile
            </Link>
            <Link className="dropdown-item" to="/settings/companysetting">
              Settings
            </Link>
            <Link className="dropdown-item" >
              <p onClick={HandleLogout}>Logout</p>
            </Link>
          </div>
        </li>
      </ul>
      {/* /Header Menu */}
      {/* Mobile Menu */}
      <div className="dropdown mobile-user-menu">
        <Link
          to="#"
          className="nav-link dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <i className="fa fa-ellipsis-v" />
        </Link>
        <div className="dropdown-menu dropdown-menu-end dropdown-menu-right">
          <Link className="dropdown-item" to="/profile">
            My Profile
          </Link>
          <Link className="dropdown-item" to="/settings/companysetting">
            Settings
          </Link>
          <Link className="dropdown-item" to="/login">
            <p onClick={HandleLogout}>Logout</p>
          </Link>
        </div>
      </div>
      {/* /Mobile Menu */}
    </div>
  );
};

export default Header;
