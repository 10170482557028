import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { ListItem } from './ProfileContent';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import ProfileLeaveSettingsModal from '../../../components/modelpopup/ProfileLeaveSettingsModal';
import { SINGLE_USER } from '../../../Queries/Employees';
import { setFilteredLeaveData } from '../../../Slices/employeeslice';
import { useDispatch, useSelector } from "react-redux";
import { setLoader } from "../../../Slices/loaderSlice";
const ProfileLeaveSettings = () => {

    // const [employeeLeaves, setEmployeeLeaves] = useState({})
    const dispatch = useDispatch();
    const { Empid } = useParams();

    const [department, setDepartment] = useState("");
    const [year, setYear] = useState("");

    const { data, loading, error, refetch } = useQuery(SINGLE_USER, {
        variables: { id: Empid },
        fetchPolicy: "network-only", // Always fetch from the server
        onCompleted: (data) => {
            const employeesLeaves = data.SingleEmployee.response.leavesSettings;
            const userDepartment = data.SingleEmployee.response.department;
            const currentYear = new Date().getFullYear().toString(); // Get current year
            setDepartment(userDepartment);
            setYear(currentYear);
            // Filter based on current year and department = "Both"
            const filteredLeave = employeesLeaves?.find(
                (leave) => leave.year === currentYear && leave.department === userDepartment
            );
            const { __typename, ...filteredLeaveData } = filteredLeave;

            if (filteredLeave) {
                // setEmployeeLeaves(filteredLeave);
                // Dispatch the cleaned object
                console.log("filteredLeaveData", filteredLeaveData);
                dispatch(setFilteredLeaveData(filteredLeaveData));
            } else {
                console.warn("No matching leave data found");
            }
        },
    });
    dispatch(setLoader(loading))
    const employeeLeaves = useSelector((state) => state.employee.filteredLeaveData);
    console.log("bankInfoup", employeeLeaves)

    const leaveData = [
        { id: 1, title: "Casual Leaves", text: employeeLeaves?.casualLeaves || 0 },
        { id: 2, title: "Carry Forward Leaves", text: employeeLeaves?.CarryforwardLeaves || 0 },
        { id: 3, title: "Medical Leave", text: employeeLeaves?.medicalLeave || 0 },
        { id: 4, title: "Carry Forward", text: employeeLeaves?.carryFlag ? "Yes" : "No" },
        { id: 5, title: "Update like other employees", text: !employeeLeaves?.Key ? "Yes" : "No" },
    ];
    return (
        <div className="tab-content">
            <div id="emp_profile" className="pro-overview tab-pane fade show active">
                <div className="row">
                    <div className="col-md-6 d-flex">
                        <div className="card profile-box flex-fill">
                            <div className="card-body">
                                <h3 className="card-title">
                                    Leave Information
                                    <Link
                                        to="#"
                                        className="edit-icon"
                                        data-bs-toggle="modal"
                                        data-bs-target="#leave_settings_modal"
                                    >
                                        <i className="fa fa-pencil" />
                                    </Link>
                                </h3>
                                <ul className="personal-info">
                                    {leaveData.map((item, index) => (
                                        <ListItem
                                            id={index}
                                            key={index}
                                            title={item.title}
                                            text={item.text}
                                        />
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ProfileLeaveSettingsModal department={department} year={year} />
        </div>
    );
};

export default ProfileLeaveSettings;
