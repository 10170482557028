import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AddCommentBox from './CommentBox';
import SalesInformationPopup from '../../../components/modelpopup/SalesInformationPopup';

const LinkedInTrack = ({ linkedinTrackInfo }) => {
  return (
    <>
    <div className="d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            LinkedIn Tracking{" "}
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#linkedin_tracking_modal"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Request To</th>
                  <th>Request By</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {linkedinTrackInfo?.map((item, index) => (
                 <tr key={index}>
                    <td>{item?.date ? dayjs(Number(item.date)).format('DD/MM/YYYY') : 'N/A'}</td>
                    <td>{item?.time ? dayjs(item.time, 'HH:mm:ss').format('hh:mm A') : 'N/A'}</td>
                  <td>{item?.status || 'N/A'}</td>
                  <td>{item?.requestTo || 'N/A'}</td>
                  <td>{item?.requestBy || 'N/A'}</td>
                  <td>{item?.remarks || 'N/A'}</td>
                </tr>               
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddCommentBox name={'linkedinComments'}/>
     <SalesInformationPopup  name={'linkedinComments'}/>
    </>
    
  );
};

export default LinkedInTrack;
