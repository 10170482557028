// import React from 'react';
// import { useQuery } from '@apollo/client';
// import { gql } from 'graphql-tag';
// import { Table } from 'antd';
// import moment from 'moment';
// import { useSelector } from 'react-redux';

// // GraphQL query to fetch the login work history
// const GET_LOGIN_WORK_HISTORY = gql`
//   query {
//     LoginWorkHistoryResolver {
//       _id
//       firstname
//       lastname
//       department
//       approvedLeaves {
//         _id
//         leaveType
//         startDate
//         endDate
//         leaveStatus
//         session1
//         session2
//       }
//       holidays {
//         holidayName
//         holidayDate
//       }
//       attendanceRecords {
//       punchOut
//       status
//       punchIn
//     }
//     }
//   }
// `;

// const AttendenceAdmin = () => {
//   const { loading, error, data } = useQuery(GET_LOGIN_WORK_HISTORY);
//   const employeesData = useSelector((state) => state.employee.AllEmployees);
  
//   if (loading) return <p>Loading...</p>;
//   if (error) return <p>Error: {error.message}</p>;

//   // Generate columns dynamically for each day of the month
//   const getDaysInMonth = (month, year) => {
//     const days = [];
//     const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

//     // Generate columns for each day of the month
//     for (let day = 1; day <= daysInMonth; day++) {
//       days.push({
//         title: `${day}`,
//         dataIndex: `day${day}`,
//         key: `day${day}`,
//         render: (value) => {
//           if (value === '✅') return '✅';  // Mark present
//           if (value === '❌') return '❌';  // Mark absent
//           if (value === 'H') return '🏖️';  // Mark holiday (weekend)
//           if (value === 'OH') return '🎃';  // Mark official holiday
//           if (value === 'L') return '❌';  // Mark leave
//           return '-';  // 🚫Upcoming or undefined days
//         },
//       });
//     }
//     return days;
//   };

//   // Define the columns dynamically for each day of the month
//   const currentMonth = moment().month() + 1; // Get current month (1-12)
//   const currentYear = moment().year(); // Get current year
//   const columns = [
//     {
//       title: 'Employee Name',
//       dataIndex: 'employeeName',
//       key: 'employeeName',
//     },
//     ...getDaysInMonth(currentMonth, currentYear), // Add dynamic days columns
//   ];

//   // Process the data to map the work history to each day
//   const workHistories = data.LoginWorkHistoryResolver.map((employee) => {
//     // Calculate attendance for each day of the current month
//     const daysInMonth = moment(`${currentYear}-${currentMonth}`, 'YYYY-MM').daysInMonth();
//     const workHistoryData = {
//       ...employee,
//       employeeName: `${employee.firstname} ${employee.lastname}`,
//       userId: employee._id, // Use _id as row key
//     };

//     // Get today's date for upcoming day comparison
//     const today = moment().date();

//     // Create attendance status for each day
//     Array.from({ length: daysInMonth }, (_, day) => {
//       const dayKey = `day${day + 1}`;
//       const currentDay = moment(`${currentYear}-${currentMonth}-${day + 1}`, 'YYYY-MM-DD'); // Get current day as moment object
    
//       // 1. Check if the day is a weekend (Saturday or Sunday)
//       const isWeekend = currentDay.day() === 6 || currentDay.day() === 0; // Saturday (6) or Sunday (0)
//       if (isWeekend) {
//         workHistoryData[dayKey] = 'H'; // Mark weekend as holiday
//         return;
//       }
    
//       // 2. Check if the day is an official holiday
//       const isHoliday = employee.holidays.some(holiday =>
//         moment(holiday.holidayDate).isSame(currentDay, 'day')
//       );
//       if (isHoliday) {
//         workHistoryData[dayKey] = 'OH'; // Mark as official holiday
//         return;
//       }
    
//       // 3. Check if the current day is after today (upcoming day)
//       if (currentDay.date() > today) {
//         // Check if the upcoming day falls within the leave range
//         const isLeave = employee.approvedLeaves.some(leave => {
//           const leaveEnd = moment(parseInt(leave.endDate));
//           const leaveStart = moment(parseInt(leave.startDate));
    
//           // If the current upcoming day is within the leave range, mark it as 'L'
//           return currentDay.isBetween(leaveStart, leaveEnd, 'day', '[]') && leave.leaveStatus === 'Approved';
//         });
    
//         if (isLeave) {
//           workHistoryData[dayKey] = 'L'; // Mark as leave for upcoming days that fall within the leave range
//         } else {
//           workHistoryData[dayKey] = '-'; // Mark as upcoming day
//         }
//         return;
//       }
    
//       // 4. Check if the employee has approved leave for this day
//       const isLeave = employee.approvedLeaves.some(leave => {
//         const leaveEnd = moment(parseInt(leave.endDate));
//         const leaveStart = moment(parseInt(leave.startDate));
    
//         // Check if the current day is within the leave range and the leave status is approved
//         if (currentDay.isBetween(leaveStart, leaveEnd, 'day', '[]') && leave.leaveStatus === 'Approved') {
//           let currentDayInRange = moment(leaveStart);
//           while (currentDayInRange.isBefore(leaveEnd) || currentDayInRange.isSame(leaveEnd, 'day')) {
//             const leaveDayKey = `day${currentDayInRange.date()}`;
//             workHistoryData[leaveDayKey] = 'L'; // Mark as leave
//             currentDayInRange.add(1, 'day'); // Move to the next day
//           }
//           return true;
//         }
//         return false;
//       });
    
//       if (isLeave) {
//         return; // Exit if leave is found for this day
//       }
    
//       // 5. Mark the rest of the days as present (✅)
//       workHistoryData[dayKey] = '✅'; // Mark present
//     });
    
    

//     return workHistoryData;
//   });

//   return (
//     <div className="page-wrapper">
//       <div className="content container-fluid">
//         <div className="row">
//           <div className="col-lg-12">
//             <div className="table-responsive">
//               <Table
//                 columns={columns}
//                 dataSource={workHistories}
//                 rowKey="userId"
//                 pagination={false}
//                 bordered
//               />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default AttendenceAdmin;


import React from 'react';
import { useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';
import { Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';

// GraphQL query to fetch the login work history
const GET_LOGIN_WORK_HISTORY = gql`
  query {
    LoginWorkHistoryResolver {
      _id
      firstname
      lastname
      department
      approvedLeaves {
        _id
        leaveType
        startDate
        endDate
        leaveStatus
      }
      holidays {
        holidayName
        holidayDate
      }
      attendanceRecords {
        punchOut
        status
        punchIn
      }
    }
  }
`;

const AttendenceAdmin = () => {
  const { loading, error, data } = useQuery(GET_LOGIN_WORK_HISTORY);
  const employeesData = useSelector((state) => state.employee.AllEmployees);
  
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Generate columns dynamically for each day of the month
  const getDaysInMonth = (month, year) => {
    const days = [];
    const daysInMonth = moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();

    // Generate columns for each day of the month
    for (let day = 1; day <= daysInMonth; day++) {
      days.push({
        title: `${day}`,
        dataIndex: `day${day}`,
        key: `day${day}`,
        render: (value) => {
          if (value === '✅') return '✅';  // Mark present
          if (value === '❌') return '❌';  // Mark absent (approved leave)
          if (value === 'H') return '🏖️';  // Mark holiday (weekend)
          if (value === 'OH') return '🎃';  // Mark official holiday
          if (value === 'L') return '❌';  // Mark leave
          if (value === 'AA') return '⚠️'; // Mark absent (no punch-in/out or leave)
          return '-';  // 🚫Upcoming or undefined days
        },
      });
    }
    return days;
  };

  // Define the columns dynamically for each day of the month
  const currentMonth = moment().month() + 1; // Get current month (1-12)
  const currentYear = moment().year(); // Get current year
  const columns = [
    {
      title: 'Employee Name',
      dataIndex: 'employeeName',
      key: 'employeeName',
    },
    ...getDaysInMonth(currentMonth, currentYear), // Add dynamic days columns
  ];

  // Process the data to map the work history to each day
  const workHistories = data.LoginWorkHistoryResolver.map((employee) => {
    // Calculate attendance for each day of the current month
    const daysInMonth = moment(`${currentYear}-${currentMonth}`, 'YYYY-MM').daysInMonth();
    const workHistoryData = {
      ...employee,
      employeeName: `${employee.firstname} ${employee.lastname}`,
      userId: employee._id, // Use _id as row key
    };

    // Get today's date for upcoming day comparison
    const today = moment().date();

    // Create attendance status for each day
    Array.from({ length: daysInMonth }, (_, day) => {
      const dayKey = `day${day + 1}`;
      const currentDay = moment(`${currentYear}-${currentMonth}-${day + 1}`, 'YYYY-MM-DD'); // Get current day as moment object
      
      // 1. Check if the day is a weekend (Saturday or Sunday)
      const isWeekend = currentDay.day() === 6 || currentDay.day() === 0; // Saturday (6) or Sunday (0)
      if (isWeekend) {
        workHistoryData[dayKey] = 'H'; // Mark weekend as holiday
        return;
      }
    
      // 2. Check if the day is an official holiday
      const isHoliday = employee.holidays.some(holiday =>
        moment(holiday.holidayDate).isSame(currentDay, 'day')
      );
      if (isHoliday) {
        workHistoryData[dayKey] = 'OH'; // Mark as official holiday
        return;
      }
    
      // 3. Check if the current day is after today (upcoming day)
      if (currentDay.date() > today) {
        // Check if the upcoming day falls within the leave range
        const isLeave = employee.approvedLeaves.some(leave => {
          const leaveEnd = moment(parseInt(leave.endDate));
          const leaveStart = moment(parseInt(leave.startDate));
    
          // If the current upcoming day is within the leave range, mark it as 'L'
          return currentDay.isBetween(leaveStart, leaveEnd, 'day', '[]') && leave.leaveStatus === 'Approved';
        });
    
        if (isLeave) {
          workHistoryData[dayKey] = 'L'; // Mark as leave for upcoming days that fall within the leave range
        } else {
          workHistoryData[dayKey] = '-'; // Mark as upcoming day
        }
        return;
      }
    
      // 4. Check if the employee has approved leave for this day
      const isLeave = employee.approvedLeaves.some(leave => {
        const leaveEnd = moment(parseInt(leave.endDate));
        const leaveStart = moment(parseInt(leave.startDate));
    
        // Check if the current day is within the leave range and the leave status is approved
        if (currentDay.isBetween(leaveStart, leaveEnd, 'day', '[]') && leave.leaveStatus === 'Approved') {
          workHistoryData[dayKey] = '❌'; // Mark as approved leave
          return true;
        }
        return false;
      });
    
      if (isLeave) {
        return; // Exit if leave is found for this day
      }
    
      // 5. Check if the employee has punchIn and punchOut for the day
      
    // 5. Check if the employee has punchIn and punchOut for the day
const hasPunchInOut = employee?.attendanceRecords?.some(record => {
  const punchInDate = moment(parseInt(record.punchIn));
  const punchOutDate = moment(parseInt(record.punchOut));

  // Ensure that both punchIn and punchOut are valid and match the current day
  return punchInDate.isSame(currentDay, 'day') && punchOutDate.isSame(currentDay, 'day');
});

if (hasPunchInOut) {
  workHistoryData[dayKey] = '✅'; // Mark present if punchIn and punchOut exist
} else {
  workHistoryData[dayKey] = 'AA'; // Mark absent if no punchIn/punchOut and no leave or holiday
}


      if (hasPunchInOut) {
        workHistoryData[dayKey] = '✅'; // Mark present if punchIn and punchOut exist
      } else {
        workHistoryData[dayKey] = 'AA'; // Mark absent if no punchIn/punchOut and no leave or holiday
      }
    });

    return workHistoryData;
  });

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={workHistories}
                rowKey="userId"
                pagination={false}
                bordered
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendenceAdmin;
