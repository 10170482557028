import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    webScraper: []
};

const ScrapperSlice = createSlice({
    name: "Scrapper",
    initialState,
    reducers: {
        setScraper(state, action) {
            console.log("wfwd",action.payload);
            state.webScraper = action.payload;
            console.log("svsvsv",state.webScraper);
        }
    }
});

// Export the reducer
export default ScrapperSlice.reducer;

// Export the action
export const { setScraper } = ScrapperSlice.actions;
