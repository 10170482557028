import { useQuery, useMutation, from } from "@apollo/client";
import React, { useState, useEffect } from "react";
import { Avatar_02 } from "../../Routes/ImagePath";
import DatePicker from "react-datepicker";
import Select from "react-select";
// Import useRef for referencing DOM elements
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { message } from "antd";
import { Form, Button, Container } from 'react-bootstrap';
import { Link, useParams, useNavigate } from "react-router-dom";
import { SINGLE_USER, UPDATE_EMPLOYEE, GET_EMPLOYEE_DETAILS, EDIT_WORKHISTORY } from "../../Queries/Employees";
import { GET_ALL_CLIENTS } from "../../Queries/Users";
import Swal from 'sweetalert2';
import { TIMER_DEPARTMENT, ROLE, STATUS, bloodGroups, genderOptions, employmentOptions, workOptions, Rehire, DOMAIN } from "../../databucket";
import { useErrorLogger } from "../../utils";
import { useApolloClient } from "@apollo/client";
import dayjs from "dayjs";
import { DateTime } from 'luxon';
import { useDispatch } from "react-redux";
import { renderOptionsformultiple } from "../../utilsdata";

import {
  setUserDatas,
  setManagersDatas,
  setBankInfos,
  SideMenu,
  setEditHistory,
  setClientHistorys,
  setEmploymentDatas,
  setPersonalInfos,
  setId,
  SetAllEmployees,
  setAllNotes,
  updateNoteData,
  setClients,
  setWorkExperiences,
  setEmergencyContactes,
  setWorkHistorys,
  setReportsDatas,
  setEducationDatas,
  setFamilyMemberses,
  setRoutes,
} from "../../Slices/employeeslice";
import { UPDATE_CLIENT } from "../../Queries/Orders";
import { useSelector } from "react-redux";
import { setLoader } from "../../Slices/loaderSlice";
import { setreupdate, setRoute } from "../../Slices/DyanamicRoute";


const PersonalInformationModelPopup = (props) => {

  const { logError } = useErrorLogger();
  const client = useApolloClient();
  const name = props?.name;
  const setEmpupdate = props?.setEmpupdate ?? (() => { });
  const [updateclient] = useMutation(UPDATE_CLIENT);
  const dispatch = useDispatch();
  const [submitBtnBlock, setSubmitBtnBlock] = useState(false);
  const options = useSelector((state) => state.dataBucket.databucket);

  const { Empid } = useParams();
  const token = localStorage.getItem("token");
  useEffect(() => {
    setTimeout(() => {
      setSubmitBtnBlock(false);
    }, 5000);
  }, [submitBtnBlock])
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const id = parseJwt(token)?.id;
  const ids = Empid ? Empid : id;

  const { data, loading, error, refetch } = useQuery(SINGLE_USER, {
    variables: { id: ids },
    fetchPolicy: "network-only", // Always fetch from the server
    onCompleted: (data) => {
      const employees = data.SingleEmployee.response;
    },
  });
  const alphaRegex = /^[A-Za-z\s]+$/;
  const [emergencyContacts, setEmergencyContacts] = useState([]);
  const [educationData, setEducationData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});

  const [note, setNote] = useState();

  const [isEmploymentFormValid, setIsEmploymentFormValid] = useState(false);
  const [workHistoryRecord, setWorkHistory] = useState([]);
  const [workExperience, setWorkExperience] = useState()

  const [errors, setErrors] = useState({});
  const [familyMembers, setFamilyMembers] = useState([]);
  const [isDirectReportFormValid, setIsDirectReportFormValid] = useState(false);
  const [isManagerFormValid, setIsManagerFormValid] = useState(false);
  const [isBankInfoFormValid, setIsBankInfoFormValid] = useState(false);
  const [isFamilyFormValid, setIsFamilyFormValid] = useState(false);
  const [isEducationFormValid, setIsEducationFormValid] = useState(false);
  const [personalValid, setpersonalValid] = useState(false);
  const [isContactsValid, setIsContactsValid] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUserDataValid, setIsUserDataValid] = useState(false);
  const [reportsData, setReportsData] = useState([]);
  const [managersData, setManagersData] = useState([]);


  const bankInfoup = useSelector((state) => state.employee.BankInfos);
  const UserDataup = useSelector((state) => state.employee.userData);
  const Personalup = useSelector((state) => state.employee.personalInfo);
  const Client = useSelector((state) => state.employee.clientHistory);
  const History = useSelector((state) => state.employee.workHistory);
  const Education = useSelector((state) => state.employee.educationData);
  const Reportsup = useSelector((state) => state.employee.reportsDatas);
  const managers = useSelector((state) => state.employee.managersData);
  const contactup = useSelector((state) => state.employee.emergencyContacts);
  const Familyup = useSelector((state) => state.employee.familyMembers);
  const Expirence = useSelector((state) => state.employee.workExperience);
  const EmployeeStatusup = useSelector((state) => state.employee.employmentData);
  const notess = useSelector((state) => state.employee.notes)
  const ID = useSelector((state) => state.employee.id);
  const AllEmployees = useSelector((state) => state.employee.AllEmployees);
  const clientOptions = useSelector(state => state.clients.clients);
  // const { data: EmpData } = useQuery(GET_EMPLOYEE_DETAILS);
  const [EditemployeeDetails] = useMutation(EDIT_WORKHISTORY);
  // const { data: clientData } = useQuery(GET_ALL_CLIENTS);
  const Employee = useSelector((state) => state.employee.AllEmployees);
  const proof = useSelector((state) => state.employee.personalInfo);
  const profile = useSelector((state) => state.employee.profile);
  const details = useSelector((state) => state.employee.AllEmployees);
  const employee = details.find((emp) => emp.id === id);

  const [updatedRoutes, setUpdatedRoutes] = useState();
  const route = useSelector((state) => state.Route.empRoute);
  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters

    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }

    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)

    e.target.value = value;
  };



  useEffect(() => {

    const targetNote = notess.find((note) => note?.name == name);
    if (targetNote) {
      setNote(targetNote?.data);
    }
    setUserData({
      EmpID: UserDataup?.EmpID,
      firstname: UserDataup?.firstname,
      lastname: UserDataup?.lastname,
      dob: UserDataup?.dob || '',
      gender: UserDataup?.gender,
      currentAddress: UserDataup?.currentAddress,
      mobile: UserDataup?.mobile,
      department: UserDataup?.department,
      designation: UserDataup?.designation,
      reportingTo: UserDataup?.reportingTo,
      bloodGroup: UserDataup?.bloodGroup,
      permanentAddress: UserDataup?.permanentAddress,
      alternativeNumber: UserDataup?.alternativeNumber,
      maritalStatus: UserDataup?.maritalStatus,
      employeestatus: UserDataup?.employeestatus || '',
      processSplization: UserDataup?.processSplization || '',
      email: UserDataup?.email || '',
      DateOfJoining: UserDataup?.DateOfJoining || '',
      personalEmail: UserDataup?.personalEmail || '',
      role: UserDataup?.role || '',
      clients: UserDataup?.clients || '',
    });
    setEmergencyContacts(contactup)

    setEducationData(Education);
    setManagersData(managers);
    setFamilyMembers(Familyup);
    setReportsData(Reportsup);

    setWorkHistory(History);
    setBankInfo({
      BankAccountNo: bankInfoup?.BankAccountNo || '',
      Branch: bankInfoup?.Branch || '',
      IFCcode: bankInfoup?.IFCcode || '',
      BankName: bankInfoup?.BankName || '',
      PanNumber: bankInfoup?.PanNumber || '',
    });
    setEmploymentData({
      employmentStatus: EmployeeStatusup?.employmentStatus,
      resignationStatus: EmployeeStatusup?.resignationStatus,
      noticePeriod: EmployeeStatusup?.noticePeriod,
      resignationDate: EmployeeStatusup?.resignationDate,
      lastWorkingDay: EmployeeStatusup?.lastWorkingDay,
      RehireEligibility: EmployeeStatusup?.RehireEligibility,
    });
    setWorkExperience({
      ShiftTimings: Expirence?.ShiftTimings || '',
      EmploymentType: Expirence?.EmploymentType || '',
      Experience: {
        years: Expirence?.Experience?.years || '',
        months: Expirence?.Experience?.months || '',
      },
      WorkType: Expirence?.WorkType || '',
      ProbationStatus: Expirence?.ProbationStatus || '',
      probationPeriod: Expirence?.probationPeriod || '',
    });
    setPersonalInfo({
      adharNumber: proof?.adharNumber,
      PanNumber: proof?.PanNumber,
      UAN: proof?.UAN,
      EsiNo: proof?.EsiNo,
      PfNo: proof?.PfNo,
      HealthInsuranceNo: proof?.HealthInsuranceNo,
    });

  }, [UserDataup, managers, Education, name])


  const [employmentData, setEmploymentData] = useState({
    employmentStatus: '',
    resignationStatus: '',
    noticePeriod: '',
    resignationDate: '',
    lastWorkingDay: '',
    RehireEligibility: ''
  });
  const [bankInfo, setBankInfo] = useState({
    BankAccountNo: '',
    BankName: '',
    IFCcode: '',
    Branch: '',
    PanNumber: '',
  });

  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);

  const [personalInfo, setPersonalInfo] = useState({
    adharNumber: '',
    PanNumber: '',
    UAN: '',
    EsiNo: '',
    PfNo: '',
    HealthInsuranceNo: '',

  });



  const [userData, setUserData] = useState({
    EmpID: "",
    firstname: "",
    lastname: "",
    dob: "",
    gender: "",
    currentAddress: "",
    mobile: "",
    department: "",
    DateOfJoining: "",
    designation: "",
    reportingManager: "",
    employeestatus: "",
    bloodGroup: '',
    permanentAddress: '',
    maritalStatus: '',
    processSplization: '',
    alternativeNumber: '',
    email: '',
    clients: '',
    personalEmail: '',
    role: ''
  });

  useEffect(() => {
    if (userData.role && userData.department) {

      const ROUTE = route?.find(
        (item) =>
          item?.name === userData?.role && item?.department === userData?.department
      );
      if (!ROUTE) {

        return;
      }
      const formattedData = ROUTE?.fields?.map((menu) => ({
        name: menu.name,
        status: menu.status,
        Subfields: menu.Subfields?.map((sub) => ({
          displayName: sub.displayName || "MISSING", // Debugging
          name: sub.name,
          status: sub.status,
        })) || [],
      }));



      if (formattedData?.length === 0) {
      }
      setUpdatedRoutes(formattedData);
    }
  }, [userData.role, userData.department, route]);

  //  dispatch(SideMenu(formattedData))
  useEffect(() => {
    try {
      const cachedData = client.readQuery({
        query: SINGLE_USER,
        variables: { id: ids },
      });

      const cachedNote = cachedData?.SingleEmployee?.response?.[name];
      const targetNote = notess.find((note) => note.name === name);

      if (targetNote && targetNote.status && name === targetNote.name) {
        // If the note is found in the cache, bind it
        if (cachedNote) {
          dispatch(setAllNotes({ data: cachedNote, name: name }));
          setNote(cachedNote); // Bind cached data to the state

        } else {
        }
      }
    } catch (error) {

    }
  }, [name, data]);

  const mobilePattern = /^[6-9]\d{9}$/; // Mobile number should be exactly 10 digits
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.com$/;
  const companyEmailPattern = /^[a-zA-Z0-9._-]+@blueplanitcorp\.com$/; // Company email validation

  // State for errors
  const [errores, setErrores] = useState({});
  const [errorChange, setErrorChange] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    let newErrors = { ...errores };

    // Field-specific validation using regex patterns
    switch (name) {
      case 'mobile':
        newErrors.mobile = mobilePattern.test(value) ? null : "Invalid mobile number.";
        break;
      case 'personalEmail':
        if (value.trim() === '') {
          delete newErrors.personalEmail; // Remove the error if the field is empty
        } else {
          newErrors.personalEmail = emailPattern.test(value)
            ? null
            : "Invalid email format.";
        }
        break;

      case 'email':
        if (value.trim() === '') {
          newErrors.email = "email is required"; // Remove the error if the field is empty
        } else {
          newErrors.email = emailPattern.test(value)
            ? null
            : "Invalid email format.";
        }
        break;

      case 'employeestatus':
        newErrors.employeestatus = value ? null : "employeestatus is required.";
        break;
      case 'alternativeNumber':
        if (!value) {
          delete newErrors.alternativeNumber;
        }
        else if (mobilePattern.test(value)) {
          delete newErrors.alternativeNumber; // Remove error if valid
        } else {
          newErrors.alternativeNumber = "Invalid alternative number."; // Set error if invalid
        }
        break;
      case 'firstname':
        newErrors.firstname = /^[A-Za-z\s-]+$/.test(value) ? null : "First name should only contain letters, spaces, or hyphens.";
        break;
      case 'lastname':
        newErrors.lastname = /^[A-Za-z\s-]+$/.test(value) ? null : "Last name should only contain letters, spaces, or hyphens.";
        break;
      case 'department':
        // newErrors.department = value ? null : "department Status is required.";
        //  updateRoute();
        break;

      // case 'designation':

      //   newErrors.designation = value ? null : "designation Status is required.";
      //   break;
      // case 'processSplization':

      //   newErrors.processSplization =/^[A-Za-z\s-]+$/.test(value) ? null : "processSplization should only contain letters, spaces, or hyphens.";
      //   break;
      case 'gender':
        newErrors.gender = value ? null : "gender  is required.";
        break;
      //   case 'clients':

      //   newErrors.clients = value ? null : "clients  is required.";
      //   break;
      case 'role':
        //  updateRoute();
        // newErrors.role = value ? null : "role  is required.";
        break;
      case 'personalEmail':
        newErrors.personalEmail = emailPattern.test(value) ? null : "Invalid email format.";
        break;
      case 'dob':
        if (!value) {
          newErrors.dob = "DOB is required.";
        } else {
          const currentDate = new Date();
          const enteredDate = new Date(value);
          const age = currentDate.getFullYear() - enteredDate.getFullYear();
          const isMonthValid =
            currentDate.getMonth() > enteredDate.getMonth() ||
            (currentDate.getMonth() === enteredDate.getMonth() && currentDate.getDate() >= enteredDate.getDate());

          // Adjust the age if the current month/day is before the birth month/day
          const actualAge = isMonthValid ? age : age - 1;

          if (actualAge < 20 || actualAge > 58) {
            newErrors.dob = "Age must be between 20 and 58 years.";
          } else {
            newErrors.dob = null; // Valid date of birth
          }
        }
        break;
        case 'DateOfJoining': {
          const today = new Date();
          const oneYearAgo = new Date();
          const oneYearFromTomorrow = new Date();
        
          // Move to tomorrow
          oneYearFromTomorrow.setDate(oneYearFromTomorrow.getDate() + 1);
        
          // Set one year ago from today
          oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
        
          // Set one year from tomorrow
          oneYearFromTomorrow.setFullYear(oneYearFromTomorrow.getFullYear() + 1);
        
          // Normalize the time to avoid time-zone issues
          today.setHours(0, 0, 0, 0);
          oneYearAgo.setHours(0, 0, 0, 0);
          oneYearFromTomorrow.setHours(0, 0, 0, 0);
        
          // Ensure the input value is a valid date
          const inputDate = new Date(value);
          inputDate.setHours(0, 0, 0, 0); // Normalize
        
          if (isNaN(inputDate.getTime())) {
            newErrors.DateOfJoining = "Invalid date format.";
          } else if (inputDate < oneYearAgo || inputDate > oneYearFromTomorrow) {
            newErrors.DateOfJoining = "Date of Joining must be between one year ago and one year from tomorrow.";
          } else {
            newErrors.DateOfJoining = null; // No error
          }
          break;
        }
        
      // case 'maritalStatus':
      //   newErrors.maritalStatus = value ? null : "maritalStatus Status is required.";
      //   break;

      default:
        break;
    }

    setErrores(newErrors);

    // Check for any errors and mark the form as changed

    setErrorChange(true);
  };
  useEffect(() => {
    const requiredFields = ['mobile', 'email', 'firstname', 'lastname', 'dob', 'gender',"DateOfJoining"];

    const allFieldsValid = requiredFields.every((field) => {
      return !errores[field] && Boolean(userData[field]); // Ensure field is non-empty and has no error
    });
    const isAlternativeNumberValid = !errores['alternativeNumber'];
    setIsUserDataValid(allFieldsValid && isAlternativeNumberValid && errorChange);
  }, [userData]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    let newErrors = { ...errores }; // Reset error object properly
    if (!userData.firstname) newErrors.firstname = "Firstname is required";
    if (!userData.lastname) newErrors.lastname = "Lastname is required";
    if (!userData.employeestatus?.trim()) newErrors.employeestatus = "Employee status is required.";
    if (!userData.mobile) newErrors.mobile = "Mobile number is required.";
    if (!userData.gender) newErrors.gender = "Gender is required.";
    if (!userData.email) newErrors.email = "Email is required.";
    if (!userData.dob) newErrors.dob = "DOB is required.";
    // if(mobilePattern.test(userData.alternativeNumber)) newErrors.alternativeNumber="Invalid alternative number"
    setErrores(newErrors);

    // If there are errors, stop submission
    const error = Object.values(newErrors).some((val) => val)
    const noErrors = Object.keys(errores).length === 0;
    // Prevent submission if no data changes are detected
    if (!isUserDataValid && noErrors) {
      message.error({
        content: "No Change Detected to Update",
        duration: 2,
        className: "custom-toast-center",
      });
      setSubmitBtnBlock(true);
      return;
    }
    try {

      if (isUserDataValid && newErrors) {
        dispatch(setLoader(true));
        const { id, ...dataWithoutEmpid } = userData;
        // Destructure and omit empid

        let variables = updatedRoutes
          ? { id: ids, input: { ...dataWithoutEmpid, menu: updatedRoutes } }
          : { id: ids, input: { ...dataWithoutEmpid } };

        let { data } = await updateEmployee({ variables });
        setIsUserDataValid(false);
        if (data?.UpdateEmploye?.status) {
          await EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname} ${employee?.lastname}`,
                action: "Main Profile",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(),
              },
            },
          });

          dispatch(setUserDatas({ ...userData }));
          dispatch(SideMenu(updatedRoutes))
          const updatedEmployees = AllEmployees.map((employee) =>
            employee.id === ids
              ? {
                ...employee,
                firstname: userData.firstname || employee.firstname,
                lastname: userData.lastname || employee.lastname,
                department: userData.department || employee.department,
                employeestatus: userData.employeestatus || employee.employeestatus,
              }
              : employee
          );

          dispatch(SetAllEmployees(updatedEmployees));
          dispatch(setreupdate(true));
          setEmpupdate(true);

          message.success({
            content: data?.UpdateEmploye?.message,
            duration: 5,
            className: 'alert alert-success text-center',
          });
        } else {
          message.error({
            content: data?.UpdateEmploye?.message,
            duration: 5,
            className: 'alert alert-success text-center',
          });
        }
      }
    } catch (error) {
      await logError("PersonalInformationModelPopup", "handleSubmit", error.message);


      message.error({
        content: data?.UpdateEmploye?.message,
        duration: 5,
        className: "alert alert-danger text-center",
      });
    } finally {
      dispatch(setLoader(false));
      setErrorChange(false);
      setIsUserDataValid(false);
      setSubmitBtnBlock(false); // Reset submit button block
    }
  };


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#837F7F" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#837F7F",
      },
    }),
  };
  const [workHistoryErrors, setWorkHistoryErrors] = useState({});
  // Input Change Handler for Work History Data
  // Handle changes for inputs and date pickers
  const handleHistoryRecordChange = (index, e) => {
    let name, value;

    // If e.target exists, it's a regular input change
    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    }
    // If it's a date change (e is an instance of Date), handle it accordingly
    else if (e && e instanceof Date) {
      name = e.name;
      value = e;  // Date object for from/to fields
    } else {

      return;
    }

    // Update the work history data with the new value
    const updatedWorkHistoryData = [...workHistoryRecord];
    if (!updatedWorkHistoryData[index]) {
      updatedWorkHistoryData[index] = {}; // Initialize if the index doesn't exist
    }
    updatedWorkHistoryData[index] = { ...updatedWorkHistoryData[index], [name]: value };
    setWorkHistory(updatedWorkHistoryData);

    // Update errors dynamically
    const updatedErrors = { ...workHistoryErrors };
    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Validate required fields (department, designation, clientHandled, processHandled)
    const requiredFields = ["department", "designation", "clientHandled", "processHandled"];

    if (requiredFields?.includes(name)) {
      if (value?.trim() === '') {
        updatedErrors[index][name] = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
      } else {
        delete updatedErrors[index][name]; // Clear error if valid
      }
    }
    if (name === "from" || name === "to" || name === "shiftTimings") {
      if (!value) {
        updatedErrors[index][name] = `${name} date is required.`;
      } else {
        delete updatedErrors[index][name]; // Remove error if a date is selected
      }
    }
    // Clean up empty error objects for the current index
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setWorkHistoryErrors(updatedErrors);

    // Validate if all required fields are filled correctly (no errors)
    const allFieldsValid = updatedWorkHistoryData.every((record, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        record?.department?.trim()?.length > 0 &&
        record?.designation?.trim()?.length > 0 &&
        record?.clientHandled?.trim()?.length > 0 &&
        record?.processHandled?.trim()?.length > 0 &&
        record?.from &&
        record?.to &&
        record?.shiftTimings &&
        Object.keys(recordErrors).length === 0
      );
    });

    setIsFormValid(allFieldsValid);
  };





  // Add New Work History Entry
  const addWorkHistory = () => {
    const newWorkHistory = {
      department: "",
      designation: "",
      clientHandled: "",
      processHandled: "",
      shiftTimings: "",
      from: "",
      to: "",
    };

    // Add new row without setting validation errors initially
    setWorkHistory((prev) => [...prev, newWorkHistory]);
    setIsFormValid(false);
  };

  // Delete Work History Entry
  const deleteWorkHistory = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedWorkHistory = workHistoryRecord?.filter((_, i) => i !== index);
        setWorkHistory(updatedWorkHistory);

        // Remove the corresponding error entry
        const updatedErrors = { ...workHistoryErrors };
        delete updatedErrors[index];

        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });

        setWorkHistoryErrors(reindexedErrors);
        // const noErrors = Object.keys(updatedErrors).length === 0;
        setIsFormValid(reindexedErrors);
      }
    })
  };

  // Work History Form Submit Handler
  const handleWorkHistorySubmit = async (e) => {
    e.preventDefault();

    const newErrors = { ...workHistoryErrors };

    // Validate each work history entry before submission
    workHistoryRecord.forEach((workHistory, index) => {

      if (!workHistory.department?.trim()) {
        newErrors[index] = { ...newErrors[index], department: "Department is required" };
      } else if (!alphaRegex.test(workHistory.department)) {
        newErrors[index] = { ...newErrors[index], department: "Department must contain only letters" };
      }

      if (!workHistory.designation?.trim()) {
        newErrors[index] = { ...newErrors[index], designation: "Designation is required" };
      } else if (!alphaRegex.test(workHistory.designation)) {
        newErrors[index] = { ...newErrors[index], designation: "Designation must contain only letters" };
      }

      if (!workHistory.clientHandled?.trim()) {
        newErrors[index] = { ...newErrors[index], clientHandled: "Client Handled is required" };
      } else if (!alphaRegex.test(workHistory.clientHandled)) {
        newErrors[index] = { ...newErrors[index], clientHandled: "Client Handled must contain only letters" };
      }

      if (!workHistory.processHandled?.trim()) {
        newErrors[index] = { ...newErrors[index], processHandled: "Process Handled is required" };
      } else if (!alphaRegex.test(workHistory.processHandled)) {
        newErrors[index] = { ...newErrors[index], processHandled: "Process Handled must contain only letters" };
      }

      if (!workHistory.shiftTimings?.trim()) {
        newErrors[index] = { ...newErrors[index], shiftTimings: "Shift Timings are required" };
      }

      if (!workHistory.from) {
        newErrors[index] = { ...newErrors[index], from: "From Date is required" };
      }


      if (!workHistory.to) {
        newErrors[index] = { ...newErrors[index], to: "To Date is required" };
      }

    });

    setWorkHistoryErrors(newErrors);
    // Prevent submission if errors exist
    if (Object.keys(newErrors).length > 0) return;
    const noErrors = Object.keys(newErrors).length === 0;
    if (noErrors && !isFormValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }
    try {
      const formattedWorkHistoryData = workHistoryRecord?.map(({ __typename, ...workHistory }) => workHistory);
      if (newErrors && isFormValid) {
        dispatch(setLoader(true))
        // Submit the data
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              workHistory: formattedWorkHistoryData,
            },
          },
        });
        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "workHistory",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "workHistory",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setWorkHistorys(formattedWorkHistoryData));
          setIsFormValid(true);
          dispatch(setLoader(false))
          message.success({
            content: data?.UpdateEmploye?.message,
            duration: 5,
            className: 'alert alert-success text-center',
          });
        }
      }
    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleWorkHistorySubmit", error.message);

      message.error({
        content: "Failed to update handleWorkHistorySubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsFormValid(false); // Ensure form state resets
    }
  };

  const [contactErrors, setContactErrors] = useState({});
  // Inline Validation on Change
  const handleEmergencyChange = (index, e) => {
    const { name, value } = e.target;
    const updatedContacts = [...emergencyContacts];
    updatedContacts[index] = { ...updatedContacts[index], [name]: value };
    setEmergencyContacts(updatedContacts);

    // Create a copy of the errors object to update inline errors dynamically
    const updatedErrors = { ...contactErrors };

    // Initialize errors for this index if not already present
    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Validate the specific field
    if (value?.trim() === '') {
      updatedErrors[index][name] = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
    } else {
      // Validate name and relationship: Only letters, spaces, and hyphens allowed
      if (name === "name" || name === "relationship") {
        const regex = /^[A-Za-z\s-]+$/;
        if (!regex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid ${name}. Only letters, spaces, and hyphens are allowed.`;
        } else {
          delete updatedErrors[index][name]; // Remove error if input is valid
        }
      }

      // Validate phone number: Only 10 digits allowed
      if (name === "phoneNumber") {
        const phoneNumberRegex = /^[0-9]{10}$/;
        if (!phoneNumberRegex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid 10-digit phone number.`;
        } else {
          delete updatedErrors[index][name]; // Remove error if input is valid
        }
      }

      // Remove any remaining error for the field if no other issues
      if (updatedErrors[index][name] === undefined) {
        delete updatedErrors[index][name];
      }
    }

    // Remove the index from errors if it has no remaining errors
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setContactErrors(updatedErrors);

    // Check if all emergency contacts are valid (no empty fields and no errors)
    const allFieldsValid = updatedContacts?.every((contact, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        contact.name?.trim() &&
        contact.relationship?.trim() &&
        contact.phoneNumber?.trim() &&
        Object.keys(recordErrors).length === 0 // Ensure there are no errors
      );
    });

    setIsContactsValid(allFieldsValid);
  };
  // Submit Handler with Validation
  const handleEmergencySubmit = async (event) => {
    event.preventDefault();

    const newErrors = { ...contactErrors };
    // Regex for letters and spaces only
    // Validate each contact entry before submission
    emergencyContacts.forEach((contact, index) => {


      if (!contact.name?.trim()) {
        newErrors[index] = { ...newErrors[index], name: "Name is required" };
      } else if (!alphaRegex.test(contact.name)) {
        newErrors[index] = { ...newErrors[index], name: "Name must contain only letters" };
      }

      if (!contact.relationship?.trim()) {
        newErrors[index] = { ...newErrors[index], relationship: "Relationship is required" };
      } else if (!alphaRegex.test(contact.relationship)) {
        newErrors[index] = { ...newErrors[index], relationship: "Relationship must contain only letters" };
      }

      if (!contact.phoneNumber?.trim() || !/^\d{10}$/.test(contact.phoneNumber)) {
        newErrors[index] = { ...newErrors[index], phoneNumber: "Phone number must be 10 digits" };
      }
    });

    setContactErrors(newErrors);


    // Prevent submission if errors exist
    if (Object.keys(newErrors).length > 0) return;
    const noErrors = Object.keys(newErrors).length === 0;
    setIsContactsValid(Object.keys(noErrors).length === 0);
    // Proceed with sanitized data
    if (noErrors && !isContactsValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }
    try {
      const sanitizedEmergencyContacts = emergencyContacts?.map(({ __typename, ...contact }) => contact);
      if (newErrors && isContactsValid) {
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              emergencyContacts: sanitizedEmergencyContacts,
            },
          },
        });

        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "EmergencyContacts",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "EmergencyContacts",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setEmergencyContactes(sanitizedEmergencyContacts));
          dispatch(setLoader(false))
          setIsContactsValid(false);
          message.success({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
      }

    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleEmergencySubmit", error.message);

      message.error({
        content: "Failed to update emergency contacts. Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsContactsValid(false); // Ensure form state resets
    }
  };
  // Add New Emergency Contact Entry
  const addNewContact = () => {
    setEmergencyContacts((prev) => [...prev, { name: "", relationship: "", phoneNumber: "" }]);
    setIsContactsValid(false);
  };
  // Delete Emergency Contact Entry and Re-index Errors
  const handleDeleteContact = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedContacts = emergencyContacts.filter((_, i) => i !== index);
        setEmergencyContacts(updatedContacts);

        const updatedContactErrors = { ...contactErrors };
        delete updatedContactErrors[index];

        // Re-index errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedContactErrors).forEach((key) => {
          const newIndex = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newIndex] = updatedContactErrors[key];
        });

        setContactErrors(reindexedErrors);
        setIsContactsValid(Object.keys(reindexedErrors).length === 0);
      }
    })
  };


  const [Personalerrors, setPersonalErrors] = useState({});
  const [profileChnage, setProfileChange] = useState(true);
  const adharPattern = /^[0-9]{12}$/; // 12 digits for Adhar
  const pfPattern = /^[A-Z]{2}\/[A-Z]{3}\/\d{7}\/\d{3}\/\d{7}$/;// Alphanumeric characters for PF Number
  const uanPattern = /^[0-9]{12}$/; // 12 digits for UAN
  const esiPattern = /^[0-9]{10}$/; // 10 digits for ESI
  const healthInsurancePattern = /^[0-9]{10,15}$/; // 10-1
  const PersonalhandleInputChange = (e) => {
    const { name, value } = e.target;
    // Update personal information state
    setPersonalInfo({ ...personalInfo, [name]: value });

    // Create a copy of the errors
    let updatedErrors = { ...Personalerrors };

    // List of required fields
    const requiredFields = ['adharNumber', 'PfNo', 'UAN', 'EsiNo', 'HealthInsuranceNo'];

    // Validation logic
    if (name === 'adharNumber') {
      if (!value.trim()) {
        delete updatedErrors.adharNumber;
      } else if (!adharPattern.test(value)) {
        updatedErrors.adharNumber = "Invalid Adhar Number. It should be 12 digits.";
      } else {
        delete updatedErrors.adharNumber;
      }
    }

    if (name === 'PfNo') {
      if (!value.trim()) {
        delete updatedErrors.PfNo;
      } else if (!pfPattern.test(value)) {
        updatedErrors.PfNo = "Invalid PF Number. It should be alphanumeric.";
      } else {
        delete updatedErrors.PfNo;
      }
    }

    if (name === 'UAN') {
      if (!value.trim()) {
        delete updatedErrors.UAN;
      } else if (!uanPattern.test(value)) {
        updatedErrors.UAN = "Invalid UAN. It should be 12 digits.";
      } else {
        delete updatedErrors.UAN;
      }
    }

    if (name === 'EsiNo') {
      if (!value.trim()) {
        delete updatedErrors.EsiNo;
      } else if (!esiPattern.test(value)) {
        updatedErrors.EsiNo = "Invalid ESI Number. It should be 10 digits.";
      } else {
        delete updatedErrors.EsiNo;
      }
    }

    if (name === 'HealthInsuranceNo') {
      if (!value.trim()) {
        delete updatedErrors.HealthInsuranceNo;
      } else if (!healthInsurancePattern.test(value)) {
        updatedErrors.HealthInsuranceNo = "Invalid Health Insurance Number. It should be 10-15 digits.";
      } else {
        delete updatedErrors.HealthInsuranceNo;
      }
    }

    // Update errors in state
    setPersonalErrors(updatedErrors);
    setProfileChange(false);
    // Validate all required fields
    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = personalInfo[field] || value; // Check updated value for the current field
      const fieldError = updatedErrors[field]; // Check if there is an error
      return fieldValue && !fieldError; // Valid if it has value and no error
    });

    // Update form validity
    setpersonalValid(allFieldsValid);

    // Indicate profile changes

  };
  const PersonalhandleSubmit = async (e) => {
    e.preventDefault();
    // Flag to track if any field is valid
    let isAnyFieldValid = false;
    let newErrors = { ...Personalerrors };
    // Temporary object to store validation errors
    if (personalInfo.adharNumber && adharPattern.test(personalInfo.adharNumber)) {
      isAnyFieldValid = true;
    }
    if (personalInfo.PfNo && pfPattern.test(personalInfo.PfNo)) {
      isAnyFieldValid = true;
    }
    if (personalInfo.UAN && uanPattern.test(personalInfo.UAN)) {
      isAnyFieldValid = true;
    }
    if (personalInfo.EsiNo && esiPattern.test(personalInfo.EsiNo)) {
      isAnyFieldValid = true;
    }
    if (personalInfo.HealthInsuranceNo && healthInsurancePattern.test(personalInfo.HealthInsuranceNo)) {
      isAnyFieldValid = true;
    }

    // If no valid fields are found, set an error

    // Remove any main error if submission criteria is met
    // If there are no errors, proceed with the submission
    const noErrors = Object.keys(newErrors).length === 0;
    // setpersonalValid(noErrors);
    if (noErrors && !personalValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }
    if (noErrors && personalValid) {
      try {
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: personalInfo, // Pass the valid data
          },
        });
        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "ID Proof's",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "ID Proof's",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          setProfileChange(true);
          setpersonalValid(false);
          dispatch(setPersonalInfos(personalInfo));
          dispatch(setLoader(false)) // Update Redux state
          message.success({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
      } catch (error) {
        // Log the error and show an error message
        await logError("PersonalInformationModelPopup", "PersonalhandleSubmit", error.message);

        message.error({
          content: "Failed to update PersonalhandleSubmit . Please try again.",
          duration: 5,
          className: "custom-toast-center",
        });
      } finally {
        // Final steps regardless of success or failure
        setpersonalValid(false); // Ensure form state resets
      }
    }
  };

  const [educationErrors, setEducationErrors] = useState({});

  const EducationhandleInputChange = (index, e, fieldName = null) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else if (fieldName && e instanceof Date) {
      name = fieldName;
      value = e.toISOString().split("T")[0]; // Store in YYYY-MM-DD format
    } else {

      return;
    }
    const updatedEducationData = [...educationData];
    if (!updatedEducationData[index]) {
      updatedEducationData[index] = {}; // Initialize if the index doesn't exist
    }

    updatedEducationData[index] = { ...updatedEducationData[index], [name]: value };

    // Set updated state for familyMembers
    // setFamilyMembers(updatedFamilyData);
    // const updatedEducationData = [...educationData];
    // if (!updatedEducationData[index]) updatedEducationData[index] = {}; // Ensure object exists

    // updatedEducationData[index] = {
    //     ...updatedEducationData[index],
    //     [name]: value 
    // };

    setEducationData(updatedEducationData);

    // Initialize error object
    const updatedErrors = { ...educationErrors };
    if (!updatedErrors[index]) updatedErrors[index] = {};

    // Required field validation
    if (!["from", "to"].includes(name) && value.trim() === "") {
      updatedErrors[index][name] = `${name.replace(/([A-Z])/g, " $1")} is required.`;
    } else {
      delete updatedErrors[index][name];
    }

    // Regex validation for text fields
    const textRegex = /^[A-Za-z\s-]+$/;
    if (["educationLevel", "college", "university", "degree", "fieldOfStudy", "typeOfStudy"].includes(name)) {
      if (!textRegex.test(value)) {
        updatedErrors[index][name] = `Please enter a valid ${name.replace(/([A-Z])/g, " $1")}. Numbers are not allowed.`;
      } else {
        delete updatedErrors[index][name];
      }
    }

    // Date validation
    const fromDate = new Date(updatedEducationData[index]?.from || "");
    const toDate = new Date(updatedEducationData[index]?.to || "");
    const currentYear = new Date().getFullYear();
    const nextYear = currentYear + 1;

    if (name === "from" && fromDate instanceof Date && !isNaN(fromDate)) {
      if (fromDate.getFullYear() >= currentYear) {
        updatedErrors[index].from = "From Date cannot be this year or later.";
      } else if (fromDate.getFullYear() < currentYear - 50) {
        updatedErrors[index].from = "From Date is too far in the past.";
      } else {
        delete updatedErrors[index].from;
      }
    }

    if (name === "to" && toDate instanceof Date && !isNaN(toDate)) {
      if (toDate.getFullYear() > nextYear) {
        updatedErrors[index].to = `To Date cannot be later than next year (${nextYear}).`;
      } else if (fromDate && fromDate > toDate) {
        updatedErrors[index].to = "To Date cannot be earlier than From Date.";
      } else {
        delete updatedErrors[index].to;
      }
    }

    // Cleanup empty error objects
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setEducationErrors(updatedErrors);

    // Check overall validation
    const allFieldsValid = updatedEducationData.every((data, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        data.educationLevel?.trim() &&
        data.college?.trim() &&
        data.university?.trim() &&
        data.degree?.trim() &&
        data.fieldOfStudy?.trim() &&
        data.typeOfStudy?.trim() &&
        Object.keys(recordErrors).length === 0
      );
    });

    setIsEducationFormValid(allFieldsValid);
  };




  const addEducation = () => {
    const newEducation = {
      degree: "",
      university: "",
      from: "",
      to: "",
    };

    // Add new row without setting validation errors initially
    setEducationData((prev) => [...prev, newEducation]);
    setIsEducationFormValid(false);
  };

  const deleteEducation = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this entry?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedEducation = educationData.filter((_, i) => i !== index);
        setEducationData(updatedEducation);

        // Remove the corresponding error entry
        const updatedErrors = { ...educationErrors };
        delete updatedErrors[index];

        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });

        setEducationErrors(reindexedErrors);

        // Check if form is valid
        const noErrors = Object.keys(reindexedErrors).length === 0;
        setIsEducationFormValid(noErrors);
      }
    });
  };

  const EducationhandleSubmit = async (e) => {
    e.preventDefault();

    const alphaRegex = /^[A-Za-z\s]+$/;
    const newErrors = { ...educationErrors };

    educationData.forEach((education, index) => {
      if (!newErrors[index]) newErrors[index] = {};

      // Validate text fields
      ["educationLevel", "college", "university", "degree", "fieldOfStudy", "typeOfStudy"].forEach((field) => {
        if (!education[field]?.trim()) {
          newErrors[index][field] = `${field.replace(/([A-Z])/g, " $1")} is required.`;
        } else if (!alphaRegex.test(education[field])) {
          newErrors[index][field] = `${field.replace(/([A-Z])/g, " $1")} must contain only letters.`;
        } else {
          delete newErrors[index][field];
        }
      });

      // Date validation
      if (!education.from) newErrors[index].from = "From Date is required.";
      if (!education.to) newErrors[index].to = "To Date is required.";
    });

    setEducationErrors(newErrors);

    const noErrors = Object.keys(newErrors).every((index) => Object.keys(newErrors[index]).length === 0);
    if (!noErrors) return;

    if (!isEducationFormValid) {
      message.error({
        content: "No Change Detected to Update",
        duration: 5,
        className: 'custom-toast-center',
      });
      setSubmitBtnBlock(true);
      return;
    }

    try {
      dispatch(setLoader(true));
      const formattedEducationData = educationData.map(({ __typename, ...education }) => education);

      const { data } = await updateEmployee({
        variables: {
          id: ids,
          input: { education: formattedEducationData },
        },
      });

      if (data?.UpdateEmploye?.status) {
        await EditemployeeDetails({
          variables: {
            input: {
              id,
              empid: Empid,
              userName: `${employee?.firstname} ${employee?.lastname}`,
              action: "Education",
              EditStatus: "Updated",
              changeTime: new Date().toISOString(),
            },
          },
        });

        dispatch(setEditHistory({
          id,
          empid: Empid,
          userName: `${employee?.firstname} ${employee?.lastname}`,
          action: "Education",
          EditStatus: "Updated",
          changeTime: new Date().toISOString(),
        }));

        const formattedEducationData = educationData.map((item) => ({
          ...item,
          from: item.from instanceof Date ? item.from.toISOString() : item.from, // Convert Date to string
          to: item.to instanceof Date ? item.to.toISOString() : item.to, // Convert Date to string
        }));

        dispatch(setEducationDatas(formattedEducationData));

        setIsEducationFormValid(false);
        dispatch(setLoader(false));
        message.success({
          content: "Updated Successfully",
          duration: 5,
          className: 'custom-toast-center',
        });
      }
    } catch (error) {
      await logError("PersonalInformationModelPopup", "EducationhandleSubmit", error.message);

      message.error({
        content: "Failed to update education. Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      setIsEducationFormValid(false);
    }
  };

  const [familyErrors, setFamilyErrors] = useState({});

  const handleFamilyChange = (index, e) => {
    let name, value;
    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else if (e && e instanceof Date) {
      name = e.name;
      value = e;  // Date object for DOB
    } else {

      return;
    }

    // Create a copy of familyMembers to update state correctly
    const updatedFamilyData = [...familyMembers];
    if (!updatedFamilyData[index]) {
      updatedFamilyData[index] = {}; // Initialize if the index doesn't exist
    }

    updatedFamilyData[index] = { ...updatedFamilyData[index], [name]: value };

    // Set updated state for familyMembers
    setFamilyMembers(updatedFamilyData);

    // Create a copy of the errors object
    const updatedErrors = { ...familyErrors };
    if (!updatedErrors[index]) updatedErrors[index] = {};

    // Handle validation for name and relationship
    if (name !== "dob" && value.trim() === "") {
      updatedErrors[index][name] = `${name.replace(/([A-Z])/g, " $1")} is required.`;
    } else {
      if (name === "name" || name === "relationship") {
        const regex = /^[A-Za-z\s-]+$/; // Only letters, spaces, and hyphens allowed
        if (!regex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid ${name}. Numbers are not allowed.`;
        } else {
          delete updatedErrors[index][name]; // Clear error if valid
        }
      }

      if (name === "age") {
        const ageRegex = /^[0-9]+$/; // Numeric values only
        const age = parseInt(value, 10); // Convert string to number
        if (!ageRegex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid age (numeric only).`;
        } else if (age < 10 || age > 100) {
          updatedErrors[index][name] = `Age must be between 10 and 100.`;
        } else {
          delete updatedErrors[index][name]; // Clear error if valid
        }
      }

      if (name === "dob") {
        const dobValue = value;
        if (dobValue) {
          // Validate date of birth
          const currentDate = new Date();
          const enteredDate = new Date(dobValue);
          const age = currentDate.getFullYear() - enteredDate.getFullYear();
          const isMonthValid =
            currentDate.getMonth() > enteredDate.getMonth() ||
            (currentDate.getMonth() === enteredDate.getMonth() && currentDate.getDate() >= enteredDate.getDate());

          // Adjust the age if the current month/day is before the birth month/day
          const actualAge = isMonthValid ? age : age - 1;
          updatedFamilyData[index] = { ...updatedFamilyData[index], age: actualAge.toString() };
          delete updatedErrors[index]["age"];
          if (actualAge < 0 || actualAge > 100) {
            updatedErrors[index][name] = "Age must be between 0 and 99 years.";
          } else {
            // Automatically update the age field if DOB is valid

            delete updatedErrors[index][name]; // Clear error if DOB is valid
          }
        } else {
          updatedErrors[index][name] = "DOB is required."; // If DOB is empty
        }
      }

      // If the 'age' is filled and does not match the calculated age, set an error
      if (name === "age" && value) {
        const enteredAge = parseInt(value, 10); // Convert string to number
        const dobValue = updatedFamilyData[index].dob;

        if (dobValue) {
          const currentDate = new Date();
          const enteredDate = new Date(dobValue);
          let calculatedAge = currentDate.getFullYear() - enteredDate.getFullYear();
          const isMonthValid =
            currentDate.getMonth() > enteredDate.getMonth() ||
            (currentDate.getMonth() === enteredDate.getMonth() && currentDate.getDate() >= enteredDate.getDate());

          const actualAge = isMonthValid ? calculatedAge : calculatedAge - 1;

          // Check if the age matches the calculated age based on DOB
          if (enteredAge !== actualAge) {
            updatedErrors[index]["age"] = "Age does not match with the provided date of birth.";
          } else {
            delete updatedErrors[index]["age"]; // Clear error if the age is correct
          }
        }
      }

      if (name === "dependent") {
        // Validate dependent (ensure it's not empty and is a valid boolean value)
        if (!["Yes", "No"].includes(value)) {
          updatedErrors[index][name] = "Dependent must be Yes or No.";
        } else {
          delete updatedErrors[index][name]; // Clear error if valid
        }
      }
    }

    // Clean up errors if no errors exist for that index
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setFamilyErrors(updatedErrors);

    // Check if all fields are valid
    const allFieldsValid = updatedFamilyData.every((family, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        family.name?.trim() &&
        family.relationship?.trim() &&
        family.dob &&
        family.age?.toString().trim() &&
        family.dependent &&
        Object.keys(recordErrors).length === 0
      );
    });

    setIsFamilyFormValid(allFieldsValid);
  };


  // Add New Family Member Entry
  const addFamilyMember = () => {
    const newFamily = {
      name: "",
      relationship: "",
      dob: "",
      age: "",
      dependent: "",
    };

    // Add new row without setting validation errors initially
    setFamilyMembers((prev) => [...prev, newFamily]);
    setIsFamilyFormValid(false);
  };
  // Delete Family Member Entry
  const removeFamilyMember = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedFamily = familyMembers.filter((_, i) => i !== index);
        setFamilyMembers(updatedFamily);

        // Remove the corresponding error entry
        const updatedErrors = { ...familyErrors };
        delete updatedErrors[index];

        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });

        setFamilyErrors(reindexedErrors);

        // Check if form is valid

        setIsFamilyFormValid(reindexedErrors);
      }
    })
  };
  // Family Form Submit Handler
  const FamilyhandleSubmit = async (e) => {
    e.preventDefault();


    const alphaRegex = /^[A-Za-z\s]+$/; // Regex for only alphabets and spaces
    const ageRegex = /^[1-9]\d*$/; // Regex for positive numeric values
    const currentDate = new Date();

    let newErrors = { ...familyErrors }

    familyMembers.forEach((family, index) => {
      // Validate 'name'
      if (!family.name?.trim()) {
        newErrors[index] = { ...newErrors[index], name: "Name is required" };
      } else if (!alphaRegex.test(family.name)) {
        newErrors[index] = { ...newErrors[index], name: "Name must contain only letters" };
      }

      // Validate 'relationship'
      if (!family.relationship?.trim()) {
        newErrors[index] = { ...newErrors[index], relationship: "Relationship is required" };
      } else if (!alphaRegex.test(family.relationship)) {
        newErrors[index] = { ...newErrors[index], relationship: "Relationship must contain only letters" };
      }
      // Validate 'dob'
      if (!family.dob) {
        newErrors[index] = { ...newErrors[index], dob: "Date of Birth is required" };
      } else {
        const dobDate = new Date(family.dob);
        if (dobDate > currentDate) {
          newErrors[index] = { ...newErrors[index], dob: "Date of Birth cannot be in the future" };
        }
      }
      // Validate 'age'
      if (!family.age) {
        newErrors[index] = { ...newErrors[index], age: "Age is required" };
      } else if (family.age > 99 || family.age < 0) {
        newErrors[index] = { ...newErrors[index], age: "Age should be in 0 to 99" };
      }
      // Validate 'dependent'
      if (!family.dependent?.trim()) {
        newErrors[index] = { ...newErrors[index], dependent: "Dependent status is required" };
      } else if (!["Yes", "No"].includes(family.dependent)) {
        newErrors[index] = { ...newErrors[index], dependent: "Dependent must be Yes or No" };
      }
    });

    setFamilyErrors(newErrors)
    const noErrors = Object.keys(newErrors).length === 0;
    if (noErrors && !isFamilyFormValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }
    // Proceed if no validation errors
    if (noErrors && isFamilyFormValid) {
      try {
        const formattedFamilyData = familyMembers?.map(({ __typename, ...family }) => family);
        dispatch(setLoader(true))
        // Submit mutation
        const { data } = await updateEmployee({
          variables: {
            id: ids,
            input: { familyMembers: formattedFamilyData },
          },
        });

        if (data?.UpdateEmploye?.status) {
          await EditemployeeDetails({
            variables: {
              input: {
                id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "familyMembers",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(),
              },
            },
          });

          dispatch(
            setEditHistory({
              id,
              empid: Empid,
              userName: `${employee?.firstname}  ${employee?.lastname}`,
              action: "familyMembers",
              EditStatus: "Updated",
              changeTime: new Date().toISOString(),
            })
          );

          dispatch(setFamilyMemberses(formattedFamilyData));
          dispatch(setLoader(false))
          message.success({
            content: "Updated Successfully",
            duration: 5,
            className: "custom-toast-center",
          });
          setIsFamilyFormValid(false);
        }
      } catch (error) {
        await logError("PersonalInformationModelPopup", "FamilyhandleSubmit", error.message);
        message.error({
          content: "Failed to update family members. Please try again.",
          duration: 5,
          className: "custom-toast-center",
        });
      } finally {
        setIsFamilyFormValid(false);
      }
    }
  };
  const [Bankerrors, setBankErrors] = useState({});

  // Validation Patterns
  const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/; // IFSC code format
  const panPattern = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/; // PAN format
  const accountNumberPattern = /^[0-9]{9,18}$/; // Account number (9-18 digits)
  const bankNamePattern = /^[a-zA-Z\s]+$/; // Letters and spaces only for Bank Name
  const branchPattern = /^[a-zA-Z\s]+$/; // Letters and spaces only for Branch Name

  // Input Change Handler for Bank Info
  const handleBankInputChange = (e) => {
    const { name, value } = e.target;

    // Update the bankInfo state with the new value
    const updatedBankInfo = { ...bankInfo, [name]: value };
    setBankInfo(updatedBankInfo);

    // Inline validation for the specific field
    let newErrors = { ...Bankerrors };

    switch (name) {
      case 'BankAccountNo':
        newErrors.BankAccountNo = value
          ? (!accountNumberPattern.test(value)
            ? "Invalid Account Number. It should be 9 to 18 digits."
            : null)
          : null;
        break;

      case 'BankName':
        newErrors.BankName = value
          ? (!bankNamePattern.test(value)
            ? "Invalid Bank Name. Only letters and spaces allowed."
            : null)
          : null;
        break;

      case 'IFCcode':
        newErrors.IFCcode = value
          ? (!ifscPattern.test(value)
            ? "Invalid IFSC Code. Format should be like 'ABCD0123456'."
            : null)
          : null;
        break;

      case 'Branch':
        newErrors.Branch = value
          ? (!branchPattern.test(value)
            ? "Invalid Branch Name. Only letters and spaces allowed."
            : null)
          : null;
        break;

      case 'PanNumber':
        newErrors.PanNumber = value
          ? (!panPattern.test(value)
            ? "Invalid PAN Number. Format should be like 'ABCDE1234F'."
            : null)
          : null;
        break;
      default:
        break;
    }

    setBankErrors(newErrors);

    // Validate all required fields
    const requiredFields = ['BankAccountNo', 'BankName', 'IFCcode', 'Branch', 'PanNumber'];
    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = updatedBankInfo[field]; // Use the updated value for the current field
      const fieldError = newErrors[field]; // Check if there is an error for the field
      return fieldValue && !fieldError; // Valid if field has value and no error
    });

    // Update form validity
    setIsBankInfoFormValid(allFieldsValid);
  };
  // Bank Info Form Submit Handler
  const handleBankInfoSubmit = async (e) => {
    e.preventDefault();

    let newErrors = { ...Bankerrors };


    // Check if each required field has a value
    if (!bankInfo.BankAccountNo) newErrors.BankAccountNo = "Account Number is required.";
    if (!bankInfo.BankName) newErrors.BankName = "Bank Name is required.";
    if (!bankInfo.IFCcode) newErrors.IFCcode = "IFSC Code is required.";
    if (!bankInfo.Branch) newErrors.Branch = "Branch Name is required.";
    if (!bankInfo.PanNumber) newErrors.PanNumber = "PAN Number is required.";

    setBankErrors(newErrors);


    const noErrors = Object.keys(newErrors).length === 0;
    if (newErrors && !isBankInfoFormValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }

    try {
      if (newErrors && isBankInfoFormValid) {
        // Call update function
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              BankAccountNo: bankInfo.BankAccountNo,
              BankName: bankInfo.BankName,
              IFCcode: bankInfo.IFCcode,
              Branch: bankInfo.Branch,
              PanNumber: bankInfo.PanNumber,
            },
          },
        });

        // Dispatch to store
        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "Bank details",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "Bank details",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setBankInfos(bankInfo));
          dispatch(setLoader(false))
          setIsBankInfoFormValid(false);
          message.success({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
      }
    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleBankInfoSubmit", error.message);

      message.error({
        content: "Failed to update handleBankInfoSubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsBankInfoFormValid(false); // Ensure form state resets
    }
  };


  const [basicinfo, setBasicInfo] = useState({});
  const [basicValid, setBasicValid] = useState(false);
  // Track if form has been modified
  const handleBasicChange = (e) => {
    const { name, value } = e.target;
    // Update workExperience state
    if (name === "years" || name === "months") {
      setWorkExperience((prev) => ({
        ...prev,
        Experience: { ...prev.Experience, [name]: value },
      }));
    } else {
      setWorkExperience((prev) => ({ ...prev, [name]: value }));
    }

    // Inline validation for each field with if-else for error handling
    let newErrors = { ...basicinfo };

    switch (name) {
      case "EmploymentType":
        if (!value.trim()) {
          newErrors.EmploymentType = "Employment Type is required.";
        } else {
          delete newErrors.EmploymentType; // Remove error if the field is valid
        }
        break;
      case "WorkType":
        if (!value.trim()) {
          newErrors.WorkType = "Work Type is required.";
        } else {
          delete newErrors.WorkType;
        }
        break;
      case "ShiftTimings":
        if (!value.trim()) {
          newErrors.ShiftTimings = "Shift Timings are required.";
        } else {
          delete newErrors.ShiftTimings;
        }
        break;
      case "years":
        if (!value.trim()) {
          newErrors.years = "Years of experience is required.";
        } else if (isNaN(value) || value < 0 || value > 60) {
          newErrors.years =
            "Years of experience must be a non-negative number and less than or equal to 60.";
        } else {
          delete newErrors.years;
        }
        break;
      case "months":
        if (!value.trim()) {
          newErrors.months = "Months of experience is required.";
        } else if (isNaN(value) || value < 0 || value > 11) {
          newErrors.months =
            "Months of experience must be a non-negative number and less than or equal to 11.";
        } else {
          delete newErrors.months;
        }
        break;
      case "ProbationStatus":
        if (!/^[A-Za-z\s-]+$/.test(value)) {
          newErrors.ProbationStatus = "Probation Status is required, only letters and spaces allowed.";
        } else {
          delete newErrors.ProbationStatus;
        }
        break;
      case "probationPeriod":
        if (!value.trim()) {
          newErrors.probationPeriod = "Probation Period is required.";
        } else if (isNaN(value) || value < 0) {
          newErrors.probationPeriod = "Probation Period must be a non-negative number.";
        } else if (value < 30 || value > 180) {
          newErrors.probationPeriod =
            "Probation Period must be greater than 30 days and less than 180 days.";
        } else {
          delete newErrors.probationPeriod;
        }
        break;
      default:
        break;
    }

    // Update errors in state
    setBasicInfo(newErrors);

    // Validate all required fields to determine form validity
    const requiredFields = [
      "EmploymentType",
      "WorkType",
      "ShiftTimings",
      "years",
      "months",
      "ProbationStatus",
      "probationPeriod",
    ];
    setWorkExperience((prev) => {
      const allFieldsValid = requiredFields.every((field) => {
        const fieldValue =
          field === "years" || field === "months"
            ? prev.Experience[field]
            : prev[field];
        const fieldError = newErrors[field];
        return fieldValue && !fieldError;
      });
      setBasicValid(allFieldsValid);
      return prev;
    });
  };
  const handleBasicSave = async (e) => {
    e.preventDefault();
    // Clear old errors
    let newErrors = { ...basicinfo };

    // Validate fields
    if (!workExperience.EmploymentType) newErrors.EmploymentType = "Employment Type is required";
    if (!workExperience.WorkType) newErrors.WorkType = "Work Type is required";
    if (!workExperience.ShiftTimings) newErrors.ShiftTimings = "Shift Timings are required";
    if (!workExperience.Experience?.years) newErrors.years = "Years of experience is required";
    if (!workExperience.Experience?.months) newErrors.months = "Months of experience is required";
    if (!workExperience.ProbationStatus?.trim()) {
      newErrors.ProbationStatus = "Probation Status is required";
    }
    if (!workExperience.probationPeriod?.trim()) {
      newErrors.probationPeriod = "Probation Period is required";
    }

    // Update state with new errors

    setBasicInfo(newErrors);
    // Stop submission if there are errors
    const noErrors = Object.keys(newErrors).length === 0;

    if (noErrors && !basicValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)
    }
    try {
      // Proceed with the update if validation is successful
      if (newErrors && basicValid) {
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              ShiftTimings: workExperience.ShiftTimings,
              EmploymentType: workExperience.EmploymentType,
              Experience: {
                years: parseInt(workExperience.Experience?.years),
                months: parseInt(workExperience.Experience?.months),
              },
              WorkType: workExperience.WorkType,
              ProbationStatus: workExperience.ProbationStatus,
              probationPeriod: workExperience.probationPeriod,
            },
          },
        });

        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "Basic Info",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(),
              },
            },
          });
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "Basic Info",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }));
          dispatch(
            setWorkExperiences({
              ShiftTimings: workExperience.ShiftTimings,
              EmploymentType: workExperience.EmploymentType,
              Experience: {
                years: parseInt(workExperience.Experience?.years),
                months: parseInt(workExperience.Experience?.months),
              },
              WorkType: workExperience.WorkType,
              ProbationStatus: workExperience.ProbationStatus,
              probationPeriod: workExperience.probationPeriod,
            })
          );
          dispatch(setLoader(false))
          message.success({
            content: "Updated Successfully",
            duration: 5,
            className: "custom-toast-center",
          });

          // Clear errors only after successful submission
          setBasicValid(false)
        }
      }
    } catch (error) {

      message.error({
        content: "Failed to update. Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Ensure errors are not cleared prematurely
      if (noErrors && basicValid) {
        setBasicInfo(false); // Reset form state after successful submission
      }
    }
  };
  const [managerErrors, setManagerErrors] = useState({});
  // Input Change Handler for Manager Data
  const handleManagerInputChange = (index, e) => {
    const { name, value } = e.target;
    // Update the manager data
    const updatedManagersData = [...managersData];
    updatedManagersData[index] = { ...updatedManagersData[index], [name]: value };
    setManagersData(updatedManagersData);

    // Live validation for the changed field and update errors
    const updatedErrors = { ...managerErrors };

    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Check for empty fields first
    if (value.trim() === '') {
      updatedErrors[index][name] = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
    } else {
      // Validation for text fields (should not contain numbers)
      if (
        name === 'reportingManagerName' ||
        name === 'reportingManagerType' ||
        name === 'reportingManagerDepartment' ||
        name === 'reportingManagerDesignation'
      ) {
        const textRegex = /^[A-Za-z\s-]+$/; // Only letters, spaces, and hyphens allowed
        if (!textRegex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid ${name.replace(/([A-Z])/g, ' $1')}. Numbers are not allowed.`;
        } else {
          // Remove error if the field is valid
          delete updatedErrors[index][name];
        }
      }
    }

    // Clean up empty error objects
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setManagerErrors(updatedErrors);

    // Check if all required fields are valid
    const allFieldsValid = updatedManagersData?.every((manager, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        manager.reportingManagerName?.trim() &&
        manager.reportingManagerType?.trim() &&
        manager.reportingManagerDepartment?.trim() &&
        manager.reportingManagerDesignation?.trim() &&
        Object.keys(recordErrors).length === 0
      );
    });

    // Update form validity
    setIsManagerFormValid(allFieldsValid);
  };
  // Add New Manager Entry
  const addManager = () => {
    const newManager = {
      reportingManagerName: "",
      reportingManagerType: "",
      reportingManagerDepartment: "",
      reportingManagerDesignation: "",
    };

    // Ensure prev is an array before spreading
    setManagersData((prev) => Array.isArray(prev) ? [...prev, newManager] : [newManager]);
    setIsManagerFormValid(false);
  };
  // Delete Manager Entry
  const deleteManager = (index) => {
    // Remove the record from managersData
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedManagersData = managersData.filter((_, i) => i !== index);
        setManagersData(updatedManagersData);

        // Remove the corresponding error entry
        const updatedErrors = { ...managerErrors };
        delete updatedErrors[index];

        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });
        setManagerErrors(reindexedErrors);
        setIsManagerFormValid(reindexedErrors);
      }
    });
  };
  // Manager Form Submit Handler
  const handleManagerSubmit = async (e) => {
    e.preventDefault();
    const newErrors = {};
    // Validate each manager entry before submission
    managersData.forEach((manager, index) => {
      // Check if each field is empty and set errors accordingly
      ["reportingManagerName", "reportingManagerType", "reportingManagerDepartment", "reportingManagerDesignation"].forEach((field) => {
        if (!manager[field]?.trim()) {
          newErrors[index] = {
            ...newErrors[index],
            [field]: `${field.replace(/([A-Z])/g, ' $1')} is required`,
          };
        } else if (!alphaRegex.test(manager[field])) {
          newErrors[index] = {
            ...newErrors[index],
            [field]: `${field.replace(/([A-Z])/g, ' $1')} must contain only letters`,
          };
        }
      });
    });

    const noErrors = Object.keys(newErrors).length === 0;
    setManagerErrors(newErrors);
    try {
      // Prepare the data by removing unnecessary properties (if any)
      if (noErrors && !isManagerFormValid) {
        message.error({
          content: "No Change Detected to Update",  // Error message
          duration: 2,  // Toast will disappear after 5 seconds
          className: 'custom-toast-center',  // Danger toast with custom styling
        });
        setSubmitBtnBlock(true)

      }
      const formattedManagersData = managersData?.map(({ __typename, ...manager }) => manager);
      if (newErrors && isManagerFormValid) {
        dispatch(setLoader(true))
        // Submit the data
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              teamsReportIngManager: formattedManagersData,
            },
          },
        });
        // Check if backend response is successful
        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "Reporting Manager",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "Reporting Manager",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setManagersDatas(formattedManagersData));
          setIsManagerFormValid(false)
          message.success({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
      }
      dispatch(setLoader(false))
    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleManagerSubmit", error.message);

      message.error({
        content: "Failed to update handleManagerSubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsManagerFormValid(false); // Ensure form state resets
    }
  };

  const [reportErrors, setDirectReportErrors] = useState({});
  // Input Change Handler for Direct Report Data
  const handleReportInputChange = (index, e) => {
    const { name, value } = e.target;

    // Update the direct report data dynamically
    const updatedDirectReportsData = [...reportsData];
    updatedDirectReportsData[index] = { ...updatedDirectReportsData[index], [name]: value };
    setReportsData(updatedDirectReportsData);

    // Live validation for the changed field and update errors
    const updatedErrors = { ...reportErrors };

    // Initialize error object for the current index if it doesn't exist
    if (!updatedErrors[index]) {
      updatedErrors[index] = {};
    }

    // Check for empty fields first
    if (value.trim() === '') {
      updatedErrors[index][name] = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
    } else {
      // Validation for text fields (should not contain numbers)
      if (
        name === 'DirectReportName' ||
        name === 'DirectReportType' ||
        name === 'DirectReportDesignation' ||
        name === 'DirectReportDepartment'
      ) {
        const textRegex = /^[A-Za-z\s-]+$/; // Only letters, spaces, and hyphens allowed
        if (!textRegex.test(value)) {
          updatedErrors[index][name] = `Please enter a valid ${name.replace(/([A-Z])/g, ' $1')}. Numbers are not allowed.`;
        } else {
          // Remove error if the field is valid
          delete updatedErrors[index][name];
        }
      }
    }

    // Clean up empty error objects
    if (Object.keys(updatedErrors[index]).length === 0) {
      delete updatedErrors[index];
    }

    setDirectReportErrors(updatedErrors);

    // Check if all required fields are valid
    const allFieldsValid = updatedDirectReportsData.every((contact, i) => {
      const recordErrors = updatedErrors[i] || {};
      return (
        contact.DirectReportName?.trim() &&
        contact.DirectReportType?.trim() &&
        contact.DirectReportDesignation?.trim() &&
        contact.DirectReportDepartment?.trim() &&
        Object.keys(recordErrors).length === 0
      );
    });

    setIsDirectReportFormValid(allFieldsValid);
  };
  // Add New Direct Report Entry
  const addReport = () => {
    const newDirectReport = {
      DirectReportName: "",
      DirectReportType: "",
      DirectReportDesignation: "",
      DirectReportDepartment: "",
    };

    setReportsData((prev) => [...prev, newDirectReport]);
    setIsDirectReportFormValid(false);
  };
  // Delete Direct Report Entry
  const removeReport = (index) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        const updatedReports = reportsData.filter((_, i) => i !== index);
        setReportsData(updatedReports);

        const updatedErrors = { ...reportErrors };
        delete updatedErrors[index];

        // Reindex errors after deletion
        const reindexedErrors = Object.keys(updatedErrors).reduce((acc, key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          acc[newKey] = updatedErrors[key];
          return acc;
        }, {});

        setDirectReportErrors(reindexedErrors);
        setIsDirectReportFormValid(reindexedErrors);
      }
    });
  };
  // Direct Report Form Submit Handler
  const handleReportSubmit = async (e) => {
    e.preventDefault();

    const newErrors = { ...reportErrors };

    // Validate each direct report entry before submission
    const alphaRegex = /^[A-Za-z\s]+$/; // Regex for only alphabets and spaces

    reportsData.forEach((report, index) => {
      ["DirectReportName", "DirectReportType", "DirectReportDesignation", "DirectReportDepartment"].forEach((field) => {
        // Check if field is empty or contains only spaces
        if (!report[field]?.trim()) {
          newErrors[index] = {
            ...newErrors[index],
            [field]: `${field.replace(/([A-Z])/g, ' $1')} is required`
          };
        }
        // Check if field matches the regex pattern (only alphabets and spaces)
        else if (!alphaRegex.test(report[field])) {
          newErrors[index] = {
            ...newErrors[index],
            [field]: `${field.replace(/([A-Z])/g, ' $1')} must contain only letters`
          };
        }
      });
    });


    setDirectReportErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;

    if (noErrors && !isDirectReportFormValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)

    }
    // Prepare data for submission
    try {
      const sanitizedDirectReports = reportsData?.map(({ __typename, ...report }) => report);

      if (noErrors && isDirectReportFormValid) {
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: { teamsReportDirect: sanitizedDirectReports },
          },
        });
        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "Direct Reports",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "Direct Reports",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setReportsDatas(sanitizedDirectReports));
          dispatch(setLoader(false))
          setIsDirectReportFormValid(false);
          message.success({
            content: "Updated Successfully",
            duration: 5,
            className: 'custom-toast-center',
          });
        }
      }

    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleReportSubmit", error.message);

      message.error({
        content: "Failed to update handleReportSubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsDirectReportFormValid(false); // Ensure form state resets
    }
  };


  const handleEmploymentInputChange = (e) => {
    let name, value;

    if (e && e.target) {
      name = e.target.name;
      value = e.target.value;
    } else if (e && e instanceof Date) {
      name = e.name;
      value = e;  // Date object for from/to fields
    } else {

      return;
    }



    setEmploymentData((prevData) => {
      const updatedData = { ...prevData, [name]: value };
      const errors = { ...validationErrors };
      const alphaSpaceHyphenRegex = /^[A-Za-z\s-]+$/;

      switch (name) {
        case "employmentStatus":
          if (!value.trim()) {
            errors.employmentStatus = "Employment status is required.";
          } else if (!alphaSpaceHyphenRegex.test(value)) {
            errors.employmentStatus = "Accept letters, spaces, or hyphens.";
          } else {
            errors.employmentStatus = null; // Clear error
          }
          break;

        case "resignationStatus":
          if (!value.trim()) {
            errors.resignationStatus = "Resignation status is required.";
          } else if (!alphaSpaceHyphenRegex.test(value)) {
            errors.resignationStatus = "Accept letters, spaces, or hyphens.";
          } else {
            errors.resignationStatus = null; // Clear error
          }
          break;

        case "noticePeriod":
          if (!value || isNaN(value) || value < 30 || value > 90) {
            errors.noticePeriod = "Notice period must be between 30 and 90 days.";
          } else {
            errors.noticePeriod = null;

            // If noticePeriod and resignationDate are valid, calculate the last working day
            if (updatedData.resignationDate && value) {
              const resignationDate = new Date(updatedData.resignationDate);
              const noticePeriodInDays = parseInt(value);

              // Calculate last working day by adding notice period to resignation date
              const lastWorkingDate = new Date(resignationDate);
              lastWorkingDate.setDate(resignationDate.getDate() + noticePeriodInDays);

              // Store the last working day (without formatting)
              updatedData.lastWorkingDay = lastWorkingDate.toString(); // Use Date object directly
            }
          }
          break;

        case "resignationDate":
          if (!value) {
            errors.resignationDate = "Resignation date is required.";
          } else {
            const resignationDate = new Date(value);
            if (isNaN(resignationDate.getTime())) {
              errors.resignationDate = "Invalid resignation date.";
            } else {
              const today = new Date();
              const twoMonthsFromToday = new Date(today);
              const sixMonthsFromToday = new Date(today);

              twoMonthsFromToday.setMonth(today.getMonth() - 2);
              sixMonthsFromToday.setMonth(today.getMonth() + 6);

              if (resignationDate < twoMonthsFromToday || resignationDate > sixMonthsFromToday) {
                errors.resignationDate = "Resignation date must be between 2 months ago and 6 months ahead.";
              } else {
                errors.resignationDate = null; // Valid date
                // Recalculate the last working day based on the new resignation date
                if (updatedData.noticePeriod) {
                  const noticePeriodInDays = parseInt(updatedData.noticePeriod);
                  const lastWorkingDate = new Date(resignationDate);
                  lastWorkingDate.setDate(resignationDate.getDate() + noticePeriodInDays);
                  updatedData.lastWorkingDay = lastWorkingDate.toString(); // No formatting
                }
              }
            }
          }
          break;

        case "lastWorkingDay":
          if (!updatedData.noticePeriod) {
            errors.lastWorkingDay = "Set the notice period first.";
          } else {
            const calculatedDate = new Date(new Date().setDate(new Date().getDate() + parseInt(updatedData.noticePeriod)));
            const enteredDate = new Date(value);

            if (enteredDate.getTime() !== calculatedDate.getTime()) {
              errors.lastWorkingDay = `Last working day must be the same as calculated based on the notice period.`;
            } else {
              errors.lastWorkingDay = null;
            }
          }
          break;

        case "RehireEligibility":
          errors.RehireEligibility = value.trim()
            ? null
            : "Please select rehire eligibility.";
          break;

        default:
          break;
      }

      setValidationErrors(errors);

      // Check if all required fields are valid
      const requiredFields = ["employmentStatus", "resignationStatus", "noticePeriod", "resignationDate", "lastWorkingDay", "RehireEligibility"];
      const allValid = requiredFields.every((field) => updatedData[field] && !errors[field]);
      setIsEmploymentFormValid(allValid);

      return updatedData;
    });
  };

  // Employment Form Submit Handler
  const handleEmploymentSubmit = async (e) => {
    e.preventDefault();

    // Initialize error object for final validation checks
    let errors = { ...validationErrors };

    // Perform the validation checks again here
    if (!employmentData.employmentStatus?.trim()) {
      errors.employmentStatus = "Employment status is required.";
    } else if (!alphaRegex.test(employmentData.employmentStatus)) {
      errors.employmentStatus = "Employment status must contain only letters.";
    }

    // Validate resignation status
    if (!employmentData.resignationStatus?.trim()) {
      errors.resignationStatus = "Resignation status is required.";
    } else if (!alphaRegex.test(employmentData.resignationStatus)) {
      errors.resignationStatus = "Resignation status must contain only letters.";
    }
    if (!employmentData.noticePeriod || isNaN(employmentData.noticePeriod) || employmentData.noticePeriod < 0) {
      errors.noticePeriod = "Notice period must be a non-negative number.";
    }
    if (!employmentData.resignationDate) {
      errors.resignationDate = "Resignation date is required.";
    }
    if (!employmentData.lastWorkingDay) {
      errors.lastWorkingDay = "Last working day is required.";
    }
    if (!employmentData.RehireEligibility) {
      errors.RehireEligibility = "Please select rehire eligibility.";
    }

    // Check if there are any errors
    const noErrors = Object.keys(errors).length === 0;
    setValidationErrors(errors);


    if (noErrors && !isEmploymentFormValid) {
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
      setSubmitBtnBlock(true)

    }
    // If no errors, proceed with the submission
    try {
      if (errors && isEmploymentFormValid) {
        dispatch(setLoader(true))
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              employmentStatus: employmentData.employmentStatus,
              resignationStatus: employmentData.resignationStatus,
              noticePeriod: employmentData.noticePeriod,
              resignationDate: employmentData.resignationDate,
              lastWorkingDay: employmentData.lastWorkingDay,
              RehireEligibility: employmentData.RehireEligibility,
            },
          },
        });

        // Dispatch to store

        if (data?.UpdateEmploye?.status) {
          EditemployeeDetails({
            variables: {
              input: {
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "Employment Status",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
              },
            }
          })
          dispatch(setEditHistory({
            id: id,
            empid: Empid,
            userName: `${employee?.firstname}  ${employee?.lastname}`,
            action: "Employment Status",
            EditStatus: "Updated",
            changeTime: new Date().toISOString(),
          }))
          dispatch(setEmploymentDatas({
            employmentStatus: employmentData.employmentStatus,
            resignationStatus: employmentData.resignationStatus,
            noticePeriod: employmentData.noticePeriod,
            resignationDate: employmentData.resignationDate,
            lastWorkingDay: employmentData.lastWorkingDay,
            RehireEligibility: employmentData.RehireEligibility,
          }));
          setIsEmploymentFormValid(false);
          dispatch(setLoader(false))
          // Display success message
          message.success({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
      }
    } catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleEmploymentSubmit", error.message);

      message.error({
        content: "Failed to update handleEmploymentSubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {
      // Final steps regardless of success or failure
      setIsEmploymentFormValid(false); // Ensure form state resets
    }
  };

  const [noteChange, setNoteChange] = useState(false);
  const handleNoteChange = (event) => {
    setNoteChange(true);
    setNote(event.target.value);
  };

  // Handler for form submission
  const handleNoteSubmit = async (event) => {
    event.preventDefault();
    if (!noteChange) {
      setSubmitBtnBlock(true);
      message.error({
        content: "No Change Detected to Update",  // Error message
        duration: 2,  // Toast will disappear after 5 seconds
        className: 'custom-toast-center',  // Danger toast with custom styling
      });
    }
    setNoteChange(false);

    try {
      if (noteChange) {
        dispatch(setLoader(true));
        const { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              [name]: note,
            }
          },
        });
        //dispatch(setNotes(note))

        if (data?.UpdateEmploye?.status) {
          dispatch((updateNoteData({ data: note, name: name })));

          message.info({
            content: "Updated Successfully",
            duration: 5, // Toast will disappear after 5 seconds
            className: 'custom-toast-center', // Custom class to center the toast
          });
        }
        dispatch(setLoader(false));
      }
    }
    catch (error) {
      // Log the error and show an error message
      await logError("PersonalInformationModelPopup", "handleNoteSubmit", error.message);

      message.error({
        content: "Failed to update handleNoteSubmit . Please try again.",
        duration: 5,
        className: "custom-toast-center",
      });
    } finally {

    }
  };

  const handleClose = (name) => {
    switch (name) {
      case 'Bank':
        setBankInfo(bankInfoup);
        setBankErrors({});

        break;

      case 'User':
        setUserData(UserDataup);
        setErrores({});
        break;

      case 'Personal':
        setPersonalInfo(Personalup)
        setPersonalErrors({});
        break;


      case 'WorkHistory':
        setWorkHistory(History)
        setWorkHistoryErrors({});
        setIsFormValid(false);
        break;
      case 'Education':
        setEducationData(Education)
        setEducationErrors({});
        setIsEducationFormValid(false);
        break;
      case 'Report':
        setReportsData(Reportsup)
        setDirectReportErrors({});
        setIsDirectReportFormValid(false);
        break;
      case 'Manager':
        setManagersData(managers);
        setManagerErrors({});
        setIsManagerFormValid(false);
        break;
      case 'Contact':
        setEmergencyContacts(contactup)
        setContactErrors({});
        setIsContactsValid(false);
        break;
      case 'Family':
        setFamilyMembers(Familyup)
        setFamilyErrors({});
        setIsFamilyFormValid(false);
        break;
      case 'Experience':
        setWorkExperience(Expirence)
        setBasicInfo({});
        break;
      case 'EmployeeStatus':

        setEmploymentData(EmployeeStatusup)
        setValidationErrors({});
        // setIsEmploymentFormValid(false);
        break;


      default:
        break;
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;
  return (
    <>
      <div id="profile_info" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Profile Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('User')} data-bs-dismiss="modal" aria-label="Close">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-img-wrap edit-img">
                      <img className="inline-block" src={Avatar_02} alt="user" />
                      <div className="fileupload btn">
                        <span className="btn-text">edit</span>
                        <input className="upload" type="file" />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Employee ID<span className="text-danger">*</span></label>
                          <input
                            type="number"
                            name="EmpID"
                            className={`form-control ${errores?.EmpID ? 'is-invalid' : ''}`}
                            value={(userData?.EmpID)?.trim() || ""}
                            onChange={handleInputChange}
                            max="99999"  // Maximum value for the input
                            min="0"      // Optional: ensures only positive numbers are allowed
                            onInput={(e) => {
                              // This will ensure the value doesn't exceed 5 digits
                              if (e.target.value.length > 5) {
                                e.target.value = e.target.value.slice(0, 5);
                              }
                            }}
                          />
                          {errores?.EmpID && <div className="text-danger">{errores?.EmpID}</div>}
                        </div>
                     

                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">First Name<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            name="firstname"
                            className={`form-control ${errores?.firstname ? 'is-invalid' : ''}`}
                            value={userData?.firstname}
                            onChange={handleInputChange}
                          />
                          {errores?.firstname && <div className="text-danger">{errores?.firstname}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Last Name<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            name="lastname"
                            className={`form-control ${errores?.lastname ? 'is-invalid' : ''}`}
                            value={userData?.lastname}
                            onChange={handleInputChange}
                          />
                          {errores?.lastname && <div className="text-danger">{errores?.lastname}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Birth Date<span className="text-danger">*</span></label>
                          <DatePicker
                            selected={userData.dob ? userData?.dob : null}
                            onChange={(date) =>
                              handleInputChange({
                                target: {
                                  name: "dob",
                                  value: date,
                                },
                              })
                            }
                            className={`form-control ${errores?.dob ? 'is-invalid' : ''}`}
                            dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                            placeholderText="dd/MM/YYYY" // Placeholder text for the input
                            showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                            popperPlacement="bottom"
                            onKeyDown={handleKeyDown}
                            maxLength={10}
                          // Optional, depending on your requirements
                          />
                          {errores?.dob && <div className="text-danger">{errores?.dob}</div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                          <Select
                            options={genderOptions}
                            name="gender"
                            value={genderOptions.find((g) => g.value === userData?.gender)}
                            onChange={(option) => {
                              handleInputChange({ target: { name: 'gender', value: option.value } });

                            }}
                            styles={customStyles}
                          />
                          {errores?.gender && <div className="text-danger">{errores?.gender}</div>}

                        </div>
                      </div>
                      <div className="col-md-6">
                          <div className="input-block mb-3">
                            <label className="col-form-label">Date of joining<span className="text-danger">*</span></label>
                            <DatePicker
                              selected={userData?.DateOfJoining ? userData?.DateOfJoining : null}
                              onChange={(date) =>
                                handleInputChange({
                                  target: {
                                    name: "DateOfJoining",
                                    value: date,
                                  },
                                })
                              }
                              className={`form-control ${errores.DateOfJoining ? 'is-invalid' : ''}`}
                              dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                              placeholderText="dd/MM/YYYY" // Placeholder text for the input
                              showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                              popperPlacement="bottom"
                              maxLength={10}
                              // minDate={new Date()} // Block dates before today
                              maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Allow dates up to 1 year from today
                               onKeyDown={handleKeyDown}
                              // onKeyDown={(e) => {
                              //   e.preventDefault();

                              // }}

                            /> {errores.DateOfJoining && <div className="text-danger">{errores.DateOfJoining}</div>}
                          </div>
                        </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Current Address</label>
                      <input
                        type="text"
                        name="currentAddress"
                        className="form-control"
                        value={userData?.currentAddress}
                        onChange={handleInputChange}
                      />{errores?.currentAddress && <div className="text-danger">{errores?.currentAddress}</div>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Permanent Address</label>
                      <input
                        type="text"
                        name="permanentAddress"
                        className="form-control"
                        value={userData?.permanentAddress}
                        onChange={handleInputChange}
                      />{errores?.currentAddress && <div className="text-danger">{errores?.currentAddress}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Phone Number<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        name="mobile"
                        className={`form-control ${errores.mobile ? 'is-invalid' : ''}`}
                        value={userData?.mobile}
                        onChange={handleInputChange}
                      />
                      {errores?.mobile && <div className="text-danger">{errores?.mobile}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Alternative Number</label>
                      <input
                        type="text"
                        name="alternativeNumber"
                        className={`form-control ${errores?.alternativeNumber ? 'is-invalid' : ''}`}
                        value={userData?.alternativeNumber}
                        onChange={handleInputChange}
                      />
                      {errores?.alternativeNumber && <div className="text-danger">{errores?.alternativeNumber}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Email<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        name="email"
                        className={`form-control ${errores.email ? 'is-invalid' : ''}`}
                        value={userData?.email}
                        onChange={handleInputChange}
                      />
                      {errores?.email && <div className="text-danger">{errores?.email}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Personal Email </label>
                      <input
                        type="text"
                        name="personalEmail"
                        className={`form-control ${errores.personalEmail ? 'is-invalid' : ''}`}
                        value={userData?.personalEmail}
                        onChange={handleInputChange}
                      />
                      {errores.personalEmail && <div className="text-danger">{errores.personalEmail}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Status<span className="text-danger">*</span></label>
                      <Select
                        name="employeestatus"
                        value={STATUS.find((status) => status.value === userData?.employeestatus) || null} // Bind existing value
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "employeestatus",
                              value: option?.value,
                            },
                          })
                        }
                        options={STATUS}
                        styles={customStyles}
                      />
                      {errores?.employeestatus && <div className="text-danger">{errores?.employeestatus}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Department</label>

                      {/* Select dropdown for department */}
                      <Select
                        name="department"
                        value={{
                          label: userData?.department,
                          value: userData?.department,
                        }}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "department",
                              value: option?.value,
                            },
                          })
                        }
                        options={renderOptionsformultiple(options, { name: "TIMER_DEPARTMENT" }, TIMER_DEPARTMENT)}
                        styles={customStyles}
                      />

                      {/* Error message for department */}
                      {errores?.department && <div className="text-danger">{errores?.department}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Role</label>

                      {/* Select dropdown for department */}
                      <Select
                        name="role"
                        value={{
                          label: userData?.role,
                          value: userData?.role,
                        }}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "role",
                              value: option?.value,
                            },
                          })
                        }
                        options={renderOptionsformultiple(options, { name: "ROLE" }, ROLE)}
                        styles={customStyles}
                      />

                      {/* Error message for department */}
                      {errores?.role && <div className="text-danger">{errores?.role}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Client</label>

                      {/* Select dropdown for clients */}
                      <Select
                        name="clients"
                        value={{
                          label: userData?.clients,
                          value: userData?.clients,
                        }}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "clients",
                              value: option?.value,
                            },
                          })
                        }
                        options={clientOptions
                          ?.filter(clientOption => clientOption?.status === "Active")
                          .map(clientOption => ({
                            label: clientOption?.clientName,
                            value: clientOption?.clientName,
                          }))
                        }

                        styles={customStyles} // Custom styles for the dropdown
                      />

                      {/* Error message for clients */}
                      {errores?.clients && <div className="text-danger">{errores?.clients}</div>}
                    </div>
                  </div>


                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Designation</label>
                      <Select
                        options={renderOptionsformultiple(options, { name: "DOMAIN" }, DOMAIN)}
                        name="designation"
                        value={{
                          label: userData?.designation,
                          value: userData?.designation,
                        }}

                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "designation",
                              value: option?.value,
                            },
                          })
                        }
                        styles={customStyles}
                      />
                      {errores?.designation && <div className="text-danger">{errores?.designation}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Reports To</label>
                      <Select

                        name="reportingTo"
                        value={{
                          label: userData?.reportingTo,
                          value: userData?.reportingTo,
                        }}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "reportingTo",
                              value: option.value,
                            },
                          })
                        }
                        options={Employee?.filter(reportingTo => reportingTo?.employeestatus === 'Active')
                          .map((reportingTo) => ({
                            label: `${reportingTo?.firstname} ${reportingTo?.lastname}`,
                            value: `${reportingTo?.firstname} ${reportingTo?.lastname}`,
                          }))
                        }
                        styles={customStyles}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Process Specialization</label>
                      <input
                        type="text"
                        className={`form-control ${errores.processSplization ? 'is-invalid' : ''}`}
                        name="processSplization"
                        value={userData?.processSplization || ''}
                        onChange={handleInputChange}
                      />
                      {errores?.processSplization && <div className="text-danger">{errores?.processSplization}</div>}
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Marital status</label>
                      <Select
                        options={[
                          { value: 1, label: "Single" },
                          { value: 2, label: "Married" },
                        ]}

                        value={{
                          label: userData?.maritalStatus,
                          value: userData?.maritalStatus,
                        }}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "maritalStatus",
                              value: option?.label,
                            },
                          })
                        }
                        styles={customStyles}
                      />
                      {errores?.maritalStatus && <div className="text-danger">{errores?.maritalStatus}</div>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Blood Group</label>
                      <Select
                        options={bloodGroups}
                        value={bloodGroups.find((r) => r.value === userData?.bloodGroup)}
                        onChange={(option) =>
                          handleInputChange({
                            target: {
                              name: "bloodGroup",
                              value: option?.value,
                            },
                          })
                        }
                        styles={customStyles}
                      />
                    </div>
                  </div>
                  
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isUserDataValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="bank_info_modal" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Bank Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Bank')} data-bs-dismiss="modal" aria-label="Close">
                ×
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleBankInfoSubmit}>
                <div className="row">
                  {/* Account Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Account Number<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${errors.BankAccountNo ? 'is-invalid' : ''}`}
                        name="BankAccountNo"
                        value={bankInfo?.BankAccountNo}
                        onChange={handleBankInputChange}
                      />
                      {Bankerrors?.BankAccountNo && <small className="text-danger">{Bankerrors?.BankAccountNo}</small>}
                    </div>
                  </div>

                  {/* Bank Name */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Bank Name<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${errors.BankName ? 'is-invalid' : ''}`}
                        name="BankName"
                        value={bankInfo?.BankName}
                        onChange={handleBankInputChange}
                      />
                      {Bankerrors?.BankName && <small className="text-danger">{Bankerrors?.BankName}</small>}
                    </div>
                  </div>

                  {/* IFSC Code */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">IFSC Code<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${errors.IFCcode ? 'is-invalid' : ''}`}
                        name="IFCcode"
                        value={bankInfo?.IFCcode}
                        onChange={handleBankInputChange}
                      />
                      {Bankerrors?.IFCcode && <small className="text-danger">{Bankerrors?.IFCcode}</small>}
                    </div>
                  </div>

                  {/* Branch Name */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Branch Name<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${errors.Branch ? 'is-invalid' : ''}`}
                        name="Branch"
                        value={bankInfo?.Branch}
                        onChange={handleBankInputChange}
                      />
                      {Bankerrors?.Branch && <small className="text-danger">{Bankerrors?.Branch}</small>}
                    </div>
                  </div>

                  {/* PAN Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">PAN Number<span className="text-danger">*</span></label>
                      <input
                        type="text"
                        className={`form-control ${errors.PanNumber ? 'is-invalid' : ''}`}
                        name="PanNumber"
                        value={bankInfo?.PanNumber}
                        onChange={handleBankInputChange}
                      />
                      {Bankerrors?.PanNumber && <small className="text-danger">{Bankerrors?.PanNumber}</small>}
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isBankInfoFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/*client */}

      {/*resignation */}
      <div id="employment_status_modal" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Employment Status Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('EmployeeStatus')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEmploymentSubmit}>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Employment Status<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${validationErrors.employmentStatus ? 'is-invalid' : ''}`}
                              name="employmentStatus"
                              value={employmentData?.employmentStatus}
                              onChange={(e) => handleEmploymentInputChange(e)}
                            />
                            {validationErrors.employmentStatus && (
                              <small className="text-danger">{validationErrors.employmentStatus}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Resignation Status<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${validationErrors.resignationStatus ? 'is-invalid' : ''}`}
                              name="resignationStatus"
                              value={employmentData?.resignationStatus}
                              onChange={(e) => handleEmploymentInputChange(e)}
                            />
                            {validationErrors?.resignationStatus && (
                              <small className="text-danger">{validationErrors?.resignationStatus}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Notice Period<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${validationErrors.noticePeriod ? 'is-invalid' : ''}`}
                              name="noticePeriod"
                              value={employmentData?.noticePeriod}
                              onChange={(e) => handleEmploymentInputChange(e)}
                            />
                            {validationErrors?.noticePeriod && (
                              <small className="text-danger">{validationErrors?.noticePeriod}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Resignation Date<span className="text-danger">*</span></label>
                            <DatePicker
                              className="form-control"
                              name="resignationDate"
                              selected={employmentData?.resignationDate}
                              // onChange={handleEmploymentInputChange}
                              onChange={(date) => {
                                handleEmploymentInputChange({ target: { name: "resignationDate", value: date } });
                              }}
                              onKeyDown={handleKeyDown}
                              dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                              placeholderText="dd/MM/YYYY" // Placeholder text for the input
                              showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                              popperPlacement="bottom"
                              maxLength={10}
                            />
                            {validationErrors?.resignationDate && (
                              <small className="text-danger">{validationErrors?.resignationDate}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Last Working Day<span className="text-danger">*</span></label>
                            <DatePicker
                              className="form-control"
                              name="lastWorkingDay"
                              selected={employmentData?.lastWorkingDay}
                              onChange={(date) => {
                                EducationhandleInputChange({ target: { name: "lastWorkingDay", value: date } });
                              }}
                              onKeyDown={handleKeyDown}
                              dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                              placeholderText="dd/MM/YYYY" // Placeholder text for the input
                              showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                              popperPlacement="bottom"
                              maxLength={10}
                            />
                            {validationErrors?.lastWorkingDay && (
                              <small className="text-danger">{validationErrors?.lastWorkingDay}</small>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="input-block mb-5 form-focus focused">
                            <label className="col-form-label">Rehire Eligibility<span className="text-danger">*</span></label>
                            <select
                              className={`form-control ${validationErrors?.RehireEligibility ? 'is-invalid' : ''}`}
                              name="RehireEligibility"
                              value={employmentData?.RehireEligibility || ''}
                              onChange={handleEmploymentInputChange}
                            >
                              <option value="">Select</option>
                              {Rehire?.map((option) => (
                                <option key={option.value} value={option.value}>
                                  {option.label}
                                </option>
                              ))}
                            </select>
                            {validationErrors?.RehireEligibility && (
                              <small className="text-danger">{validationErrors?.RehireEligibility}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit"
                    disabled={submitBtnBlock}
                    {...(isEmploymentFormValid ? { "data-bs-dismiss": "modal" } : {})}>
                    Submit
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>
      </div>
      {/*reporting */}
      <div id="direct_reports" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Direct Reports Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Report')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleReportSubmit}>
                <div className="form-scroll">
                  {reportsData?.map((report, index) => (
                    <div className="card" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Direct Report Information {index + 1}
                          <Link to="#" className="delete-icon" onClick={() => removeReport(index)}>
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="input-block mb-3"> {/* Increased margin-bottom here */}
                              <label className="col-form-label">Name<span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className={`form-control floating ${reportErrors[index]?.DirectReportName ? 'is-invalid' : ''}`}
                                name="DirectReportName"
                                value={report?.DirectReportName}
                                onChange={(e) => handleReportInputChange(index, e)}
                              />
                              {/* <label className="focus-label">Name</label> */}
                              {reportErrors[index]?.DirectReportName && (
                                <small className="text-danger">{reportErrors[index]?.DirectReportName}</small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3"> {/* Increased margin-bottom here */}
                              <label className="col-form-label">Type<span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className={`form-control floating ${reportErrors[index]?.DirectReportType ? 'is-invalid' : ''}`}
                                name="DirectReportType"
                                value={report?.DirectReportType}
                                onChange={(e) => handleReportInputChange(index, e)}
                              />
                              {/* <label className="focus-label">Type</label> */}
                              {reportErrors[index]?.DirectReportType && (
                                <small className="text-danger">{reportErrors[index]?.DirectReportType}</small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3"> {/* Increased margin-bottom here */}
                              <label className="col-form-label">Designation<span className="text-danger">*</span></label>
                              <input
                                type="text"
                                className={`form-control floating ${reportErrors[index]?.DirectReportDesignation ? 'is-invalid' : ''}`}
                                name="DirectReportDesignation"
                                value={report?.DirectReportDesignation}
                                onChange={(e) => handleReportInputChange(index, e)}
                              />
                              {/* <label className="focus-label">Designation</label> */}
                              {reportErrors[index]?.DirectReportDesignation && (
                                <small className="text-danger">{reportErrors[index]?.DirectReportDesignation}</small>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="input-block mb-3">
                              <label className="col-form-label">Department<span className="text-danger">*</span></label>
                              {/* Increased margin-bottom here */}
                              <input
                                type="text"
                                className={`form-control floating ${reportErrors[index]?.DirectReportDepartment ? 'is-invalid' : ''}`}
                                name="DirectReportDepartment"
                                value={report?.DirectReportDepartment}
                                onChange={(e) => handleReportInputChange(index, e)}
                              />
                              {/* <label className="focus-label">Department</label> */}
                              {reportErrors[index]?.DirectReportDepartment && (
                                <small className="text-danger">{reportErrors[index]?.DirectReportDepartment}</small>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="add-more">
                    <Link to="#" onClick={addReport}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isDirectReportFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>


      <div id="emergency_contact_modal" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Emergency Contact </h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Contact')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleEmergencySubmit} style={{ backgroundColor: 'white', padding: '20px', borderRadius: '10px' }}>
                {emergencyContacts?.map((contact, index) => (
                  <div key={index} className="card ">
                    <div className="card-body">
                      <h3 className="card-title">Emergency Contact {index + 1}
                        <Link to="#" className="delete-icon" onClick={() => handleDeleteContact(index)}>
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-form-label">
                            Name <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${contactErrors[index]?.name ? 'is-invalid' : ''}`}
                            name="name"
                            value={contact?.name || ''}
                            onChange={(e) => handleEmergencyChange(index, e)}

                          />
                          {contactErrors[index]?.name && (
                            <small className="text-danger">{contactErrors[index]?.name}</small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">
                            Relationship <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${contactErrors[index]?.relationship ? 'is-invalid' : ''}`}
                            name="relationship"
                            value={contact?.relationship || ''}
                            onChange={(e) => handleEmergencyChange(index, e)}

                          />
                          {contactErrors[index]?.relationship && (
                            <small className="text-danger">{contactErrors[index]?.relationship}</small>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label className="col-form-label">
                            Phone <span className="text-danger">*</span>
                          </label>
                          <input
                            type="text"
                            className={`form-control ${contactErrors[index]?.phoneNumber ? 'is-invalid' : ''}`}
                            name="phoneNumber"
                            value={contact?.phoneNumber || ''}
                            onChange={(e) => handleEmergencyChange(index, e)}

                          />
                          {contactErrors[index]?.phoneNumber && (
                            <small className="text-danger">{contactErrors[index]?.phoneNumber}</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div className="add-more">
                  <Link to="#" onClick={addNewContact}>
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </Link>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isContactsValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/*workinfo*/}



      {/*addnote */}
      <div
        id="note_modal_popup"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header d-flex justify-content-between align-items-center" style={{ height: "80px" }}>
              <h3 className="modal-title">Add Note</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose('note')}
              >
                ×
              </button>
            </div>
            <div className="modal-body pt-3">
              <form
                onSubmit={handleNoteSubmit} // Handle form submission
                style={{
                  backgroundColor: "white",
                  padding: "20px",
                  borderRadius: "10px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "flex-start",
                  minHeight: "300px", // Adjust height for the main box
                  width: "100%" // Ensure full width
                }}
              >
                <div className="input-block mb-3 form-focus" style={{ width: "100%" }}>
                  <textarea
                    rows={5} // Set the number of rows
                    value={note} // Allow for user input
                    onChange={handleNoteChange} // Change handler
                    className="w-100"
                    style={{
                      backgroundColor: "#ffffff", // Change to white for user input
                      cursor: "text", // Change cursor to text for enabled textarea
                      resize: "none",
                      minHeight: "200px", // Minimum height for the textarea
                      height: "auto",
                      outline: 'none' // Remove outline for focused textarea
                    }}
                  />
                </div>
                {/* Ensure space between textarea and submit button */}
              </form>
              <div className="submit-section" style={{
                marginTop: "20px",
                width: "100%", textAlign: "center"
              }}>
                <button className="btn btn-primary submit-btn"
                  data-bs-dismiss={noteChange ? "modal" : undefined}
                  disabled={submitBtnBlock}
                  onClick={handleNoteSubmit} style={{ width: "200px" }}>

                  Submit
                </button>
              </div>

            </div>
          </div>
        </div>
      </div>



      {/*historyrecords */}
      <div
        id="work_history_modal_popup"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Work History</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('WorkHistory')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>

            </div>
            <div className="modal-body">
              <form
                onSubmit={handleWorkHistorySubmit}

              >
                {workHistoryRecord?.map((record, index) => (
                  <div key={index} className="card mb-3 shadow-sm">
                    <div className="card-body">
                      <h3 className="card-title d-flex justify-content-between align-items-center">
                        Work History {index + 1}
                        <Link
                          to="#"
                          className="delete-icon "
                          onClick={() => deleteWorkHistory(index)}
                        >
                          <i className="fa-regular fa-trash-can" />
                        </Link>
                      </h3>
                      <div className="row">
                        {/* Department Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">Department<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${workHistoryErrors[index]?.department ? 'is-invalid' : ''}`}
                            name="department"
                            value={record?.department || ''}
                            onChange={(e) => handleHistoryRecordChange(index, e)}
                          />
                          {workHistoryErrors[index]?.department && (
                            <div className="invalid-feedback">{workHistoryErrors[index]?.department}</div>
                          )}
                        </div>

                        {/* Designation Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">Designation<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${workHistoryErrors[index]?.designation ? 'is-invalid' : ''}`}
                            name="designation"
                            value={record.designation || ''}
                            onChange={(e) => handleHistoryRecordChange(index, e)}
                          />
                          {workHistoryErrors[index]?.designation && (
                            <div className="invalid-feedback">{workHistoryErrors[index].designation}</div>
                          )}
                        </div>

                        {/* Client Handled Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">Client Handled<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${workHistoryErrors[index]?.clientHandled ? 'is-invalid' : ''}`}
                            name="clientHandled"
                            value={record?.clientHandled || ''}
                            onChange={(e) => handleHistoryRecordChange(index, e)}
                          />
                          {workHistoryErrors[index]?.clientHandled && (
                            <div className="invalid-feedback">{workHistoryErrors[index].clientHandled}</div>
                          )}
                        </div>

                        {/* Process Handled Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">Process Handled<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${workHistoryErrors[index]?.processHandled ? 'is-invalid' : ''}`}
                            name="processHandled"
                            value={record?.processHandled || ''}
                            onChange={(e) => handleHistoryRecordChange(index, e)}
                          />
                          {workHistoryErrors[index]?.processHandled && (
                            <div className="invalid-feedback">{workHistoryErrors[index].processHandled}</div>
                          )}
                        </div>

                        {/* Shift Timings Field */}


                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">Shift Timings<span className="text-danger">*</span></label>
                          <select
                            className={`form-control ${workHistoryErrors[index]?.shiftTimings ? 'is-invalid' : ''}`}
                            name="shiftTimings"
                            value={record?.shiftTimings || ''}
                            onChange={(e) =>
                              handleHistoryRecordChange(index, {
                                target: {
                                  name: 'shiftTimings',
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Shift Timings</option>
                            <option value="9Am-5pm">9Am-5pm</option>

                          </select>
                          {workHistoryErrors[index]?.shiftTimings && (
                            <div className="invalid-feedback">{workHistoryErrors[index].shiftTimings}</div>
                          )}


                        </div>
                        {/* From Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">From<span className="text-danger">*</span></label>
                          <DatePicker
                            className={`form-control ${workHistoryErrors[index]?.from ? 'is-invalid' : ''}`}
                            selected={record?.from} // Ensure this is a Date object
                            onChange={(date) =>
                              handleHistoryRecordChange(index, {
                                target: {
                                  name: "from", // Name of the field being updated
                                  value: date,  // Date object
                                },
                              })
                            }
                            dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                            placeholderText="dd/MM/YYYY" // Placeholder text for the input
                            showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                            popperPlacement="bottom"
                            maxDate={record?.to}
                            maxLength={10}
                          />

                          {workHistoryErrors[index]?.from && (
                            <div className="invalid-feedback">{workHistoryErrors[index].from}</div>
                          )}
                        </div>
                        {/* To Field */}
                        <div className="col-md-6 mb-3">
                          <label className="col-form-label">To<span className="text-danger">*</span></label>
                          <DatePicker
                            className={`form-control ${workHistoryErrors[index]?.to ? 'is-invalid' : ''}`}
                            selected={record?.to} // Ensure this is a Date object
                            onChange={(date) =>
                              handleHistoryRecordChange(index, {
                                target: {
                                  name: "to", // Name of the field being updated
                                  value: date,  // Date object
                                },
                              })
                            }
                            dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                            placeholderText="dd/MM/YYYY" // Placeholder text for the input
                            showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                            popperPlacement="bottom"
                            minDate={record.from}
                            maxLength={10}
                          />
                          {workHistoryErrors[index]?.to && (
                            <div className="invalid-feedback">{workHistoryErrors[index].to}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* Add New Work History */}
                <div className="add-more">
                  <Link to="#" onClick={addWorkHistory}>
                    <i className="fa-solid fa-plus-circle" /> Add More
                  </Link>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>

      </div>





      {/* Personal Info Modal */}
      <div
        id="personal_info_modal"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Personal Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Personal')} data-bs-dismiss="modal" aria-label="Close">
                ×
              </button>
            </div>
            <div className="modal-body">

              <form onSubmit={PersonalhandleSubmit}>
                <div className="row">
                  {/* Adhar Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Aadhar Number</label>
                      <input
                        type="text"
                        className={`form-control ${Personalerrors.adharNumber ? 'is-invalid' : ''}`}
                        name="adharNumber"
                        value={personalInfo?.adharNumber}
                        onChange={PersonalhandleInputChange}
                      />
                      {/* Only show error message if there's an error */}
                      {Personalerrors.adharNumber && <small className="text-danger">{Personalerrors.adharNumber}</small>}
                    </div>
                  </div>

                  {/* Pf Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">PF Number</label>
                      <input
                        type="text"
                        className={`form-control ${Personalerrors.PfNo ? 'is-invalid' : ''}`}
                        name="PfNo"
                        value={personalInfo?.PfNo}
                        onChange={PersonalhandleInputChange}
                      />
                      {Personalerrors.PfNo && <small className="text-danger">{Personalerrors.PfNo}</small>}
                    </div>
                  </div>

                  {/* UAN */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">UAN</label>
                      <input
                        type="text"
                        className={`form-control ${Personalerrors.UAN ? 'is-invalid' : ''}`}
                        name="UAN"
                        value={personalInfo?.UAN}
                        onChange={PersonalhandleInputChange}
                      />
                      {Personalerrors.UAN && <small className="text-danger">{Personalerrors.UAN}</small>}
                    </div>
                  </div>

                  {/* ESI Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">ESI Number</label>
                      <input
                        type="text"
                        className={`form-control ${Personalerrors.EsiNo ? 'is-invalid' : ''}`}
                        name="EsiNo"
                        value={personalInfo?.EsiNo}
                        onChange={PersonalhandleInputChange}
                      />
                      {Personalerrors.EsiNo && <small className="text-danger">{Personalerrors.EsiNo}</small>}
                    </div>
                  </div>

                  {/* Health Insurance Number */}
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Health Insurance No</label>
                      <input
                        type="text"
                        className={`form-control ${Personalerrors.HealthInsuranceNo ? 'is-invalid' : ''}`}
                        name="HealthInsuranceNo"
                        value={personalInfo?.HealthInsuranceNo}
                        onChange={PersonalhandleInputChange}
                      />
                      {Personalerrors.HealthInsuranceNo && <small className="text-danger">{Personalerrors.HealthInsuranceNo}</small>}
                    </div>
                  </div>

                </div>

                {/* Submit Button */}
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(personalValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button><br /><br />
                  {Personalerrors?.main && <span className="text-danger">{Personalerrors.main}</span>}
                </div>

              </form>

            </div>
          </div>
        </div>
      </div>

      {/* /Personal Info Modal */}
      {/* */}
      <div
        id="basic_info_model"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Work Experience</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Experience')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleBasicSave}>
                <div className="form-scroll">
                  <div className="card">
                    <div className="card-body">
                      <div className="row g-3">
                        <div className="col-md-4">
                          <label className="col-form-label">Employment Type<span className="text-danger">*</span></label>
                          <select
                            className="form-control"
                            name="EmploymentType"
                            value={workExperience?.EmploymentType || ""}
                            onChange={(e) =>
                              handleBasicChange({
                                target: {
                                  name: "EmploymentType",
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Employment Type</option> {/* Empty default option */}
                            <option value="Full Time">Full Time</option>
                            <option value="Part Time">Part Time</option>
                          </select>
                          {basicinfo?.EmploymentType && (
                            <small className="text-danger mt-1 d-block">{basicinfo?.EmploymentType}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Work Type<span className="text-danger">*</span></label>
                          <select
                            className="form-control"
                            name="WorkType"
                            value={workExperience?.WorkType || ""}
                            onChange={(e) =>
                              handleBasicChange({
                                target: {
                                  name: "WorkType",
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Work Type</option> {/* Empty default option */}
                            <option value="WFO">WFO</option>
                            <option value="WFH">WFH</option>
                          </select>
                          {basicinfo?.WorkType && (
                            <small className="text-danger mt-1 d-block">{basicinfo?.WorkType}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Shift Timings<span className="text-danger">*</span></label>
                          <select
                            className="form-control"
                            name="ShiftTimings"
                            value={workExperience?.ShiftTimings || ""}
                            onChange={(e) =>
                              handleBasicChange({
                                target: {
                                  name: "ShiftTimings",
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Shift Timings</option> {/* Empty default option */}
                            <option value="9 AM - 5PM">9 AM - 5PM</option>
                            <option value="9 AM - 4PM">9 AM - 4PM</option>
                          </select>
                          {basicinfo?.ShiftTimings && (
                            <small className="text-danger mt-1 d-block">{basicinfo?.ShiftTimings}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Years<span className="text-danger">*</span></label>
                          <input
                            type="number"
                            className={`form-control ${basicinfo.years ? 'is-invalid' : ''}`}
                            name="years"
                            value={workExperience?.Experience.years}
                            onChange={handleBasicChange}
                            max="60" // Set maximum value
                            min="0"
                          />
                          {basicinfo?.years && (
                            <small className="text-danger mt-1 d-block">{basicinfo.years}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Months<span className="text-danger">*</span></label>
                          <input
                            type="number"
                            className={`form-control ${basicinfo.months ? 'is-invalid' : ''}`}
                            name="months"
                            value={workExperience?.Experience.months}
                            onChange={handleBasicChange}
                            max="11" // Set maximum value
                            min="0"
                          />
                          {basicinfo?.months && (
                            <small className="text-danger mt-1 d-block">{basicinfo.months}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Probation Status<span className="text-danger">*</span></label>
                          <select
                            className={`form-control ${basicinfo.ProbationStatus ? 'is-invalid' : ''}`}
                            name="ProbationStatus"
                            value={workExperience?.ProbationStatus || ""}
                            onChange={(e) =>
                              handleBasicChange({
                                target: {
                                  name: "ProbationStatus",
                                  value: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Select Probation Status</option> {/* Empty default option */}
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                          {basicinfo?.ProbationStatus && (
                            <small className="text-danger mt-1 d-block">{basicinfo.ProbationStatus}</small>
                          )}
                        </div>

                        <div className="col-md-4">
                          <label className="col-form-label">Probation Period<span className="text-danger">*</span></label>
                          <input
                            type="text"
                            className={`form-control ${basicinfo.probationPeriod ? 'is-invalid' : ''}`}
                            name="probationPeriod"
                            value={workExperience?.probationPeriod}
                            onChange={handleBasicChange}
                          />
                          {basicinfo?.probationPeriod && (
                            <small className="text-danger mt-1 d-block">{basicinfo.probationPeriod}</small>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(basicValid ? { "data-bs-dismiss": "modal" } : {})}>
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>



      {/* Education Modal */}
      <div id="education_info" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Education Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose('Education')}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={EducationhandleSubmit}>
                <div className="form-scroll">
                  {educationData?.map((education, index) => (
                    <div className="card" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Education Information {index + 1}
                          <Link to="#" className="delete-icon" onClick={() => deleteEducation(index)}>
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">
                          {/* Input Fields */}
                          {["educationLevel", "college", "university", "degree", "fieldOfStudy", "typeOfStudy"].map((field) => (
                            <div className="col-md-6 mb-4" key={field}>
                              <label className="col-form-label">
                                {field.replace(/([A-Z])/g, ' $1')}
                                <span className="text-danger">*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${educationErrors[index]?.[field] ? 'is-invalid' : ''}`}
                                name={field}
                                value={education[field] || ''}
                                onChange={(e) => EducationhandleInputChange(index, e)} // Handles input change for text fields
                              />
                              {/* Show error message for the field */}
                              {educationErrors[index]?.[field] && (
                                <small className="text-danger mt-1 d-block">{educationErrors[index][field]}</small>
                              )}
                            </div>
                          ))}

                          {/* Date Pickers */}
                          <div className="col-md-6 mb-4">
                            <label className="col-form-label">Joining Date<span className="text-danger">*</span></label>
                            <DatePicker
                              className="form-control"
                              selected={education?.from ? new Date(education?.from) : null} // Format the date if needed
                              onChange={(date) => {
                                EducationhandleInputChange(index, { target: { name: "from", value: date } });
                              }}
                              onKeyDown={handleKeyDown}
                            />
                            {educationErrors[index]?.from && (
                              <small className="text-danger mt-1 d-block">{educationErrors[index].from}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-4">
                            <label className="col-form-label">Completed Date<span className="text-danger">*</span></label>
                            <DatePicker
                              className="form-control"
                              selected={education?.to ? new Date(education?.to) : null} // Format the date if needed
                              onChange={(date) => {
                                EducationhandleInputChange(index, { target: { name: "to", value: date } });
                              }}
                              onKeyDown={handleKeyDown}
                              dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                              placeholderText="dd/MM/YYYY" // Placeholder text for the input
                              showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                              popperPlacement="bottom"
                              maxLength={10}
                            />
                            {educationErrors[index]?.to && (
                              <small className="text-danger mt-1 d-block">{educationErrors[index].to}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="add-more">
                    <Link to="#" onClick={addEducation}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(isEducationFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>


      {/* /reporting Modal */}
      <div id="reporting_manager" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document" >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Reporting Manager Information</h5>
              <button type="button" className="btn-close" onClick={() => handleClose('Manager')} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleManagerSubmit}>
                <div className="form-scroll">
                  {managersData?.map((manager, index) => (
                    <div className="card" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Reporting Manager Information {index + 1}
                          <Link to="#" className="delete-icon" onClick={() => deleteManager(index)}>
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">
                          <div className="col-md-6 mb-3"> {/* Added margin-bottom for consistency */}
                            <label className="col-form-label">Name<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${managerErrors[index]?.reportingManagerName ? 'is-invalid' : ''}`}
                              name="reportingManagerName"
                              value={manager?.reportingManagerName}
                              onChange={(e) => handleManagerInputChange(index, e)}
                            />
                            {managerErrors[index]?.reportingManagerName && (
                              <small className="text-danger">{managerErrors[index].reportingManagerName}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-3"> {/* Added margin-bottom for consistency */}
                            <label className="col-form-label">Type<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${managerErrors[index]?.reportingManagerType ? 'is-invalid' : ''}`}
                              name="reportingManagerType"
                              value={manager?.reportingManagerType}
                              onChange={(e) => handleManagerInputChange(index, e)}
                            />
                            {managerErrors[index]?.reportingManagerType && (
                              <small className="text-danger">{managerErrors[index].reportingManagerType}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-3"> {/* Added margin-bottom for consistency */}
                            <label className="col-form-label">Designation<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${managerErrors[index]?.reportingManagerDesignation ? 'is-invalid' : ''}`}
                              name="reportingManagerDesignation"
                              value={manager?.reportingManagerDesignation}
                              onChange={(e) => handleManagerInputChange(index, e)}
                            />
                            {managerErrors[index]?.reportingManagerDesignation && (
                              <small className="text-danger">{managerErrors[index].reportingManagerDesignation}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-3"> {/* Added margin-bottom for consistency */}
                            <label className="col-form-label">Department<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${managerErrors[index]?.reportingManagerDepartment ? 'is-invalid' : ''}`}
                              name="reportingManagerDepartment"
                              value={manager?.reportingManagerDepartment}
                              onChange={(e) => handleManagerInputChange(index, e)}
                            />
                            {managerErrors[index]?.reportingManagerDepartment && (
                              <small className="text-danger">{managerErrors[index].reportingManagerDepartment}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="add-more">
                    <Link to="#" onClick={addManager}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    // Disable during submission
                    {...(isManagerFormValid ? { "data-bs-dismiss": "modal" } : {})} // Add dismiss only on success
                  >
                    Submit
                  </button>
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
      {/* Family Info Modal */}
      <div
        id="family_info_modal"
        className="modal custom-modal fade"
        role="dialog"
        data-bs-backdrop="static" data-bs-keyboard="false"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title"> Family Informations</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => handleClose('Family')}
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={FamilyhandleSubmit}>
                <div className="form-scroll">
                  {familyMembers?.map((member, index) => (
                    <div className="card mb-4" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Family Information {index + 1}
                          <Link
                            to="#"
                            className="delete-icon"
                            onClick={() => removeFamilyMember(index)}
                          >
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">

                          {/* Name Field */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="col-form-label">
                                Name <span className="text-danger">*</span>
                              </label>
                              <input
                                className={`form-control ${familyErrors[index]?.name ? 'is-invalid' : ''}`}
                                type="text"
                                name="name"
                                value={member?.name}
                                onChange={(e) => handleFamilyChange(index, e)}
                                placeholder="Enter Name"
                              />
                              {familyErrors[index]?.name && (
                                <small className="text-danger">{familyErrors[index].name}</small>
                              )}
                            </div>
                          </div>

                          {/* Relationship Field */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="col-form-label">
                                Relationship <span className="text-danger">*</span>
                              </label>
                              <input
                                className={`form-control ${familyErrors[index]?.relationship ? 'is-invalid' : ''}`}
                                type="text"
                                name="relationship"
                                value={member?.relationship}
                                onChange={(e) => handleFamilyChange(index, e)}
                                placeholder="Enter Relationship"
                              />
                              {familyErrors[index]?.relationship && (
                                <small className="text-danger">{familyErrors[index].relationship}</small>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          {/* Date of Birth Field */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="col-form-label">
                                Date of Birth <span className="text-danger">*</span>
                              </label>
                              <DatePicker
                                className={`form-control ${familyErrors[index]?.dob ? 'is-invalid' : ''}`}

                                name="dob"
                                selected={member?.dob}
                                onChange={(date) => {
                                  handleFamilyChange(index, { target: { name: "dob", value: date } });
                                }}
                                onKeyDown={handleKeyDown}
                                dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                                placeholderText="dd/MM/YYYY" // Placeholder text for the input
                                showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                                popperPlacement="bottom"
                                maxLength={10}
                              />
                              {familyErrors[index]?.dob && (
                                <small className="text-danger">{familyErrors[index].dob}</small>
                              )}
                            </div>
                          </div>
                          {/* Age Field */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="col-form-label">
                                Age <span className="text-danger">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="age"
                                value={member?.age}
                                onChange={(e) => handleFamilyChange(index, e)}
                                placeholder="Enter Age"
                              />
                              {familyErrors[index]?.age && (
                                <small className="text-danger">{familyErrors[index].age}</small>
                              )}
                            </div>
                          </div>

                          {/* Dependent Field */}
                          <div className="col-md-6">
                            <div className="form-group mb-3">
                              <label className="col-form-label">
                                Dependent <span className="text-danger">*</span>
                              </label>
                              <select
                                className="form-control"
                                name="dependent"
                                value={member?.dependent}
                                onChange={(e) => handleFamilyChange(index, e)}
                              >
                                <option value="">Select Dependent Status</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>
                              {familyErrors[index]?.dependent && (
                                <small className="text-danger">{familyErrors[index].dependent}</small>
                              )}
                            </div>
                          </div>

                        </div>

                      </div>
                    </div>
                  ))}

                  {/* Add More Button */}
                  <div className="add-more mb-3">
                    <Link to="#" onClick={addFamilyMember}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                  {/* Submit Button */}
                </div>
                <div className="submit-section">
                  <button
                    // className="btn btn-primary submit-btn"
                    type="submit"
                    className="btn btn-primary submit-btn"
                    disabled={submitBtnBlock}
                    {...(isFamilyFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                </div>
              </form>


            </div>
          </div>
        </div>
        <style jsx>{`
    input.is-invalid {
      box-shadow: none !important; /* Remove the box-shadow effect */
      border-color: #dc3545; /* Optional: Adjust border color to show error */
    }
  `}</style>
      </div>
      {/* /Family Info Modal */}
    </>
  );
};

export default PersonalInformationModelPopup;
