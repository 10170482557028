import { useState, useEffect } from 'react';
import { Link,useParams } from 'react-router-dom';
import { GET_ALL_LEAD_WORKHISTORY_BY_ID } from '../../../Queries/Leads';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
const WorkHistory = () => {
  const [leadWorkHistoryDetails, setLeadWorkHistoryDetails] = useState([])
  const { id } = useParams();

  const workHistoryObj = {
    leadId:id,
}

  const [getAllLeadWorkHistoryByID, { }] = useMutation(GET_ALL_LEAD_WORKHISTORY_BY_ID, {
    onCompleted(data) {
        console.log('getAllLeadWorkHistoryByID-->', data)
        setLeadWorkHistoryDetails(data.getAllLeadWorkHistoryByID)
    },
});


useEffect(() => {
    // getAllWorkHistoryByID({ variables: workHistoryObj });
    getAllLeadWorkHistoryByID({ variables: workHistoryObj });

}, []);
   
  return (
    <div className="d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Work History{" "}
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#work_history_modal"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>Date & Time</th>
                  <th>Action</th>
                  <th>Status</th>
                  <th>User Name</th>
                </tr>
              </thead>
              <tbody>
                {leadWorkHistoryDetails?.map((item, index) => (
                  <tr key={index}>
                  <td>{item?.changeTime ? dayjs(parseInt(item.changeTime)).format('DD/MM/YYYY h:mm:ss a') : 'N/A'}</td>
                    <td>{item?.action || 'N/A'}</td>
                    <td>{item?.leadStatus || 'N/A'}</td>
                    <td>{item?.userName || 'N/A'}</td>
                  </tr>
                ))}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkHistory;
