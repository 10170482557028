import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import { fetchEmployeeReport } from "../Slices/employeereportslice";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_ALL_USERS } from "../Queries/Users";
import { Spin } from "antd";


const ClientsReportFilter = ({PageChange}) => {

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const today = new Date().toISOString().split('T')[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  console.log("fromDate,toDate",fromDate,toDate)
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const maxFromDate = twoMonthsAgo.toISOString().split('T')[0];
  const { data } = useQuery(GET_ALL_USERS);
  const options = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
    {value:"",label:"Both"}
  ];
  const [billable, setBillable] = useState(options[2]);
  console.log("billable",billable)
  const [billableValue, setBillableValue] = useState(false);
  const handleBillableChange = (selectedOption) => {
    setBillable(selectedOption);
    setBillableValue(selectedOption.value);
  };
  
 
  const customStyles = {
    control: (provided) => ({
      ...provided,
      minHeight: "39px", // Matching height to inputs
      fontSize: "14px",  // Slightly increased font size for better readability
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#837F7F" : "#fff",
      color: state.isFocused ? "#fff" : "#4d5154",
      "&:hover": {
        backgroundColor: "#837F7F",
      },
    }),
  };
  const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
  const filters = {
    employeeName: parseJwt(token)?.id.toString(),
    startDateTime: fromDate.toString(),
    endDateTime: toDate.toString(),
    billable: billableValue,
  };
  const handleSearch = () => {
    setLoading(true);
    dispatch(fetchEmployeeReport(filters))
      .finally(() => setLoading(false));
    PageChange(1)
  };
  const handleClear = () => {
    setLoading(true);
    setFromDate(today);
    setToDate(today);
    setBillable(null);
    setBillableValue(null)
    const filters = {
      employeeName: parseJwt(token)?.id.toString(),
      startDateTime: today,
      endDateTime: today,
 
    };
    dispatch(fetchEmployeeReport(filters))
    .finally(() => setLoading(false));
    PageChange(1);
  };
 
 
  
  return (
    <div className="row filter-row align-items-center" style={{ gap: "15px", paddingBottom: "10px" }}>
      <div className="d-flex gap-3 align-items-center" style={{ flexWrap: 'wrap' }}>
        {/* From Date */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="fromDate" style={{ display: 'block', marginBottom: '5px' }}>
            From Date
          </label>
          <input
            type="date"
            id="fromDate"
        value={fromDate}
        max={today}
        min={maxFromDate}
        onChange={(e) => setFromDate(e.target.value)}
            style={{
              padding: '10px',
              width: '200px',  
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '39px',  
            }}
          />
        </div>

        {/* To Date */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="toDate" style={{ display: 'block', marginBottom: '5px' }}>
            To Date
          </label>
          <input
            id="toDate"
            type="date"
            value={toDate}
            max={today}
            min={fromDate}
            onChange={(e) => setToDate(e.target.value)}
            style={{
              padding: '10px',
              width: '200px',  // Set fixed width for consistent alignment
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '39px',  // Set height consistent with other elements
            }}
          />
        </div>

        {/* Billable Dropdown */}
        <div style={{ marginBottom: '15px' }}>
          <label htmlFor="billable" style={{ display: "block", marginBottom: "5px" }}>
            Billable
          </label>
          <Select
            options={options}
            placeholder="Select"
            value={billable} 
            onChange = {handleBillableChange}
            styles={customStyles}
            menuPlacement="auto"
            id="billable"
            style={{
              padding: '10px',
              width: '200px',  // Set fixed width for consistent alignment
              borderRadius: '5px',
              border: '1px solid #ccc',
              height: '39px',  // Set height consistent with other elements
            }}
          />
        </div>

        {/* Clear Button */}
        <div style={{ marginBottom: '15px' }}>
          <label style={{ visibility: 'hidden', display: 'block', marginBottom: '5px' }}>Clear</label> {/* Hidden label for spacing */}
          <button
            // className="bg-primary"
            onClick={handleClear}
            style={{
              padding: '10px 15px',
              width: '100px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              color: '#fff',
              cursor: 'pointer',
              height: '39px',  // Set height consistent with other elements
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#837F7F',
            }}
          >
            Clear
          </button>
        </div>

        {/* Search Button */}
        <div style={{ marginBottom: '15px' }}>
          <label style={{ visibility: 'hidden', display: 'block', marginBottom: '5px' }}>Clear</label> {/* Hidden label for spacing */}
          <button
            // className="bg-primary"
            onClick={handleSearch}
            style={{
              padding: '10px 15px',
              width: '100px',
              borderRadius: '5px',
              border: '1px solid #ccc',
              color: '#fff',
              cursor: 'pointer',
              height: '39px',  // Set height consistent with other elements
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#007bff',
            }}
          >
            Search
          </button>
        </div>
      </div>
      {loading && (
        <div className="loader" style={{ textAlign: "center", marginTop: "20px" }}>
          <Spin size="large" /> 
        </div>
      )}
    </div>
  );
};

export default ClientsReportFilter;
