import React, { useState, memo, useEffect } from 'react';
import { Formik, FieldArray, Field, useFormikContext } from 'formik';
import { Collapse, Form, Input, Button, Select, Switch, Divider, Row, Col, Typography, Space } from 'antd';
import CommentField from './CommentField';
import { useDispatch, useSelector } from 'react-redux';
import { TimePicker, DatePicker } from 'antd';
import { ArrowDownOutlined } from '@ant-design/icons';
import * as DataBucket from '../../../databucket';
import { addLastContactedDate, removeLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import { setIsRefetch } from '../../../Slices/salesSlice';

import dayjs from 'dayjs';
import Swal from 'sweetalert2';
const { Option } = Select;
const { TextArea } = Input;

const OperationsTeam = memo((props) => {

  const dispatch = useDispatch();

  const { SalesInfo, assignToUsers, getBy, setFieldValue } = props;
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext();

  const [emails, setEmails] = useState(values.leadsOPS?.map((ops) => ops.emails) || []);
  const [phonenumbers, setPhonenumbers] = useState(values.leadsOPS?.map((ops) => ops.phonenumber) || []);
  const [remarks, setRemarks] = useState(values.leadsOPS?.map((ops) => ops.remarks) || []);
  const [clientAttendees, setClientAttendees] = useState(values.leadsOPS?.map((ops) => ops.clientAttendees) || []);
  const [blueplanitAttendees, setBlueplanitAttendees] = useState(values.leadsOPS?.map((ops) => ops.blueplanitAttendees) || []);
  const [amsUsedByClient, setAmsUsedByClient] = useState(values.leadsOPS?.map((ops) => ops.amsusedbyClient) || []);
  const [clientRequirements, setClientRequirements] = useState(values.leadsOPS?.map((ops) => ops.clientRequirements) || []);
  const [pros, setPros] = useState(values.leadsOPS?.map((ops) => ops.pros) || []);
  const [cons, setCons] = useState(values.leadsOPS?.map((ops) => ops.cons) || []);

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setEmails([]);
      setPhonenumbers([]);
      setRemarks([]);
      setClientAttendees([]);
      setBlueplanitAttendees([]);
      setAmsUsedByClient([]);
      setClientRequirements([]);
      setPros([]);
      setCons([]);
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const [localErrors, setLocalErrors] = useState([]);

  const handleAddRow = (arrayHelpers) => {

    const newDate = dayjs(new Date());
    setFieldValue("lastContactedDate", newDate);
    arrayHelpers.push({
      date: newDate || "",
      time: dayjs().set('second', 0).set('millisecond', 0) || '',
      callType: '',
      emails: '',
      phonenumber: '',
      callScheduledBy: getBy(null),
      remarks: '',
      callConnected: false,
      clientAttendees: '',
      blueplanitAttendees: '',
      amsusedbyClient: '',
      clientRequirements: '',
      pros: '',
      cons: '',
      emailSent: false,
    });

    // Add the new date to Redux state
    dispatch(addLastContactedDate(newDate));
  };

  // Local Validation for Email (onChange)
  const handleEmailChange = (e, index) => {
    const email = e.target.value.trim();
    const newEmails = [...emails];
    newEmails[index] = email;

    let errorMessage = "";

    // Updated email validation (only for '.com' domain)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
    if (email && !emailRegex.test(email.trim())) {
      errorMessage = "Invalid email format";
    }

    // Remove phone error if email is provided
    const newErrors = [...localErrors];
    newErrors[index] = {
      ...newErrors[index],
      emails: errorMessage,
      phonenumber: email ? "" : newErrors[index]?.phonenumber, // Clear phone error if email is valid
    };

    setEmails(newEmails);
    setLocalErrors(newErrors);
  };

  // Local Validation for Phone Number (onChange)
  const handlePhoneNumberChange = (e, index) => {
    // let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    // if (value.length <= 10) {
    const newPhonenumbers = [...phonenumbers];
    newPhonenumbers[index] = e.target.value;

    // // Local validation for phone number
    // let errorMessage = "";
    // if (value && value.length !== 10) {
    //   errorMessage = "Phone number must be exactly 10 digits";
    // } else if (value && !/^[1-9][0-9]{9}$/.test(value)) {
    //   errorMessage = "Phone number cannot start with 0";
    // }

    // // Remove email error if phone number is provided
    // const newErrors = [...localErrors];
    // newErrors[index] = {
    //   ...newErrors[index],
    //   phonenumber: errorMessage,
    //   emails: value ? "" : newErrors[index]?.emails, // Clear email error if phone is valid
    // };

    setPhonenumbers(newPhonenumbers);
    // setLocalErrors(newErrors);
    // }
  };

  const lastContactedDates = useSelector(state => state.lastContactedDates);
  console.log("lastDates", lastContactedDates)

  const handleDeleteRow = (index, arrayHelpers) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this OTC record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        arrayHelpers.remove(index); // Remove the call record
        // Update local states
        setEmails((prev) => prev.filter((_, i) => i !== index));
        setPhonenumbers((prev) => prev.filter((_, i) => i !== index));
        setRemarks((prev) => prev.filter((_, i) => i !== index));
        setClientAttendees((prev) => prev.filter((_, i) => i !== index));
        setBlueplanitAttendees((prev) => prev.filter((_, i) => i !== index));
        setAmsUsedByClient((prev) => prev.filter((_, i) => i !== index));
        setClientRequirements((prev) => prev.filter((_, i) => i !== index));
        setPros((prev) => prev.filter((_, i) => i !== index));
        setCons((prev) => prev.filter((_, i) => i !== index));

        // Remove the corresponding local errors
        setLocalErrors((prev) => prev.filter((_, i) => i !== index));

        // Get the date of the row being deleted from `leadsCalls`
        const deletedRowDate = arrayHelpers.form.values.leadsOPS[index].date;

        const lastContactedDatess = lastContactedDates.filter(date => dayjs(date).valueOf() !== dayjs(deletedRowDate).valueOf());

        const newDate = dayjs(new Date());
        const latestDate = lastContactedDatess.length > 0
          ? Math.max(...lastContactedDatess.map(date => dayjs(date).valueOf()))
          : null;

        dispatch(setLastContactedDates(lastContactedDatess));

        if (latestDate) {
          setFieldValue("lastContactedDate", dayjs(latestDate));
        }
      }
    });

  };

  const getTime = (time) => {
    let formatedTime = ''
    if (time != undefined && time != '') {
      if (typeof time === 'object') {
        formatedTime = time

      } else if (typeof time === 'string') {
        if (time?.includes('T')) {
          let tempdatetime = time?.split('T')
          let temptime = tempdatetime[1]?.split('.')
          time = temptime[0]
        }
        let timeArray = time?.split(':')
        formatedTime = dayjs().set('hour', timeArray[0]).set('minute', timeArray[1]).set('second', timeArray[2])
      }
    } // Set the desired time
    return formatedTime;
  }
  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != '') {
      if (typeof datevalue === 'string' && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue))
      } else if (typeof datevalue === 'object') {
        finalDate = datevalue
      }
    }
    return finalDate;
  }
  return (
    <div style={{ marginTop: '15px', backgroundColor: "#fff", padding: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <Typography.Title level={5}>Operations Team Communication</Typography.Title>
      <FieldArray
        name="leadsOPS"
        render={(arrayHelpers) => (
          <>
            {values?.leadsOPS?.map((OpsInfo, index) => (
              <div key={index}>
                <Row gutter={16}>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <Typography.Text strong>Call Schedule No. {index + 1}</Typography.Text>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        color: '#00c5fb',
                        cursor: 'pointer',
                        fontSize: '16px',
                      }}
                      onClick={() => handleDeleteRow(index, arrayHelpers)}
                    />
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsOPS.${index}.date`}>
                        {({ field }) => (
                          <DatePicker
                            allowClear={false}
                            inputReadOnly={true}
                            format="DD/MM/YYYY"
                            value={getDateValue(field?.value)}
                            onChange={(date) =>
                              field.onChange({ target: { value: date, name: field.name } })
                            }
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Time"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsOPS.${index}.time`}>
                        {({ field }) => (
                          <TimePicker
                            format="h:mm A"
                            allowClear={false}
                            inputReadOnly={true}
                            value={getTime(field.value)}
                            onChange={(time) =>
                              field.onChange({ target: { value: time, name: field.name } })
                            }
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Type of Call"
                      labelCol={{ span: 24 }} // Label takes full width
                      wrapperCol={{ span: 24 }} // Field takes full width
                    >
                      <Field name={`leadsOPS.${index}.callType`}>
                        {({ field, form }) => (
                          <Select
                            {...field} // Spread field props to ensure Formik manages the state
                            value={field.value || 'Phone Call'} // Default value handling
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state on change
                            style={{ width: '100%' }}
                          >
                            {DataBucket.SALES_SCHEDULED_CALL_TYPE?.map((carrierItem, key) => (
                              <Select.Option key={key} value={carrierItem}>
                                {carrierItem}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label={
                        <span>
                          Emails
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // help={touched?.leadsOPS?.[index]?.emails && errors?.leadsOPS?.[index]?.emails  }
                      // validateStatus={errors?.leadsOPS?.[index]?.emails && touched?.leadsOPS?.[index]?.emails ? 'error' : ''}
                      help={localErrors[index]?.emails || (touched?.leadsOPS?.[index]?.emails && errors?.leadsOPS?.[index]?.emails)}
                      validateStatus={localErrors[index]?.emails || (touched?.leadsOPS?.[index]?.emails && errors?.leadsOPS?.[index]?.emails) ? 'error' : ''}
                    >
                      <Input
                        name={`leadsOPS.${index}.emails`}
                        value={emails[index] || ''}
                        // onChange={(e) => {
                        //   const newEmails = [...emails];
                        //   newEmails[index] = e.target.value;
                        //   setEmails(newEmails);
                        // }}
                        onChange={(e) => handleEmailChange(e, index)}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].emails`,
                              value: e.target.value.trim(),
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>

                  <Col span={6}>
                    <Form.Item
                      label={
                        <span>
                          Phone Number
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // help={touched?.leadsOPS?.[index]?.phonenumber && errors?.leadsOPS?.[index]?.phonenumber} // Show error message
                      // validateStatus={
                      //   touched?.leadsOPS?.[index]?.phonenumber && errors?.leadsOPS?.[index]?.phonenumber ? 'error' : ''
                      // } 
                      help={localErrors[index]?.phonenumber || (touched?.leadsOPS?.[index]?.phonenumber && errors?.leadsOPS?.[index]?.phonenumber)}
                      validateStatus={localErrors[index]?.phonenumber || (touched?.leadsOPS?.[index]?.phonenumber && errors?.leadsOPS?.[index]?.phonenumber) ? 'error' : ''}
                    >
                      <Input
                        name={`leadsOPS.${index}.phonenumber`}
                        value={phonenumbers[index] || ''}
                        // onChange={(e) => {
                        //   let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        //   if (value.length <= 10) {
                        //     const newPhonenumbers = [...phonenumbers];
                        //     newPhonenumbers[index] = value;
                        //     setPhonenumbers(newPhonenumbers);
                        //   }
                        // }}
                        onChange={(e) => handlePhoneNumberChange(e, index)}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].phonenumber`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>

                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="Call Scheduled by"
                      labelCol={{ span: 24 }} // Ensure the label takes full width
                      wrapperCol={{ span: 24 }} // Ensure the field takes full width
                    >
                      <Field name={`leadsOPS.${index}.callScheduledBy`}>
                        {({ field, form }) => (
                          <Select
                            {...field} // Spread Formik field props to the Select
                            showSearch
                            value={field.value || ''} // Default to an empty string if no value is set
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state
                            style={{ width: '100%' }}
                          >
                            {assignToUsers?.map((user, key) => (
                              <Select.Option key={key} value={`${user?.firstname} ${user?.lastname}`}>
                                {`${user?.firstname} ${user?.lastname}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Client Attendees"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.clientAttendees`}
                        value={clientAttendees[index] || ''}
                        onChange={(e) => {
                          const newClientAttendees = [...clientAttendees];
                          newClientAttendees[index] = e.target.value;
                          setClientAttendees(newClientAttendees);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].clientAttendees`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={6}>
                    <Form.Item label="Blueplanit Attendees"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.blueplanitAttendees`}
                        value={blueplanitAttendees[index] || ''}
                        onChange={(e) => {
                          const newBlueplanitAttendees = [...blueplanitAttendees];
                          newBlueplanitAttendees[index] = e.target.value;
                          setBlueplanitAttendees(newBlueplanitAttendees);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].blueplanitAttendees`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={6}>
                    <Form.Item label="AMS used by Client"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.amsusedbyClient`}
                        value={amsUsedByClient[index] || ''}
                        onChange={(e) => {
                          const newAmsUsedByClient = [...amsUsedByClient];
                          newAmsUsedByClient[index] = e.target.value;
                          setAmsUsedByClient(newAmsUsedByClient);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].amsusedbyClient`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Client Requirements"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.clientRequirements`}
                        value={clientRequirements[index] || ''}
                        onChange={(e) => {
                          const newClientRequirements = [...clientRequirements];
                          newClientRequirements[index] = e.target.value;
                          setClientRequirements(newClientRequirements);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].clientRequirements`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Pros"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.pros`}
                        value={pros[index] || ''}
                        onChange={(e) => {
                          const newPros = [...pros];
                          newPros[index] = e.target.value;
                          setPros(newPros);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].pros`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item label="Cons"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input
                        name={`leadsOPS.${index}.cons`}
                        value={cons[index] || ''}
                        onChange={(e) => {
                          const newCons = [...cons];
                          newCons[index] = e.target.value;
                          setCons(newCons);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].cons`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item>
                      <div>
                        <span style={{ display: 'block', marginBottom: '15px' }}>Call Connected</span> {/* Label above */}
                        <Switch
                          checked={OpsInfo?.callConnected}
                          onChange={(checked) =>
                            handleChange({ target: { name: `leadsOPS.${index}.callConnected`, value: checked } })
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={3}>
                    <Form.Item>
                      <div>
                        <span style={{ display: 'block', marginBottom: '15px' }}>Email Sent</span> {/* Label above */}
                        <Switch
                          checked={OpsInfo?.emailSent}
                          onChange={(checked) =>
                            handleChange({ target: { name: `leadsOPS.${index}.emailSent`, value: checked } })
                          }
                        />
                      </div>
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Remarks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.TextArea
                        name={`leadsOPS.${index}.remarks`}
                        value={remarks[index] || ''}
                        onChange={(e) => {
                          const newRemarks = [...remarks];
                          newRemarks[index] = e.target.value;
                          setRemarks(newRemarks);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsOPS[${index}].remarks`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                </Row>
                <Divider />
              </div>
            ))}
            <Row justify="end" style={{ marginTop: '16px' }}>
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => handleAddRow(arrayHelpers)}
              >
                Add Row
              </Button>
            </Row>
          </>
        )}
      />
      <CommentField label="Comments" name="opsTeamComments" />
    </div>
  );
});

export default OperationsTeam;

