import { createSlice } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
const lastContactedDatesSlice = createSlice({
  name: 'lastContactedDates',
  initialState: [],
  reducers: {
    addLastContactedDate: (state, action) => {
      state.push(action.payload);
    },
    // removeLastContactedDate: (state, action) => {
    //     console.log("latestDate",action.payload)
    //     const deletedTimestamp = dayjs(action.payload).valueOf();
    //     return state.filter(date => dayjs(date).valueOf() !== deletedTimestamp);
    // },
    setLastContactedDates: (state, action) => {
      return action.payload;
    },
  },
});

export const { addLastContactedDate, removeLastContactedDate, setLastContactedDates } = lastContactedDatesSlice.actions;
export default lastContactedDatesSlice.reducer;
