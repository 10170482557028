import React, { useState, useEffect } from "react";
import { useQuery, useMutation, from } from "@apollo/client";
import { Link, useParams, useNavigate } from "react-router-dom";
import { UPDATE_EMPLOYEE } from "../../../Queries/Employees";
import AddNoteBox from "./addNote";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { useSelector } from "react-redux";
import { ClientModelpopup } from "../../../components/modelpopup/clientModelpopup";
const ClientHistory = () => {
 const clientData = useSelector((state) => state.employee.clientHistory);
    
 const { Empid } = useParams();
   

    return (
       <div>
        <div id="client_info_modal" className="card profile-box flex-fill">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="card-title">Client Info</h3>
          {Empid ? <Link
            to="#"
            className="edit-icon"
            data-bs-toggle="modal"
            data-bs-target="#client_history"
          >
            <i className="fa fa-pencil" />
          </Link> : null}
        </div>

        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Name of Client</th>
                <th>Processes</th>
                <th>Role</th>
                <th>Slot No</th>
              </tr>
            </thead>
            <tbody>
              {clientData && clientData.length > 0 ? (
                clientData.map((client, index) => (
                  <tr key={index}>
                    <td>{client.NameOfClient || "N/A"}</td>
                    <td>{client.processes || "N/A"}</td>
                    <td>{client.role || "N/A"}</td>
                    <td>{client.SlotNo || "N/A"}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">No client information available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      <PersonalInformationModelPopup name={"clientNote"} />
      <ClientModelpopup/>
    </div>
    <AddNoteBox name={"clientNote"}/>
    </div>
    );
};

export default ClientHistory;
