// import React from "react";
// import { Applogo } from "../../../Routes/ImagePath";
// import { Link } from "react-router-dom";

// const ChangePassword = () => {
//   return (
//     <div>
//       <div className="account-page">
//         <div className="main-wrapper">
//           <div className="account-content">
//             {/* Account Logo */}
//             <div className="account-logo">
//               <Link to="/admin-dashboard">
//                 <img src={Applogo} alt="Dreamguy's Technologies" />
//               </Link>
//             </div>
//             <div className="account-box">
//               <div className="account-wrapper">
//                 <h3 className="account-title">Change Password</h3>
//                 <form>
//                   <div className="input-block mb-3">
//                     <label className="col-form-label">Old password</label>
//                     <input type="password" className="form-control" />
//                   </div>
//                   <div className="input-block mb-3">
//                     <label className="col-form-label">New password</label>
//                     <input type="password" className="form-control" />
//                   </div>
//                   <div className="input-block mb-3">
//                     <label className="col-form-label">Confirm password</label>
//                     <input type="password" className="form-control" />
//                   </div>
//                   <div className="submit-section mb-4">
//                     <Link
//                       to="/admin-dashboard"
//                       className="btn btn-primary submit-btn"
//                     >
//                       Update Password
//                     </Link>
//                   </div>
//                 </form>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default ChangePassword;
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Applogo } from "../../../Routes/ImagePath";
import { emailrgx } from "../Authentication/RegEx";
import { useMutation } from "@apollo/client";
import { AUTH_RESET_PASSWORD } from "../../../Queries/Users";
const schema = yup.object({
  email: yup
    .string()
    .matches(emailrgx, "Email is required")
    .required("Email is required")
    .trim(),
  password: yup
    .string()
    .min(6, "Password must be at least 6 characters")
    .max(20, "Password must be at most 20 characters")
    .required("Password is required")
    .trim(),
    confirmPassword: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
    token: yup
    .string()
    .required("Confirm Password is required"),
});

const ChangePassword = (props) => {
  const [passwordEye, setPasswordEye] = useState(true); // State for password field
  const [checkUser, setCheckUser] = useState(false); // State for password field
  const [repeatPasswordEye, setRepeatPasswordEye] = useState(true); // State for repeat password field

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const details = localStorage.getItem("loginDetails");
  const loginInfo = JSON.parse(details) || []; // Initialize as an empty array if null

  const [loginState, setLoginState] = useState(loginInfo);
  const [createpassword, { loading }] = useMutation(AUTH_RESET_PASSWORD, {
    onCompleted(data) {
      if (data.createpassword.status === true) {
        // Redirect to login after password change
        navigate("/");
        console.log(data, "AUTH_RESET_PASSWORD");
      } else {
        console.log(data.createpassword.message, "Password reset error");
      }
    },
  });

  // Handle form submission for password change
  const onSubmit = (data) => {
   //email: data.email, password: data.password };

    // Call mutation to reset password
    createpassword({
      variables: {
        email: data.email,
        password: data.password,
        token:data.token,
        confirmPassword: data.confirmPassword,
        isPasswordGenerated: true
      },
    });
  };

  return (
    <div className="account-page">
      <div className="main-wrapper">
        <div className="account-content">
          <div className="container">
            {/* Account Logo */}
            <div className="account-logo">
              <Link to="/admin-dashboard">
                <img src={Applogo} alt="Dreamguy's Technologies" />
              </Link>
            </div>
            {/* /Account Logo */}
            <div className="account-box">
              <div className="account-wrapper">
                <h3 className="account-title">Register</h3>
                <p className="account-subtitle">Access to our dashboard</p>
                {/* Account Form */}
                <div>
                  <form>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Email</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control ${
                              errors?.email ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                        )}
                      />

                      <span className="text-danger">
                        {errors?.email?.message}
                      </span>
                      
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Token</label>
                      <Controller
                        name="token"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <input
                            className={`form-control ${
                              errors?.token ? "error-input" : ""
                            }`}
                            type="text"
                            value={value}
                            onChange={onChange}
                            autoComplete="false"
                          />
                        )}
                      />

                      <span className="text-danger">
                        {errors?.token?.message}
                      </span>
                      
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Password</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={passwordEye ? "password" : "text"}
                              className={`form-control  ${
                                errors?.password ? "error-input" : ""
                              }`}
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() => setPasswordEye(!passwordEye)}
                              className={`fa toggle-password ${
                                passwordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />

                      <span className="text-danger">
                        {errors?.password?.message}
                      </span>
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Repeat Password</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field: { value, onChange } }) => (
                          <div
                            className="pass-group"
                            style={{ position: "relative" }}
                          >
                            <input
                              type={repeatPasswordEye ? "password" : "text"}
                              className={`form-control  ${
                                errors?.confirmPassword ? "error-input" : ""
                              }`}
                              value={value}
                              onChange={onChange}
                              autoComplete="false"
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "5%",
                                top: "30%",
                              }}
                              onClick={() =>
                                setRepeatPasswordEye(!repeatPasswordEye)
                              }
                              className={`fa toggle-password ${
                                repeatPasswordEye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                        defaultValue=""
                      />

                      <span className="text-danger">
                        {errors?. confirmPassword?.message}
                      </span>
                    </div>
                    <div className="input-block text-center">
                      <Link
                        to="#"
                        className="btn btn-primary account-btn"
                        onClick={handleSubmit(onSubmit)}
                      >
                        Register
                      </Link>
                    </div>
                  </form>

                  <div className="account-footer">
                    <p>
                      Already have an account? <Link to="/">Login</Link>
                    </p>
                  </div>
                </div>
                {/* /Account Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
