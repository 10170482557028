import React, { useState, useEffect } from "react";
import { useMutation, useQuery } from '@apollo/client';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { setClients, addClient, updateClient } from "../../../Slices/clientSlice";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ClientsFilter from "../../../components/ClientsFilter";
import { AllClientAddPopup } from "../../../components/modelpopup/AddClientModal";
import { AllClientEditPopup } from "../../../components/modelpopup/UpdateClientModal";
// import { GET_ALL_CLIENTS } from "../../../Queries/Users";
// import { GET_ALL_ERROR_LOGS } from "../../../Queries/ErrorLog";
import { useErrorLogger } from "../../../utils";
import { Spin } from "antd";


const Clients = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [rowInfo, setRowInfo] = useState({});
  // const {errorlogs} = useQuery(GET_ALL_ERROR_LOGS);
  // const { data, loading, error } = useQuery(GET_ALL_CLIENTS);  
  const clients = useSelector(state => state.clients.clients);
  console.log("clients", clients)
  const { logError } = useErrorLogger();
  // useEffect(() => {
  //   try {
  //     if (data) {
  //       if (clients.length < 2) {
  //         const newClients = [...clients, ...data.getAllClients.response];
  //         dispatch(setClients(newClients)); 
  //       }
  //     }
  //   } catch (error) {
  //     logError("clients", "useEffect - Fetch Clients", error.message);
  //   }
  // }, [data, dispatch, clients]);


  // useEffect(() => {
  //   try {
  //     if (data) {
  //       if (clients.length < 1) {
  //         // When the clients list is empty, just add the fetched clients
  //         dispatch(setClients(data.getAllClients.response)); 
  //       } else {
  //         // If the clients list already exists, prepend new clients at the top
  //         const newClients = data.getAllClients.response;

  //         // Ensure the new clients are at the top, followed by any previous clients
  //         dispatch(setClients([...newClients, ...clients])); 
  //       }
  //     }
  //   } catch (error) {
  //     logError("clients", "useEffect - Fetch Clients", error.message);
  //   }
  // }, [data, dispatch, clients]);


  const handleEdit = (row) => {
    setRowInfo(row);
    setOpen(true);
  };


  const getInitials = (name) => {

    const names = name.split(' ');
    if (names?.length > 1) {
      return `${names[0].charAt(0).toUpperCase()}${names[1].charAt(0).toUpperCase()}`;
    }
    return names[0] ? names[0].charAt(0).toUpperCase() : 'N/A';

  };

  // if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Clients"
            title="Dashboard"
            subtitle="Clients"
            modal="#add_client"
            name="Add Client"
            Linkname="/clients"
            Linkname1="/clients-list"
            view="grid"
          />

          <div className="row staff-grid-row">

            {clients?.map((client) => {
              const isEmpty = client?.clientName && client?.department;
              const isActive = client?.status === 'Active';
              return (
                <div
                  key={client?.id}
                  className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                >
                  <div className={`profile-widget ${isEmpty ? 'profile-widget' : ''}`} style={{ position: 'relative' }}>
                    {/* Edit Icon */}
                    <div
                      style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                      }}
                    >
                      <Link
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_client"
                        onClick={() => handleEdit(client)}
                      >
                        <i className="fa fa-pencil fa-xs m-r-5" />
                      </Link>
                    </div>


                    <div className="profile-img" style={{ position: 'relative' }}>
                      <Link to={`/clients/${client?.id}`} className="avatar">
                        {client?.avatar ? (
                          <img alt="" src={client?.avatar} />
                        ) : (
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              width: '70px',
                              height: '70px',
                              borderRadius: '50%',
                              backgroundColor: '#ccc',
                              color: 'white',
                              fontSize: '20px',
                            }}
                          >
                            {client?.clientName ? getInitials(client?.clientName) : 'N/A'}
                          </div>
                        )}
                      </Link>
                      <div
                        style={{
                          width: '12px',
                          height: '12px',
                          borderRadius: '50%',
                          backgroundColor: isActive ? 'green' : 'red',
                          position: 'absolute',
                          bottom: '8px',
                          right: '8px',
                          border: '2px solid white',
                        }}
                      ></div>
                    </div>

                    {/* Client Information */}
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      <Link to={`/clients/${client?.id}`}>{client?.companyName || ''}</Link>
                    </h4>
                    <h5 className="user-name m-t-10 mb-0 text-ellipsis">
                      <Link to={`/clients/${client?.id}`}>{client?.clientName?.length > 11 ? `${client?.clientName.substring(0, 10)}...` : client?.clientName}</Link>
                    </h5>
                    <div className="small text-muted">{client?.department}</div>
                    <Link
                      to={`/clients/${client?.id}`}
                      className="btn btn-white btn-sm m-t-10 justify-content-center"
                    >
                      View Profile
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {/* Modals */}
      <AllClientAddPopup />
      <AllClientEditPopup clientData={rowInfo} />
    </div>
  );
};

export default Clients;
