import { gql } from "@apollo/client";


export const ATTENDENCE = gql(`
mutation($input:AttendanceInput){
    AttendenceLogin(input:$input){
    status
    message
    response{
        id
        punchIn
        punchOut
        elapsedTime
        status
    }
  }

}
 `)

export const GET_LAST_ATTENDENCE_RECORD = gql(`
    query($id:String){
    LastAttendence(id:$id){
      status
      message
      response{
        id
        empId
        punchIn
        punchOut
        status
        elapsedTime
      }
      
    }

}
    `)

export const UPDATE_PUNCHOUT = gql(`
    mutation($id:String,$input:AttendanceInput)
    {
    Attendenceupdate(id:$id,input:$input){
    status
    message
    }
}
    `)  


    export const GET_ALL_RECORDS=gql(`
      query($id:String) {
  AllAttendence(id:$id) {
    status
    message
    response {
      punchIn
      punchOut
      empId
      status
    }
  }
}
      
      `)