import { Row, Col, Form, Button, Select, Space, Input, Radio, Typography } from "antd";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as DataBucket from '../../../databucket';
import { GET_ALL_INSURANCE_CARRIERS } from "../../../Queries/Users";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;

const NewQuoteInformation = (props) => {
    const { employeeInfo, handleChange, handleAddQuote, handleDeleteQuote } = props;
    const Eligibility = [
        { value: "Eligible", label: "Eligible" },
        { value: "Ineligible", label: "Ineligible" },
        { value: "UW Review", label: "UW Review" }
    ]
    // const [quoteInfo, setQuoteInfo] = useState([{
    //     policy: "", insuranceCarrier: "", eligibility: "", quotedPremium: "", remarks: ""
    // }])
    const { data, refetch } = useQuery(GET_ALL_INSURANCE_CARRIERS);
    let insuranceCarriers = data?.getAllInsuranceCarriers?.response || [];
    console.log("insurance", insuranceCarriers)
    const options = useSelector((state) => state.dataBucket.databucket);
    // const renderOptions = (options, criteria, fallbackData = []) => {
    //     console.log("fallbackData",fallbackData)
       
    //     return options?.reduce((acc, item) => {
    //         // Check if the item name matches the criteria
    //         if (item.name === criteria.name && item.data) {
    //             // Filter subItems based on active status and flag === true
    //             const filteredSubItems = item.data.filter(
    //                 (subItem) => subItem.status === "active" && subItem.flag === true
    //             );
    //             // Accumulate valid subItems into the result array
    //             acc.push(...filteredSubItems);
    //         }
    //         return acc;
    //     }, []).map((subItem, index) => (
    //         <Option key={index} value={subItem?._id}>
    //             {subItem?.name}
    //         </Option>
    //     )) ||
    //      fallbackData?.map((item, index) => (
    //         <Option key={index} value={item}>
    //             {item}
    //         </Option>
    //     ));
    // };
    return (
        <>
            <div className="row">
                <Form layout="vertical">
                    <div className="form-scroll">
                        <h3 className="card-title">New Quote Information</h3>
                        <div className="mb-3">
                            <hr />
                        </div>
                        {employeeInfo.map((quote,index)=>(
                            <Row key={index} gutter={16}>
                            <Col span={3.5}>
                                <Form.Item label=" Quote No.">
                                    {index + 1}
                                </Form.Item>
                            </Col>
                            <Col span={5}>
                                <Form.Item label="Policy" name={`newQuoteInformation.${index}.policy`} rules={[{ required: true, message: 'Please select a ppoliy' },]}>
                                    <Select
                                        name={`employeeInfo.${index}.policy`}
                                        //onChange={(value) => handleInputChange(value, 'policy', index)} // Adjust to match the onChange handler in Ant Design
                                        value={quote.policy || ''}
                                        onChange={(value) => handleChange({ 
                                            target: { 
                                              name: 'newQuoteInformation', 
                                              value: value, // Pass the selected value directly
                                              index: index, // Include the index to identify which policy to update
                                              field: 'policy' // Specify the field to update
                                            }
                                          })}
                                        // placeholder="Select a policy" // Use placeholder for displayEmpty equivalent
                                        aria-label="Without label"
                                    >
                                        {renderOptions(options, { name: "PROPERTY_TYPE_CONFIG_POLICY_QUOTE" }, DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item label="Insurance Carrier" name={`newQuoteInformation.${index}.insuranceCarrier`} rules={[{ required: true, message: 'Please select a insurance carrier' },]}>
                                    <Select
                                        name={`employeeInfo.${index}.insuranceCarrier`}
                                        //onChange={(value) => handleInputChange(value, 'insuranceCarrier', index)} // Adjust to match the onChange handler in Ant Design
                                        value={quote.insuranceCarrier || ''}
                                        onChange={(value) => handleChange({ 
                                            target: { 
                                              name: 'newQuoteInformation', 
                                              value: value, // Pass the selected value directly
                                              index: index, // Include the index to identify which policy to update
                                              field: 'insuranceCarrier' // Specify the field to update
                                            }
                                          })}
                                        //placeholder="Select a policy" // Use placeholder for displayEmpty equivalent
                                        aria-label="Without label"
                                    >
                                        {insuranceCarriers.filter(item => item?.status == 'Active').map((carrierItem, key) => {
                                            return (<Select.Option key={key} value={carrierItem?.carrierName}>{carrierItem?.carrierName}</Select.Option>)
                                        })}
                                        {/* {((employeeInfo?.processType === 'Policy Quoting' && employeeInfo?.department === 'Commercial Lines')
                                            ? DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE
                                            : DataBucket.PROPERTY_TYPE_CONFIG).map((carrierItem, key) => (
                                                <Select.Option key={key} value={carrierItem}>
                                                    {carrierItem}
                                                </Select.Option>
                                            ))} */}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={3.5}>
                                <Form.Item label="Eligibility" name={`newQuoteInformation.${index}.eligibility`} rules={[{ required: true, message: 'Please select a eligibility' },]}>
                                    <Select
                                        name={`employeeInfo.${index}.eligibility`}
                                        //onChange={(value) => handleInputChange(value, 'eligibility', index)} // Use e.target.value for the selected radio button value
                                        value={employeeInfo[index]?.eligibility || ''}
                                        onChange={(value) => handleChange({ 
                                            target: { 
                                              name: 'newQuoteInformation', 
                                              value: value, // Pass the selected value directly
                                              index: index, // Include the index to identify which policy to update
                                              field: 'eligibility' // Specify the field to update
                                            }
                                          })}
                                        //style={{ paddingTop: 16 }}
                                        aria-label="Without label"
                                    >
                                        {Eligibility.map((item) => {
                                            return (<Select.Option key={item.label} value={item.value}>{item.label}</Select.Option>)
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item rules={[{ required: true, message: 'Please select a eligibility' },]}>
                                    {employeeInfo[index]?.eligibility === 'Eligible' ? (
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Typography.Text>Quoted Premium</Typography.Text>
                                            <Input
                                                name={`quoteInfo.${index}.quotedPremium`}
                                                //onChange={(e) => handleInputChange(e.target.value, 'quotedPremium', index)} // Update quotedPremium field
                                                value={employeeInfo[index]?.quotedPremium || ''}
                                                onChange={(e) => handleChange({ 
                                                    target: { 
                                                      name: 'newQuoteInformation', 
                                                      value: e.target.value, // Get the input value
                                                      index: index, // Include the index to identify which policy to update
                                                      field: 'quotedPremium' // Specify the field to update
                                                    }
                                                  })}
                                            />
                                        </Space>
                                    ) : (
                                        <Space direction="vertical" style={{ width: '100%' }}>
                                            <Typography.Text>Remarks</Typography.Text>
                                            <Input
                                                name={`quoteInfo.${index}.remarks`}
                                                //onChange={(e) => handleInputChange(e.target.value, 'remarks', index)} // Update remarks field
                                                value={employeeInfo[index]?.remarks || ''}
                                                onChange={(e) => handleChange({ 
                                                    target: { 
                                                      name: 'newQuoteInformation', 
                                                      value: e.target.value, // Get the input value
                                                      index: index, // Include the index to identify which policy to update
                                                      field: 'remarks' // Specify the field to update
                                                    }
                                                  })}
                                            />
                                        </Space>
                                    )}
                                </Form.Item>
                            </Col>

                            <Col span={1} style={{ marginTop: "25px", display: 'flex', alignItems: 'center' }}>
                                <Form.Item>
                                    <Link to="#" className="delete-icon" onClick={() => handleDeleteQuote(index)}>
                                        <i className="fa-regular fa-trash-can" />
                                    </Link>
                                </Form.Item>
                            </Col>
                        </Row>
                        ))}

                        <div className="add-more">
                            <Link to="#" onClick={handleAddQuote}>
                                <i className="fa-solid fa-plus-circle" /> Add More
                            </Link>
                        </div>
                    </div>
                </Form>
            </div>
        </>
    )
}
export default NewQuoteInformation;