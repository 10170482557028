import React, { useEffect, useState } from "react";
// import { Link } from "react-router-dom";
import axios from "axios";
import {  Table ,Tooltip,Modal } from 'antd';
import { 
  Form, 
  Input, 
  Select, 
  DatePicker, 
  Row, 
  Col, 
  Button, 
  Space, 
  Divider,
  AutoComplete,
  Empty ,
  message
} from 'antd';
import { ReloadOutlined,SearchOutlined,FilterOutlined, PlusOutlined } from '@ant-design/icons'; 
// import SearchIcon from '@mui/icons-material/Search';
// import FilterListIcon from '@mui/icons-material/FilterList';
// import Autocomplete from '@mui/material/Autocomplete';
import { Avatar_02, Avatar_05 } from "../../../Routes/ImagePath";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import TicketModelPopup from "../../../components/modelpopup/TicketModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import TicketFilter from "../../../components/TicketFilter";

import CreateSales from "./CreateSale/CreateSales";

import { base_url } from "../../../base_urls";
import { GET_ALL_LEADS,LEADS_FILTERS,CREATE_LEAD_FILTER ,GET_ALL_LEAD_FILTERS_BY_ID} from "../../../Queries/Leads";
import { useQuery ,useMutation} from '@apollo/client';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getAllSales,updateSales } from "../../../Slices/salesSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import dayjs from 'dayjs'
const { Search } = Input;


const renderWithTooltip = (text, maxLength) => {
  // Check if text is valid and not null or undefined
  if (!text) return null;

  return (
    <Tooltip title={text}>
      {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
    </Tooltip>
  );
};

const AllSales = () => {

  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  const dispatch = useDispatch()

  const [showCreateSaleForm, setShowCreateSaleForm] = useState(false);

  const [data, setData] = useState([])
  const [rowsCount, setRowsCount] = useState(50)

  const [filterSno,setFilterSno] = useState('');
  const [filterAgencyName, setFilterAgencyName] = useState([]);
  const [filterState, setFilterState] = useState([]);
  const [filterCity, setFilterCity] = useState([]);
  const [filterChannel, setFilterChannel] = useState([]);
  const [filterAssignedTo, setFilterAssignedTo] = useState([]);
  const [filterTimeZone, setFilterTimeZone] = useState([]);
  const [filterFollowup, setFilterFollowup] = useState([]);
  const [filterSuccessfull, setFilterSuccessfull] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterPriority, setFilterPriority] = useState([]);
  const [filterStage, setFilterStage] = useState([]);
  const [filterScheduledOPSStatus, setFilterScheduledOPSStatus] = useState([]);

  const [leadfilterObject, setleadfilterObject] = useState({});
  const [leadfilterObjectTemp, setleadfilterObjectTemp] = useState({});
  const [sortObject, setSortObject] = useState({});

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);

  const [filterSearch, setFilterSearch] = useState('');

  useEffect(() => {
    axios.get(base_url + "/ticket.json").then((res) => setUsers(res.data));
  }, []);

  const { data: leadsData, refetch, loading, error } = useQuery(GET_ALL_LEADS, {
    variables: { page, pageSize: pageSize, filters: leadfilterObject, sort: sortObject },
    fetchPolicy: 'no-cache',
  });
   dispatch(setLoader(loading))

  const datacontent = leadsData ? leadsData.getAllLeads : [];
  // console.log("DATACONTENT",datacontent)
  const { data: filterdata, } = useQuery(LEADS_FILTERS, { variables: { filters: leadfilterObject } });



  let leadfilterdata = filterdata ? filterdata.getLeadFilters : [];
  // let userscontent = userdata ? userdata.getAllUsers : [];
  // console.log('filterdatafilterdata', filterdata)
  // console.log('leadfilterObject...', leadfilterdata);

  useEffect(() => {
    if (leadfilterdata?.responsestatus == 200) {
      setFilterAgencyName(leadfilterdata?.agencyName)
      setFilterState(leadfilterdata?.state)
      setFilterCity(leadfilterdata?.city)
      setFilterSno(leadfilterdata?.leadID)
      setFilterChannel(leadfilterdata?.channel)
      setFilterTimeZone(leadfilterdata?.timeZone)
      setFilterAssignedTo(leadfilterdata?.assignedTo)
      setFilterFollowup(leadfilterdata?.followup)
      setFilterSuccessfull(leadfilterdata?.successfull)
      setFilterStatus(leadfilterdata?.status)
      setFilterPriority(leadfilterdata?.priority)
      setFilterStage(leadfilterdata?.stage)
      setFilterScheduledOPSStatus(leadfilterdata?.callscheduledwithOPs)
      // setFilterCity(filterdata?.city?.map((str, index) => ({ value: str })))
    }

  }, [leadfilterdata]);

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const fetchData = async () => {
    refetch()
   
    if (datacontent?.response) {
      setRowsCount(datacontent?.count)
      setData(datacontent?.response)

      await dispatch(getAllSales(datacontent?.response))
  }
  };

  useEffect(() => {
    fetchData()
      
  }, [datacontent, dispatch]);
  console.log('datacontent', datacontent?.response, data)

  const tableData=useSelector((state) => state?.sales?.sales);

  console.log("tableData",tableData)
  // console.log("redux leads",[...data,...useSelector((state) => state.sales.sales)])

  const handleDetailPage = (rowid) => {
    // console.log('rowid', rowid,)
    sessionStorage.setItem('salesFilter', JSON.stringify(leadfilterObject));
    sessionStorage.setItem('salesSort',JSON.stringify(sortObject));
    navigate(`/sales/${rowid}`)
  }

  function formatDateTime(timestamp) {
    // console.log('timestamptimestamp', timestamp, timestamp != null)
    if (timestamp != null) {
      const date = new Date(parseInt(timestamp));
      const formattedDate = date.toLocaleString('en-IN', {
        // timeZone: 'UTC',
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        // second: 'numeric',
        hour12: true,
      });
      return formattedDate;
    } else {
      return ''
    }
  }

  const columns = [
    {
      title:'S.No',
      dataIndex: 'leadID',
      key: 'leadID',
      ellipsis: {
        showTitle: false,
      },
      width: 70, // Adjust column width
      renderCell: (params) => parseInt(params.value),
      sorter: true, 
    },
    {
      title: 'Agency Name',
      dataIndex: 'agencyName',
      key: 'agencyName',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Stage',
      dataIndex: 'stage',
      key: 'stage',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Follow-Up',
      dataIndex: 'followupDate',
      key: 'followupDate',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      width: 100,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={formatDateTime(text)}// Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Time Zone',
      dataIndex: 'timeZone',
      key: 'timeZone',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdatedDate',
      key: 'lastUpdatedDate',
      ellipsis: {
        showTitle: false,
      },
      width: 100,
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={formatDateTime(text)} // Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Last Contacted',
      dataIndex: 'lastContactedDate',
      key: 'lastContactedDate',
      ellipsis: {
        showTitle: false,
      },
      width:100,
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={formatDateTime(text)} // Full name will be displayed on hover
          >
            {formatDateTime(text)}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Channel',
      dataIndex: 'channel',
      key: 'channel',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Assigned To',
      dataIndex: 'assignedTo',
      key: 'assignedTo',
      ellipsis: {
        showTitle: false,
      },
      sorter: true,
      render: (text) => (
        <Tooltip title={text} placement="topLeft">
          <span 
            style={{
              fontSize: '12px', // Add font size
              textOverflow: 'ellipsis', 
              whiteSpace: 'nowrap', 
              overflow: 'hidden'
            }} 
            title={text} // Full name will be displayed on hover
          >
            {text}
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'Edit',
      key: 'edit',
      render: (text, record) => (
        <button
          onClick={() => handleDetailPage(record?.id)}
          className="btn btn-primary"
          style={{ fontSize: '10px', padding: '4px 8px' }}
        >
          Edit
        </button>
      ),
    },
  ];

  const [popupStatus, setSetPopupStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('');

  const [allFilters, setAllFilters] = useState([]);
  const [selectedFilterName, setSelectedFilterName] = useState({});
  const [saveFilterCheck, setSaveFilterCheck] = useState(false);
  const [filterName, setFilterName] = useState(false);

  const handleDialogClose = () => {
    setSetPopupStatus(false);
  };

  const [prevVisibility, setDivVisibility] = useState(false);

  const handleFilters = (event) => {
    setDivVisibility((prevVisibility) => !prevVisibility);
    setSaveFilterCheck(false)
    setFilterName('')
  };

  const  handleCloseFilter=()=>{
    setDivVisibility(false)
    resetFilters();
  }

  const loadAgencyNames = (event, newValue) => {
    if (event?.target?.value.length > 2) {
      let AllAgencyNames = leadfilterdata?.agencyName
      const searchedAgencyNames = AllAgencyNames.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterAgencyName(searchedAgencyNames)
    } else {
      setFilterAgencyName([])
    }
  };
  const loadStates = (event, newValue) => {

    if (event?.target?.value.length > 2) {
      let AllStates = leadfilterdata?.state
      const searchedStates = AllStates.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterState(searchedStates)
    } else {
      setFilterState([])
    }
  };
  const loadCities = (event, newValue) => {
    if (event?.target?.value.length > 2) {
      let AllCities = leadfilterdata?.city
      const searchedCities = AllCities.filter(str => str.toLowerCase()?.includes(event?.target?.value.toLowerCase()));
      setFilterCity(searchedCities)
    } else {
      setFilterCity([])
    }
  };

  const handleSavedFiltersChange = (filterName) => {
    // Find the selected filter object based on filterName
    const selectedFilter = allFilters.find((filter) => filter.filterName === filterName);
   
    if (selectedFilter) {
      try {
        // Parse filterString from the selected filter
        setleadfilterObject(JSON.parse(selectedFilter.filterString));
        setSelectedFilterName(selectedFilter);  // Set the selected filter object
        console.log(JSON.parse(selectedFilter.filterString))
        setleadfilterObjectTemp(JSON.parse(selectedFilter.filterString))
  
      } catch (error) {
        console.error("Failed to parse filterString:", error);
      }
    } else {
      // Reset the state if no valid filter is selected
      setleadfilterObject({});
      setSelectedFilterName({});
    }
  
    setSaveFilterCheck(false);
    setFilterName('');
  };
  

  const handleFilterSnoChange = (event,newValue)=>{
    setleadfilterObjectTemp((prvState) => ({ ...prvState, leadID: event.target.value }));
    setSaveFilterCheck(false)
    setFilterName('')

  };

  const handleFilterAgencyChange = (value) => {
   
    setleadfilterObjectTemp((prvState) => ({ ...prvState, agencyName:value }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterStateChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, state: value }));
    setFilterState([])
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterCityChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, city: value }));
    setFilterCity([])
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterChannelChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, channel: value }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterAssignedToChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, assignedTo: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterTimezoneChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, timeZone: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  const handleFilterFollowUpChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, followup: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterSuccessfullChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, successfull: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterStageChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, stage: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterOPSCallChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, callscheduledwithOPs: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterStatusChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, status: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  const handleFilterPriorityChange = (value) => {
    setleadfilterObjectTemp((prvState) => ({ ...prvState, priority: value }));
    setSaveFilterCheck(false);
    setFilterName('');
  };
  
  //updated
  const handleFilterLastUpdatedFromChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastUpdatedFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastUpdatedToChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastUpdatedTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  //contacted
  const handleFilterLastContactedFromChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastContactedFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastContactedToChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastContactedTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  //followup
  const handleFilterLastFollowupFromChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastFollowupFrom: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };
  const handleFilterLastFollowupToChange = (event, newValue) => {
    const formattedDate = event ? dayjs(event).format('YYYY-MM-DD') : null;
    setleadfilterObjectTemp((prvState) => ({ ...prvState, lastFollowupTo: formattedDate }));
    setSaveFilterCheck(false)
    setFilterName('')
  };

  const setFilters = () => {
    if (!leadfilterObjectTemp || Object.keys(leadfilterObjectTemp).length === 0) {
      message.error('Please enter filter criteria before applying.');
      return; // Exit if no criteria are provided
    }
    setPage(1)
    console.log(leadfilterObjectTemp);
    setleadfilterObject(leadfilterObjectTemp);
  };

  const resetFilters = () => {
    setleadfilterObject({})
    setleadfilterObjectTemp({})
    setSelectedFilterName({})
    setFilterSearch('')
    setSaveFilterCheck(false)
    setPage(1)
  }

  const handleTableChange = (pagination, filters, sorter) => {
    // Update sortObject when the sorting changes
    const newSortObject = sorter?.order
      ? { [sorter.field]: sorter.order === 'ascend' ? 'asc' : 'desc' }
      : {};
    setSortObject(newSortObject);

    setPage(pagination.current);
    setPageSize(pagination.pageSize);

    // Refetch data with new sorting and pagination
    refetch({
      page: pagination.current,
      pageSize: pagination.pageSize,
      sort: newSortObject,
    });
  };

  const CustomNoRowsOverlay = () => (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <Empty description="No rows found" />
    </div>
  );

  // const data = [
  //   {
  //     id: 1,
  //     image: Avatar_02,
  //     name: "John Doe",
  //     ticketid: "TKT-0001",
  //     ticketsubject: "Internet Issue",
  //     createddate: "5 Jan 2023 07:21 AM",
  //     lastreply: "5 Jan 2023 11.12 AM	",
  //     priority: "High",
  //     status: "New",
  //   },
  //   {
  //     id: 2,
  //     image: Avatar_05,
  //     name: "Catherine Manseau",
  //     ticketid: "TKT-0001",
  //     ticketsubject: "Laptop Issue",
  //     createddate: "5 Jan 2023 07:21 AM",
  //     lastreply: "5 Jan 2023 11.12 AM	",
  //     priority: "High",
  //     status: "New",
  //   },
  // ];

  
  // const columns = [
  //   {
  //     title: "#",
  //     dataIndex: "id",
  //     sorter: (a, b) => a?.id.length - b?.id.length,
  //   },
  //   {
  //     title: "Ticket Id",
  //     dataIndex: "ticketid",
  //     render: () => (
  //       <Link
  //         onClick={() => localStorage.setItem("minheight", "true")}
  //         to="/ticket-view"
  //       >
  //         #TKT-0001
  //       </Link>
  //     ),
  //     sorter: (a, b) => a.ticketid.length - b.ticketid.length,
  //   },

  //   {
  //     title: "Ticket Subject",
  //     dataIndex: "ticketsubject",
  //     sorter: (a, b) => a.ticketsubject.length - b.ticketsubject.length,
  //   },
  //   {
  //     title: "Assigned Staff",
  //     dataIndex: "name",
  //     render: (text, record) => (
  //       <span className="table-avatar">
  //         <Link to="/profile" className="avatar">
  //           <img alt="" src={record.image} />
  //         </Link>
  //         <Link to="/profile">{text}</Link>
  //       </span>
  //     ),
  //     sorter: (a, b) => a.name.length - b.name.length,
  //   },
  //   {
  //     title: "Created Date",
  //     dataIndex: "createddate",
  //     sorter: (a, b) => a.createddate.length - b.createddate.length,
  //   },

  //   {
  //     title: "Last Reply",
  //     dataIndex: "lastreply",
  //     sorter: (a, b) => a.lastreply.length - b.lastreply.length,
  //   },
  //   {
  //     title: "Priority",
  //     dataIndex: "priority",
  //     render: () => (
  //       <div className="dropdown action-label">
  //         <Link
  //           className="btn btn-white btn-sm btn-rounded dropdown-toggle"
  //           to="#"
  //           data-bs-toggle="dropdown"
  //           aria-expanded="false"
  //         >
  //           <i className="far fa-dot-circle text-danger" /> High{" "}
  //         </Link>
  //         <div className="dropdown-menu dropdown-menu-right">
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-danger" /> High
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-warning" /> Medium
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-success" /> Low
  //           </Link>
  //         </div>
  //       </div>
  //     ),
  //     sorter: (a, b) => a.priority.length - b.priority.length,
  //   },
  //   {
  //     title: "Status",
  //     dataIndex: "status",
  //     render: () => (
  //       <div className="dropdown action-label text-center">
  //         <Link
  //           className="btn btn-white btn-sm btn-rounded dropdown-toggle"
  //           to="#"
  //           data-bs-toggle="dropdown"
  //           aria-expanded="false"
  //         >
  //           <i className="far fa-dot-circle text-danger" /> New
  //         </Link>
  //         <div className="dropdown-menu dropdown-menu-right">
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-info" /> Open
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-info" /> Reopened
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-danger" /> On Hold
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-success" /> Closed
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-success" /> In Progress
  //           </Link>
  //           <Link className="dropdown-item" to="#">
  //             <i className="far fa-dot-circle text-danger" /> Cancelled
  //           </Link>
  //         </div>
  //       </div>
  //     ),
  //     sorter: (a, b) => a.status.length - b.status.length,
  //   },
  //   {
  //     title: "Action",
  //     render: () => (
  //       <div className="dropdown dropdown-action text-end">
  //         <Link
  //           to="#"
  //           className="action-icon dropdown-toggle"
  //           data-bs-toggle="dropdown"
  //           aria-expanded="false"
  //         >
  //           <i className="material-icons">more_vert</i>
  //         </Link>
  //         <div className="dropdown-menu dropdown-menu-right">
  //           <Link
  //             className="dropdown-item"
  //             to="#"
  //             data-bs-toggle="modal"
  //             data-bs-target="#edit_ticket"
  //           >
  //             <i className="fa fa-pencil m-r-5" /> Edit
  //           </Link>
  //           <Link
  //             className="dropdown-item"
  //             to="#"
  //             data-bs-toggle="modal"
  //             data-bs-target="#delete"
  //           >
  //             <i className="fa fa-trash m-r-5" /> Delete
  //           </Link>
  //         </div>
  //       </div>
  //     ),
  //     sorter: true,
  //   },
  // ];

  const [getAllLeadFilterByID, { }] = useMutation(GET_ALL_LEAD_FILTERS_BY_ID, {
    onCompleted(data) {
      // console.log('getAllLeadFilterByID-->', data?.getAllLeadFilterByID)
      // setLeadWorkHistoryDetails(data.getAllLeadWorkHistoryByID)
      setAllFilters(data?.getAllLeadFilterByID)
    },
  });
  useEffect(() => {
    // getAllWorkHistoryByID({ variables: workHistoryObj });
    getAllLeadFilterByID({ variables: { userID: parseJwt(token)?.id } });

  }, []);

  const [createLeadFilters, { }] = useMutation(CREATE_LEAD_FILTER, {
    onCompleted(data) {
      // console.log(data.createLeadFilters?.id, 'data')
      setSetPopupStatus(true);
      let submitResponse = data?.createLeadFilters && "Filter Created Successfully!" || 'Error'
      setSubmitStatus(submitResponse)

      if (data?.createLeadFilters) {
        const newFilter = data.createLeadFilters;
        setAllFilters(prevFilters => [...prevFilters, newFilter]);  // Assuming filters is the state holding the list of filters
      }
    },
  });

  const saveFilterCheckHandler = () => {
        // Check if leadfilterObject is empty
        if (!leadfilterObject || Object.keys(leadfilterObject).length === 0) {
          message.error('Please enter filter criteria before saving.');
          return; // Exit the function if the object is empty
        }
    setSaveFilterCheck(!saveFilterCheck)
    setFilterName('')
  }

  const handlefilterNameChange = (e) => {
    setFilterName(e.target.value)
  }

  const handleSaveFilter = () => {
  
    const filterObject = {
      userID: parseJwt(token)?.id,
      filterName: filterName,
      filterString: JSON.stringify(leadfilterObject),
      filterStatus: true,
    };
  
    // Check if filterName is also provided
    if (!filterName) {
       message.error('Please enter a name for the filter.');
      return;
    }
    
    // Check for duplicate filter name in allFilters
    const isDuplicate = allFilters.some(
      (filter) => filter.filterName.toLowerCase() === filterName.toLowerCase()
    );
    if (isDuplicate) {
      message.error({
        content: "A filter with the same name already exists. Please choose a different name.",
        duration: 5,
        className: 'alert alert-danger text-center', 
      });
      // alert('A filter with the same name already exists. Please choose a different name.');
      return;
    }
    console.log('leadfilterObject', leadfilterObject);
    createLeadFilters({ variables: filterObject });
    
    setFilterName('');
  };

  const handleSearchChange = (event) => {
    let searchText = event.target.value
    setFilterSearch(searchText)
    
    if (searchText?.length == 0) {
      setleadfilterObject((prvState) => ({ ...prvState, search: '' }));

    }

  }

  const handleSearchClick = () => {
    setleadfilterObject((prvState) => ({ ...prvState, search: filterSearch }));
  }

  const handleOpenForm = () => {
    console.log('handleOpenForm')
    setShowCreateSaleForm(true);
  };

  const handleCloseForm = () => {
    setShowCreateSaleForm(false);
  };
  
  return (
    <>
      {showCreateSaleForm ? (
        <div className="page-wrapper">
          <div className="content container-fluid">
            {/* Button placed above the form */}
            <div style={{ textAlign: "right", marginBottom: "1rem" }}>
              <Button
                type="default"
                style={{ color: "#ffffff", backgroundColor:'#FF0000' }}
                onClick={handleCloseForm}
              >
                Close
              </Button>
            </div>
            {/* CreateSales form */}
            <CreateSales onClose={handleCloseForm} fetchData={fetchData} />
          </div>
        </div>

          ) :
            (
              <>
              <Modal
                  title="Lead Status"
                  open={popupStatus} // Use open instead of visible
                  onCancel={handleDialogClose}
                  footer={[
                    <Button key="ok" type="primary" onClick={handleDialogClose}>
                      OK
                    </Button>
                  ]}
              >
                <p>{submitStatus}</p>
              </Modal>
              <div className="page-wrapper">
                {/* Page Content */}
                <div className="content container-fluid">
                  {/* Page Header */}
                  {/* <Breadcrumbs
                    maintitle="Sales"
                    title="Dashboard"
                    subtitle="Sales"
                    modal="#add_sale"
                    name="Add Sale"
                  /> */}
      
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-group m-b-30">
                        {users?.map((user, index) => (
                          <div className="card" key={index}>
                            <div className="card-body">
                              <div className="d-flex justify-content-between mb-3">
                                <div>
                                  <span className="d-block">{user.title}</span>
                                </div>
                                <div>
                                  <span
                                    className={
                                      user.percentage.includes("-")
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  >
                                    {user.percentage}
                                  </span>
                                </div>
                              </div>
                              <h3 className="mb-3">{user.value}</h3>
                              <div className="progress mb-2" style={{ height: "5px" }}>
                                <div
                                  className="progress-bar bg-primary"
                                  role="progressbar"
                                  style={{ width: "70%" }}
                                  aria-valuenow={40}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
      
                  {/* <TicketFilter /> */}
                  <Form layout="inline" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end',gap: '0px',  marginBottom: '10px'  }}>
                    <Form.Item>
                      <Input.Search
                        placeholder="Search"
                        value={filterSearch}
                        onSearch={handleSearchClick}
                        onChange={handleSearchChange}
                        allowClear
                        style={{ width: 200 }}
                        enterButton={<i className="fa fa-search" />}
                      />
                    </Form.Item>
                    <Form.Item>
                      <Select
                        value={selectedFilterName?.filterName || undefined}  // Use filterName for the value
                        onChange={handleSavedFiltersChange}
                        style={{ backgroundColor: '#0000000a', width: 200 }}
                        placeholder="Saved Filters"
                      >
                        {allFilters?.map((option) => (
                          <Select.Option key={option?.id} value={option.filterName}>
                            {option.filterName}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{  backgroundColor: '#4D5154', borderRadius: '20px', color: 'white' }}
                        icon={<FilterOutlined />}
                        onClick={handleFilters}
                      >
                        Filter
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button
                        type="primary"
                        style={{
                          backgroundColor: "#4D5154",
                          borderRadius: "20px",
                          color: "white",
                        }}
                        icon={<PlusOutlined />} // Add a "+" icon
                        onClick={handleOpenForm}
                      >
                        Create Sale
                      </Button>
                    </Form.Item>
                    
                  </Form>
                  
                  {
                    prevVisibility && (
                      <div
                        style={{
                          position: 'relative',  // Allows the icon to be positioned at the top right
                          marginTop: 10,
                          padding: 20,
                          backgroundColor: '#efefef',
                          borderRadius: 16,
                          marginBottom: 20,
                          boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2), 0px 10px 10px -5px rgba(0, 0, 0, 0.2), 10px 0px 10px -5px rgba(0, 0, 0, 0.2), -10px 0px 10px -5px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        {/* Close icon */}
                        <i
                          className="fa-solid fa-xmark"
                          style={{
                            position: 'absolute',
                            top: 10,
                            right: 10,
                            fontSize: '16px',
                            cursor: 'pointer',
                            color: '#000',
                          }}
                          onClick={handleCloseFilter}  // Update this with your state setter function
                        />
                        <Form layout="vertical">
                          <Row gutter={[24]} style={{ marginTop: '10px' }}>
                            <Col span={4}>
                              <Form.Item label="S.NO">
                                <Input value={filterSno} onChange={handleFilterSnoChange} />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Agency Name">
                              <AutoComplete
                                  value={leadfilterObjectTemp?.agencyName || ''} 
                                  onChange={handleFilterAgencyChange} 
                                  options={filterAgencyName?.map(name => ({ label: name, value: name }))}
                                  onSearch={loadAgencyNames}
                                  placeholder="Search Agency"
                                  allowClear
                                  style={{ width: '100%' }}
                                >
                                  <Input 
                                    style={{ backgroundColor: leadfilterObjectTemp.agencyName ? '#d0e8ff' : 'inherit' }} 
                                    onChange={loadAgencyNames} 
                                  />
                                </AutoComplete>
                              </Form.Item>
                              {/* <h1>hii</h1> */}
                            </Col>
                            <Col span={4}>
                              <Form.Item label="State">
                              <AutoComplete
                                  value={leadfilterObjectTemp?.state || ''}
                                  options={filterState?.map(state => ({ label: state, value: state }))}
                                  onChange={handleFilterStateChange}
                                  placeholder="Select State"
                                  allowClear
                                  style={{ width: '100%' }}
                                >
                                  <Input   onChange={loadStates} 
                                    style={{ backgroundColor: leadfilterObjectTemp?.state ? '#d0e8ff' : 'inherit' }} 
                                  />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="City">
                              <AutoComplete
                                value={leadfilterObjectTemp?.city || ''}
                                options={filterCity?.map(city => ({ label: city, value: city }))}
                                onChange={handleFilterCityChange}
                                placeholder="Select City"
                                allowClear
                                style={{ width: '100%' }}
                              >
                                <Input onChange={loadCities} 
                                  style={{ backgroundColor: leadfilterObjectTemp?.city ? '#d0e8ff' : 'inherit' }} 
                                />
                              </AutoComplete>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Channel">
                              <AutoComplete
                                  value={leadfilterObjectTemp?.channel || ''}
                                  options={filterChannel?.map(channel => ({ label: channel, value: channel }))}
                                  onChange={handleFilterChannelChange}
                                  style={{ width: '100%' }}
                                >
                                  <Input 
                                    style={{ backgroundColor: leadfilterObjectTemp?.channel ? '#d0e8ff' : 'inherit' }} 
                                    placeholder="Select Channel" 
                                    allowClear 
                                  />
                                </AutoComplete>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Time Zone">
                                <Select 
                                  value={leadfilterObjectTemp?.timeZone || ''} 
                                  onChange={handleFilterTimezoneChange} 
                                  options={filterTimeZone?.map(zone => ({ label: zone, value: zone }))}
                                  style={{
                                    backgroundColor: leadfilterObjectTemp?.timeZone ? '#d0e8ff' : 'inherit',
                                  }}
                                />
                              </Form.Item>
                            </Col>
      
                            
                            <Col span={4}>
                              <Form.Item label="Assigned To">
                                <Select 
                                  value={leadfilterObjectTemp?.assignedTo || ''} 
                                  onChange={handleFilterAssignedToChange} 
                                  options={filterAssignedTo?.map(person => ({ label: person, value: person }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Last Updated">
                                <Space direction="horizontal" style={{ width: '100%' }}>
                                  <DatePicker label="From Date" onChange={handleFilterLastUpdatedFromChange} style={{ width: '100%' }} />
                                  <DatePicker label='To Date' onChange={handleFilterLastUpdatedToChange} style={{ width: '100%' }} />
                                </Space>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Last Contacted">
                                <Space direction="horizontal" style={{ width: '100%' }}>
                                  <DatePicker onChange={handleFilterLastContactedFromChange} style={{ width: '100%' }} />
                                  <DatePicker onChange={handleFilterLastContactedToChange} style={{ width: '100%' }} />
                                </Space>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Follow-up">
                                <Space direction="horizontal" style={{ width: '100%' }}>
                                  <DatePicker onChange={handleFilterLastFollowupFromChange} style={{ width: '100%' }} />
                                  <DatePicker onChange={handleFilterLastFollowupToChange} style={{ width: '100%' }} />
                                </Space>
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Call with OPS Status">
                                <Select 
                                  value={leadfilterObjectTemp?.callscheduledwithOPs} 
                                  onChange={handleFilterOPSCallChange} 
                                  options={filterScheduledOPSStatus?.map(status => ({ label: status, value: status }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Follow Up">
                                <Select 
                                  value={leadfilterObjectTemp?.followup} 
                                  onChange={handleFilterFollowUpChange} 
                                  options={filterFollowup?.map(followup => ({ label: followup, value: followup }))}
                                />
                              </Form.Item>
                            </Col>
      
                            
                            <Col span={4}>
                              <Form.Item label="Lead Successful">
                                <Select 
                                  value={leadfilterObjectTemp?.successfull} 
                                  onChange={handleFilterSuccessfullChange} 
                                  options={filterSuccessfull?.map(success => ({ label: success, value: success }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Lead Stage">
                                <Select 
                                  value={leadfilterObjectTemp?.stage} 
                                  onChange={handleFilterStageChange} 
                                  options={filterStage?.map(stage => ({ label: stage, value: stage }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Lead Status">
                                <Select 
                                  value={leadfilterObjectTemp?.status} 
                                  onChange={handleFilterStatusChange} 
                                  options={filterStatus?.map(status => ({ label: status, value: status }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={4}>
                              <Form.Item label="Priority">
                                <Select 
                                  value={leadfilterObjectTemp?.priority} 
                                  onChange={handleFilterPriorityChange} 
                                  options={filterPriority?.map(priority => ({ label: priority, value: priority }))}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                            <Space>
                              <Button
                                type="default"
                                style={{ backgroundColor:'#4D5154', borderRadius: '20px', color: 'white' }}
                                icon={<ReloadOutlined />}
                                onClick={resetFilters}
                              >
                                Reset
                              </Button>
                              <Button
                                type="primary"
                                style={{ borderRadius: '20px', backgroundColor:'#0253cc' }}
                                onClick={setFilters}
                              >
                                Apply
                              </Button>
                              <Button
                                type="primary"
                                style={{
                                  backgroundColor: '#00c5fb',
                                  color: 'white',
                                  borderRadius: '20px',
                                }}
                                onClick={saveFilterCheckHandler}
                              >
                                Save
                              </Button>
                              </Space>
                            </Col>
                  
                          </Row>
      
                          
      
                          {saveFilterCheck && (
                            <Row justify="center" style={{ textAlign: 'center' }}>
                              <Col span={24}>
                                <Divider style={{ borderColor: '#1890ff' }} />
                              </Col>
                              <Col span={8}>
                                <Row gutter={8} align="middle">
                                  <Col flex="auto">
                                    <Input
                                      name="filterName"
                                      id="filterName"
                                      value={filterName}
                                      onChange={handlefilterNameChange}
                                      autoFocus={!saveFilterCheck}
                                      placeholder="Filter Name"
                                      disabled={!saveFilterCheck}
                                    />
                                  </Col>
                                  <Col>
                                    <Button
                                      disabled={!saveFilterCheck}
                                      type="primary"
                                      style={{ backgroundColor: '#0253cc', borderRadius: '20px', color: 'white' }}
                                      onClick={handleSaveFilter}
                                    >
                                      Save Filter
                                    </Button>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          )}
                        </Form>
                      </div>
      
                    )
                  }
      
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <Table
                          className="table-striped"
                          rowKey={(record) => record?.id}
                          columns={columns}
                          dataSource={tableData?.length > 0 ? tableData : []}
                        
                          pagination={{
                            current: page,                 
                            pageSize: pageSize,
                            total: datacontent?.count || 0,
                            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                            onChange: handleTableChange,
                            showQuickJumper: true,
                            showSizeChanger: false
                        }}
                        // loading={loading}
                        style={{maxWidth: '100%', tableLayout: 'fixed' }} 
                        
                        size="small"
                        onChange={handleTableChange} 
                        locale={{
                          emptyText: <CustomNoRowsOverlay />,
                        }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <TicketModelPopup />
              <DeleteModal Name="Delete Ticket" />
            </>                   
            )
          }
    </>
     
  );
};

export default AllSales;
