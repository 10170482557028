import { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Select, Form, Input, message } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_INSURANCE_CARRIERS } from '../../../Queries/Users';
import { CREATE_INSURANCE_CARRIER, UPDATE_INSURANCE_CARRIER } from '../../../Queries/Orders';
import { useErrorLogger } from "../../../utils";

const { Option } = Select;

const CreateInsuranceCarrier = ({ open, handleClose, data }) => {
    const [carrierName, setCarrierName] = useState('');
    const [status, setStatus] = useState('');
    const token = localStorage.getItem('token');
    const { logError } = useErrorLogger();
    const { data: existingCarriersData } = useQuery(GET_ALL_INSURANCE_CARRIERS); 
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    useEffect(() => {
        setCarrierName(data?.carrierName || '');
        setStatus(data?.status || '');
    }, [data]);

    const [createInsuranceCarrier] = useMutation(CREATE_INSURANCE_CARRIER, {
      
        onCompleted(data) {
            if (data?.createInsuranceCarrier) {
                const messageContent = data.createInsuranceCarrier.message || 'Successfully Created Insurance Carrier';
                message.success(messageContent);
                handleClose(true);
            } else {
                message.error('Error creating carrier');
            }
        },
        onError() {
            logError("CreateInsuranceCarrier","Create Mutation","error while creatingcarrier")    
        }
    });

    const [updateInsuranceCarrier, { refetch }] = useMutation(UPDATE_INSURANCE_CARRIER, {
        onCompleted(data) {
            if (data?.updateInsuranceCarrier) {
                const messageContent = data.updateInsuranceCarrier.message || 'Successfully Updated Insurance Carrier';
                message.success({
                    content: messageContent,
                    duration: 1,
                });
                handleClose(true);
            } else {
                message.error('Error updating carrier');
            }
        },
        onError() {
            logError("CreateInsuranceCarrier","Update Mutation","error while updating carrier") 
            
        }
    });

    const isDuplicateCarrierName = (newCarrierName) => {
        const existingCarriers = existingCarriersData?.getAllInsuranceCarriers?.response || [];
        return existingCarriers.some(
            (carrier) => carrier.carrierName.toLowerCase() === newCarrierName.toLowerCase()
        );
    };

    const isDuplicateCarrier = (newCarrierName) => {
        const existingCarriers = existingCarriersData?.getAllInsuranceCarriers?.response || [];
        return existingCarriers.some((carrier) => {
            if (data?.id && carrier.id === data.id) return false;
            return carrier.carrierName.toLowerCase() === newCarrierName.toLowerCase();
        });
    };

    const submit = () => {
        
        setIsButtonDisabled(true);
        if (!carrierName.trim()) {
            message.error('Please enter a valid Carrier Name');
            setTimeout(() => {
                setIsButtonDisabled(false);  
            }, 3000);
            return;
        }
        if (!status) {
            message.error('Please select Status');
            setTimeout(() => {
                setIsButtonDisabled(false);  
            }, 3000);
            return;
        }

        if (!data?.id && isDuplicateCarrierName(carrierName.trim())) {
            message.error('This carrier name already exists in the system');
            setTimeout(() => {
                setIsButtonDisabled(false);  
            }, 3000);
            return;
        }

        if (data?.id && carrierName.trim() !== data?.carrierName.trim() && isDuplicateCarrier(carrierName.trim())) {
            message.error('A carrier with the same name and status already exists');
            setTimeout(() => {
                setIsButtonDisabled(false);  
            }, 3000);
            return;
        }

        if (data?.id && carrierName.trim() === data?.carrierName.trim() && status === data?.status) {
            message.warning("No Change Detected to Update");
            setTimeout(() => setIsButtonDisabled(false), 3000);
            return;
        }

        const valuesObject = {
            id: data?.id,
            userId: parseJwt(token)?.id,
            carrierName,
            status,
        };

       
        if (data?.id) {
            updateInsuranceCarrier({ variables: valuesObject });
        } else {
            createInsuranceCarrier({ variables: valuesObject });
        }

        
        handleClose(true);

       
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 3000);
    };

    return (
        <Modal
            title={data?.id ? "Update Insurance Carrier" : "Create Insurance Carrier"}
            open={open}
            onCancel={() => handleClose(false)}
            footer={null}
        >
            <Form layout="vertical" onFinish={submit}>
                <Row gutter={16}>
                    <Col span={12}>
                        <Form.Item label="Carrier Name">
                            <Input
                                placeholder="Enter Carrier Name"
                                required
                                value={carrierName}
                                onChange={(e) => setCarrierName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label="Status" rules={[{ required: true, message: 'Please select a Status' }]}>
                            <Select
                                value={status}
                                onChange={(value) => setStatus(value)}
                                placeholder="Select Status"
                            >
                                <Option value="Active">Active</Option>
                                <Option value="Inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>

                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isButtonDisabled} // Disable the button while processing
                        >
                            {data?.id ? 'Update' : 'Save'}
                        </Button>
                        <Button color='danger' variant='solid' onClick={() => handleClose(false)} style={{ marginLeft: '8px' }}>
                            Cancel
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateInsuranceCarrier;
