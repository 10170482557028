import React from 'react';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AddCommentBox from './CommentBox';
import SalesInformationPopup from '../../../components/modelpopup/SalesInformationPopup';

const EmailTrack = ({emailTrackInfo}) => {
  return (
    <>
    <div className="d-flex">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Email Tracking{" "}
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#email_tracking_modal"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Time</th>
                  <th>Status</th>
                  <th>Emailed To</th>
                  <th>Emailed By</th>
                  <th>Email Follow up</th>
                  <th>Remarks</th>
                </tr>
              </thead>
              <tbody>
                {emailTrackInfo?.map((item, index) => (
                  <tr key={index}>
                     <td>{item?.date ? dayjs(Number(item.date)).format('DD/MM/YYYY') : 'N/A'}</td>
                     <td>{item?.time ? dayjs(item.time, 'HH:mm:ss').format('hh:mm A') : 'N/A'}</td>
                    <td>{item?.status || 'N/A'}</td>
                    <td>{item?.emailedto || 'N/A'}</td>
                    <td>{item?.emailedy || 'N/A'}</td>
                    <td>{item?.emailFollowup || 'N/A'}</td>
                    <td>{item?.remarks || 'N/A'}</td>
                  </tr>
                
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <AddCommentBox name={'emailComments'}/>
     <SalesInformationPopup  name={'emailComments'}/>
    </>

  );
};

export default EmailTrack;
