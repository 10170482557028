import { gql, useMutation } from "@apollo/client";

export const CREATE_LEAVE_REQUEST = gql`
  mutation CreateLeaveRequest(
    $employeeId: String!
    $leaveType: String!
    $startDate: String!
    $endDate: String!
    $leaveReason: String!
    $leaveDays: String!
    $session1: String
    $session2: String
  ) {
    createLeaveRequest(
      employeeId: $employeeId
      leaveType: $leaveType
      startDate: $startDate
      endDate: $endDate
      leaveReason: $leaveReason
      leaveDays: $leaveDays
      session1: $session1
      session2: $session2
    ) {
      employeeId
      leaveType
      startDate
      endDate
      leaveStatus
      leaveReason
      managerApproval
      hrApproval
      leaveRequestDate
      duration
      totalLeave
      leaveTaken
      leaveRemaining
      createdAt
      status
      updatedAt
      leaveDays  
      session1
      session2
      leaveHistory {
        month
        year
        totalLeave
        leaveTaken
        leaveRemaining
      }
    }
  }
`;

export const GET_LEAVE_REQUESTS = gql`
  query GetLeaveRequests(
    $employeeId: String
    $leaveType: String
    $leaveStatus: String
    $fromDate: String
    $toDate: String
  ) {
    getLeaveRequests(
      employeeId: $employeeId
      leaveType: $leaveType
      leaveStatus: $leaveStatus
      fromDate: $fromDate
      toDate: $toDate
    ) {
      _id
      
      employeeId
      leaveType
      startDate
      endDate
      leaveStatus
      leaveReason
      managerApproval
      hrApproval
      leaveRequestDate
      duration
      totalLeave
      leaveTaken
      status
      leaveRemaining
      createdAt
      updatedAt
      leaveDays
      approvedBy
      session1
      session2
      remarks
      leaveHistory {
        month
        year
        totalLeave
        leaveTaken
        leaveRemaining
      }
    }
  }
`;

export const USER_LEAVES = gql`
  query SingleEmployee($id: String!) {  
    SingleEmployee(ID: $id) {
      status
      message
      count
      response{
    id
    EmpID
    firstname
    lastname
    Leaves{
        year
        availableLeaves
        usedLeaves
        medicalLeaves
        AnnualLeaves
      }

  }
}
  }
`;
export const UPDATE_LEAVES = gql(`
  mutation($input:LeavesDataBucketInputType){
   createLeaves(input:$input){
     status
     message
   }
  }
  
  `)

export const GET_LEAVES_DATA = gql(`
   query{
GetAllLeaves {
 message
  status
  response{
    year
    department
    medicalLeave
    carryFlag
    CarryforwardLeaves
    casualLeaves
  }
}
}
  `)


export const GET_EMPLOYEE_LEAVE_DETAILS = gql`
  query GetEmployeeLeaveDetails($employeeId: String!) {
    CalculateLeaveDaysResolver(employeeId: $employeeId) {
      medicalLeavesApplied
      medicalLeavesAvailable
      casualLeavesApplied
      casualLeavesAvailable
    }
  }
`;

export const UPDATE_LEAVE_SETTINGS = gql`
  mutation updateLeaveSettings(
    $EmpID: String!,
    $year: String!,
    $department: String,
    $casualLeaves: String,
    $medicalLeave: String,
    $CarryforwardLeaves: String,
    $carryFlag: Boolean,
    $Key: Boolean
  ) {
    updateLeaveSettings(
      EmpID: $EmpID,
      year: $year,
      department: $department,
      casualLeaves: $casualLeaves,
      medicalLeave: $medicalLeave,
      CarryforwardLeaves: $CarryforwardLeaves,
      carryFlag: $carryFlag,
      Key: $Key
    ) {
      status
      message
      response {
        firstname
        lastname
        department
        leavesSettings {
          year
          department
          casualLeaves
          medicalLeave
          CarryforwardLeaves
          carryFlag
          Key
        }
      }
    }
  }
`;

export const UPDATE_EMP_LEAVE_SETTINGS = gql`
  mutation UpdateEmpLeaveSettings($id: String!, $input: leavesSettingsInputType!) {
    updateEmpLeaveSettings(id: $id, input: $input) {
      status
      message
      response {
        id
        EmpID
        createdDate
        createdTime
        employeestatus
        firstname
        lastname
        password
        leavesSettings {
          department
          year
          casualLeaves
          medicalLeave
          CarryforwardLeaves
          carryFlag
          Key
        }
      }
    }
  }
`;