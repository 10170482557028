/* eslint-disable jsx-a11y/img-redundant-alt */
// import { useQuery } from "@apollo/client";
import { SINGLE_USER, GET_EMPLOYEE_DETAILS, } from "../../../Queries/Employees";
import { GET_ALL_CLIENTS } from "../../../Queries/Users.js";
import React, { useState, useEffect, } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import dayjs from "dayjs";
import ProfileTab from "./ProfileTab";
import { SERVER_URL, SIDEMENU } from "../../../databucket.js";
import axios from "axios";
import { UPDATE_EMPLOYEE } from "../../../Queries/Employees";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  setUserDatas,
  setManagersDatas,
  setClients,
  setBankInfos,
  setClientHistorys,
  setEmploymentDatas,
  setPersonalInfos,
  setId,
  SideMenu,
  setRoutes,
  updateEditHistory,
  setWorkExperiences,
  setEmergencyContactes,
  setWorkHistorys,
  setReportsDatas,
  setEducationDatas,
  setFamilyMemberses,
  SetAllEmployees
} from "../../../Slices/employeeslice";
import { GET_USER_DETAILS } from '../../../Queries/Users.js';
import { useQuery } from "@apollo/client";
import { Form, Button, Container } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import EmployeeReports from "./ReportingManager";
import ReportingTable from "./ReportingManager";
import WorkHistorys from "./WorkHistory.jsx";
import ClientHistory from "./ClientInformation";
import FamilyInfo from "./familyInformation";
import EmergencyContacts from "./emergencyContact";
import EditHistory from "./editworkhistory.jsx";
import EducationComponent from "./education";
import AttachemntsHistory from "./attachementHistory";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import AddNoteBox from "./addNote";
import { useRef } from "react";
import { useSelector } from 'react-redux';
import { useApolloClient } from "@apollo/client";
import { setLoader } from "../../../Slices/loaderSlice.jsx";
import IDCard from "../../../components/IdCardgenerater.js";
import ConditionMenu from "../../layout/conditionMenu.js";
import { setreupdate } from "../../../Slices/DyanamicRoute.js";
import AttendanceEmployee from "../Employees/AttendenceEmployee.jsx";
import EmployeeLeave from "../Employees/EmployeeLeave.jsx";
import ProfileLeaveSettings from "./ProfileLeaveSettings.jsx";
const Profile = () => {
  const Navigation=useNavigate();
  const client = useApolloClient();
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const empUpdate = useSelector((state) => state.Route.reupdate)
  //console.log(userData);
  const fileInputRef = useRef(null);
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };
  const clientOptions = useSelector((state) => state.employee.clients);
  const { data: clientData } = useQuery(GET_ALL_CLIENTS, {
    onCompleted: (clientData) => {
      if (clientOptions?.length <= 0) {
        dispatch(setClients(clientData.getAllClients.response));
      }
    },
  });
  useEffect(() => {
    refetch();
    dispatch(setreupdate(false));
    dispatch(setId(true));
  }, [empUpdate])
  // State to manage active tab
  const [note, setNote] = useState('');
  //console.log("usedata", userData);
  const dispatch = useDispatch();
  const { Empid } = useParams();
  const [activeTab, setActiveTab] = useState(Empid ? "emp_profile" : "work_history");
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const [image, setImage] = useState();
  const [empdata, setempdata] = useState(true);
  const { data: AllData } = useQuery(GET_EMPLOYEE_DETAILS);
  useEffect(() => {
    if (detail?.length <= 0 && AllData?.EmployeeDetail?.response) {
      dispatch(SetAllEmployees(AllData.EmployeeDetail.response));
      setempdata(false);
    }
  }, [AllData, empdata, dispatch]);
  const token = localStorage.getItem("token");
  const id = parseJwt(token)?.id;
  // Use query safely after it's initialized
  const ids = Empid ? Empid : id;
  console.log("dfbvvfdv", ids)
  const { data, loading, error, refetch } = useQuery(SINGLE_USER, {
    variables: { id: ids },
    fetchPolicy: "network-only", // Always fetch from the server
    onCompleted: (data) => {
      dispatch(setLoader(false))
      // const employees = data.SingleEmployee.response;
      //console.log("feq",data);
    },
  });
  const userData = useSelector((state) => state.employee.userData);
  const ID = useSelector((state) => state.employee.id);
  const detail = useSelector((state) => state.employee.AllEmployees)
  useEffect(() => {
    if (ID) {
      dispatch(setLoader(loading));
      dispatch(setUserDatas({}), setManagersDatas(),
        setClientHistorys(), setEmploymentDatas({}),
        setEmergencyContactes(), setBankInfos({}),
        setPersonalInfos({}), setWorkHistorys(),
        setReportsDatas(), setEducationDatas(), setFamilyMemberses())
    }
  }, [])
  // Helper function to parse JWT and extract ID
  function getInitials(name) {
    if (!name) return 'N/A'; // Handle case where name is empty or undefined

    const names = name.split(' ');  // Split by spaces to separate first and second names.

    // If there's only one word, return the first letter of the first word
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();  // First letter of the first word
    }
    // If there are two or more words, return the first letter of the first word and the second letter of the second word.
    const firstInitial = names[0].charAt(0).toUpperCase();  // First letter of the first word
    const secondInitial = names[1] ? names[1].charAt(0).toUpperCase() : '';  // Second letter of the second word (if exists)
    return firstInitial + secondInitial;  // Combine the initials
  }

  useEffect(() => {
    console.log("New ID detected:", ids);
    if (data?.SingleEmployee?.response) {
      const employee = data.SingleEmployee.response;
      console.log("employee", data?.SingleEmployee?.response?.menu, SIDEMENU)
      if (ID) {
        dispatch(setId(false));
        console.log("hte", employee)
        dispatch(
          setUserDatas({
            id: employee.id,
            EmpID: employee.EmpID,
            firstname: employee.firstname,
            lastname: employee.lastname,
            dob: employee.dob || '',
            gender: employee.gender,
            currentAddress: employee.currentAddress,
            mobile: employee.mobile,
            department: employee.department,
            designation: employee.designation,
            reportingTo: employee.reportingTo,
            bloodGroup: employee.bloodGroup,
            permanentAddress: employee.permanentAddress,
            maritalStatus: employee.maritalStatus,
            processSplization: employee.processSplization,
            alternativeNumber: employee.alternativeNumber || '',
            email: employee.email || '',
            clients: employee.clients || '',
            employeestatus: employee.employeestatus || '',
            DateOfJoining: employee.DateOfJoining || '',
            personalEmail: employee.personalEmail || '',
            role: employee.role || '',
            image: employee.image,
          })
        );
        setImage(employee?.image)
        dispatch(setManagersDatas(employee?.teamsReportIngManager || []));
        const EmployeeMenu = (employee?.menu && employee.menu.length > 0) ? employee.menu : SIDEMENU;
        dispatch(SideMenu(EmployeeMenu));
        // dispatch(setRoutes(employee?.route))
        dispatch(setClientHistorys(employee?.client || []));
        dispatch(
          setEmploymentDatas({
            employmentStatus: employee?.employmentStatus || '',
            resignationStatus: employee?.resignationStatus || '',
            noticePeriod: employee?.noticePeriod || '',
            resignationDate: employee?.resignationDate || '',
            lastWorkingDay: employee?.lastWorkingDay || '',
            RehireEligibility: employee?.RehireEligibility || '',
          })
        );
        dispatch(updateEditHistory(employee?.EditEmployee))
        console.log("hterere", employee?.EditEmployee[0])
        dispatch(
          setWorkExperiences({
            ShiftTimings: employee?.ShiftTimings || '',
            EmploymentType: employee?.EmploymentType || '',
            Experience: {
              years: employee?.Experience?.years || '',
              months: employee?.Experience?.months || '',
            },
            WorkType: employee?.WorkType || '',
            ProbationStatus: employee?.ProbationStatus || '',
            probationPeriod: employee?.probationPeriod || '',
          })
        );
        dispatch(setEmergencyContactes(employee.emergencyContacts));
        dispatch(
          setBankInfos({
            BankAccountNo: employee?.BankAccountNo || '',
            Branch: employee?.Branch || '',
            IFCcode: employee?.IFCcode || '',
            BankName: employee?.BankName || '',
            PanNumber: employee?.PanNumber || '',
          })
        );
        dispatch(setPersonalInfos({
          adharNumber: employee?.adharNumber || '',
          PanNumber: employee?.PanNumber || '',
          UAN: employee?.UAN || '',
          EsiNo: employee?.EsiNo || '',
          HealthInsuranceNo: employee?.HealthInsuranceNo || '',
          PfNo: employee?.PfNo || '',
        }))


        dispatch(setWorkHistorys(employee?.workHistory || []));
        dispatch(setReportsDatas(employee?.teamsReportDirect || []));
        dispatch(setEducationDatas(employee.education));
        dispatch(setFamilyMemberses(employee.familyMembers));

      }
    }

  }, [ids, data]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    if (file) {
      dispatch(setLoader(true));
      // Preview the image locally
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result); // Set preview image
      };
      reader.readAsDataURL(file);
      // Prepare and send the file to the server
      const formData = new FormData();
      formData.append('files', file);
      try {
        const res = await axios.post(`${SERVER_URL}/upload`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        // Get the uploaded file's URL from the server response
        const savedFile = res.data.files[0];
        console.log("Uploaded file URL:", savedFile.url);
        let { data } = await updateEmployee({
          variables: {
            id: ids,
            input: {
              image: savedFile.url,
            },
          },
        });
        if (data?.UpdateEmploye?.status) {
          const updatesEmp = detail?.map((employee) =>
            employee.id === ids
              ? {
                ...employee,
                image: savedFile.url
              }
              : employee
          );
          console.log("Ffdfd", updatesEmp);
          dispatch(SetAllEmployees(updatesEmp));
        }

        setImage(savedFile.url);
        dispatch(setLoader(false));
      } catch (error) {
        console.error("Error uploading file:", error);
      }
    }
  };
   const handleGo=()=>{
    Navigation(-1)

   }
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* <Breadcrumbs
            maintitle="Profile"
            title="Dashboard"
            subtitle="Profile"
            modal="#add_indicator"
            name="Add New"
           */}
            <Button onClick={handleGo}
             style={{ marginBottom:"3px"}}
            > ←  Back</Button>
          <div className="card mb-0">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="profile-view">
                    <div className="profile-img-wrap">
                      <div>
                        {userData?.image ? (
                          <div style={{ position: "relative", display: "inline-block" }}>
                            <div className="profile-img">
                              <img
                                src={userData?.image}
                                alt="User"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  width: "120px",
                                  height: "120px",
                                  borderRadius: "50%",
                                  backgroundColor: "#ccc",
                                  color: "#333",
                                  fontSize: "20px",
                                  position: "relative",
                                  border: "2px solid #ddd", // Optional border for styling
                                }}
                              />
                              <input
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                style={{ display: "none" }}
                                id="imageUpload"
                                ref={fileInputRef}
                              />
                              <label
                                htmlFor="imageUpload"
                                style={{
                                  position: "absolute",
                                  bottom: "5px",
                                  right: "5px",
                                  background: "transparent",
                                  color: "#000",
                                  marginLeft: "-10px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                  fontSize: "18px",
                                  // Optional border for the edit icon
                                }}
                              >
                                {Empid ? <Link
                                  onClick={(e) => {
                                    e.preventDefault();
                                    triggerFileInput();
                                  }}
                                  to="#"
                                  className="edit-icon"
                                  htmlFor="imageUpload"

                                >
                                  <i className="fa fa-pencil" />
                                </Link> : null}
                              </label>
                            </div>
                          </div>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "120px",
                              height: "120px",
                              borderRadius: "50%",
                              backgroundColor: "#898484",
                              color: "white",
                              fontSize: "20px",
                              position: "relative",
                              border: "2px solid #898484", // Optional border for styling
                            }}
                          >
                            {/* Generate initials or display 'N/A' */}
                            {userData?.firstname ? getInitials(userData.firstname) : "N/A"}
                            <input
                              type="file"
                              accept="image/*"
                              onChange={handleImageChange}
                              style={{ display: "none" }}
                              id="imageUpload"
                              ref={fileInputRef}
                            />
                            <label
                              htmlFor="imageUpload"
                              style={{
                                position: "absolute",
                                bottom: "5px",
                                right: "5px",
                                background: "transparent",
                                color: "#000",
                                marginLeft: "-10px",
                                borderRadius: "50%",
                                cursor: "pointer",
                                fontSize: "18px",
                                // Optional border for the edit icon
                              }}
                            >
                              <Link
                                onClick={(e) => {
                                  e.preventDefault();
                                  triggerFileInput();
                                }}
                                to="#"
                                className="edit-icon"
                                htmlFor="imageUpload"

                              >
                                <i className="fa fa-pencil" />
                              </Link>
                            </label>
                          </div>
                        )}
                      </div>

                    </div>
                    <div className="profile-basic">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="profile-info-left">
                            <h3 className="user-name m-t-0 mb-0">
                              {userData.firstname} {userData.lastname}
                            </h3>
                            {/* <h6 className="text-muted">
                              {userData?.department || "N/A"}
                            </h6> */}
                            <div className="staff-id">
                              Emp ID : {userData?.EmpID || "N/A"}
                            </div>
                            <div className="staff-id">
                              Designation:<span className="text-muted">{userData?.designation || "-"}</span>
                            </div>
                            <div className="staff-id">
                              Department:<span className="text-muted"> {userData?.department || "-"}</span>
                            </div>
                            <div className="staff-id">
                              processSplization:<span className="text-muted">{` ${userData?.processSplization || "-"}`}</span>
                            </div>
                            <div className="staff-id">
                              Date of Joining:{" "}
                              <span className="text-muted"> {dayjs(userData?.DateOfJoining).format("DD/MM/YYYY")}</span>
                            </div>
                            <div className="staff-id">
                              Role:{" "}
                              <span className="text-muted"> {userData?.role}</span>
                            </div>
                            <div className="staff-id">
                              Status:{" "}
                              <span className="text-muted"> {userData?.employeestatus}</span>
                            </div>
                            <div className="staff-id">
                              Phone: <span className="text-muted"> {userData?.mobile || "N/A"}</span>
                            </div>
                            <div className="staff-msg">
                              {Empid ? <Link className="btn btn-custom" to="">
                                Send Message
                              </Link> : null}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7">
                          <ul className="personal-info">
                            <li>
                              <div className="title">Alternate Phone:</div>
                              <div className="text">
                                {userData?.alternativeNumber || "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Email:</div>
                              <div className="text">
                                <Link to="">
                                  {userData?.email || "N/A"}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Company Email:</div>
                              <div className="text">
                                <Link to="">
                                  {userData?.personalEmail || "N/A"}
                                </Link>
                              </div>
                            </li>
                            <li>
                              <div className="title">Birthday:</div>
                              <div className="text">
                                {userData?.dob ? dayjs(userData.dob).format('DD/MM/YYYY') : "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Current Address:</div>
                              <div className="text">
                                {userData?.currentAddress || "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Permanent Address:</div>
                              <div className="text">
                                {userData?.permanentAddress || "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Gender:</div>
                              <div className="text">
                                {userData?.gender || "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Marital Status:</div>
                              <div className="text">

                                {userData?.maritalStatus || "N/A"}

                              </div>
                            </li>
                            <li>
                              <div className="title">Blood Group:</div>
                              <div className="text">
                                {userData?.bloodGroup || "N/A"}
                              </div>
                            </li>
                            <li>
                              <div className="title">Reports to:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    <img src={Avatar_16} alt="User Image" />
                                  </div>
                                </div>
                                <Link to="">
                                  {userData?.reportingTo || "N/A"}
                                </Link>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    {Empid ? <div className="pro-edit">
                      <Link
                        data-bs-target="#profile_info"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </div> : null}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card tab-box">
            <div className="row user-tabs">
              <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom">
                  <li className="nav-item">
                    {Empid ? <Link
                      to="#emp_profile"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "emp_profile" ? "active" : ""}`}
                      onClick={() => setActiveTab("emp_profile")}
                    >
                      Documents
                    </Link> : ""}
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#work_history"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "work_history" ? "active" : ""}`}
                      onClick={() => setActiveTab("work_history")}
                    >
                      Work
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#client_history"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "client_history" ? "active" : ""}`}
                      onClick={() => setActiveTab("client_history")}
                    >
                      Client
                    </Link>
                  </li>
                  <li className="nav-item">
                    {Empid ? <Link
                      to="#Reporting_data"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "Reporting_data" ? "active" : ""}`}
                      onClick={() => setActiveTab("Reporting_data")}
                    >
                      Teams
                    </Link> : null}
                  </li>
                  <li className="nav-item">
                    <Link
                      to="#education_data"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "education_data" ? "active" : ""}`}
                      onClick={() => setActiveTab("education_data")}
                    >
                      Education
                    </Link>
                  </li>

                  <li className="nav-item">
                    <Link
                      to="#family_info"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "family_info" ? "active" : ""}`}
                      onClick={() => setActiveTab("family_info")}
                    >
                      Family Information
                    </Link>
                  </li>
                  <li className="nav-item">
                    {Empid ? <Link
                      to="#update_workHistory"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "update_workHistory" ? "active" : ""}`}
                      onClick={() => setActiveTab("update_workHistory")}
                    >
                      Update WorkHistory
                    </Link> : null}
                  </li>
                  {/* <li className="nav-item">
                    <Link
                      to="#Leaves_Tab"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "Leaves_Tab" ? "active" : ""}`}
                      onClick={() => setActiveTab("Leaves_Tab")}
                    >
                      Leaves
                    </Link>
                  </li> */}
                  {/* <li className="nav-item">
                    <Link
                      to="#Attendence"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "Attendence" ? "active" : ""}`}
                      onClick={() => setActiveTab("Attendence")}
                    >
                      Attendence
                    </Link>
                  </li> */}


                  {/* <li className="nav-item">
                    {Empid ? <Link
                      to="#IdCard"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "IdCard" ? "active" : ""}`}
                      onClick={() => setActiveTab("IdCard")}
                    >
                      IdCard
                    </Link> : null}
                  </li> */}
                  <li className="nav-item">
                    {Empid ? <Link
                      to="#routes"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "routes" ? "active" : ""}`}
                      onClick={() => setActiveTab("routes")}
                    >
                      Route Access
                    </Link> : null}
                  </li>
                  {/* <li className="nav-item">
                    {Empid ? <Link
                      to="#leave_settings"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "leave_settings" ? "active" : ""}`}
                      onClick={() => setActiveTab("leave_settings")}
                    >
                      Leave Settings
                    </Link> : null}
                  </li> */}
                  <li className="nav-item">
                    {Empid ? <Link
                      to="#profile_leave_settings"
                      data-bs-toggle="tab"
                      className={`nav-link ${activeTab === "profile_leave_settings" ? "active" : ""}`}
                      onClick={() => setActiveTab("profile_leave_settings")}
                    >
                      Leave Settings
                    </Link> : null}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Tab Contents */}
          <div className="tab-content">
            {activeTab === "emp_profile" && <ProfileTab />}
            {activeTab === "Reporting_data" && <ReportingTable />}
            {activeTab === "work_history" && <WorkHistorys />}
            {activeTab === "client_history" && <ClientHistory />}
            {activeTab === "family_info" && <FamilyInfo />}
            {activeTab === "education_data" && <EducationComponent />}
            {activeTab === "update_workHistory" && <EditHistory editData={data?.SingleEmployee?.response?.EditEmployee} />}
            {activeTab === "IdCard" && <IDCard img={image} />}
            {activeTab === "routes" && <ConditionMenu />}
            {activeTab === "Attendence" && <AttendanceEmployee/>}
            {activeTab === "Leaves_Tab" && <EmployeeLeave/>}
            {activeTab === "profile_leave_settings" && <ProfileLeaveSettings/>}
                    
            {/* Add additional components for other tabs here */}
          </div>
        </div>

        <PersonalInformationModelPopup />


      </div>
    </>
  );
};

export default Profile;
