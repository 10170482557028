import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import AddNoteBox from "./addNote";

const ReportingTable = () => {
  const Reports = useSelector((state) => state.employee.managersData);
  const reportingDirect = useSelector((state) => state.employee.reportsDatas);

  return (
    <div className="col">
      {/* Manager Reporting Table */}
      <div id="reporting_manager_info" className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Reporting Manager
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#reporting_manager"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap text-center" style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Designation</th>
                  <th>Department</th>
                </tr>
              </thead>
              <tbody>
                {Reports?.length > 0 ? (
                  Reports.map((item, index) => (
                    <tr key={index}>
                      <td>{item.reportingManagerName}</td>
                      <td>{item.reportingManagerType}</td>
                      <td>{item.reportingManagerDesignation}</td>
                      <td>{item.reportingManagerDepartment}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No reports found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Direct Report Table */}
      <div id="direct_reports_info" className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Direct Reports
            <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#direct_reports"
            >
              <i className="fa fa-pencil" />
            </Link>
          </h3>
          <div className="table-responsive">
            <table className="table table-nowrap text-center" style={{ width: "100%", borderCollapse: "collapse" }}>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Type</th>
                  <th>Designation</th>
                  <th>Department</th>
                </tr>
              </thead>
              <tbody>
                {reportingDirect?.length > 0 ? (
                  reportingDirect.map((item, index) => (
                    <tr key={index}>
                      <td>{item.DirectReportName}</td>
                      <td>{item.DirectReportType}</td>
                      <td>{item.DirectReportDesignation}</td>
                      <td>{item.DirectReportDepartment}</td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No reports found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <PersonalInformationModelPopup name={"teamNote"} />
      <AddNoteBox name={"teamNote"} />
    </div>
  );
};

export default ReportingTable;
