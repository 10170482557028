import { useState } from 'react';
import { Modal, Button, Form, Row, Col } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as DataBucket from '../databucket';
import { Starttask } from '../Slices/timerslice';
import { UPDATE_WORKHISTORY } from '../Queries/Orders';
import { useMutation } from '@apollo/client';

const CreateOrder = (props) => {
    const { setModalVisible, handleClose, handleStart, RequestInfo, isVisible, onClose,userData } = props;
    const [updateWorkHistory, { data, loading, error }] = useMutation(UPDATE_WORKHISTORY);
    const dispatch = useDispatch();

    const [formValues, setFormValues] = useState({
        clientName: '',
        processType: '',
        taskType: '',
        email: '',
        subject: '',
        body: '',
    });

    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const values = formValues;
        try {
            const response = await updateWorkHistory({
                variables: {
                    userId: parseJwt(token)?.id.toString(),
                    emailId: values.emailId || '',
                    requestId: values.requestId || '', 
                    clientName: values.clientName || '',
                    processType: values.processType || '',
                    taskType: values.taskType || '',
                    email: values.email || '',
                    subject: values.subject || '',
                    body: values.body || '',
                    startTime: Date.now().toString(),
                    endTime: '', 
                },
            });
            
            const payload = {
                department: values.processType,
                task: values.taskType,
                time: Date.now()
            };
            console.log("payload", payload);
            dispatch(Starttask(payload));
            localStorage.setItem('StartTime', Date.now());
            localStorage.setItem('task', values.taskType);
            setModalVisible(false);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal show={isVisible} onHide={onClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>Create Order</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group as={Row} controlId="requestId">
                        <Form.Label column sm={3}>Request#</Form.Label>
                        <Col sm={9}>
                            <Form.Text className="font-weight-bold">{userData?.requestId}</Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="clientName">
                        <Form.Label column sm={3}>Client Name</Form.Label>
                        <Col sm={9}>
                            <Form.Text className="font-weight-bold">{userData?.clientName || 'N/A'}</Form.Text>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="processType">
                        <Form.Label column sm={3}>Service</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                as="select"
                                name="processType"
                                value={formValues.processType}
                                onChange={handleChange}
                            >
                                <option value="">Select Service</option>
                                {DataBucket.PROCESS_TYPE_CONFIG.map((item, key) => (
                                    <option key={key} value={item}>{item}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} controlId="taskType">
                        <Form.Label column sm={3}>Task</Form.Label>
                        <Col sm={9}>
                            <Form.Control
                                as="select"
                                name="taskType"
                                value={formValues.taskType || 'Processing'}
                                onChange={handleChange}
                            >
                                <option value="">Select Task</option>
                                {DataBucket.REPORT_TASKS.map((item, key) => (
                                    <option key={key} value={item}>{item}</option>
                                ))}
                            </Form.Control>
                        </Col>
                    </Form.Group>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                        <Button variant="primary" type="submit">
                            Save
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default CreateOrder;
