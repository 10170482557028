import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { useMutation } from "@apollo/client";
import { gql } from '@apollo/client';
import { useSelector } from "react-redux";
import { message } from "antd";
const UPDATE_LEAVE_STATUS = gql`
mutation updateLeaveStatus($leaveUpdates: [LeaveUpdateInput!]!) {
  updateLeaveStatus(leaveUpdates: $leaveUpdates) {
   _id
  leaveStatus
  updatedAt
  status
  session1
  session2
  leaveDays
  remarks
  }
}
`;

export const AdminLeaveAddModelPopup = ({ leaveData, isOpen, onClose, refetch }) => {
  console.log("leaveData", leaveData)
  const adminLeaves = useSelector((state) => state?.adminLeave?.leaves)
  const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails)
  const [formData, setFormData] = useState({
    leaveType: null,
    leaveStatus: null,
    fromDate: null,
    toDate: null,
    numberOfDays: 0,
    leaveReason: "",
    remarks:null,
    fromSession: "session1",
    toSession: "session1",
  });

  const [updateLeaveStatus] = useMutation(UPDATE_LEAVE_STATUS, {
    onCompleted: (response) => {
      console.log("response", response);
      refetch();
      // dispatch(updateLeave(response?.UpdateLeaveStatus));

      message.success("Leave updated successfully!");
      resetForm();
    },
    onError: (error) => {
      console.error("Error updating leave request:", error);
      message.error("Failed to update leave request. Please try again.");
    },
  });

  const leaves = [
    { value: "", label: "Select LeaveType" },
    { value: "Paid Leaves", label: "Paid Leave" },
    { value: "Casual Leave", label: "Casual Leave" },
    { value: "Sick Leave", label: "Sick Leave" },
  ];

  const leaveStatus = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Declined", label: "Declined" },
  ];

  const sessions = [
    { value: "", label: "Select LeaveType" },
    { value: "Session 1", label: "Session 1" },
    { value: "Session 2", label: "Session 2" }
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const [selectedLeaveType, setSelectedLeaveType] = useState(null);
  const [selectedLeaveStatus, setSelectedLeaveStatus] = useState(leaveData?.leaveStatus);

  const [selectedDate1, setSelectedDate1] = useState(null);
  const [selectedDate2, setSelectedDate2] = useState(null);
  const [leaveReason, setLeaveReason] = useState("");
  const [leaveDays, setLeaveDays] = useState(leaveData?.leaveDays);
  console.log("leaveDays", leaveDays, selectedLeaveStatus, leaveData)
  const [isDisabled, setIsDisabled] = useState(false);
  // const [remarks,setRemarks] = useState("");
  // console.log("remarks",remarks)
  const startTimer = () => {
    setIsDisabled(true); 
    setTimeout(() => {
      setIsDisabled(false); 
    }, 3000);
  };

  useEffect(() => {
    if (leaveData) {
      setFormData({
        leaveType: leaves.find((leave) => leave.value === leaveData.leaveType) || null,
        leaveStatus: leaveStatus.find((leave) => leave.value === leaveData.leaveStatus) || null,
        fromDate: leaveData.startDate ? new Date(parseInt(leaveData.startDate)) : null,
        toDate: leaveData.endDate ? new Date(parseInt(leaveData.endDate)) : null,
        leaveReason: leaveData.leaveReason || "",
        remarks: leaveData?.remarks || "",
        numberOfDays: leaveData.leaveDays || 0,
        fromSession: leaveData?.session1 === "true" ? "session1" : "session2",
        toSession: leaveData?.session2 === "true" ? "session2" : "session1",
        //remarks:setRemarks(remarks)
      });
    }
  }, [leaveData]);

  const handleDateChange1 = (date) => {
    setSelectedDate1(date);
    handleChange("fromDate", date);
  };

  const handleDateChange2 = (date) => {
    setSelectedDate2(date);
    handleChange("toDate", date);
  };

  const handleLeaveStatusChange = (selectedOption) => {
    setSelectedLeaveStatus(selectedOption);
  };

  const calculateDays = (fromDate, fromSession, toDate, toSession) => {
    if (!fromDate || !toDate) return 0;

    const dayDiff = Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24);
    let totalDays = Math.ceil(dayDiff);

    console.log("totalDays", totalDays);
    // Handle the case when both dates are on the same day
    if (fromDate.getTime() === toDate.getTime()) {
      if (fromSession === "session1" && toSession === "session1") return 0.5; // Morning session only
      if (fromSession === "session1" && toSession === "session2") return 1; // Full day (morning + afternoon)
      if (fromSession === "session2" && toSession === "session2") return 0.5; // Afternoon session only
    } else {

      if (fromSession === "session1" && toSession === "session1") totalDays += 0.5; // Half day on the last day
      else if (fromSession === "session1" && toSession === "session2") totalDays += 1; // Full day on the last day
      else if (fromSession === "session2" && toSession === "session1") totalDays += 0; // No days for reverse
      else if (fromSession === "session2" && toSession === "session2") totalDays += 0.5; // Half day on the last day
    }
    return totalDays;
  };

  const disableLeaveDates = (date) => {
    if (!adminLeaves || adminLeaves.length === 0) return true; // Ensure allLeaves exists

    const dateTimestamp = new Date(date).setHours(0, 0, 0, 0); // Normalize selected date

    for (const leave of adminLeaves) {
      // Only consider "Approved" leaves
      if (leave.leaveStatus == "Approved") {
        const leaveStartDate = new Date(parseInt(leave.startDate)).setHours(0, 0, 0, 0);
        const leaveEndDate = new Date(parseInt(leave.endDate)).setHours(0, 0, 0, 0);

        // Debugging logs
        console.log(`Checking date: ${date.toISOString().split("T")[0]}`);
        console.log(`Leave Period: ${new Date(parseInt(leave.startDate)).toISOString().split("T")[0]} to ${new Date(parseInt(leave.endDate)).toISOString().split("T")[0]}`);

        if (dateTimestamp >= leaveStartDate && dateTimestamp <= leaveEndDate) {
          console.log(`Blocked Date: ${date.toISOString().split("T")[0]}`);
          return false; // Disable this date
        }
      }
    }

    return true; // Allow date selection
  };
  const calculateNumberOfDays = () => {
    if (selectedDate1 && selectedDate2) {
      const timeDifference = selectedDate2 - selectedDate1;
      return Math.floor(timeDifference / (1000 * 3600 * 24)) + 1;
    }
    return 0;
  };

  const handleChange = (name, value) => {
    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };

      if (["fromDate", "toDate", "fromSession", "toSession"].includes(name)) {
        updatedData.numberOfDays = calculateDays(
          updatedData.fromDate,
          updatedData.fromSession,
          updatedData.toDate,
          updatedData.toSession
        );
      }

      if (name === "fromDate" && updatedData.toDate && updatedData.toDate < updatedData.fromDate) {
        updatedData.toDate = null;
        updatedData.numberOfDays = 0;
      }
      if (name === "toDate" && updatedData.fromDate && updatedData.toDate < updatedData.fromDate) {
        updatedData.toDate = null;
        updatedData.numberOfDays = 0;
      }

      return updatedData;
    });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();

    const { fromDate, toDate, numberOfDays, fromSession, toSession, leaveStatus, remarks } = formData;
    console.log("Selected leave status:", leaveStatus.value);
    console.log("remarks",remarks)

    const leaveId = leaveData ? leaveData._id : null;
    const newStatus = leaveStatus ? leaveStatus.value : "";
    // const remarks = leaveData ? leaveData.remarks: "";
    try {
      if (leaveData) {
        startTimer()
        //const updatedRemarks = remarks || "No remarks";
        const formStartDateTimestamp = formData.fromDate ? formData.fromDate.getTime() : null;
        const formEndDateTimestamp = formData.toDate ? formData.toDate.getTime() : null;

        // Convert leaveData dates to Unix timestamps
        const leaveStartDateTimestamp = leaveData.startDate ? parseInt(leaveData.startDate) : null;
        const leaveEndDateTimestamp = leaveData.endDate ? parseInt(leaveData.endDate) : null;

        // Check if any of the fields have changed
        const isDifferentLeave =
          leaveData.leaveStatus !== formData.leaveStatus.value ||
          // leaveData?.remarks == formData?.remarks ||
          formStartDateTimestamp !== leaveStartDateTimestamp ||
          formEndDateTimestamp !== leaveEndDateTimestamp ||
          (formData.fromSession === "session1" ? "true" : "false") !== leaveData.session1 ||
          (formData.toSession === "session2" ? "true" : "false") !== leaveData.session2;

        if (!isDifferentLeave) {
          message.error({
            content:"You haven't updated anything. Please make changes before submitting.",
            duration: 3,
            className: 'custom-toast-center',
          });
          return; // Stop the submission if no changes are made
        }
        const leaveUpdateInput = {
          leaveId,
          newStatus,
          remarks: remarks, 
          leaveDays: numberOfDays.toString(),
          newStartDate: fromDate,
          newEndDate: toDate,
          approvedBy: loginUser?.firstname + " " + loginUser?.lastname,
          session1: fromSession === "session1" ? "true" : "false",
          session2: toSession === "session2" ? "true" : "false",
        };
  
        await updateLeaveStatus({
          variables: {
            leaveUpdates: [leaveUpdateInput],
          },
        });
        refetch();
        onClose();
      }
    } catch (error) {
      console.error("Error updating leave status:", error);
    }
  };


  const resetForm = () => {
    setFormData({
      leaveType: null,
      fromDate: null,
      toDate: null,
      numberOfDays: 0,
      leaveReason: "",
      fromSession: "session1",
      toSession: "session1",
      remarks:"",
    });
    onClose();
  };


  return (
    <div
      id="edit_leave"
      className={`modal custom-modal fade ${isOpen ? "show" : ""}`}
      role="dialog"
      style={{ display: isOpen ? "block" : "none" }}
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              {leaveData ? "Edit Leave" : "Add Leave"}
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              onClick={resetForm}
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Leave Type <span className="text-danger">*</span>
                </label>
                <Select
                  options={leaves}
                  placeholder="Select"
                  value={formData.leaveType}
                  onChange={(selected) => handleChange("leaveType", selected)}
                  styles={customStyles}
                  isDisabled={true}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  From <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-md-8">
                    <div className="cal-icon">
                      <DatePicker
                        selected={formData.fromDate}
                        onChange={(date) => handleChange("fromDate", date)}
                        className="form-control datetimepicker"
                        type="date"
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        filterDate={disableLeaveDates}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                      value={formData.fromSession}
                      onChange={(e) => handleChange("fromSession", e.target.value)}
                      className="form-control"
                      disabled
                    >
                      <option value="session1">Session 1</option>
                      <option value="session2">Session 2</option>
                    </select>
                  </div>
                </div>

              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  To <span className="text-danger">*</span>
                </label>
                <div className="row">
                  <div className="col-md-8">
                    <div className="cal-icon">
                      <DatePicker
                        selected={formData.toDate}
                        onChange={(date) => handleChange("toDate", date)}
                        className="form-control datetimepicker"
                        type="date"
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        filterDate={disableLeaveDates}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                      value={formData.toSession}
                      onChange={(e) => handleChange("toSession", e.target.value)}
                      className="form-control"
                      disabled
                    >
                      <option value="session1">Session 1</option>
                      <option value="session2">Session 2</option>
                    </select>
                  </div>
                </div>

              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Number of days <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  readOnly
                  type="text"
                  value={formData.numberOfDays}
                  disabled
                // value={leaveData?.leaveDays} 
                //value={leaveDays || calculateNumberOfDays()}
                //value={formData.numberOfDays}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Remaining Leaves <span className="text-danger">*</span>
                </label>
                <input
                  className="form-control"
                  readOnly
                  value={leaveData?.remainingLeaves || 12}
                  type="text"
                  disabled
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">
                  Leave Status <span className="text-danger">*</span>
                </label>
                <Select
                  options={leaveStatus}
                  placeholder="Select"
                  value={formData.leaveStatus} // Should be the object that has both `value` and `label`
                  onChange={(selected) => handleChange("leaveStatus", selected)} // Pass the whole selected object
                  styles={customStyles}
                  disabled={false}
                />
              </div>

              <div className="input-block mb-3">
                <label className="col-form-label">Leave Remarks<span className="text-danger">*</span></label>
                <textarea
                  className="form-control"
                  rows="4"
                  value={formData.remarks} 
                  onChange={(e) => handleChange("remarks",e.target.value)}
                ></textarea>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit" disabled={isDisabled}>
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
