import { gql } from "@apollo/client";

export const RESET_TOKEN = gql`
    mutation ResetToken($email: String){
        ResetToken (email: $email){
            email
            token
            success   
            message
        }
    }
`;