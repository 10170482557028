import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  leaves: [],
};

const leavesSlice = createSlice({
  name: "leaves",
  initialState,
  reducers: {
    addLeave: (state, action) => {
      state.leaves.push(action.payload);
    },
    removeLeave: (state, action) => {
      state.leaves = state.leaves.filter(leave => leave._id !== action.payload);
    },
    updateLeave: (state, action) => {
      const index = state.leaves.findIndex(leave => leave._id === action.payload._id);
      if (index !== -1) {
        state.leaves[index] = action.payload;
      }
    },
    setAllLeaves: (state, action) => {
      state.leaves = action.payload; // Replaces all leave requests at once
    },
  },
});

export const { addLeave, removeLeave, updateLeave, setAllLeaves } = leavesSlice.actions;

export default leavesSlice.reducer;
