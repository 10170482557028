import React, { useState, useEffect, useRef } from "react";
import { message } from 'antd';
import { useMutation } from "@apollo/client";
import { UPDATE_CLIENT } from "../../Queries/Orders";
import { useDispatch, useSelector } from "react-redux";
import { updateClient } from "../../Slices/clientSlice";
import { useErrorLogger } from "../../utils";
import { renderOptions } from "../../utilsdata";
import { DEPARTMENT_CONFIG_CLIENT } from "../../databucket";

export const AllClientEditPopup = ({ clientData }) => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [updateclient] = useMutation(UPDATE_CLIENT);
  const [ischanged, setIschanged] = useState(false);
  const { logError } = useErrorLogger();
  const [isFormValid, setIsFormValid] = useState(false);
  const [isUserDataValid, setIsUserDataValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const updatedClientData = useSelector(state =>
    state.clients.clients?.find(client => client?.id === clientData?.id)
  );
  const [formData, setFormData] = useState({
    clientName: "",
    mobileNo: "",
    emailId: "",
    status: "",
    timeZone: "",
    department: "",
  });
  const options = useSelector((state) => state.dataBucket.databucket);

  const [errores, setErrores] = useState({});
  const [errorChange, setErrorChange] = useState(false);

  const resetErrors = () => {
    setErrors({});
    setErrores({});
    setIsUserDataValid(false);
    setIschanged(false);
    setErrorChange(false);
  };

  const handleClose = () => {
    if (!ischanged || (Object.keys(errores).length === 0 && isUserDataValid)) {
      resetErrors();
      setFormData(clientData);
    }
  };

  const namepattern = /^[A-Za-z\s-]+$/;
  const mobilePattern = /^(?:\+1[-.\s]?)?(\(?\d{3}\)?[-.\s]?)?[\d]{3}[-.\s]?\d{4}$/;  // Regex for US and Indian numbers
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  
  useEffect(() => {
    try {
      if (updatedClientData) {
        setFormData(prevData => ({
          ...prevData,
          ...updatedClientData,
          status: updatedClientData?.status || "Active",
        }));
        resetErrors();
      }
    } catch (error) {
      logError("UpdateClientModal", "useEffect-Setdata", error.message);
    }
  }, [updatedClientData]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData((prevData) => ({
      ...prevData,
      [name]: trimmedValue
    }));
  
    let newErrors = { ...errores };
    switch (name) {
      case 'mobileNo':
        newErrors.mobileNo = mobilePattern.test(value) ? null : "Invalid mobile number.";  // Updated regex
        break;
      case 'emailId':
        newErrors.emailId = emailPattern.test(value) ? null : "Must be a company email.";
        break;
      case 'clientName':
        newErrors.clientName = /^[A-Za-z0-9\s-]+$/.test(value) ? null : "Client name should only contain letters, spaces, or hyphens.";
        break;
      case 'department':
        newErrors.department = value ? null : "Department is required.";
        break;
      case 'timeZone':
        newErrors.timeZone = value ? null : "Time zone is required.";
        break;
      case 'status':
        newErrors.status = value ? null : "Status is required.";
        break;
      default:
        newErrors[name] = value ? null : `${name.charAt(0).toUpperCase() + name.slice(1)} is required.`;
        break;
    }
  
    setErrores(newErrors);
    const requiredFields = ['mobileNo', 'emailId', 'clientName', 'department', 'timeZone', 'status'];
    const allFieldsValid = requiredFields.every(field => {
      const fieldValue = formData[field];
      const fieldError = newErrors[field];
      return fieldValue && !fieldError;
    });
    setIsUserDataValid(allFieldsValid);
    setErrorChange(true);
  };
  


  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsButtonDisabled(true);
    let newErrors = {};
    if (!formData.mobileNo) {
      newErrors.mobileNo = "Mobile number is required.";
    } else if (!mobilePattern.test(formData.mobileNo)) {
      newErrors.mobileNo = "Invalid mobile number. Please enter a 10-digit number.";
    }
    if (!formData.emailId) {
      newErrors.emailId = "Email is required.";
    } else if (!emailPattern.test(formData.emailId)) {
      newErrors.emailId = "Invalid email format.";
    }
    if (!formData.clientName) {
      newErrors.clientName = "client name is required.";
    }
    //   else if (!namepattern.test(formData.clientName)) {
    //   newErrors.clientName = "Valid client name is required.";
    // }
    if (!formData.department) newErrors.department = "department is required";

    if (!formData.timeZone) newErrors.timeZone = "Timezone is required";

    if (!formData.status) newErrors.status = "status is required";

    setErrores(newErrors);

    const noErrors = Object.keys(newErrors).length === 0;

    if (isUserDataValid && newErrors) {
      try {
        setIsUserDataValid(true);
        // setErrores({});
        const { data } = await updateclient({
          variables: {
            id: clientData?.id,
            clientName: formData?.clientName,
            mobileNo: formData?.mobileNo,
            emailId: formData?.emailId.toLowerCase(),
            status: formData?.status,
            timeZone: formData?.timeZone,
            department: formData?.department,
          },
        });

        setErrorChange(false);
        setIsUserDataValid(false);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
        message.info({
            content: data?.updateclient?.message,
          duration: 2,
          className: 'custom-toast-center',
        });
        dispatch(updateClient({
          id: clientData?.id,
          clientName: formData.clientName,
          mobileNo: formData.mobileNo,
          emailId: formData?.emailId.toLowerCase(),
          status: formData.status || 'Active',
          timeZone: formData.timeZone,
          department: formData.department,
        }));
        setIsUserDataValid(true);
      }
      catch (error) {
        logError("UpdateClientModal", "handleSubmit", error.message);
        message.error("client already exists with this details");
        setFormData(clientData);
      }
    }
    else {
      if (!noErrors) {
        message.info({
          content: "Fix the errors.",
          duration: 2,
          className: 'custom-toast-center',
        });
      } else {
        message.info({
          content: "No content is changed.",
          duration: 2,
          className: 'custom-toast-center',
        });
      }
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };

  return (
    <div id="edit_client" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Client</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>x</button>
          </div>
          <div className="modal-body">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <label className="col-form-label">Client Name</label>
                  <input
                    name="clientName"
                    className="form-control"
                    value={formData?.clientName}
                    onChange={handleChange}
                  />
                  {errores.clientName && <small className="text-danger">{errores.clientName}</small>}
                </div>
                <div className="col-sm-6">
                  <label className="col-form-label">Mobile No.</label>
                  <input
                    name="mobileNo"
                    className="form-control"
                    value={formData?.mobileNo}
                    onChange={handleChange}
                  />
                  {errores.mobileNo && <small className="text-danger">{errores.mobileNo}</small>}
                </div>

                <div className="col-sm-6">
                  <label className="col-form-label">Email</label>
                  <input
                    name="emailId"
                    className="form-control"
                    value={formData?.emailId}
                    onChange={handleChange}
                  />
                  {errores.emailId && <small className="text-danger">{errores.emailId}</small>}
                </div>

                <div className="col-sm-6">
                  <label className="col-form-label">Status</label>
                  <select
                    name="status"
                    className="form-control"
                    value={formData?.status}
                    onChange={handleChange}
                  >
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  {errores.status && <small className="text-danger">{errores.status}</small>}
                </div>

                <div className="col-sm-6">
                  <label className="col-form-label">
                    Time Zone <span className="text-danger">*</span>
                  </label>
                  <div className="dropdown">
                    <select
                      name="timeZone"
                      className={`form-control ${errors.timeZone ? 'is-invalid' : ''}`}
                      value={formData.timeZone}
                      onChange={handleChange}
                    >
                      <option value="">Select Time Zone</option>
                      <option value="Eastern Time">Eastern Time</option>
                      <option value="Central Time">Central Time</option>
                      <option value="Mountain Time">Mountain Time</option>
                      <option value="Pacific Time">Pacific Time</option>
                      <option value="Hawaii Time">Hawaii Time</option>
                    </select>
                    <i className="dropdown-icon"></i>
                  </div>
                  {errors.timeZone && <small className="text-danger">{errors.timeZone}</small>}
                </div>

                <div className="col-sm-6">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <div className="dropdown">
                    <i className="dropdown-icon"></i>
                    <select
                      name="department"
                      className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                      value={formData.department}
                      onChange={handleChange}
                    >
                      {renderOptions(options, { name: "DEPARTMENT_CONFIG_CLIENT" }, DEPARTMENT_CONFIG_CLIENT)}
                    </select>
                    <i className="dropdown-icon"></i>
                  </div>
                  {errors.department && <small className="text-danger">{errors.department}</small>}
                </div>
              </div>


              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  disabled={isButtonDisabled}
                  {...(isUserDataValid ? { "data-bs-dismiss": "modal" } : {})}
                >
                  Update
                </button>

              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
