import { Select } from 'antd';
import { DEPARTMENTS } from './databucket';
const { Option } = Select;


 

// export const renderOptions = (options, criteria, fallbackData = []) => {
//     console.log("Options:", options, "Fallback Data:", fallbackData);

//     // Filter the options based on criteria and conditions
//     const filteredOptions = options?.reduce((acc, item) => {
//         if (item.name === criteria.name && item.data) {
//             const filteredSubItems = item.data.filter(
//                 (subItem) => subItem.status === "active" && subItem.flag === true
//             );
//             acc.push(...filteredSubItems);  // Add valid subItems to the accumulator
//         }
//         console.log("aefsfdsv",acc)
//         return acc;
//     }, []);

//     // If valid options are found, return them, otherwise use fallbackData
//     const renderData = filteredOptions?.length > 0 ? filteredOptions : fallbackData;

//     return renderData?.map((item, index) => (
//         <Option key={index} value={item?._id || item}>
//             {item?.name || item}
//         </Option>
//     ));
// };


// export const renderOptions = (options, criteria, fallbackData = []) => {
//     console.log("Options:", options, "Fallback Data:", fallbackData);

//     // Check if options is missing or empty or if no option matches criteria.name
//     const hasMatchingOption = options?.some((item) => item.name != criteria.name.trim() || !item.status);

//     if (!options || options.length === 0 || !hasMatchingOption) {
//         // If no matching options found, return fallback data
//         return fallbackData?.map((item, index) => (
//             <Option key={index} value={item}>
//                 {item}
//             </Option>
//         ));
//     }

//     // Process the options if available and matching the criteria
//     const filteredOptions = options.reduce((acc, item) => {
//         // Compare item name with criteria name and ensure data and status exist
//         if (item.name === criteria.name.trim() && item.data && item.status) {
//             // Filter subItems based on the active status and flag === true
//             const filteredSubItems = item.data.filter(
//                 (subItem) => subItem.status === "active" && subItem.flag === true
//             );

//             // Accumulate valid subItems into the result array
//             acc.push(...filteredSubItems);
//         }
//         console.log("rrgrgr",DEPARTMENTS)
//         return acc;
//     }, []);

//     // If we have filtered options, map them to <Option> components
//     if (filteredOptions.length > 0) {
//         return filteredOptions.map((subItem, index) => (
//             <Option key={index} value={subItem?._id}>
//                 {subItem?.name}
//             </Option>
//         ));
//     }

//     // If no valid options are found, return fallback data
//     return fallbackData?.map((item, index) => (
//         <Option key={index} value={item}>
//             {item}
//         </Option>
//     ));
// };


// export const renderOptions = (options, criteria, fallbackData = []) => {
//     console.log("Options:", options, "Fallback Data:", fallbackData);

//     // Check if options are missing or empty or if no option matches criteria.name
//     const hasMatchingOption = options?.find((item) => item.name === criteria.name.trim() && item.status);

//     // if (!options || options.length === 0 || !hasMatchingOption) {
//     //     // If no matching options found, return fallback data
//     //     return fallbackData?.map((item, index) => (
//     //         <option key={index} value={item}>
//     //             {item.label}
//     //         </option>
//     //     ));
//     // }
//          const acc=[];
//     // Process the options if available and matching the criteria
//     if (hasMatchingOption && hasMatchingOption.data>0 ) {
//         const itemData = hasMatchingOption.data;

//         if (itemData && Array.isArray(itemData) && hasMatchingOption.status) {
//             // Filter and map the data based on the active status and flag condition
//             const data = itemData
//                 .filter(subItem => subItem.status === "active" && subItem.flag === true)  // Filtering condition
//                 .map(subItem => ({
//                     label: subItem.name,  // Assuming 'name' exists on each subItem
//                     value: subItem.name   // Assuming 'name' exists and is valid
//                 }));

//             // Push the mapped data into the accumulator
          
//                 return data.map((subItem, index) => (
//                     <option key={index} value={subItem?._id}>
//                         {subItem?.name}
//                     </option>
//                 ));
            
//         }
//     }else{
//         return fallbackData?.map((item, index) => (
//             <option key={index} value={item}>
//                 {item.label}
//             </option>
//         ));
//     }
    
//     // If we have filtered options, map them to <Option> components
  

//     // If no valid options are found, return fallback data
   
// };

export const renderOptions = (options, criteria, fallbackData = []) => {
    console.log("Options:", options, "Fallback Data:", fallbackData);

    // Use find to check if a matching option exists
    const hasMatchingOption = options?.find((item) => item.name === criteria.name.trim() && item.status);

    if (hasMatchingOption && hasMatchingOption.data && Array.isArray(hasMatchingOption.data)) {
        const itemData = hasMatchingOption.data;

        // Filter and map the data based on the active status and flag condition
        const filteredData = itemData
            .filter(subItem => subItem.status === "active" && subItem.flag === true)
            .map(subItem => ({
                label: subItem.name,  // Assuming 'name' exists on each subItem
                value: subItem.name   // Assuming 'name' exists and is valid
            }));

        // If we have valid filtered data, map them to <option> components
        if (filteredData.length > 0) {
            return filteredData.map((subItem, index) => (
                <option key={index} value={subItem.value}>
                    {subItem.label}
                </option>
            ));
        }
    }

    // If no valid options were found, return fallback data
    return fallbackData?.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));
};

// export const renderOptions = (options, criteria, fallbackData = []) => {
//     console.log("Options:", options, "Fallback Data:", fallbackData);

//     // Use find to get the first matching option based on the criteria.name
//     const val = options?.find((item) => item.name === criteria.name.trim() && item.status);

//     // If a matching option is found, process it
//     if (val) {
//         const itemData = val.data;

//         // Check if the data exists and filter based on active status and flag condition
//         if (itemData && Array.isArray(itemData) && val.status) {
//             const filteredSubItems = itemData
//                 .filter((subItem) => subItem.status === "active" && subItem.flag === true)  // Filtering condition
//                 .map((subItem) => ({
//                     label: subItem.name,  // Assuming 'name' exists on each subItem
//                     value: subItem._id   // Assuming '_id' exists and is valid
//                 }));

//             // Return the options as <option> components
//             return filteredSubItems.map((item, index) => (
//                 <option key={index} value={item.value}>
//                     {item.label}
//                 </option>
//             ));
//         }
//     }

//     // If no matching options were found, return fallback data
//     return fallbackData?.map((item, index) => (
//         <option key={index} value={item}>
//             {item.label}
//         </option>
//     ));
// };

// export const renderOptionsformultiple = (options, criteria, fallbackData = []) => {
//     console.log("Options:", options, "Fallback Data:", fallbackData);

//     // First, check if there are valid options that match the criteria
//     const hasValidOption = options?.some(
//         (item) => item.name != criteria.name && !item.status
//     );

//     // If no valid options match the criteria, return fallback data
//     if (!hasValidOption) {
//         return fallbackData?.map((item, index) => (
//             <Option key={index} value={item}>
//                 {item}
//             </Option>
//         ));
//     }

//     // Process the options and filter based on criteria and active status
//     const filteredOptions = options.reduce((acc, item) => {
//         // Compare item name with criteria name and ensure data and status exist
//         if (item.name === criteria.name && item.data && item.status) {
//             // Filter subItems based on the active status and flag === true
//             const filteredSubItems = item.data.filter(
//                 (subItem) => subItem.status === "active" && subItem.flag === true
//             );

//             // Accumulate valid subItems into the result array
//             acc.push(...filteredSubItems);
//         }
//         return acc;
//     }, []);

//     // If we have filtered options, map them to <Option> components
//     if (filteredOptions.length > 0) {
//         return filteredOptions.map((subItem, index) => (
//             <Option key={index} value={subItem?._id}>
//                 {subItem?.name}
//             </Option>
//         ));
//     }

//     // If no valid options are found, return fallback data
//     return fallbackData?.map((item, index) => (
//         <Option key={index} value={item}>
//             {item}
//         </Option>
//     ));
// };
export const renderOptionsformultiple = (options, criteria, fallbackData = []) => {
    console.log("Options:", options, "Fallback Data:", fallbackData);
        // Check if the item name matches the criteria name dynamically
        const acc=[]
        const val=options?.find((item)=>item.name===criteria.name && item.status )
        if (val) {
            const itemData = val.data;

            if (itemData && Array.isArray(itemData) && val.status) {
                // Filter and map the data based on the active status and flag condition
                const data = itemData
                    .filter(subItem => subItem.status === "active" && subItem.flag === true)  // Filtering condition
                    .map(subItem => ({
                        label: subItem.name,  // Assuming 'name' exists on each subItem
                        value: subItem.name   // Assuming 'name' exists and is valid
                    }));

                // Push the mapped data into the accumulator
                acc.push(...data);
            } 
        }else {
            // If the item name does not match the criteria name, add the fallback data only once
            if (acc.length === 0) {  // Add fallback data only if it's not already added
                const menu = fallbackData.map(menu => ({
                    label: menu.label,  // Assuming 'label' exists
                    value: menu.value   // Assuming 'value' exists
                }));
                acc.push(...menu);
            }
        
    }
        console.log("Accumulated Options:", acc);
        return acc; // Return the accumulated options
      // Start with an empty array
};




    



