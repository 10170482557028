// // ** Third Party Imports
// import { createSlice } from "@reduxjs/toolkit";
// import { GET_USER_DETAILS } from "../Queries/Users";
// import { client } from "..";
// const initialState =  {
//   loginUserDetails:{},
//   getAllClients:[],
//   userStatus:false,
//   allUsers:[],
//   // loginUserDetails: JSON.parse(localStorage.getItem('loginUserDetails')) || null, // Load from localStorage or set null
// }

// const loginUserSlice = createSlice({
//   name: "loginUser",
//   initialState,
//   reducers: {
//     setLoginUserDetails: (state, action) => {
//         state.loginUserDetails = action.payload;
//         // localStorage.setItem('loginUserDetails', JSON.stringify(action.payload)); // Save to localStorage
//     }, 
//     getAllClientsData: (state,action)=>{
//       console.log("dacadcdac",action.payload)
//       state.getAllClients = action.payload
//       console.log("dacadcdac2",state.getAllClients)
//     },
//     setUserStatus: (state, action) => {
//       state.userStatus = action.payload;
//     },
//     setAllUsers:(state, action) => {
//       state.allUsers = action.payload;
//     },
//   },
// });


// export const { setLoginUserDetails,setUserStatus, setAllUsers, getAllClientsData } = loginUserSlice.actions;

// export default loginUserSlice.reducer;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_USER_DETAILS, FETCH_ALL_USERS } from '../Queries/Users';
import { client } from '..';
import { SetAllEmployees } from './employeeslice';

// Initial state
const initialState = {
  loginUserDetails: {},
  getAllClients: [],
  userStatus: false,
  allUsers: [],
};

// Async thunk to fetch user details using Apollo Client's mutate method
export const fetchUserDetails = createAsyncThunk(
  'loginUser/fetchUserDetails', // Action type string
  async (userId, { rejectWithValue }) => {
    try {
      // Use Apollo Client's mutate method for GraphQL mutation
      const { data } = await client.mutate({
        mutation: GET_USER_DETAILS,
        variables: { id: userId }, // Pass the userId or any other necessary variables
      });
      return data.getUserDetails[0]; // Returning the first user detail
    } catch (error) {
      return rejectWithValue(error.message); // In case of error, we return the error message
    }
  }
);
export const fetchAllUsers = createAsyncThunk(
  'users/fetchAllUsers', // Action type string
  async (_, { dispatch, rejectWithValue }) => { // Destructure from thunkAPI, not the second argument
    try {
      // Use Apollo Client's mutate method for GraphQL mutation
      const { data } = await client.mutate({
        mutation: FETCH_ALL_USERS,
      });
      dispatch(SetAllEmployees(data.fetchAllUsers.response));

      return data.fetchAllUsers.response; // Return the list of all users
    } catch (error) {
      return rejectWithValue(error.message); // In case of error, return error message
    }
  }
);


const loginUserSlice = createSlice({
  name: 'loginUser',
  initialState,
  reducers: {
    setLoginUserDetails: (state, action) => {
      state.loginUserDetails = action.payload;
    },
    getAllClientsData: (state, action) => {
      console.log('dacadcdac', action.payload);
      state.getAllClients = action.payload;
      console.log('dacadcdac2', state.getAllClients);
    },
    setUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    setAllUsers: (state, action) => {
      state.allUsers = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        // On successful fetch, update the user details and status
        state.loginUserDetails = action.payload;
        state.userStatus = true;
      })
      .addCase(fetchAllUsers.fulfilled, (state, action,) => {
        state.loading = false;
        state.allUsers = action.payload;
        state.userStatus = true;

        //     state.employee.allEmployees = action.payload
        //     .map(emp => ({
        //       ...emp,
        //       token: emp.token || 'N/A', // Add token field if it's missing
        //     }))
        //     .sort((a, b) => a.firstname.localeCompare(b.firstname)); // Sort by firstname
      });


  },
});

export const { setLoginUserDetails, setUserStatus, setAllUsers, getAllClientsData } = loginUserSlice.actions;
export default loginUserSlice.reducer;


