// ** Graphql
import { gql } from '@apollo/client';

export const AUTH_LOGIN = gql`
  mutation ($email: String!, $password: String  ) {
    authLogin(email: $email, password: $password) {
      token
      status
      message
      response{
         menu {
        name
        status
        Subfields {
          name
          status
        }
      }
      }
    }
  }
`;
export const AUTH_RESET_PASSWORD = gql`
  mutation ($email: String!, $password: String!, $token: String!, $isPasswordGenerated: Boolean ) {
    createpassword(email: $email, password: $password, token: $token, isPasswordGenerated: $isPasswordGenerated) {
      status
      message
    }
  }
`;

export const GET_ALL_USERS = gql`
  query {
    getAllUsers {
      status
      message
      response{
        id
        firstname
        lastname
        email
        mobile
        gender
        token
        isPasswordGenerated
        clients
        department
        employeestatus
        role
        reportingManager
       
      }
    }
  }
`;

export const FETCH_ALL_USERS = gql`
  mutation($loginUserId: String) {
    fetchAllUsers (loginUserId: $loginUserId){
      status
      message
      response{
        id   
    EmpID
    role
    createdDate
    firstname
    lastname
    designation
    department
    reportingManager
    signedIn
    employeestatus
    gender
    clients
    dob
    image
    maritalStatus
    bloodGroup
    currentAddress
    permanentAddress
    mobile
    alternativeNumber
    personalEmail
    email
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
      
      }
    }
  }
`;


export const GET_USER_DETAILS = gql`
  mutation ($id: String!) {
    getUserDetails(id: $id) {
      id
     EmpID
    firstname
    lastname
    createdTime
    designation
    department
    reportingManager
    image
    punchInStatus
    role
    clients
    employeestatus
    clientKey
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
       menu {
        name
        status
        Subfields {
       displayName
          name
          status
        }
      }
        route{
          name
          status
        }
      client {
      NameOfClient
      processes
      role
      SlotNo
    }
    }
  }
`;

export const GET_ALL_CLIENTS = gql`
  query {
    getAllClients {
      status
      message
      response{
        id
        clientName
        emailId
        mobileNo
        department
        timeZone
        status
        
      }
    }
  }
`;

export const GET_ALL_INSURANCE_CARRIERS = gql`
  query {
    getAllInsuranceCarriers {
      status
      message
      response{
        id
        carrierName
        status
        userId
      }
    }
  }
`;

export const GET_ALL_CURRENT_CARRIERS = gql`
  query {
    getAllCurrentCarriers {
      status
      message
      response{
        id
        carrierName
        status
        userId
      }
    }
  }
`;

export const GET_ALL_CARRIERS = gql`
query{
getAllCarriers{
 status
 message
 response {
        id
        userId
        carrierName
        status
        flag
      }
}
}
`