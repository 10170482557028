import React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";

const CreateOPS = ({
  leadsOPS,
  handleDeleteOPS,
  handleOPSInputChange,
  handleAddNewOPS,
  formData,
  handleChange,
  dataBucket,
  userNames,
}) => {
  return (
    <>

      {leadsOPS?.map((call, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">
              Lead OPS {index + 1}
              <Link
                to="#"
                className="delete-icon btn btn-sm float-end"
                onClick={() => handleDeleteOPS(index)}
              >
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h3>

            <div className="row">
              {/* Call Date */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Date</label>
                <DatePicker
                  value={call.date ? dayjs(call.date, "DD/MM/YYYY") : null}
                  onChange={(date) =>
                    handleOPSInputChange(index, {
                      target: { name: "date", value: date ? dayjs(date).format("DD/MM/YYYY") : "" },
                    })
                  }
                  className="form-control"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                />
              </div>

              {/* Call Time */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Time</label>
                <TimePicker
                  id={`time-picker-${index}`}
                  value={call.time ? dayjs(call.time, "HH:mm:ss") : null}
                  onChange={(time) => {
                    if (time) {
                      const formattedTime = time.format("HH:mm:ss");
                      handleOPSInputChange(index, {
                        target: { name: "time", value: formattedTime },
                      });
                    }
                  }}
                  className="form-control"
                  placeholder="Select time"
                />
              </div>

              {/* Call Type */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Type</label>
                <select
                  name="callType"
                  className="form-control"
                  value={call.callType || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                >
                  <option value="" disabled>
                    Select Call Type
                  </option>
                  {dataBucket.SALES_SCHEDULED_CALL_TYPE?.map((type, key) => (
                    <option key={key} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
              </div>

              {/* Emails */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Emails</label>
                <input
                  type="text"
                  name="emails"
                  className="form-control"
                  value={call.emails || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* Phone Number */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Phone Number</label>
                <input
                  type="text"
                  name="phonenumber"
                  className="form-control"
                  value={call.phonenumber || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* Call Scheduled By */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Scheduled By</label>
                <select
                  name="callScheduledBy"
                  className="form-control"
                  value={call.callScheduledBy || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                >
                  <option value="" disabled>
                    Select User
                  </option>
                  {userNames?.map((name, idx) => (
                    <option key={idx} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* AMS Used By Client */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">AMS Used By Client</label>
                <input
                  type="text"
                  name="amsusedbyClient"
                  className="form-control"
                  value={call.amsusedbyClient || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* Client Requirements */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Client Requirements</label>
                <input
                  name="clientRequirements"
                  className="form-control"
                  value={call.clientRequirements || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* Pros */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Pros</label>
                <input
                  name="pros"
                  className="form-control"
                  value={call.pros || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* Cons */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Cons</label>
                <input
                  name="cons"
                  className="form-control"
                  value={call.cons || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>


              {/* Call Connected */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Connected</label>
                <input
                  type="checkbox"
                  name="callConnected"
                  className="form-check-input"
                  checked={call.callConnected || false}
                  onChange={(e) =>
                    handleOPSInputChange(index, {
                      target: { name: "callConnected", value: e.target.checked },
                    })
                  }
                />
              </div>

              {/* Client Attendees */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Client Attendees</label>
                <input
                  type="text"
                  name="clientAttendees"
                  className="form-control"
                  value={call.clientAttendees || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>

              {/* BluePlanit Attendees */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">BluePlanit Attendees</label>
                <input
                  type="text"
                  name="blueplanitAttendees"
                  className="form-control"
                  value={call.blueplanitAttendees || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                />
              </div>
               {/* Remarks */}
               <div className="col-md-12 mb-3">
                <label className="col-form-label">Remarks</label>
                <textarea
                  name="remarks"
                  className="form-control"
                  value={call.remarks || ""}
                  onChange={(e) => handleOPSInputChange(index, e)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Add New OPS Button */}
      <div className="mb-3 mt-2">
        <Link to="#" onClick={handleAddNewOPS}>
          <i className="fa-solid fa-plus-circle" /> Add OPS
        </Link>
      </div>

      {/* Comments Section */}
      <div className="mb-3">
        <label className="col-form-label">OPS Comments</label>
        <textarea
          name="opsTeamComments"
          className="form-control"
          value={formData.opsTeamComments || ""}
          onChange={(e) => handleChange("opsTeamComments", e.target.value)}
        ></textarea>
      </div>
    </>
  );
};

export default CreateOPS;
