import { useState } from 'react';
import { Formik } from 'formik';
import { useQuery, useMutation } from '@apollo/client';
import * as DataBucket from '../../../databucket'

import { UPDATE_WORKHISTORY } from '../../../Queries/Orders';
import { Starttask } from '../../../Slices/timerslice';
// import { timerHistoryPayloadChange,settaskStartTime } from '../../../Slices/user';

import { useDispatch, useSelector } from 'react-redux';
import { renderOptions } from '../../../utilsdata';
import { Modal, Button, Form, Input, Select, Typography, Row, Col } from 'antd';;
const { Option } = Select;
const { TextArea } = Input;

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};


const CreateOrder = (props) => {
    const { open, handleClose, handleStart, RequestInfo, setWorkHistoryId } = props;
    const [updateWorkHistory, { data, loading, error }] = useMutation(UPDATE_WORKHISTORY);
    const dispatch = useDispatch();

const options = useSelector((state) => state?.dataBucket?.databucket)
    const initialValues = {

        requestId: RequestInfo.requestId,
        clientName: '',
        processType: '',
        taskType: '',
        email: '',
        subject: '',
        body: '',
    }
    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const submit = (values) => {
      

        const valuesObject = {
           
            userId: parseJwt(token)?.id.toString(),
            emailId: RequestInfo.id,
            requestId: RequestInfo.requestId || '',
            clientName: RequestInfo.clientName || '',
            processType: values.processType,
            taskType: values.taskType,
            email: values?.email,
            subject: values?.subject,
            body: values?.body,
            time: Date.now().toString(),
            endTime: "",
        }
        const response = updateWorkHistory({variables:valuesObject})
        const payload = {
            department: values.processType,
            task: values.taskType,
            time: Date.now(),
            requestId: RequestInfo.requestId,
        };
        localStorage.setItem('time', new Date().getTime());
        localStorage.setItem('task', values.taskType);
        localStorage.setItem('department', "Operations");
        localStorage.setItem('requestId', RequestInfo.requestId.toString());
        dispatch(Starttask(payload))
        handleStart();
        console.log("valuesObject",valuesObject)
        handleClose()
    }

    //const theme = useTheme();
    //const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    return (
        <>
          <Modal
            open={open}
            onCancel={handleClose}
            footer={null}
            width="50%"
            centered
        >
            <Formik
                initialValues={initialValues}
                //validate={validate}
                onSubmit={submit}
            >
                {({ handleChange, handleSubmit, values, errors, touched }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Row gutter={16}>
                            <Col span={10}>
                            <Form.Item label="Request#">
                                <Typography.Text strong>{RequestInfo?.requestId}</Typography.Text>
                            </Form.Item>
                            </Col>
                            <Col span={14}>
                            <Form.Item label="ClientName">
                                <Typography.Text strong>{RequestInfo?.clientName}</Typography.Text>
                            </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                            <Form.Item label="Services">
                                <Select value={values.processType} onChange={(value) => handleChange({ target: { name: 'processType', value } })} name="processType">
                                    {renderOptions(options, { name: "PROCESS_TYPE_CONFIG" }, DataBucket.PROCESS_TYPE_CONFIG)}
                                </Select>
                            </Form.Item>
                            </Col>
                            <Col span={12}>
                            <Form.Item label="Task">
                                <Select value={values.taskType} onChange={(value) => handleChange({ target: { name: 'taskType', value } })} name="taskType">
                                    {/* {DataBucket.REPORT_TASKS?.map((Item, key) => (
                                        <Option key={key} value={Item}>
                                            {Item}
                                        </Option>
                                    ))} */}
                                    {renderOptions(options, { name: "TASKS_TO_CONFIG" }, DataBucket.TASKS_TO_CONFIG)}
                                </Select>
                            </Form.Item>
                            </Col>
                        </Row>

                        <Row justify="end" gutter={16}>
                            <Col>
                                <Button onClick={handleClose} variant="outlined">
                                    Cancel
                                </Button>
                            </Col>
                            <Col>
                                <Button type="primary" htmlType="submit">
                                    Submit
                                </Button>
                            </Col>
                        </Row>
                    </form>
                )}
            </Formik>
            </Modal>
        </>
    )
}
export default CreateOrder;