import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TYPES_OF_LEAVES } from "../../databucket";
import { CREATE_LEAVE_REQUEST } from "../../Leaves/Leaves";
import { renderOptionsformultiple } from "../../utilsdata";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { addLeave, updateLeave } from "../../Slices/LeavesSlice";
import Swal from "sweetalert2";
import { gql } from "@apollo/client";
const UPDATE_LEAVE_STATUS = gql`
    mutation updateLeaveStatus($leaveUpdates: [LeaveUpdateInput!]!) {
      updateLeaveStatus(leaveUpdates: $leaveUpdates) {
       _id
      employeeId
      leaveType
      leaveDays
      startDate
      endDate
      leaveStatus
      leaveReason
      managerApproval
      createdAt
      status
      session1
      session2
      }
    }
  `;
 
const EmployeeLeaveModelPopup = (props) => {
   
  const { leaveData, isOpen, onClose, refetch,allLeaves,remainingLeaves } = props;
  const dispatch = useDispatch();
  const token = localStorage.getItem("token");
 
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
 
  const leaves = [
    { value: "", label: "Select Leave Type" },
    { value: "Paid Leaves", label: "Paid Leave" },
    { value: "Casual Leave", label: "Casual Leave" },
    { value: "Sick Leave", label: "Sick Leave" },
  ];
 
  const [formData, setFormData] = useState({
    leaveType: null,
    fromDate: null,
    toDate: null,
    numberOfDays: 0,
    leaveReason: "",
    fromSession: "",
    toSession: "",
  });
 
  // const allLeaves = useSelector((state) => state?.leaves?.leaves);
 
  console.log("leaveData",leaveData);
 
  useEffect(() => {
    if (leaveData) {
      setFormData({
        leaveType: leaves.find((leave) => leave.value === leaveData.leaveType) || null,
        fromDate: leaveData.startDate ? new Date(parseInt(leaveData.startDate)) : null,
        toDate: leaveData.endDate ? new Date(parseInt(leaveData.endDate)) : null,
        leaveReason: leaveData.leaveReason || "",
        numberOfDays: leaveData.noOfDays || 0,
        fromSession: leaveData?.session1 ==="true"? "session1" : "session2",
        toSession: leaveData?.session2 ==="true"? "session2" : "session1",
      });
    }
  }, [leaveData]);
 
  const userId = parseJwt(token)?.id;
 
  const [createLeaveRequest, { loading, error }] = useMutation(CREATE_LEAVE_REQUEST, {
    onCompleted: (response) => {
      refetch();
      // dispatch(addLeave(response?.CreateLeaveRequest));
      message.success("Leave created successfully!");
      resetForm();
    },
    onError: (error) => {
      console.error("Error submitting leave request:", error);
      message.error("Failed to submit leave request. Please try again.");
    },
  });
 
 
  const [updateLeaveStatus] = useMutation(UPDATE_LEAVE_STATUS, {
    onCompleted: (response) => {
      console.log("response", response);
      refetch();
      // dispatch(updateLeave(response?.UpdateLeaveStatus));
     
      message.success("Leave updated successfully!");
      resetForm();
    },
    onError: (error) => {
      console.error("Error updating leave request:", error);
      message.error("Failed to update leave request. Please try again.");
    },
  });
 
  // const [updateLeave] = useMutation(UPDATE_LEAVE_STATUS, {
  //   onCompleted: (response) => {
  //     console.log("response", response);
  //     // dispatch(addLeave(response?.UpdateLeaveStatus));
  //     refetch();
  //     message.success("Leave updated successfully!");
  //     resetForm();
  //   },
  //   onError: (error) => {
  //     console.error("Error updating leave request:", error);
  //     message.error("Failed to update leave request. Please try again.");
  //   },
  // });
 
  const options = useSelector((state) => state.dataBucket.databucket);
  const typeOfLeaves = renderOptionsformultiple(options, { name: "TYPES_OF_LEAVES" }, TYPES_OF_LEAVES);
 
  // Recalculate number of leave days based on date and session
  // const calculateDays = (fromDate, fromSession, toDate, toSession, allLeaves) => {
  //   if (!fromDate || !toDate) return 0;
 
  //   let currentDate = new Date(fromDate);
  //   const endDate = new Date(toDate);
  //   let totalDays = 0;
 
  //   // Normalize the dates (set time to 00:00:00)
  //   currentDate.setHours(0, 0, 0, 0);
  //   endDate.setHours(0, 0, 0, 0);
 
  //   while (currentDate <= endDate) {
  //     const currentTimestamp = currentDate.getTime();
 
  //     // Check if the current date is a disabled leave date
  //     const isLeaveDay = allLeaves?.some((leave) => {
  //       if (leave.leaveStatus !== "Approved") return false;
  //       const leaveStart = new Date(parseInt(leave.startDate)).setHours(0, 0, 0, 0);
  //       const leaveEnd = new Date(parseInt(leave.endDate)).setHours(0, 0, 0, 0);
  //       return currentTimestamp >= leaveStart && currentTimestamp <= leaveEnd;
  //     });
 
  //     if (!isLeaveDay) {
  //       totalDays++; // Count only non-disabled days
  //     }
 
  //     currentDate.setDate(currentDate.getDate() + 1); // Move to next day
  //   }
 
  //   // Adjust for sessions if the start and end date are the same
  //   if (fromDate.getTime() === toDate.getTime()) {
  //     if (fromSession === "session1" && toSession === "session1") return 0.5; // Morning only
  //     if (fromSession === "session1" && toSession === "session2") return 1; // Full day
  //     if (fromSession === "session2" && toSession === "session2") return 0.5; // Afternoon only
  //   } else {
  //     // Adjust first and last day based on session selection
  //     if (fromSession === "session2") totalDays -= 0.5; // Start from afternoon
  //     if (toSession === "session1") totalDays -= 0.5; // End in the morning
  //   }
 
  //   return totalDays;
  // };
 
  const calculateDays = (fromDate, fromSession, toDate, toSession) => {
    if (!fromDate || !toDate) return 0;

    const dayDiff = Math.abs(toDate - fromDate) / (1000 * 60 * 60 * 24); // Calculate total days difference
    let totalDays = Math.ceil(dayDiff);

    console.log("totalDays", totalDays);
    // Handle the case when both dates are on the same day
    if (fromDate.getTime() === toDate.getTime()) {
      if (fromSession === "session1" && toSession === "session1") return 0.5; // Morning session only
      if (fromSession === "session1" && toSession === "session2") return 1; // Full day (morning + afternoon)
      if (fromSession === "session2" && toSession === "session2") return 0.5; // Afternoon session only
    } else {
      // For date ranges, calculate based on the sessions
      if (fromSession === "session1" && toSession === "session1") totalDays += 0.5; // Half day on the last day
      else if (fromSession === "session1" && toSession === "session2") totalDays += 1; // Full day on the last day
      else if (fromSession === "session2" && toSession === "session1") totalDays += 0; // No days for reverse
      else if (fromSession === "session2" && toSession === "session2") totalDays += 0.5; // Half day on the last day
    }
    return totalDays;
  };
 
 
  // Filter out leave dates
  const disableLeaveDates = (date) => {
    if (!allLeaves || allLeaves.length === 0) return true; // Ensure allLeaves exists
 
    const dateTimestamp = new Date(date).setHours(0, 0, 0, 0); // Normalize selected date
 
    for (const leave of allLeaves) {
      // Only consider "Approved" leaves
      if (leave?.leaveStatus == "Approved") {
        const leaveStartDate = new Date(parseInt(leave.startDate)).setHours(0, 0, 0, 0);
        const leaveEndDate = new Date(parseInt(leave.endDate)).setHours(0, 0, 0, 0);

        const isSameDay = leaveStartDate === leaveEndDate;

        const isHalfDay = leave.session1 === "true" && leave.session2 === "false";
        const secondHalfDay = leave.session1 === "false" && leave.session2 === "true";
        console.log("isHalfDay",dateTimestamp,leaveStartDate,leaveEndDate);  
        if (dateTimestamp >= leaveStartDate && dateTimestamp <= leaveEndDate) {

          // If leave is a half day on a single date, allow selection
          if (isSameDay && (isHalfDay || secondHalfDay)) return true;

          // If leave end date is a half-day, allow selection of that date
          if (dateTimestamp === leaveEndDate && (isHalfDay || secondHalfDay)) return true;

          return false; // Disable this date
        }
      }
    }
 
   return true; // Allow date selection
};
 
  // Handle form input changes
  const handleChange = (name, value) => {
    setFormData((prevData) => {
      let updatedData = { ...prevData, [name]: value };
 
      if (["fromDate", "toDate", "fromSession", "toSession"].includes(name)) {
        updatedData.numberOfDays = calculateDays(
          updatedData.fromDate,
          updatedData.fromSession,
          updatedData.toDate,
          updatedData.toSession,
          allLeaves
        );
      }
 
      // Clear To Date if it is before From Date
      if (name === "fromDate" && updatedData.toDate && updatedData.toDate < updatedData.fromDate) {
        updatedData.toDate = null; // Reset To Date
        updatedData.numberOfDays = 0; // Reset number of days to 0
      }
 
      // Clear From Date if To Date is before From Date
      if (name === "toDate" && updatedData.fromDate && updatedData.toDate < updatedData.fromDate) {
        updatedData.toDate = null; // Reset To Date
        updatedData.numberOfDays = 0; // Reset number of days to 0
      }
 
      return updatedData;
    });
  };
 
  const [isDisabled, setIsDisabled] = useState(false);
 
  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 5000);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.leaveType || !formData.fromDate || !formData.toDate || !formData.leaveReason) {
      startTimer();
      message.error("Please fill all required fields!");
      return;
    }

    // Check for invalid condition: same date and conflicting sessions
    if (formData.fromDate && formData.toDate && formData.fromDate.getTime() === formData.toDate.getTime()) {
      if (formData.fromSession === "session2" && formData.toSession === "session1") {
        message.error("You cannot select 'Session 2' for 'From Date' and 'Session 1' for 'To Date' on the same day!");
        return;
      }
    }
 
    // Convert selected leave dates to timestamps for comparison
    const selectedLeaveStart = new Date(formData.fromDate);
    selectedLeaveStart.setHours(0, 0, 0, 0);

    const selectedLeaveEnd = new Date(formData.toDate);
    selectedLeaveEnd.setHours(0, 0, 0, 0);
    const selectedFromSession = formData.fromSession;
    const selectedToSession = formData.toSession;

    // Check if the selected dates overlap with any approved leave
    // const hasConflict = allLeaves?.some((leave) => {
    //   if (leave.leaveStatus !== "Approved") return false;

    //   const leaveStart = new Date(parseInt(leave.startDate));
    //   leaveStart.setHours(0, 0, 0, 0);

    //   const leaveEnd = new Date(parseInt(leave.endDate));
    //   leaveEnd.setHours(0, 0, 0, 0);
    //   const leaveSession1 = leave.session1 === "true"; // Morning session
    //   const leaveSession2 = leave.session2 === "true"; // Afternoon session

    //   // Full overlap check
    //   const fullOverlap =
    //     (selectedLeaveStart >= leaveStart && selectedLeaveStart <= leaveEnd) ||
    //     (selectedLeaveEnd >= leaveStart && selectedLeaveEnd <= leaveEnd) ||
    //     (selectedLeaveStart <= leaveStart && selectedLeaveEnd >= leaveEnd);

    //   if (!fullOverlap) return false; // No overlap

    //   // **Half-Day Logic**
    //   if (selectedLeaveStart.getTime() === leaveStart.getTime() && selectedLeaveEnd.getTime() === leaveEnd.getTime()) {
    //     // If the leave is only for a half-day and does not overlap, allow it
    //     if (
    //       (selectedFromSession === "session1" && !leaveSession1) || // User selects morning, but existing leave is NOT morning
    //       (selectedFromSession === "session2" && !leaveSession2) || // User selects afternoon, but existing leave is NOT afternoon
    //       (selectedToSession === "session1" && !leaveSession1) || // End session is morning but not booked
    //       (selectedToSession === "session2" && !leaveSession2) // End session is afternoon but not booked
    //     ) {
    //       return false; // No conflict
    //     }
    //   }
    //   // If the selected leave starts or ends on an approved leave's half-day, ensure they don't fully overlap
    //   if (selectedLeaveEnd.getTime() === leaveEnd.getTime() && leaveSession1 && selectedToSession === "session2") {
    //     return false; // No conflict if approved leave ends in session1 and selected leave ends in session2
    //   }
    //   if (selectedLeaveStart.getTime() === leaveStart.getTime() && leaveSession2 && selectedFromSession === "session1") {
    //     return false; // No conflict if approved leave starts in session2 and selected leave starts in session1
    //   }
    //   return true; // Conflict exists
    // });

    // if (hasConflict) {
    //   message.error("The selected leave period overlaps with an already approved leave request. Please choose different dates.");
    //   return;
    // }

    const { numberOfDays } = formData;

    if (parseFloat(numberOfDays) > parseFloat(remainingLeaves)) {
      Swal.fire({
        icon: "info",
        title: "Leave Balance Exceeded",
        text: `You have only ${remainingLeaves} leave days left. The extra ${numberOfDays - remainingLeaves} days will be considered as Pay Leave.`,
        showCancelButton: true,
        confirmButtonText: "Proceed",
        cancelButtonText: "Cancel",
      }).then((result) => {
        if (result.isConfirmed) {
          processLeaveRequest(); // Proceed if user confirms
        } else {
          message.info("Leave request cancelled.");
        }
      });
    } else {
      processLeaveRequest(); // If leave balance is enough, submit directly
    }

  };

  const processLeaveRequest = () => {
    const { leaveType, fromDate, toDate, leaveReason, numberOfDays, fromSession, toSession } = formData;
    const formattedData = {
      employeeId: userId,
      leaveType: leaveType.value,
      startDate: fromDate,
      endDate: toDate,
      leaveReason,
      leaveDays: numberOfDays.toString(),
      session1: fromSession === "session1" ? "true" : "false",
      session2: toSession === "session2" ? "true" : "false",
    };

    if (leaveData) {

      // Convert formData dates to Unix timestamps for easier comparison
      const formStartDateTimestamp = formData.fromDate ? formData.fromDate.getTime() : null;
      const formEndDateTimestamp = formData.toDate ? formData.toDate.getTime() : null;

      // Convert leaveData dates to Unix timestamps
      const leaveStartDateTimestamp = leaveData.startDate ? parseInt(leaveData.startDate) : null;
      const leaveEndDateTimestamp = leaveData.endDate ? parseInt(leaveData.endDate) : null;

      // Check if any of the fields have changed
      const isDifferentLeave =
        formStartDateTimestamp !== leaveStartDateTimestamp ||
        formEndDateTimestamp !== leaveEndDateTimestamp ||
        (formData.fromSession === "session1" ? "true" : "false") !== leaveData.session1 ||
        (formData.toSession === "session2" ? "true" : "false") !== leaveData.session2;

      if (!isDifferentLeave) {
        startTimer();
        message.error("You haven't updated anything. Please make date and session changes before submitting.");
        return; // Stop the submission if no changes are made
      }

      // When you are updating an existing leave request:
      const leaveUpdateInput = {
        leaveId: leaveData.leaveId,  // Ensure that the leaveData has _id field
        newStatus: leaveData.leaveStatus, // If you want to keep the same status or update it
        // newStartDate: fromDate.toISOString().split("T")[0], // Start Date
        // newEndDate: toDate.toISOString().split("T")[0], // End Date
        newStartDate: fromDate, // Start Date
        newEndDate: toDate, // End Date
        leaveDays: numberOfDays.toString(), // Number of days
        session1: fromSession === "session1" ? "true" : "false",
        session2: toSession === "session2" ? "true" : "false",
        // leaveReason,
        // leaveType: leaveType.value,
      };

      updateLeaveStatus({
        variables: {
          leaveUpdates: [leaveUpdateInput], // Update the leave status
        },
      });
    } else {
      // Create a new leave request
      createLeaveRequest({ variables: formattedData });
    }
    document.querySelector("#add_leave .btn-close")?.click();
  }

  const getSessionAvailability = (date) => {
    if (!allLeaves || allLeaves.length === 0) return { session1: true, session2: true };

    if (!date) {
      console.error("Invalid date received", date);
      return { session1: true, session2: true };
    }
  
    const parsedDate = new Date(date); // Ensure it's a Date object
    if (isNaN(parsedDate.getTime())) {
      console.error("Invalid Date object", date);
      return { session1: true, session2: true };
    }
  
    const dateTimestamp = parsedDate.setHours(0, 0, 0, 0); // Normalize
    console.log("dateTimestamp", dateTimestamp);
    let session1Available = true;
    let session2Available = true;

    for (const leave of allLeaves) {
      if (leave.leaveStatus !== "Approved") continue;

      const leaveStartDate = new Date(parseInt(leave.startDate)).setHours(0, 0, 0, 0);
      const leaveEndDate = new Date(parseInt(leave.endDate)).setHours(0, 0, 0, 0);

      const leaveSession1 = leave.session1 === "true";
      const leaveSession2 = leave.session2 === "true";

      if (dateTimestamp >= leaveStartDate && dateTimestamp <= leaveEndDate) {
        // Full-day leave → disable both sessions
        if (leaveSession1 && leaveSession2) return { session1: false, session2: false };

        // Half-day leave
        if (leaveSession1) session1Available = false;
        if (leaveSession2) session2Available = false;
      }
    }

    return { session1: session1Available, session2: session2Available };
  };
console.log("fromDate:", formData.fromDate, "toDate:", formData.toDate);

  const sessionAvailabilityFrom = formData.fromDate ? getSessionAvailability(formData.fromDate) : { session1: true, session2: true };
  const sessionAvailabilityTo = formData.toDate ? getSessionAvailability(formData.toDate) : { session1: true, session2: true };
  
 
  // Reset form after successful submission
  const resetForm = () => {
    setFormData({
      leaveType: null,
      fromDate: null,
      toDate: null,
      numberOfDays: 0,
      leaveReason: "",
      fromSession: "",
      toSession: "",
    });
    onClose(); // Close the modal
  };
 
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": { backgroundColor: "#ff9b44" },
    }),
  };
 
  console.log("isOpen", formData);
  return (
    <div id="add_leave" className={`modal custom-modal fade ${isOpen ? "show" : ""}`} role="dialog" style={{ display: isOpen ? "block" : "none" }}>
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{leaveData ? "Update Leave" : "Add Leave"}</h5>
            <button type="button" onClick={resetForm} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="input-block mb-3">
                <label className="col-form-label">Leave Type <span className="text-danger">*</span></label>
                <Select
                  value={formData.leaveType}
                  options={typeOfLeaves}
                  placeholder="Select"
                  styles={customStyles}
                  onChange={(selected) => handleChange("leaveType", selected)}
                />
              </div>
              <div className="input-block mb-3">
                <label className="col-form-label">From <span className="text-danger">*</span></label>
                <div className="row">
                  <div className="col-md-8">
                    <div className="cal-icon">
                      <DatePicker
                        selected={formData.fromDate}
                        onChange={(date) => handleChange("fromDate", date)}
                        className="form-control datetimepicker"
                        dateFormat="dd-MM-yyyy"
                        minDate={new Date()}
                        // filterDate={disableLeaveDates} // Disable dates already taken
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                      value={formData.fromSession || ''}
                      onChange={(e) => handleChange("fromSession", e.target.value)}
                      className="form-control"
                    >
                      <option value="" disabled>Select Session</option> 
                       {/* <option value="session1" disabled={!sessionAvailabilityFrom.session1}>Session 1</option>
                       <option value="session2" disabled={!sessionAvailabilityFrom.session2}>Session 2</option> */}
                       <option value="session1" >Session 1</option>
                       <option value="session2" >Session 2</option>
                    </select>
                  </div>
                </div>
              </div>
 
              <div className="input-block mb-3">
                <label className="col-form-label">To <span className="text-danger">*</span></label>
                <div className="row">
                  <div className="col-md-8">
                    <div className="cal-icon">
                      <DatePicker
                        selected={formData.toDate}
                        onChange={(date) => handleChange("toDate", date)}
                        className="form-control datetimepicker"
                        dateFormat="dd-MM-yyyy"
                        minDate={formData.fromDate ? formData.fromDate : new Date()} // Ensure To Date is after From Date
                        // filterDate={disableLeaveDates} // Disable dates already taken
                      />
                    </div>
                  </div>
                  <div className="col-md-4">
                    <select
                      value={formData.toSession || ''}
                      onChange={(e) => handleChange("toSession", e.target.value)}
                      className="form-control"
                    >
                      <option value="" disabled>Select Session</option> 
                      {/* <option value="session1" disabled={!sessionAvailabilityTo.session1}>Session 1</option>
                      <option value="session2" disabled={!sessionAvailabilityTo.session2}>Session 2</option> */}
                      <option value="session1" >Session 1</option>
                      <option value="session2" >Session 2</option>
                    </select>
                  </div>
                </div>
              </div>
 
              {/* Number of Days */}
              <div className="input-block mb-3">
                <label className="col-form-label">Number of Days</label>
                <input
                  type="text"
                  className="form-control"
                  value={formData.numberOfDays}
                  readOnly
                />
              </div>
 
              <div className="input-block mb-3">
                <label className="col-form-label">Leave Reason <span className="text-danger">*</span></label>
                <textarea
                  className="form-control"
                  rows="4"
                  value={formData.leaveReason}
                  onChange={(e) => handleChange("leaveReason", e.target.value)}
                ></textarea>
              </div>
 
              <div className="submit-section">
                <button disabled={isDisabled} className="btn btn-primary submit-btn" type="submit" aria-label="Close">{leaveData ? "Update" : "Submit"}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
 
export default EmployeeLeaveModelPopup;
 