import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Table, Input } from "antd";
import Breadcrumbs from "./Breadcrumbs";
import AllEmployeeAddPopup from "./modelpopup/AllEmployeeAddPopup";
import { GET_EMPLOYEE_DETAILS } from "../Queries/Employees";
import { SetAllEmployees, setId } from "../Slices/employeeslice";
import { useQuery } from "@apollo/client";
import { setLoader } from "../Slices/loaderSlice";

const EmployeeListFilter = () => {
  const dispatch = useDispatch();
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  dispatch((setId(true)))
  const details = useSelector((state) => state.employee.AllEmployees);
  const Navigator = useNavigate();

  // Update filteredEmployees whenever details change
  useEffect(() => {
    dispatch(setLoader(details.length > 0 ? false : true));

    setFilteredEmployees(details);
  }, [details]);

  // Handle search input change
  const handleChange = (e) => {
    const val = e.target.value.toLowerCase();
    if (!val) {
      setFilteredEmployees(details); // Reset to all employees if input is empty
      return;
    }

    const filteredData = details.filter(
      (item) =>
        item.firstname?.toLowerCase()?.includes(val) ||
        item.lastname?.toLowerCase()?.includes(val)
    );
    setFilteredEmployees(filteredData);
  };


  const columns = [
    {
      title: "Full Name",
      dataIndex: "fullName",
      render: (_, record) => `${record.firstname || ""} ${record.lastname || ""}`,
      sorter: (a, b) =>
        `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`),
      width: 100,
      align: "start",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
    {
      title: "Gender",
      dataIndex: "gender",
      sorter: (a, b) => a.gender?.localeCompare(b.gender),
      width: 100,
      align: "center",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
    {
      title: "Role",
      dataIndex: "role",
      sorter: (a, b) => a.role?.localeCompare(b.role),
      width: 100,
      align: "center",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a.department?.localeCompare(b.department),
      width: 110,
      align: "center",
      render: (text) => (
        <span title={text}>
          {text?.length > 15 ? `${text.slice(0, 15)}...` : text}
        </span>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email?.localeCompare(b.email),
      width: 150,
      align: "center",
      render: (text) => (
        <span title={text}>
          {text?.length > 15 ? `${text.slice(0, 15)}...` : text}
        </span>
      ),
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
    {
      title: "Mobile",
      dataIndex: "mobile",
      sorter: (a, b) => a.mobile?.localeCompare(b.mobile),
      width: 100,
      align: "center",
      onHeaderCell: () => ({
        style: {
          backgroundColor: "#007BFF",
          color: "white",
        },
      }),
    },
  ];

  // if (loading) {
  //   return <>loading</>;
  // }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid" >
          {/* Breadcrumbs */}
          <Breadcrumbs
            maintitle="Employee"
            // title="Dashboard"
            // subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            view="list"
          />
          <Input
            type="text"
            placeholder="Search By Name..."
            onChange={handleChange}
            style={{
              marginLeft: "20px",
              padding: "5px",
              marginBottom: "20px",
              width: "200px",
              border: "1px solid #d9d9d9",
              outline: "none",
              borderRadius: "4px",
            }}
          />

          <div id="employee_list_modal_popup" className="card profile-box">
            <div className="card-body" style={{ paddingTop: 0 }}>
              <div className="table-responsive" style={{ margin: 0 }}>
                <Table
                  className="table-striped"
               
                  rowKey={(record) => record.id} // Ensure the unique key for each row
                  columns={columns}
                  dataSource={filteredEmployees || []}
                  pagination={{
                    showQuickJumper: true,
                    showSizeChanger: false,
                  }}
                  size="small"
                  onRow={(record) => ({
                    onClick: () => {
                      console.log(record.id); // Debugging: log the employee id
                      Navigator(`/employees/${record.id}`); // Navigate to the correct employee's page
                    },
                  })}
                  scroll={{ x: false, y: false }} // Disable scrolling
                  bordered
                  rowClassName="table-row"
                />
              </div>
            </div>
          </div>

        </div>
        <AllEmployeeAddPopup />
      </div>
    </>
  );
};

export default EmployeeListFilter;
