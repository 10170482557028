import React,{useEffect} from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Link from 'antd/es/typography/Link';
import { useParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { GET_LEAD_BY_ID } from '../../../Queries/Leads';
import { setContactComments } from '../../../Slices/salesSlice';
import { useDispatch, useSelector } from 'react-redux';

const ContactCommentBox = () => {
  const dispatch = useDispatch();
  const name ='contactInfoComments' ;
  const comment = useSelector((state) => state.sales.contactComments); // Update selector to use 'comment'
  
  
  const { id } = useParams();

  const [getLeadById, { }] = useMutation(GET_LEAD_BY_ID, {
    onCompleted(data) {
      const SalesInfo=data?.getLeadById[0]
      dispatch(setContactComments(SalesInfo?.[name]));
    },
  });

  useEffect(() => {
    const valuesObject = {
        id: id,
    }
    getLeadById({ variables: valuesObject })
  }, []);

  return (
    <div id="comment_info_card" className="card profile-box flex-fill">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="card-title">Comments</h3>
          <Link
            to="#"
            className="edit-icon"
            data-bs-toggle="modal"
            data-bs-target="#conatct_comment_modal_popup"
          >
            <i className="fa fa-pencil" />
          </Link>
        </div>

        {/* Display Comment as a Non-Editable Textarea */}
        <Form.Group controlId="commentDisplay" className="mt-3">
          <Form.Control
            as="textarea"
            rows={4}
            value={comment || "No comments available"}
            disabled
            className="w-100"
            style={{ backgroundColor: "#f8f9fa", cursor: "not-allowed", resize: "none" }}
          />
        </Form.Group>
      </div>
    </div>
  );
};

export default ContactCommentBox;
