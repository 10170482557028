import React, { useState, useEffect } from 'react';
import { Form, Input, Select, Button, Checkbox, Radio, Row, Col, Card, Typography, InputNumber, Space, Collapse } from 'antd';
import { CheckOutlined } from '@ant-design/icons';
import * as DataBucket from '../../../databucket';
import { useErrorLogger } from '../../../utils';
import { renderOptions } from '../../../utilsdata';
import { useSelector } from 'react-redux';

// third party
// import { Formik } from 'formik';
// import { useQuery, useMutation } from '@apollo/client';
// import { useDispatch, useSelector } from "react-redux";
// import { editOrder } from "../../store/reducers/ordersSlice";
// import {
//     UPDATE_REQUEST,
//     GET_ALL_ORDERS,
//     GET_REQUESTS_BY_ID,
// } from "../../Queries/Orders";

import NewQuoteInformation from './NewQuoteInformation';
import ExistingPolicyInformation from './ExistingPolicyInformation';
const { Panel } = Collapse;
const RequestForm = (props) => {
  const {RequestInfo, handleChange, values,errors, touched,handleBlur,setFieldValue} = props;
  let employeeDetails = props.employeeDetails;
  let setEmployeeDetails = props.setEmployeeDetails;
  let initialValues = props.initialValues;
  
   const options = useSelector((state) => state.dataBucket.databucket);
   
  const { logError } = useErrorLogger();
  const [age, setAge] = useState('');
  const [quoteType, setQuoteType] = useState('');
  const [department, setDepartment] = useState('');
  const [insuredName, setInsuredName] = useState(values.insuredName);
  console.log("insuredName",insuredName)
  const [noofQuotes, setNoofQuotes] = useState(values.noofQuotes);
  const [comments,setComments] = useState(values.comments)
  const [radioValue, setRadioValue] = useState('');

  // console.log('RequestForm-->', RequestInfo, employeeDetails, employeeDetails['QuoteType'], quoteType, department, insuredName, noofQuotes, radioValue)

  console.log('RequestForm-->', RequestInfo, values)
  // const initialValues = {

  //     quoteType: RequestInfo.quoteType,

  // }

  // const radiohandleChange = (event) => {
  //     setValue(event.target.value);
  // };
  const radioHandleChange = (event) => {
    setEmployeeDetails({ ...employeeDetails, 'RadioValue': event.target.value })

    // setRadioValue(event.target.value);
  };

  const quoteTypeChange = (event) => {
    // thisValue.setState({ 'Quote_Type': event.target.value })
    setEmployeeDetails({ ...employeeDetails, 'QuoteType': event.target.value })

    // setQuoteType(event.target.value)
  };

  const departmentChange = (event) => {
    // thisValue.setState({ 'Department': event.target.value })
    setEmployeeDetails({ ...employeeDetails, 'Department': event.target.value })

    // setDepartment(event.target.value)
  };
  const insuredNameChange = (event) => {
    // thisValue.setState({ 'Insured_Name': event.target.value })
    setEmployeeDetails({ ...employeeDetails, 'InsuredName': event.target.value })

    // setInsuredName(event.target.value)
  };
  const noofQuotesChange = (event) => {
    // thisValue.setState({ 'Number_of_Quotes': event.target.value })
    setEmployeeDetails({ ...employeeDetails, 'NoofQuotes': parseInt(event.target.value) })

    // setNoofQuotes(event.target.value)
  };

  const getNoofDisplayName = () => {
    try {
      if (values?.processType == 'EOP Template' && values?.department == 'Commercial Lines') return "No?. of Templates"
      else if (values?.processType == 'Endorsements' && values?.department == 'Commercial Lines') return "No?. of Endorsements"
      else if (values?.processType == 'Audits' && values?.department == 'Commercial Lines') return "No?. of Audits"
      else if (values?.processType == 'Pre-Renewal Letters' && values?.department == 'Commercial Lines') return "No?. of Letters"
      else if (values?.processType == 'Cancellations' && values?.department == 'Commercial Lines') return "No?. of Notices"
      else if (values?.processType == 'Certificate Issuance' && values?.department == 'Commercial Lines') return "No?. of Certificates"
      else if (values?.processType == 'Auto ID Cards' && values?.department == 'Commercial Lines') return "No?. of Auto ID’s"
      else if (values?.processType == 'Policy Checking' && values?.department == 'Commercial Lines') return "No?. of Policies"
      else if (values?.processType == 'Policy Renewals' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'BORs' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'LPRs' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'PFAs' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Accords' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Supplemental' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Invoicing' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Loss Runs' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Direct Bill Reconciliation' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'New Account Creation' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Renewals Processing' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Special Projects' && values?.department == 'Commercial Lines') return "No?. of Transactions"
      else if (values?.processType == 'Proposals' && values?.department == 'Commercial Lines') return "No. of Transactions"

      else {
        return 'Number of Quotes'
      }

    }
    catch (error) {
      logError("RequestForm", "get quotename based on process type", error.message)
    }

  }
  useEffect(() => {
    setInsuredName(values.insuredName);
  }, [values.insuredName]); // Update state when values.insuredName changes
  
  useEffect(() => {
    setNoofQuotes(values.noofQuotes);
  }, [values.noofQuotes]);

  useEffect(() => {
    setComments(values.comments);
  }, [values.comments]);

  return (
    <Card title="Request Details">
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item label="Request Type"
              validateStatus={touched?.quoteType && errors.quoteType ? 'error' : ''}
              help={touched.quoteType && errors.quoteType ? errors.quoteType : null}
            >
              <Select
                name="quoteType"
                value={values?.quoteType || RequestInfo?.quoteType || undefined}
                onChange={(value) => handleChange({ target: { name: 'quoteType', value } })}
                placeholder="Select Request Type"
              >
                {renderOptions(options, { name: "QUOTE_TYPE_CONFIG" }, DataBucket.QUOTE_TYPE_CONFIG)}
              </Select>
            </Form.Item>
          </Col>

          
          <Col span={6}>
            <Form.Item label="Department"
             validateStatus={touched?.department && errors.department ? 'error' : ''}
             help={touched?.department && errors.department ? errors.department : null}
             >
              <Select
                name="department"
                value={values?.department || RequestInfo?.department || undefined}
                onChange={(value) => handleChange({ target: { name: 'department', value } })}
                placeholder="Select Department"
              >
                {renderOptions(options, { name: "DEPARTMENT_CONFIG" }, DataBucket.DEPARTMENT_CONFIG)}
              </Select>
            </Form.Item>
          </Col>

        
          <Col span={6}>
            <Form.Item label="Insured Name"
            validateStatus={touched.insuredName && errors.insuredName ? 'error' : ''}
            help={touched.insuredName && errors.insuredName ? errors.insuredName : null}
            >
              <Input
                id="insuredName"
                name="insuredName"
                placeholder='Enter Insured Name'
                value={insuredName}
                onChange={(e)=>{
                  setInsuredName(e.target.value)
                  //handleChange(e)
                }}
                onBlur={(e)=>{
                  handleChange(e)
                }}
              //placeholder="Enter Insured Name"
              />
            </Form.Item>
          </Col>

          
          <Col span={6}>
            <Form.Item label={getNoofDisplayName(values)}
             validateStatus={touched.noofQuotes && errors.noofQuotes ? 'error' : ''}
             help={touched.noofQuotes && errors.noofQuotes ? errors.noofQuotes : null}
             >
              <Input
                name="noofQuotes"
                type='text'
                placeholder='Enter Quotes value'
                value={noofQuotes}
                onChange={(e)=>setNoofQuotes(e.target.value)}
                onBlur={(e)=>{
                  handleChange(e)
                }}
                min={1}
                //placeholder="Enter Number of Quotes"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          {values?.processType === 'Certificate Issuance' && values?.department === 'Commercial Lines' && (
            <>
              <Col span={6}>
                <Form.Item label="Holder Name">
                  <Input
                    name="holderName"
                    value={values?.holderName || ''}
                    onChange={handleChange}
                    placeholder="Enter Holder Name"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Insured Delivery">
                  <Input
                    name="insuredDelivery"
                    value={values.insuredDelivery || ''}
                    onChange={handleChange}
                    placeholder="Enter Insured Delivery"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Holder Delivery">
                  <Input
                    name="holderDelivery"
                    value={values.holderDelivery || ''}
                    onChange={handleChange}
                    placeholder="Enter Holder Delivery"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Account Manager Delivery">
                  <Input
                    name="accountManagerDelivery"
                    value={values.accountManagerDelivery || ''}
                    onChange={handleChange}
                    placeholder="Enter Account Manager Delivery"
                  />
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item label="Other Delivery">
                  <Input
                    name="otherDelivery"
                    value={values.otherDelivery || ''}
                    onChange={handleChange}
                    placeholder="Enter Other Delivery"
                  />
                </Form.Item>
              </Col>
            </>
          )}

        
          {values?.department === 'Personal Line' && (
            <Row gutter={[8, 8]} style={{ paddingTop: '16px' }} className="mb-4">
              <Col span={5}>
                <Checkbox
                  name="Auto"
                  onChange={handleChange}
                  checked={values.Auto}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Auto</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  name="MotorCycle"
                  onChange={handleChange}
                  checked={values.MotorCycle}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Motor Cycle</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={2}>
                <Checkbox
                  name="Boat"
                  onChange={handleChange}
                  checked={values.Boat}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Boat</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  name="DP3"
                  onChange={handleChange}
                  checked={values.DP3}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(DP3) Dwelling Fire</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  name="HO3"
                  onChange={handleChange}
                  checked={values.HO3}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO3) Home Owners</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={5}>
                <Checkbox
                  name="HO4"
                  onChange={handleChange}
                  checked={values.HO4}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO4) Renters</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={12}>
                <Checkbox
                  name="HO5"
                  onChange={handleChange}
                  checked={values.HO5}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO5) Home Owners with higher Dwelling Limit</Typography.Text>
                </Checkbox>
              </Col>
              <Col span={4}>
                <Checkbox
                  name="HO6"
                  onChange={handleChange}
                  checked={values.HO6}
                >
                  <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO6) Condo</Typography.Text>
                </Checkbox>
              </Col>
            </Row>
          )}
        </Row>
      </Form>

      {(values?.department === 'Personal Line') && (
        <>
          <ExistingPolicyInformation
            initialValues={initialValues}
            employeeDetails={employeeDetails}
            setEmployeeDetails={setEmployeeDetails}
            handleChange={handleChange}
            values={values}
            RequestInfo={RequestInfo}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldValue={setFieldValue}
          />
          <NewQuoteInformation
            initialValues={initialValues}
            employeeDetails={employeeDetails}
            setEmployeeDetails={setEmployeeDetails}
            handleChange={handleChange}
            values={values}
            RequestInfo={RequestInfo}
            errors={errors}
            touched={touched}
          />
        </>
      )}

      {(values?.processType === 'Policy Quoting' && values?.department === 'Commercial Lines') && (
        <>
          <ExistingPolicyInformation
            initialValues={initialValues}
            employeeDetails={employeeDetails}
            setEmployeeDetails={setEmployeeDetails}
            handleChange={handleChange}
            values={values}
            RequestInfo={RequestInfo}
          />
          <NewQuoteInformation
            initialValues={initialValues}
            employeeDetails={employeeDetails}
            setEmployeeDetails={setEmployeeDetails}
            handleChange={handleChange}
            values={values}
            RequestInfo={RequestInfo}
          />
        </>
      )}

      <Col span={24}>
        {/* <Collapse defaultActiveKey={['1']}> */}
          <Typography.Title level={5}>Comments</Typography.Title>
            <Form.Item>
              <Input.TextArea
                rows={4}
                name="comments"
                value={comments}
                onChange={(e)=>setComments(e.target.value)}
                onBlur={(e)=>{
                  handleChange(e)
                }}
                placeholder="Enter Comments"
              />
            </Form.Item>
          {/* </Panel>
        </Collapse> */}
      </Col>
    </Card>
  );

}

export default RequestForm;