import React, { useState, useRef, useEffect } from "react";
import DatePicker from "react-datepicker";
import { Collapse } from "antd";
import { ADD_EMPLOYEES } from "../../Queries/Employees";
import { useMutation } from "@apollo/client";
import { message } from "antd";
import { Checkbox, Button } from "antd";
import { SetNewEmployees } from "../../Slices/employeeslice";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SIDEMENU } from "../../databucket";
import { setClients } from "../../Slices/employeeslice";
import { useQuery } from "@apollo/client";
import { Avatar_02 } from "../../Routes/ImagePath";
import { TIMER_DEPARTMENT, ROLE, STATUS, bloodGroups, genderOptions, DOMAIN, status } from "../../databucket";
import { GET_ALL_CLIENTS } from "../../Queries/Users";
import { useErrorLogger } from "../../utils";
import { renderOptionsformultiple } from "../../utilsdata";
import { renderOptions } from "../../utilsdata";

import ConditionMenu from "../../views/layout/conditionMenu";

import { DAYNAMIC_ROUTES } from "../../Queries/options";
import { GET_DYNAMIC_ROUTES } from "../../Queries/options";
const { Option } = Select
const { Panel } = Collapse;
const AllEmployeeAddPopup = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const { logError } = useErrorLogger();
  const [createEmployee] = useMutation(ADD_EMPLOYEES);
  // const employeesData = useSelector((state) => state.employee.AllEmployees);
  const [isUserDataValid, setIsUserDataValid] = useState(false);
  const [department, setDepartment] = useState(null);
  const [name, setName] = useState(null);
  const [sidemenu, setSidemenu] = useState(SIDEMENU);
  const clientOptions = useSelector(state => state.clients.clients) // useSelector((state) => state.employee.clients);
  const [SideMENU] = useMutation(DAYNAMIC_ROUTES);
  const [openDepartment, setOpenDepartment] = useState(false);
  // const { data: clientData } = useQuery(GET_ALL_CLIENTS, {
  //   onCompleted: (clientData) => {
  //     if (clientOptions?.length <= 0) {
  //       dispatch(setClients(clientData.getAllClients.response));
  //     }
  //   },
  // });
  const { data, loading, error } = useQuery(GET_DYNAMIC_ROUTES, {
    onCompleted: (data) => {
      console.log("Data fetched:", data);
    },
    onError: (error) => {
      console.error("Error fetching data:", error);
    },
  });
  const Employee = useSelector((state) => state.employee.AllEmployees);
  const initialState = {
    EmpID: "",
    firstname: "",
    lastname: "",
    dob: "",
    gender: "",
    currentAddress: "",
    mobile: "",
    department: "",
    designation: "",
    clients: "",
    reportingTo: "",
    bloodGroup: "",
    permanentAddress: "",
    maritalStatus: "",
    processSplization: "",
    alternativeNumber: "",
    email: "",
    employeestatus: "Active",
    personalEmail: "",
    DateOfJoining: "",
    role: '',
  };
  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters

    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }

    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)

    e.target.value = value;
  };

  const [userData, setUserData] = useState(initialState); // Final Data


  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "rgb(131, 127, 127)" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "rgb(131, 127, 127)",
      },
    }),
  };
  const options = useSelector((state) => state.dataBucket.databucket);
  console.log("dagdy", TIMER_DEPARTMENT)
  const mobilePattern = /^[6-9]\d{9}$/; // Mobile number should be exactly 10 digits
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.com$/;
  const companyEmailPattern = /^[a-zA-Z0-9._%+-]+@blueplanitcorp\.com$/; // Company email validation
  // const handleMaritalStatusChange = (selectedOption) => {
  //   setUserData({
  //     ...userData,
  //     maritalStatus: selectedOption.label,
  //   });
  // }
  const handleModalClose = () => {
    console.log("close");
    setUserData(initialState)
    setErrores({});
    setSidemenu(SIDEMENU)
    // Reset the form  
  };

  // State for errors
  const [errores, setErrores] = useState({});
  const [errorChange, setErrorChange] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });

    let newErrors = { ...errores };


    // Field-specific validation using regex patterns
    switch (name) {
      case 'mobile':
        newErrors.mobile = mobilePattern.test(value) ? null : "Invalid mobile number.";
        break;
      case 'personalEmail':
        if (value.trim() === '') {
          delete newErrors.personalEmail; // Remove the error if the field is empty
        } else {
          newErrors.personalEmail = emailPattern.test(value)
            ? null
            : "Invalid email format.";
        }
        break;


      case 'email':
        if (value.trim() === '') {
          newErrors.email = "email is required"; // Remove the error if the field is empty
          newErrors.email = "email is required"; // Remove the error if the field is empty
        } else {
          newErrors.email = emailPattern.test(value)
            ? null
            : "Invalid email format.";
        }
        break;


      case 'employeestatus':
        newErrors.employeestatus = value ? null : "employeestatus is required.";
        break;
      case 'alternativeNumber':
        if (!value) {
          delete newErrors.alternativeNumber;
        }
        else if (mobilePattern.test(value)) {
          delete newErrors.alternativeNumber; // Remove error if valid
        } else {
          newErrors.alternativeNumber = "Invalid alternative number."; // Set error if invalid
        }
        break;
      case 'firstname':
        newErrors.firstname = /^[A-Za-z\s-]+$/.test(value) ? null : "First name should only contain letters, spaces, or hyphens.";
        break;
      case 'lastname':
        newErrors.lastname = /^[A-Za-z\s-]+$/.test(value) ? null : "Last name should only contain letters, spaces, or hyphens.";
        break;
      // case 'department':
      //   newErrors.department = value ? null : "department Status is required.";
      //   break;

      case 'department':
        newErrors.department = value ? null : "department Status is required.";
        break;

      // case 'designation':


      //   newErrors.designation = value ? null : "designation Status is required.";
      //   break;
      // case 'processSplization':


      //   newErrors.processSplization =/^[A-Za-z\s-]+$/.test(value) ? null : "processSplization should only contain letters, spaces, or hyphens.";
      //   break;
      case 'gender':
        newErrors.gender = value ? null : "gender  is required.";
        break;
      //   case 'clients':


      //   newErrors.clients = value ? null : "clients  is required.";
      //   break;
      // case 'role':

      //   newErrors.role = value ? null : "role  is required.";
      //   break;
      case 'department':

        break;
      case 'role':
        // newErrors.role = value ? null : "Role is required.";
        break;
      case 'personalEmail':
        newErrors.personalEmail = emailPattern.test(value) ? null : "Invalid email format.";
        break;
      case 'dob':
        if (!value) {
          newErrors.dob = "DOB is required.";
        } else {
          const currentDate = new Date();
          const enteredDate = new Date(value);
          const age = currentDate.getFullYear() - enteredDate.getFullYear();
          const isMonthValid =
            currentDate.getMonth() > enteredDate.getMonth() ||
            (currentDate.getMonth() === enteredDate.getMonth() && currentDate.getDate() >= enteredDate.getDate());


          // Adjust the age if the current month/day is before the birth month/day
          const actualAge = isMonthValid ? age : age - 1;


          if (actualAge < 20 || actualAge > 58) {
            newErrors.dob = "Age must be between 20 and 58 years.";
          } else {
            newErrors.dob = null; // Valid date of birth
          }
        }
        break;
      case 'DateOfJoining': {
        const today = new Date();
        const oneYearFromTomorrow = new Date();

        // Move to tomorrow
        oneYearFromTomorrow.setDate(oneYearFromTomorrow.getDate() + 1);

        // Add one year to tomorrow
        oneYearFromTomorrow.setFullYear(oneYearFromTomorrow.getFullYear() + 1);

        // Ensure value is parsed correctly
        const inputDate = new Date(value);
        if (isNaN(inputDate.getTime())) { // Check if it's a valid date
          newErrors.DateOfJoining = "Invalid date format.";
          break;
        }

        // Normalize the time part by setting it to midnight for accurate comparison
        today.setHours(0, 0, 0, 0);
        oneYearFromTomorrow.setHours(0, 0, 0, 0);
        inputDate.setHours(0, 0, 0, 0);

        if (!value) {
          newErrors.DateOfJoining = "Date of Joining is required.";
        } else if (inputDate < today || inputDate > oneYearFromTomorrow) {
          newErrors.DateOfJoining = "Date of Joining must be between today and one year from tomorrow.";
        } else {
          newErrors.DateOfJoining = null; // No error
        }
        break;
      }

      // case 'maritalStatus':
      //   newErrors.maritalStatus = value ? null : "maritalStatus Status is required.";
      //   break;


      default:
        break;
    }


    setErrores(newErrors);

    // Check for any errors and mark the form as changed
  }

  const maxSubroutes = Math?.max(
    ...sidemenu?.map((menu) => (menu.Subfields ? menu.Subfields.length : 0))
  );
  const toggleSidemenuStatus = (index, subIndex = null) => {
    const updatedSidemenu = sidemenu?.map((menu, i) => {
      if (i === index) {
        if (subIndex === null) {
          // Toggle main sidemenu
          const newStatus = !menu.status;
          return {
            ...menu,
            status: newStatus,
            Subfields: menu.Subfields
              ? menu.Subfields?.map((sub) => ({
                ...sub,
                status: newStatus, // Enable or disable based on main status
              }))
              : [],
          };
        } else {
          // Toggle specific subfield
          return {
            ...menu,
            Subfields: menu.Subfields?.map((sub, sIndex) =>
              sIndex === subIndex
                ? { ...sub, status: !sub.status } // Toggle subfield status
                : sub
            ),
          };
        }
      }
      return menu;
    });
    setSidemenu(updatedSidemenu);
  };
  useEffect(() => {
    const requiredFields = ['mobile', 'email', 'firstname', 'lastname', 'dob', 'DateOfJoining', 'gender'];

    const allFieldsValid = requiredFields.every((field) => {
      return !errores[field] && Boolean(userData[field]); // Ensure field is non-empty and has no error
    });
    const isAlternativeNumberValid = !errores['alternativeNumber'];
    setIsUserDataValid(allFieldsValid && isAlternativeNumberValid);
  }, [userData, errores]);
  useEffect(() => {
    if (data && data.getSideMenu) {
      const foundItem = data?.getSideMenu?.find(
        (item) => item?.name === userData?.role && item.department === userData?.department
      );
      if (foundItem) {
        const fields = foundItem.fields;
        setSidemenu(fields);
        console.log("qedefadcdcds", foundItem) // Update sidemenu based on selected name and department
      } else {
        setSidemenu(SIDEMENU)
        console.log("No matching menu item found");
      }
    }
  }, [userData.role, userData.department, data]);

  const handleParentCheckboxChange = (index) => {
    setSidemenu((prevMenu) =>
      prevMenu.map((menu, i) =>
        i === index
          ? {
            ...menu,
            status: !menu.status,
            key: !menu.status ? menu.key : false, // Set key to false if status is false
            Subfields: menu.Subfields.map((subfield) => ({
              ...subfield,
              status: !menu.status,
              key: !menu.status ? subfield.key : false, // Update key based on status
            })),
          }
          : menu
      )
    );
  };

  const handleSubfieldCheckboxChange = (parentIndex, subIndex) => {
    setSidemenu((prevMenu) =>
      prevMenu.map((menu, i) =>
        i === parentIndex
          ? (() => {
            // Toggle subfield status first
            const updatedSubfields = menu.Subfields.map((subfield, j) =>
              j === subIndex
                ? { ...subfield, status: !subfield.status, key: !subfield.status ? subfield.key : false }
                : subfield
            );

            // Check if all subfields are now false
            const allSubfieldsFalse = updatedSubfields.every(sub => !sub.status);

            return {
              ...menu,
              Subfields: updatedSubfields,
              status: allSubfieldsFalse ? false : menu.status, // If all false, uncheck main route
              key: allSubfieldsFalse ? false : menu.key, // If all false, key should also be false
            };
          })()
          : menu
      )
    );
  };



  // Example usage
  // Example function to handle toggle
  const handleSubmit = async (e) => {
    e.preventDefault();
    const alphaRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces
    const alphaSpecialRegex = /^[A-Za-z\s-]+$/;
    const today = new Date(); // Current date
    const oneYearFromToday = new Date();
    oneYearFromToday?.setFullYear(today.getFullYear() + 1); // Add 1 year to the current date
    console.log(isUserDataValid, "allFieldsValid")
    const inputDate = new Date(userData.DateOfJoining);


    let newErrors = { ...errores };


    // Check if all required fields are filled (for final validation)
    if (!userData.mobile) newErrors.mobile = "Mobile number is required.";
    //if (!userData.alternativeNumber) newErrors.alternativeNumber = "Alternative number is required.";
    if (!userData.personalEmail) {
      delete newErrors.personalEmail;
    }

    if (!userData.email) {
      newErrors.email = "email is required.";
    }

    if (!userData.firstname?.trim()) {
      newErrors.firstname = "First name is required.";
    }

    // else if (!alphaRegex.test(userData.firstname)) {
    //   newErrors.firstname = "First name should only contain letters and spaces.";
    // }


    // Validate Last Name
    if (!userData.lastname?.trim()) {
      newErrors.lastname = "Last name is required.";
    }


    if (!userData.dob) {
      newErrors.dob = "DOB is required.";
    }


    if (!userData.DateOfJoining) {
      newErrors.DateOfJoining = "DateOfJoining is required.";
    }
    if (!userData.gender) {
      newErrors.gender = "gender is required.";
    }


    setErrores(newErrors);
    // Check if there are no errors and form has changed
    const noErrors = Object.keys(newErrors).length === 0;
    if (newErrors && isUserDataValid) {
      setIsUserDataValid(true);
      formRef.current.reset();
      try {
        // Perform the GraphQL API call
        const cleanSubfields = (subfields) => {
          return subfields?.map((subfield) => {
            const { __typename, ...rest } = subfield;
            return rest; // Return cleaned subfield without __typename
          });
        };

        const cleanedSidemenu = sidemenu?.map((item) => {
          const { __typename, Subfields, ...rest } = item;

          // Clean subfields if they exist
          const cleanedSubfields = Subfields ? cleanSubfields(Subfields) : [];

          // Return the item with cleaned subfields
          return { ...rest, Subfields: cleanedSubfields };
        });
        const formattedData = sidemenu.map((menu) => ({
          name: menu.name,
          status: menu.status,
          Subfields: menu.Subfields?.map((sub) => ({
            displayName: sub.displayName,
            name: sub.name,
            status: sub.status,
          })) || [], // Handle cases where there are no subfields
        }));

        console.log(formattedData);

        console.log("thtrhtrjf", formattedData);
        const { data } = await createEmployee({
          variables: {
            input: {
              ...userData,
              menu: cleanedSidemenu
            }
          },
          variables: {
            input: {
              ...userData,
              menu: formattedData
            }
          },
        });
        setErrorChange(false); // Reset change tracker
        setIsUserDataValid(false); // Reset validation state
        console.log()
        if (data?.createEmploye?.status) {
          const newEmployee = data?.createEmploye?.response;
          dispatch(SetNewEmployees(newEmployee));
        }
        message.success({
          content: data?.createEmploye?.message,
          duration: 5,
          className: 'alert alert-success text-center',
        });
        // Programmatically close the modal if there are no errors and changes are made
        const modalElement = document.querySelector('[data-bs-dismiss="modal"]');
        modalElement && modalElement.click();
      } catch (error) {
        // Log the error and show an error message
        await logError("AllEmployeeAddPop", "handleSubmit", error.message);
        console.error("Error occurred during handleSubmit AllEmployeeAddPop update:", error);
        message.error({
          content: "Failed to update handleSubmit AllEmployeeAddPop . Please try again.",
          duration: 5,
          className: "alert alert-danger text-center",
        });
      } finally {


      }
    }
  };

  return (
    <div id="add_employee" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Employee</h5>
            <button type="button" className="btn-close" onClick={handleModalClose} data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true" >×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit} ref={formRef}>
              <div className="row">
                <div className="col-md-12">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">
                          Employee ID<span className="text-danger">*</span>
                        </label>
                        <input
                          type="number"
                          name="EmpID"
                          className={`form-control ${errores.EmpID ? 'is-invalid' : ''}`}
                          value={(userData?.EmpID)?.trim() || ""}
                          onChange={handleInputChange}
                          max="99999"  // Maximum value for the input
                          min="0"      // Optional: ensures only positive numbers are allowed
                          onInput={(e) => {
                            // This will ensure the value doesn't exceed 5 digits
                            if (e.target.value.length > 5) {
                              e.target.value = e.target.value.slice(0, 5);
                            }
                          }}
                        />
                        {errores.EmpID && <div className="text-danger">{errores.EmpID}</div>}
                      </div>

                    </div>
                  </div>


                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">First Name<span className="text-danger">*</span></label>
                        <input
                          type="text"
                          name="firstname"
                          className={`form-control ${errores.firstname ? 'is-invalid' : ''}`}
                          value={userData?.firstname}
                          onChange={handleInputChange}
                        />
                        {errores.firstname && <div className="text-danger">{errores.firstname}</div>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Last Name<span className="text-danger">*</span></label>
                        <input
                          type="text"
                          name="lastname"
                          className={`form-control ${errores.lastname ? 'is-invalid' : ''}`}
                          value={userData?.lastname}
                          onChange={handleInputChange}
                        />
                        {errores.lastname && <div className="text-danger">{errores.lastname}</div>}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Birth Date<span className="text-danger">*</span></label>
                        <DatePicker
                          selected={userData.dob ? userData?.dob : null}
                          onChange={(date) =>
                            handleInputChange({
                              target: {
                                name: "dob",
                                value: date,
                              },
                            })
                          }
                          className={`form-control ${errores?.dob ? 'is-invalid' : ''}`}
                          dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                          placeholderText="dd/MM/YYYY" // Placeholder text for the input
                          showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                          popperPlacement="bottom"
                          onKeyDown={handleKeyDown}
                          maxLength={10}
                        // Optional, depending on your requirements
                        />
                        {errores.dob && <div className="text-danger">{errores.dob}</div>}
                      </div>
                    </div>


                    <div className="col-md-6">
                      <div className="input-block mb-3">
                        <label className="col-form-label">Gender<span className="text-danger">*</span></label>
                        <Select
                          options={genderOptions}
                          name="gender"
                          value={genderOptions.find((g) => g.value === userData?.gender) || ''}
                          onChange={(option) => {
                            handleInputChange({ target: { name: 'gender', value: option.value } });
                            setUserData({ ...userData, gender: option.value });
                          }}
                          styles={customStyles}
                        />
                        {errores.gender && <div className="text-danger">{errores.gender}</div>}


                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Current Address</label>
                    <input
                      type="text"
                      name="currentAddress"
                      className="form-control"
                      value={userData?.currentAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Permanent Address</label>
                    <input
                      type="text"
                      name="permanentAddress"
                      className="form-control"
                      value={userData?.permanentAddress}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Phone Number<span className="text-danger">*</span></label>
                    <input
                      type="text"
                      name="mobile"
                      className={`form-control ${errores.mobile ? 'is-invalid' : ''}`}
                      value={userData?.mobile}
                      onChange={handleInputChange}
                    />
                    {errores.mobile && <div className="text-danger">{errores.mobile}</div>}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Alternative Number</label>
                    <input
                      type="text"
                      name="alternativeNumber"
                      className={`form-control ${errores.alternativeNumber ? 'is-invalid' : ''}`}
                      value={userData?.alternativeNumber}
                      onChange={handleInputChange}
                    />
                    {errores.alternativeNumber && <div className="text-danger">{errores.alternativeNumber}</div>}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Email <span className="text-danger">*</span></label>
                    <input
                      type="text"
                      name="email"
                      className={`form-control ${errores.email ? 'is-invalid' : ''}`}
                      value={userData?.email}
                      onChange={handleInputChange}
                    />
                    {errores.email && <div className="text-danger">{errores.email}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Personal Email</label>
                    <input
                      type="text"
                      name="personalEmail"
                      className={`form-control ${errores.personalEmail ? 'is-invalid' : ''}`}
                      value={userData?.personalEmail}
                      onChange={handleInputChange}
                    />
                    {errores.personalEmail && <div className="text-danger">{errores.personalEmail}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Department</label>
                    {/* Select dropdown for department */}
                    <Select
                      name="department"
                      value={{
                        label: userData?.department,
                        value: userData?.department,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "department",
                            value: option?.value,
                          },
                        })
                      }
                      // options={TIMER_DEPARTMENT}
                      options={renderOptionsformultiple(options, { name: "TIMER_DEPARTMENT" }, TIMER_DEPARTMENT)}


                      styles={customStyles}
                    >
                      {/* {renderOptions(options, { name: "TIMER_DEPARTMENT" }, TIMER_DEPARTMENT)} */}

                    </Select>
                    {/* Error message for department */}
                    {errores.department && <div className="text-danger">{errores.department}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Role</label>
                    {/* Select dropdown for department */}
                    <Select
                      name="role"
                      value={{
                        label: userData?.role,
                        value: userData?.role,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "role",
                            value: option?.value,
                          },
                        })
                      }
                      options={renderOptionsformultiple(options, { name: "ROLE" }, ROLE)}
                      styles={customStyles}
                    />


                    {/* Error message for department */}
                    {errores.role && <div className="text-danger">{errores.role}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Status</label>


                    {/* Select dropdown for department */}
                    <Select
                      name="employeestatus"
                      value={{
                        label: userData?.employeestatus,
                        value: userData?.employeestatus,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "employeestatus",
                            value: option?.value,
                          },
                        })
                      }
                      options={STATUS}
                      styles={customStyles}
                    />


                    {/* Error message for department */}
                    {errores.employeestatus && <div className="text-danger">{errores.employeestatus}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Client</label>


                    {/* Select dropdown for clients */}
                    <Select
                      name="clients"
                      value={{
                        label: userData?.clients,
                        value: userData?.clients,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "clients",
                            value: option?.value,
                          },
                        })
                      }
                      options={clientOptions
                        ?.filter(clientOption => clientOption?.status === "Active")
                        .map(clientOption => ({
                          label: clientOption?.clientName,
                          value: clientOption?.clientName,
                        }))
                      }
                      styles={customStyles} // Custom styles for the dropdown
                    />


                    {/* Error message for clients */}
                    {errores.clients && <div className="text-danger">{errores.clients}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Designation</label>
                    <Select
                      options={renderOptionsformultiple(options, { name: "DOMAIN" }, DOMAIN)}
                      name="designation"
                      value={{
                        label: userData?.designation,
                        value: userData?.designation,
                      }}


                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "designation",
                            value: option.value,
                          },
                        })
                      }
                      styles={customStyles}
                    />
                    {errores.designation && <div className="text-danger">{errores.designation}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Reports To</label>
                    <Select
                      // options={[
                      //   { value: "Wilmer Deluna", label: "Wilmer Deluna" },
                      //   { value: "Lesley Grauer", label: "Lesley Grauer" },
                      //   { value: "Jeffery Lalor", label: "Jeffery Lalor" },
                      // ]}
                      name="reportingTo"
                      value={{
                        label: userData?.reportingTo,
                        value: userData?.reportingTo,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "reportingTo",
                            value: option?.value,
                          },
                        })
                      }
                      options={Employee?.filter(emp => emp?.employeestatus === "Active")
                        .map((clientOption) => ({
                          label: `${clientOption?.firstname} ${clientOption?.lastname}`,
                          value: `${clientOption?.firstname} ${clientOption?.lastname}`,
                        }))
                      }

                      styles={customStyles}
                    />{errores.reportingTo && <div className="text-danger">{errores.reportingTo}</div>}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Date of joining<span className="text-danger">*</span></label>
                    <DatePicker
                      selected={userData?.DateOfJoining ? userData?.DateOfJoining : null}
                      onChange={(date) =>
                        handleInputChange({
                          target: {
                            name: "DateOfJoining",
                            value: date,
                          },
                        })
                      }
                      className={`form-control ${errores.DateOfJoining ? 'is-invalid' : ''}`}
                      dateFormat="dd/MM/yyyy" // Show the full date (day, month, year)
                      placeholderText="dd/MM/YYYY" // Placeholder text for the input
                      showMonthYearPicker={false} // Allow day, month, and year selection (without restricting to month/year only)
                      popperPlacement="bottom"
                      maxLength={10}
                      minDate={new Date()} // Block dates before today
                      maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))} // Allow dates up to 1 year from today
                      // onKeyDown={handleKeyDown}
                      onKeyDown={(e) => {
                        e.preventDefault();

                      }}

                    /> {errores.DateOfJoining && <div className="text-danger">{errores.DateOfJoining}</div>}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Process Specialization</label>
                    <input
                      type="text"
                      className={`form-control ${errores.processSplization ? 'is-invalid' : ''}`}
                      name="processSplization"
                      value={userData.processSplization || ''}
                      onChange={handleInputChange}
                    />
                    {errores.processSplization && <div className="text-danger">{errores.processSplization}</div>}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Marital status</label>
                    <Select
                      options={[
                        { value: "Single", label: "Single" },
                        { value: "Married", label: "Married" },
                      ]}


                      value={{
                        label: userData?.maritalStatus,
                        value: userData?.maritalStatus,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "maritalStatus",
                            value: option?.label,
                          },
                        })
                      }
                      styles={customStyles}
                    />
                    {errores.maritalStatus && <div className="text-danger">{errores.maritalStatus}</div>}
                  </div>
                </div>


                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Blood Group</label>
                    <Select
                      options={bloodGroups}
                      value={{
                        label: userData?.bloodGroup,
                        value: userData?.bloodGroup,
                      }}
                      onChange={(option) =>
                        handleInputChange({
                          target: {
                            name: "bloodGroup",
                            value: option?.value,
                          },
                        })
                      }
                      styles={customStyles}
                    />
                  </div>
                </div>
              </div>
              <h2 style={{ marginBottom: "20px" }}>Manage Routes and Sidemenu</h2>

              <div
                style={{
                  padding: "20px",
                  background: "#fff",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  borderRadius: "8px",

                }}
              >
                {/* Department Dropdown */}

                {/* Sidemenu Collapse */}
                <Collapse>
                  {sidemenu?.map((menu, index) => (
                    <Panel
                      key={index}
                      header={
                        <Checkbox
                          checked={menu.status}
                          onChange={() => handleParentCheckboxChange(index)}
                          style={{ fontFamily: "serif", fontSize: "16px" }}
                        >
                          {menu.name}
                        </Checkbox>
                      }
                    >
                      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                        {menu.Subfields &&
                          menu.Subfields.map((sub, subIndex) => (
                            <div
                              key={subIndex}
                              style={{
                                maxWidth: "200px",
                                padding: "5px",
                                display: "flex",
                                alignItems: "center",
                                border: "1px solid #ddd",
                                borderRadius: "5px",
                                boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                              }}
                            >
                              <Checkbox
                                checked={sub.status}
                                disabled={!menu.status}
                                onChange={() => handleSubfieldCheckboxChange(index, subIndex)}
                              >
                                {sub.displayName}
                              </Checkbox>
                            </div>
                          ))}
                      </div>
                    </Panel>
                  ))}
                </Collapse>
              </div>
              {/* Submit Button */}
              {/* <ConditionMenu val={false}/> */}
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  {...(isUserDataValid ? { "data-bs-dismiss": "modal" } : {})}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};


export default AllEmployeeAddPopup;

