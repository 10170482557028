// // ** Third Party Imports
// import { createSlice } from "@reduxjs/toolkit";
// import { client } from "..";
// import { GET_ALL_CLIENTS } from "../Queries/Users";
// const initialState = {
//   clients: [],      
// };

// const clientSlice = createSlice({
//   name: "clients",
//   initialState,
//   reducers: {
//     setClients: (state, action) => {
//       console.log("dcdacaca",action.payload)
//       state.clients = action.payload;
//         console.log("dcdacaca1",state.clients )
//     },

//     addClient: (state, action) => {
//       state.clients = [action.payload,...state.clients];
//     },

//     updateClient: (state, action) => {
//       const updatedClient = action.payload;
//       const index = state.clients.findIndex(client => client.id === updatedClient.id);
    
//       if (index !== -1) {
//         state.clients[index] = {...updatedClient };
//       }
//     },
//   },
// });

// export const {
//   setClients,
//   addClient,
//   updateClient,
// } = clientSlice.actions;

// export default clientSlice.reducer;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { client } from "..";
import { GET_ALL_CLIENTS } from "../Queries/Users";

// Initial state of your slice
const initialState = {
  clients: [],
  loading: false,
  error: null,
};

// Thunk to fetch clients
export const fetchClients = createAsyncThunk(
  "clients/fetchClients",
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await client.query({
        query: GET_ALL_CLIENTS,
      });
      return data.getAllClients.response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const clientSlice = createSlice({
  name: "clients",
  initialState,
  reducers: {
    setClients: (state, action) => {
      state.clients = action.payload;
    },
    addClient: (state, action) => {
      state.clients = [action.payload, ...state.clients];
    },
    updateClient: (state, action) => {
      const updatedClient = action.payload;
      const index = state.clients.findIndex(client => client.id === updatedClient.id);
    
      if (index !== -1) {
        state.clients[index] = { ...updatedClient };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle loading state
      .addCase(fetchClients.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      // Handle success state
      .addCase(fetchClients.fulfilled, (state, action) => {
        state.loading = false;
        state.clients = action.payload;
      })
      // Handle error state
      .addCase(fetchClients.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setClients,
  addClient,
  updateClient,
} = clientSlice.actions;

export default clientSlice.reducer;
