import React from "react";
import { Row, Col, Card, Typography } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { GET_ALL_LEADS,GET_SALES_STATS } from "../../../Queries/Leads";
import { GET_ALL_ORDERS } from "../../../Queries/Orders";
import { setLoader } from "../../../Slices/loaderSlice";
import { setTodayStats, setMonthlyStats, setFollowupStats, setTotalStats } from "../../../Slices/salesSlice";
import { getSummaryData } from "../../../Slices/orderSlice";
import ReactECharts from 'echarts-for-react';
//import { Doughnut } from 'react-chartjs-2';
//import { Chart as ChartJS } from 'chart.js/auto';
//import { Line } from "react-chartjs-2";
//import { Chart as ChartJS, CategoryScale, LinearScale, LineElement, PointElement, Tooltip, Legend } from "chart.js";
const { Title, Text } = Typography;
// ChartJS?.register(
//     CategoryScale,
//     LinearScale,
//     LineElement,
//     PointElement,
//     Title,
//     Tooltip,
//     Legend
//   );

const Dashboard = () => {

    const dispatch = useDispatch();

    const { data: salesData } = useQuery(GET_SALES_STATS,{
        fetchPolicy: 'no-cache',
    });

    const { data: leadsData, loading, error } = useQuery(GET_ALL_LEADS, {
        variables: { page: 1, pageSize: 50, filters: {}, sort: {} },
        fetchPolicy: 'no-cache',
    });

    dispatch(setLoader(loading))
    const datacontent = leadsData ? leadsData.getAllLeads : [];

    dispatch(setTodayStats(datacontent?.stats?.todayStats))
    dispatch(setMonthlyStats(datacontent?.stats?.monthStats))
    dispatch(setFollowupStats(datacontent?.stats?.followUpsStats))
    dispatch(setTotalStats(datacontent?.stats?.totalStats))

    const todayStats = useSelector((state) => state?.sales?.todayStats);
    const monthlyStats = useSelector((state) => state?.sales?.monthlyStats);
    const followUpStats = useSelector((state) => state?.sales?.followUpStats);
    const totalStats = useSelector((state) => state?.sales?.totalStats);

    const { data } = useQuery(GET_ALL_ORDERS, {
        variables: { page: 1, pageSize: 50, filters: { filter: "Inbox" }, isAllOrders: false },
        fetchPolicy: 'no-cache',
    })
    console.log(data)
    dispatch(getSummaryData(data?.getAllOrders?.summary));


    const summary = useSelector((state) => state.orders.summary);


    console.log("salesData", salesData?.getSalesStats?.response);

    const statsData = salesData?.getSalesStats?.response?.last12MonthsStats

    const months = statsData?.map(stat => `${stat.month}/${stat.year}`);

    const callsData = statsData?.map(stat => stat?.calls);
    const emailsData = statsData?.map(stat => stat?.emails);
    const linkedInData = statsData?.map(stat => stat?.linkedIn);
    const leadsGeneratedData = statsData?.map(stat => stat?.leadsGenerated);
    const callWithOpsData = statsData?.map(stat => stat.callWithOps);

    const todayStatss = salesData?.getSalesStats?.response?.todayStats
    const followUpsStatss = salesData?.getSalesStats?.response?.followUpsStats
    const totalStatss = salesData?.getSalesStats?.response?.totalStats
    const monthlyStatss = salesData?.getSalesStats?.response?.monthStats

    // Function to generate bar chart for monthly stats
    const generateMonthStatsChart = (data) => ({
        title: {
            text: `${new Date().toLocaleString("default", {month: "long",})} - ${new Date().getFullYear()} Stats Overview`, // Chart Title
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['Calls', 'Emails', 'LinkedIn', 'Leads Generated', 'Call With Ops'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    data?.calls,
                    data?.emails,
                    data?.linkedIn,
                    data?.leadsGenerated,
                    data?.callWithOps
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#3498db' // Blue color for bars
                }
            }
        ]
    });


    // Function to generate bar chart
    const generateTotalStatsChart = (data) => ({
        title: {
            text: 'Total Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        tooltip: {
            trigger: 'axis'
        },
        xAxis: {
            type: 'category',
            data: ['Scheduled Calls', 'Leads Closed', 'Connected With Ops', 'Leads Generated', 'Total Leads'],
            axisLabel: {
                rotate: 27,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [
                    data?.callsScheduledForOps,
                    data?.leadsClosed,
                    data?.leadsConnectedWithOps,
                    data?.leadsGenerated,
                    data?.totalLeads
                ],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#ff9933' // Orange color for bars
                }
            }
        ]
    });

    // Function to generate bar chart
    const generateFollowUpsChart = (data) => ({
        tooltip: {
            trigger: 'axis'
        },
        title: {
            text: 'Followup Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        xAxis: {
            type: 'category',
            data: ['Today', 'With Ops', 'This Month', 'Total'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [data?.todayFollowUps, data?.followUpsWithOps, data?.monthFollowUps, data?.totalFollowUps],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#9ec5fe' // Blue color for bars
                }
            }
        ]
    });

    // Function to generate bar chart
    const generateTodayStatsChart = (data) => ({
        tooltip: {
            trigger: 'axis'
        },
        title: {
            text: 'Today Stats Overview',  // Title for the chart
            left: 'center',
            textStyle: {
                fontSize: 18,
                fontWeight: 'bold'
            }
        },
        xAxis: {
            type: 'category',
            data: ['Calls', 'Emails', 'LinkedIn', 'Leads Generated', 'Call with Ops'],
            axisLabel: {
                rotate: 30,  // Tilts labels slightly for readability
                margin: 15   // Adds bottom margin
            }
        },
        yAxis: {
            type: 'value'
        },
        series: [
            {
                data: [data?.calls, data?.emails, data?.linkedIn, data?.leadsGenerated, data?.callWithOps],
                type: 'bar',
                barWidth: '50%',
                itemStyle: {
                    color: '#FF5733'
                }
            }
        ]
    });


    // Function to generate a bar chart for each category
    const generateBarChart = (title, data, color) => {
        return {
            title: {
                text: title,
                left: 'center',
            },
            tooltip: {
                trigger: 'axis',
            },
            xAxis: {
                type: 'category',
                data: months,
                axisLabel: {
                    rotate: 45, // Rotate the labels to avoid overlap
                },
            },
            yAxis: {
                type: 'value',
                axisLabel: {
                    formatter: (value) => Math.round(value), // Ensures no decimals
                },
                minInterval: 1, // Ensures only whole numbers are used
            },
            series: [
                {
                    data: data,
                    type: 'bar',
                    itemStyle: {
                        color: color,
                    },
                },
            ],
        };
    };

    // const cardStyle = {
    //     background: "linear-gradient(to right, #1677ff,rgb(0, 162, 255))",
    //     color: "white",
    //     borderRadius: "12px",
    //     boxShadow: "0 4px 10px rgba(0, 0, 0, 0.15)",
    //     height: "100%",
    //     position: "relative", // Make sure the pseudo-elements are positioned relative to the card
    //     overflow: "hidden", // Hide any overflow caused by the circles
    //     transition: "transform 0.3s ease",
    // };

    // const salesDashboard = [
    //     {
    //         title: "Today",
    //         stats: [
    //             todayStats?.calls || 0,
    //             todayStats?.emails || 0,
    //             todayStats?.linkedIn || 0,
    //             todayStats?.leadsGenerated || 0,
    //             todayStats?.callWithOps || 0,
    //         ],
    //         labels: [
    //             "No. of Calls",
    //             "No. of Emails",
    //             "No. of LinkedIn",
    //             "No. of Leads Generated",
    //             "Call with Ops.",
    //         ],
    //     },
    //     {
    //         title: `${new Date().toLocaleString("default", {
    //             month: "long",
    //         })} - ${new Date().getFullYear()}`,
    //         stats: [
    //             monthlyStats?.calls || 0,
    //             monthlyStats?.emails || 0,
    //             monthlyStats?.linkedIn || 0,
    //             monthlyStats?.leadsGenerated || 0,
    //             monthlyStats?.callWithOps || 0,
    //         ],
    //         labels: [
    //             "No. of Calls",
    //             "No. of Emails",
    //             "No. of LinkedIn",
    //             "No. of Leads Generated",
    //             "Call with Ops.",
    //         ],
    //     },
    //     {
    //         title: "Follow-ups",
    //         stats: [
    //             followUpStats?.todayFollowUps || 0,
    //             followUpStats?.followUpsWithOps || 0,
    //             followUpStats?.monthFollowUps || 0,
    //             followUpStats?.totalFollowUps || 0,
    //         ],
    //         labels: [
    //             "Today Follow-ups",
    //             "Follow-ups with ops.",
    //             `${new Date().toLocaleString("default", { month: "long" })} Follow-ups`,
    //             "Total Follow-ups",
    //         ],
    //     },
    //     {
    //         title: "Total",
    //         stats: [
    //             totalStats?.totalLeads || 0,
    //             totalStats?.leadsGenerated || 0,
    //             totalStats?.callsScheduledForOps || 0,
    //             totalStats?.leadsConnectedWithOps || 0,
    //             totalStats?.leadsClosed || 0,
    //         ],
    //         labels: [
    //             "Total No. of Leads",
    //             "No. of Leads Generated",
    //             "No. of Calls scheduled for Ops.",
    //             "No. of Leads connected with Ops.",
    //             "No. of Leads Closed",
    //         ],
    //     },
    // ];

    const getInboxOnDemand = summary?.inbox?.getInboxOnDemand;
    const getAvailableOnDemand = summary?.inbox?.getAvailableOnDemand;
    const getInprogressOnDemand = summary?.inbox?.getInprogressOnDemand;
    const getOnholdOnDemand = summary?.inbox?.getOnholdOnDemand;
    const getInboxRush = summary?.inbox?.getInboxRush;
    const getAvailableRush = summary?.inbox?.getAvailableRush;
    const getInprogressRush = summary?.inbox?.getInprogressRush;
    const getOnholdRush = summary?.inbox?.getOnholdRush;
    const getInboxBulk = summary?.inbox?.getInboxBulk;
    const getAvailableBulk = summary?.inbox?.getAvailableBulk;
    const getInprogressBulk = summary?.inbox?.getInprogressBulk;
    const getOnholdBulk = summary?.inbox?.getOnholdBulk;

    const getInboxOnDemandWeekly = summary?.inbox?.getInboxOnDemandWeekly;
    const getAvailableOnDemandWeekly = summary?.inbox?.getAvailableOnDemandWeekly;
    const getInprogressOnDemandWeekly = summary?.inbox?.getInprogressOnDemandWeekly;
    const getOnholdOnDemandWeekly = summary?.inbox?.getOnholdOnDemandWeekly;

    const getInboxRushWeekly = summary?.inbox?.getInboxRushWeekly;
    const getAvailableRushWeekly = summary?.inbox?.getAvailableRushWeekly;
    const getInprogressRushWeekly = summary?.inbox?.getInprogressRushWeekly;
    const getOnholdRushWeekly = summary?.inbox?.getOnholdRushWeekly;

    const getInboxBulkWeekly = summary?.inbox?.getInboxBulkWeekly;
    const getAvailableBulkWeekly = summary?.inbox?.getAvailableBulkWeekly;
    const getInprogressBulkWeekly = summary?.inbox?.getInprogressBulkWeekly;
    const getOnholdBulkWeekly = summary?.inbox?.getOnholdBulkWeekly;

    const getInboxOnDemandMonthly = summary?.inbox?.getInboxOnDemandMonthly;
    const getAvailableOnDemandMonthly = summary?.inbox?.getAvailableOnDemandMonthly;
    const getInprogressOnDemandMonthly = summary?.inbox?.getInprogressOnDemandMonthly;
    const getOnholdOnDemandMonthly = summary?.inbox?.getOnholdOnDemandMonthly;

    const getInboxRushMonthly = summary?.inbox?.getInboxRushMonthly;
    const getAvailableRushMonthly = summary?.inbox?.getAvailableRushMonthly;
    const getInprogressRushMonthly = summary?.inbox?.getInprogressRushMonthly;
    const getOnholdRushMonthly = summary?.inbox?.getOnholdRushMonthly;
    const getInboxBulkMonthly = summary?.inbox?.getInboxBulkMonthly;
    const getAvailableBulkMonthly = summary?.inbox?.getAvailableBulkMonthly;
    const getInprogressBulkMonthly = summary?.inbox?.getInprogressBulkMonthly;
    const getOnholdBulkMonthly = summary?.inbox?.getOnholdBulkMonthly;

    const totalInboxCount = getInboxOnDemand + getInboxRush + getInboxBulk;

    const getEmailneeded = summary?.emailNeeded?.getEmailneeded;
    const getEmailneededOnDemand = summary?.emailNeeded?.getEmailneededOnDemand;
    const getEmailneededRush = summary?.emailNeeded?.getEmailneededRush;
    const getEmailneededBulk = summary?.emailNeeded?.getEmailneededBulk;

    const getEmailneededOnDemandWeekly = summary?.emailNeeded?.getEmailneededOnDemandWeekly;
    const getEmailneededRushWeekly = summary?.emailNeeded?.getEmailneededRushWeekly;
    const getEmailneededBulkWeekly = summary?.emailNeeded?.getEmailneededBulkWeekly;

    const getEmailneededOnDemandMonthly = summary?.emailNeeded?.getEmailneededOnDemandMonthly;
    const getEmailneededRushMonthly = summary?.emailNeeded?.getEmailneededRushMonthly;
    const getEmailneededBulkMonthly = summary?.emailNeeded?.getEmailneededBulkMonthly;

    const totalEmailneededCount = getEmailneeded;

    const getPendings = summary?.pending?.getPendings;
    const getPendingsOnDemand = summary?.pending?.getPendingsOnDemand;
    const getPendingsRush = summary?.pending?.getPendingsRush;
    const getPendingsBulk = summary?.pending?.getPendingsBulk;

    const getPendingsOnDemandWeekly = summary?.pending?.getPendingsOnDemandWeekly;
    const getPendingsRushWeekly = summary?.pending?.getPendingsRushWeekly;
    const getPendingsBulkWeekly = summary?.pending?.getPendingsBulkWeekly;

    const getPendingsOnDemandMonthly = summary?.pending?.getPendingsOnDemandMonthly;
    const getPendingsRushMonthly = summary?.pending?.getPendingsRushMonthly;
    const getPendingsBulkMonthly = summary?.pending?.getPendingsBulkMonthly;

    const totalPendingsCount = getPendingsOnDemand + getPendingsRush + getPendingsBulk;

    const getDiscardOnDemandMonthly = summary?.discard?.getDiscardOnDemandMonthly;
    const getDiscardRushMonthly = summary?.discard?.getDiscardRushMonthly;
    const getDiscardBulkMonthly = summary?.discard?.getDiscardBulkMonthly;


    const getCompletedOnDemand = summary?.completed?.getCompletedOnDemand;
    const getCompletedRush = summary?.completed?.getCompletedRush;
    const getCompletedBulk = summary?.completed?.getCompletedBulk;
    const getCompletedTodayOnDemand = summary?.completed?.getCompletedTodayOnDemand;
    const getCompletedThisMonthOnDemand = summary?.completed?.getCompletedThisMonthOnDemand;
    const getCompletedThisYearOnDemand = summary?.completed?.getCompletedThisYearOnDemand;
    const getCompletedTodayRush = summary?.completed?.getCompletedTodayRush;
    const getCompletedThisMonthRush = summary?.completed?.getCompletedThisMonthRush;
    const getCompletedThisYearRush = summary?.completed?.getCompletedThisYearRush;
    const getCompletedTodayBulk = summary?.completed?.getCompletedTodayBulk;
    const getCompletedThisMonthBulk = summary?.completed?.getCompletedThisMonthBulk;
    const getCompletedThisYearBulk = summary?.completed?.getCompletedThisYearBulk;

    const totalCompletedCount = getCompletedOnDemand + getCompletedRush + getCompletedBulk;

    const InboxweeklyData = {
        labels: [
            // "Inbox On Demand", "Inbox Rush", "Inbox Bulk",
            "Available On Demand", "Available Rush", "Available Bulk",
            "In-Progress On Demand", "In-Progress Rush", "In-Progress Bulk",
            "On Hold On Demand", "On Hold Rush", "On Hold Bulk"
        ],
        datasets: [
            {
                data: [
                    // getInboxOnDemandWeekly, getInboxRushWeekly, getInboxBulkWeekly,
                    getAvailableOnDemandWeekly, getAvailableRushWeekly, getAvailableBulkWeekly,
                    getInprogressOnDemandWeekly, getInprogressRushWeekly, getInprogressBulkWeekly,
                    getOnholdOnDemandWeekly, getOnholdRushWeekly, getOnholdBulkWeekly
                ],
                backgroundColor: [
                    // "#4e73df", "#1cc88a", "#36b9cc", // Inbox
                    "#1cc88a", "#36b9cc", "#f6c23e", // Available
                    "#36b9cc", "#f6c23e", "#FF5733", // In-Progress
                    "#f6c23e", "#FF5733", "#FF6384"  // On Hold
                ],
            },
        ],
    };

    // Chart options (customize appearance, tooltips, etc.)
    const InboxweeklyOptions = {
        responsive: true,
        plugins: {
            legend: {
                position: 'top',
            },
            tooltip: {
                callbacks: {
                    label: function (tooltipItem) {
                        return tooltipItem.label + ": " + tooltipItem.raw;
                    },
                },
            },
        },
    };

    const getAllMonths = () => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const currentMonth = new Date().getMonth(); // Get current month (0-11)
        const currentYear = new Date().getFullYear();
        
        // Get the last 12 months (including current month)
        const monthsData = [];
        for (let i = 0; i < 12; i++) {
            const monthIndex = (currentMonth - i + 12) % 12; // Handle the previous months
            const year = currentMonth - i < 0 ? currentYear - 1 : currentYear; // Account for previous year
            monthsData.unshift(`${months[monthIndex]} ${year}`); // Prepend to get the months in correct order
        }
    
        return monthsData;
    };
    
    // Prepare Monthly Data for the past 12 months
    const monthsData = getAllMonths(); // Get all months in correct order (current month on the right)
    
    const monthlyData = [
        totalInboxCount
    ];
    
    // Ensure the number of months and data points match
    const adjustedMonthlyData = monthlyData.slice(0, monthsData.length); // Slice data to match the calls of months
    
    // Set up the Inbox Monthly Bar Graph Data
    const InboxMonthlyBarData = {
        xAxis: {
            type: 'category',
            data: monthsData, // Use the months dynamically
            axisLabel: {
                interval: 0, // Show all labels (ensure all months are visible)
                rotate: 45,  // Rotate labels if necessary for better visibility
            }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: adjustedMonthlyData,
                type: 'bar',
                barWidth: '70%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            '#1cc88a', '#36b9cc', '#f6c23e', '#FF5733', '#f6c23e', '#FF5733', '#FF6384', '#f6c23e', '#36b9cc', '#f6c23e', '#36b9cc', '#FF5733'
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };
    
    const InboxMonthlyBarOptions = {
        title: {
            text: 'Total Requests',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        xAxis: InboxMonthlyBarData.xAxis,
        yAxis: InboxMonthlyBarData.yAxis,
        series: InboxMonthlyBarData.series,
    };
    
    
    const InboxMonthlyBarDataPriority = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getInboxOnDemand, getInboxRush, getInboxBulk,
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            // '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsPriority = {
        title: {
            text: 'Priority Wise',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataPriority.xAxis,
        yAxis: InboxMonthlyBarDataPriority.yAxis,
        series: InboxMonthlyBarDataPriority.series,
    };

    const InboxMonthlyBarDataCompleted = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getCompletedThisMonthOnDemand, getCompletedThisMonthRush, getCompletedThisMonthBulk,
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            // '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsCompleted = {
        title: {
            text: 'Completed',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataCompleted.xAxis,
        yAxis: InboxMonthlyBarDataCompleted.yAxis,
        series: InboxMonthlyBarDataCompleted.series,
    };

    const InboxMonthlyBarDataPedings = {
        xAxis: {
            type: 'category',
            data: [
                "Pendings"
            ],
            boundaryGap: true,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    totalPendingsCount,
                ],
                type: 'bar',
                position: 'left',
                barWidth: '20%',
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            //'#36b9cc', 
                            //'#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            //'#FF5733', 
                            '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsPendins = {
        title: {
            text: 'Pendings',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataPedings.xAxis,
        yAxis: InboxMonthlyBarDataPedings.yAxis,
        series: InboxMonthlyBarDataPedings.series,
    };
    const InboxMonthlyBarDataEmailNeeded = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getEmailneededOnDemand, getEmailneededRush, getEmailneededBulk
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            //'#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsEmailNeeded = {
        title: {
            text: 'Email Needed',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataEmailNeeded.xAxis,
        yAxis: InboxMonthlyBarDataEmailNeeded.yAxis,
        series: InboxMonthlyBarDataEmailNeeded.series,
    };
    const InboxMonthlyBarDataDiscard = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getDiscardOnDemandMonthly, getDiscardRushMonthly, getDiscardBulkMonthly
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            //'#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsDiscard = {
        title: {
            text: 'Discard',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataDiscard.xAxis,
        yAxis: InboxMonthlyBarDataDiscard.yAxis,
        series: InboxMonthlyBarDataDiscard.series,
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                {/* <Typography style={{ marginBottom: "20px", fontWeight: 'bold' }}>Sales Dashboard</Typography>
                <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
                    {salesDashboard.map((item, index) => (
                        <Col span={6} key={index}>
                            <Card
                                title={
                                    <div
                                        style={{
                                            display: "flex",
                                            // justifyContent: "center",
                                            height: "25px",
                                        }}
                                    >
                                        <Title
                                            level={5}
                                            style={{ color: "white", fontWeight: "bold" }}
                                        >
                                            {item.title}
                                        </Title>
                                    </div>
                                }
                                style={{
                                    ...cardStyle,
                                    position: "relative", // Ensure the card is the relative container
                                    overflow: "hidden", // To ensure icon is clipped if needed
                                    backgroundColor: "#f0f0f0", // Ensure background color for better visibility
                                }}
                            >

                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-15%",
                                        left: "37%",
                                        width: "250px",
                                        height: "250px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-27%",
                                        right: "25%",
                                        width: "250px",
                                        height: "250px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div style={{ position: "relative", zIndex: 1 }}>
                                    {item.labels.map((label, i) => (
                                        <div
                                            key={i}
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: "white",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {label}
                                            </Text>
                                            <Text
                                                style={{
                                                    color: "white",
                                                    textAlign: "right",
                                                    marginLeft: "8px",
                                                    width: "70px",
                                                    fontWeight: "bold",
                                                }}
                                            >
                                                {item.stats[i]}
                                            </Text>
                                        </div>
                                    ))}
                                </div>
                            </Card>
                        </Col>
                    ))}
                </Row> */}
                <Typography style={{ marginBottom: "20px", fontWeight: 'bold' }}>Requests Dashboard</Typography>
                <div className="row">
                    {/* First Card */}
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group">
                            <div className="card">
                                {/* <div
                                    style={{
                                        position: "absolute",
                                        top: "-15%",
                                        left: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-15%",
                                        right: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div> */}
                                <div className="card-body">
                                    {/* <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="d-block" style={{ fontWeight: "bold", color: "white" }}>Inbox({totalInboxCount ? <span style={{ fontWeight: "lighter" }}>{totalInboxCount}</span> : 0})</h5>
                                        </div>
                                        <div>
                                            <span className="m-2">{getInboxOnDemand || 0}</span>
                                            <span className="m-2">{getInboxRush || 0}</span>
                                            <span className="m-2">{getInboxBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <hr />
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">Available</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getAvailableOnDemand || 0}</span>
                                            <span className="m-2">{getAvailableRush || 0}</span>
                                            <span className="m-2">{getAvailableBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">In-Progress</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getInprogressOnDemand || 0}</span>
                                            <span className="m-2">{getInprogressRush || 0}</span>
                                            <span className="m-2">{getInprogressBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="d-block">onHold</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getOnholdOnDemand || 0}</span>
                                            <span className="m-2">{getOnholdRush || 0}</span>
                                            <span className="m-2">{getOnholdBulk || 0}</span>
                                        </div>
                                    </div> */}

                                    {/* <div className="my-4">
                                        <h6>Weekly Data</h6>
                                        <Line data={weeklyData} options={{ responsive: true }} />
                                    </div> */}

                                    {/* Monthly Graph */}
                                    {/* <div className="my-4">
                                        <h6>Monthly Data</h6>
                                        <Line data={monthlyData} options={{ responsive: true }} />
                                    </div> */}
                                    <h3 style={{ fontWeight: 'bold', color: 'black' }}>Current Month Details</h3>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptions} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsPriority} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsCompleted} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* Second Card */}
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                {/* <div
                                    style={{
                                        position: "absolute",
                                        top: "-15%",
                                        left: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-15%",
                                        right: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div> */}
                                <div className="card-body">
                                    {/* <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="d-block" style={{ fontWeight: "bold", color: "white" }}> Email Needed({totalEmailneededCount ? <span style={{ fontWeight: "lighter" }}>{totalEmailneededCount}</span> : 0})</h5>
                                        </div>
                                        <div>
                                            <span>{getEmailneeded || 0}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <hr />
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">On Demand</span>
                                        </div>
                                        <div>
                                            <span>{getEmailneededOnDemand || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">Rush</span>
                                        </div>
                                        <div>
                                            <span>{getEmailneededRush || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="d-block">Bulk</span>
                                        </div>
                                        <div>
                                            <span>{getEmailneededBulk || 0}</span>
                                        </div>
                                    </div> */}
                                    {/* <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            <h5 style={{ fontWeight: "bold", color: "black" }}>Weekly Overview</h5>
                                        </div>
                                        <div className="mb-4" style={{ width: "300px", height: "300px" }}>
                                            <Doughnut data={EmailNeededweeklyData} options={EmailNeededweeklyOptions} />
                                        </div>
                                        <div className="mb-4 d-flex">
                                            <h5 style={{ fontWeight: "bold", color: "black" }}>Monthly Overview</h5>
                                        </div>

                                        <div className="mb-4" style={{ width: "400px", height: "400px" }}>
                                            <Doughnut data={EmailNeededmonthlyData} options={EmailNeededmonthlyOptions} />
                                        </div>
                                    </div> */}
                                    {/* <h5 style={{ fontWeight: 'bold', color: 'black' }}>Current Month Requests</h5> */}
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsPendins} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsEmailNeeded} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsDiscard} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Typography style={{ marginBottom: "20px", fontWeight: 'bold' }}>Sales Dashboard</Typography>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Calls', callsData, '#FF5733')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Emails', emailsData, '#17a6df')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('LinkedIn', linkedInData, '#0512bf')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Leads Generated', leadsGeneratedData, '#FF33A6')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 33%', minWidth: '300px' }}>
                                            <ReactECharts option={generateBarChart('Call with OPS', callWithOpsData, '#55ce63')} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div className="card-body">
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateTodayStatsChart(todayStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateFollowUpsChart(followUpsStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateTotalStatsChart(totalStatss)} style={{ height: "400px", width: "100%", }} />
                                        </div>
                                        <div style={{ flex: '1 1 25%', minWidth: '300px' }}>
                                            <ReactECharts option={generateMonthStatsChart(monthlyStatss)} style={{ height: "400px", width: "100%" }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {/* Third Card */}
                {/* <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-15%",
                                        left: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-15%",
                                        right: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="d-block" style={{ fontWeight: "bold", color: "white" }}>Pending({totalPendingsCount ? <span style={{ fontWeight: "lighter" }}>{totalPendingsCount}</span> : 0})</h5>
                                        </div>
                                        <div>
                                            <span>{getPendings || 0}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <hr />
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">On Demand</span>
                                        </div>
                                        <div>
                                            <span>{getPendingsOnDemand || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">Rush</span>
                                        </div>
                                        <div>
                                            <span>{getPendingsRush || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="d-block">Bulk</span>
                                        </div>
                                        <div>
                                            <span>{getPendingsBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div style={{ display: "flex" }}>
                                            <h5 style={{ fontWeight: "bold", color: "black" }}>Weekly Overview</h5>
                                        </div>
                                        <div className="mb-4" style={{ width: "300px", height: "300px" }}>
                                            <Doughnut data={PendingsweeklyData} options={PendingsdweeklyOptions} />
                                        </div>
                                        <div className="mb-4 d-flex">
                                            <h5 style={{ fontWeight: "bold", color: "black" }}>Monthly Overview</h5>
                                        </div>

                                        <div className="mb-4" style={{ width: "400px", height: "400px" }}>
                                            <Doughnut data={PendingsmonthlyData} options={PendingsmonthlyOptions} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* Fourth Card */}
                {/* <div className="row">
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card" style={{ ...cardStyle, position: "relative", overflow: "hidden", backgroundColor: "#f0f0f0" }}>
                                <div
                                    style={{
                                        position: "absolute",
                                        top: "-15%",
                                        left: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>

                                <div
                                    style={{
                                        position: "absolute",
                                        bottom: "-15%",
                                        right: "50%",
                                        width: "145px",
                                        height: "145px",
                                        borderRadius: "50%",
                                        backgroundColor: "white",
                                        opacity: 0.2,
                                        boxShadow: "13px -5px 11px rgba(5, 1, 1, 0.5)",
                                    }}
                                ></div>
                                <div className="card-body">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <h5 className="d-block" style={{ fontWeight: "bold", color: "white" }}>Completed({totalCompletedCount ? <span style={{ fontWeight: "lighter" }}>{totalCompletedCount}</span> : 0})</h5>
                                        </div>
                                        <div>
                                            <span className="m-">{getCompletedOnDemand || 0}</span>
                                            <span className="m-2">{getCompletedRush || 0}</span>
                                            <span className="m-2">{getCompletedBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <hr />
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">Today</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getCompletedTodayOnDemand || 0}</span>
                                            <span className="m-2">{getCompletedTodayRush || 0}</span>
                                            <span className="m-2">{getCompletedTodayBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between mb-3">
                                        <div>
                                            <span className="d-block">{new Date().toLocaleString('default', { month: 'long' })}</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getCompletedThisMonthOnDemand || 0}</span>
                                            <span className="m-2">{getCompletedThisMonthRush || 0}</span>
                                            <span className="m-2">{getCompletedThisMonthBulk || 0}</span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <span className="d-block">{new Date().getFullYear()}</span>
                                        </div>
                                        <div>
                                            <span className="m-2">{getCompletedThisYearOnDemand || 0}</span>
                                            <span className="m-2">{getCompletedThisYearRush || 0}</span>
                                            <span className="m-2">{getCompletedThisYearBulk || 0}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default Dashboard;
