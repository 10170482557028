import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Input, Select, Button, Typography } from 'antd';
import { Field, useFormikContext } from 'formik';
import * as DataBucket from '../../../databucket';
import { useSelector, useDispatch } from 'react-redux';
import { setIsRefetch } from '../../../Slices/salesSlice';
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;
const AgencyInformationForm = ({

  handleStartButtonClick,
  handleStop,
  isRunning,
  task,
  CurrentTask,
  id,
  leadID,
}) => {
  console.log("AgencyInformationForm", leadID, id, isRunning)
  //   const renderOptions = (options, criteria, fallbackData = []) => {
  //     console.log("fallbackData",fallbackData)

  // //     return options?.reduce((acc, item) => {
  // //         // Check if the item name matches the criteria
  // //         if (item.name === criteria.name && item.data) {
  // //             // Filter subItems based on active status and flag === true
  // //             const filteredSubItems = item.data.filter(
  // //                 (subItem) => subItem.status === "active" && subItem.flag === true
  // //             );

  // //             // Accumulate valid subItems into the result array
  // //             acc.push(...filteredSubItems);
  // //         }
  // //         return acc;
  // //     }, []).map((subItem, index) => (
  // //         <Option key={index} value={subItem?._id}>
  // //             {subItem?.name}
  // //         </Option>
  // //     )) || fallbackData?.map((item, index) => (
  // //         <Option key={index} value={item}>
  // //             {item}
  // //         </Option>
  // //     ));
  //  };

  const { values, handleChange, handleBlur, errors, touched, setFieldValue } = useFormikContext();
  const dispatch = useDispatch();
  console.log("values", id)
  const options = useSelector((state) => state.dataBucket.databucket);
  const [agencyName, setAgencyName] = useState(values.agencyName || '');
  const [address, setAddress] = useState(values.address || '');
  const [city, setCity] = useState(values.city || '');
  const [state, setState] = useState(values.state || '');
  const [webURL, setWebURL] = useState(values.webURL || '');
  const [contactInfoURL, setContactInfoURL] = useState(values.contactInfoURL || '');
  const [linkedinURL, setLinkedinURL] = useState(values.linkedinURL || '');

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setAgencyName('');
      setAddress('');
      setCity('');
      setState('');
      setWebURL('');
      setContactInfoURL('');
      setLinkedinURL('');
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch]);

  const getTimeZoneFinal = (timezonecode) => {
    let finalTimeZone = timezonecode;
    DataBucket.SALES_TIMEZONES?.map((SALES_TIMEZONE) => {
      if (SALES_TIMEZONE?.toLowerCase() == timezonecode?.toLowerCase()) {
        finalTimeZone = SALES_TIMEZONE;
      }
    })
    return finalTimeZone;
  }
  const getTimeZone = (timezonecode) => {

    // console.log('timezonecode?.length', timezonecode?.length, timezonecode)
    if (timezonecode?.length == 3) {
      switch (timezonecode) {
        case 'EST':
          return "Eastern Time";
        case 'CST':
          return "Central Time";
        case 'MST':
          return "Mountain Time";
        case 'PST':
          return "Pacific Time";
        case 'HST':
          return "Hawaii Time";
      }
    } else {
      return getTimeZoneFinal(timezonecode);
    }
  }
  const salesLeadId = useSelector((state) => state?.sales?.sale?.leadID);
  const WorkingId = useSelector((state) => state?.timer?.requestId);
  return (
    <div
      style={{
        marginTop: '15px',
        backgroundColor: '#fff',
        padding: '20px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        borderRadius: '8px',
      }}
    >
      <Typography.Title level={5}>
        Agency Information
        {
          id !== undefined && (
            WorkingId == leadID && task === CurrentTask ? (
              <Button
                color="danger"
                variant="solid"
                style={{
                  float: 'right',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleStop()}
              >
                Stop
              </Button>
            ) : (
              <Button
                color="primary"
                variant="solid"
                style={{
                  float: 'right',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={() => handleStartButtonClick()}
              >
                Start
              </Button>
            )
          )
        }

      </Typography.Title>

      <Row gutter={16}>
        {/* Agency Name Field */}
        <Col span={5}>
          <Form.Item
            // required
            label={
              <span>
                Name of the Agency <span style={{ color: "red" }}>*</span>
              </span>
            }
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={touched.agencyName && errors.agencyName}
            validateStatus={touched.agencyName && errors.agencyName ? 'error' : ''}
          >
            <Input
              name="agencyName"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        {/* Address Field */}
        <Col span={5}>
          <Form.Item
            label="Address"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={touched.address && errors.address}
            validateStatus={touched.address && errors.address ? 'error' : ''}
          >
            <Input
              name="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        {/* City Field */}
        <Col span={5}>
          <Form.Item
            label="City"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={touched.city && errors.city}
            validateStatus={touched.city && errors.city ? 'error' : ''}
          >
            <Input
              name="city"
              value={city}
              onChange={(e) => setCity(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        {/* State Field */}
        <Col span={5}>
          <Form.Item
            label="State"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={touched.state && errors.state}
            validateStatus={touched.state && errors.state ? 'error' : ''}
          >
            <Input
              name="state"
              value={state}
              onChange={(e) => setState(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        {/* TimeZone Field */}
        <Col span={4}>
          <Form.Item
            label="TimeZone"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            help={touched.timeZone && errors.timeZone}
            validateStatus={touched.timeZone && errors.timeZone ? 'error' : ''}
          >
            <Field name="timeZone">
              {({ field, form }) => (
                <Select
                  value={getTimeZone(field.value)}
                  onChange={(value) => form.setFieldValue(field.name, value)}
                >
                  {
                    renderOptions(options, { name: "SALES_TIMEZONES" }, DataBucket.SALES_TIMEZONES)
                  }

                </Select>
              )}
            </Field>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item label="Website URL" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Input
              name="webURL"
              value={webURL}
              onChange={(e) => setWebURL(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label="Contact Information URL"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              name="contactInfoURL"
              value={contactInfoURL}
              onChange={(e) => setContactInfoURL(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item label="LinkedIn URL" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Input
              name="linkedinURL"
              value={linkedinURL}
              onChange={(e) => setLinkedinURL(e.target.value)}
              onBlur={handleChange}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

AgencyInformationForm.propTypes = {
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleStartButtonClick: PropTypes.func.isRequired,
  handleStop: PropTypes.func.isRequired,
  isRunning: PropTypes.bool.isRequired,
  task: PropTypes.string.isRequired,
  CurrentTask: PropTypes.string.isRequired,
};

export default AgencyInformationForm;
