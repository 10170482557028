import React from "react";
import ReactECharts from 'echarts-for-react';
import { useQuery } from "@apollo/client";
import { GET_ALL_ORDERS } from "../../../Queries/Orders";
import { useSelector, useDispatch } from "react-redux";
import { setLoader } from "../../../Slices/loaderSlice";
import { getSummaryData } from "../../../Slices/orderSlice";
import { Typography } from "antd";

const RequestsStats = () => {
    const dispatch=useDispatch();
    const { data, loading } = useQuery(GET_ALL_ORDERS, {
        variables: { page: 1, pageSize: 50, filters: { filter: "Inbox" }, isAllOrders: false },
        fetchPolicy: 'no-cache',
    })
    dispatch(setLoader(loading))
    console.log(data)
    dispatch(getSummaryData(data?.getAllOrders?.summary));


    const summary = useSelector((state) => state.orders.summary);
    const getInboxOnDemand = summary?.inbox?.getInboxOnDemand;
    const getAvailableOnDemand = summary?.inbox?.getAvailableOnDemand;
    const getInprogressOnDemand = summary?.inbox?.getInprogressOnDemand;
    const getOnholdOnDemand = summary?.inbox?.getOnholdOnDemand;
    const getInboxRush = summary?.inbox?.getInboxRush;
    const getAvailableRush = summary?.inbox?.getAvailableRush;
    const getInprogressRush = summary?.inbox?.getInprogressRush;
    const getOnholdRush = summary?.inbox?.getOnholdRush;
    const getInboxBulk = summary?.inbox?.getInboxBulk;
    const getAvailableBulk = summary?.inbox?.getAvailableBulk;
    const getInprogressBulk = summary?.inbox?.getInprogressBulk;
    const getOnholdBulk = summary?.inbox?.getOnholdBulk;

    const getInboxOnDemandWeekly = summary?.inbox?.getInboxOnDemandWeekly;
    const getAvailableOnDemandWeekly = summary?.inbox?.getAvailableOnDemandWeekly;
    const getInprogressOnDemandWeekly = summary?.inbox?.getInprogressOnDemandWeekly;
    const getOnholdOnDemandWeekly = summary?.inbox?.getOnholdOnDemandWeekly;

    const getInboxRushWeekly = summary?.inbox?.getInboxRushWeekly;
    const getAvailableRushWeekly = summary?.inbox?.getAvailableRushWeekly;
    const getInprogressRushWeekly = summary?.inbox?.getInprogressRushWeekly;
    const getOnholdRushWeekly = summary?.inbox?.getOnholdRushWeekly;

    const getInboxBulkWeekly = summary?.inbox?.getInboxBulkWeekly;
    const getAvailableBulkWeekly = summary?.inbox?.getAvailableBulkWeekly;
    const getInprogressBulkWeekly = summary?.inbox?.getInprogressBulkWeekly;
    const getOnholdBulkWeekly = summary?.inbox?.getOnholdBulkWeekly;

    const getInboxOnDemandMonthly = summary?.inbox?.getInboxOnDemandMonthly;
    const getAvailableOnDemandMonthly = summary?.inbox?.getAvailableOnDemandMonthly;
    const getInprogressOnDemandMonthly = summary?.inbox?.getInprogressOnDemandMonthly;
    const getOnholdOnDemandMonthly = summary?.inbox?.getOnholdOnDemandMonthly;

    const getInboxRushMonthly = summary?.inbox?.getInboxRushMonthly;
    const getAvailableRushMonthly = summary?.inbox?.getAvailableRushMonthly;
    const getInprogressRushMonthly = summary?.inbox?.getInprogressRushMonthly;
    const getOnholdRushMonthly = summary?.inbox?.getOnholdRushMonthly;
    const getInboxBulkMonthly = summary?.inbox?.getInboxBulkMonthly;
    const getAvailableBulkMonthly = summary?.inbox?.getAvailableBulkMonthly;
    const getInprogressBulkMonthly = summary?.inbox?.getInprogressBulkMonthly;
    const getOnholdBulkMonthly = summary?.inbox?.getOnholdBulkMonthly;

    const totalInboxCount = getInboxOnDemand + getInboxRush + getInboxBulk;

    const getEmailneeded = summary?.emailNeeded?.getEmailneeded;
    const getEmailneededOnDemand = summary?.emailNeeded?.getEmailneededOnDemand;
    const getEmailneededRush = summary?.emailNeeded?.getEmailneededRush;
    const getEmailneededBulk = summary?.emailNeeded?.getEmailneededBulk;

    const getEmailneededOnDemandWeekly = summary?.emailNeeded?.getEmailneededOnDemandWeekly;
    const getEmailneededRushWeekly = summary?.emailNeeded?.getEmailneededRushWeekly;
    const getEmailneededBulkWeekly = summary?.emailNeeded?.getEmailneededBulkWeekly;

    const getEmailneededOnDemandMonthly = summary?.emailNeeded?.getEmailneededOnDemandMonthly;
    const getEmailneededRushMonthly = summary?.emailNeeded?.getEmailneededRushMonthly;
    const getEmailneededBulkMonthly = summary?.emailNeeded?.getEmailneededBulkMonthly;

    const totalEmailneededCount = getEmailneeded;

    const getPendings = summary?.pending?.getPendings;
    const getPendingsOnDemand = summary?.pending?.getPendingsOnDemand;
    const getPendingsRush = summary?.pending?.getPendingsRush;
    const getPendingsBulk = summary?.pending?.getPendingsBulk;

    const getPendingsOnDemandWeekly = summary?.pending?.getPendingsOnDemandWeekly;
    const getPendingsRushWeekly = summary?.pending?.getPendingsRushWeekly;
    const getPendingsBulkWeekly = summary?.pending?.getPendingsBulkWeekly;

    const getPendingsOnDemandMonthly = summary?.pending?.getPendingsOnDemandMonthly;
    const getPendingsRushMonthly = summary?.pending?.getPendingsRushMonthly;
    const getPendingsBulkMonthly = summary?.pending?.getPendingsBulkMonthly;

    const totalPendingsCount = getPendingsOnDemand + getPendingsRush + getPendingsBulk;

    const getDiscardOnDemandMonthly = summary?.discard?.getDiscardOnDemandMonthly;
    const getDiscardRushMonthly = summary?.discard?.getDiscardRushMonthly;
    const getDiscardBulkMonthly = summary?.discard?.getDiscardBulkMonthly;


    const getCompletedOnDemand = summary?.completed?.getCompletedOnDemand;
    const getCompletedRush = summary?.completed?.getCompletedRush;
    const getCompletedBulk = summary?.completed?.getCompletedBulk;
    const getCompletedTodayOnDemand = summary?.completed?.getCompletedTodayOnDemand;
    const getCompletedThisMonthOnDemand = summary?.completed?.getCompletedThisMonthOnDemand;
    const getCompletedThisYearOnDemand = summary?.completed?.getCompletedThisYearOnDemand;
    const getCompletedTodayRush = summary?.completed?.getCompletedTodayRush;
    const getCompletedThisMonthRush = summary?.completed?.getCompletedThisMonthRush;
    const getCompletedThisYearRush = summary?.completed?.getCompletedThisYearRush;
    const getCompletedTodayBulk = summary?.completed?.getCompletedTodayBulk;
    const getCompletedThisMonthBulk = summary?.completed?.getCompletedThisMonthBulk;
    const getCompletedThisYearBulk = summary?.completed?.getCompletedThisYearBulk;

    const totalCompletedCount = getCompletedOnDemand + getCompletedRush + getCompletedBulk;
    const getAllMonths = () => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const currentMonth = new Date().getMonth(); // Get current month (0-11)
        const currentYear = new Date().getFullYear();

        // Get the last 12 months (including current month)
        const monthsData = [];
        for (let i = 0; i < 12; i++) {
            const monthIndex = (currentMonth - i + 12) % 12; // Handle the previous months
            const year = currentMonth - i < 0 ? currentYear - 1 : currentYear; // Account for previous year
            monthsData.unshift(`${months[monthIndex]} ${year}`); // Prepend to get the months in correct order
        }

        return monthsData;
    };

    // Prepare Monthly Data for the past 12 months
    const monthsData = getAllMonths(); // Get all months in correct order (current month on the right)

    const monthlyData = [
        totalInboxCount, // Current month's total count (You can replace this with dynamic data)
        // You would need to replace these with actual data fetched from your backend for each month
        getInboxOnDemand,
        getAvailableOnDemand,
        getInprogressOnDemand,
        getOnholdOnDemand,
        getInboxRush,
        getAvailableRush,
        getInprogressRush,
        getOnholdRush,
        getInboxBulk,
        getAvailableBulk,
        getInprogressBulk,
        getOnholdBulk,
    ];

    // Ensure the number of months and data points match
    const adjustedMonthlyData = monthlyData.slice(0, monthsData.length); // Slice data to match the calls of months

    // Set up the Inbox Monthly Bar Graph Data
    const InboxMonthlyBarData = {
        xAxis: {
            type: 'category',
            data: monthsData, // Use the months dynamically
            axisLabel: {
                interval: 0, // Show all labels (ensure all months are visible)
                rotate: 45,  // Rotate labels if necessary for better visibility
            }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: adjustedMonthlyData,
                type: 'bar',
                barWidth: '70%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            '#1cc88a', '#36b9cc', '#f6c23e', '#FF5733', '#f6c23e', '#FF5733', '#FF6384', '#f6c23e', '#36b9cc', '#f6c23e', '#36b9cc', '#FF5733'
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptions = {
        title: {
            text: 'Total Requests',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        xAxis: InboxMonthlyBarData.xAxis,
        yAxis: InboxMonthlyBarData.yAxis,
        series: InboxMonthlyBarData.series,
    };


    const InboxMonthlyBarDataPriority = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
                //"Available On Demand", "Available Rush", "Available Bulk",
                // "In-Progress On Demand", "In-Progress Rush", "In-Progress Bulk",
                // "On Hold On Demand", "On Hold Rush", "On Hold Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getInboxOnDemand, getInboxRush, getInboxBulk,
                    //getAvailableOnDemandMonthly, getInprogressOnDemandMonthly,getOnholdOnDemandMonthly,
                    //  getAvailableRushMonthly, getAvailableBulkMonthly,
                    // getInprogressRushMonthly, getInprogressBulkMonthly,
                    // getOnholdOnDemandMonthly, getOnholdRushMonthly, getOnholdBulkMonthly
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            // '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsPriority = {
        title: {
            text: 'Priority Wise',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataPriority.xAxis,
        yAxis: InboxMonthlyBarDataPriority.yAxis,
        series: InboxMonthlyBarDataPriority.series,
    };

    const InboxMonthlyBarDataCompleted = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getCompletedThisMonthOnDemand, getCompletedThisMonthRush, getCompletedThisMonthBulk,
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            // '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsCompleted = {
        title: {
            text: 'Completed',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataCompleted.xAxis,
        yAxis: InboxMonthlyBarDataCompleted.yAxis,
        series: InboxMonthlyBarDataCompleted.series,
    };

    const InboxMonthlyBarDataPedings = {
        xAxis: {
            type: 'category',
            data: [
                "Pendings"
            ],
            boundaryGap: true,
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    totalPendingsCount,
                ],
                type: 'bar',
                position: 'left',
                barWidth: '20%',
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            //'#36b9cc', 
                            //'#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            //'#FF5733', 
                            '#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsPendins = {
        title: {
            text: 'Pendings',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataPedings.xAxis,
        yAxis: InboxMonthlyBarDataPedings.yAxis,
        series: InboxMonthlyBarDataPedings.series,
    };
    const InboxMonthlyBarDataEmailNeeded = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getEmailneededOnDemand, getEmailneededRush, getEmailneededBulk
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            //'#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsEmailNeeded = {
        title: {
            text: 'Email Needed',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataEmailNeeded.xAxis,
        yAxis: InboxMonthlyBarDataEmailNeeded.yAxis,
        series: InboxMonthlyBarDataEmailNeeded.series,
    };
    const InboxMonthlyBarDataDiscard = {
        xAxis: {
            type: 'category',
            data: [
                "On Demand", "Rush", "Bulk"
            ],
            // axisLabel: {
            //     interval: 0,  // Rotate the labels if necessary
            //     rotate: 45,  // Adjust the angle for better visibility
            // }
        },
        yAxis: {
            type: 'value',
        },
        series: [
            {
                data: [
                    getDiscardOnDemandMonthly, getDiscardRushMonthly, getDiscardBulkMonthly
                ],
                type: 'bar',
                barWidth: '30%',  // Adjust bar width
                itemStyle: {
                    color: function (params) {
                        const colors = [
                            //'#1cc88a',
                            '#36b9cc',
                            '#f6c23e', // Available
                            // '#36b9cc', 
                            // '#f6c23e', '#FF5733', // In-Progress
                            // '#f6c23e', 
                            '#FF5733',
                            //'#FF6384'  // On Hold
                        ];
                        return colors[params.dataIndex];
                    }
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}'  // Show data values at the top of bars
                }
            }
        ]
    };

    const InboxMonthlyBarOptionsDiscard = {
        title: {
            text: 'Discard',
            //left: 'center',
            textStyle: {
                fontWeight: 'bold',
                fontSize: 18,
                color: '#000000'
            }
        },
        // tooltip: {
        //     trigger: 'axis',
        //     axisPointer: {
        //         type: 'shadow'
        //     },
        //     formatter: function (params) {
        //         return `${params[0].name}: ${params[0].value}`;
        //     }
        // },
        // grid: {
        //     left: '10%',
        //     right: '10%',
        //     bottom: '10%',
        //     top: '20%',
        // },
        xAxis: InboxMonthlyBarDataDiscard.xAxis,
        yAxis: InboxMonthlyBarDataDiscard.yAxis,
        series: InboxMonthlyBarDataDiscard.series,
    };

    return (
        <div className="page-wrapper">
            <div className="content container-fluid">
                <Typography style={{ marginBottom: "20px", fontWeight: 'bold' }}>Requests Dashboard</Typography>
                <div className="row">
                    {/* First Card */}
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group">
                            <div className="card">

                                <div className="card-body">

                                    <h3 style={{ fontWeight: 'bold', color: 'black' }}>Current Month Details</h3>
                                    <div style={{ display: 'flex' }}>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptions} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsPriority} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsCompleted} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {/* Second Card */}
                    <div className="col-md-12 col-sm-12">
                        <div className="card-group m-b-30">
                            <div className="card">

                                <div className="card-body">

                                    <div style={{ display: 'flex' }}>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsPendins} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsEmailNeeded} />
                                        </div>
                                        <div style={{ height: "300px", width: "400px" }}>
                                            <ReactECharts option={InboxMonthlyBarOptionsDiscard} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default RequestsStats