import { render } from "@testing-library/react";
import { Table, Input, Button, Modal, message, Spin } from "antd";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setId } from "../../../Slices/employeeslice";
import { useSelector } from "react-redux";
import { Filter } from "react-feather";
import Item from "antd/es/list/Item";
import { FastBackwardFilled } from "@ant-design/icons";
import { useMutation, useQuery } from "@apollo/client";
import { RESET_TOKEN } from "../../../Queries/ResetToken";
import { GET_EMPLOYEE_DETAILS } from "../../../Queries/Employees";
import { updateEmployeeToken } from "../../../Slices/employeeslice"


const ResetToken = () => {

    const dispatch = useDispatch();
    
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState(null);

    // const details = useSelector((state) => state.employee.AllEmployees);

    const {data,refetch, loading} = useQuery(GET_EMPLOYEE_DETAILS)
    const employees  = data?.EmployeeDetail?.response;

    const [ResetToken] = useMutation(RESET_TOKEN);

    useEffect(()=>{
        refetch()
    },[refetch])


    useEffect(() => {
        dispatch(setId(true));
        setFilteredEmployees(employees);
    }, [employees, dispatch]);
    


    const handleChange = (e) => {
        const val = e.target.value.toLowerCase();
        if(!val) {
            setFilteredEmployees(employees);
            return;
        }

        const filteredData = employees.filter(
            (item) => item.firstname?.toLowerCase()?.includes(val) ||
                      item.lastname?.toLowerCase()?.includes(val)
        );
        setFilteredEmployees(filteredData);
    };


    const showResetTokenModal = (employee) => {
        setSelectedEmployee(employee);
        setIsModalVisible(true);
    };


    const handleResetToken = async  () => {
        if (!selectedEmployee ) {
            message.error("Invalid Employee ID. Please try again.");
            return;
          }
        
        try{
            const {data} = await ResetToken({
                variables: {
                    // id: selectedEmployee.id,
                    email: selectedEmployee.email
                },
            });

            if (data.ResetToken.success===true) {
                const newToken = data.ResetToken.token;
                message.success( "Token Reset Successful" );
                refetch();
            }else {
                message.error("Failed to reset token.");
                }
        } catch (error) {
            message.error("Failed to reset token. Please try again.");
        }
                
            setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    }


    const columns = [
        {
            title: "User Name",
            dataIndex: "UserName",
            render: (_, record) => {
                return `${record.firstname || ""} ${record.lastname || ""}`;
            },
            width: 100,
            align: "start",
            onHeaderCell: () => ({
                style: {
                    backgroundColor: "#e0e0e0",
                    color: "black",
                },
            }),
        },
        {
            title: "Email",
            dataIndex: "email",
            width: 200,
            align: "center",
            onHeaderCell: () => ({
                style: {
                    backgroundColor: "#e0e0e0",
                    color: "black",
                },
            }),
        },
        {
            title: "Token",
            dataIndex: "token",
            width: 100,
            align: "center",
            render: (_, record) => {
                return record.token || "N/A";
            },
            onHeaderCell: () => ({
                style: {
                    backgroundColor: "#e0e0e0",
                    color: "black",
                },
            }),
        },
        {
            title: "Action",
            dataIndex: "action",
            width: 100,
            align: "center",
            onHeaderCell: () => ({
                style: {
                    backgroundColor: "#e0e0e0",
                    color: "black",
                },
            }),
            render:  (_, record) => (
                <Button type="primary" onClick={() => showResetTokenModal(record)}> 
                    Reset Token
                </Button>
            ),
        }
    ];

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid" >

                    <Input
                        type="text"
                        placeholder="Search By Name..."
                        onChange={handleChange}
                        style={{
                        marginLeft: "20px",
                        padding: "5px",
                        marginBottom: "20px",
                        width: "200px",
                        border: "1px solid #d9d9d9",
                        outline: "none",
                        // borderRadius: "4px",
                        }}
                    />

                {loading ? (
                    <Spin size="large" style={{ display: "block", margin: "20px auto" }} />
                ) : (
                <Table 
                    columns={columns} 
                    dataSource={filteredEmployees} 
                    // rowKey={(record) => record.id}
                    rowKey={(record) => record.email}
                    bordered 
                    rowClassName="table-row"
                />
                )}

                <Modal
                    open= {isModalVisible}
                    onOk={handleResetToken}
                    onCancel={handleCancel}
                    style={{textAlign: 'center'}}
                    footer={[
                        <div style={{ display: "flex", justifyContent: "center", gap: "20px" }}>
                            <Button key="cancel" onClick={handleCancel}>
                                No
                            </Button>
                            <Button key="ok" type="primary" onClick={handleResetToken}>
                                Yes
                            </Button>
                        </div>,
                    ]}
                >
                    Are you Sure want to Reset Token for <b>{selectedEmployee?.firstname} {selectedEmployee?.lastname}</b>?
                </Modal>
                
                </div>
            </div>
        
        </>
    );
}

export default ResetToken;
