import React, { useState, useEffect } from "react";
import { Avatar_02 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
//import DatePicker from "react-datepicker";
//import Select from "react-select";
import { useQuery, useMutation, from } from "@apollo/client";
import { GET_USER_DETAILS, GET_ALL_USERS } from '../../Queries/Users';
import { GET_ALL_CLIENTS } from '../../Queries/Users';
import { UPDATE_REQUEST, GET_REQUESTS_BY_ID } from "../../Queries/Orders"
import { requestInfoData, requestDetailsData } from "../../Slices/orderSlice"
//import { Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import moment from "moment";
import dayjs from "dayjs";
import * as DataBucket from "../../../src/databucket";
import ExistingPolicyInformation from "../../views/pages/Requests/ExistingPlicyInformation";
import NewQuoteInformation from "../../views/pages/Requests/NewQuoteInformation";
//import { AutoComplete } from 'antd';
import { DatePicker, ConfigProvider } from 'antd';
import { useSelector } from "react-redux";
import locale from 'antd/es/locale/en_US';
import { Checkbox, Form, Select, Switch, Row, Col, Typography } from 'antd';
import { message } from "antd";
import { CheckOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
const { Option } = Select;


const RequestProfileModelPopup = (props) => {
  const { modalOpen } = props;
  const [form] = Form.useForm();
  const requestInformation = {
    id: '',
    requestId: '',
    date: '',
    dueDate: '',
    priority: '',
    processType: '',
    assignedTo: '',
    assignTo: '',
    startDate: '',
    completedDate: '',
    requestStatus: '',
    Assign: false,
    radioInfo: '',
    fromPending: false,
    instructions: false,
    from: '',
    to: '',
    subject: '',
    body: '',
    clientName: '',
  }
  const [employeeInfo, setEmployeeInfo] = useState(requestInformation)
  const requestDetails = {
    id: '',
    quoteType: '',
    department: '',
    insuredName: '',
    noofQuotes: '',
    Auto: false,
    MotorCycle: false,
    Boat: false,
    DP3: false,
    HO3: false,
    HO4: false,
    HO5: false,
    HO6: false,
    existingPolicyInformation: [
      {
        currentCarrier: '',
        propertyType: '',
        propertyNew: '',
        policyNumber: '',
        effectiveDate: '',
        currentPremium: ''
      }
    ],
    newQuoteInformation: [
      {
      policy: '',
      insuranceCarrier: '',
      eligibility: '',
      quotedPremium: '',
      remarks: ''
    }
  ]
  }
  const [requestDetailsDtaInfo, setRequestDetailsDtaInfo] = useState(requestDetails)
  console.log("requestdetailsdata", requestDetailsDtaInfo)
  const { id } = useParams();

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const userid = parseJwt(token).id;
  const [assignToUsers, setAssignToUsers] = useState([]);
  // const [clients, setClients] = useState([]);
  const clients = useSelector(state => state.clients.clients);
  
  const [selectedDepartment, setSelectedDepartment] = useState("");
  //const [employeeInfoState, setEmployeeInfoState] = useState({ assignedTo: '', Assign: false, requestStatus: 'Open', });
  const [values, setValues] = useState({ radioInfo: employeeInfo?.radioInfo });
  const [pending, setPending] = useState({ fromPending: false });
  //const [instructionVal, setInstructionVal] = useState(employeeInfo.instructions ? employeeInfo.instructions : false);
  //const [fromPendingVal, setfromPendingVal] = useState(employeeInfo.fromPending ? employeeInfo.fromPending : false);
  const [loginUser, setLoginUser] = useState({});
  const [timestamp, setTimestamp] = useState();
  const [ReqDue, setReqDue] = useState(true);
  const [startDate, setStartDate] = useState(dayjs(parseInt(employeeInfo?.date)));
  console.log("startdate", startDate)
  //const [dueDate, setDueDate] = useState(dayjs(employeeInfo?.dueDate));
  //console.log("duedate", dueDate);
  const [isUserDataValid, setIsUserDataValid] = useState(false);
  const [errors, setErrors] = useState({});
  const [errorChange, setErrorChange] = useState(false);

  const dispatch = useDispatch();
  const [updateRequest] = useMutation(UPDATE_REQUEST)
  const [getRequestById, { }] = useMutation(GET_REQUESTS_BY_ID, {
    onCompleted(data) {
      const requestInfo = data?.getRequestById[0];
      console.log("requestInfo", requestInfo)
      setEmployeeInfo({
        id: requestInfo?.id,
        requestId: requestInfo?.requestId,
        date: requestInfo?.date,
        dueDate: requestInfo?.dueDate,
        startDate: requestInfo?.startDate,
        priority: requestInfo?.priority,
        processType: requestInfo?.processType,
        assignedTo: requestInfo?.assignedTo,
        requestStatus: requestInfo?.requestStatus,
        assignTo: requestInfo?.assignTo,
        Assign: requestInfo?.Assign,
        // date: requestInfo?.date,
        // dueDate: requestInfo?.dueDate,
        completedDate: requestInfo?.completedDate,
        fromPending: requestInfo?.fromPending,
        instructions: requestInfo?.instructions,
        radioInfo: requestInfo?.radioInfo,
        from: requestInfo?.from,
        to: requestInfo?.to,
        subject: requestInfo?.subject,
        body: requestInfo?.body,
        clientName: requestInfo?.clientName,
      })
      dispatch(requestInfoData({
        id: requestInfo?.id || '',
        requestId: requestInfo?.requestId || '',
        date: requestInfo?.date || '',
        dueDate: requestInfo?.dueDate || '',
        startDate: requestInfo?.startDate || '',
        priority: requestInfo?.priority || '',
        processType: requestInfo?.processType || '',
        assignedTo: requestInfo?.assignedTo || '',
        requestStatus: requestInfo?.requestStatus || '',
        assignTo: requestInfo?.assignTo || '',
        Assign: requestInfo?.Assign || '',
        // date: requestInfo?.date || '',
        // dueDate: requestInfo?.dueDate || '',
        completedDate: requestInfo?.completedDate || '',
        fromPending: requestInfo?.fromPending || '',
        instructions: requestInfo?.instructions || '',
        radioInfo: requestInfo?.radioInfo || '',
        from: requestInfo?.from,
        to: requestInfo?.to,
        subject: requestInfo?.subject,
        body: requestInfo?.body,
        clientName: requestInfo?.clientName
      }))

      setRequestDetailsDtaInfo({
        id: requestInfo?.id,
        quoteType: requestInfo?.quoteType,
        department: requestInfo?.department,
        insuredName: requestInfo?.insuredName,
        noofQuotes: parseInt(requestInfo?.noofQuotes),
        Auto: requestInfo?.Auto,
        MotorCycle: requestInfo?.MotorCycle,
        Boat: requestInfo?.Boat,
        DP3: requestInfo?.DP3,
        HO3: requestInfo?.HO3,
        HO4: requestInfo?.HO4,
        HO5: requestInfo?.HO5,
        HO6: requestInfo?.HO6,
        existingPolicyInformation: [{
          currentCarrier: requestInfo?.existingPolicyInformation[0]?.currentCarrier,
          propertyType: requestInfo?.existingPolicyInformation[0]?.propertyType,
          policyNumber: requestInfo?.existingPolicyInformation[0]?.policyNumber,
          effectiveDate: requestInfo?.existingPolicyInformation[0]?.effectiveDate,
          currentPremium: requestInfo?.existingPolicyInformation[0]?.currentPremium,
        }],
        newQuoteInformation: [{
          policy: requestInfo?.newQuoteInformation[0]?.policy,
          insuranceCarrier: requestInfo?.newQuoteInformation[0]?.insuranceCarrier,
          eligibility: requestInfo?.newQuoteInformation[0]?.eligibility,
          quotedPremium: requestInfo?.newQuoteInformation[0]?.quotedPremium,
          remarks: requestInfo?.newQuoteInformation[0]?.remarks,
        }]
      })
      dispatch(requestDetailsData({
        id: requestInfo?.id || '',
        quoteType: requestInfo?.quoteType || '',
        department: requestInfo?.department || '',
        insuredName: requestInfo?.insuredName || '',
        noofQuotes: parseInt(requestInfo?.noofQuotes),
        Auto: requestInfo?.Auto || '',
        MotorCycle: requestInfo?.MotorCycle || '',
        Boat: requestInfo?.Boat || '',
        DP3: requestInfo?.DP3 || '',
        HO3: requestInfo?.HO3 || '',
        HO4: requestInfo?.HO4 || '',
        HO5: requestInfo?.HO5 || '',
        HO6: requestInfo?.HO6 || '',
        existingPolicyInformation: [{
          currentCarrier: requestInfo?.existingPolicyInformation[0]?.currentCarrier,
          propertyType: requestInfo?.existingPolicyInformation[0]?.propertyType,
          policyNumber: requestInfo?.existingPolicyInformation[0]?.policyNumber,
          effectiveDate: requestInfo?.existingPolicyInformation[0]?.effectiveDate,
          currentPremium: requestInfo?.existingPolicyInformation[0]?.currentPremium,
        }],
        newQuoteInformation: [{
          policy: requestInfo?.newQuoteInformation[0]?.policy,
          insuranceCarrier: requestInfo?.newQuoteInformation[0]?.insuranceCarrier,
          eligibility: requestInfo?.newQuoteInformation[0]?.eligibility,
          quotedPremium: requestInfo?.newQuoteInformation[0]?.quotedPremium,
          remarks: requestInfo?.newQuoteInformation[0]?.remarks,
        }]
      }))
    }
  })
  //const [selectedDate, setSelectedDate] = useState(dayjs(parseInt(employeeInfo.dueDate) || parseInt(timestamp)).toDate());
  const [getUserDetails, { }] = useMutation(GET_USER_DETAILS, {
    onCompleted(data) {
      // console.log(data.getUserDetails[0], 'getUserDetails-->')
      setLoginUser(data?.getUserDetails[0])
    },
  });

  // const resetRequestInfo = ()=>{
  //   setEmployeeInfo(requestInformation)
  // }

  const resetRequestDetails = () => {
    setRequestDetailsDtaInfo(requestDetails)
  }

  const getDateTime = (timeValue) => {
    console.log('getDateTime', timeValue)
    const startDate = new Date(timeValue).toLocaleDateString();
    const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

    const dateTimeString = `${startDate} ${startTimeString}`;

    return dateTimeString;
  }

  const getDueTime = (timeValue, typeValue) => {

    const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

    const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');
    console.log('getDateTime1', newDateTime, timeValue, typeValue, employeeInfo.date)

    return newDateTime;
  }

  const getDueTimePriority = (emailText) => {
    let hours = 24;
    const hasRushOrUrgent = emailText.toLowerCase().includes("rush") || emailText.toLowerCase().includes("urgent");

    if (hasRushOrUrgent) {
      hours = 2;
      // }else if(){

    }

    return hours;
  }

  console.log(userid);

  const { data, loading, error, refetch } = useQuery(GET_ALL_USERS, {
    variables: { loginUserId: userid },
  });

  let userscontent = data ? data.getAllUsers : [];
  console.log(userscontent);

  // const { } = useQuery(GET_ALL_CLIENTS, {
  //   onCompleted(data) {
  //     setClients(data?.getAllClients?.response)
  //     console.log("clients", clients)
  //   }
  // });
  useEffect(() => {

    console.log("userscontent", userscontent)

    if (userscontent.status == 200) {
      if (userscontent.response?.length > 0) {
        setAssignToUsers(userscontent.response)
        console.log("userscontent", userscontent.response)

      }
    }

  }, [userscontent]);
  // useEffect(() => {

  //   console.log("userscontent", clients)

  //   if (clients.status == 200) {
  //     if (clients.response?.length > 0) {
  //       setClients(clients.response)
  //       console.log("userscontent", clients.response)

  //     }
  //   }

  // }, [clients]);

  useEffect(() => {
    const valuesObject = {
      id: id
    }
    getRequestById({ variables: valuesObject })
    // if (data) {
    //   setEmployeeData(data?.SingleEmployee?.response); // Update employee data when data is available
    // }
  }, [])

  useEffect(() => {

    const employeeObj = {
      id: parseJwt(token)?.id,
    }
    getUserDetails({ variables: employeeObj })


    let priorityValue = getDueTimePriority(employeeInfo?.subject + ' ' + employeeInfo?.html)

    if (employeeInfo?.priority == null) {
      if (priorityValue == 2) {
        //setPriorityChange('priority', 'Rush')
      } else if (priorityValue == 24) {
        //setPriorityChange('priority', 'On Demand')
      } else {
        //setPriorityChange('priority', 'Bulk')
      }
    }
  }, []);
  // const priorityChangeHandler = (event) => {
  //   const priorityValue = event.target.value || '';
  //   console.log("priorityvalue",priorityValue);
  //   setEmployeeInfo({ ...employeeInfo, priority: priorityValue });

  //   // Recalculate the due date when the priority is changed
  //   GetDueDateTemp(employeeInfo, priorityValue);
  //   GetDueDateTempOverried(employeeInfo,employeeInfo?.date)
  // }

  const priorityChangeHandler = (event) => {
    const priorityValue = event.target?.value || '';
    console.log("priorityValue", priorityValue);

    // Update the priority in the employeeInfo state
    setEmployeeInfo(prevState => {
        const newEmployeeInfo = { ...prevState, priority: priorityValue };

        // Recalculate dueDate when priority is changed
        const newDueDate = GetDueDateTempOverried(newEmployeeInfo, newEmployeeInfo?.date);
        console.log("newDate",newDueDate)

        return { ...newEmployeeInfo, dueDate: newDueDate }; // Update both priority and dueDate
    });
};


  const GetDueDateTemp = (employeeInfo, priorityValue = null) => {
    let dateValue = employeeInfo?.date;
    const priority = priorityValue || employeeInfo?.priority;

    console.log('value.date', dateValue, priority);

    if (!priority) {
      return getDateTime(getDueTime(parseInt(employeeInfo?.date), getDueTimePriority(employeeInfo?.subject + ' ' + employeeInfo?.html))
      );
    } else {
      if (priority === 'Rush') {
        dateValue = getDateTime(getDueTime(parseInt(employeeInfo?.date), 2));
      } else if (priority === 'On Demand') {
        dateValue = getDateTime(getDueTime(parseInt(employeeInfo?.date), 24));
      } else if (priority === 'Bulk') {
        dateValue = getDateTime(getDueTime(parseInt(employeeInfo?.date), 48));
      }
    }

    console.log('Updated due date:', dateValue);

    return dateValue;
  };

  // const GetDueDateTempOverried = (employeeInfo, date) => {
  //   let dateValue = employeeInfo?.date;
  //   if (employeeInfo?.priority == null) {
  //     getDateTime(getDueTime(parseInt(employeeInfo.date), getDueTimePriority(employeeInfo?.subject + ' ' + employeeInfo.html)))
  //   }
  //   else {

  //     if (employeeInfo?.priority == 'Rush') {

  //       dateValue = getDateTime(getDueTime(parseInt(date), 2))


  //     } else if (employeeInfo?.priority == 'On Demand') {
  //       dateValue = getDateTime(getDueTime(parseInt(date), 24))

  //     } else if (employeeInfo?.priority == 'Bulk') {
  //       dateValue = getDateTime(getDueTime(parseInt(date), 48));

  //     }
  //   }
  //   //setTimestamp(dateValue);
  //   console.log('priority', dateValue);
  //   //setDueDate(dateValue);
  //   return dateValue


  // }
  let GetDueDateTempOverried = (employeeInfo, date) => {
    let dateValue = employeeInfo?.date;
    if (employeeInfo?.priority == null) {
        dateValue = getDateTime(getDueTime(parseInt(employeeInfo.date), getDueTimePriority(employeeInfo?.subject + ' ' + employeeInfo.html)));
    } else {
        if (employeeInfo?.priority === 'Rush') {
            dateValue = getDateTime(getDueTime(parseInt(date), 2));
        } else if (employeeInfo?.priority === 'On Demand') {
            dateValue = getDateTime(getDueTime(parseInt(date), 24));
        } else if (employeeInfo?.priority === 'Bulk') {
            dateValue = getDateTime(getDueTime(parseInt(date), 48));
        }
    }
    
    console.log('Updated dueDate', dateValue);

    // Ensure the returned date is a dayjs object
    return (dateValue);  // Convert the date to dayjs
};


  const convertToFormattedStringAndSeconds = (dateString) => {
    // Step 1: Parse the date string into a moment object
    const date = moment(dateString, 'MM/DD/YYYY hh:mm A', true);

    // Check for invalid date
    if (!date.isValid()) {
      console.error("Invalid date format");
      return null;
    }

    // Step 2: Format the date into "dd/mm/yyyy hh:mm:ss a"
    const day = String(date.date()).padStart(2, '0');
    const month = String(date.month() + 1).padStart(2, '0'); // Months are 0-based
    const year = date.year();

    // Format hours, minutes, and seconds
    const formattedHours = date.hours() % 12 || 12; // Convert to 12-hour format
    const minutes = String(date.minutes()).padStart(2, '0');
    const seconds = String(date.seconds()).padStart(2, '0');
    const ampm = date.hours() < 12 ? 'am' : 'pm';

    const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;


    return {
      formattedDate,
      timestamp: date.valueOf() // Get Unix timestamp in milliseconds
    };
  };

  const AutoDateChanger = async (employeeInfo) => {
    console.log('ReqDue:', ReqDue);
    console.log('values.startDate:', employeeInfo.startDate);



    if (!employeeInfo?.dueDate) {
      let dateString = '';

      if (employeeInfo.startDate) {
        const times = convertToFormattedStringAndSeconds(employeeInfo.startDate);
        const value = times?.timestamp;
        const formate = GetDueDateTempOverried(employeeInfo, value);
        console.log('formate:', formate);
        dateString = formate;
      } else {
        console.log('Entering else block');
        dateString = GetDueDateTemp(employeeInfo);
        console.log('dateString:', dateString);
      }

      // Split and parse the dateString
      //const dateParts = dateString ? dateString.split(/[\s/:]+/) : [];
      const dateParts = dateString;
      const date = new Date(
        dateParts[2], // Year
        dateParts[1] - 1, // Month (0-based index)
        dateParts[0], // Day
        dateParts[3] % 12 + (dateParts[4] === "PM" ? 12 : 0), // Hours
        dateParts[4], // Minutes
        dateParts[5] // Seconds
      );

      const timestamp = date.getTime();
      setTimestamp(timestamp);
      const dueVal = dayjs(parseInt(timestamp));
      console.log('dueVal:', dueVal);

      //setDueDate('dueDate', dueVal);
    }
  };

  useEffect(() => {
    console.log('ReqDue100', ReqDue);

    console.log('duedate', employeeInfo.dueDate)
    //setDueDate('dueDate', employeeInfo?.dueDate);
    AutoDateChanger(employeeInfo);

  }, [employeeInfo.priority, employeeInfo.startDate, employeeInfo]);

  const DateCondition = () => {
    let minDate;
    if (employeeInfo.startDate > employeeInfo.dueDate) {
      minDate = dayjs(employeeInfo.startDate);
      console.log('startDate', minDate);
    }
    else if (employeeInfo.startDate) {
      minDate = dayjs(employeeInfo.startDate);
      console.log('RequestInfo', minDate);
    } else {
      minDate = dayjs(employeeInfo.date);
      console.log('date', minDate);
    }
    return minDate;
  }

  useEffect(() => {
    DateCondition();
  }, [employeeInfo]);

  useEffect(() => {
    // If employeeInfo changes and you want to update the date
    setStartDate(dayjs(parseInt(employeeInfo?.date)));
    //setDueDate(dayjs(parseInt(employeeInfo?.dueDate)))
  }, [employeeInfo]);

  // useEffect(() => {
  //   if (employeeInfo) {
  //     setEmployeeInfoState({
  //       assignedTo: employeeInfo?.assignedTo || '',
  //       Assign: employeeInfo?.Assign || false,
  //       requestStatus: employeeInfo?.Assign ? 'In Progress' : 'Open', // Set to 'in progress' if Assign is true
  //     });
  //   }
  // }, [employeeInfo]);
  const minDateValue = DateCondition();

  // const handleSelectChangesA = (key, value) => {
  //   setEmployeeInfoState((prev) => ({
  //     ...prev,
  //     [key]: value,
  //   }));
  // };
  // const handleInputChangeA = (e) => {
  //   const { name, value } = e.target;
  //   setEmployeeInfoState((prev) => ({
  //     ...prev,
  //     [name]: value,
  //   }));
  // };
  // const handleCheckboxChange = (e) => {
  //   const { checked } = e.target;
  //   setEmployeeInfoState((prev) => ({
  //     ...prev,
  //     Assign: checked,
  //     assignedTo: checked ? employeeInfo?.assignedTo || loginUser?.firstname : '', // Set assignedTo when checked, clear when unchecked
  //     requestStatus: checked ? 'In Progress' : 'Open', // Set requestStatus based on Assign
  //   }));
  // };
  const assignHandler = (e) => {
    const checked = e.target.checked;
    setEmployeeInfo((prev) => ({
      ...prev,
      Assign: checked,
      assignedTo: checked ? prev?.assignedTo || loginUser?.firstname : '',
      requestStatus: checked ? 'In Progress' : 'Open',
    }));
  };

  const radiohandleChange = (e) => {
    const { value } = e.target;

    // Perform state updates for radioInfo, completedDate, requestStatus, and pending in a single call
    setEmployeeInfo((prevState) => {
      let newEmployeeInfo = {
        ...prevState,
        radioInfo: value,
      };

      if (value === 'Completed') {
        // Update fields if the value is 'Completed'
        newEmployeeInfo = {
          ...newEmployeeInfo,
          completedDate: moment(),  // Set completed date to the current moment
          requestStatus: 'Completed',
        };

        setPending((prevPending) => ({
          ...prevPending,
          fromPending: false,
        }));
      } else if (value === 'Pending') {
        // Reset completedDate and requestStatus when value is 'Pending'
        newEmployeeInfo = {
          ...newEmployeeInfo,
          completedDate: null,
          requestStatus: '',
        };

        setPending((prevPending) => ({
          ...prevPending,
          fromPending: true,
        }));
      } else {
        // Set to 'In Progress' when value is neither 'Completed' nor 'Pending'
        newEmployeeInfo = {
          ...newEmployeeInfo,
          completedDate: null,
          requestStatus: '',
        };

        setPending((prevPending) => ({
          ...prevPending,
          fromPending: false,
        }));
      }

      return newEmployeeInfo;
    });
  };


  const handleSelectChanges = (value) => {
    radiohandleChange({ target: { name: 'radioInfo', value } });
  };
  const getAssignToUsers = (usersList) => {

    console.log(usersList);
    return usersList.map(item => item.firstname + " " + item.lastname);
  }

  const getClients = (clientList) => {

    console.log(clientList);
    return clientList.map(item => item.clientName);
  }


  const startDateHandler = (date) => {
    setStartDate(date ? date : null);
  };
  // const handleDueDateChange = (date) => {
  //   setDueDate('dueDate', date ? date : null);
  // };
  console.log('getDateTime1', values.date, values)

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === 'completedDate' && value) {
      const dateValue = moment(value, 'DD/MM/YYYY hh:mm A').toDate();
      setEmployeeInfo({
        ...employeeInfo,
        [name]: dateValue,
      });
    } else {
      setEmployeeInfo({
        ...employeeInfo,
        [name]: value,
      });
    }
  };

  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setEmployeeInfo({ ...employeeInfo, [name]: value });
  // };
  const handleSelectChange = (key, value) => {
    setEmployeeInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };
  const handleChange = (e) => {
    setEmployeeInfo({
      ...employeeInfo,
      [e.target.name]: e.target.value,
    });
  };
  const handleRequestInfoSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Submitted', employeeInfo);
    try {
      const response = await updateRequest({ variables: employeeInfo });
      console.log('response:', response);
      dispatch(requestInfoData(employeeInfo));
      console.log("updateSuccess", response.data.updateRequest)
      // Dispatch Redux action to update global state
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  // const departmentHandleChange = (event) => {
  //   setRequestDetailsDtaInfo(event.target.value);
  // };
  const handleRequestInputChange = ({ target: { name, value, index, field,checked,type } }) => {
    //const { name, value, checked, type } = e.target;

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: '',
    }));
    if (name === 'existingPolicyInformation') {
      setRequestDetailsDtaInfo((prevDetails) => {
        const updatedPolicies = [...prevDetails.existingPolicyInformation];
        updatedPolicies[index] = {
          ...updatedPolicies[index],
          [field]: value,
        };
        return { ...prevDetails, existingPolicyInformation: updatedPolicies };
      });
    } else if (name === 'newQuoteInformation'){
      setRequestDetailsDtaInfo((prevDetails) => {
        const updatedPolicies = [...prevDetails.newQuoteInformation];
        updatedPolicies[index] = {
          ...updatedPolicies[index],
          [field]: value,
        };
        return { ...prevDetails, newQuoteInformation: updatedPolicies };
      });
    } 
    else {
      if (type === 'checkbox') {
        setRequestDetailsDtaInfo({
          ...requestDetailsDtaInfo,
          [name]: checked,
        });
      } else if (type === 'number') {
        setRequestDetailsDtaInfo({
          ...requestDetailsDtaInfo,
          [name]: value === '' ? '' : parseInt(value, 10),
        });
      } else {
        setRequestDetailsDtaInfo({
          ...requestDetailsDtaInfo,
          [name]: value,
        });
      }
    }
  };
  
  const handleAddPolicy = () => {
    setRequestDetailsDtaInfo((prevDetails) => ({
      ...prevDetails,
      existingPolicyInformation: [
        ...prevDetails.existingPolicyInformation,
        { currentCarrier: '', propertyType: '', propertyNew: '', policyNumber: '', effectiveDate: '', currentPremium: '' }
      ]
    }));
  };

  const handleAddQuote = () => {
    setRequestDetailsDtaInfo((prevDetails) => ({
      ...prevDetails,
      newQuoteInformation: [
        ...prevDetails.newQuoteInformation,
        { policy: '', insuranceCarrier: '', eligibility: '', quotedPremium: '', remarks: '' }
      ]
    }));
  };

  const handleDeletePolicy = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this existingPolicyInformation?");
    if (confirmDelete) {
    setRequestDetailsDtaInfo((prevDetails) => ({
      ...prevDetails,
      existingPolicyInformation: prevDetails?.existingPolicyInformation.filter((_, i) => i !== index)
    }));
  }
  };

  const handleDeleteQuote = (index) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this newQuoteInformation?");
    if (confirmDelete) {
    setRequestDetailsDtaInfo((prevDetails) => ({
      ...prevDetails,
      newQuoteInformation: prevDetails?.newQuoteInformation.filter((_, i) => i !== index)
    }));
  }
  };




  const handleRequestSelectChange = (key, value) => {
    setRequestDetailsDtaInfo((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const validateForm = () => {
    const validationErrors = {};
    const { quoteType, insuredName, department, noofQuotes } = requestDetailsDtaInfo;

    if (!quoteType) validationErrors.quoteType = "Request Type is required";
    if (!insuredName) validationErrors.insuredName = "Insured Name is required";
    if (!department) validationErrors.department = "Department is required";
    if (!noofQuotes || isNaN(noofQuotes) || noofQuotes <= 0) validationErrors.noofQuotes = "Number of Quotes must be a valid number greater than 0";

    // You can add more validation rules here for checkboxes if needed

    return validationErrors;
  };

  const handleRequestDetailsSubmit = async (e) => {
    e.preventDefault();
    console.log('Form Submitted', requestDetailsDtaInfo);
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);  // Update errors state to show error messages
      return;  // Don't proceed with form submission if validation fails
    }

    console.log('Form Submitted', requestDetailsDtaInfo);
    try {
      const response = await updateRequest({ variables: requestDetailsDtaInfo });
      console.log('response:', response);
      dispatch(requestDetailsData(requestDetailsDtaInfo));
      console.log("updateSuccess", response.data.updateRequest)
    } catch (error) {
      console.error('Error updating request:', error);
    }
  };

  // const handleRequestDetailsSubmit = async (e) => {
  //   e.preventDefault();
  //   console.log('Form Submitted', requestDetailsDtaInfo);

  //   let newErrors = {};
  //   if (!requestDetailsDtaInfo.quoteType) newErrors.quoteType = 'Request type is required';
  //   if (!requestDetailsDtaInfo.insuredName) newErrors.insuredName = 'Insured name is required';
  //   if (!requestDetailsDtaInfo.noofQuotes) newErrors.noofQuotes = 'Number of quotes is required';
  //   // Add any other validations you need here

  //   setErrors(newErrors);

  //   const noErrors = Object.keys(newErrors).length === 0;
  //   if (noErrors && errorChange) {
  //     setIsUserDataValid(true);

  //     try {
  //       const response = await updateRequest({ variables: requestDetailsDtaInfo });
  //       console.log("responsed",response)
  //       setErrorChange(false); // Reset change tracker
  //       setIsUserDataValid(false);
  //       dispatch(requestDetailsData(requestDetailsDtaInfo));
  //       console.log('Update successful:', response.data.updateRequest);

  //       // Display success message
  //       message.info({
  //         content: response?.data?.updateRequest,
  //         duration: 5,
  //         className: 'alert alert-success text-center',
  //       });

  //       // Programmatically close the modal
  //       const modalElement = document.querySelector('[data-bs-dismiss="modal"]');
  //       if (modalElement) {
  //         modalElement.click();  // Simulate a click to close the modal
  //       }
  //     } catch (error) {
  //       console.error('Error updating lead:', error);
  //       // You might want to display an error message to the user here
  //     }
  //   } else {
  //     // Handle the case where there are validation errors (don't submit)
  //     message.error('Please fix the validation errors.');
  //   }
  // };


  const priority = [
    { value: "On Demand", label: "On Demand" },
    { value: "Rush", label: "Rush" },
    { value: "Bulk", label: "Bulk" }
  ];
  const requestStatus = [
    { value: "Open", label: "Open" },
    { value: "In Progress", label: "In Progress" },
    { value: "On Hold", label: "On Hold" },
    { value: "Completed", label: "Completed" }
  ]

  return (
    <>
      {employeeInfo && (
        <>
          <div id="request_profile" className="modal custom-modal fade" role="dialog" aria-labelledby="requestProfileModalLabel" aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Request Information</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  //onClick={resetRequestInfo}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleRequestInfoSubmit}>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Request Received Date</label>
                          <div>
                            {getDateTime(parseInt(employeeInfo?.date))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Due Date</label>
                          <div>
                            {/* {GetDueDateTemp(employeeInfo, priorityChange.priority)} */}
                            {GetDueDateTemp(employeeInfo)}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Priority</label>
                          <select
                            className="form-control form-select floating"
                            name='priority'
                            value={employeeInfo?.priority}
                            onChange={priorityChangeHandler}
                            //onChange={(e) => handleSelectChange('priority', e.target.value)}
                          >
                            {priority.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Process Type</label>
                          <select
                            className="form-control form-select floating"
                            name="processType"
                            value={employeeInfo?.processType}
                            onChange={(e) => handleSelectChange('processType', e.target.value)}
                          >
                            <option value=''></option>
                            {DataBucket.PROCESS_TYPE_CONFIG.map((Item, key) => {
                              return (<option key={key} value={Item}>{Item}</option>)
                            })}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Assigned to</label>
                          <input
                            type="text"
                            name='assignedTo'
                            className="form-control"
                            value={employeeInfo?.assignedTo || ''}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Assign To</label>
                          <select
                            className="form-control form-select floating"
                            options={getAssignToUsers(assignToUsers)}
                            value={employeeInfo?.assignTo}
                            onChange={(e) => handleSelectChange('assignTo', e.target.value)}
                          >
                            <option value=''></option>
                            {assignToUsers.map((item, key) => {
                              return <option key={key}>{item.firstname}</option>
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Start Date Override</label>
                          <DatePicker
                            className="form-control"
                            format="DD/MM/YYYY hh:mm A"
                            showTime
                            name="startDate"
                            value={startDate}
                            onChange={startDateHandler}
                          //disabledDate={(current) => current && current < dayjs(parseInt(employeeInfo?.date))}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Due Date Override</label>
                          <DatePicker
                            className="form-control"
                            format="DD/MM/YYYY hh:mm A"
                            showTime
                            name="dueDate"
                            //value={dayjs(parseInt(GetDueDateTemp(employeeInfo)) || parseInt(timestamp))}
                            //value={dayjs(GetDueDateTemp(employeeInfo))}
                            value={employeeInfo?.dueDate ? dayjs(GetDueDateTempOverried(employeeInfo)) : null} 
                            onChange={(date, dateString) => {
                              handleInputChange({ target: { name: 'dueDate', value: dateString } });
                            }}
                            //onChange={handleDueDateChange}
                            minDate={DateCondition()}
                          //disabledDate={(current) => current && current < dayjs(parseInt(employeeInfo?.date))}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Completed Date</label>
                          <DatePicker
                            className="form-control"
                            format="DD/MM/YYYY hh:mm A"
                            id="completedDate"
                            name="completedDate"
                            value={employeeInfo?.completedDate ? moment(employeeInfo?.completedDate, 'DD/MM/YYYY hh:mm A') : null}
                            onChange={(date, dateString) => {
                              handleInputChange({ target: { name: 'completedDate', value: dateString } });
                            }}
                            showTime={{ format: 'hh:mm A' }}
                          />

                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Request Status</label>
                          <select
                            className="form-control form-select floating"
                            name='requestStatus'
                            value={employeeInfo?.requestStatus || 'Open'}
                            onChange={e => handleSelectChange('requestStatus', e.target.value)}
                          //onChange={requestStatusHandler}
                          >
                            {requestStatus.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block">
                          <div className="form-check mb-5">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              id="assignCheckbox"
                              checked={employeeInfo?.Assign || false}
                              onChange={assignHandler}
                            />
                            <label className="col-form-label" htmlFor="assignCheckbox">
                              Assign
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Client Name</label>
                          <select
                            className="form-control form-select floating"
                            options={getClients(clients)}
                            value={employeeInfo?.clientName}
                            onChange={(e) => handleSelectChange('clientName', e.target.value)}
                          >
                            <option value=''></option>
                            {clients.map((item, key) => {
                              return <option key={key}>{item.clientName}</option>
                            })}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="d-flex flex-row p-2">
                          <Select
                            size="large"
                            value={employeeInfo?.radioInfo}
                            dropdownStyle={{ backgroundColor: "#aa5a6e", color: "#fff" }}
                            style={{ width: '300px' }}
                            onChange={handleSelectChanges}
                          >
                            <Select.Option value="Inbox">Inbox</Select.Option>
                            <Select.Option value="Email Needed">Email Needed</Select.Option>
                            <Select.Option value="Pending">Pending</Select.Option>
                            <Select.Option value="Discard">Discard</Select.Option>
                            <Select.Option value="Completed">Completed</Select.Option>
                          </Select>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <Form.Item style={{ marginTop: '15px' }} label={<Typography.Title level={5} disabled>From Pending</Typography.Title>}>
                          <Switch
                            checked={pending.fromPending}
                            onChange={(checked) => setPending({ ...pending, fromPending: checked })}
                            disabled
                          // checked={pending.fromPending}
                          // onChange={(checked) => handleChange({ target: { name: 'fromPending', value: checked } })}
                          // disabled
                          />
                        </Form.Item>
                      </div>

                      <div className="col-md-3">
                        <Form.Item style={{ marginTop: '15px' }} label={<Typography.Title level={5}>Instructions</Typography.Title>}>
                          <Switch
                            //checked={pending.instructions}
                            onChange={(checked) => handleChange({ target: { name: 'instructions', value: checked } })}
                          />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div id="request_details" className="modal custom-modal fade" role="dialog" aria-labelledby="requestProfileModalLabel" aria-hidden="true">
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title">Request Details Information</h5>
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={resetRequestDetails}
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form onSubmit={handleRequestDetailsSubmit}>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Request Type</label>
                          <input
                            type="text"
                            name="quoteType"
                            className={`form-control ${errors?.quoteType ? 'is-invalid' : ''}`}
                            value={requestDetailsDtaInfo?.quoteType}
                            onChange={handleRequestInputChange}
                          />
                          {errors?.quoteType && (
                            <div className="invalid-feedback">
                              {errors.quoteType}
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Insured</label>
                          <input
                            type="text"
                            name="insuredName"
                            className="form-control"
                            value={requestDetailsDtaInfo?.insuredName}
                            onChange={handleRequestInputChange}
                          />
                          {errors?.insuredName && (
                            <div className="invalid-feedback">
                              {errors.insuredName}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">Department</label>
                          <select
                            // value={department}
                            name='department'
                            value={requestDetailsDtaInfo?.department}
                            onChange={handleRequestInputChange}
                            displayEmpty
                            className="form-select"
                            aria-label="Select Department"
                          //inputProps={{ 'aria-label': 'Without label' }}
                          >
                            <option value="" disabled>Select a department</option>
                            {DataBucket.DEPARTMENT_CONFIG.map((DepartmentItem, key) => {
                              return (<option key={key} value={DepartmentItem}>{DepartmentItem}</option>)
                            })}

                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="input-block mb-3">
                          <label className="col-form-label">{requestDetailsDtaInfo?.quoteType}</label>
                          <input
                            type="number"
                            name="noofQuotes"
                            className="form-control"
                            value={requestDetailsDtaInfo?.noofQuotes}
                            onChange={handleRequestInputChange}
                          />
                          {errors?.noofQuotes && (
                            <div className="invalid-feedback">
                              {errors.noofQuotes}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {requestDetailsDtaInfo?.department === 'Personal Line' && (
                      <>
                        <Row gutter={[8, 8]} style={{ paddingTop: '16px' }} className="mb-4">
                          <Col span={8}>
                            <Checkbox
                              name="Auto"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo?.Auto}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Auto</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="MotorCycle"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo?.MotorCycle}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Motor Cycle</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="Boat"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.Boat}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>Boat</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="DP3"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.DP3}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(DP3) Dwelling Fire</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="HO3"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.HO3}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO3) Home Owners</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="HO4"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.HO4}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO4) Renters</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="HO5"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.HO5}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO5) Home Owners with higher Dwelling Limit</Typography.Text>
                            </Checkbox>
                          </Col>
                          <Col span={8}>
                            <Checkbox
                              name="HO6"
                              onChange={handleRequestInputChange}
                              checked={requestDetailsDtaInfo.HO6}
                            >
                              <Typography.Text style={{ fontSize: '15px', color: '#000' }}>(HO6) Condo</Typography.Text>
                            </Checkbox>
                          </Col>
                        </Row>
                        <div className="row mb-4">
                          <ExistingPolicyInformation
                            employeeInfo={requestDetailsDtaInfo.existingPolicyInformation}
                            handleChange={handleRequestInputChange}
                            handleAddPolicy={handleAddPolicy}
                            handleDeletePolicy={handleDeletePolicy} />
                        </div>
                        <div className="row">
                          <NewQuoteInformation employeeInfo={requestDetailsDtaInfo?.newQuoteInformation} 
                          handleChange={handleRequestInputChange} 
                          handleAddQuote={handleAddQuote}
                          handleDeleteQuote={handleDeleteQuote} />
                        </div>
                      </>
                    )}
                    <div className="submit-section">
                      <button
                        className="btn btn-primary submit-btn"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                        type="submit"
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

    </>
  );
};

export default RequestProfileModelPopup;
