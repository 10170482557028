import React, { useState, useEffect, useRef } from "react";
import { Link,useLocation } from "react-router-dom";
import {
  Table,
  Input,
  DatePicker,
  message,
  Button,
  Tooltip,
  Row,
  Col,
  Select,
} from "antd";

import moment from "moment";
import dayjs from "dayjs";
import EmployeeReportFilter from "../../../components/EmployeeReportFilter";
import EmployeeReportModal from "../../../components/modelpopup/EmployeeReportModal";
import { useSelector } from "react-redux";
import { selectAdminReport,addOverrideHistory } from "../../../Slices/employeereportslice";
import {
  fetchAdminReport,
  fetchEmailsByRequestIds,
  updateFieldInSpecificAdminReport,
} from "../../../Slices/employeereportslice";
import { useDispatch } from "react-redux";
import { GET_ALL_USERS } from "../../../Queries/Users";
import { useQuery } from "@apollo/client";
import {
  updateAdminReport,
  updateMultipleWorkHistories,

} from "../../../Slices/employeereportslice";
import { date } from "yup";
import { use } from "react";
import Swal from "sweetalert2";
import { setLoader } from "../../../Slices/loaderSlice";

const ClientReport = () => {
    const employees = useSelector((state) => state.loginUserInfo.allUsers);
    const location = useLocation();
      const currentPath = location.pathname;
  let clients = useSelector(selectAdminReport);
  console.log("clients",clients)
  const [DataForClear, SetdataForClear] = useState();
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [editingKey, setEditingKey] = useState("");
  const [isEditingBulk, setIsEditingBulk] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [modalData, setModalData] = useState({});
  const [startingTime, setStartingTime] = useState();
  const [endingTime, setEndingTime] = useState();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const { data } = useQuery(GET_ALL_USERS);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };
  const calculateDuration = (start, end) => {
    if (start && end) {
      console.log("start,end",start,end)
      const startDate = new Date(Number(start));
      const endDate = new Date(Number(end));
      const durationMs = endDate - startDate;
  
      // Calculate hours, minutes, and seconds
      const hours = Math.floor(durationMs / (1000 * 60 * 60)); // Convert ms to hours
      const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
      const seconds = Math.floor((durationMs % (1000 * 60)) / 1000); // Remaining seconds
      
      const time =  `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
      console.log("start,end",time)
      return time
    }
    return null;
  };
  const calculateTotalDuration = () => {
    let totalSeconds = clients?.reduce((acc, client) => {
      const duration = client.duration;
  
      const match = duration?.match(/^(\d{2}):(\d{2}):(\d{2})$/); // This matches HH:MM:SS format

    if (match) {
      const hours = parseInt(match[1]); // Get hours from match[1]
      const minutes = parseInt(match[2]); // Get minutes from match[2]
      const seconds = parseInt(match[3]); // Get seconds from match[3]

      // Convert to total seconds and accumulate
      return acc + (hours * 3600) + (minutes * 60) + seconds;
    }

    return acc; // If no match, return accumulator as is
  }, 0);

  // Convert total seconds into hours, minutes, and seconds
  const totalHours = Math.floor(totalSeconds / 3600); // Total hours
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes
  const remainingSeconds = totalSeconds % 60; // Remaining seconds

  // Return total duration in HH:MM:SS format
  return `${totalHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`; 
};
  const handleView = (record) => {
  
    console.log("record", record);

    setIsEdit(false);
    setModalData(record);
    setShowModal(true);
  };
  const specificDate = moment(startingTime);

  const disabledDate = (current) => {
    return current.isBefore(
      moment(specificDate)
        .startOf("day")
        .add(moment(specificDate).hours(), "hours")
        .add(moment(specificDate).minutes(), "minutes"),
      "second"
    );
  };

  const handleEdit = (record) => {
    setIsEdit(true);
    setModalData(record);
    setShowModal(true);
  };
  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
      try {
          return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
          return null;
      }
  };
  const userid = parseJwt(token)?.id.toString();
  const employee = employees.find((emp) => emp.id === userid);

  const employeename = employee
  ? `${employee.firstname} ${employee.lastname}`
  : 'Employee not found';

  const toggleBulkEdit = () => {
    if (isEditingBulk) {
      const updatedRecords = clients
        .map((client) => {
          if (client.isUpdated) {
            console.log("client is updated", client);

            return {
              id: client._id,
              startTime: startingTime?.toString(),
              endTime: endingTime?.toString(),
              message: client.message,
              taskType: client.taskType,
              requestId: client.requestIds,
              updatedBy:employeename,
            };
          }
          return null;
        })
        .filter((record) => record !== null);

      if (updatedRecords.length > 0) {
        console.log("updatedRecords", updatedRecords);

        // Show SweetAlert confirmation before submitting
        Swal.fire({
          title: "Are you sure?",
          text: "You are about to submit these changes.",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, submit it!",
          cancelButtonText: "No, cancel",
        }).then((result) => {
          if (result.isConfirmed) {
            // Proceed with dispatch if user confirms
            dispatch(updateMultipleWorkHistories({ updates: updatedRecords }))
              .then((res) => {
                console.log("res",res)
                if(res.meta.requestStatus ==="fulfilled"){
                  dispatch(addOverrideHistory(res.payload))
                }
                message.success("Bulk changes have been submitted!");
              })
              .catch((error) => {
                message.error(`Error submitting changes: ${error.message}`);
              });
          } else {
            // Optionally, log a message if the user cancels
            console.log("Submission canceled by user.");
          }
        });
      } else {
        message.info("No changes to submit.");
      }
    }

    // Toggle the editing state after the process
    setIsEditingBulk(!isEditingBulk);
  };

  const filters = {
    startDateTime: new Date(),
    endDateTime: new Date(),
    reporttype:currentPath
  };

  useEffect(() => {
    dispatch(setLoader(true))
    dispatch(fetchAdminReport(filters)).then((results) => {
 
      SetdataForClear(results.payload);
      dispatch(setLoader(false))
    });
  }, [dispatch, data,currentPath]);

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination);
  };

  const handleClear = () => {
    setIsEditingBulk(false);
    dispatch(updateAdminReport(DataForClear));
    message.info("Changes have been cleared");
  };

  const handleInlineEdit = (key, field, value) => {
    console.log("key, field, value", key, field, value);
    const updatedClients = clients.map((client) =>
      client._id === key
        ? { ...client, [field]: value, isUpdated: true,duration:calculateDuration(client.startTime,client.endTime) }
        : client
    );
    dispatch(updateAdminReport(updatedClients));
  };
  const handleDateChange = (key, field, value) => {
    console.log("handleDateChange - value:", value); // Check the value passed
    const formattedDate = value ? dayjs(value).valueOf() : "";
    if (field == "startTime") {
      setStartingTime(formattedDate);
    }
    if (field == "endTime") {
      setEndingTime(formattedDate);
    }
    const timestamp = value ? dayjs(value).valueOf() : null;
    const duration = startingTime && formattedDate ? calculateDuration(startingTime, formattedDate) : null;
    console.log("duration123",duration)
    const updatedClients = clients.map((client) =>
      client._id === key
        ? { ...client, [field]: timestamp, isUpdated: true }
        : client
    );
    console.log("updatedClients", updatedClients);
    dispatch(updateAdminReport(updatedClients));
  };

  const renderWithTooltip = (text, maxLength) => {
    if (!text) return null;

    return (
      <Tooltip title={text}>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </Tooltip>
    );
  };

  const handleSave = (field, value) => {
    const updatedClients = clients.map((client) =>
      client.key === modalData.key
        ? { ...client, [field]: value, isUpdated: true }
        : client
    );

    dispatch(
      updateMultipleWorkHistories({
        id: modalData.key,
        startTime: modalData.startTime,
        endTime: modalData.endingTime,
        updatedBy: modalData.message,
        taskType: modalData.taskType,
        requestId: modalData.requestIds,
      })
    )
      .then(() => {
        message.success("Record updated successfully");
        setShowModal(false);
      })
      .catch((error) => {
        message.error(`Error updating record: ${error.message}`);
      });
  };

  const handleClose = () => setShowModal(false);
  const columns = [
    {
      title: "",
      dataIndex: "overrideHistory",
      render: (text, record) => {
        return record?.overrideHistory?.length !== 0 ? (
          <span
            style={{
              width: "8px",
              height: "8px",
              borderRadius: "50%",
              backgroundColor: "#0255CD", 
              display: "inline-block",
              marginRight: "8px",
            }}
          ></span>
        ) : null;
      },
      width: 40,
    },
    {
      title: "Employee Name",
      dataIndex: "employeeName",
    },
    {
      title: "Email",
      dataIndex: "emailId",
    },
    {
      title: filters.reporttype === "/ClientReport" ? "Client Name" : filters.reporttype === "/SalesReport" ? "Agency Name" : null,
      dataIndex: filters.reporttype === "/ClientReport" ? "clientName" : filters.reporttype === "/SalesReport" ? "agencyName" : null,
      render: (text, record) => {
        if (filters.reporttype === "/ClientReport") {
          return text;
        } else if (filters.reporttype === "/SalesReport") {
          return text;
        }
        return null;
      },
    },
    {
      title: filters.reporttype === "/ClientReport" ? "Service" : null,
      dataIndex: filters.reporttype === "/ClientReport" ? "service" : null,
      render: (text, record) => {
        return filters.reporttype === "/ClientReport" ? text : null;
      },
    },
    {
      title: "Reporting To",
      dataIndex: "reportingTo",
      render: (text, record) => {
        return text || "N/A"; // Display "N/A" if reportingTo is not available
      },
    },
    {
      title: "Task",
      dataIndex: "taskType",
      render: (text, record) =>
        isEditingBulk ? (
          <div style={{ width: "150px" }}>
            <select
              value={record.taskType}
              onChange={(e) => handleInlineEdit(record._id, "taskType", e.target.value)}
              style={{
                border: "none",
                outline: "none",
                padding: "5px",
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              <option value="">Select</option>
              <option value="Available">Available</option>
              <option value="Break">Break</option>
              <option value="Meeting">Meeting</option>
              <option value="Admin">Admin</option>
              <option value="Management">Management</option>
              <option value="Training">Training</option>
              <option value="Cross-Training">Cross-Training</option>
              <option value="No-work Assigned">No-work Assigned</option>
              <option value="Cold Calling">Cold Calling</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Email">Email</option>
              <option value="Data Processing">Data Processing</option>
            </select>
          </div>
        ) : (
          text
        ),
    },
    {
      title: filters.reporttype === "/SalesReport" ? "Type of production" : null,
      dataIndex: filters.reporttype === "/SalesReport" ? "taskType" : null,
      render: (text, record) => {
        if (filters.reporttype === "/SalesReport") {
          const productiveTasks = ["Cold Calling", "LinkedIn", "Email", "Data Processing"];
          const nonProductiveTasks = ["Available", "Break", "Meeting", "Management"];
          
          if (productiveTasks.includes(record.taskType)) {
            return "Productive";
          } else if (nonProductiveTasks.includes(record.taskType)) {
            return "Non-Productive";
          }
          return "Unknown";
        }
        return null;
      },
    },
    {
      title: filters.reporttype === "/ClientReport" ? "Billable" : null,
      dataIndex: filters.reporttype === "/ClientReport" ? "billable" : null,
      render: (text, record) => {
        return filters.reporttype === "/ClientReport" ? text : null;
      },
    },
    {
      title: filters.reporttype === "/ClientReport" ? "Request#" : null,
      dataIndex: filters.reporttype === "/ClientReport" ? "requestIds" : null,
      render: (text, record) => {
        return filters.reporttype === "/ClientReport" ? text : null;
      },
    },
    {
      title: filters.reporttype === "/ClientReport" ? "Request Completed" : null,
      dataIndex: filters.reporttype === "/ClientReport" ? "requestCompleted" : null,
      sorter: (a, b) => a.requestCompleted.length - b.requestCompleted.length,
      render: (text, record) => {
        return filters.reporttype === "/ClientReport" ? text : null;
      },
    },
    {
      title: filters.reporttype === "/SalesReport" ? "Lead ID" : null,
      dataIndex: filters.reporttype === "/SalesReport" ? "requestId" : null,
      render: (text, record) => {
        return filters.reporttype === "/SalesReport" ? text : null;
      },
    },
    {
      title: "Start Date & Time",
      dataIndex: "startTime",
      render: (text, record) => {
        return isEditingBulk ? (
          <div style={{ width: "150px" }}>
            <DatePicker
              disabled={!text}
              showTime
              value={dayjs(parseInt(text)).isValid() ? dayjs(parseInt(text)) : dayjs()}
              format="DD/MM/YYYY hh:mm A"
              onChange={(date) => {
                if (date) {
                  handleDateChange(record._id, "startTime", date);
                }
              }}
            />
          </div>
        ) : (
          dayjs(parseInt(text)).format("DD/MM/YYYY hh:mm A")
        );
      },
    },
    {
      title: "End Date & Time",
      dataIndex: "endTime",
      render: (text, record) => {
        return isEditingBulk ? (
          <div style={{ width: "150px" }}>
            <DatePicker
              disabled={!text}
              showTime
              value={text ? dayjs(parseInt(text)) : null}
              format="DD/MM/YYYY hh:mm A"
              onChange={(date) => {
                if (date) {
                  handleDateChange(record._id, "endTime", date);
                }
              }}
            />
          </div>
        ) : text ? (
          dayjs(parseInt(text)).format("DD/MM/YYYY hh:mm A")
        ) : (
          " "
        );
      },
    },
    {
      title: "Duration",
      dataIndex: "duration",
      sorter: (a, b) => a.requestId.length - b.requestId.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "View",
      render: (text, record) => (
        <Link to="#" onClick={() => handleView(record)}>
          <i className="fa fa-eye m-r-5" />
        </Link>
      ),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Link to="#" onClick={() => handleEdit(record)}>
          <i className="fa fa-pencil m-r-5" />
        </Link>
      ),
    },
  ];
  
  
  
  
  
  
  const PageChange = (page) => {
    setCurrentPage(page);
  };

  function formatDateTime(timestamp) {
    const date = new Date(parseInt(timestamp));
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-IN", options);
  }

  let rowsWithSerialNumber = [];

  if (clients && clients.length > 0) {
    rowsWithSerialNumber = clients.map((row, index) => {
      return {
        // sno: (page - 1) * pageSize + index + 1,
        EmployeeName: row.employeeName ? row.employeeName : "  ",
        EmailId: row.emailId ? row.emailId : "  ",
        ReportingTo: row.reportingTo ? row.reportingTo : "  ",
        ClientName: row.clientName ? row.clientName : "  ",
        Service: row.service ? row.service : "  ",
        TaskType: row.taskType ? row.taskType : "  ",
        Billable: row.RequestIds ?  "true" : "No",
        RequestIds: row.requestIds ? row.requestIds : "  ",
        RequestCompleted: row.requestCompleted ? row.requestCompleted : "  ",
        StartTime: row.startTime ? formatDateTime(row.startTime) : "  ",
        EndTime: row.endTime ? formatDateTime(row.endTime) : "  ",
        Duration: row.duration ? row.duration : "  ",
        Status: row.status ? row.status : "  ",
        ...row,
      };
    });
  }

  const simulatePrintDialog = (dataToExport, selectedColumns) => {
    // try{
    const printableContent = document.createElement("div");
    printableContent.style.width = "100%";
    printableContent.style.margin = "0 auto";
    printableContent.style.padding = "5px";
    printableContent.style.fontFamily = "Arial, sans-serif";
    printableContent.style.fontSize = "5px";

    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.border = "1px solid #000";
    table.style.borderCollapse = "collapse";

    const thead = document.createElement("thead");
    const headerRow = document.createElement("tr");

    selectedColumns.forEach((col) => {
      const th = document.createElement("th");
      th.style.padding = "2px";
      th.style.textAlign = "left";
      th.style.border = "1px solid #ddd";
      th.innerText = col.toUpperCase();
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);

    const tbody = document.createElement("tbody");
    dataToExport.forEach((row) => {
      const rowElement = document.createElement("tr");
      selectedColumns.forEach((col) => {
        const td = document.createElement("td");
        td.style.padding = "2px";
        td.style.textAlign = "left";
        td.style.border = "1px solid #ddd";
        td.innerText = row[col];
        rowElement.appendChild(td);
      });
      tbody.appendChild(rowElement);
    });

    table.appendChild(tbody);
    printableContent.appendChild(table);

    const printStyle = document.createElement("style");
    printStyle.innerHTML = `
      body * {
        visibility: hidden;
      }
      #print-content, #print-content * {
        visibility: visible;
      }
      #print-content {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
      }
      table {
        margin-top: 5px;
        width: 100%;
        border: 1px solid black;
        border-collapse: collapse;
      }
      th, td {
        padding: 2px;
        text-align: left;
        border: 1px solid #ddd;
      }
    `;
    document.head.appendChild(printStyle);

    printableContent.id = "print-content";
    document.body.appendChild(printableContent);

    window.print();

    document.body.removeChild(printableContent);
    document.head.removeChild(printStyle);
  };

  const handleExport = (fileFormat) => {
    const selectedColumns = [
      "EmployeeName",
      "EmailId",
      "ReportingTo",
      "ClientName",
      "Service",
      "TaskType",
      "billable",
      "StartTime",
      "EndTime",
      "RequestIds",
      "RequestCompleted",
      "Status",
      "Duration",
    ];

    setDropdownOpen(false);
    let dataToExport;

    dataToExport = rowsWithSerialNumber;
    console.log("rowsWithSerialNumber",rowsWithSerialNumber)
    if (dataToExport.length > 0) {
      if (dataToExport.length > 0) {
        if (fileFormat === "CSV") {
          const csvData = convertToCSV(dataToExport, selectedColumns);
          downloadCSV(csvData, `Report.csv`);
        } else if (fileFormat === "PDF") {
          simulatePrintDialog(dataToExport, selectedColumns);
        }
      } else {
        message.info({
          content: "No data available for export.",
          duration: 5,
          className: "custom-toast-center",
        });
      }
    }
  };

  const convertToCSV = (responseArray, selectedColumns) => {
    // try{
    if (!responseArray || responseArray.length === 0) return "";

    const headers = selectedColumns.join(",") + "\n";

    const rows = responseArray
      .map((row) => {
        return selectedColumns
          .map((column) => {
            let value = row[column];

            if (
              typeof value === "number" ||
              (typeof value === "string" && value.length > 10)
            ) {
              value = value.toString().padEnd(15, " ");
            }

            const escapedValue = ("" + value).replace(/"/g, '""');
            return `"${escapedValue}"`;
          })
          .join(",");
      })
      .join("\n");

    return headers + rows;
  };

  const downloadCSV = (csv, filename) => {
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <EmployeeReportFilter
            toggleBulkEdit={toggleBulkEdit}
            isEditingBulk={isEditingBulk}
            PageChange={PageChange}
          />
          <div className="row mt-20px">
            <div className="col-md-12">
              <div className="table-responsive">
                <Row justify="end" style={{ marginBottom: 16 }}>
                  <Col>
                    <Button
                      onClick={toggleBulkEdit}
                      type="primary"
                      style={{ marginRight: 8 }}
                    >
                      {isEditingBulk ? "Submit" : "Edit "}
                    </Button>
                  </Col>
                  {isEditingBulk && (
                    <Col>
                      <Button 
                        style={{ marginRight: 8 }} onClick={handleClear}>
                        Clear
                      </Button>
                    </Col>
                  )}
                  <Col>
                    <Button onClick={toggleDropdown} type="primary">
                      Export
                    </Button>
                    {dropdownOpen && (
                      <ul
                        ref={dropdownRef}
                        className="dropdown-menu show"
                        style={{ display: "block", position: "absolute" }}
                      >
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleExport("CSV");
                            }}
                          >
                            CSV
                          </a>
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            href="#"
                            onClick={(e) => {
                              e.preventDefault();
                              handleExport("PDF");
                            }}
                          >
                            PDF
                          </a>
                        </li>
                      </ul>
                    )}

                    <style jsx>{`
                      .dropdown-menu {
                        list-style-type: none;
                        padding: 0;
                        margin: 0;
                        position: absolute;
                        background-color: #fff;
                        border: 1px solid #ccc;
                        width: auto; /* Adjusted width to be auto */
                        min-width: 100px; /* Ensure it has a minimum width */
                        z-index: 1;
                        box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
                        right: 0; /* Align to the right to stay within container */
                        top: 35px; /* Adjust this value to move the dropdown further down */
                      }

                      .dropdown-menu li {
                        padding: 8px;
                        cursor: pointer;
                      }

                      .dropdown-menu li:hover {
                        background-color: #f0f0f0;
                      }

                      /* Ensure the dropdown stays within the view */
                      .dropdown-menu.show {
                        max-height: 200px;
                        overflow-y: auto;
                      }
                    `}</style>
                  </Col>
                </Row>
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns.map(col => ({
                    ...col,
                    align: 'center'
                  }))}
                  dataSource={clients}
                  rowKey={(record) => record.key}
                  size="small"
                  pagination={{
                    current: currentPage,
                    pageSize: 10,
                    position: ["bottomRight"],
                    onChange: handleTableChange,
                    showQuickJumper: true,
                    showSizeChanger: false,
                  }}
                  scroll={{ x: 1000 }}
                  footer={() => (
                    <div>
                      <strong>Total Duration: </strong>{" "}
                      {calculateTotalDuration()}
                    </div>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeReportModal
        show={showModal}
        handleClose={handleClose}
        data={modalData}
        isEdit={isEdit}
        handleSave={handleSave}
      />
    </>
  );
};

export default ClientReport;
