import React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";

const CreateLinkedin = ({
  leadsLinkedins,
  handleDeleteLinkedin,
  handleLinkedinsInputChange,
  handleAddNewLinkedin,
  handleChange,
  formData,
  userNames,
  dataBucket,
}) => {
  return (
    <>
      {leadsLinkedins?.map((linkedin, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">
              Linkedin Request {index + 1}
              <Link
                to="#"
                className="delete-icon float-end"
                onClick={() => handleDeleteLinkedin(index)}
              >
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h3>

            <div className="row">
              {/* Request Date */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Date</label>
                <DatePicker
                  value={linkedin.date ? dayjs(linkedin.date, "DD/MM/YYYY") : null}
                  onChange={(date) =>
                    handleLinkedinsInputChange(index, {
                      target: { name: "date", value: date ? dayjs(date).format("DD/MM/YYYY") : "" },
                    })
                  }
                  className="form-control"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                />
              </div>

              {/* Request Time */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Time</label>
                <TimePicker
                  id={`time-picker-${index}`}
                  value={linkedin.time ? dayjs(linkedin.time, "HH:mm:ss") : null}
                  onChange={(time) => {
                    if (time) {
                      const formattedTime = time.format("HH:mm:ss");
                      handleLinkedinsInputChange(index, {
                        target: { name: "time", value: formattedTime },
                      });
                    }
                  }}
                  className="form-control"
                  placeholder="Select time"
                />
              </div>

              {/* Request Status */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={linkedin.status || ""}
                  onChange={(e) => handleLinkedinsInputChange(index, e)}
                >
                  {dataBucket.SALES_LINKEDIN_STATUS?.map((statusItem, key) => (
                    <option key={key} value={statusItem}>
                      {statusItem}
                    </option>
                  ))}
                </select>
              </div>

              {/* Requested By */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Requested By</label>
                <select
                  name="requestBy"
                  className="form-control"
                  value={linkedin.requestBy || ""}
                  onChange={(e) => handleLinkedinsInputChange(index, e)}
                >
                  {userNames?.map((name, idx) => (
                    <option key={idx} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Requested To */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Request To</label>
                <input
                  type="text"
                  name="requestTo"
                  className="form-control"
                  value={linkedin.requestTo || ""}
                  onChange={(e) => handleLinkedinsInputChange(index, e)}
                />
              </div>

              {/* Remarks */}
              <div className="col-12 mb-3">
                <label className="col-form-label">Remarks</label>
                <textarea
                  name="remarks"
                  className="form-control"
                  value={linkedin.remarks || ""}
                  onChange={(e) => handleLinkedinsInputChange(index, e)}
                />
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="mb-3 mt-2">
        <Link to="#" onClick={handleAddNewLinkedin}>
          <i className="fa-solid fa-plus-circle" /> Add more
        </Link>
      </div>

      <div className="mb-3">
        <label className="col-form-label">LinkedIn Info Comments</label>
        <textarea
            name="linkedinComments"
            className="form-control"
            value={formData.linkedinComments || ''}
            onChange={(e) => handleChange("linkedinComments", e.target.value)}
        ></textarea>
        </div>

    </>
  );
};

export default CreateLinkedin;
