import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setLastContactedDates } from '../../../Slices/lastContactedDatesSlice';
import { useNavigate } from 'react-router-dom';
//import { jwtDecode } from 'jwt-decode';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import SaleInformation1 from './SaleInformation1';
import SaleInformation2 from './SaleInformation2';

import CallingTrack from './CallingTrack';
import EmailTrack from './EmailTrack';
import LinkedinTrack from './LinkedinTrack';
import SchedulingCall from './SchedulingCall';
import OperationsTeam from './OperationsTeam';
// import AttachmentsHistory from './AttachmentsHistory';
import RequestWorkHistory from './RequestWorkHistory';
import { setRefreshStatus, setSale, setDivVisibility, updateSales, setIsRefetch } from '../../../Slices/salesSlice';
import { setLoader } from '../../../Slices/loaderSlice';
import {
    UPDATE_REQUEST,
    GET_ALL_ORDERS,
    GET_REQUESTS_BY_ID,
    INSERT_ORDERS,
    UPDATE_WORKHISTORY,
    GET_WORKHISTORY,
    GET_WORKHISTORY_BY_ID,
    GET_ALLWORKHISTORY_BY_ID,
    CREATE_STAUS_WORKHISTORY,
} from '../../../Queries/Orders';
import { CREATE_LEAD } from '../../../Queries/Leads';
import * as DataBucket from '../../../databucket';

import { GET_ALL_USERS, GET_USER_DETAILS } from '../../../Queries/Users';
import { UPDATE_LEAD } from '../../../Queries/Leads';
import { message } from 'antd';
import { useErrorLogger } from "../../../utils";
import dayjs from 'dayjs';

import { Modal, Button, Tabs, Form, Row, Col, Input } from 'antd';
const { TabPane } = Tabs;


const validationSchema = Yup.object({
    agencyName: Yup.string()
        .required('Agency Name is required'),
    // city: Yup.string()
    //   .test('is-string', 'City must be a valid string', (value) =>
    //     value ? typeof value === 'string' && isNaN(Number(value)) : true
    //   )
    //   .nullable(),
    // state: Yup.string()
    //   .test('is-string', 'State must be a valid string', (value) =>
    //     value ? typeof value === 'string' && isNaN(Number(value)) : true
    //   )
    //   .nullable(),

    // Leads Contacts Array Validation
    leadsContacts: Yup.array().of(
        Yup.object({
            // contactPerson: Yup.string()
            //     .matches(/^[A-Za-z\s/]+$/, 'Contact Person must contain only letters and spaces'),

            //     title: Yup.string()
            //     .matches(/^[A-Za-z\s/]+$/, 'Title must contain only letters and spaces'),

            email: Yup.string()
                .trim()
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
                .email('Invalid email format'),

            // phone: Yup.string()
            //     .matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0'),
            remarks: Yup.string(),  // No validation for remarks
        })
    ),

    // leadsCalls: Yup.array().of(
    //     Yup.object().shape({
    //         status: Yup.string().required('Call Status is required'), // Ensure status is included
    //         spokeWith: Yup.string().when('status', (status, schema) =>
    //             status === 'Attempt' ? schema.required('Spoke with is required') : schema
    //         )
    //     })
    // ),

    leadsEmails: Yup.array().of(
        Yup.object().shape({
            emailedto: Yup.string()
                .trim()
                .email("Must be a valid email")
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
            // .required("Emailed to is required"),
            // status: Yup.string().required('Status is required'),
        })
    ),

    // leadsLinkedins: Yup.array().of(
    //     Yup.object().shape({
    //         requestTo: Yup.string()
    //         // .required('Request To is required')
    //         // .matches(/^[A-Za-z\s]+$/, 'Request To must contain only letters and spaces'),

    //       // status: Yup.string().required('Status is required'),
    //     })
    //   ), 


    leadsScheduledCalls: Yup.array().of(
        Yup.object().shape({
            emails: Yup.string()
                .trim()
                .email('Invalid email format')
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format"),
            // .test(
            //   'email-or-phone',
            //   'Either email or phone number must be provided',
            //   function (value) {
            //     const { phonenumber } = this.parent;
            //     return !!value || !!phonenumber; // Validation passes if either is filled
            //   }
            // ),

            // phonenumber: Yup.string()
            //   .matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0')
            //   .test(
            //     'email-or-phone',
            //     'Either email or phone number must be provided',
            //     function (value) {
            //       const { emails } = this.parent;
            //       return !!value || !!emails; // Validation passes if either is filled
            //     }
            //   ),
        })
    ),


    leadsOPS: Yup.array().of(
        Yup.object().shape({
            emails: Yup.string()
                .trim()
                .email('Invalid email format') // Validate email format
                .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
            // .test('email-or-phone', 'Either email or phone number must be provided', function(value) {
            //   const { phonenumber } = this.parent; // Access the other field (phonenumber)
            //   return !!value || !!phonenumber; // Validation passes if either emails or phonenumber is filled
            // }),

            // phonenumber: Yup.string()
            //   .matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0')
            //   .test('email-or-phone', 'Either email or phone number must be provided', function(value) {
            //     const { emails } = this.parent; // Access the other field (emails)
            //     return !!value || !!emails; // Validation passes if either emails or phonenumber is filled
            //   }),
        })
    ),

});


const AddSales = (props) => {
    // const { SalesInfo, isCreate, handleClose, requestSubmit,isDisabled,leadID } = props;
    const { SalesInfo, isCreate, handleClose, leadID } = props;

    const dispatch = useDispatch();
    const { logError } = useErrorLogger();

    const [age, setAge] = useState('');
    const [value, setValue] = useState('');
    const [employeeDetails, setEmployeeDetails] = React.useState({});

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const token = localStorage.getItem('token');
    const initialTime = 0;
    const [elapsedTime, setElapsedTime] = useState(0);
    const [workHistoryId, setWorkHistoryId] = useState('');
    const [startTime, setStartTime] = useState('');
    const [isRunning, setIsRunning] = useState(false);
    let intervalId = null;

    const [tabValue, setTabValue] = useState("0");

    const usersList = useSelector((state) => state.employee.AllEmployees);
    const assignToUsers = usersList.filter(
        user => user.employeestatus === "Active" && user.department !== "Operations"
    );
    console.log('assignToUsers', assignToUsers)
    const existingSales = useSelector((state) => state.sales.sales || [])

    const tabChange = (key) => {

        setTabValue(key);
    };
    const navigate = useNavigate();

    const [updateWorkHistory, { }] = useMutation(UPDATE_WORKHISTORY, {
        onCompleted(data) {
            setIsRunning(false);
        },
    });

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token)?.id;

    const [getWorkHistoryID, { }] = useMutation(GET_WORKHISTORY_BY_ID, {
        onCompleted(data) {
            let datacontent = data.getWorkHistoryID;
            if (datacontent.length > 0) {
                setWorkHistoryId(datacontent[0]?.id)
                setStartTime(datacontent[0].startTime)
                setIsRunning(true);
            }
        },
    });

    const [createStatusWorkHistory, { }] = useMutation(CREATE_STAUS_WORKHISTORY, {
        onCompleted(data) {

        },
    });


    useEffect(() => {
        let intervalId;
        if (isRunning) {
            intervalId = setInterval(() => {
                const currentTime = new Date().getTime();
                const timeDifference = currentTime - parseInt(startTime);
                setElapsedTime(timeDifference);
            }, 1000);
        }

        return () => {
            clearInterval(intervalId);
        };
        dispatch(setRefreshStatus(null));
    }, [isRunning, startTime]);


    const formatTime = (time) => {
        const seconds = Math.floor(time / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30);
        const years = Math.floor(months / 12);

        const formattedTime = [
            years > 0 ? `${years} year${years > 1 ? 's' : ''}` : '',
            months > 0 ? `${months} month${months > 1 ? 's' : ''}` : '',
            days > 0 ? `${days} day${days > 1 ? 's' : ''}` : '',
            `${hours % 24}:${minutes % 60}:${seconds % 60}`,
        ].filter(Boolean).join(' ');

        return formattedTime;
    };

    const handleStart = () => {
        setIsRunning(true);
        setStartTime(Date.now().toString())
    };

    const handleStop = () => {
        setIsRunning(false);
        setElapsedTime(initialTime);

        const valuesObject = {
            id: workHistoryId,

            endTime: Date.now().toString(),
        }
        updateWorkHistory({ variables: valuesObject });
    };



    const steps = ['Request Details', 'Attachment History', 'Work History'];


    const isStepOptional = (step) => {
        return step === 1; // set optional stage
    };




    const isStepSkipped = (step) => {
        return skipped.has(step);
    };

    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSkip = () => {
        if (!isStepOptional(activeStep)) {
            throw new Error("You can't skip a step that isn't optional.");
        }

        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped((prevSkipped) => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
        });
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    const refreshStatus = useSelector((state) => state.sales.refreshStatus);
    const cancelHandelar = () => {
        handleClose()
        dispatch(setLastContactedDates([]))
        if (refreshStatus == null) {
            dispatch(setRefreshStatus(true));
        }
        dispatch(setSale({}));

    }

    const getLoginUser = () => {
        let username = ''
        let userObject = assignToUsers?.filter((key) => key?.id == parseJwt(token)?.id);
        if (userObject.length > 0) {
            username = userObject[0]?.firstname + ' ' + userObject[0]?.lastname
        }
        return username;

    }
    const initialValues = {
        id: SalesInfo?.id,
        leadID: SalesInfo?.leadID,
        createdDate: SalesInfo?.createdDate,
        createdBy: getLoginUser() || '',
        lastUpdatedDate: SalesInfo?.lastUpdatedDate,
        lastContactedDate: SalesInfo?.lastContactedDate,
        followup: SalesInfo?.followup,
        followupDate: SalesInfo?.followupDate,
        callscheduledwithOPs: SalesInfo?.callscheduledwithOPs,
        assign: true,
        assignedTo: getLoginUser() || '',
        assignTo: SalesInfo?.assignTo,
        priority: SalesInfo?.priority || 'Low',
        status: SalesInfo?.status || 'New',
        stage: SalesInfo?.stage || 'Sales team',
        channel: SalesInfo?.channel,
        successfull: SalesInfo?.successfull,
        agencyName: SalesInfo?.agencyName || '',
        address: SalesInfo?.address,
        city: SalesInfo?.city,
        state: SalesInfo?.state,
        timeZone: SalesInfo?.timeZone,
        webURL: SalesInfo?.webURL,
        contactInfoURL: SalesInfo?.contactInfoURL,
        linkedinURL: SalesInfo?.linkedinURL || '',
        contactInfoComments: SalesInfo?.contactInfoComments,
        callingComments: SalesInfo?.callingComments,
        emailComments: SalesInfo?.emailComments,
        linkedinComments: SalesInfo?.linkedinComments,
        scheduledCallComments: SalesInfo?.scheduledCallComments,
        opsTeamComments: SalesInfo?.opsTeamComments,
        leadsContacts: SalesInfo?.leadsContacts,
        leadsCalls: SalesInfo?.leadsCalls,
        leadsEmails: SalesInfo?.leadsEmails,
        leadsLinkedins: SalesInfo?.leadsLinkedins,
        leadsScheduledCalls: SalesInfo?.leadsScheduledCalls,
        leadsOPS: SalesInfo?.leadsOPS,
    }
    const getTimeZoneCode = (timezone) => {

        if (timezone?.length == 3) {
            return timezone;
        } else {
            switch (timezone) {
                case 'Eastern Time':
                    return "EST";
                case 'Central Time':
                    return "CST";
                case 'Mountain Time':
                    return "MST";
                case 'Pacific Time':
                    return "PST";
                case 'Hawaii Time':
                    return "HST";
            }
        }
    }

    // const submit = (values) => {

    //       const valuesObject = {
    //         // id: SalesInfo?.id,
    //         // leadID: values?.leadID,
    //         createdDate: Date.now() + '',
    //         createdBy: values?.createdBy,
    //         lastUpdatedDate: Date.now() + '',
    //         lastContactedDate: values?.lastContactedDate,
    //         followup: values?.followup,
    //         // followupDate: (values?.followup == true && values?.followupDate == null) ? new Date().toISOString() : values?.followupDate,
    //         followupDate: values?.followupDate,
    //         callscheduledwithOPs: values?.callscheduledwithOPs,
    //         assign: values?.assign,
    //         assignedTo: ((values?.assignTo == null) ? values?.assignedTo : values?.assignTo),
    //         assignTo: values?.assignTo,
    //         priority: values?.priority,
    //         status: values?.status,
    //         stage: values?.stage,
    //         channel: values?.channel,
    //         successfull: values?.successfull,
    //         agencyName: values.agencyName,
    //         address: values.address,
    //         city: values.city,
    //         state: values.state,
    //         timeZone: getTimeZoneCode(values?.timeZone),
    //         webURL: values?.webURL,
    //         contactInfoURL: values?.contactInfoURL,
    //         linkedinURL: values?.linkedinURL,
    //         contactInfoComments: values?.contactInfoComments,
    //         callingComments: values?.callingComments,
    //         emailComments: values?.emailComments,
    //         linkedinComments: values?.linkedinComments,
    //         scheduledCallComments: values?.scheduledCallComments,
    //         opsTeamComments: values?.opsTeamComments,
    //         leadsContacts: values?.leadsContacts?.map(({ __typename, ...rest }) => rest),
    //         leadsCalls: values?.leadsCalls?.map(({ __typename, ...rest }) => rest),
    //         leadsEmails: values?.leadsEmails?.map(({ __typename, ...rest }) => rest),
    //         leadsLinkedins: values?.leadsLinkedins?.map(({ __typename, ...rest }) => rest),
    //         leadsScheduledCalls: values?.leadsScheduledCalls?.map(({ __typename, ...rest }) => rest),
    //         leadsOPS: values?.leadsOPS?.map(({ __typename, ...rest }) => rest),
    //     }
    // }

    // const [updateLead, { loading, error }] = useMutation(UPDATE_LEAD, {
    //     onCompleted(data) {

    //         setSetPopupStatus(true);
    //         let submitResponse = data?.updateLead && "Submitted Successfully!" || 'Error'
    //         setSubmitStatus(submitResponse)
    //     },
    //     onError(error) {
    //         setSetPopupStatus(true);

    //         setSubmitStatus('An error occurred. Please try again.'); // Set your error message

    //     },
    //   });

    const [newLead, setNewLead] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const startTimer = () => {
        setIsDisabled(true); // Disable the button
        setTimeout(() => {
            setIsDisabled(false); // Enable the button after 5 seconds
        }, 5000);
    };

    const requestSubmit = async (values, { resetForm }) => {

        const { createdDate, lastUpdatedDate, ...newObject } =
            newLead;
        const {
            createdDate: date,
            lastUpdatedDate: last,
            // lastContactedDate: contact,
            ...createdObject
        } = values;

        if (JSON.stringify(newObject) === JSON.stringify(createdObject)) {
            startTimer();
            message.error({
                content:
                    "You haven't changed anything compared to the last lead. Please modify a value before submitting.",
                duration: 3,
                className: "custom-toast-center", // Custom warning styling
            });
            return;
        }
        startTimer();
        setNewLead(values);

        try {

            dispatch(setLoader(true))// Show loader when starting the submission

            const valuesObject = {
                createdDate: Date.now() + "" || "",
                createdBy: getLoginUser() || "",
                lastUpdatedDate: Date.now() + "" || "",
                lastContactedDate: values?.lastContactedDate || "",
                followup: values?.followup || false,
                // followupDate: (values?.followup == true && values?.followupDate == null) ? new Date().toISOString() : values?.followupDate,
                followupDate: values?.followupDate || "",
                callscheduledwithOPs: values?.callscheduledwithOPs || false,
                assign: values?.assign || false,
                assignedTo: values?.assignedTo || "",
                assignTo: values?.assignTo || "",
                priority: values?.priority || "",
                status: values?.status || "",
                stage: values?.stage || "",
                channel: values?.channel || "",
                successfull: values?.successfull || false,
                agencyName: values.agencyName || "",
                address: values.address || "",
                city: values.city || "",
                state: values.state || "",
                timeZone: getTimeZoneCode(values?.timeZone) || "",
                webURL: values?.webURL || "",
                contactInfoURL: values?.contactInfoURL || "",
                linkedinURL: values?.linkedinURL || "",
                contactInfoComments: values?.contactInfoComments || "",
                callingComments: values?.callingComments || "",
                emailComments: values?.emailComments || "",
                linkedinComments: values?.linkedinComments || "",
                scheduledCallComments: values?.scheduledCallComments || "",
                opsTeamComments: values?.opsTeamComments || "",
                leadsContacts:
                    values?.leadsContacts?.map(({ __typename, ...rest }) => rest) || [],
                leadsCalls:
                    values?.leadsCalls?.map(({ __typename, ...rest }) => rest) || [],
                leadsEmails:
                    values?.leadsEmails?.map(({ __typename, ...rest }) => rest) || [],
                leadsLinkedins:
                    values?.leadsLinkedins?.map(({ __typename, ...rest }) => rest) || [],
                leadsScheduledCalls:
                    values?.leadsScheduledCalls?.map(({ __typename, ...rest }) => rest) ||
                    [],
                leadsOPS:
                    values?.leadsOPS?.map(({ __typename, ...rest }) => rest) || [],
            };

            await createLead({ variables: valuesObject });
            // Reset form after successful submission
            resetForm();
        } catch (error) {
            dispatch(setLoader(false)); // Hide loader
            await logError("SalesInformationPopup", "requestSubmit", error.message);

            console.error('Error creating lead:', error);
            message.error({
                content: "Failed to create lead. Please try again.",
                duration: 5,
                className: "alert alert-danger text-center",
            });
        }
    };

    const [createLead] = useMutation(CREATE_LEAD, {
        onCompleted: async (data) => {
            dispatch(setIsRefetch(true));
            dispatch(setRefreshStatus(false));
            dispatch(setDivVisibility(false));
            dispatch(setSale(data.createLead));
            message.success({
                content: `Lead created successfully! with lead Id ${data?.createLead?.leadID}`,
                duration: 5,
                className: "custom-toast-center", // Custom success styling
            });
            dispatch(setLoader(false)); // Hide loader
            dispatch(updateSales(data.createLead, ...existingSales));
            dispatch(setLastContactedDates([]))
            const workingId = data.createLead.id
            navigate(`/sales/${workingId}`)
            //  handleCloseFilter();
        },

        onError(error) {
            dispatch(setLoader(false))

        },
    });


    const [popupStatus, setSetPopupStatus] = useState(false);
    const [submitStatus, setSubmitStatus] = useState('');


    const handleDialogClose = () => {
        setSetPopupStatus(false);

    };

    const getBy = (userby) => {
        let callbyuser = '';
        if (userby == '') {
            return callbyuser;
        } else if (userby == undefined) {
            let calluser = assignToUsers.filter(item => item?.id == parseJwt(token)?.id)
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
            }
            return callbyuser
        } else {
            let calluser = assignToUsers.filter(item => (item.firstname.toLowerCase() + " " + item.lastname.toLowerCase()) == userby?.toLowerCase())
            if (calluser?.length == 0) {
                calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
            }
            if (calluser?.length > 0) {
                callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
            }

            return callbyuser
        }
    }

    const salesLeadId = useSelector((state) => state?.sales?.sale?.leadID);
    const salesDates = useSelector((state) => state?.sales?.sale);

    return (
        <>
            <Modal
                visible={popupStatus}
                onCancel={handleDialogClose}
                footer={[
                    <Button key="close" onClick={handleDialogClose} type="primary">
                        OK
                    </Button>
                ]}
            >
                <h2>Submit Status</h2>
                <p>{submitStatus}</p>
            </Modal>

            <div style={{ width: '100%' }}>
                <Formik
                    initialValues={initialValues}
                    validateOnChange={true}
                    onSubmit={requestSubmit}
                    validationSchema={validationSchema}
                >
                    {({ resetForm, handleBlur, handleChange, handleSubmit, isSubmitting, values, setFieldValue, errors, touched }) => (

                        <form noValidate onSubmit={handleSubmit}>

                            <Row gutter={16}>
                                <Col
                                    span={24}
                                    style={{ marginTop: '16px', display: 'flex', justifyContent: 'flex-end', gap: '8px' }}
                                >
                                    <Button color='primary' variant='solid' htmlType="submit" disabled={isDisabled}>
                                        Create
                                    </Button>
                                    <Button
                                        color="danger"
                                        variant="solid"
                                        onClick={cancelHandelar}>
                                        Close
                                    </Button>
                                </Col>
                            </Row>

                            <SaleInformation2
                                salesLeadId={salesLeadId}
                                salesDates={salesDates}
                                initialValues={initialValues}
                                employeeDetails={employeeDetails}
                                setEmployeeDetails={setEmployeeDetails}
                                assignToUsers={assignToUsers}
                                SalesInfo={SalesInfo}
                                handleChange={handleChange}
                                values={values}
                                setFieldValue={setFieldValue}
                            />

                            <Tabs activeKey={String(tabValue)} onChange={tabChange} type="card" style={{ marginBottom: '10px' }}>
                                <TabPane tab="Calling Track" key="0" />
                                <TabPane tab="Email Track" key="1" />
                                <TabPane tab="Linkedin Track" key="2" />
                                <TabPane tab="Scheduling a Call" key="3" />
                                <TabPane tab="Operations Team Communication" key="4" />
                                {!isCreate && <TabPane tab="Attachment History" key="5" />}
                                {!isCreate && <TabPane tab="Work History" key="6" />}
                            </Tabs>

                            {tabValue === '0' && <CallingTrack SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === '1' && <EmailTrack SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === '2' && <LinkedinTrack SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === '3' && <SchedulingCall SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {tabValue === '4' && <OperationsTeam SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} getBy={getBy} setFieldValue={setFieldValue} />}
                            {!isCreate && tabValue === '6' && <RequestWorkHistory initialValues={initialValues} employeeDetails={employeeDetails} setEmployeeDetails={setEmployeeDetails} SalesInfo={SalesInfo} handleChange={handleChange} values={values} assignToUsers={assignToUsers} />}
                        </form>
                    )}
                </Formik>
            </div>
        </>
    );

}

export default AddSales;