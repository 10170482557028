import { gql, useMutation } from '@apollo/client';

export const WEB_SCRAPING_MUTATION = gql`
  mutation WebScraping($config: WebScreppingConfigInput) {
    webScrepping(config: $config) {
      status
      message
      data {
        fieldName
        value
      }
    }
  }
`;
