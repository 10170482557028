// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  reports: [],
  reportData: [],
  TotalCount: 0,
  clietReportOptions: {}
};
const reportSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {
    getReports: (state, action) => {
      state.reports = action.payload;
    },
    getReportData: (state,action)=>{
      state.reportData = action.payload;
    },
    TotalCount: (state, action) => {
      state.TotalCount = action.payload;
    },
    getClientReportOptions: (state,action)=>{
      state.clietReportOptions = action.payload;
    }
  },
});

export const { getReports,getReportData,TotalCount, getClientReportOptions } = reportSlice.actions;

export default reportSlice.reducer;
