import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_ALL_CURRENT_CARRIERS } from "../../../Queries/Users";
import * as DataBucket from "../../../databucket";
import { Select, Input, DatePicker, Button, Form, Row, Col } from "antd";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;

const ExistingPolicyInformation = ({ employeeInfo, handleChange,values, handleAddPolicy, handleDeletePolicy }) => {
  const { data } = useQuery(GET_ALL_CURRENT_CARRIERS);
  const currentCarriers = data?.getAllCurrentCarriers?.response || [];
  console.log("curent", currentCarriers)
  const options = useSelector((state) => state.dataBucket.databucket);
  // const renderOptions = (options, criteria, fallbackData = []) => {
  //   console.log("fallbackData", fallbackData)

  //   return options?.reduce((acc, item) => {
  //     // Check if the item name matches the criteria
  //     if (item.name === criteria.name && item.data) {
  //       // Filter subItems based on active status and flag === true
  //       const filteredSubItems = item.data.filter(
  //         (subItem) => subItem.status === "active" && subItem.flag === true
  //       );
  //       // Accumulate valid subItems into the result array
  //       acc.push(...filteredSubItems);
  //     }
  //     return acc;
  //   }, []).map((subItem, index) => (
  //     <Option key={index} value={subItem?._id}>
  //       {subItem?.name}
  //     </Option>
  //   )) ||
  //     fallbackData?.map((item, index) => (
  //       <Option key={index} value={item}>
  //         {item}
  //       </Option>
  //     ));
  // };
  return (
    <div className="row">
      <Form layout="vertical">
        <div className="form-scroll">
          <h3 className="card-title">Existing Policy Information</h3>
          <div className="mb-3">
            <hr />
          </div>

          {employeeInfo?.map((policy, index) => (
            <Row key={index} gutter={16}>
              <Col span={4.5}>
                <Form.Item
                  label="Current Carrier"
                  name={`existingPolicyInformation.${index}.currentCarrier`}
                  rules={[
                    { required: true, message: 'Please select a current carrier' },
                  ]}
                >
                  <Select
                    name={`existingPolicyInformation.${index}.currentCarrier`}
                    value={policy.currentCarrier || ''}
                    onChange={(value) => handleChange({
                      target: {
                        name: 'existingPolicyInformation',
                        value: value,
                        index: index,
                        field: 'currentCarrier'
                      }
                    })}
                  >
                    {/* Add an empty option as the first option */}
                    <Select.Option value="">
                      Select a Carrier
                    </Select.Option>

                    {/* Map through the currentCarriers to display options */}
                    {currentCarriers
                      .filter((item) => item?.status === 'Active')
                      .map((carrierItem) => (
                        <Select.Option key={carrierItem?.carrierName} value={carrierItem?.carrierName}>
                          {carrierItem?.carrierName}
                        </Select.Option>
                      ))
                    }
                  </Select>
                </Form.Item>

              </Col>

              <Col span={4}>
                <Form.Item label="Processes" name={`existingPolicyInformation.${index}.propertyType`}
                  rules={[
                    { required: true, message: 'Please select a property type' },
                  ]}>
                  <Select
                    name={`existingPolicyInformation.${index}.propertyType`}
                    value={policy.propertyType || ''}  // Make sure the default value is either an empty string or a valid property
                    onChange={(value) => handleChange({
                      target: {
                        name: 'existingPolicyInformation',
                        value: value, // Pass the selected value directly
                        index: index, // Include the index to identify which policy to update
                        field: 'propertyType' // Specify the field to update
                      }
                    })}

                  >
                    {((values.processType === "Policy Quoting" && values.department === "Commercial Lines")
                     ?
                     renderOptions(options, { name: "PROPERTY_TYPE_CONFIG_POLICY_QUOTE" }, DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE) : 
                     renderOptions(options, { name: "PROPERTY_TYPE_CONFIG" }, DataBucket.PROPERTY_TYPE_CONFIG)
                      )}
                    {}
                  </Select>

                </Form.Item>

              </Col>

              <Col span={4.5}>
                <Form.Item label="Policy Number" name={`existingPolicyInformation.${index}.policyNumber`}
                  rules={[
                    { required: true, message: 'Please enter the policy number' },
                  ]}>
                  <Input
                    value={policy.policyNumber || ''}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'existingPolicyInformation',
                        value: e.target.value, // Get the input value
                        index: index, // Include the index to identify which policy to update
                        field: 'policyNumber' // Specify the field to update
                      }
                    })}
                  />
                </Form.Item>
              </Col>

              <Col span={4.5}>
                <Form.Item label="Effective Date" name={`existingPolicyInformation.${index}.effectiveDate`}
                  rules={[
                    { required: true, message: 'Please select an effective date' },
                  ]}>
                  <DatePicker
                    value={policy.effectiveDate ? dayjs(policy.effectiveDate) : null}
                    onChange={(date) => handleChange({
                      target: {
                        name: 'existingPolicyInformation',
                        value: date ? date.format('YYYY-MM-DD') : '',
                        index: index,
                        field: 'effectiveDate',
                      }
                    })}
                    format="YYYY-MM-DD"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>

              <Col span={5}>
                <Form.Item label="Current Premium" name={`existingPolicyInformation.${index}.currentPremium`}
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the current premium',
                    },
                    {
                      pattern: /^\d+(\.\d{1,2})?$/,
                      message: 'Please enter a valid premium amount',
                    },
                  ]}>
                  <Input
                    value={policy.currentPremium || ''}
                    onChange={(e) => handleChange({
                      target: {
                        name: 'existingPolicyInformation',
                        value: e.target.value, // Get the input value
                        index: index, // Include the index to identify which policy to update
                        field: 'currentPremium' // Specify the field to update
                      }
                    })}
                  //onChange={(e) => handleChange({ target: { name: 'existingPolicyInformation', value: employeeInfo.map((item, i) => i === index ? { ...item, currentPremium: e.target.value } : item) } })}
                  />
                </Form.Item>
              </Col>

              <Col span={1} style={{ marginTop: "25px", display: 'flex', alignItems: 'center' }}>
                <Form.Item>
                  <Link to="#" className="delete-icon" onClick={() => handleDeletePolicy(index)}>
                    <i className="fa-regular fa-trash-can" />
                  </Link>
                </Form.Item>
              </Col>
            </Row>
          ))}

          <div className="add-more">
            <Link to="#" onClick={handleAddPolicy}>
              <i className="fa-solid fa-plus-circle" /> Add More
            </Link>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ExistingPolicyInformation;