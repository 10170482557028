import React, { useState ,useEffect } from 'react';
import { Input, Button, Form, Space, Card, Typography } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { WEB_SCRAPING_MUTATION } from '../Queries/webScrepping';
import { useMutation } from '@apollo/client';
import { Spin } from 'antd';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setScraper } from '../Slices/webScrepperSlice';
const WebScrapingConfig = () => {
  const data=useSelector((state)=>state.scraper.webScraper);
  const [mainLink, setMainLink] = useState('');
  const [mainClass, setMainClass] = useState('');
  const [loading, setLoading] = useState(false);
  const [requiredClasses, setRequiredClasses] = useState([{ fieldName: '', className: '' }]);
  const [innerConfigs, setInnerConfigs] = useState({
    mainClass: '',
    fields: [{ fieldName: '', className: '' }]
  });
  const [scrapedData, setScrapedData] = useState(); // For displaying backend data
  const [webScrepping] = useMutation(WEB_SCRAPING_MUTATION);
  
  const dispatch=useDispatch();
  const handleRequiredClassChange = (index, field, value) => {
    const updatedClasses = [...requiredClasses];
    updatedClasses[index][field] = value;
    setRequiredClasses(updatedClasses);
  };

  // Handle changing values in inner config fields
  const handleInnerConfigChange = (field, value) => {
    setInnerConfigs((prevConfig) => ({
      ...prevConfig,
      [field]: value,
    }));
  };

  // Handle changing values in inner fields
  const handleInnerFieldChange = (fieldIndex, field, value) => {
    const updatedConfigs = { ...innerConfigs };
    updatedConfigs.fields[fieldIndex][field] = value;
    setInnerConfigs(updatedConfigs);
  };

  // Add a new inner field to the existing config
  const handleAddInnerField = () => {
    const updatedConfigs = { ...innerConfigs };
    updatedConfigs.fields.push({ fieldName: '', className: '' });
    setInnerConfigs(updatedConfigs);
  };
  useEffect(()=>
  {
    setScrapedData(data);
    console.log("SDvfdsbfdzb",data ,scrapedData)
  },[data])
//   const datas = [
//     {
//         fieldName: "price",
//         value: "$865,000",
//         __typename: "WebScreppingField"
//     },
//     {
//         fieldName: "address",
//         value: "1898 Meridian Ave #43,",
//         __typename: "WebScreppingField"
//     },
//     {
//         fieldName: "beds",
//         value: "3",
//         __typename: "WebScreppingField"
//     },
//     // ... more data
// ];
  const handleSubmit = async () => {
    const config = {
      mainLink: mainLink,
      mainClass: mainClass,
      requiredClasses: requiredClasses.map((item) => ({
        fieldName: item.fieldName,
        className: item.className,
      })),
      innerConfigs: {
        mainClass: innerConfigs.mainClass,
        Subfields: innerConfigs.fields.map((field) => ({
          fieldName: field.fieldName,
          className: field.className,
        })),
      },
    };

    console.log('Web Scraping Configuration:', config);

    // Start loading
    setScrapedData([]);
    setLoading(true);
    try {
      // Send the mutation with the correct config structure
      const response = await webScrepping({
        variables: {
          config: {
            mainLink: config.mainLink,
            mainClass: config.mainClass,
            requiredClasses: config.requiredClasses,
            innerConfigs: config.innerConfigs,
          },
        },
      });
     
      dispatch(setScraper(response?.data?.webScrepping?.data));
      // console.log("response", response, response.data, response?.data?.webScrepping?.data);
      // console.log("response1",scrapedData?.data?.webScrepping?.data);
      // console.log("response2",scrapedData);
      // Simulate 5 seconds of scraping delay
    } catch (error) {
      console.error('Error during web scraping:', error);
      setLoading(false); // Stop loading in case of an error
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="page-wrapper" style={{ display: 'flex', height: '100vh' }}>
      {/* Left Section */}
      <div
        style={{
          flex: '1',
          padding: '20px',
          overflowY: 'auto',
          borderRight: '2px solid #f0f0f0',
        }}
      >
        <h2 style={{ textAlign: 'center', fontSize: '1.5rem' }}>Web Scraping Configuration</h2>

        <Card style={{ marginBottom: '20px' }}>
          <Form layout="vertical">
            <Form.Item label="Main Link">
              <Input
                value={mainLink}
                onChange={(e) => setMainLink(e.target.value)}
                placeholder="Enter the main link..."
              />
            </Form.Item>
            <Form.Item label="Main Class Name">
              <Input
                value={mainClass}
                onChange={(e) => setMainClass(e.target.value)}
                placeholder="Enter the main class name..."
              />
            </Form.Item>
          </Form>
        </Card>

        <Card title="Required Class Names" style={{ marginBottom: '20px' }}>
          {requiredClasses?.map((item, index) => (
            <Space key={index} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
              <Input
                placeholder="Field Name"
                value={item.fieldName}
                onChange={(e) => handleRequiredClassChange(index, 'fieldName', e.target.value)}
              />
              <Input
                placeholder="Class Name"
                value={item.className}
                onChange={(e) => handleRequiredClassChange(index, 'className', e.target.value)}
              />
              {/* <MinusCircleOutlined
                onClick={() => setRequiredClasses(requiredClasses.filter((_, i) => i !== index))}
              /> */}
            </Space>
          ))}
        </Card>

        <Card title="Inner Configurations">
          {/* Render inner config fields */}
          <Card title="Inner Config">
            <Form layout="vertical">
              <Form.Item label="Main Class">
                <Input
                  placeholder="Enter the inner main class name..."
                  value={innerConfigs.mainClass}
                  onChange={(e) => handleInnerConfigChange('mainClass', e.target.value)}
                />
              </Form.Item>

              <Typography.Title level={5}>Fields</Typography.Title>
              {innerConfigs.fields?.map((field, fieldIndex) => (
                <Space key={fieldIndex} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                  <Input
                    placeholder="Field Name"
                    value={field.fieldName}
                    onChange={(e) =>
                      handleInnerFieldChange(fieldIndex, 'fieldName', e.target.value)
                    }
                  />
                  <Input
                    placeholder="Class Name"
                    value={field.className}
                    onChange={(e) =>
                      handleInnerFieldChange(fieldIndex, 'className', e.target.value)
                    }
                  />
                  <MinusCircleOutlined
                    onClick={() => {
                      const updatedFields = innerConfigs.fields.filter((_, i) => i !== fieldIndex);
                      setInnerConfigs((prevConfig) => ({ ...prevConfig, fields: updatedFields }));
                    }}
                  />
                </Space>
              ))}
              <Button type="dashed" onClick={handleAddInnerField} icon={<PlusOutlined />}>
                Add Field
              </Button>
            </Form>
          </Card>
        </Card>

        <Button type="primary" style={{ marginTop: '10px' }} onClick={handleSubmit}>
          Submit Configuration 🚀
        </Button>
      </div>
      {/* Right Section */}
      <div style={{ flex: '1', padding: '20px', overflowY: 'auto' }}>
        <h2 style={{ textAlign: 'center', fontSize: '1.5rem' }}> Scraped Data will Be here</h2>
        <Card>
          {loading ? (
            <Spin
              size="large"
              tip="Scraping data..."
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            />
          ) : scrapedData?.length > 0 ? (
            scrapedData?.map((item, index) => (
              <p key={index}>
                <strong>{item.fieldName}:</strong> {item.value}
              </p>
            ))
          ) : (
            <p>{ "No data available. Submit the configuration to scrape data."}</p>
          )}

        </Card>
      </div>
    </div>
  );
};

export default WebScrapingConfig;
