/* eslint-disable no-unused-expressions */

import { Table } from "antd";
import { AddHoliday } from "../../../components/modelpopup/AddHoliday";
import { useEffect, useState, message } from "react";
import moment from "moment";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { base_url } from "../../../base_urls";
import { useQuery, useMutation } from "@apollo/client";
import { GET_HOLIDAY, DELETE_HOLIDAY } from "../../../Queries/Holiday";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getHoliday } from "../../../Slices/holidaySlice";
import { setLoader } from "../../../Slices/loaderSlice";

const Holidays = () => {
  const [users, setUsers] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState(null);
  const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
  const dispatch = useDispatch();
  //const users = useSelector((state) => state?.holidayData?.holiday);
  const { loading, error, data, refetch } = useQuery(GET_HOLIDAY, {
    variables: { department: loginUser?.department, role: loginUser?.role },
    fetchPolicy: "no-cache",
  });
  dispatch(setLoader(loading));

  const handleEditClick = (record) => {
    setSelectedHoliday(record); // Set selected leave data for editing
    setIsModalOpen(true); // Open the modal
  };

  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedHoliday(null); // Clear selected leave data
  };
  useEffect(() => {
    if (data && data.GetHoliday) {
      setUsers(data.GetHoliday);
      //dispatch(getHoliday(data?.GetHoliday));
    }
  }, [data]);


  // useEffect(() => {
  //   axios.get(base_url + "/holiday.json").then((res) => setUsers(res.data));
  // }, []);

  // const userElements = users.map((user, index) => ({
  //   key: index,
  //   id: user.id,
  //   Title: user.Title,
  //   HolidayDate: user.HolidayDate,
  //   Day: user.Day,
  // }));

  //const [selectedHoliday, setSelectedHoliday] = useState(null);
  const [DeleteHoliday] = useMutation(DELETE_HOLIDAY, {
    onCompleted: () => {
      //message.success("Holiday deleted successfully!");
      refetch(); // Refresh the data
    },
    onError: () => {
      message.error("Failed to delete holiday.");
    },
  });

  const handleDeleteClick = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this leave record?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        // Ensure selectedHoliday and its id are valid
        // if (!record?.id) {
        //   console.error("Holiday ID is not valid:", selectedHoliday);
        //   Swal.fire("Error", "Invalid holiday ID", "error");
        //   return;
        // }

        // console.log("Deleting holiday with ID:", record._id); // Debug log

        DeleteHoliday({ variables: { id: record._id } })
          .then(() => {
            // Optionally refetch the data or update the local state after the deletion
            refetch();
            Swal.fire("Deleted!", "The holiday record has been deleted.", "success");
          })
          .catch((err) => {
            console.error("Error updating leave status:", err);
            Swal.fire("Error", `Failed to delete the holiday. Error: ${err.message}`, "error");
          });
      }
    });
  };


  const handleDelete = async () => {
    if (!selectedHoliday?.id) {
      message?.error("No holiday selected.");
      return;
    }

    try {
      const { data } = await DeleteHoliday({ variables: { id: selectedHoliday.id } });

      if (data?.DeleteHoliday?.success) {
        message.success("Holiday deleted successfully!");
        refetch(); // Refresh the data
      } else {
        message.error(data?.DeleteHoliday?.message || "Failed to delete holiday.");
      }
    } catch (error) {
      message.error("Failed to delete holiday. Please try again.");
    }
  };

  const columns = [
    {
      title: "Holiday Name",
      dataIndex: "name",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Department",
      dataIndex: "department",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.Title.length - b.Title.length,
    },
    {
      title: "Holiday Date",
      dataIndex: "date",
      render: (text) => {
        const date = moment(text).format('DD/MM/YYYY');
        return <span>{date}</span>;
      },
    },
    // {
    //   title: "Day",
    //   dataIndex: "Day",
    //   render: (text) => <span>{text}</span>,
    //   sorter: (a, b) => a.Day.length - b.Day.length,
    // },
    ...(loginUser?.role === "Super Admin" || loginUser?.role === "Admin"
      ? [
        {
          title: "Action",
          render: (record) => (
            <div className="dropdown dropdown-action ">
              <Link
                to="#"
                className="action-icon dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                <i className="material-icons">more_vert</i>
              </Link>
              <div className="dropdown-menu dropdown-menu-right">
                {(loginUser?.role === "Super Admin" || loginUser?.role === "Admin") && (
                  <>
                    <Link
                      className="dropdown-item"
                      to="#"
                      // data-bs-toggle="modal"
                      // data-bs-target="#edit_holiday"
                      onClick={() => handleEditClick(record)} // Set selected holiday
                    >
                      <i className="fa fa-pencil m-r-5" /> Edit
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="#"
                      // data-bs-toggle="modal"
                      // data-bs-target="#delete"
                      onClick={() => handleDeleteClick(record)}
                    >
                      <i className="fa-regular fa-trash-can m-r-5" /> Delete
                    </Link>
                  </>
                )}
              </div>
            </div>
          ),
          sorter: false,
        },
      ]
      : [])
  ];

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {(loginUser?.role === "Super Admin" || loginUser?.role === "Admin") && (
            <Breadcrumbs
              maintitle="Holidays"
              title="Dashboard"
              subtitle="Holidays"
              modal="#add_holiday"
              name="Add Holiday"
            />

          )}

          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={users}
                  className="table-striped"
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
      <AddHoliday users={users} selectedHoliday={selectedHoliday} refetch={refetch}
        setSelectedHoliday={setSelectedHoliday} isOpen={isModalOpen} onClose={handleModalClose} />
      {/* <DeleteModal Name="Delete Holiday" /> */}
    </>
  );
};

export default Holidays;
