import * as React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { MdCloudDownload, MdMoreVert } from 'react-icons/md';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Link from 'antd/es/typography/Link';
import dayjs from 'dayjs';

import { useQuery, useMutation } from '@apollo/client';
import {  GET_ALLWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, GET_ALL_MODIFIED_ATTACHMENTS_BY_ID, UPLOAD_FILE, UPLOAD_FILES_MUTATION, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS, GET_ALLATTACHMENT_HISTORY_BY_ID } from '../../../Queries/Employees';
import { useSelector } from 'react-redux';
import { GET_ALL_USERS } from '../../../Queries/Users';

import axios from 'axios';
import { SERVER_URL } from '../../../databucket'; 

export default function AttachemntsHistory(props) {

    const {id}=useParams()
    const fileInputRef = useRef(null);
    const fileInputRefSingle = useRef(null);
    // const [assignToUsers, setAssignToUsers] = useState([]);
    const assignToUsers=useSelector((state)=>state.loginUserInfo.allUsers)
    const [attachmentStatus, setAttachmentStatus] = useState(false)

    const [attachmentsHistory, setAttachmentsHistory] = useState([])
    const [modifiedAttachmentsHistory, setModifiedAttachmentsHistory] = useState([])


    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token)?.id;

    // const { data, } = useQuery(GET_ALL_USERS,{
    //     variables: { loginUserId:userid },
    //   });

    // let userscontent = data ? data.getAllUsers : [];

    // useEffect(() => {

    //     // console.log("userscontent", userscontent)

    //     if (userscontent.status == 200) {
    //         if (userscontent.response?.length > 0) {
    //             const usersList=userscontent.response
    //             setAssignToUsers(usersList)
    //             console.log("filtered data",usersList)

    //         }
    //     }

    // }, [userscontent]);


    // const { data, } = useQuery(GET_ALL_USERS);

    // let userscontent = data ? data.getAllUsers : [];

    const [createAttachments, { loading }] = useMutation(CREATE_ATTACHEMNTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            // console.log(data, 'dataidddd', data.createWorkHistory?.id, data.createWorkHistory._id)
        },
    });
    const [deleteAttachments, { }] = useMutation(DELETE_ATTACHMENTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);

            // console.log('deleteAttachments-->', data)
        },
    });

    const attachmentObj = {
        emailId: id,
    }
    const attachmentHistoryObj = {
        userId: parseJwt(token)?.id,
        emailId: id,
    }
    const [updateAttachments, { }] = useMutation(UPDATE_ATTACHMENTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            console.log('updateAttachments-->', data)
        },
    });
    const [getAllAttachmentsByID, { }] = useMutation(GET_ALLATTACHMENTS_BY_ID, {
      onCompleted(data) {
          console.log('getAllAttachmentsByID-->', data)
          setAttachmentsHistory(data.getAllAttachmentsByID)

          const allAttachments = data?.getAllAttachmentsByID?.flatMap((attachments) =>
            Array.isArray(attachments?.attachmentHistory) ? attachments.attachmentHistory : [])

          setModifiedAttachmentsHistory(allAttachments)
      },
  });

    const [getAllAttachmentHistoryByID, { }] = useMutation(GET_ALLATTACHMENT_HISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllAttachmentHistoryByID-->', data)
            // generateAttachmentHistory(data.getAllModifiedAttachmentsByID)
            // setModifiedAttachmentsHistory(data.getAllAttachmentHistoryByID)
        },
    });

    useEffect(() => {
        getAllAttachmentsByID({ variables: attachmentObj });
        // getAllModifiedAttachmentsByID({ variables: attachmentObj })
        getAllAttachmentHistoryByID({ variables: attachmentHistoryObj })

    }, [attachmentStatus]);

    const openFileInputSingle = () => {
        if (fileInputRefSingle.current) {
            fileInputRefSingle.current.value = null;
            fileInputRefSingle.current.click();
        }
    };
    
    const handleSingleAttachment = (event, index) => {

        // console.log("-----------------------")
        const selectedFile = event.target.files[0];

        const formData = new FormData();
        formData.append('files', selectedFile);

        axios.post(SERVER_URL + '/upload',
            formData)
            .then((res) => {


                console.log(res.data);
                let savedFile = res.data.files[0];

                const valuesObject = {
                    userId: parseJwt(token)?.id,
                    emailId: id,

                    data: savedFile.url,
                    filename: savedFile.originalName,
                    mimetype: savedFile.mimeType,
                    addDate: Date.now().toString(),
                    updateDate: Date.now().toString(),
                    status: true,
                }

                // console.log('handleSingleAttachment-->', valuesObject)

                createAttachments({ variables: valuesObject })

            })
            .catch((error) => {
                console.error(error);
            });

    };


    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    const handleMultipleAttachment = (event, index) => {
        console.log('---------------------------->>>>>>>>>')
        const multipleFiles = Array.from(event.target.files);
        const formData = new FormData();
        multipleFiles.forEach((file) => {
            formData.append('files', file);
        });


        axios.post(SERVER_URL + '/upload', formData)
            .then((res) => {
                console.log(res.data);

                let uploadedFiles = res.data.files
                uploadedFiles?.map((uploadedItem) => {

                    const valuesObject = {
                        userId: parseJwt(token)?.id,
                        emailId: id,

                        data: uploadedItem.url,
                        filename: uploadedItem.originalName,
                        mimetype: uploadedItem.mimeType,
                        addDate: Date.now().toString(),
                        updateDate: Date.now().toString(),
                        status: true,
                    }

                    // console.log('handleSingleAttachment-->', valuesObject)

                    createAttachments({ variables: valuesObject })
                })

            })
            .catch((error) => {
                console.error(error);
            });
    };

    const handleupadteAttachment = (event) => {
        return event.target.files[0];
    }
    const updateHandler = async (attachemntId) => {
        const selectedFilePromise = new Promise((resolve, reject) => {
            const inputElement = document.getElementById('updateAttachmentInput');
            inputElement.addEventListener('change', (event) => {
                const selectedFile = event.target.files[0];
                resolve(selectedFile);
            });
            inputElement.click();
        });

        const selectedFile = await selectedFilePromise;


        const formData = new FormData();
        formData.append('files', selectedFile);

        axios.post(SERVER_URL + '/upload',
            formData)
            .then((res) => {
                let savedFile = res.data.files[0];

                const valuesObject = {
                    id: attachemntId,
                    data: savedFile.url,
                    filename: savedFile.originalName,
                    mimetype: savedFile.mimeType,
                    userId: parseJwt(token)?.id,
                    updateDate: Date.now().toString(),
                }

                updateAttachments({ variables: valuesObject })

            })

    }

    const deleteHandler = (attachemntId) => {
        const valuesObject = {
            id: attachemntId,
            userId: userid.toString(),
            updateDate: Date.now().toString(),
        }
        console.log(valuesObject, Date.now().toString())
        deleteAttachments({ variables: valuesObject })
    }


    const handleDownload = (fileUrl, fileName) => {
        // console.log('handleDownload-->', fileUrl, fileName)

        fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set desired file name
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch(error => console.error('Error downloading file:', error));
    };


    // const handleMultipleAttachmentOLD = async (event, index) => {
    //     try {
    //         const selectedFiles = Array.from(event.target.files);
    //         const files = await Promise.all(selectedFiles?.map((file) => file));

    //         const { data } = await uploadFile({ variables: { files } });

    //         console.log(data);


    //     } catch (error) {
    //         console.error(error);
    //         // Handle error
    //     }
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: [event.target.files[0].name] })
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: multipleFiles })
    // };

    const getDateTime = (timeValue) => {
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }

    const getStatus = (status, addDate, updateDate) => {
      let resultData = ""
      if (status) {
          if (addDate === updateDate) {
              resultData = "Created"
          } else {
              resultData = "Deleted"
          }
      } else {
          resultData = "Updated"
      }
      return resultData;
    }
    const getHistoryStatus = (status) => {
        let resultData = ""
        if (status == true) {
            resultData = "Created"
        } else if (status == null) {
            resultData = "Updated"
        } else if (status == false) {
            resultData = "Deleted"
        } else {
            resultData = ""
        }
        return resultData;
    }

    const getUserName = (userid) => {
        let userName = 'anonymous'
        assignToUsers?.map((userdetails) => {
            if (userdetails?.id == userid) {
                userName = userdetails?.firstname
            }
        })
        return userName;
    }

    const getNewUrl = (url) => {
        const initialPart1 = url.split('/uploads')[0];
        const initialPart2 = SERVER_URL;
        let replacedUrl = url
        if (initialPart1 !== initialPart2) {
            replacedUrl = initialPart2 + url.substring(initialPart1.length);
        }
        return replacedUrl;
    }

    return (
        <>
<div className="col-md-12 d-flex overflow-hidden">
  <div className="card profile-box flex-fill">
    <div className="card-body">
      <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
        Attachments
        <div style={{ display: 'flex', gap: '1rem' }}>
          <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
            <input
              type="file"
              ref={fileInputRefSingle}
              onChange={handleSingleAttachment}
              style={{ display: 'none' }}
            />
            <Link onClick={() => fileInputRefSingle.current && fileInputRefSingle.current.click()}>
                <i 
                    className="fa-solid fa-file" 
                    style={{ 
                        fontSize: "20px",  
                    }} 
                    aria-label="Add Single Attachment" 
                />

            </Link>
          </Col>
          <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
            <input
              type="file"
              multiple
              ref={fileInputRef}
              onChange={handleMultipleAttachment}
              style={{ display: 'none' }}
            />
            <Link onClick={() => fileInputRef.current && fileInputRef.current.click()}>
            <i 
                className="fa-regular fa-folder-open" 
                style={{ 
                    fontSize: "20px", 
                   
                }} 
                aria-label="Add Multiple Attachments" 
            />

            </Link>
          </Col>
        </div>
      </h3>

      <Table responsive>
        <thead>
          <tr>
            <th style={{ textAlign: 'center' }}>Attachments</th>
            <th style={{ textAlign: 'center' }}>Add Date</th>
            <th style={{ textAlign: 'center' }}>User Name</th>
            <th style={{ textAlign: 'center' }}>Actions</th>
            <th style={{ textAlign: 'center' }}>Options</th>
          </tr>
        </thead> 
        <tbody>
          {attachmentsHistory?.map((row, key) => {
            if (row.status ===false) return null;
            return(
            <tr key={key}>
              <td>
                <a href={getNewUrl(row.data)} target="_blank" rel="noopener noreferrer">
                  {row.filename}
                </a>
              </td>
              <td align="center">{getDateTime(parseInt(row.addDate))}</td>
              <td align="center">{getUserName(row.userId)}</td>
              <td align="center">
                <Button variant="primary" onClick={() => handleDownload(getNewUrl(row.data), row.filename)}>
                  <MdCloudDownload /> Download
                </Button>
              </td>
              <td align="center">
                <Dropdown drop="end">
                  <Dropdown.Toggle as="span" style={{ cursor: 'pointer' }}>
                    <MdMoreVert size={24} />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <input type="file"  id="updateAttachmentInput" onChange={handleupadteAttachment} style={{ display: 'none' }} />
                    <Dropdown.Item onClick={() => updateHandler(row?.id)}>
                      Replace
                    </Dropdown.Item>
                    
                    <Dropdown.Item onClick={() => deleteHandler(row?.id)}>
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </td>
            </tr>)
})}
        </tbody>
      </Table>
    </div>
  </div>
</div>

<div className="col-md-12 d-flex overflow-hidden">
  <div className="card profile-box flex-fill">
    <div className="card-body">
      <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between' }}>
        Attachments History
      </h3>
      <div style={{ overflowX: 'auto' }}>
        <Table responsive style={{ width: '100%', tableLayout: 'fixed' }}>
          <thead>
            <tr>
              <th style={{ textAlign: 'center' }}>Sno</th>
              <th style={{ textAlign: 'center' }}>Attachments</th>
              <th style={{ textAlign: 'center' }}>Actions</th>
              <th style={{ textAlign: 'center' }}>Add Date</th>
              <th style={{ textAlign: 'center' }}>Updated Date</th>
            </tr>
          </thead>
          <tbody>
            {modifiedAttachmentsHistory?.map((row, key) => (
              <tr key={key}>
                <td align="center">{key + 1}</td>
                <td align="center">
                  <div
                    style={{
                      maxWidth: '150px', // Adjust width as needed
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'inline-block',
                    }}
                    title={row.filename} // Tooltip to show the full filename
                  >
                    <a href={row.data} target="_blank" rel="noopener noreferrer">
                      {row.filename}
                    </a>
                  </div>
                </td>

               
                <td align="center">
                  {getDateTime(parseInt(row.addDate))}
                </td>
                <td align="center">
                  {row.updateDate ? getDateTime(parseInt(row.updateDate)) : "Not updated"}
                </td>
                <td align="center">
                {getStatus(row.status, row.addDate, row.updateDate)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

    </div>
  </div>
</div>

        </>
    );
}
