import React from "react";
import { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Table, Button, Tooltip,message } from "antd";
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEmployeeReport, filterEmployeeReport} from '../../../Slices/employeereportslice';
import { selectReport } from "../../../Slices/employeereportslice";
import ClientsReportFilter from "../../../components/ClientsReportFilter";
import { setLoader } from "../../../Slices/loaderSlice";




const EmployeeReport = () => {
 
  const today = new Date().toISOString().split('T')[0];
    const [currentPage,setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const data = useSelector(selectReport);
   const token = localStorage.getItem('token');
  const parseJwt = (token) => {
   try {
     return token ? JSON.parse(atob(token.split(".")[1])) : null;
   } catch (e) {
     return null;
   }
 };
 
 const userid = parseJwt(token).id.toString();
 const calculateTotalDuration = () => {
  let totalSeconds = data?.reduce((acc, data) => {
    const duration = data.duration;
    const match = duration?.match(/^(\d{2}):(\d{2}):(\d{2})$/); // This matches HH:MM:SS format

    if (match) {
      const hours = parseInt(match[1]); // Get hours from match[1]
      const minutes = parseInt(match[2]); // Get minutes from match[2]
      const seconds = parseInt(match[3]); // Get seconds from match[3]

      // Convert to total seconds and accumulate
      return acc + (hours * 3600) + (minutes * 60) + seconds;
    }

    return acc; // If no match, return accumulator as is
  }, 0);

  // Convert total seconds into hours, minutes, and seconds
  const totalHours = Math.floor(totalSeconds / 3600); // Total hours
  const remainingMinutes = Math.floor((totalSeconds % 3600) / 60); // Remaining minutes
  const remainingSeconds = totalSeconds % 60; // Remaining seconds

  // Return total duration in HH:MM:SS format
  return `${totalHours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`; 
};

 const filters = {
  employeeName: userid,
  startDateTime: today,
  endDateTime: today,
  billable: null,
};
 console.log("userid",typeof(userid))
 useEffect(() => {
  dispatch(setLoader(true))
    dispatch(filterEmployeeReport({ startDate: today, endDate: today }));
  dispatch(fetchEmployeeReport(filters)).then(()=>{
    dispatch(setLoader(false))
  });

}, [userid]);
const PageChange = (page) => {
  setCurrentPage(page);
};

  const renderWithTooltip = (text, maxLength) => {

    if (!text) return null;

    return (
      <Tooltip title={text}>
        {text.length > maxLength ? `${text.slice(0, maxLength)}...` : text}
      </Tooltip>
    );
  };

  const columns = [
    //{
    //   title: "EmployeeName",
    //   dataIndex: "employeeName",
    //   render: (text, record) => (
    //     <span className="table-avatar">
    //       <Link to="/profile">{text}</Link>
    //     </span>
    //   ),
    //   sorter: (a, b) => a.employeeName.length - b.employeeName.length,
    // },
    // {
    //   title: "Email",
    //   dataIndex: "emailId",
    //   render: (text) => (
    //     <span style={{ fontSize: '12px' }}>
    //       {renderWithTooltip(text, 8)}
    //     </span>
    //   ),
    //   sorter: (a, b) => a.emailId.length - b.emailId.length,
    // },
    {
        title: "clientName",
        dataIndex: "clientName",
        // sorter: (a, b) => a.clientName.length - b.clientName.length,
      },

    {
      title: "Service",
      dataIndex: "service",
      // sorter: (a, b) => a.service.length - b.service.length,
    },
    {
        title: "Task",
        dataIndex: "taskType",
        // sorter: (a, b) => a.service.length - b.service.length,
      },
      {
        title: "Billable",
        dataIndex: "billable",
        // sorter: (a, b) => a.billable.length - b.billable.length,
      },
      {
        title: "Request#",
        dataIndex: "requestIds",
        sorter: (a, b) => a?.requestId?.length - b?.requestId.length,
      },
      {
        title: "startDateandTime",
        dataIndex: "startingTime",
        sorter: (a, b) => a?.startdate?.length - b?.startdate?.length,
      },
      {
        title: "EndDateandTime",
        dataIndex: "endingTime",
        sorter: (a, b) => a?.endDate?.length - b?.endDate?.length,
      },
      {
        title: "Duration",
        dataIndex: "duration",
        sorter: (a, b) => a?.requestId?.length - b?.requestId?.length,
      },
    {
      title: "Status",
      dataIndex: "status",
      // render: (text) => (
      //   <div className="dropdown">
      //     <Link
      //       to="#"
      //       className="btn btn-white btn-sm btn-rounded dropdown-toggle"
      //       data-bs-toggle="dropdown"
      //       aria-expanded="false"
      //     >
      //       <i
      //         className={
      //           text === "Active"
      //             ? "far fa-dot-circle text-success"
      //             : "far fa-dot-circle text-danger"
      //         }
      //       />{" "}
      //       {text}{" "}
      //     </Link>
      //     <div className="dropdown-menu">
      //       <Link className="dropdown-item" to="#">
      //         <i className="far fa-dot-circle text-success" /> Active
      //       </Link>
      //       <Link className="dropdown-item" to="#">
      //         <i className="far fa-dot-circle text-danger" /> Inactive
      //       </Link>
      //     </div>
      //   </div>
      // ),
      sorter: (a, b) => a?.status?.length - b?.status?.length,
    },
  ];
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <ClientsReportFilter PageChange={PageChange} />
          <div className="row mt-20px">
            <div className="col-md-12">
              <div className="table-responsive">
                {/* <SearchBox /> */}
                    <Table
                    className="table-striped"
                    style={{ overflowX: "auto" }}
                    columns={columns}
                    dataSource={data}
                    rowKey={(record) => record.id}

                    size="small"
                    pagination={{
                      current: currentPage,
                      onChange: (page) => setCurrentPage(page),
                      pageSize: 10, 
                      position: ['bottomRight'], 
                    }}
                    scroll={{ x: 1000 }} 
                    footer={() =>    <div>
                      <strong>Total Duration: </strong> {calculateTotalDuration()} 
                    </div>}
                  />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default EmployeeReport;
