import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { AUTH_LOGIN, AUTH_RESET_PASSWORD } from '../../../Queries/Users';
import { GET_USER_DETAILS, FETCH_ALL_USERS } from '../../../Queries/Users';
import layer1 from '../../../assets/img/LoginImages/1 - layer1.png';
import sky from '../../../assets/img/LoginImages/7 - sky background.png';
import clouds from '../../../assets/img/LoginImages/6 - clouds.png';
import birds from '../../../assets/img/LoginImages/5 - birds.png';
import layer3 from '../../../assets/img/LoginImages/4 - layer3.png';
import layer2 from '../../../assets/img/LoginImages/3 - layer2.png';
import deer2 from '../../../assets/img/LoginImages/2 - deer2.png';
import text from '../../../assets/img/LoginImages/text.png';
import { UpdateLoginTime, Updatetime, Starttask, Updatetask } from '../../../Slices/timerslice.jsx';
import { SetAllEmployees, setId } from '../../../Slices/employeeslice.js';
import { useDispatch } from 'react-redux';
import '../../../assets/css/LoginNew.css';
import { message, Spin } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { CREATE_WORKHISTORY } from '../../../Queries/Orders';
import { CREATE_LOGINWORKHISTORY, } from '../../../Queries/Orders.js';
import CryptoJS from 'crypto-js';
import { ATTENDENCE, GET_LAST_ATTENDENCE_RECORD } from '../../../Queries/attendence.js';
import Swal from "sweetalert2";
import { setLoginUserDetails, setUserStatus, setAllUsers, getAllClientsData } from '../../../Slices/loginUserSlice.jsx';
import { setDataBucket } from '../../../Slices/dataBucket.js';
import { GET_FIELDS } from '../../../Queries/options.js';
import { setClients } from '../../../Slices/clientSlice.jsx';
import { GET_ALL_CLIENTS } from '../../../Queries/Users';
import { setLoader } from '../../../Slices/loaderSlice.jsx';
import { setRoute, setempRoute } from '../../../Slices/DyanamicRoute.js';
import { GET_DYNAMIC_ROUTES } from '../../../Queries/options.js';
import { useSelector } from 'react-redux';
import { setAttendence, setLastRecord } from '../../../Slices/attendenceSlice.js';
import { UPDATE_EMPLOYEE } from '../../../Queries/Employees.js';
import { RESET_TOKEN } from '../../../Queries/ResetToken.js';
const LoginN = () => {
    const dispatch = useDispatch();
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [tokenKey, setTokenKey] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [userdata, setUserdata] = useState('');
    const lastAttendance = useSelector((state) => state.Attendence.lastRecord);
     const AllEmployees = useSelector((state) => state.employee.AllEmployees);
    console.log("Redux Attendance State:", lastAttendance);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const history = useNavigate();
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [activeForm, setActiveForm] = useState("login"); 


    const [AttendenceLogin] = useMutation(ATTENDENCE)
    const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails);
    const [UpdateEmploye] = useMutation(UPDATE_EMPLOYEE);
    // console.log("loginUser",loginUser)
    // const [userStatus,setUserStatus]=useState(false);
    const { data: Data, loading: loadings, refetch } = useQuery(GET_FIELDS,{skip: !isLoggedIn});
    const [getUserDetails, { }] = useMutation(GET_USER_DETAILS, {
        onCompleted(data) {
            console.log('getUserDetails-->', data.getUserDetails[0],)
            
            dispatch(setLoginUserDetails(data?.getUserDetails[0]))
            dispatch(setUserStatus(true))
            dispatch(getAllClientsData(data?.getUserDetails[0]?.client))
        },
    });

    
    dispatch(setDataBucket(Data?.GetDynamicField));
    // const { data: clientData } = useQuery(GET_ALL_CLIENTS);
    // console.log("cdcdcds", clientData)
    // dispatch(setClients(clientData?.getAllClients?.response));
    // const { data: Clients, loading: ClientsLoading, error: clientsError } = useQuery(GET_ALL_CLIENTS, {
    //     onCompleted: (Clients) => {
    //         console.log("Clients Data fetched:", Clients);
    //         //   dispatch(setRoute(Route))
    //         // dispatch(setempRoute(Route?.getSideMenu));
    //         dispatch(setClients(Clients?.getAllClients?.response));

    //     },
    //     onError: (error) => {
    //         console.error("Error fetching data:", error);
    //     },
    // });


    const [fetchAllUsers, { loading: Loading }] = useMutation(FETCH_ALL_USERS, {
        onCompleted(data) {
            if (data) {
                const usersContent = data.fetchAllUsers || [];
                const usersList = usersContent.response || [];
                console.log("usersList-->1", usersList);
                dispatch(SetAllEmployees(data.fetchAllUsers.response))
                dispatch(setAllUsers(usersList));  // Dispatch the users list to the store
                dispatch(setUserStatus(true))
            }
        },
    })
    dispatch(setLoader(Loading))
    const { data: Route, loading: routeLoading, error: RouteError } = useQuery(GET_DYNAMIC_ROUTES, {
        skip: !isLoggedIn,
        onCompleted: (Route) => {
            console.log("Data fetched:", Route);
            //   dispatch(setRoute(Route))
            dispatch(setempRoute(Route?.getSideMenu));

        },
        onError: (error) => {
            console.error("Error fetching data:", error);
        },
    });


    const [ResetToken] = useMutation(RESET_TOKEN)
  
    const handleResetToken = async (event) => {
        event.preventDefault();

        if(!email) {
            message.warning("Please enter your Email!");
            return;
        }
        setLoading(true);

    try {
        const { data } = await ResetToken({
            variables: {
                email : email,
            },
        });

        if (data.ResetToken.success===true) {
            const newToken = data.ResetToken.token;
            message.success(`Token Reset Successful: ${newToken}`);
        } else {
            message.error(data?.ResetToken?.message || "Failed to reset token.");
        }
    } catch (error) {
        message.error("Failed to reset token. Please try again.");
    }
        setLoading(false);
    };



    const encryptPassword = (password) => {
        const secretkey = process.env.REACT_APP_SECRET_KEY;
       
        return CryptoJS.AES.encrypt(password, secretkey).toString();
    };
    const [createLoginWorkHistory, { data }] = useMutation(CREATE_LOGINWORKHISTORY);
    const [createWorkHistory, { data: workHistoryData }] = useMutation(CREATE_WORKHISTORY);
    const parseJwt = (token) => {
        try {
            return token ? JSON.parse(atob(token.split(".")[1])) : null;
        } catch (e) {
            return null;
        }
    };
    const CreateLoginWorkHistory = async (userid) => {
        try {
            const response = await createLoginWorkHistory({
                variables: { userId: parseJwt(userid)?.id.toString(), title: "", description: "", companyname: "BlueplanIt", department: "", task: 'login', date: "", startTime: new Date().toISOString(), endTime: "" },
            }).then((response) => {
                dispatch(UpdateLoginTime(response.data.createLoginWorkHistory.startTime))
                localStorage.setItem('LoginTime', new Date(response.data.createLoginWorkHistory.startTime).getTime());
            });
            const Workresponse = await createWorkHistory({

                variables: {
                    userId: parseJwt(userid)?.id,
                    emailId: '',
                    requestId: '',
                    clientName: 'Blueplaint',
                    processType: '',
                    taskType: 'Available',
                    email: '',
                    subject: '',
                    body: '',
                    startTime: Date.now().toString(),
                    endTime: '',
                },

            });
            console.log("Workresponse", Workresponse.data.createWorkHistory.taskType)
            dispatch(Updatetime(Number(Workresponse.data.createWorkHistory.startTime)));
            dispatch(Updatetask(Workresponse.data.createWorkHistory.taskType));
            localStorage.setItem('task', Workresponse.data.createWorkHistory.taskType)
            //   localStorage.setItem('task',Workresponse.data.createWorkHistory.taskType)
            //   localStorage.setItem('LoginTime', response.data.createLoginWorkHistory.startTime);
            localStorage.setItem('time', Workresponse.data.createWorkHistory.startTime)
            console.log('Work history created:', Workresponse.data.createWorkHistory);

            //   dispatch(UpdateLoginTime(response.data.createLoginWorkHistory.startTime))
            //   console.log("response.data",response)
            //   localStorage.setItem('LoginTime', response.data.createLoginWorkHistory.startTime); 

        } catch (err) {
            console.error('Error:', err);
        }
    };

    const { data: AttendenceLastRecord, loading: LoadingAttendence, refetch: refetchLastRecord } = useQuery(GET_LAST_ATTENDENCE_RECORD, {
        variables: { id: loginUser?.id },
        fetchPolicy: "network-only",  // Always fetch from the server, no cache
        skip: !loginUser?.id,  // Skip query execution if loginUser?.id is not available
        onCompleted: (data) => {
            if (data?.LastAttendence) {
                dispatch(setLastRecord(data.LastAttendence));
            }
        }
    });
    console.log("loginUser", loginUser)
    const [authLogin, { loading: loginLoading }] = useMutation(AUTH_LOGIN, {
        onCompleted(data) {

            if (data.authLogin.status === 200) {
                refetchLastRecord();
                setUserdata(data);
                const token = data.authLogin.token;
                const userId = parseJwt(token)?.id;
                CreateLoginWorkHistory(token);
                console.log("Login User:", loginUser);
                 dispatch(setId(true));
                localStorage.setItem('token', token);
                localStorage.setItem('StartTime', new Date().getTime());

                getUserDetails({ variables: { id: userId } });
                fetchAllUsers({ variables: { loginUserId: userId } });

                setError('');
                setSuccessMessage(data.authLogin.message);

                const routeName = data.authLogin.response?.menu?.find((mainRoute) =>
                    mainRoute.status && mainRoute?.Subfields?.find((subRoute) => subRoute.status)
                )?.Subfields?.find((subRoute) => subRoute.status)?.name;

                console.log("First active subfield name:", routeName, data.authLogin.response?.menu);

                setTimeout(() => {
                    if (routeName) {
                        history(routeName);
                        PunchHander(userId)
                      
                    }else{
                        history("profile");
                    }
                }, 3000);

            } else {
                setError(data.authLogin.message || 'Login error. Please check your credentials.');
                setSuccessMessage('');
            }
        }
        
    });
    

    const PunchHander = (userId) => {
      
        console.log("Attendance Check:", loginUser?.punchInStatus, lastAttendance);
        if (!lastAttendance || !loginUser?.punchInStatus) {
            Swal.fire({
                title: "Please Punch In",
                text: "Punch in before starting your work",
                icon: "warning",
                confirmButtonText: "Punch In",
                allowOutsideClick: false,
                allowEscapeKey: false,
                showCloseButton: true, 
            }).then(async (result) => {
                if (!result.isConfirmed) return;
                try {   
                    dispatch(setLoader(true))
                    console.log("Punch-in process started...",loginUser);
        
                    const updateResponse = await UpdateEmploye({
                        variables: {
                            id: userId,  // Ensure correct structure
                            input: {
                                punchInStatus: true
                            }
                        }
                    });
                    console.log("updateEmployee Response:", updateResponse);
        
                    const attendanceResponse = await AttendenceLogin({
                        variables: {
                            input: {
                                empId: loginUser?.id,
                                punchIn: Date.now().toString(),
                                status: true,
                                elapsedTime:0
                            }
                        }
                    });
                   await dispatch(setLoginUserDetails(updateResponse?.data?.UpdateEmploye?.response))
                    console.log("Full Response from AttendenceLogin:", attendanceResponse);
        
                    if (!attendanceResponse?.data?.AttendenceLogin?.response) {
                        console.error("No response object received, aborting dispatch.");
                        return;
                    }
                    dispatch(setAttendence(attendanceResponse.data.AttendenceLogin.response));
                    dispatch(setLastRecord(attendanceResponse.data.AttendenceLogin));
        
                    console.log("User punched in successfully:", attendanceResponse.data.AttendenceLogin.response);
                    dispatch(setLoader(false))
                } catch (error) {
                    console.error("Error during punch-in:", error);
                    Swal.fire("Error", "Failed to punch in. Please try again.", "error");
                 }
            });
        } else {
            dispatch(setAttendence(lastAttendance));
            dispatch(setLastRecord(lastAttendance));
        }
    }        
    const handleLoginSubmit = async (event) => {
        event.preventDefault();

        if (loginLoading) return;

        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email || !emailPattern.test(email)) {
            setError('Please enter a valid email address.');
            return;
        }

        if (password.length < 6) {
            setError('Password must be at least 6 characters long.');
            return;
        }

        const encryptedPassword = encryptPassword(password);

        try {
            await authLogin({ variables: { email, password: encryptedPassword } });
            
            setIsLoggedIn(true);
        } catch (error) {
            console.error("Login failed:", error);
            setError("Login failed, please try again.");
        }
    
    };


    const [createPassword] = useMutation(AUTH_RESET_PASSWORD, {
        onCompleted(data) {
            setLoading(false);
            if (data && data.createpassword) {
                if (data.createpassword.status === 200) {
                    setSuccessMessage('Password reset successfully!');
                    setError('');
                    history('/');
                } else {
                    setError(data.createpassword.message || 'Reset error');
                    setSuccessMessage('');
                }
            } else {
                setError('Unexpected response from server');
                setSuccessMessage('');
            }
        }
    });

    const handleResetSubmit = async (event) => {
        event.preventDefault();
        setError('');
        setSuccessMessage('');

        if (newPassword.length < 6) {
            setError('New password must be at least 6 characters long.');
            return;
        }

        if (newPassword !== confirmPassword) {
            setError('Passwords do not match!');
            return;
        }
        setLoading(true);

        const encryptedNewPassword = encryptPassword(newPassword);

        createPassword({
            variables: {
                email: email,
                token: tokenKey,
                password: encryptedNewPassword,
                isPasswordGenerated: true
            }
        });
    };



    useEffect(() => {
        // localStorage.clear();
        localStorage.removeItem("LoginTime");
        localStorage.removeItem("StartTime");
        localStorage.removeItem("task");
        localStorage.removeItem("time");
        let bg = document.getElementById('bg');
        let deer = document.getElementById('deer');
        let clouds = document.getElementById('clouds');
        let birds = document.getElementById('birds');
        let layer3 = document.getElementById('layer3');
        let layer2 = document.getElementById('layer2');
        let layer1 = document.getElementById('layer1');
        let text = document.getElementById('text');

        window.addEventListener('scroll', () => {
            var value = window.scrollY;
            bg.style.top = value * 0.5 + 'px';
            deer.style.left = value * 0.1 + 'px';
            clouds.style.left = value * 0.2 + 'px';
            birds.style.left = -value * 0.4 + 'px';
            layer3.style.top = value * 0.3 + 'px';
            layer2.style.top = value * 0.1 + 'px';
            text.style.top = value * 0.3 + 'px';
        });
    }, []);

    const handleFormSwitch = (formName) => {
        setActiveForm(formName);
        setError(''); 
        setSuccessMessage(''); 

        setEmail('');
        setPassword('');
        setTokenKey('');
        setNewPassword('');
        setConfirmPassword('');
        setShowPassword(false);
        setShowNewPassword(false);
        setShowConfirmPassword(false);
      };


    return (
        <>
            <section>
                <img src={sky} id="bg" alt="Background" />
                <img src={clouds} id="clouds" alt="Clouds" />
                <img src={birds} id="birds" alt="Birds" />
                <img src={layer3} id="layer3" alt="Layer 3" />
                <img src={layer2} id="layer2" alt="Layer 2" />
                <img src={deer2} id="deer" alt="Deer" />
                <img src={text} id="text" alt="Text" />
                <img src={layer1} id="layer1" alt="Layer 1" />
            </section>

            <div className="mouse-design">
                <div className="scroll-downs">
                    <div className="mousey">
                        <div className="scroller"></div>
                    </div>
                </div>
            </div>

            <section>
                <div className="form-design">
                    <div className="wrapper">
                        {/* Login Form */}
                        {activeForm === 'login' && (
                            <div className="form-box login">
                                <h2>Login</h2>
                                <form onSubmit={handleLoginSubmit}>
                                    {error && <div className="error">{error}</div>}
                                    {successMessage && <div className="success">{successMessage}</div>}
 
                                    <div className="input-box">
                                        {email === '' && <label> Email</label>}
                                        <input
                                            type="text"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                    </div>
                                    <div className="input-box">
                                        {password === '' && <label> Password</label>}
                                        <input
                                            type={showPassword ? 'text' : 'password'}
                                            value={password}
                                            onChange={(e) => setPassword(e.target.value)}
                                            required
                                        />
                                        <span onClick={() => setShowPassword(!showPassword)} style={{ cursor: 'pointer' }}>
                                            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </span>
                                    </div>
                                    <div className="remember-reset">
                                        <label>
                                            <input type="checkbox" />
                                            Remember me
                                        </label>
                                        <Link className="resetToken-link" onClick={() => handleFormSwitch('resetToken')}>
                                            Reset Token
                                        </Link>
                                        <Link className="reset-link" onClick={() => handleFormSwitch('reset')}>
                                            Reset Password
                                        </Link>
                                    </div>
                                    <button type="submit" className="btn-btn">
                                        Login
                                    </button>
                                </form>
                            </div>
                        )}
 
                        {loginLoading && (
                            <div className="loader-container">
                                <Spin size="large" />
                            </div>
                        )}

                        
                        {/* Reset Token */}
                        {activeForm === 'resetToken' && (
                            <div className="form-box resetToken">
                                <h2>Reset Token</h2>
                                <form onSubmit={handleResetToken}>

                                    {error && <div className="error">{error}</div>}
                                    {successMessage && <div className="success">{successMessage}</div>}
 
                                    <div className="input-box">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        {email === '' && <label> Email</label>}
                                    </div>
                                    <div className="remember-reset">
                                        {/* <label>
                                            <input type="checkbox" />I agree to terms & conditions
                                        </label> */}
                                        <Link className="login-link" to="/" onClick={() =>handleFormSwitch('login')}>
                                            Login
                                        </Link>
                                        <Link className="reset-link" onClick={() => handleFormSwitch('reset')}>
                                            Reset Password
                                        </Link>
                                    </div>
                                    <button type="submit" className="btn-btn" disabled={loading}>
                                        {loading ? 'Resetting...' : 'Reset'}
                                    </button>
                                </form>
                            </div>
                        )}
 
 
                        {/* Reset Password Form */}
                        {activeForm === 'reset' && (
                            <div className="form-box reset">
                                <h2>Reset Password</h2>
                                <form onSubmit={handleResetSubmit}>

                                    {error && <div className="error">{error}</div>}
                                    {successMessage && <div className="success">{successMessage}</div>}
 
                                    <div className="input-box">
                                        <input
                                            type="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            required
                                        />
                                        {email === '' && <label> Email</label>}
                                    </div>
                                    <div className="input-box">
                                        <input
                                            className="token"
                                            type="text"
                                            value={tokenKey}
                                            onChange={(e) => setTokenKey(e.target.value)}
                                            required
                                        />
                                        {tokenKey === '' && <label> Token Key</label>}
                                    </div>
                                    <div className="input-box">
                                        <input
                                            type={showNewPassword ? 'text' : 'password'}
                                            value={newPassword}
                                            onChange={(e) => setNewPassword(e.target.value)}
                                            required
                                        />
                                        {newPassword === '' && <label> New Password</label>}
                                        <span onClick={() => setShowNewPassword(!showNewPassword)} style={{ cursor: 'pointer', }}>
                                            {showNewPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </span>
                                    </div>
                                    <div className="input-box">
                                        <input
                                            type={showConfirmPassword ? 'text' : 'password'}
                                            value={confirmPassword}
                                            onChange={(e) => setConfirmPassword(e.target.value)}
                                            required
                                        />
                                        {confirmPassword === '' && <label> Confirm Password</label>}
                                        <span onClick={() => setShowConfirmPassword(!showConfirmPassword)} style={{ cursor: 'pointer' }}>
                                            {showConfirmPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
                                        </span>
                                    </div>
                                    <div className="remember-reset">
                                        <label>
                                            <input type="checkbox" />I agree to terms & conditions
                                        </label>
                                        <Link className="login-link" onClick={() => handleFormSwitch('resetToken')}>
                                            Reset Token
                                        </Link>
                                        <Link className="login-link" onClick={() => handleFormSwitch('login')}>
                                            Login
                                        </Link>
                                    </div>
                                    <button type="submit" className="btn-btn" disabled={loading}>
                                        {loading ? 'Resetting...' : 'Reset'}
                                    </button>
                                </form>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </>
    );
};

export default LoginN;










