import React from "react";
import { Link, useParams } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { useSelector } from "react-redux";
// TableRow component to display individual contact details in a table row
const TableRow = ({ title, relationship, phoneNumber }) => (
  <tr>
    <td className="text-center">{title}</td>
    <td className="text-center">{relationship}</td>
    <td className="text-center">{phoneNumber}</td>
  </tr>
);

const EmergencyContacts = () => {
  const Contacts =useSelector((state)=>state.employee.emergencyContacts)
   const { Empid } = useParams();
  return (
    <div id="emergency_contact" className="card profile-box flex-fill">
      <div className="card-body">
        <h3 className="card-title">
          Emergency Contacts
          {Empid ?<Link
            to="#"
            className="edit-icon"
            data-bs-toggle="modal"
            data-bs-target="#emergency_contact_modal"
          >
            <i className="fa fa-pencil" />
          </Link> : null}
        </h3>
        <div className="table-responsive">
          <table className="table table-nowrap">
            <thead>
              <tr>
                <th className="text-center">Name</th>
                <th className="text-center">Relationship</th>
                <th className="text-center">Phone</th>  
              </tr>
            </thead>
            <tbody>
              {Contacts?.map((item, index) => (
                <TableRow
                  key={`contact-${index}`}
                  title={item.name}
                  relationship={item.relationship}
                  phoneNumber={item.phoneNumber}
                  dob={item.dob}
                  age={item.age}
                  dependent={item.dependent}
                />
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <PersonalInformationModelPopup />
      
     <PersonalInformationModelPopup  />
    </div>
  );
};

export default EmergencyContacts;
