import { useState, useEffect } from "react";
import SaleDropdown from "../../../components/SaleDropdown";
import { timerHistoryPayloadChange } from "../../../Slices/user";
import { settaskStartTime } from "../../../Slices/user";
import { Starttask } from "../../../Slices/timerslice";
import * as React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useQuery, useMutation } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import FetchWithAi from "./FetchWithAi";
import { setRefreshStatus, setSale, setSalesWorkHistory } from "../../../Slices/salesSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import { addLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import {
  UPDATE_REQUEST,
  GET_ALL_ORDERS,
  GET_REQUESTS_BY_ID,
  INSERT_ORDERS,
  UPDATE_WORKHISTORY,
  GET_WORKHISTORY,
  GET_WORKHISTORY_BY_ID,
  GET_ALLWORKHISTORY_BY_ID,
  CREATE_STAUS_WORKHISTORY,
} from "../../../Queries/Orders";

import * as DataBucket from "../../../databucket";
import { memo } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { useErrorLogger } from "../../../utils";
import { message, Spin } from "antd";

import SaleInformation2 from "./SaleInformation2";
import CallingTrack from "./CallingTrack";
import EmailTrack from "./EmailTrack";
import LinkedinTrack from "./LinkedinTrack";
import SchedulingCall from "./SchedulingCall";
import OperationsTeam from "./OperationsTeam";
import { GET_ALL_USERS, GET_USER_DETAILS } from "../../../Queries/Users";
import {
  CREATE_LEAD_WORKHISTORY,
  GET_LEAD_BY_ID,
  UPDATE_LEAD,
} from "../../../Queries/Leads";
import AttachemntsHistory from "./AttachmentHistory";
import RequestWorkHistory from "./RequestWorkHistory";
import { Modal, Button, Tabs, Form, Row, Col, Input, Space } from "antd";
import SalesInformation from "../Sales/SalesInformation";
import SalesInformationPopup from "../../../components/modelpopup/SalesInformationPopup";
import Swal from "sweetalert2";
import dayjs from "dayjs";
const { TabPane } = Tabs;

const validationSchema = Yup.object({
  agencyName: Yup.string()
    .required('Agency Name is required'),
  // city: Yup.string()
  //   .test("is-string", "City must be a valid string", (value) =>
  //     value ? typeof value === "string" && isNaN(Number(value)) : true
  //   )
  //   .nullable(), // Makes it optional and allows null values
  // state: Yup.string()
  //   .test("is-string", "State must be a valid string", (value) =>
  //     value ? typeof value === "string" && isNaN(Number(value)) : true
  //   )
  //   .nullable(),

  // Leads Contacts Array Validation
  leadsContacts: Yup.array().of(
    Yup.object({
      // contactPerson: Yup.string()
      //   .matches(
      //     /^[A-Za-z\s/]+$/,
      //     "Contact Person must contain only letters and spaces"
      //   ),

      // title: Yup.string()
      //   .matches(
      //     /^[A-Za-z\s/]+$/,
      //     "Title must contain only letters and spaces"
      //   ),
      email: Yup.string()
        .trim()
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
        .email('Invalid email format'),

      // phone: Yup.string().matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0'),
      // remarks: Yup.string(), // No validation for remarks
    })
  ),

  // leadsCalls: Yup.array().of(
  //   Yup.object().shape({
  //     status: Yup.string().required('Call Status is required'), // Ensure status is included
  //     spokeWith: Yup.string().when('status', (status, schema) => 
  //       status === 'Attempt' ? schema.required('Spoke with is required') : schema
  //     )
  //   })
  // ),

  leadsEmails: Yup.array().of(
    Yup.object({
      emailedto: Yup.string()
        .trim()
        .email("Must be a valid email")
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
      // .required("Emailed to is required"),
      // status: Yup.string().required("Status is required"),
    })
  ),
  // leadsLinkedins: Yup.array().of(
  //   Yup.object().shape({
  //     requestTo: Yup.string()
  //       .required("Request To is required")
  // .matches(
  //   /^[A-Za-z\s]+$/,
  //   "Request To must contain only letters and spaces"
  // ),

  //     status: Yup.string().required("Status is required"),
  //   })
  // ),

  leadsScheduledCalls: Yup.array().of(
    Yup.object().shape({
      emails: Yup.string()
        .trim()
        .email("Invalid email format")
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
      //   .test(
      //     "email-or-phone",
      //     "Either email or phone number must be provided",
      //     function (value) {
      //       const { phonenumber } = this.parent;
      //       return !!value || !!phonenumber; // Validation passes if either is filled
      //     }
      //   ),

      // phonenumber: Yup.string()
      //   .matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0')
      //   .test(
      //     "email-or-phone",
      //     "Either email or phone number must be provided",
      //     function (value) {
      //       const { emails } = this.parent;
      //       return !!value || !!emails; // Validation passes if either is filled
      //     }
      //   ),
    })
  ),
  leadsOPS: Yup.array().of(
    Yup.object().shape({
      emails: Yup.string()
        .trim()
        .email("Invalid email format") // Validate email format
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/, "Invalid email format")
      //   .test(
      //     "email-or-phone",
      //     "Either email or phone number must be provided",
      //     function (value) {
      //       const { phonenumber } = this.parent; // Access the other field (phonenumber)
      //       return !!value || !!phonenumber; // Validation passes if either emails or phonenumber is filled
      //     }
      //   ),

      // phonenumber: Yup.string()
      //   .matches(/^[1-9][0-9]{9}$/, 'Phone number must be 10 digits and cannot start with 0')
      //   .test(
      //     "email-or-phone",
      //     "Either email or phone number must be provided",
      //     function (value) {
      //       const { emails } = this.parent; // Access the other field (emails)
      //       return !!value || !!emails; // Validation passes if either emails or phonenumber is filled
      //     }
      //   ),
    })
  ),
});

const SaleDetails = memo((props) => {
  const { handleClose } = props;
  const [open, setOpen] = useState(false);
  const { id } = useParams();

  const dispatch = useDispatch();
  const { logError } = useErrorLogger();

  const [age, setAge] = useState("");
  const [value, setValue] = useState("");
  const [employeeDetails, setEmployeeDetails] = React.useState({});

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const initialTime = 0;
  const [elapsedTime, setElapsedTime] = useState(0);
  const [workHistoryId, setWorkHistoryId] = useState("");
  const [startTime, setStartTime] = useState("");
  const [isRunning, setIsRunning] = useState(false);
  const requestId = useSelector((state) => state.timer.requestId);
  let intervalId = null;

  const [tabValue, setTabValue] = useState("0");

  const usersList = useSelector((state) => state.employee.AllEmployees);
  const assignToUsers = usersList.filter(
    user => user.employeestatus === "Active" && user.department !== "Operations"
  );
  const existingWorkHistory = useSelector((state) => state.sales.salesWorkHistory);

  const tabChange = (key) => {
    setTabValue(key);
  };
  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const userid = parseJwt(token)?.id;

  const [updateWorkHistory, { }] = useMutation(UPDATE_WORKHISTORY, {
    onCompleted(data) {
      setIsRunning(false);
    },
  });

  const [newLead, setNewLead] = useState({});
  const [getLeadById, { loading }] = useMutation(GET_LEAD_BY_ID, {
    onCompleted(data) {
      dispatch(setSale(data?.getLeadById[0]))
      const SalesInfo = data?.getLeadById[0]
      dispatch(addLastContactedDate(dayjs(parseInt(SalesInfo.lastContactedDate))))

      const initial = {
        id: SalesInfo?.id,
        leadID: SalesInfo?.leadID,
        createdDate: SalesInfo?.createdDate,
        createdBy: SalesInfo?.createdBy,
        lastUpdatedDate: SalesInfo?.lastUpdatedDate,
        lastContactedDate: SalesInfo?.lastContactedDate,
        followup: SalesInfo?.followup,
        followupDate: SalesInfo?.followupDate || '',
        callscheduledwithOPs: SalesInfo?.callscheduledwithOPs,
        assign: SalesInfo?.assign,
        assignedTo: SalesInfo?.assignedTo,
        assignTo: SalesInfo?.assignTo,
        priority: SalesInfo?.priority,
        status: SalesInfo?.status,
        stage: SalesInfo?.stage,
        channel: SalesInfo?.channel,
        successfull: SalesInfo?.successfull,
        agencyName: SalesInfo?.agencyName || '',
        address: SalesInfo?.address,
        city: SalesInfo?.city,
        state: SalesInfo?.state,
        timeZone: SalesInfo?.timeZone || '',
        webURL: SalesInfo?.webURL,
        contactInfoURL: SalesInfo?.contactInfoURL,
        linkedinURL: SalesInfo?.linkedinURL || '',
        contactInfoComments: SalesInfo?.contactInfoComments,
        callingComments: SalesInfo?.callingComments,
        emailComments: SalesInfo?.emailComments,
        linkedinComments: SalesInfo?.linkedinComments,
        scheduledCallComments: SalesInfo?.scheduledCallComments,
        opsTeamComments: SalesInfo?.opsTeamComments,
        leadsContacts: SalesInfo?.leadsContacts,
        leadsCalls: SalesInfo?.leadsCalls,
        leadsEmails: SalesInfo?.leadsEmails,
        leadsLinkedins: SalesInfo?.leadsLinkedins,
        leadsScheduledCalls: SalesInfo?.leadsScheduledCalls,
        leadsOPS: SalesInfo?.leadsOPS,
      }

      setNewLead(initial)

    },
  });

  dispatch(setLoader(loading));

  const [getWorkHistoryID, { }] = useMutation(GET_WORKHISTORY_BY_ID, {
    onCompleted(data) {
      let datacontent = data.getWorkHistoryID;
      if (datacontent.length > 0) {
        setWorkHistoryId(datacontent[0]?.id)
        setStartTime(datacontent[0].startTime)
        setIsRunning(true);
      }
    },
  });

  const [createLeadWorkHistory, { }] = useMutation(CREATE_LEAD_WORKHISTORY, {
    onCompleted(data) {
      console.log("workhistory", data.createLeadWorkHistory)
      const newHistory = data.createLeadWorkHistory
      dispatch(setSalesWorkHistory([...existingWorkHistory, newHistory]))
    },
  });

  useEffect(() => {
    const valuesObject = {
      id: id,
    };
    getLeadById({ variables: valuesObject });
    dispatch(setRefreshStatus(null));
  }, []);

  const SalesInfo = useSelector((state) => state?.sales?.sale);

  const formatTime = (time) => {
    const seconds = Math.floor(time / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);
    const months = Math.floor(days / 30);
    const years = Math.floor(months / 12);

    const formattedTime = [
      years > 0 ? `${years} year${years > 1 ? "s" : ""}` : "",
      months > 0 ? `${months} month${months > 1 ? "s" : ""}` : "",
      days > 0 ? `${days} day${days > 1 ? "s" : ""}` : "",
      `${hours % 24}:${minutes % 60}:${seconds % 60}`,
    ]
      .filter(Boolean)
      .join(" ");

    return formattedTime;
  };

  const steps = ["Request Details", "Attachment History", "Work History"];

  const isStepOptional = (step) => {
    return step === 1; // set optional stage
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const refreshStatus = useSelector((state) => state.sales.refreshStatus);

  const cancelHandelar = () => {
    navigate("/sales");
    dispatch(setLastContactedDates([]))
    if (refreshStatus == null) {
      dispatch(setRefreshStatus(true));
    }
    dispatch(setSale({}));
  };

  const initialValues = {
    id: SalesInfo?.id,
    leadID: SalesInfo?.leadID,
    createdDate: SalesInfo?.createdDate,
    createdBy: SalesInfo?.createdBy,
    lastUpdatedDate: SalesInfo?.lastUpdatedDate,
    lastContactedDate: SalesInfo?.lastContactedDate,
    followup: SalesInfo?.followup,
    followupDate: SalesInfo?.followupDate || "",
    callscheduledwithOPs: SalesInfo?.callscheduledwithOPs,
    assign: SalesInfo?.assign,
    assignedTo: SalesInfo?.assignedTo,
    assignTo: SalesInfo?.assignTo,
    priority: SalesInfo?.priority,
    status: SalesInfo?.status,
    stage: SalesInfo?.stage,
    channel: SalesInfo?.channel,
    successfull: SalesInfo?.successfull,
    agencyName: SalesInfo?.agencyName || "",
    address: SalesInfo?.address,
    city: SalesInfo?.city,
    state: SalesInfo?.state,
    timeZone: SalesInfo?.timeZone || "",
    webURL: SalesInfo?.webURL,
    contactInfoURL: SalesInfo?.contactInfoURL,
    linkedinURL: SalesInfo?.linkedinURL || "",
    contactInfoComments: SalesInfo?.contactInfoComments,
    callingComments: SalesInfo?.callingComments,
    emailComments: SalesInfo?.emailComments,
    linkedinComments: SalesInfo?.linkedinComments,
    scheduledCallComments: SalesInfo?.scheduledCallComments,
    opsTeamComments: SalesInfo?.opsTeamComments,
    leadsContacts: SalesInfo?.leadsContacts,
    leadsCalls: SalesInfo?.leadsCalls,
    leadsEmails: SalesInfo?.leadsEmails,
    leadsLinkedins: SalesInfo?.leadsLinkedins,
    leadsScheduledCalls: SalesInfo?.leadsScheduledCalls,
    leadsOPS: SalesInfo?.leadsOPS,
  };
  const getTimeZoneCode = (timezone) => {

    if (timezone?.length == 3) {
      return timezone;
    } else {
      switch (timezone) {
        case "Eastern Time":
          return "EST";
        case "Central Time":
          return "CST";
        case "Mountain Time":
          return "MST";
        case "Pacific Time":
          return "PST";
        case "Hawaii Time":
          return "HST";
      }
    }
  };

  const [isDisabled, setIsDisabled] = useState(false);


  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 5000);
  };

  const submit = async (values) => {

    try {
      if (JSON.stringify(newLead) === JSON.stringify(values)) {
        startTimer();
        message.error({
          content: "No changes detected. Please update the fields before submitting.",
          duration: 3,
          className: 'custom-toast-center', // Custom warning styling
        });
        return;
      }

      startTimer();
      setNewLead(values)

      const valuesObject = {
        id: SalesInfo?.id,
        leadID: values?.leadID,
        createdDate: values?.createdDate || '',
        createdBy: values?.createdBy || '',
        lastUpdatedDate: Date.now() + '' || '',
        lastContactedDate: values?.lastContactedDate || '',
        followup: values?.followup || false,
        // followupDate: (values?.followup == true && values?.followupDate == null) ? new Date().toISOString() : values?.followupDate,
        followupDate: values?.followupDate || '',
        callscheduledwithOPs: values?.callscheduledwithOPs || false,
        assign: values?.assign || false,
        assignedTo: values?.assignedTo || '',
        assignTo: values?.assignTo || '',
        priority: values?.priority || '',
        status: values?.status || '',
        stage: values?.stage || '',
        channel: values?.channel || '',
        successfull: values?.successfull || false,
        agencyName: values.agencyName || '',
        address: values.address || '',
        city: values.city || '',
        state: values.state || '',
        timeZone: getTimeZoneCode(values?.timeZone) || '',
        webURL: values?.webURL || '',
        contactInfoURL: values?.contactInfoURL || '',
        linkedinURL: values?.linkedinURL || '',
        contactInfoComments: values?.contactInfoComments || '',
        callingComments: values?.callingComments || '',
        emailComments: values?.emailComments || '',
        linkedinComments: values?.linkedinComments || '',
        scheduledCallComments: values?.scheduledCallComments || '',
        opsTeamComments: values?.opsTeamComments || '',
        leadsContacts: values?.leadsContacts?.map(({ __typename, ...rest }) => rest),
        leadsCalls: values?.leadsCalls?.map(({ __typename, ...rest }) => rest),
        leadsEmails: values?.leadsEmails?.map(({ __typename, ...rest }) => rest),
        leadsLinkedins: values?.leadsLinkedins?.map(({ __typename, ...rest }) => rest),
        leadsScheduledCalls: values?.leadsScheduledCalls?.map(({ __typename, ...rest }) => rest),
        leadsOPS: values?.leadsOPS?.map(({ __typename, ...rest }) => rest),
      }

      updateLead({ variables: valuesObject });
      dispatch(setSale(valuesObject));
    } catch (error) {

      await logError("SalesInformationPopup", "submit", error.message);

      // Log the error in the console for development
      console.error("Error updating lead:", error);

      // Show an error message to the user
      message.error({
        content: "Failed to update lead. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center", // Custom error styling
      });
    }
  };

  const [updateLead, { error }] = useMutation(UPDATE_LEAD, {
    onCompleted(data) {
      const historyObject = {
        leadId: SalesInfo?.id,
        userName: getBy(undefined),
        action: "Updated",
        leadStatus: "Updated",
        changeTime: Date.now().toString(),
      };

      createLeadWorkHistory({ variables: historyObject });

      dispatch(setRefreshStatus(false));
      message.success({
        content: "Lead updated successfully!",
        duration: 5,
        className: "custom-toast-center", // Custom success styling
      });
    },
    onError(error) {
      // Handle error here
      setSetPopupStatus(true);
      setSubmitStatus("An error occurred. Please try again."); // Set your error message
    },
  });

  const [popupStatus, setSetPopupStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const handleOpen = () => {
    setOpen(true);
  };
  const handleStop = async () => {
    setIsRunning(false);
    setElapsedTime(initialTime);
    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),
      endTime: Date.now().toString(),
      requestId: "",
    };
    //setRefetch(true);
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
    await updateWorkHistory({
      variables: {
        userId: parseJwt(token).id,
        emailId: "",
        requestId: "",
        clientName: "Bluepalnit",
        processType: "",
        taskType: "Available",
        email: "",
        subject: "",
        body: "",
        startTime: Date.now().toString(),
        endTime: "",
      },
    });
    const payload = {
      // department: values.processType,
      task: "Available",
      time: Date.now(),
      department: "",
      requestId: ''
    };
    localStorage.setItem("StartTime", new Date().getTime());
    localStorage.removeItem("department");
    localStorage.removeItem("requestId");
    localStorage.removeItem("task");
    dispatch(settaskStartTime(new Date().getTime()));
    dispatch(Starttask(payload));
  };
  const handleDropdownClose = () => {
    setOpen(false);
  };
  const handleDialogClose = () => {
    setSetPopupStatus(false);
  };
  const getBy = (userby) => {
    let callbyuser = "";
    if (userby == "") {
      return callbyuser;
    } else if (userby == undefined) {
      let calluser = assignToUsers.filter(
        (item) => item?.id == parseJwt(token)?.id
      );
      if (calluser?.length > 0) {
        callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname;
      }
      return callbyuser;
    } else {
      let calluser = assignToUsers.filter(
        (item) =>
          item.firstname.toLowerCase() + " " + item.lastname.toLowerCase() ==
          userby?.toLowerCase()
      );
      if (calluser?.length == 0) {
        calluser = assignToUsers.filter(
          (item) => item.firstname.toLowerCase() == userby?.toLowerCase()
        );
      }
      if (calluser?.length > 0) {
        callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname;
      }

      return callbyuser;
    }
  };

  return (
    <>
      <Modal
        visible={popupStatus}
        onCancel={handleDialogClose}
        footer={[
          <Button key="close" onClick={handleDialogClose} type="primary">
            OK
          </Button>,
        ]}
      >
        <h2>Submit Status</h2>
        <p>{submitStatus}</p>
      </Modal>

      <div className="page-wrapper">
        <div className="content container-fluid">
          <div style={{ width: "100%" }}>
            {Object.keys(SalesInfo).length > 0 && (
              <Formik
                initialValues={initialValues}
                onSubmit={submit}
                validationSchema={validationSchema}
                validateOnChange={true}
              >
                {({
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  values,
                  setFieldValue,
                  errors,
                  touched,
                  setFieldTouched
                }) => (
                  <>
                    {/* <pre>Errors: {JSON.stringify(errors, null, 2)}</pre> */}
                    <form noValidate onSubmit={handleSubmit}>
                      <Row gutter={16}>
                        <Col
                          span={24}
                          style={{
                            marginTop: "20px",
                            justifyContent: "space-between",
                            display: "flex",
                          }}
                          align="end"
                        >
                          <Space>
                            <Button color='primary' variant='solid' htmlType="submit" disabled={isDisabled}>
                              Update
                            </Button>
                            <Button
                              color="danger"
                              variant="solid"
                              onClick={cancelHandelar}>
                              Close
                            </Button>
                          </Space>
                          {isRunning || requestId == SalesInfo?.leadID ? (
                            <Button
                              color="danger"
                              variant="solid"
                              // className="btn btn-info"
                              onClick={handleStop}
                              style={{
                                float: "right",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              Stop
                            </Button>
                          ) : (
                            <Button
                              // className="btn btn-info"
                              color='primary' variant='solid'
                              style={{
                                float: "right",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: '20px'
                              }}
                              onClick={handleOpen}
                            >
                              Start
                            </Button>
                          )}

                        </Col>
                      </Row>

                      {open && (
                        <SaleDropdown
                          open={open}
                          handleClose={handleDropdownClose}
                          handleStart={handleOpen}
                          SalesInfo={SalesInfo}
                          setWorkHistoryId={setWorkHistoryId}
                        />
                      )}
                      <SaleInformation2
                        initialValues={initialValues}
                        employeeDetails={employeeDetails}
                        setEmployeeDetails={setEmployeeDetails}
                        SalesInfo={SalesInfo}
                        setFieldValue={setFieldValue}
                        assignToUsers={assignToUsers}
                        id={id}
                      />

                      <Tabs
                        activeKey={String(tabValue)}
                        onChange={tabChange}
                        style={{ marginBottom: "10px" }}
                        tabPosition="top"
                      >
                        <TabPane tab="Calling Track" key="0" />
                        <TabPane tab="Email Track" key="1" />
                        <TabPane tab="Linkedin Track" key="2" />
                        <TabPane tab="Scheduling a Call" key="3" />
                        <TabPane tab="Operations Team Communication" key="4" />
                        <TabPane tab="Attachment History" key="5" />
                        <TabPane tab="Work History" key="6" />
                        <TabPane tab="Fetch With AI" key="7" />
                      </Tabs>

                      {tabValue === "0" && (
                        <CallingTrack
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                          getBy={getBy}
                          setFieldValue={setFieldValue}
                          id={id}
                          leadID={SalesInfo?.leadID}
                        />
                      )}
                      {tabValue === "1" && (
                        <EmailTrack
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                          getBy={getBy}
                          setFieldValue={setFieldValue}
                          id={id}
                          leadID={SalesInfo?.leadID}
                        />
                      )}
                      {tabValue === "2" && (
                        <LinkedinTrack
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                          getBy={getBy}
                          setFieldValue={setFieldValue}
                          id={id}
                          leadID={SalesInfo?.leadID}
                        />
                      )}
                      {tabValue === "3" && (
                        <SchedulingCall
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                          getBy={getBy}
                          setFieldValue={setFieldValue}
                          id={id}
                        />
                      )}
                      {tabValue === "4" && (
                        <OperationsTeam
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                          getBy={getBy}
                          setFieldValue={setFieldValue}
                          id={id}
                        />
                      )}
                      {tabValue === "5" && <AttachemntsHistory />}
                      {tabValue === "6" && (
                        <RequestWorkHistory
                          initialValues={initialValues}
                          SalesInfo={SalesInfo}
                          assignToUsers={assignToUsers}
                        />
                      )}
                      {tabValue === "7" && <FetchWithAi />}
                    </form>
                  </>
                )}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
});

export default SaleDetails;
