import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { Spin, Modal } from 'antd';
import { MdCloudDownload, MdMoreVert } from 'react-icons/md';
import dayjs from 'dayjs';
import { GET_ALLWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, GET_ALL_MODIFIED_ATTACHMENTS_BY_ID, UPLOAD_FILE, UPLOAD_FILES_MUTATION, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS, GET_ALLATTACHMENT_HISTORY_BY_ID } from "../../../Queries/Orders";
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_USERS } from '../../../Queries/Users';
import axios from 'axios';
import { SERVER_URL } from '../../../databucket';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import Link from 'antd/es/typography/Link';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setLoader } from '../../../Slices/loaderSlice';
import { useErrorLogger } from "../../../utils";

export default function AttachemntsHistory(props) {

    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    const { RequestInfo } = props;
    console.log("attachment", RequestInfo)
    const fileInputRef = useRef(null);
    const fileInputRefSingle = useRef(null);
     const { logError } = useErrorLogger();
    const dispatch = useDispatch();
    const [attachmentStatus, setAttachmentStatus] = useState(false)

    const [isUploading, setIsUploading] = useState(false);

    const [attachmentsHistory, setAttachmentsHistory] = useState([])
    console.log("attachments", attachmentsHistory)
    const [modifiedAttachmentsHistory, setModifiedAttachmentsHistory] = useState([])
    const [attachmentworkhistory, setAttachmentWorkhistory] = useState([]);

    const token = localStorage.getItem('token');

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const userid = parseJwt(token).id;

    const { data, } = useQuery(GET_ALL_USERS, {
        variables: { loginUserId: userid },
    });

    let userscontent = data ? data.getAllUsers : [];

    const [createAttachments, { loading }] = useMutation(CREATE_ATTACHEMNTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            // console.log(data, 'dataidddd', data.createWorkHistory.id, data.createWorkHistory._id)
        },
    });
    const [deleteAttachments, { }] = useMutation(DELETE_ATTACHMENTS, {
        onCompleted(data) {
            dispatch(setLoader(true))
            setIsUploading(true)
            setAttachmentStatus(!attachmentStatus);
            setIsUploading(false)

            // console.log('deleteAttachments-->', data)
        },
    });



    // const [uploadFile, { }] = useMutation(UPLOAD_FILE, {
    //     onCompleted(data) {

    //         console.log("FILE----------->", data)
    //     },
    // });

    // const [uploadFiles, { }] = useMutation(UPLOAD_FILES_MUTATION, {
    //     onCompleted(data) {

    //         console.log("FILESss----------->", data)
    //     },
    // });


    // const attachmentObj = {
    //     emailId: RequestInfo?.id,
    // }
    const attachmentObj = {
        emailId: RequestInfo?.id,
    }
    const attachmentHistoryObj = {
        userId: parseJwt(token)?.id,
        emailId: RequestInfo?.id,
    }
    const [updateAttachments, { }] = useMutation(UPDATE_ATTACHMENTS, {
        onCompleted(data) {
            setAttachmentStatus(!attachmentStatus);
            console.log('updateAttachments-->', data)
        },
    });
    const [getAllAttachmentsByID, { }] = useMutation(GET_ALLATTACHMENTS_BY_ID, {
        onCompleted(data) {
            console.log('getAllAttachmentsByID-->', data)
            setAttachmentsHistory(data.getAllAttachmentsByID)
            const allAttachments = data?.getAllAttachmentsByID?.flatMap((employee) =>
                Array.isArray(employee?.attachmentHistory) ? employee.attachmentHistory : [])
            console.log("allAttachments", allAttachments)
            setAttachmentWorkhistory(allAttachments)
        },
        onError(){
            logError("AttachmentsHistory","getAllAttachmentsBId based on id","error while getAllAttachmentsByid")
        }
    });

    // useEffect(()=>
    // {

    // },[data])
    // const [getAllModifiedAttachmentsByID, { }] = useMutation(GET_ALL_MODIFIED_ATTACHMENTS_BY_ID, {
    //     onCompleted(data) {
    //         console.log('getAllModifiedAttachmentsByID-->', data)
    //         // generateAttachmentHistory(data.getAllModifiedAttachmentsByID)
    //         setModifiedAttachmentsHistory(data.getAllModifiedAttachmentsByID)
    //     },
    // });

    const [getAllAttachmentHistoryByID, { }] = useMutation(GET_ALLATTACHMENT_HISTORY_BY_ID, {
        onCompleted(data) {
            console.log('getAllAttachmentHistoryByID-->', data)
            // generateAttachmentHistory(data.getAllModifiedAttachmentsByID)
            setModifiedAttachmentsHistory(data.getAllAttachmentHistoryByID)
        },
    });



    useEffect(() => {
        getAllAttachmentsByID({ variables: attachmentObj });
        // getAllModifiedAttachmentsByID({ variables: attachmentObj })
        getAllAttachmentHistoryByID({ variables: attachmentHistoryObj })

    }, [attachmentStatus]);

    const openFileInputSingle = () => {
        if (fileInputRefSingle.current) {
            fileInputRefSingle.current.value = null;
            fileInputRefSingle.current.click();
        }
    };

    // const generateAttachmentHistory = (attachMentHistory) => {
    //     let finalAttachmentHistory = []
    //     let attachMentHistoryTemp = []
    //     attachMentHistoryTemp.push(...attachMentHistory);
    //     attachMentHistoryTemp?.map((attachemnts) => {
    //         let attachemntsTemp = attachemnts;
    //         attachemntsTemp.status = 'Created'
    //         attachemntsTemp.updateDate = attachemnts.addDate
    //         finalAttachmentHistory.push(attachemntsTemp)
    //     })
    //     attachMentHistoryTemp?.map((attachemnts) => {
    //         let attachemntsTemp = attachemnts;
    //         console.log('attachMentHistoryattachMentHistory', attachMentHistoryTemp,)

    //         if (attachemnts.status) {
    //             if (attachemnts.addDate != attachemnts.updateDate) {

    //                 attachemntsTemp.status = 'Updated'
    //                 finalAttachmentHistory.push(attachemntsTemp)
    //             }
    //         } else {
    //             attachemntsTemp.status = 'Deleted'
    //             finalAttachmentHistory.push(attachemntsTemp)
    //         }

    //     })

    //     console.log('attachMentHistoryattachMentHistory', attachMentHistory, attachMentHistoryTemp, finalAttachmentHistory)

    //     return finalAttachmentHistory;
    // }

    // const handleSingleAttachment = (event, index) => {

    //     // console.log("-----------------------")
    //     const selectedFile = event.target.files[0];

    //     const formData = new FormData();
    //     formData.append('files', selectedFile);

    //     axios.post(SERVER_URL + '/upload',
    //         formData)
    //         .then((res) => {


    //             console.log(res.data);
    //             let savedFile = res.data.files[0];

    //             const valuesObject = {
    //                 userId: parseJwt(token)?.id,
    //                 emailId: RequestInfo?.id,

    //                 data: savedFile.url,
    //                 filename: savedFile.originalName,
    //                 mimetype: savedFile.mimeType,
    //                 addDate: Date.now().toString(),
    //                 updateDate: Date.now().toString(),
    //                 status: true,
    //             }

    //             // console.log('handleSingleAttachment-->', valuesObject)

    //             createAttachments({ variables: valuesObject })

    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });

    //     setEmployeeDetails({ ...employeeDetails, ['Attachment']: event.target.files[0] })
    // };
    const handleSingleAttachment = (event, index) => {
        try{
            dispatch(setLoader(true));
        const selectedFile = event.target.files[0];
        const formData = new FormData();
        formData.append('files', selectedFile);

        //setIsUploading(true); // Start loading

        axios.post(SERVER_URL + '/upload', formData)
            .then((res) => {
                let savedFile = res.data.files[0];
                const valuesObject = {
                    userId: parseJwt(token)?.id,
                    emailId: RequestInfo?.id,
                    data: savedFile.url,
                    filename: savedFile.originalName,
                    mimetype: savedFile.mimeType,
                    addDate: Date.now().toString(),
                    updateDate: Date.now().toString(),
                    status: true,
                };

                createAttachments({ variables: valuesObject });

                dispatch(setLoader(false));
            })
            .catch((error) => {
                console.error(error);
                setIsUploading(false); // Stop loading if error occurs
            });

        setEmployeeDetails({ ...employeeDetails, ['Attachment']: event.target.files[0] });
        }
        catch(error){
            logError("AttachmentsHistory","upload single Attachments",error.message)
        }
    };


    const openFileInput = () => {
        if (fileInputRef.current) {
            fileInputRef.current.value = null;
            fileInputRef.current.click();
        }
    };

    const handleMultipleAttachment = (event, index) => {
        console.log('---------------------------->>>>>>>>>')
        try{
            dispatch(setLoader(true))
            const multipleFiles = Array.from(event.target.files);
            const formData = new FormData();
            multipleFiles.forEach((file) => {
                formData.append('files', file);
            });
    
            axios.post(SERVER_URL + '/upload', formData)
                .then((res) => {
                    console.log(res.data);
    
                    let uploadedFiles = res.data.files
                    uploadedFiles.map((uploadedItem) => {
    
                        const valuesObject = {
                            userId: parseJwt(token)?.id,
                            emailId: RequestInfo?.id,
    
                            data: uploadedItem.url,
                            filename: uploadedItem.originalName,
                            mimetype: uploadedItem.mimeType,
                            addDate: Date.now().toString(),
                            updateDate: Date.now().toString(),
                            status: true,
                        }
    
                        // console.log('handleSingleAttachment-->', valuesObject)
    
                        createAttachments({ variables: valuesObject })
                        dispatch(setLoader(false))
                    })
    
                })
                .catch((error) => {
                    console.error(error);
                });
        }
        catch(error){
            logError("AttachmentsHistory","upload multiple Attachments",error.message)
        }
    };

    const handleupadteAttachment = (event) => {
        return event.target.files[0];
    }
    const updateHandler = async (attachemntId) => {
        try{
            const selectedFilePromise = new Promise((resolve, reject) => {
                const inputElement = document.getElementById('updateAttachmentInput');
                inputElement.addEventListener('change', (event) => {
                    const selectedFile = event.target.files[0];
                    resolve(selectedFile);
                });
                inputElement.click();
            });
    
            const selectedFile = await selectedFilePromise;
    
    
            const formData = new FormData();
            formData.append('files', selectedFile);
            dispatch(setLoader(true))
            axios.post(SERVER_URL + '/upload',
                formData)
                .then((res) => {
                    let savedFile = res.data.files[0];
    
                    const valuesObject = {
                        userId: parseJwt(token)?.id,
                        id: attachemntId,
                        data: savedFile.url,
                        filename: savedFile.originalName,
                        mimetype: savedFile.mimeType,
                        updateDate: Date.now().toString(),
                    }
    
                    updateAttachments({ variables: valuesObject })
                    dispatch(setLoader(false))
                })
                .catch((error) => {
                    console.error(error);
                    dispatch(setLoader(false)); // Stop loading if error occurs
                });
        }
        catch(error){
            logError("AttachmentsHistory","update the attachments file",error.message)
        }

    }

    const deleteHandler = (attachemntId) => {
        try{
            dispatch(setLoader(true))
            const valuesObject = {
                userId: parseJwt(token)?.id,
                id: attachemntId,
                updateDate: Date.now().toString(),
            }
            console.log(valuesObject, Date.now().toString())
            deleteAttachments({ variables: valuesObject })
                .then(() => {
                    dispatch(setLoader(false)) // Stop loader
                })
                .catch((error) => {
                    dispatch(setLoader(false)) // Stop loader
                    console.error(error);
                    // notification.error({ message: 'Attachment delete failed', description: error.message }); // Display error notification
                });
        }
       catch(error){
        logError("AttachmentsHistory","delete the attachments file",error.message)
       }
    }


    const handleDownload = (fileUrl, fileName) => {
        // console.log('handleDownload-->', fileUrl, fileName
        try{
            fetch(fileUrl)
            .then(response => response.blob())
            .then(blob => {
                const url = window.URL.createObjectURL(new Blob([blob]));
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName; // Set desired file name
                a.click();
                window.URL.revokeObjectURL(url);
            })
            .catch((error) => {
                console.error('Error downloading file:', error)
            });
        }
        catch(error){
            logError("AttachmentsHistory","download the attachments file",error.message)
        }
    };


    // const handleMultipleAttachmentOLD = async (event, index) => {
    //     try {
    //         const selectedFiles = Array.from(event.target.files);
    //         const files = await Promise.all(selectedFiles.map((file) => file));

    //         const { data } = await uploadFile({ variables: { files } });

    //         console.log(data);


    //     } catch (error) {
    //         console.error(error);
    //         // Handle error
    //     }
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: [event.target.files[0].name] })
    //     // setEmployeeDetails({ ...employeeDetails, ['Attachments']: multipleFiles })
    // };

    const getDateTime = (timeValue) => {
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }

    const getStatus = (status, addDate, updateDate) => {
        let resultData = ""
        if (status) {
            if (addDate === updateDate) {
                resultData = "Created"
            } else {
                resultData = "Deleted"
            }
        } else {
            resultData = "Updated"
        }
        return resultData;
    }
    // const getHistoryStatus = (status) => {
    //     let resultData = ""
    //     if (status === true) {
    //         resultData = "Created"
    //     } else if (status === null) {
    //         resultData = "Updated"
    //     } else if (status === false) {
    //         resultData = "Deleted"
    //     } else {
    //         resultData = ""
    //     }
    //     return resultData;
    // }
    const assignToUsers=useSelector((state)=>state.loginUserInfo.allUsers)
    
    const getUserName = (userid) => {
        let userName = 'anonymous'
        assignToUsers?.map((userdetails) => {
            if (userdetails?.id == userid) {
                userName = userdetails?.firstname + ' ' + userdetails?.lastname;
            }
        })
        return userName;
    }

    return (
        <>
            <div className="col-md-12 d-flex overflow-hidden" >
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            Attachments

                            <div style={{ display: 'flex', gap: '1rem' }}>
                                <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
                                    <input
                                        type="file"
                                        ref={fileInputRefSingle}
                                        onChange={handleSingleAttachment}
                                        style={{ display: 'none' }}
                                    />
                                    <Link onClick={() => fileInputRefSingle.current && fileInputRefSingle.current.click()}>

                                        <i
                                            className="fa-solid fa-file"
                                            style={{
                                                fontSize: "20px",
                                            }}
                                            aria-label="Add Single Attachment"
                                        />
                                    </Link>
                                </Col>

                                <Col style={{ color: "#4A90E2", cursor: "pointer" }}>
                                    <input
                                        type="file"
                                        multiple
                                        ref={fileInputRef}
                                        onChange={handleMultipleAttachment}
                                        style={{ display: 'none' }}
                                    />
                                    <Link onClick={() => fileInputRef.current && fileInputRef.current.click()}>
                                        <i
                                            className="fa-regular fa-folder-open"
                                            style={{
                                                fontSize: "20px",

                                            }}
                                            aria-label="Add Multiple Attachments"
                                        />

                                    </Link>
                                </Col>


                            </div>
                        </h3>
                        {/* {isUploading ? (
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                            <Spin /> 
                        </div>
                    ) : ( */}
                        <Modal
                            visible={isUploading}
                            footer={null}
                            closable={false}
                            width={300}
                            centered
                        >
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Spin size="large" />
                                <p style={{ textAlign: 'center' }}>Uploading...</p>
                            </div>
                        </Modal>
                        {!isUploading && (
                            <Table responsive>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: 'center' }}>Attachments</th>
                                        <th style={{ textAlign: 'center' }}>Add Date</th>
                                        <th style={{ textAlign: 'center' }}>User Name</th>
                                        <th style={{ textAlign: 'center' }}>Actions</th>
                                        <th style={{ textAlign: 'center' }}>Options</th>
                                    </tr>
                                </thead>
                                {/* {isUploading ? (
                                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                    <Spin />
                                </div>
                            ) : ( */}
                                <tbody>
                                    {attachmentsHistory?.map((row, key) => {
                                        if (row.status === false) return null;
                                        return (
                                            <tr key={key}>
                                                <td style={{ textAlign: "center" }}>
                                                    <a href={row.data} target='_blank'>{row.filename}</a>
                                                </td>
                                                <td style={{ textAlign: "center" }}>{getDateTime(parseInt(row.addDate))}</td>
                                                <td style={{ textAlign: "center" }}>{getUserName(row.userId)}</td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Button variant="primary" onClick={() => handleDownload(row.data, row.filename)}>
                                                        <MdCloudDownload /> Download
                                                    </Button>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <Dropdown drop="end">
                                                        <Dropdown.Toggle as="span" style={{ cursor: 'pointer' }}>
                                                            <MdMoreVert size={24} />
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            <>
                                                                <Dropdown.Item onClick={() => updateHandler(row.id)}>
                                                                    Replace
                                                                </Dropdown.Item>
                                                                <input type="file" id="updateAttachmentInput" onChange={handleupadteAttachment} style={{ display: 'none' }} />

                                                            </>

                                                            <Dropdown.Item onClick={() => deleteHandler(row.id)}>
                                                                Delete
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>)
                                    })}
                                </tbody>
                                {/* )} */}

                            </Table>
                        )}
                    </div>
                </div>
            </div>
            <div className="col-md-12 d-flex overflow-hidden" >
                <div className="card profile-box flex-fill">
                    <div className="card-body">
                        <h3 className="card-title" style={{ display: 'flex', alignItems: 'start', justifyContent: 'space-between', fontWeight: 'bold' }}>
                            Attachments History
                        </h3>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Sno</th>
                                    <th style={{ textAlign: 'center' }}>Attachments</th>
                                    <th style={{ textAlign: 'center' }}>Add Date</th>
                                    <th style={{ textAlign: 'center' }}>Updated Date</th>
                                    <th style={{ textAlign: 'center' }}>Username</th>
                                    <th style={{ textAlign: 'center' }}>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {attachmentworkhistory?.map((row, key) => (
                                    <tr key={key}>
                                        <td style={{ textAlign: "center" }}>{key + 1}</td>
                                        <td style={{ textAlign: "center" }}>
                                            <a href={row.data} target="_blank" rel="noopener noreferrer">
                                                {row.filename}
                                            </a>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {getDateTime(parseInt(row.addDate))}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {getDateTime(parseInt(row.updateDate))}
                                        </td>
                                        <td style={{ textAlign: "center" }}>{getUserName(row.userId)}</td>
                                        <td style={{ textAlign: "center" }}>{getStatus(row.status, row.addDate, row.updateDate)}</td>
                                    </tr>

                                ))}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
}
