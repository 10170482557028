import React, { useState, useEffect } from 'react';
import { Field, FieldArray, useFormikContext } from 'formik';
import { Row, Col, Button, Form, Input, Typography } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { setIsRefetch } from '../../../Slices/salesSlice';
import Swal from 'sweetalert2';

const ContactInformationForm = ({
  handleAddRow,
}) => {

  const { values, handleChange, handleBlur, errors, touched } = useFormikContext();
  const dispatch = useDispatch();

  const [contactPerson, setContactPerson] = useState(values.leadsContacts?.map(contact => contact.contactPerson) || []);
  const [titles, setTitles] = useState(values.leadsContacts?.map(contact => contact.title) || []);
  const [phones, setPhones] = useState(values.leadsContacts?.map(contact => contact.phone) || []);
  const [emails, setEmails] = useState(values.leadsContacts?.map(contact => contact.email) || []);
  const [remarks, setRemarks] = useState(values.leadsContacts?.map(contact => contact.remarks) || []);

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setContactPerson([]);
      setTitles([]);
      setPhones([]);
      setEmails([]);
      setRemarks([]);
      setLocalErrors([])
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const [localErrors, setLocalErrors] = useState([]);

  // Local Validation for Email (onChange)
  const handleEmailChange = (e, index) => {
    const email = e.target.value.trim();
    const newEmails = [...emails];
    newEmails[index] = email;

    let errorMessage = "";
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
    if (email && !emailRegex.test(email.trim())) {
      errorMessage = "Invalid email format";
    }

    const newErrors = [...localErrors];
    newErrors[index] = { ...newErrors[index], email: errorMessage };

    setEmails(newEmails);
    setLocalErrors(newErrors);
  };

  // Local Validation for Phone Number (onChange)
  const handlePhoneChange = (e, index) => {
    // let value = e.target.value.replace(/\D/g, "");
    // if (value.length <= 10) {
    const newPhones = [...phones];
    newPhones[index] = e.target.value;

    // Local validation for phone number
    // let errorMessage = "";
    // if (value && value.length !== 10) {
    //   errorMessage = "Phone number must be exactly 10 digits";
    // } else if (value && !/^[1-9][0-9]{9}$/.test(value)) {
    //   errorMessage = "Phone number cannot start with 0";
    // }

    // const newErrors = [...localErrors];
    // newErrors[index] = { ...newErrors[index], phone: errorMessage };

    setPhones(newPhones);
    // setLocalErrors(newErrors);
    // }
  };

  // Local Validation for Contact Person
  const handleContactPersonChange = (e, index) => {
    const value = e.target.value;
    const newContactPerson = [...contactPerson];
    newContactPerson[index] = value;

    // let errorMessage = "";

    // if (value && !/^[A-Za-z\s]+$/.test(value)) {
    //   errorMessage = "Contact Person must contain only letters and spaces";
    // }

    // const newErrors = [...localErrors];
    // newErrors[index] = { ...newErrors[index], contactPerson: errorMessage };

    setContactPerson(newContactPerson);
    // setLocalErrors(newErrors);
  };

  // Local Validation for Title
  const handleTitleChange = (e, index) => {
    const value = e.target.value;
    const newTitles = [...titles];
    newTitles[index] = value;

    // let errorMessage = "";
    // if (value && !/^[A-Za-z\s/]+$/.test(value)) {
    //   errorMessage = "Title must contain only letters and spaces";
    // }

    // const newErrors = [...localErrors];
    // newErrors[index] = { ...newErrors[index], title: errorMessage };

    setTitles(newTitles);
    // setLocalErrors(newErrors);
  };

  // const handleInputChange = (index, field, value) => {
  //   switch (field) {
  //     case 'contactPerson':
  //       const newContactPerson = [...contactPerson];
  //       newContactPerson[index] = value;
  //       setContactPerson(newContactPerson);
  //       break;
  //     case 'title':
  //       const newTitles = [...titles];
  //       newTitles[index] = value;
  //       setTitles(newTitles);
  //       break;
  //     case 'phone':
  //       const newPhones = [...phones];
  //       newPhones[index] = value;
  //       setPhones(newPhones);
  //       break;
  //     case 'email':
  //       const newEmails = [...emails];
  //       newEmails[index] = value;
  //       setEmails(newEmails);
  //       break;
  //     case 'remarks':
  //       const newRemarks = [...remarks];
  //       newRemarks[index] = value;
  //       setRemarks(newRemarks);
  //       break;
  //     default:
  //       break;
  //   }
  // };

  return (


    <FieldArray
      name="leadsContacts"
      render={(arrayHelpers) => (
        <div>
          {values?.leadsContacts?.map((contact, index) => (
            <Row gutter={16} key={index}>
              {/* Contact Person */}
              <Col span={4}>
                <Form.Item
                  label={`Contact Person ${index + 1}`}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  // validateStatus={
                  //   touched?.leadsContacts?.[index]?.contactPerson &&
                  //   errors?.leadsContacts?.[index]?.contactPerson
                  //     ? 'error'
                  //     : ''
                  // }
                  // help={
                  //   touched?.leadsContacts?.[index]?.contactPerson &&
                  //   errors?.leadsContacts?.[index]?.contactPerson
                  // }
                  help={localErrors[index]?.contactPerson || (touched?.leadsContacts?.[index]?.contactPerson && errors?.leadsContacts?.[index]?.contactPerson)}
                  validateStatus={localErrors[index]?.contactPerson || (touched?.leadsContacts?.[index]?.contactPerson && errors?.leadsContacts?.[index]?.contactPerson) ? 'error' : ''}
                >
                  <Input
                    value={contactPerson[index]}
                    // onChange={(e) => {
                    //   const newContactPerson = [...contactPerson];
                    //   newContactPerson[index] = e.target.value;
                    //   setContactPerson(newContactPerson);
                    // }}
                    onChange={(e) => handleContactPersonChange(e, index)}
                    onBlur={(e) => {
                      handleChange({
                        target: {
                          name: `leadsContacts[${index}].contactPerson`,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Title */}
              <Col span={4}>
                <Form.Item
                  label="Title"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  // validateStatus={
                  //   touched?.leadsContacts?.[index]?.title &&
                  //   errors?.leadsContacts?.[index]?.title
                  //     ? 'error'
                  //     : ''
                  // }
                  // help={
                  //   touched?.leadsContacts?.[index]?.title &&
                  //   errors?.leadsContacts?.[index]?.title
                  // }
                  help={localErrors[index]?.title || (touched?.leadsContacts?.[index]?.title && errors?.leadsContacts?.[index]?.title)}
                  validateStatus={localErrors[index]?.title || (touched?.leadsContacts?.[index]?.title && errors?.leadsContacts?.[index]?.title) ? 'error' : ''}
                >
                  <Input
                    value={titles[index]}
                    // onChange={(e) => {
                    //   const newTitles = [...titles];
                    //   newTitles[index] = e.target.value;
                    //   setTitles(newTitles);
                    // }}
                    onChange={(e) => handleTitleChange(e, index)}
                    onBlur={(e) => {
                      handleChange({
                        target: {
                          name: `leadsContacts[${index}].title`,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Phone */}
              <Col span={4}>
                <Form.Item
                  label="Phone"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  // validateStatus={
                  //   touched?.leadsContacts?.[index]?.phone &&
                  //   errors?.leadsContacts?.[index]?.phone
                  //     ? 'error'
                  //     : ''
                  // }
                  // help={
                  //   touched?.leadsContacts?.[index]?.phone &&
                  //   errors?.leadsContacts?.[index]?.phone
                  // }
                  help={localErrors[index]?.phone || (touched?.leadsContacts?.[index]?.phone && errors?.leadsContacts?.[index]?.phone)}
                  validateStatus={localErrors[index]?.phone || (touched?.leadsContacts?.[index]?.phone && errors?.leadsContacts?.[index]?.phone) ? 'error' : ''}
                >
                  <Input
                    value={phones[index]}
                    // onChange={(e) => {
                    //   const value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                    //   if (value.length <= 10) {
                    //     const newPhones = [...phones];
                    //     newPhones[index] = value;
                    //     setPhones(newPhones);
                    //   }
                    // }}
                    onChange={(e) => handlePhoneChange(e, index)}
                    onBlur={(e) => {
                      // handleBlur(e); 
                      handleChange({
                        target: {
                          name: `leadsContacts[${index}].phone`,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Email */}
              <Col span={4}>
                <Form.Item
                  label="Email"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  // help={
                  //   touched?.leadsContacts?.[index]?.email &&
                  //   errors?.leadsContacts?.[index]?.email
                  // }
                  // validateStatus={
                  //   touched?.leadsContacts?.[index]?.email &&
                  //   errors?.leadsContacts?.[index]?.email
                  //     ? 'error'
                  //     : 'success'
                  // }
                  help={localErrors[index]?.email || (touched?.leadsContacts?.[index]?.email && errors?.leadsContacts?.[index]?.email)}
                  validateStatus={localErrors[index]?.email || (touched?.leadsContacts?.[index]?.email && errors?.leadsContacts?.[index]?.email) ? 'error' : ''}
                >
                  <Input
                    value={emails[index]}
                    // onChange={(e) => {
                    //   const newEmails = [...emails];
                    //   newEmails[index] = e.target.value;
                    //   setEmails(newEmails);
                    // }}
                    onChange={(e) => handleEmailChange(e, index)}
                    onBlur={(e) => {
                      handleChange({
                        target: {
                          name: `leadsContacts[${index}].email`,
                          value: e.target.value.trim(),
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Remarks */}
              <Col span={5}>
                <Form.Item
                  label="Remarks"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Input
                    value={remarks[index]}
                    onChange={(e) => {
                      const newRemarks = [...remarks];
                      newRemarks[index] = e.target.value;
                      setRemarks(newRemarks);
                    }}
                    onBlur={(e) => {
                      handleChange({
                        target: {
                          name: `leadsContacts[${index}].remarks`,
                          value: e.target.value,
                        },
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              {/* Delete Button */}
              <Col
                span={2}
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  textAlign: 'right',
                }}
              >
                <i
                  className="fa-regular fa-trash-can"
                  style={{
                    color: '#00c5fb',
                    cursor: 'pointer',
                    fontSize: '16px',
                  }}
                  onClick={() => {
                    Swal.fire({
                      title: 'Are you sure?',
                      text: 'Do you really want to delete this Contact?',
                      icon: 'warning',
                      showCancelButton: true,
                      confirmButtonColor: '#3085d6',
                      cancelButtonColor: '#d33',
                      confirmButtonText: 'Yes, delete it!',
                      cancelButtonText: 'Cancel',
                    }).then((result) => {
                      if (result.isConfirmed) {
                        arrayHelpers.remove(index);

                        // Update local states
                        setContactPerson(prev => prev.filter((_, i) => i !== index));
                        setTitles(prev => prev.filter((_, i) => i !== index));
                        setPhones(prev => prev.filter((_, i) => i !== index));
                        setEmails(prev => prev.filter((_, i) => i !== index));
                        setRemarks(prev => prev.filter((_, i) => i !== index));

                        setLocalErrors((prev) => prev.filter((_, i) => i !== index));
                        // Swal.fire(
                        //   'Deleted!',
                        //   'Your item has been deleted.',
                        //   'success'
                        // );
                      }
                    });
                  }}
                />
              </Col>
            </Row>
          ))}
          <Row justify="end" style={{ marginTop: '16px' }}>
            <Button type="primary" onClick={() => handleAddRow(arrayHelpers)}>
              Add Row
            </Button>
          </Row>
        </div>
      )}
    />



  );
};

export default ContactInformationForm;
