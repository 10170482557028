import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { SINGLE_USER } from '../../Queries/Employees';
import { UPDATE_EMP_LEAVE_SETTINGS } from '../../Leaves/Leaves';
import { setFilteredLeaveData } from '../../Slices/employeeslice';
import { useSelector, useDispatch } from 'react-redux';
import { message } from 'antd';
const ProfileLeaveSettingsModal = ({ department, year }) => {

  const dispatch = useDispatch();
  const { Empid } = useParams();
  const [basicValid, setBasicValid] = useState(false);
  const [leaveData, setLeaveData] = useState({
    casualLeaves: "",
    CarryforwardLeaves: "",
    medicalLeave: "",
    carryFlag: false,
    Key: false,
  });

  const employeeLeaves = useSelector((state) => state.employee.filteredLeaveData);
  console.log("employeeLeaves", employeeLeaves)
  const [updateEmpLeaveSettings] = useMutation(UPDATE_EMP_LEAVE_SETTINGS, {
    onCompleted: (data) => {
      const filteredLeaveData = data?.updateEmpLeaveSettings?.[0]?.response?.[0]?.leavesSettings[0]
      console.log("data", filteredLeaveData);
      dispatch(setFilteredLeaveData(filteredLeaveData));
      message.success('Leaves updated successfully', 3);
    }
  });

  useEffect(() => {
    if (employeeLeaves) {
      setLeaveData((prevData) => ({
        ...prevData,
        casualLeaves: employeeLeaves.casualLeaves,
        CarryforwardLeaves: employeeLeaves.carryFlag ? employeeLeaves.CarryforwardLeaves : "0",
        medicalLeave: employeeLeaves.medicalLeave,
        carryFlag: employeeLeaves.carryFlag,
        Key: employeeLeaves.Key,
      }));
    }
  }, [employeeLeaves]);

  console.log("leaveData", leaveData)
  const handleLeaveInputChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // Convert boolean strings to actual boolean values
    if (value === "true" || value === "false") {
      updatedValue = JSON.parse(value);
    }

    setLeaveData((prev) => ({ ...prev, [name]: updatedValue }));
    setBasicValid(true);
  }

  const handleClose = (type) => {
    console.log(`${type} modal closed`);
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 3000);
  };

  const handleLeaveInfoSubmit = async (e) => {
    e.preventDefault();

    const hasChanges = Object.keys(leaveData).some(
      (key) => leaveData[key] !== employeeLeaves[key]
    );

    if (!hasChanges) {
      // If no changes, show error
      startTimer();
      message.error("No values have been changed. Please modify the leave data before submitting.");
      return;
    }
    startTimer();
    setBasicValid(true);
    try {
      const response = await updateEmpLeaveSettings({
        variables: {
          id: Empid,
          input: {
            ...leaveData,
            department,
            year,
          },
        },
      });
      setBasicValid(false);
      console.log('Success:', response.data);
    } catch (err) {
      console.error('Error:', err);
    }
  };
  console.log("basicValid", basicValid);
  return (
    <div id="leave_settings_modal" className="modal custom-modal fade" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Leave Information</h5>
            <button
              type="button"
              className="btn-close"
              onClick={() => handleClose('Leave')}
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              ×
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleLeaveInfoSubmit}>
              <div className="row">
                {/* Casual Leaves */}
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Casual Leaves<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="casualLeaves"
                      value={leaveData.casualLeaves}
                      onChange={handleLeaveInputChange}
                    />
                  </div>
                </div>

                {/* Medical Leave */}
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Medical Leave<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      name="medicalLeave"
                      value={leaveData.medicalLeave}
                      onChange={handleLeaveInputChange}
                    />
                  </div>
                </div>

                {/* Carryforward Flag */}
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Carryforward<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="carryFlag"
                      value={leaveData.carryFlag}
                      onChange={handleLeaveInputChange}
                    >
                      <option value="">Select</option>
                      <option value="true">Yes</option>
                      <option value="false">No</option>
                    </select>
                  </div>
                </div>

                {/* Carryforward Leaves */}
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Carryforward Leaves<span className="text-danger">*</span>
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      disabled={!leaveData.carryFlag}
                      name="CarryforwardLeaves"
                      value={leaveData.carryFlag ? leaveData.CarryforwardLeaves : 0}
                      onChange={handleLeaveInputChange}
                    />
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Update like other employees<span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="Key"
                      value={leaveData.Key}
                      onChange={handleLeaveInputChange}
                    >
                      <option value="">Select</option>
                      <option value="false">Yes</option>
                      <option value="true">No</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  disabled={isDisabled}
                  {...(basicValid ? { "data-bs-dismiss": "modal" } : {})}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileLeaveSettingsModal;
