import React, { useState, useEffect } from 'react';
import { Form, Input, Select, DatePicker, Radio, Switch, Checkbox, Button, Typography, Row, Col, Card, Divider, AutoComplete, Space } from 'antd';
import dayjs from 'dayjs';
import { CheckOutlined } from '@ant-design/icons';
import { GET_REQUESTS_BY_ID } from '../../../Queries/Orders.js';

import * as DataBucket from '../../../databucket';
import { useQuery, useMutation } from '@apollo/client';
import { getSingleOrder, updateRequestInfo, storeOrderUnderInstructions, removeOrderFromInstructions } from '../../../Slices/orderSlice.jsx';

import { useErrorLogger } from '../../../utils.js';
import { useDispatch } from 'react-redux';

import { renderOptions } from '../../../utilsdata';

import moment from 'moment';
import { Field, useFormikContext } from 'formik';
import { GET_USER_DETAILS, GET_ALL_USERS } from '../../../Queries/Users.js';
import { GET_ALL_CLIENTS } from '../../../Queries/Users.js';
import { useSelector } from 'react-redux';
import { setComments } from '../../../Slices/salesSlice.js';
import { GET_EMPLOYEE_CLIENTS } from '../../../Queries/EmployeeClients.js';
import { getAllClientsData } from '../../../Slices/loginUserSlice.jsx';
const { Option } = Select;


const RequestInformation = (props) => {
    const { RequestInfo, handleChange, values, setFieldValue, touched, errors } = props;
    console.log("requestinfo", RequestInfo)
    //const { setFieldValue, values } = useFormikContext();
    console.log("valuesreq", values)
    const dispatch = useDispatch();
    let employeeDetails = props.employeeDetails;
    let setEmployeeDetails = props.setEmployeeDetails;
    //console.log('RequestInformation-->', employeeDetails, values, RequestInfo.date)
    const { logError } = useErrorLogger();
    const [age, setAge] = useState('');
    const assignToUsers = useSelector((state) => state.loginUserInfo.allUsers)
    const loginUser = useSelector((state) => state?.loginUserInfo?.loginUserDetails)
    const options = useSelector((state) => state?.dataBucket?.databucket)

    const clientsData = useSelector((state) => state?.loginUserInfo?.getAllClients)
    console.log("clientsData", clientsData)
    // const [assignToUsers, setAssignToUsers] = useState([]);
    // const [loginUser, setLoginUser] = useState({});
    const [timestamp, setTimestamp] = useState();
    const [ReqDue, setReqDue] = useState(true);
    //const [assignValue, setAssignValue] = useState(RequestInfo?.Assign || false); 
    // const [clients, setClients] = useState([]);
    const [clientName, setClientName] = useState(values.clientName || '');
    console.log("clientName", clientName)
    const [previousClientName, setPreviousClientName] = useState(values.clientName || '');
    const [subject, setSubject] = useState(values.subject)
    console.log("subject", subject)

    //const options = useSelector((state) => state.dataBucket.databucket);
    // const renderOptions = (options, criteria, fallbackData = []) => {
    //     console.log("fallbackData",fallbackData)

    //     return options?.reduce((acc, item) => {
    //         // Check if the item name matches the criteria
    //         if (item.name === criteria.name && item.data) {
    //             // Filter subItems based on active status and flag === true
    //             const filteredSubItems = item.data.filter(
    //                 (subItem) => subItem.status === "active" && subItem.flag === true
    //             );
    //             // Accumulate valid subItems into the result array
    //             acc.push(...filteredSubItems);
    //         }
    //         return acc;
    //     }, []).map((subItem, index) => (
    //         <Option key={index} value={subItem?._id}>
    //             {subItem?.name}
    //         </Option>
    //     )) ||
    //      fallbackData?.map((item, index) => (
    //         <Option key={index} value={item}>
    //             {item}
    //         </Option>
    //     ));
    // };
    const token = localStorage.getItem('token');

    const dateValue = dayjs(parseInt(RequestInfo?.completedDate))

    //const dateValue = RequestInfo.completedDate ? getDateTimeValue(RequestInfo.value): null;

    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };

    const userid = parseJwt(token)?.id;

    const { data } = useQuery(GET_EMPLOYEE_CLIENTS, {
        variables: {
            id: userid,
        },
        fetchPolicy: 'no-cache',
        // onCompleted: (data) => {
        //     console.log("data", data)
        //     if (data?.getEmployeeClients?.response.length >= 0) {
        //         dispatch(getAllClientsData(data?.getEmployeeClients?.response[0]?.client))
        //         console.log("data", data?.getEmployeeClients?.response[0]?.client)
        //     }
        // }
    })

    useEffect(() => {
        if (data) {
            dispatch(getAllClientsData(data?.getEmployeeClients?.response[0]?.client))
        }
    }, [data, dispatch])
    const clientData = data?.getEmployeeClients?.response[0]?.client
    console.log("clientData", clientData)
    // console.log('token', parseJwt(token)?.id)


    const getDateTime = (timeValue) => {
        console.log('getDateTime', timeValue)
        const startDate = new Date(timeValue).toLocaleDateString();
        const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

        const dateTimeString = `${startDate} ${startTimeString}`;

        return dateTimeString;
    }

    const getDueTimeOld = (timeValue, typeValue) => {

        const date = new Date(timeValue).toLocaleDateString();

        if (typeValue == 1) { // 2 hours
            date.setTime(date.getTime() + 2 * 60 * 60 * 1000);
        } else if (typeValue == 2) { // 24 hours
            date.setTime(date.getTime() + 24 * 60 * 60 * 1000);
        } else if (typeValue == 3) { // 48 hours
            date.setTime(date.getTime() + 48);
        }
        return date;
    }
    const getDueTime = (timeValue, typeValue) => {

        const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

        const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');
        console.log('getDateTime1', newDateTime, timeValue, typeValue, values.date)

        return newDateTime;
    }

    const getDueTimePriority = (emailText) => {
        let hours = 24;
        const hasRushOrUrgent = emailText.toLowerCase().includes("rush") || emailText.toLowerCase().includes("urgent");

        if (hasRushOrUrgent) {
            hours = 2;
            // }else if(){

        }

        return hours;
    }

    console.log(userid);



    useEffect(() => {
        try {
            const employeeObj = {
                id: parseJwt(token)?.id,
            }
            //getUserDetails({ variables: employeeObj })


            let priorityValue = getDueTimePriority(RequestInfo?.subject + ' ' + RequestInfo?.html)

            if (values.priority == null) {
                if (priorityValue == 2) {
                    setFieldValue('priority', 'Rush')
                } else if (priorityValue == 24) {
                    setFieldValue('priority', 'On Demand')
                } else {
                    setFieldValue('priority', 'Bulk')
                }
            }
        }
        catch (error) {
            logError("RequestInformation", "useEffect-set the priority value", error.message)
        }

    }, []);

    // useEffect(() => {
    //     if (RequestInfo) {
    //         setAssignValue(RequestInfo?.Assign || false);
    //     }
    // }, [RequestInfo]);

    const assignedToHandler = (event) => {
        const checkedId = event.target.value;

        setFieldValue('assignedTo', checkedId);
        if (checkedId == loginUser?.firstname) {
            setFieldValue('requestStatus', "In Progress");
        }
    }

    const requestStatusHandler = (value) => {
        //const checkedId = event.target?.value;

        setFieldValue('requestStatus', value);
        if (value == 'In Progress') {
            setFieldValue('Assign', true);
            setFieldValue('assignedTo', loginUser?.firstname);
        }
        console.log('values--->', values)
    }


    useEffect(() => {
        // Syncing the requestStatus with the initial Redux value
        if (!values.requestStatus && RequestInfo?.requestStatus) {
            setFieldValue('requestStatus', RequestInfo.requestStatus);
        }
    }, [RequestInfo?.requestStatus, values, setFieldValue]);



    const emailSummaryChange = (event) => {
        setFieldValue('subject', event.target.value);

    }

    const handleDateChange = (date) => {
        setFieldValue('dueDate', date);
        //handleDueDateChange(date); // Implement this function as needed
    };
    const priorityChangeHandler = (event) => {

        let priorityValue = event.target?.value || ''
        setFieldValue('priority', priorityValue);
        //     let dateValue = ''
        //     if (priorityValue == 'Rush') {
        //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 2))

        //     } else if (priorityValue == 'On Demand') {
        //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 24));
        //     } else if (priorityValue == 'Bulk') {
        //         dateValue = getDateTime(getDueTime(RequestInfo.startDate, 48));
        //     }
        //     console.log('priorityChangeHandler', dateValue)

        //     setFieldValue('dueDate', dateValue);
        //     console.log('priorityChangeHandler', values, RequestInfo)




    }
    const GetDueDateTemp = (values, RequestInfo) => {
        try {
            let dateValue = values.date;
            console.log('value.date', dateValue)
            if (values.priority == null) {
                getDateTime(getDueTime(parseInt(values.date), getDueTimePriority(RequestInfo?.subject + ' ' + RequestInfo?.html)))
            }
            else {

                if (values.priority == 'Rush') {

                    dateValue = getDateTime(getDueTime(parseInt(values.date), 2))


                } else if (values.priority == 'On Demand') {
                    dateValue = getDateTime(getDueTime(parseInt(values.date), 24));

                } else if (values.priority == 'Bulk') {
                    dateValue = getDateTime(getDueTime(parseInt(values.date), 48));

                }
            }

            console.log('priorityChangeHandler', dateValue);

            return dateValue;
        }
        catch (error) {
            logError("RequestInformation", "GetDueDateTemp for date and time", error.message)
        }



    }
    const GetDueDateTempOverried = (values, RequestInfo, date) => {
        try {
            let dateValue = values.date;
            console.log("dateValue", dateValue)
            if (values.priority == null) {
                getDateTime(getDueTime(parseInt(date), getDueTimePriority(RequestInfo?.subject + ' ' + RequestInfo?.html)))
            }
            else {

                if (values.priority == 'Rush') {

                    dateValue = getDateTime(getDueTime(parseInt(date), 2))


                } else if (values.priority == 'On Demand') {
                    dateValue = getDateTime(getDueTime(parseInt(date), 24));

                } else if (values.priority == 'Bulk') {
                    dateValue = getDateTime(getDueTime(parseInt(date), 48));

                }
            }

            console.log('priorityChangeHandler', dateValue);
            return dateValue;

        }
        catch (error) {
            logError("RequestInformation", "GetDueDateTempOverride for change the time based on priority value", error.message)
        }
    }

    const convertToFormattedStringAndSeconds = (dateString) => {
        try {
            const date = moment(dateString, 'MM/DD/YYYY hh:mm A', true);

            // Check for invalid date
            if (!date.isValid()) {
                console.error("Invalid date format");
                return null;
            }

            // Step 2: Format the date into "dd/mm/yyyy hh:mm:ss a"
            const day = String(date.date()).padStart(2, '0');
            const month = String(date.month() + 1).padStart(2, '0');
            const year = date.year();

            // Format hours, minutes, and seconds
            const formattedHours = date.hours() % 12 || 12;
            const minutes = String(date.minutes()).padStart(2, '0');
            const seconds = String(date.seconds()).padStart(2, '0');
            const ampm = date.hours() < 12 ? 'am' : 'pm';

            const formattedDate = `${day}/${month}/${year} ${formattedHours}:${minutes}:${seconds} ${ampm}`;


            return {
                formattedDate,
                timestamp: date.valueOf()
            };
        }
        catch (error) {
            logError("RequestInformation", "convertToFormattedStringAndSeconds to date and time", error.message)
        }
    };

    const updateDueDateBasedOnPriority = (startDate) => {
        const priority = values.priority;
        let newDueDate;
        if (startDate) {
            switch (priority) {
                case 'Rush':
                    newDueDate = dayjs(startDate).add(2, 'hours');
                    break;
                case 'On Demand':
                    newDueDate = dayjs(startDate).add(24, 'hours');
                    break;
                case 'Bulk':
                    newDueDate = dayjs(startDate).add(48, 'hours');
                    break;
                default:
                    newDueDate = dayjs(startDate).add(1, 'days');
            }
            setFieldValue('dueDate', newDueDate);
        }
    };

    const AutoDateChanger = async (values, RequestInfo) => {
        console.log('ReqDue:', ReqDue);
        console.log('values.startDate:', values.startDate);
        try {
            if (!RequestInfo?.dueDate) {
                let dateString = '';
                if (values.startDate?.$d || RequestInfo?.startDate) {
                    const times = convertToFormattedStringAndSeconds(values.startDate.$d);
                    const value = times?.timestamp;
                    const formate = GetDueDateTempOverried(values, RequestInfo, value);
                    console.log('formate:', formate);
                    dateString = formate;
                } else {
                    console.log('Entering else block');
                    dateString = GetDueDateTemp(values, RequestInfo);
                    console.log('dateString:', dateString);
                }

                if (typeof dateString === 'string') {
                    // Split and parse the dateString
                    const dateParts = dateString.split(/[\s/:]+/);

                    if (dateParts.length >= 6) {
                        const date = new Date(
                            dateParts[2], // Year
                            dateParts[1] - 1, // Month (0-based index)
                            dateParts[0], // Day
                            dateParts[3] % 12 + (dateParts[4] === "PM" ? 12 : 0), // Hours
                            dateParts[4], // Minutes
                            dateParts[5] // Seconds
                        );

                        const timestamp = date.getTime();
                        setTimestamp(timestamp);
                        const dueVal = dayjs(parseInt(timestamp));
                        console.log('dueVal:', dueVal);

                        setFieldValue('dueDate', dueVal);
                    } else {
                        console.error('Invalid dateString format:', dateString);
                    }
                } else {
                    console.error('Expected a string, but received:', dateString);
                }
            }
        }
        catch (error) {
            logError("RequestInformation", "AutodateChanger function", error.message);
        }
    };

    useEffect(() => {
        console.log('ReqDue100', ReqDue);
        console.log('duedate', values.dueDate);
        //setFieldValue('dueDate', RequestInfo.dueDate);
        AutoDateChanger(values, RequestInfo);
    }, [values.priority, values.startDate, RequestInfo]);


    // Declare minDate outside the useEffect
    const DateCondition = () => {
        try {
            let minDate;
            if (values.startDate > RequestInfo?.dueDate) {
                minDate = dayjs(values.startDate);
                console.log('startDate', minDate);
            }
            else if (RequestInfo?.startDate) {
                minDate = dayjs(parseInt(RequestInfo?.startDate));
                console.log('RequestInfo', minDate);
            } else {
                minDate = dayjs(parseInt(RequestInfo?.date));
                console.log('date', minDate);
            }
            return minDate;
        }
        catch (error) {
            logError("RequestInformation", "Dateconditions", error.message)
        }
    }

    useEffect(() => {
        DateCondition();
    }, [values, RequestInfo]);

    const handleDueDateChange = (date) => {
        setFieldValue('dueDate', date);
        console.log("duedate", date)
    };
    // Ensure the effect runs on these dependencies

    const assignHandler = (isChecked) => {
        try {
            //const isChecked = event.target.checked;
            console.log('assignHandler-->', isChecked)
            setFieldValue('Assign', isChecked);
            if (isChecked) {
                setFieldValue('requestStatus', 'In Progress');
                setFieldValue('assignedTo', loginUser?.firstname);

            } else {
                setFieldValue('requestStatus', 'Open');
                setFieldValue('assignedTo', '');

            }
        }
        catch (error) {
            logError("RequestInformation", "assignhandler function for asign functionality", error.message)
        }
        // console.log("event.target.checked", event.target.checked, values)

    }

    const radiohandleChange = (value) => {
        try {
            // Set the radioInfo value in Formik
            setFieldValue('radioInfo', value);
            console.log("radioInfo-->", values.radioInfo);
            if (value == 'Completed') {
                setFieldValue('completedDate', dayjs(new Date()).valueOf());
                setFieldValue('requestStatus', 'Completed');
            } else if (value === 'Pending') {
                if (RequestInfo?.fromPending == null || RequestInfo?.fromPending === false) {
                    setFieldValue('fromPending', true);
                }
            } else {
                if (RequestInfo?.fromPending == null || RequestInfo?.fromPending === false) {
                    setFieldValue('fromPending', null);
                }
            }
        } catch (error) {
            logError("RequestInformation", "radiohandlechange function for handle radioinfo", error.message);
        }
    };

    const getAssignToUsers = (usersList) => {
        console.log("usersList", usersList)
        const usersListFormatted = [];
        for (let i = 0; i < usersList?.length; i++) {
            usersListFormatted.push({
                key: i,
                value: usersList[i].firstname + " " + usersList[i].lastname,
            });
        }
        return usersListFormatted;
    }
    const startDateHandler = (date) => {
        // const event = {
        //     target: {
        //         value: date,
        //         name: field.name,
        //     },
        // };
        // field.onChange(event);
        console.log('startDateHandler', date)
        setFieldValue('startDate', date);
        updateDueDateBasedOnPriority(date);
    };
    const handleKeyDown = (e) => {
        let value = e.target.value.replace(/[^\d\/]/g, '');
        if (value.length >= 10 && e.key !== "Backspace") {
            e.preventDefault();
            return;
        }

        value = value
            .replace(/^(\d{2})(\d)$/g, '$1/$2')
            .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2');

        e.target.value = value;
    };
    console.log('getDateTime1', RequestInfo?.date, values)

    const handleSwitchChange = (checked) => {
        setFieldValue("instructions", checked);

        // Dispatch action to store the order under the "Instructions" tab
        // if (checked) {
        //   dispatch(storeOrderUnderInstructions(RequestInfo)); // Add to instructionsOrders
        // } 
        // else {
        //   dispatch(removeOrderFromInstructions(RequestInfo)); // Remove from instructionsOrders if switch is off
        // }
    };

    const getDateTimeValue = (datevalue) => {
        let finalDate = null;
        if (datevalue != undefined || datevalue != '') {
            if (typeof datevalue === 'string' && datevalue?.length == 13) {
                finalDate = dayjs(parseInt(datevalue))
            } else if (typeof datevalue === 'object') {
                finalDate = datevalue
            }
        }
        return finalDate;
    }

    return (
        <>
            <Card title={
                <Space direction="horizontal" style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Typography.Title level={5} style={{ marginBottom: 0, flex: 1, textAlign: 'left', fontWeight: "bold" }}>Request Information</Typography.Title>
                    <div style={{ flex: 1, textAlign: 'right' }}><Typography.Title level={5} style={{ marginBottom: 0, fontWeight: "bold", display: "inline" }}>Client Name: </Typography.Title>
                        <Select
                            style={{ width: 200 }}
                            name="clientName"
                            value={values.clientName}
                            onChange={(value) => handleChange({ target: { name: "clientName", value } })}
                            placeholder="Select client's name"
                            showSearch
                            filterOption={(inputValue, option) =>
                                option.children.toLowerCase().includes(inputValue.toLowerCase())
                            }
                        >
                            {clientsData?.map((item, key) => (
                                <Select.Option key={key} value={item.NameOfClient}>
                                    {item.NameOfClient}
                                </Select.Option>
                            ))}
                        </Select>
                        {/* <Typography.Title level={5} style={{ marginBottom: 0, display: 'inline', fontWeight: "normal" }}>{RequestInfo.clientName}</Typography.Title> */}
                    </div>
                </Space>}>
                <Form layout="vertical">
                    <Row gutter={16}>
                        <Col span={2}>
                            <Form.Item label="RequestId">
                                <Typography.Text strong>{RequestInfo?.requestId}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Received Date">
                                <Typography.Text strong>{getDateTime(parseInt(values.date))}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Due Date">
                                <Typography.Text strong>{GetDueDateTemp(values, RequestInfo)}</Typography.Text>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Priority">
                                <Select
                                    value={values?.priority}
                                    name="priority"
                                    onChange={(value) => handleChange({ target: { name: 'priority', value } })}
                                >
                                    {renderOptions(options, { name: "PRIORITY_CONFIG" }, DataBucket.PRIORITY_CONFIG)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={3}>
                            <Form.Item label="Process Type">
                                <Select value={values.processType} name="processType" onChange={(value) => handleChange({ target: { name: 'processType', value } })}>
                                    {renderOptions(options, { name: "PROCESS_TYPE_CONFIG" }, DataBucket.PROCESS_TYPE_CONFIG)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Assigned to">
                                <Input name="assignedTo" value={values.assignedTo} disabled={true} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={3}>

                            <Form.Item label="Assign to">
                                <Select
                                    showSearch
                                    options={getAssignToUsers(assignToUsers)}
                                    value={values.assignTo}
                                    onChange={(value) => {
                                        setFieldValue('assignTo', value);
                                        setFieldValue('assignedTo', value);
                                    }}
                                    style={{ width: '100%' }}
                                />
                                {/* <AutoComplete
                                    name="assignTo"
                                    value={values.assignTo}
                                    onChange={(value) => {
                                        setFieldValue('assignTo', value);
                                        setFieldValue('assignedTo', value);
                                    }}
                                    //onChange={(value) => handleChange({ target: { name: 'assignTo', value } })}
                                    filterOption={(inputValue, option) =>
                                        option?.value?.toLowerCase().includes(inputValue?.toLowerCase())
                                    }
                                    placeholder="Assign to"
                                    style={{ width: '100%' }}
                                >
                                    {getAssignToUsers(assignToUsers)?.map((item, key) => (
                                        <AutoComplete.Option key={key} value={item}>
                                            {item}
                                        </AutoComplete.Option>
                                    ))}
                                </AutoComplete> */}
                            </Form.Item>

                        </Col>
                        <Col span={4}>
                            <Form.Item label="Start Date Override">
                                <DatePicker
                                    name="startDate"
                                    showTime
                                    value={dayjs(values.startDate || parseInt(RequestInfo?.date))}
                                    onChange={startDateHandler}
                                    format="DD/MM/YYYY hh:mm A"
                                    disabledDate={(current) => current && current < dayjs(parseInt(RequestInfo?.date))}
                                    allowClear={false}
                                    onKeyDown={handleKeyDown}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Due Date Override">
                                <DatePicker
                                    name="dueDate"
                                    showTime
                                    value={dayjs(values.dueDate || parseInt(timestamp))}
                                    onChange={handleDueDateChange}
                                    format="DD/MM/YYYY hh:mm A"
                                    disabledDate={(current) => current && current < dayjs(parseInt(RequestInfo.dueDate))}
                                    allowClear={false}
                                    onKeyDown={handleKeyDown}
                                />
                                {/* <DatePicker
                                    name="dueDate"
                                    showTime
                                    //value={dayjs(parseInt(values.dueDate)) || dayjs(parseInt(timestamp))}
                                    //onChange={handleDueDateChange}
                                    value={values?.dueDate ? dayjs(parseInt(values.dueDate)) : dayjs(parseInt(timestamp))}
                                    //value = { (values?.dueDate!=null) ? dayjs(parseInt(timestamp)) : dayjs(parseInt(values?.dueDate)) }
                                    onChange={(date)=>{
                                        setFieldValue("dueDate" ,date?.valueOf().toString())}}
                                    format="DD/MM/YYYY hh:mm A"
                                    //minDate={DateCondition()}
                                    disabledDate={(current) => current && current < dayjs(values.startDate)}
                                /> */}
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Completed Date">
                                <Field name="completedDate">
                                    {({ field }) => {
                                        const parsedValue = parseInt(field.value);
                                        console.log("parsedValue", parsedValue)
                                        const dateValue = (parsedValue && !isNaN(parsedValue)) ? dayjs(parsedValue) : null;
                                        const minDateValue = DateCondition();
                                        console.log('Min date:', minDateValue.format('DD/MM/YYYY'));

                                        return (
                                            <DatePicker
                                                name="completedDate"
                                                value={dateValue}
                                                onChange={(date) => {
                                                    field.onChange({ target: { value: date?.valueOf().toString() || '', name: field.name } });
                                                }}
                                                format="DD/MM/YYYY hh:mm A"
                                                disabledDate={(current) => current && current < minDateValue}
                                                allowClear={false}
                                                onKeyDown={handleKeyDown}
                                            />
                                        );
                                    }}
                                </Field>
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Status">
                                <Select value={values?.requestStatus || 'Open'} name="requestStatus" onChange={requestStatusHandler}>
                                    {renderOptions(options, { name: "REQUEST_STATUS_CONFIG" }, DataBucket.REQUEST_STATUS_CONFIG)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={2}>
                            <Form.Item label={values?.Assign ? "UnAssign" : "Assign"}>
                                <Switch checked={values.Assign} onChange={assignHandler} />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item label="Stage">
                                <Select onChange={radiohandleChange} value={values.radioInfo}>
                                    {renderOptions(options, { name: "FILTER_STATUS_CONFIG" }, DataBucket.FILTER_STATUS_CONFIG)}
                                </Select>
                            </Form.Item>

                        </Col>
                        <Col span={4}>
                            <Form.Item style={{ marginTop: 'auto' }} label={<span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>From Pending</span>}>
                                <Switch name="fromPending" checked={values.fromPending} disabled />
                            </Form.Item>
                        </Col>
                        <Col span={3}>
                            <Form.Item label="Instructions">
                                <Switch name="instructions" checked={values?.instructions} onChange={handleSwitchChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="Subject">
                                <Input name="subject" value={values.subject} onChange={handleChange}
                                //onChange={(e) => setSubject(e.target.value)}
                                //onBlur={(e) => handleChange(e)} 
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="From"
                            //validateStatus={touched.from && errors.from ? 'error' : ''}
                            //help={touched.from && errors.from}
                            >
                                <Input type="email" name="from" value={values.from} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item label="To"
                            //validateStatus={touched.to && errors.to ? 'error' : ''}
                            //help={touched.to && errors.to}
                            >
                                <Input name="to" value={values.to} onChange={handleChange} />
                            </Form.Item>
                        </Col>
                        {/* <Col span={6}>
                            <Form.Item label="Client Name">
                                <Select
                                    name="clientName"
                                    value={RequestInfo?.clientName || ''}
                                    onChange={(value) => handleFieldChange('clientName', value)}
                                >
                                    <Option value=''>Select clients name</Option> 
                                    {clients.map((item, key) => (
                                        <Option key={key} value={item.clientName}>
                                            {item.clientName}
                                        </Option>
                                    ))}
                                </Select>

                            </Form.Item>
                        </Col> */}
                    </Row>
                    <div className="card mt-2">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="m-2">
                                        {/* If you need to show the subject */}
                                        {/* <Title level={5}>{RequestInfo?.subject}</Title> */}
                                        {/* {RequestInfo?.subject && (
                                            <Title level={5}>{RequestInfo.subject}</Title>
                                        )} */}
                                        <div
                                            style={{ padding: 0 }}
                                            dangerouslySetInnerHTML={{
                                                __html: RequestInfo?.html || RequestInfo?.body,
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

                {/* <Divider /> */}

                {/* <Card>
                    <Typography.Title level={5}>{RequestInfo?.subject}</Typography.Title>
                    <Typography.Text dangerouslySetInnerHTML={{ __html: RequestInfo?.html || RequestInfo?.body }} />
                </Card> */}
            </Card>
        </>
    );

}

export default RequestInformation;

