import React, { useState,useEffect } from 'react';
import { Row, Col, Form, Input, Select, Button, Space, Typography } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { GET_ALL_INSURANCE_CARRIERS, GET_ALL_CARRIERS, GET_ALL_CURRENT_CARRIERS } from '../../../Queries/Users';
import { useQuery } from '@apollo/client';
import * as DataBucket from "../../../databucket";
import { FieldArray } from 'formik';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { renderOptions } from '../../../utilsdata';
const { Text } = Typography;

const NewQuoteInformation = (props) => {

    const { handleChange, values, errors, touched } = props;

    const Eligibility = [
        { value: "Eligible", label: "Eligible" },
        { value: "Ineligible", label: "Ineligible" },
        { value: "UW Review", label: "UW Review" }
    ]

    const [remarks, setRemarks] = useState([]);
    
    const [quotePremium, setQuotePremium] = useState([]);

   const [carrierFlag,setCarrierFlag] = useState([]);
   
     const {data:carrierData} = useQuery(GET_ALL_CARRIERS,{
       fetchPolicy: 'no-cache',
     });
   
     useEffect(()=>{
       if(carrierData){
         setCarrierFlag(carrierData?.getAllCarriers?.response)
       }
     },[carrierData])

      useEffect(() => {
         if (values.newQuoteInformation) {
            setRemarks(values.newQuoteInformation?.map(quote => quote.remarks));
         }
       }, [values.newQuoteInformation]);

        useEffect(() => {
           if (values.newQuoteInformation) {
            setQuotePremium(values.newQuoteInformation.map(quote => quote.quotedPremium));
           }
         }, [values.newQuoteInformation]);
    // const { data } = useQuery(carrierFlag ? GET_ALL_CARRIERS : GET_ALL_CURRENT_CARRIERS);
    // let insuranceCarriers = data?.getAllCurrentCarriers?.response || [];
    // console.log("currentCarriers", insuranceCarriers)

    const options = useSelector((state) => state.dataBucket.databucket);
    console.log("frfsvds", options)
    const handleAddRow = (arrayHelpers) => {
        arrayHelpers.push({
            policy: '',
            insuranceCarrier: '',
            eligibility: '',
            quotedPremium: '',
            remarks: '',

        });
        setRemarks((prevRemarks) => [...prevRemarks, '']);
        setQuotePremium((prevQuoted) => [...prevQuoted, '']);
    };

    const handleDeleteRow = (index, arrayHelpers) => {
        const confirmDelete = window.confirm("Are you sure you want to delete this QuoteInformation?");
        if (confirmDelete) {
            arrayHelpers.remove(index);
            setRemarks((prevRemarks) => prevRemarks.filter((_, i) => i !== index));
            setQuotePremium((prevQuoted) => prevQuoted.filter((_, i) => i !== index));
        }
    };


    return (<>
        <Form layout="vertical">
            <h3 className="card-title" style={{ fontWeight: 'bold' }}>New Quote Information</h3>
            <div className="mb-3">
                <hr />
            </div>
            {/* FieldArray Replacement */}
            <FieldArray
                name="newQuoteInformation"
                render={(arrayHelpers) => (
                    <>
                        <Row gutter={16}>
                            {values?.newQuoteInformation?.map((quote, index) => (
                                <React.Fragment key={index}>
                                    <Col span={3}>
                                        <Form.Item label=" Quote No.">
                                            {index + 1}
                                        </Form.Item>
                                    </Col>
                                    {/* Quote No. */}
                                    {/* <Col span={24} sm={6}>
                                    <Form.Item label={`Quote No. ${index + 1}`} style={{ textAlign: 'center' }}>
                                        <Text>{`Quote No. ${index + 1}`}</Text>
                                    </Form.Item>
                                </Col> */}

                                    {/* Policy */}
                                    <Col span={5}>
                                        <Form.Item label="Policy Type"
                                        //name="policy"
                                        //validateStatus={touched?.newQuoteInformation?.[index]?.policy && errors?.newQuoteInformation?.[index]?.policy ? 'error' : ''}
                                        //help={touched?.newQuoteInformation?.[index]?.policy && errors?.newQuoteInformation?.[index]?.policy}
                                        >
                                            <Select
                                                value={quote.policy}
                                                onChange={(value) => handleChange({ target: { name: `newQuoteInformation.${index}.policy`, value } })}
                                                style={{ width: '80%' }}
                                            >
                                                 {(values.processType == 'Policy Quoting' && values.department == 'Commercial Lines')? renderOptions(options, { name: "PROPERTY_TYPE_CONFIG_POLICY_QUOTE" }, DataBucket.PROPERTY_TYPE_CONFIG_POLICY_QUOTE) : renderOptions(options, { name: "PROPERTY_TYPE_CONFIG" }, DataBucket.PROPERTY_TYPE_CONFIG) }
                                            </Select>

                                        </Form.Item>
                                    </Col>

                                    {/* Insurance Carrier */}
                                    <Col span={5}>
                                        <Form.Item label="Insurance Carrier"
                                        // name="insuranceCarrier"
                                        //validateStatus={touched?.newQuoteInformation?.[index]?.insuranceCarrier && errors?.newQuoteInformation?.[index]?.insuranceCarrier ? 'error' : ''}
                                        //</Col>help={touched?.newQuoteInformation?.[index]?.insuranceCarrier && errors?.newQuoteInformation?.[index]?.insuranceCarrier}
                                        >
                                            <Select
                                                value={quote.insuranceCarrier}
                                                onChange={(value) => handleChange({ target: { name: `newQuoteInformation.${index}.insuranceCarrier`, value } })}
                                                style={{ width: '80%' }}
                                            >
                                                {carrierFlag?.filter(item => item?.status === 'Active')?.map((carrierItem, key) => (
                                                    <Select.Option key={key} value={carrierItem?.carrierName}>
                                                        {carrierItem?.carrierName}
                                                    </Select.Option>
                                                ))}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {/* Eligibility */}
                                    <Col span={4}>
                                        <Form.Item label="Eligibility"
                                        //name="eligibility"
                                        //validateStatus={touched?.newQuoteInformation?.[index]?.eligibility && errors?.newQuoteInformation?.[index]?.eligibility ? 'error' : ''}
                                        //help={touched?.newQuoteInformation?.[index]?.eligibility && errors?.newQuoteInformation?.[index]?.eligibility}
                                        >
                                            <Select
                                                placeholder="select current carrier"
                                                //showSearch
                                                onChange={(value) => handleChange({ target: { name: `newQuoteInformation.${index}.eligibility`, value } })}
                                                value={quote.eligibility}
                                            >
                                                {Eligibility?.map((item) => {
                                                    return (<Select.Option key={item.label} value={item.value}>{item.label}</Select.Option>)
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    {/* Quoted Premium / Remarks */}
                                    <Col span={5}>
                                        {quote.eligibility === 'Eligible' ? (
                                            <Form.Item label="Quoted Premium"
                                            //name="quotedPremium"
                                            //validateStatus={touched?.newQuoteInformation?.[index]?.quotedPremium && errors?.newQuoteInformation?.[index]?.quotedPremium ? 'error' : ''}
                                            //help={touched?.newQuoteInformation?.[index]?.quotedPremium && errors?.newQuoteInformation?.[index]?.quotedPremium}
                                            >
                                                <Input
                                                    value={quotePremium[index] || ''}
                                                    onChange={(e) => {
                                                        const updatedRemarks = [...quotePremium];
                                                        updatedRemarks[index] = e.target.value;
                                                        setQuotePremium(updatedRemarks);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: `newQuoteInformation[${index}].quotedPremium`,
                                                                value: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        ) : (
                                            <Form.Item label="Remarks"
                                            // name="remarks"
                                            //validateStatus={touched?.newQuoteInformation?.[index]?.remarks && errors?.newQuoteInformation?.[index]?.remarks ? 'error' : ''}
                                            //help={touched?.newQuoteInformation?.[index]?.remarks && errors?.newQuoteInformation?.[index]?.remarks}
                                            >
                                                <Input
                                                    style={{ width: '90%' }}
                                                    value={remarks[index] || ''}
                                                    onChange={(e) => {
                                                        const updatedRemarks = [...remarks];
                                                        updatedRemarks[index] = e.target.value;
                                                        setRemarks(updatedRemarks);
                                                    }}
                                                    onBlur={(e) => {
                                                        handleChange({
                                                            target: {
                                                                name: `newQuoteInformation[${index}].remarks`,
                                                                value: e.target.value,
                                                            },
                                                        });
                                                    }}
                                                />
                                            </Form.Item>
                                        )}
                                    </Col>
                                    {/* Delete Row */}
                                    <Col span={2} style={{ marginTop: "25px", display: 'flex', alignItems: 'center' }}>
                                        <Form.Item>
                                            <Link to="#" className="delete-icon" onClick={() => handleDeleteRow(index, arrayHelpers)}>
                                                <i className="fa-regular fa-trash-can" />
                                            </Link>
                                        </Form.Item>
                                        {/* <Form.Item>
                                        <Button
                                            type="danger"
                                            icon={<MinusCircleOutlined />}
                                            onClick={() => handleDeleteRow(index, arrayHelpers)}
                                        >
                                            Delete
                                        </Button>
                                    </Form.Item> */}
                                    </Col>
                                </React.Fragment>
                            ))}

                            {/* Add Row Button */}
                        </Row>
                        <Form.Item>
                            <Link to="#" onClick={() => handleAddRow(arrayHelpers)}>
                                <i className="fa-solid fa-plus-circle" /> Add More
                            </Link>
                        </Form.Item>
                    </>
                )}
            />
        </Form>

    </>);




    // const renderGridItems = () => {
    //     const gridItems = [];

    //     for (let i = 1; i <= rowCount; i++) {
    //         gridItems.push(
    //             <React.Fragment key={i}>
    //                 <Grid item xs={3} sm={3} container justifyContent="center" alignItems="center">
    //                     <FormLabel component="legend" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //                         Quote No. {i}
    //                     </FormLabel>
    //                 </Grid>
    //                 <Grid item xs={3}>
    //                     <FormControl style={{ width: '100%' }}>
    //                         <FormLabel>Policy</FormLabel>
    //                         <Select
    //                             value={values.newQuoteInformation[(i - 1)]?.policy}
    //                             // value={employeeDetails && employeeDetails['Policy' + '_' + i]}
    //                             // onChange={(event) => policyChange(event, i)}
    //                             name='policy'
    //                             onChange={handleChange}
    //                             displayEmpty
    //                             inputProps={{ 'aria-label': 'Without label' }}>
    //                             {DataBucket.POLICY_CONFIG.map((carrierItem, key) => {
    //                                 return (<MenuItem key={key} value={carrierItem}>{carrierItem}</MenuItem>)
    //                             })}
    //                             {/* <MenuItem value={'DP3 (Dwelling Fire) 1'}>DP3 (Dwelling Fire) 1</MenuItem>
    //                             <MenuItem value={'DP3 (Dwelling Fire) 2'}>DP3 (Dwelling Fire) 2</MenuItem>
    //                             <MenuItem value={'DP3 (Dwelling Fire) 3'}>DP3 (Dwelling Fire) 3</MenuItem> */}
    //                         </Select>
    //                     </FormControl>
    //                 </Grid>
    //                 <Grid item xs={3}>
    //                     <FormControl style={{ width: '100%' }}>
    //                         <FormLabel>Insurance Carrier</FormLabel>
    //                         <Select
    //                             value={values.newQuoteInformation[(i - 1)]?.insuranceCarrier}
    //                             // value={employeeDetails && employeeDetails['Insurance_Carrier' + '_' + i]}
    //                             onChange={(event) => insurenceCarrierChange(event, i)}
    //                             displayEmpty
    //                             inputProps={{ 'aria-label': 'Without label' }}>
    //                             {DataBucket.INSURANCE_CARRIERS_CONFIG.map((carrierItem, key) => {
    //                                 return (<MenuItem key={key} value={carrierItem}>{carrierItem}</MenuItem>)
    //                             })}
    //                             {/* <MenuItem value={'Nationwide'}>Nationwide</MenuItem>
    //                             <MenuItem value={'Progressive'}>Progressive</MenuItem>
    //                             <MenuItem value={'Travellers'}>Travellers</MenuItem> */}
    //                         </Select>
    //                     </FormControl>
    //                 </Grid>
    //                 <Grid item xs={3}>
    //                     <Stack>
    //                         <FormLabel>Quoted Premium</FormLabel>
    //                         <TextField
    //                             value={values.newQuoteInformation[(i - 1)]?.quotedPremium}
    //                             // value={employeeDetails && employeeDetails['Quoted_Premium' + '_' + i]}
    //                             onChange={(event) => quotedPremiumChange(event, i)}
    //                             sx={{ '& legend': { display: 'none' }, '& fieldset': { top: 0 } }} />
    //                     </Stack>
    //                 </Grid>
    //             </React.Fragment>
    //         );
    //     }

    //     return gridItems;
    // };

    // return (
    //     <Grid container item spacing={2} xs={12} m={1}>
    //         <Grid item xs={12}>
    //             <FormLabel>
    //                 <Typography px={1} variant="h4" style={{ backgroundColor: '#bacee7', color: '#000000' }}>
    //                     New Quote Information
    //                 </Typography>
    //             </FormLabel>
    //         </Grid>

    //         {renderGridItems()}

    //         <Grid item xs={12} container justifyContent="flex-end">
    //             <Button variant="outlined" onClick={handleAddRow}>
    //                 Add Row
    //             </Button>
    //         </Grid>
    //     </Grid>
    // );
};

export default NewQuoteInformation;
