import { configureStore } from "@reduxjs/toolkit";
import { userSlice } from "./Slices/user";
import timerReducer from './Slices/timerslice';
import salesSlice from './Slices/salesSlice';
import getOrders from "./Slices/orderSlice";
import employeeReducer from './Slices/employeeslice'; 
import clientReducer from './Slices/clientSlice';
import employeeReportReducer from './Slices/employeereportslice';
import { createLogger } from 'redux-logger'; 
import  getReports  from "./Slices/reportSice";
import loaderSlice  from "./Slices/loaderSlice";
import loginUserSlice from "./Slices/loginUserSlice";
import  DataBucket  from "./Slices/dataBucket";
import ScrapperSlice from "./Slices/webScrepperSlice";
import Route from "./Slices/DyanamicRoute";
import lastContactedDatesSlice from  './Slices/lastContactedDatesSlice';
import leavesReducer from "./Slices/LeavesSlice";
import adminLeaveRequest from "./Slices/adminLeaveSlice";
import holidaySlice from "./Slices/holidaySlice";
import attendence from "./Slices/attendenceSlice"
const logger = createLogger({
  collapsed: true,
});
const store = configureStore({
    reducer: {
      user: userSlice.reducer, 
        timer: timerReducer,
        employee: employeeReducer,
        sales:salesSlice,
        clients: clientReducer,
        reports:getReports,
        orders:getOrders ,
        employeeReports:employeeReportReducer,
        loader: loaderSlice,
        loginUserInfo:loginUserSlice,
        dataBucket:DataBucket,
        scraper:ScrapperSlice,
        Route:Route,
        lastContactedDates:lastContactedDatesSlice,
        leaves: leavesReducer,
        adminLeave: adminLeaveRequest,
        holidayData: holidaySlice,
        Attendence:attendence,
    },
    // middleware: (getDefaultMiddleware) =>
    //   getDefaultMiddleware().concat(logger),
    // devTools: true,
});

export default store;
