import { LOG_ERROR } from "./Queries/ErrorLog";
import { useMutation } from "@apollo/client";
 
export const useErrorLogger = () => {
  const token = localStorage.getItem("token");
  const [createLogError] = useMutation(LOG_ERROR);
 
  const logError = async ( componentName, functionName, errorMessage) => {
    try {
      await createLogError({
        variables: {
          userId: token,
          componentName: componentName,
          functionName: functionName,
          lastError: errorMessage,
          dateTime: new Date().toISOString(),
        },
      });
    } catch (error) {
      console.error("Failed to log error:", error);
    }
  };
 
  return { logError };
};