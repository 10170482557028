// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sales: [],
  sale: {},
  leadsInfo: {},
  agencyInfo: {},
  contactsInfo: [],
  callsInfo: [],
  emailsInfo: [],
  linkedinInfo: [],
  schedulingCallsInfo: [],
  otcInfo: [],
  comments: {},
  contactComments: {},
  todayStats: {},
  monthlyStats: {},
  followUpStats: {},
  totalStats: {},
  savedFilters: [],
  refreshStatus: null,
  pageCount: 0,
  leadfilterObject: {},
  leadfilterObjectTemp: {},
  sortObject: {},
  filterSearch: '',
  page: 1,
  open: false,
  selectedFilterName: {},
  prevVisibility: false,
  salesWorkHistory: [],
  isRefetch: false,
};

const salesSlice = createSlice({
  name: "sales",
  initialState,
  reducers: {
    getAllSales: (state, action) => {
      state.sales = action.payload
    },
    updateSales: (state, action) => {
      state.sales = [action.payload, ...state.sales];
    },
    setSale: (state, action) => {
      state.sale = action.payload;
    },
    setLeadsInfo: (state, action) => {
      state.leadsInfo = action.payload;
    },
    setAgencyInfo: (state, action) => {
      state.agencyInfo = action.payload;
    },
    setContactsInfo: (state, action) => {
      state.contactsInfo = action.payload;
    },
    setCallsInfo: (state, action) => {
      state.callsInfo = action.payload;
    },
    setEmailsInfo: (state, action) => {
      state.emailsInfo = action.payload;
    },
    setLinkedinInfo: (state, action) => {
      state.linkedinInfo = action.payload;
    },
    setSchedulingCallsInfo: (state, action) => {
      state.schedulingCallsInfo = action.payload;
    },
    setOtcInfo: (state, action) => {
      state.otcInfo = action.payload;
    },
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    setContactComments: (state, action) => {
      state.contactComments = action.payload;
    },
    setTodayStats: (state, action) => {
      state.todayStats = action.payload;
    },
    setMonthlyStats: (state, action) => {
      state.monthlyStats = action.payload;
    },
    setFollowupStats: (state, action) => {
      state.followUpStats = action.payload;
    },
    setTotalStats: (state, action) => {
      state.totalStats = action.payload;
    },
    setSavedFilters: (state, action) => {
      state.savedFilters = action.payload;
    },
    setRefreshStatus: (state, action) => {
      state.refreshStatus = action.payload;
    },
    setPageCount: (state, action) => {
      state.pageCount = action.payload;
    },
    setleadfilterObjectt: (state, action) => {
      state.leadfilterObject = action.payload;
    },
    setleadfilterObjectTempp: (state, action) => {
      state.leadfilterObjectTemp = action.payload;
    },
    setSortObjectt: (state, action) => {
      state.sortObject = action.payload;
    },
    setFilterSearchh: (state, action) => {
      state.filterSearch = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setOpen: (state, action) => {
      state.open = action.payload;
    },
    setSelectedFilterName: (state, action) => {
      state.selectedFilterName = action.payload;
    },
    setDivVisibility: (state, action) => {
      state.prevVisibility = action.payload;
    },
    setSalesWorkHistory: (state, action) => {
      state.salesWorkHistory = action.payload;
    },

    setIsRefetch: (state, action) => {
      state.isRefetch = action.payload;
    },
  },
});

export const {
  getAllSales,
  updateSales,
  setSale,
  setLeadsInfo,
  setAgencyInfo,
  setContactsInfo,
  setCallsInfo,
  setEmailsInfo,
  setLinkedinInfo,
  setSchedulingCallsInfo,
  setOtcInfo,
  setComments,
  setContactComments,
  setTodayStats,
  setMonthlyStats,
  setFollowupStats,
  setTotalStats,
  setSavedFilters,
  setRefreshStatus,
  setPageCount,
  setleadfilterObjectTempp,
  setleadfilterObjectt,
  setSortObjectt,
  setFilterSearchh,
  setPage,
  setOpen,
  setSelectedFilterName,
  setDivVisibility,
  setSalesWorkHistory,
  setIsRefetch,

} = salesSlice.actions;

export default salesSlice.reducer;
