import { useState, useEffect } from 'react';
import { Avatar_02, Avatar_16 } from "../../../Routes/ImagePath";
import { Link, useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { useQuery,useMutation } from '@apollo/client';
import { GET_LEAD_BY_ID} from "../../../Queries/Leads";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';

import CallingTrack from "./CallingTrack";
import EmailTrack from "./EmailTrack";
import LinkedInTrack from './LinkedInTrack';
import SchedulingTrack  from './SchedulingTrack';
import OTCTrack from "./OTCTrack";
import AttachmentHistory from './AttachmentHistory';
import WorkHistory from "./WorkHistory";

import AddCommentBox from './CommentBox';

import SalesInformationPopup from '../../../components/modelpopup/SalesInformationPopup';
import ContactCommentBox from './ContactCommentBox';

const SalesInformation = () => {
  const [activeTab, setActiveTab] = useState("calling_track");
  //const [SalesInfo, setSalesInfo] = useState({});

const { id } = useParams();

// const [getLeadById, { }] = useMutation(GET_LEAD_BY_ID, {
//   onCompleted(data) {
//      setSalesInfo(data?.getLeadById[0])
//       console.log(data, 'dataaaaa')

//   },
// });

// useEffect(() => {
//   const valuesObject = {
//       id: id,
//   }
//   getLeadById({ variables: valuesObject })
// }, []);

const {
  rawLeadsInfo,
  agencyInfo: rawAgencyInfo,
  contactsInfo,
  callingTrackInfo,
  emailTrackInfo,
  linkedinTrackInfo,
  schedulingTrackInfo,
  otcTrackInfo
} = useSelector((state) => ({
  rawLeadsInfo: state.sales.leadsInfo,
  agencyInfo: state.sales.agencyInfo,
  contactsInfo: state.sales.contactsInfo || [],
  callingTrackInfo: state.sales.callsInfo || [],
  emailTrackInfo: state.sales.emailsInfo || [],
  linkedinTrackInfo: state.sales.linkedinInfo || [],
  schedulingTrackInfo: state.sales.schedulingCallsInfo || [],
  otcTrackInfo:  state.sales.otcInfo || []
}));

// console.log("rawleadinfo",rawLeadsInfo)

const formatDate = (date) => {
  if (!date) return 'N/A'; // If date is null/undefined, return 'N/A'
  const parsedDate = dayjs(Number(date)); // Convert to number if it's a string or timestamp
  return parsedDate.isValid() ? parsedDate.format('DD/MM/YYYY hh:mm A') : 'N/A'; // Format if valid
};


const leadsInfo = {
  leadID: rawLeadsInfo?.leadID || 'N/A',
  createdDate: rawLeadsInfo?.createdDate
    ? formatDate(rawLeadsInfo.createdDate)
    : 'N/A',
  createdBy: rawLeadsInfo?.createdBy || 'N/A',
  lastUpdatedDate: rawLeadsInfo?.lastUpdatedDate
    ? formatDate(rawLeadsInfo.lastUpdatedDate)
    : 'N/A',
    lastContactedDate: rawLeadsInfo?.lastContactedDate
    ? formatDate(rawLeadsInfo.lastContactedDate)
    : 'N/A',
  followup: rawLeadsInfo?.followup ? 'Yes' : 'No',
  followupDate: rawLeadsInfo?.followupDate
    ? formatDate(rawLeadsInfo.followupDate)
    : 'N/A',
  callscheduledwithOPs: rawLeadsInfo?.callscheduledwithOPs ? 'Yes' : 'No',
  assign: rawLeadsInfo?.assign ? 'Yes' : 'No',
  assignedTo: rawLeadsInfo?.assignedTo || 'N/A',
  assignTo: rawLeadsInfo?.assignTo || 'N/A',
  priority: rawLeadsInfo?.priority || 'N/A',
  status: rawLeadsInfo?.status || 'N/A',
  stage: rawLeadsInfo?.stage || 'N/A',
  channel: rawLeadsInfo?.channel || 'N/A',
  successfull: rawLeadsInfo?.successfull ? 'Yes' : 'No',
};

// console.log("rawleadinfo",leadsInfo)

const agencyInfo = {
  agencyName: rawAgencyInfo?.agencyName || 'N/A',
  address: rawAgencyInfo?.address || 'N/A',
  city: rawAgencyInfo?.city || 'N/A',
  state: rawAgencyInfo?.state || 'N/A',
  timeZone: rawAgencyInfo?.timeZone || 'N/A',
  webURL: rawAgencyInfo?.webURL || 'N/A',
  contactInfoURL: rawAgencyInfo?.contactInfoURL || 'N/A',
  linkedinURL: rawAgencyInfo?.linkedinURL || 'N/A',
};


  return (
   <>
  <div className="page-wrapper">
    <div className="content container-fluid">
      {/* <Breadcrumbs
        maintitle="Edit Sales"
        title="Dashboard"
        subtitle="Sales"
        modal="#add_indicator"
        name="Add New"
      /> */}
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-view">

                {/* Lead Information Section */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <h4 className="mb-3">
                      Lead Information
                      <Link
                        data-bs-target="#edit_lead"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Lead ID:</div>
                            <div className="text">{leadsInfo.leadID}</div>
                          </li>
                          <li>
                            <div className="title">Created Date:</div>
                            <div className="text">{leadsInfo.createdDate}</div>
                          </li>
                          <li>
                            <div className="title">Created By:</div>
                            <div className="text">{leadsInfo.createdBy}</div>
                          </li>
                          <li>
                            <div className="title">Last Updated:</div>
                            <div className="text">{leadsInfo.lastUpdatedDate}</div>
                          </li>
                          <li>
                            <div className="title">Last Contacted:</div>
                            <div className="text">{leadsInfo.lastContactedDate}</div>
                          </li>
                          <li>
                            <div className="title">Follow Up:</div>
                            <div className="text">{leadsInfo.followup}</div>
                          </li>
                          <li>
                            <div className="title">Follow Up Date:</div>
                            <div className="text">{leadsInfo.followupDate}</div>
                          </li>
                          <li>
                            <div className="title">Call Scheduled with Ops:</div>
                            <div className="text">{leadsInfo.callscheduledwithOPs}</div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Assign:</div>
                            <div className="text">{leadsInfo.assign}</div>
                          </li>
                          <li>
                            <div className="title">Assigned To:</div>
                            <div className="text">{leadsInfo.assignedTo}</div>
                          </li>
                          <li>
                            <div className="title">Assign To:</div>
                            <div className="text">{leadsInfo.assignTo}</div>
                          </li>
                          <li>
                            <div className="title">Priority:</div>
                            <div className="text">{leadsInfo.priority}</div>
                          </li>
                          <li>
                            <div className="title">Status:</div>
                            <div className="text">{leadsInfo.status}</div>
                          </li>
                          <li>
                            <div className="title">Stage:</div>
                            <div className="text">{leadsInfo.stage}</div>
                          </li>
                          <li>
                            <div className="title">Channel:</div>
                            <div className="text">{leadsInfo.channel}</div>
                          </li>
                          <li>
                            <div className="title">Successful:</div>
                            <div className="text">{leadsInfo.successfull}</div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Agency Information Section */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <h4 className="mb-3">
                      Agency Information
                      <Link
                        data-bs-target="#edit_agency"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </h4>
                    <div className="row">
                      <div className="col-md-6">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Agency Name:</div>
                            <div className="text">{agencyInfo.agencyName}</div>
                          </li>
                          <li>
                            <div className="title">Address:</div>
                            <div className="text">{agencyInfo.address}</div>
                          </li>
                          <li>
                            <div className="title">City:</div>
                            <div className="text">{agencyInfo.city}</div>
                          </li>
                          <li>
                            <div className="title">State:</div>
                            <div className="text">{agencyInfo.state}</div>
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6">
                        <ul className="personal-info">
                          <li>
                            <div className="title">Time Zone:</div>
                            <div className="text">{agencyInfo.timeZone}</div>
                          </li>
                          <li>
                            <div className="title">Website URL:</div>
                            <div className="text">
                              <Link to={agencyInfo.webURL} target="_blank" rel="noopener noreferrer">
                                {agencyInfo.webURL}
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="title">Contact Info URL:</div>
                            <div className="text">
                              <Link to={agencyInfo.contactInfoURL} target="_blank" rel="noopener noreferrer">
                                {agencyInfo.contactInfoURL}
                              </Link>
                            </div>
                          </li>
                          <li>
                            <div className="title">LinkedIn URL:</div>
                            <div className="text">
                              <Link to={agencyInfo.linkedinURL} target="_blank" rel="noopener noreferrer">
                                {agencyInfo.linkedinURL}
                              </Link>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Contacts Information Section */}
                <div className="row mb-4">
                  <div className="col-md-12">
                    <h4 className="mb-3">
                      Contacts Information
                      <Link
                        data-bs-target="#edit_contacts"
                        data-bs-toggle="modal"
                        className="edit-icon"
                        to="#"
                      >
                        <i className="fa-solid fa-pencil"></i>
                      </Link>
                    </h4>
                    <div className="table-responsive">
                      <table className="table table-nowrap">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Title</th>
                            <th>Phone</th>
                            <th>Email</th>
                            <th>Remarks</th>
                          </tr>
                        </thead>
                        <tbody>
                          {contactsInfo?.map((contact, index) => (
                            <tr key={index}>
                              <td>{contact.contactPerson}</td>
                              <td>{contact.title}</td>
                              <td>{contact.phone}</td>
                              <td>{contact.email}</td>
                              <td>{contact.remarks}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <ContactCommentBox name={'contactInfoComments'}/>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card tab-box mb-0 pb-0">
        <div className="row user-tabs ">
          <div className="col-12">
            <ul className="nav nav-tabs nav-tabs-bottom">
              <li className="nav-item">
                <Link
                  to="#calling_track"
                  data-bs-toggle="tab"
                  data-bs-target="#calling_track"
                  className={`nav-link ${activeTab === "calling_track" ? "active" : ""}`}
                  onClick={() => setActiveTab("calling_track")}
                >
                  Calling Track
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#email_track"
                  data-bs-toggle="tab"
                  data-bs-target="#email_track"
                  className={`nav-link ${activeTab === "email_track" ? "active" : ""}`}
                  onClick={() => setActiveTab("email_track")}
                >
                  Email Track
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#linkedin_track"
                  data-bs-toggle="tab"
                  data-bs-target="#linkedin_track"
                  className={`nav-link ${activeTab === "linkedin_track" ? "active" : ""}`}
                  onClick={() => setActiveTab("linkedin_track")}
                >
                  LinkedIn Track
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#scheduling_a_call"
                  data-bs-toggle="tab"
                  data-bs-target="#scheduling_a_call"
                  className={`nav-link ${activeTab === "scheduling_a_call" ? "active" : ""}`}
                  onClick={() => setActiveTab("scheduling_a_call")}
                >
                  Scheduling a Call
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#operations_team_communication"
                  data-bs-toggle="tab"
                  data-bs-target="#operations_team_communication"
                  className={`nav-link ${activeTab === "operations_team_communication" ? "active" : ""}`}
                  onClick={() => setActiveTab("operations_team_communication")}
                >
                  Operations Team Communication
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#attachment_history"
                  data-bs-toggle="tab"
                  data-bs-target="#attachment_history"
                  className={`nav-link ${activeTab === "attachment_history" ? "active" : ""}`}
                  onClick={() => setActiveTab("attachment_history")}
                >
                  Attachment History
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="#work_history"
                  data-bs-toggle="tab"
                  data-bs-target="#work_history"
                  className={`nav-link ${activeTab === "work_history" ? "active" : ""}`}
                  onClick={() => setActiveTab("work_history")}
                >
                  Work History
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Tab Contents */}
      <div className="tab-content mt-0 pt-0">
        {activeTab === "calling_track" && <CallingTrack callingTrackInfo={callingTrackInfo} />}
        {activeTab === "email_track" && <EmailTrack emailTrackInfo={emailTrackInfo} />}
        {activeTab === "linkedin_track" && <LinkedInTrack linkedinTrackInfo={linkedinTrackInfo} />}
        {activeTab === "scheduling_a_call" && <SchedulingTrack schedulingTrackInfo={schedulingTrackInfo} />}
        {activeTab === "operations_team_communication" && <OTCTrack  otcTrackInfo={otcTrackInfo} />}
        {activeTab === "attachment_history" && <AttachmentHistory />}
        {activeTab === "work_history" && <WorkHistory />}
      </div>
      <SalesInformationPopup name={'callingComments'}/>
    </div>
  </div>
  
</>

  );
};

export default SalesInformation;
