import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

const EditHistory = () => {
  // Select data from Redux
  const workHistory = useSelector((state) => state.employee.editHistory) || [];
 
  // Flatten the nested array
  const flattenedHistory = workHistory.flat();
  console.log("rwrhh",flattenedHistory,workHistory)
  return (
    <div>
      <div id="update_workHistory" className="card profile-box flex-fill">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <h3 className="card-title">Work History</h3>
          </div>

          <div className="table-responsive">
            <table className="table table-nowrap text-center">
              <thead>
                <tr>
                  <th>User Name</th>
                  <th>Edit Status</th>
                  <th>Action</th>
                  <th>Updated Time</th>
                </tr>
              </thead>
              <tbody>
                {flattenedHistory.length > 0 ? (
                  flattenedHistory.map((item, index) => (
                    <tr key={index}>
                      <td>{item?.userName || "N/A"}</td>
                      <td>{item?.EditStatus || "N/A"}</td>
                      <td>{item?.action || "N/A"}</td>
                      <td>
                        {item?.changeTime
                          ? dayjs(item.changeTime).format("DD/MM/YYYY hh:mm:ss a")
                          : "N/A"}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="4">No update found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditHistory;
