import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import * as DataBucket from "../databucket";
import "react-datepicker/dist/react-datepicker.css";
import { fetchAdminReport } from "../Slices/employeereportslice";
import { useDispatch } from "react-redux";
import { useQuery } from "@apollo/client";
import { setLoader } from "../Slices/loaderSlice";
import { updateAdminReport } from "../Slices/employeereportslice";
import { useSelector } from "react-redux";
import { GET_ALL_CLIENTS } from "../Queries/Users";
import { Spin } from "antd";
import { Alert } from "bootstrap/dist/js/bootstrap.bundle.min";
import { setLoading } from "../Slices/employeeslice";
const EmployeeReportFilter = ({
  toggleBulkEdit,
  isEditingBulk,
  PageChange,
}) => {
  const location = useLocation();
  const currentPage = location.pathname;
  console.log("currentPage", currentPage);
  const [inputValueEmployeeName, setInputValueEmployeeName] = useState("");
  const [SelectedEmployeeetails, setSelectedEmployeeetails] = useState();
  console.log("Employeeindex", SelectedEmployeeetails);
  const [inputValueEmailId, setInputValueEmailId] = useState("");
  const [inputValueClientName, setInputValueClientName] = useState("");
  const [inputValueReportingTo, setInputValueReportingTo] = useState("");
  const dispatch = useDispatch();
  const Employee = useSelector((state) => state.employee.AllEmployees);
  console.log("Employee", Employee);
  const today = new Date().toISOString().split("T")[0];
  const [fromDate, setFromDate] = useState(today);
  const [toDate, setToDate] = useState(today);
  console.log("fromDate,toDate", fromDate, toDate);
  const twoMonthsAgo = new Date();
  twoMonthsAgo.setMonth(twoMonthsAgo.getMonth() - 2);
  const maxFromDate = twoMonthsAgo.toISOString().split("T")[0];
  const [requestReceivedDate, setRequestReceivedDate] = useState("");
  const [requestCompletedDate, setRequestCompletedDate] = useState("");
  const [task, setTask] = useState("");
  const [timezone, setTimezone] = useState("");
  const [productivetype, setProductivetype] = useState("");
  const [SIA, setSIA] = useState("");
  const [service, setService] = useState("");
  const [request, setRequest] = useState(null);
  const [client, setClients] = useState();
  const clientOptions = useSelector((state) => state.clients.clients);
  // const { data: clientdata } = useQuery(GET_ALL_CLIENTS);
  console.log("clientOptions",clientOptions)
  const options = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
    { value: null, label: "Both" },
  ];
  const [billable, setBillable] = useState(options[2]);
  const [billableValue, setBillableValue] = useState(null);
  const handleBillableChange = (selectedOption) => {
    console.log("selectedOption", selectedOption.value);
    setBillable(selectedOption);
    setBillableValue(selectedOption.value);
  };
  const statusOptions = [
    { value: "open", label: "Pending" },
    { value: "completed", label: "Completed" },
    { value: "", label: "Both" },
  ];
  const [status, setStatus] = useState(statusOptions[2]);
  const [statusValue, setStatusValue] = useState();
  console.log("status", status);
  const handleStatusChange = (option) => {
    setStatusValue(option.value);
    setStatus(option);
  };
  const handleProductivetypeChange = (e) => {
    setProductivetype(e.target.value);
    setTask("");
  };
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // width:'120px',
      minHeight: "39px",
      fontSize: "12px",
    }),
    option: (provided, state) => ({
      ...provided,

      backgroundColor: state.isFocused ? "#7a7c7f" : "#fff",
      color: state.isFocused ? "#fff" : "#4d5154",
      "&:hover": {
        backgroundColor: "#7a7c7f",
      },
    }),
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = new Date(e.target.value);

    // Format the date to "YYYY-MM-DDTHH:mm:ss.sss+00:00"
    // const formattedDate = formatToExactISOString(selectedFromDate);

    // Set the formatted date
    setFromDate(selectedFromDate);
  };

  // Helper function to format the date to exactly "YYYY-MM-DDTHH:mm:ss.sss+00:00"
  const formatToExactISOString = (date) => {
    const isoString = date.toISOString();
    return isoString.replace("Z", "+00:00"); // Replaces 'Z' (UTC) with '+00:00'
  };

  const handleToDateChange = (e) => {
    const newToDate = new Date(e.target.value);
    const newToTimestamp = newToDate.getTime();
    const maxAllowedToDate = calculateMaxToDate(fromDate);

    if (newToTimestamp > maxAllowedToDate) {
      alert("To date exceeded 2 months");
    } else {
      //  const formattedToDate = formatToExactISOString(newToDate)
      setToDate(newToDate);
    }
  };

  let EmployeeIds = [];
  const calculateMaxToDate = (fromDate) => {
    const date = new Date(fromDate);
    date.setMonth(date.getMonth() + 2);
    return date.toISOString().split("T")[0];
  };

  const handleEmployeeChange = (e) => {
    const selectedName = e.target.value;
    const selectedIndex = e.target.selectedIndex;
    

    setInputValueEmployeeName(selectedName);
    const selectedUserDetails = Employee.find(user => 
      `${user.firstname} ${user.lastname}` === selectedName
    );
    console.log("selectedUserDetails", selectedUserDetails);
    setSelectedEmployeeetails(selectedUserDetails?.id);
  };

  const filters = {
    reporttype: currentPage,
    task: task || "",
    service: service || "",
    requestNumber: request || "",
    employeeName: SelectedEmployeeetails || "",
    emailId: inputValueEmailId || "",
    reportingTo: inputValueReportingTo || "",
    startDateTime: fromDate ? fromDate.toString() : today,
    endDateTime: toDate ? toDate.toString() : today,
    billable: billableValue,
    status: statusValue || "",
    clientName: inputValueClientName || "",
    requestReceivedDate: requestReceivedDate || "",
    requestCompletedDate: requestCompletedDate || "",
    SIA: SIA || "",
    timezone: timezone || "",
    productivetype: productivetype || "",
  };
  console.log("filters", filters);
  const shouldDisableSearchButton =
    Object.values(filters).filter((value) => {
      return value !== "" && value !== today;
    }).length === 0;

  console.log("shouldDisableSearchButton", shouldDisableSearchButton);
  const handleSearch = () => {
    if (!fromDate || !toDate) {
      alert("Both From Date and To Date are required!");
      return;
    }

    if (status?.value === "" && status?.label === "Both") {
      filters.status = ""; // Include status only if it's a valid value
    }
    const cleanedFilters = Object.fromEntries(
      Object.entries(filters).filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
    );

    console.log("filters", cleanedFilters);
    dispatch(setLoader(true));
    dispatch(fetchAdminReport(cleanedFilters))
      .then((results) => {
        console.log("results", results.payload);

        PageChange(1);
        dispatch(setLoader(false));
      })
      .catch(() => {
        dispatch(setLoader(false));
      });
  };

  const clearFilters = () => {
    setInputValueEmployeeName("");
    setSelectedEmployeeetails("");
    setInputValueEmailId("");
    setInputValueReportingTo("");
    setStatus("");
    setSIA("");
    setFromDate(today);
    setToDate(today);
    setRequestReceivedDate("");
    setRequestCompletedDate("");
    setBillable("");
    setBillableValue(null);
    setInputValueClientName("");
    setRequest("");
    setService("");
    setTask("");
    setStatusValue("");
    setTimezone("");
    setProductivetype("");
    const filters = {
      startDateTime: today,
      endDateTime: today,
    };
    dispatch(fetchAdminReport(filters))
      .then((results) => {
        console.log("results", results.payload);

        PageChange(1);
      })
      .catch(() => {});
  };

  return (
    <div
      className="row filter-row align-items-center"
      style={{
        gap: "15px",
        marginBottom: "30px",
        padding: "20px",
        backgroundColor: "#e8e7e7",
        borderRadius: "20px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      }}
    >
      <div
        className="d-flex gap-3 align-items-center"
        style={{
          flexWrap: "wrap",
          justifyContent: "space-between",
          maxWidth: "100%", // Ensure it fits within the page
        }}
      >
        {/* Field Wrapper to group 4 per row */}
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            gap: "15px",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          {/* Employee Name */}
          <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
            <label
              htmlFor="employeeName"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Employee Name
            </label>
            <select
              id="employeeName"
              value={inputValueEmployeeName}
              onChange={handleEmployeeChange}
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
                height: "39px",
              }}
            >
              <option>Select</option>
              {[...Employee]
                .filter((user) => {
                  // Filter based on the current page and employee status
                  if (currentPage === "SalesReport") {
                    return (
                      user.department === "Sales" &&
                      user.employeestatus === "Active"
                    );
                  }
                  if (currentPage === "ClientReport") {
                    return (
                      user.department === "Operations" &&
                      user.employeestatus === "Active"
                    );
                  }
                  // For other pages, show active employees only
                  return user.employeestatus === "Active";
                })

                .map((user, index) => (
                  <option
                    key={user.id}
                    value={`${user.firstname} ${user.lastname}`}
                  >
                    {`${user.firstname} ${user.lastname}`}
                  </option>
                ))}
            </select>
          </div>

          {/* Email ID */}
          <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
            <label
              htmlFor="emailId"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Email ID
            </label>
            <input
              type="email"
              id="emailId"
              value={inputValueEmailId}
              onChange={(e) => setInputValueEmailId(e.target.value)}
              placeholder="Enter Email"
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
                height: "39px",
              }}
            />
          </div>

          {/* From Date */}
          <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
            <label
              htmlFor="fromDate"
              style={{ display: "block", marginBottom: "5px" }}
            >
              From Date
            </label>
            <input
              type="date"
              value={fromDate}
              max={today}
              min={maxFromDate}
              onChange={(e) => setFromDate(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
                height: "39px",
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>

          {/* To Date */}
          <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
            <label
              htmlFor="toDate"
              style={{ display: "block", marginBottom: "5px" }}
            >
              To Date
            </label>
            <input
              type="date"
              value={toDate}
              max={today}
              min={fromDate}
              onChange={(e) => setToDate(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
                height: "39px",
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>

          {/* Billable */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="billable"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Billable
              </label>
              <Select
                options={options}
                placeholder="Select"
                value={billable}
                onChange={handleBillableChange}
                styles={customStyles}
                menuPlacement="auto"
                id="billable"
                style={{
                  width: "100%",
                  height: "39px",
                }}
              />
            </div>
          )}

          {/* Reporting To */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="ReportingTo"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Reporting To
              </label>
              <input
                type="text"
                id="ReportingTo"
                value={inputValueReportingTo}
                onChange={(e) => setInputValueReportingTo(e.target.value)}
                placeholder="Enter Reporting Manaager Name"
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              />
            </div>
          )}

          {/* Client Name */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="clientName"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Client Name
              </label>
              <select
                id="clientName"
                value={inputValueClientName}
                onChange={(e) => setInputValueClientName(e.target.value)}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              >
                <option value={""}>Select </option>
                {clientOptions?.filter(client => client.status === "Active")?.map((client, index) => (
                  <option key={index} value={client.clientName}>
                    {client.clientName}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Service */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="service"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Service
              </label>
              <select
                id="service"
                value={service}
                onChange={(e) => setService(e.target.value)}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              >
                <option value="">Select</option>
                {DataBucket.PROCESS_TYPE_CONFIG.map((item, key) => (
                  <option key={key} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </div>
          )}

          {/* Task */}
          <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
            <label
              htmlFor="task"
              style={{ display: "block", marginBottom: "5px" }}
            >
              Task
            </label>
            <select
              id="task"
              value={task}
              onChange={(e) => setTask(e.target.value)}
              style={{
                padding: "10px",
                width: "100%",
                borderRadius: "5px",
                border: "1px solid #ccc",
                height: "39px",
              }}
            >
              <option value="">Select</option>
              <option value="Available">Available</option>
              <option value="Break">Break</option>
              <option value="Meeting">Meeting</option>
              <option value="Admin">Admin</option>
              <option value="Management">Management</option>
              <option value="Training">Training</option>
              <option value="Cross-Training">Cross-Training</option>
              <option value="No-work Assigned">No-work Assigned</option>
            </select>
          </div>

          {/* Request # */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="requestNumber"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Request
              </label>
              <input
                type="text"
                id="requestNumber"
                value={request}
                placeholder="Enter Request Number"
                onChange={(e) => setRequest(e.target.value)}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              />
            </div>
          )}
          {/* Request # Received */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="requestRecieved"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Request Received
              </label>
              <input
                type="date"
                id="requestRecieved"
                value={requestReceivedDate}
                onChange={(e) => setRequestReceivedDate(e.target.value)}
                max={new Date().toISOString().split("T")[0]}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          )}

          {/* Request # Completed */}
          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="requestCompleted"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Request Completed
              </label>
              <input
                type="date"
                id="requestCompleted"
                value={requestCompletedDate}
                max={new Date().toISOString().split("T")[0]}
                onChange={(e) => setRequestCompletedDate(e.target.value)}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
                onKeyDown={(e) => {
                  e.preventDefault();
                }}
              />
            </div>
          )}

          {/* Status Dropdown */}

          {currentPage === "/ClientReport" && (
            <div style={{ marginBottom: "15px" }}>
              <label
                htmlFor="status"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Status
              </label>
              <Select
                options={statusOptions}
                placeholder="Select"
                onChange={handleStatusChange}
                value={status}
                styles={customStyles}
                menuPlacement="auto"
                id="status"
                style={{
                  padding: "10px",
                  width: "100%", // Set fixed width for consistent alignment
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px", // Set height consistent with other elements
                }}
              />
            </div>
          )}

          {currentPage === "/ClientReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="SIA"
                style={{ display: "block", marginBottom: "5px" }}
              >
                SIA Missed
              </label>
              <select
                id="SIA"
                value={SIA}
                styles={customStyles}
                onChange={(e) => setSIA(e.target.value)}
                style={{
                  padding: "10px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              >
                <option value="False">False</option>
                <option value="True">True</option>
              </select>
            </div>
          )}
          {currentPage === "/SalesReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="timezone"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Time Zone
              </label>
              <select
                id="timeZone"
                value={timezone}
                onChange={(e) => setTimezone(e.target.value)}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              >
                <option value="">Select</option>
                <option value="Eastern Time">Eastern Time</option>
                <option value="Central Time">Central Time</option>
                <option value="Mountain Time">Mountain Time</option>
                <option value="Pacific Time">Pacific Time</option>
                <option value="Hawaii Time">Hawaii Time</option>
              </select>
            </div>
          )}
          {currentPage === "/SalesReport" && (
            <div style={{ flex: "1 1 calc(25% - 15px)", marginBottom: "15px" }}>
              <label
                htmlFor="productivetype"
                style={{ display: "block", marginBottom: "5px" }}
              >
                Productive Type
              </label>
              <select
                id="productivetype"
                value={productivetype}
                onChange={handleProductivetypeChange}
                style={{
                  padding: "10px",
                  width: "100%",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  height: "39px",
                }}
              >
                <option value="">Select</option>
                <option value="Productive">Productive</option>
                <option value="Non Productive">Non Productive</option>
              </select>
            </div>
          )}

          {/* Clear Button */}
          <div
            className="d-flex justify-content-end"
            style={{ marginBottom: "15px", flex: "1 1 calc(25% - 15px)" }}
          >
            <div style={{ marginLeft: "5px", marginBottom: "15px" }}>
              <label
                style={{
                  visibility: "hidden",
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                Clear
              </label>
              <button
                onClick={clearFilters}
                style={{
                  padding: "10px 30px",
                  margin: "5px",
                  width: "100px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  color: "#fff",
                  cursor: "pointer",
                  height: "39px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  background: "#837f7f",
                }}
              >
                Clear
              </button>
            </div>

            <div style={{ marginBottom: "15px" }}>
              <label
                style={{
                  visibility: "hidden",
                  display: "block",
                  marginBottom: "5px",
                }}
              >
                Search
              </label>
              <button
                // className="btn btn-primary"
                onClick={handleSearch}
                disabled={shouldDisableSearchButton}
                style={{
                  padding: "10px 15px",
                  width: "100px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                  color: "#fff",
                  cursor: "pointer",
                  height: "39px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "#1677ff",
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeReportFilter;
