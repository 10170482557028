import React, { useState, useEffect, memo } from "react";
import CommentField from "./CommentField";
import {
  Button,
  Divider,
  Form,
  Input,
  Select,
  Switch,
  Typography,
  Row,
  Col,
  DatePicker,
  TimePicker,
} from "antd";
import { Formik, FieldArray, Field, useFormikContext } from "formik";
import { useMutation } from "@apollo/client";
import { timerHistoryPayloadChange } from "../../../Slices/user";
import { settaskStartTime } from "../../../Slices/user";
import { Starttask } from "../../../Slices/timerslice";
import { addLastContactedDate, removeLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import { setIsRefetch } from '../../../Slices/salesSlice';
import { UPDATE_WORKHISTORY } from "../../../Queries/Orders";
import { useDispatch, useSelector } from "react-redux";
import * as DataBucket from "../../../databucket";
import dayjs from "dayjs";
import Swal from "sweetalert2";
import { renderOptions } from '../../../utilsdata';
const CallingTrack = memo((props) => {

  const salesLeadId = useSelector((state) => state?.sales?.sale?.leadID);
  const WorkingId = useSelector((state) => state?.timer?.requestId);
  const CurrentTask = 'Calling';
  const dispatch = useDispatch();
  const task = useSelector((state) => state.timer.task);
  const [isRunning, setIsRunning] = useState(false);
  const [updateWorkHistory, { data, loading, error }] = useMutation(UPDATE_WORKHISTORY);
  const { SalesInfo, assignToUsers, getBy, setFieldValue, id, leadID } = props;
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext();

  const [spokeWith, setSpokeWith] = useState(values.leadsCalls?.map(call => call.spokeWith) || []);
  const [remarks, setRemarks] = useState(values.leadsCalls?.map(call => call.remarks) || []);

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setSpokeWith([]);
      setRemarks([]);
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const token = localStorage.getItem("token");
  const options = useSelector((state) => state.dataBucket.databucket);

  const [localErrors, setLocalErrors] = useState([]);

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const newSpokeWith = [...spokeWith];
    newSpokeWith[index] = value;

    // Local validation: only letters and spaces allowed
    // let errorMessage = "";
    // if (value && !/^[A-Za-z\s]+$/.test(value)) {
    //   errorMessage = "Spoke with must contain only letters and spaces";
    // }

    // const newErrors = [...localErrors];
    // newErrors[index] = errorMessage;

    setSpokeWith(newSpokeWith);
    // setLocalErrors(newErrors);
  };

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const handleStartButtonClick = async () => {
    const payload = {
      department: "Sales",
      task: "Calling",
      time: Date.now(),
      requestId: SalesInfo?.leadID || salesLeadId,
    };

    try {
      const response = await updateWorkHistory({
        variables: {
          userId: parseJwt(token).id,
          emailId: "",
          requestId: SalesInfo?.leadID.toString() || salesLeadId,
          clientName: "Bluepalnit",
          processType: "",
          taskType: "Calling",
          email: "",
          subject: "",
          body: "",
          startTime: Date.now().toString(),
          endTime: "",
        },
      });
    } catch (err) {
      console.error("Error updating work history:", err);
    }
    localStorage.setItem("task", "Calling");
    localStorage.setItem("department", "Sales");
    localStorage.setItem("requestId", SalesInfo?.leadID || salesLeadId);
    localStorage.setItem("time", Date.now());
    // dispatch(updateAReport(Reportpayload));
    dispatch(Starttask(payload));
  };
  const handleStop = async () => {
    setIsRunning(false);

    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),
      endTime: Date.now().toString(),
      requestId: "",
    };
    //setRefetch(true);
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
    await updateWorkHistory({
      variables: {
        userId: parseJwt(token).id,
        emailId: "",
        requestId: "",
        clientName: "Bluepalnit",
        processType: "",
        taskType: "Available",
        email: "",
        subject: "",
        body: "",
        startTime: Date.now().toString(),
        endTime: "",
      },
    });
    const payload = {
      department: "",
      task: "Available",
      requestId: "",
      time: Date.now(),
    };
    localStorage.setItem("StartTime", new Date().getTime());
    localStorage.removeItem('department');
    localStorage.removeItem('requestId');
    dispatch(settaskStartTime(new Date().getTime()));
    dispatch(Starttask(payload));
  };
  const handleAddRow = (arrayHelpers) => {

    const newDate = dayjs(new Date());
    setFieldValue("lastContactedDate", newDate);
    arrayHelpers.push({
      date: newDate || "",
      time: dayjs().set("second", 0).set("millisecond", 0) || "",
      status: "Attempt",
      spokeWith: "",
      callby: getBy(null),
      emailSent: false,
      remarks: "",
    });

    // Add the new date to Redux state
    dispatch(addLastContactedDate(newDate));

  };

  const lastContactedDates = useSelector(state => state.lastContactedDates);
  console.log("lastDates", lastContactedDates)

  const handleDeleteRow = (index, arrayHelpers) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this call record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {

        arrayHelpers.remove(index); // Remove the call record

        // Remove the corresponding entry from spokeWith and remarks local states
        setSpokeWith(prev => prev.filter((_, i) => i !== index));
        setRemarks(prev => prev.filter((_, i) => i !== index));

        setLocalErrors((prev) => prev.filter((_, i) => i !== index));

        // Get the date of the row being deleted from `leadsCalls`
        const deletedRowDate = arrayHelpers.form.values.leadsCalls[index].date;

        const lastContactedDatess = lastContactedDates.filter(date => dayjs(date).valueOf() !== dayjs(deletedRowDate).valueOf());

        const newDate = dayjs(new Date());
        const latestDate = lastContactedDatess.length > 0
          ? Math.max(...lastContactedDatess.map(date => dayjs(date).valueOf()))
          : null;

        dispatch(setLastContactedDates(lastContactedDatess));

        if (latestDate) {
          setFieldValue("lastContactedDate", dayjs(latestDate));
        }
      }
    });

  };


  const getCallByUser = () => {
    let callbyuser = "";

    let calluser = assignToUsers.filter(
      (item) => item.id == parseJwt(token)?.id
    );
    if (calluser?.length > 0) {
      callbyuser = calluser[0]?.firstname;
    }
    return callbyuser;
  };
  const getCallBy = (callby) => {
    let callbyuser = "";
    if (callby == "") {
      return callbyuser;
    } else if (callby == undefined) {
      let calluser = assignToUsers.filter(
        (item) => item.id == parseJwt(token)?.id
      );
      if (calluser?.length > 0) {
        callbyuser = calluser[0]?.firstname;
      }
    } else {
      let calluser = assignToUsers.filter(
        (item) => item.firstname.toLowerCase() == callby?.toLowerCase()
      );
      if (calluser?.length > 0) {
        callbyuser = calluser[0]?.firstname;
      }

      return callbyuser;
    }
  };
  const getTime = (time) => {
    let formatedTime = "";
    if (time != undefined && time != "") {
      if (typeof time === "object") {
        formatedTime = time;
      } else if (typeof time === "string") {
        if (time?.includes("T")) {
          let tempdatetime = time?.split("T");
          let temptime = tempdatetime[1]?.split(".");
          time = temptime[0];
        }

        let timeArray = time?.split(":");
        formatedTime = dayjs()
          .set("hour", timeArray[0])
          .set("minute", timeArray[1])
          .set("second", timeArray[2]);
      }
    } // Set the desired time
    return formatedTime;
  };

  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != "") {
      if (typeof datevalue === "string" && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue));
      } else if (typeof datevalue === "object") {
        finalDate = datevalue;
      }
    }
    return finalDate;
  };

  return (
    <div
      style={{
        marginTop: "15px",
        backgroundColor: "#fff",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography.Title level={4}>
        Calling Track{" "}
        {
          id !== undefined && (
            WorkingId === leadID && task === CurrentTask ? (
              <Button
                color="danger"
                variant="solid"
                style={{
                  float: 'right',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleStop}
              >
                Stop
              </Button>
            ) : (
              <Button
                color="primary"
                variant="solid"
                style={{
                  float: 'right',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                onClick={handleStartButtonClick}
              >
                Start
              </Button>
            )
          )
        }

      </Typography.Title>

      <FieldArray
        name="leadsCalls"
        render={(arrayHelpers) => (
          <>
            {values?.leadsCalls?.map((Callinginfo, index) => (
              <div key={index} style={{ marginBottom: "1rem" }}>
                <Row gutter={16}>
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // marginBottom: "20px",
                      marginTop: '15px'
                    }}
                  >
                    <Typography.Text strong>
                      Call No. {index + 1}
                    </Typography.Text>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        color: "#00c5fb",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => handleDeleteRow(index, arrayHelpers)}
                    />
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label="Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <DatePicker
                        inputReadOnly={true}
                        allowClear={false}
                        format="DD/MM/YYYY"
                        name={`leadsCalls.${index}.date`}
                        value={getDateValue(Callinginfo?.date)}
                        onChange={(date) =>
                          handleChange({
                            target: {
                              value: date,
                              name: `leadsCalls.${index}.date`,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Time"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TimePicker
                        inputReadOnly={true}
                        format="h:mm A"
                        allowClear={false}
                        name={`leadsCalls.${index}.time`}
                        value={getTime(Callinginfo?.time)}
                        onChange={(date) =>
                          handleChange({
                            target: {
                              value: date,
                              name: `leadsCalls.${index}.time`,
                            },
                          })
                        }
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Call Status"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      validateStatus={
                        touched.leadsCalls?.[index]?.status &&
                          errors.leadsCalls?.[index]?.status
                          ? "error"
                          : ""
                      }
                      help={
                        touched.leadsCalls?.[index]?.status &&
                        errors.leadsCalls?.[index]?.status
                      }
                    >
                      <Field name={`leadsCalls.${index}.status`}>
                        {({ field, form }) => (
                          <Select
                            value={field.value || ""}
                            onChange={(value) =>
                              form.setFieldValue(field.name, value)
                            }
                          >
                            {renderOptions(options, { name: "SALES_CALL_STATUS" }, DataBucket.SALES_CALL_STATUS)}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label={
                        <span>
                          Spoke with
                          {/* {Callinginfo?.status === "Attempt" && <span style={{ color: "red" }}>*</span>} */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      validateStatus={
                        touched.leadsCalls?.[index]?.spokeWith &&
                          errors.leadsCalls?.[index]?.spokeWith
                          ? "error"
                          : ""
                      }
                      help={
                        touched.leadsCalls?.[index]?.spokeWith &&
                          errors.leadsCalls?.[index]?.spokeWith
                          ? errors.leadsCalls?.[index]?.spokeWith
                          : ""
                      }
                    // validateStatus={
                    //   (touched.leadsCalls?.[index]?.spokeWith && errors.leadsCalls?.[index]?.spokeWith) || localErrors[index]
                    //     ? "error"
                    //     : ""
                    // }
                    // help={
                    //   localErrors[index] ||
                    //   (touched.leadsCalls?.[index]?.spokeWith && errors.leadsCalls?.[index]?.spokeWith)
                    // }
                    >
                      <Input
                        // onChange={(e) => {
                        //   const newSpokeWith = [...spokeWith];
                        //   newSpokeWith[index] = e.target.value;
                        //   setSpokeWith(newSpokeWith);
                        // }}
                        onChange={(e) => handleInputChange(e, index)}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsCalls[${index}].spokeWith`,
                              value: e.target.value,
                            },
                          });
                        }}
                        value={spokeWith[index]}
                      />

                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <Form.Item
                      label="Call By"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsCalls.${index}.callby`}>
                        {({ field, form }) => (
                          <Select
                            showSearch
                            value={field.value || ""} // Bind Formik field value, default to an empty string if undefined
                            onChange={(value) =>
                              form.setFieldValue(field.name, value)
                            } // Explicitly set the value in Formik state
                            style={{ width: "100%" }}
                          >
                            {assignToUsers?.map((user, key) => (
                              <Select.Option
                                key={key}
                                value={`${user?.firstname} ${user?.lastname}`}
                              >
                                {`${user?.firstname} ${user?.lastname}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  <Col span={4}>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <Form.Item label="Email Sent" labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}>
                        <Switch
                          name={`leadsCalls.${index}.emailSent`}
                          onChange={(checked) =>
                            handleChange({
                              target: {
                                value: checked,
                                name: `leadsCalls.${index}.emailSent`,
                              },
                            })
                          }
                          checked={Callinginfo?.emailSent}
                        />
                      </Form.Item>
                    </div>

                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label="Remarks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.TextArea
                        onChange={(e) => {
                          const newRemarks = [...remarks];
                          newRemarks[index] = e.target.value;
                          setRemarks(newRemarks);
                        }}
                        value={remarks[index]}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsCalls[${index}].remarks`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />

                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ backgroundColor: "#ccc" }} />
              </div>
            ))}
            <Row justify="end" style={{ marginTop: "16px" }}>
              <Button
                // className="btn btn-primary"
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={() => handleAddRow(arrayHelpers)}
              >
                Add Row
              </Button>
            </Row>
          </>
        )}
      />
      <CommentField label="Calling Comments" name="callingComments" />
    </div>
  );
});

export default CallingTrack;
