
import React, { useState } from "react";
import { Select, Button } from "antd";
import "./PayslipReport.css";
import Payslip from "./PayslipPDF";
import { PDFDownloadLink } from '@react-pdf/renderer';


const { Option } = Select;

const PayslipReport = () => {
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth());

    const monthNames = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const employee = {
        employeeId: "EMP001",
        employeeName: "Sowmya Gorajana",
        designation: "Software Engineer",
        ctcBasic: 20000,
        ctcHRA: 10000,
        ctcMedical: 3000,
        ctcSpecial: 5000,
        ctcIncentives: 8000,
        ctcNightShift: 4000,
        earningBasic: 19500,
        earningHRA: 9500,
        earningMedical: 3000,
        earningSpecial: 5000,
        earningIncentives: 8000,
        earningNightShift: 4000,
        performanceBonus: 2000,
        professionalTax: 500,
        tardyArrival: 300,
    };

    const totals = {
        totalCTC: employee.ctcBasic + employee.ctcHRA + employee.ctcMedical + employee.ctcSpecial + employee.ctcIncentives + employee.ctcNightShift,
        totalEarnings: employee.earningBasic + employee.earningHRA + employee.earningMedical + employee.earningSpecial + employee.earningIncentives + employee.earningNightShift + employee.performanceBonus,
        totalDeductions: employee.professionalTax + employee.tardyArrival,
    };

    const netPayment = totals.totalEarnings - totals.totalDeductions;

    // const handleDownloadAsImage = () => {
    //     alert("Downloading..");
    // };
    const handleGetDetails = () => {
        console.log("");
    };

    return (
        <div className="payslip-container">
            <div className="payslip-report">
                <div className="payslip-header">
                    <div className="left-section">
                        <Select value={year} onChange={setYear} style={{ width: 120 }}>
                            {[2025, 2024].map((yr) => <Option key={yr} value={yr}>{yr}</Option>)}
                        </Select>

                        <Select value={month} onChange={setMonth} style={{ width: 120 }}>
                            {monthNames.map((m, index) => (
                                <Option key={index} value={index}>{m}</Option>
                            ))}
                        </Select>

                        <Button type="primary" onClick={handleGetDetails}>
                            Get Details
                        </Button>
                    </div>
                    <PDFDownloadLink
                        document={<Payslip employee={employee} totals={totals} year={year} month={monthNames[month]} />}
                        fileName={`Payslip_${employee.employeeName}_${monthNames[month]}_${year}.pdf`}
                    >
                        {({ loading }) => (
                            <Button type="primary">
                                {loading ? 'Generating PDF...' : 'Download Payslip'}
                            </Button>
                        )}
                    </PDFDownloadLink>
                </div>

                <div className="employee-details">
                    <div className="employee-id">
                        Employee ID: {employee.employeeId}
                    </div>
                    <div className="employee-name">
                        Employee Name: {employee.employeeName}
                    </div>
                    <div className="designation">
                        Designation: {employee.designation}
                    </div>
                </div>

                <div className="payslip-sections">
                    <div className="section">
                        <h4>CTC Rates</h4>
                        <p><span>Basic:</span><span>{employee.ctcBasic}</span></p>
                        <p><span>HRA:</span><span>{employee.ctcHRA}</span></p>
                        <p><span>Medical Allowance:</span><span>{employee.ctcMedical}</span></p>
                        <p><span>Special Allowance:</span><span>{employee.ctcSpecial}</span></p>
                        <p><span>Incentives:</span><span>{employee.ctcIncentives}</span></p>
                        <p><span>Night Shift Allowance:</span><span>{employee.ctcNightShift}</span></p>
                    </div>

                    <div className="section">
                        <h4>Earnings</h4>
                        <p><span>Basic:</span><span>{employee.earningBasic}</span></p>
                        <p><span>HRA:</span><span>{employee.earningHRA}</span></p>
                        <p><span>Medical Allowance:</span><span>{employee.earningMedical}</span></p>
                        <p><span>Special Allowance:</span><span>{employee.earningSpecial}</span></p>
                        <p><span>Incentives:</span><span>{employee.earningIncentives}</span></p>
                        <p><span>Night Shift Allowance:</span><span>{employee.earningNightShift}</span></p>
                        <p><span>Performance Bonus:</span><span>{employee.performanceBonus}</span></p>
                    </div>

                    <div className="section">
                        <h4>Deductions</h4>
                        <p><span>Professional Tax:</span><span>{employee.professionalTax}</span></p>
                        <p><span>Tardy Arrival:</span><span>{employee.tardyArrival}</span></p>
                    </div>
                </div>

                <div className="totals-row">
                    <div>Total CTC: Rs. {totals.totalCTC}</div>
                    <div>Total Earnings: Rs. {totals.totalEarnings}</div>
                    <div>Total Deductions: Rs. {totals.totalDeductions}</div>
                </div>

                <div className="net-payment">
                    Net Payment: Rs. {netPayment}
                </div>
            </div>
        </div>
    );
};

export default PayslipReport;



