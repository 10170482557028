import { gql } from '@apollo/client';

export const GET_EMPLOYEE_CLIENTS=gql`
query getEmployeeClients($id:String!){
  getEmployeeClients(id: $id) {
    response{
    id
    EmpID
    role
    createdDate
    firstname
    lastname
    designation
    department
    reportingManager
    signedIn
    employeestatus
    gender
    clients
    dob
    image
    maritalStatus
    bloodGroup
    currentAddress
    permanentAddress
    mobile
    alternativeNumber
    personalEmail
    email
    adharNumber
    PanNumber
    BankAccountNo
    BankName
    IFCcode
    Branch
    PfNo
    UAN
    EsiNo
    DateOfJoining
    EmploymentType
    ShiftTimings
    ProbationStatus
    clientKey
    Experience {
      years
      months
    }
    WorkType
    HealthInsuranceNo
    familyMembers {
      name
      relationship
      dob
      age
      dependent
    }
    emergencyContacts {
      name
      relationship
      phoneNumber
    }
    education {
      educationLevel
      college
      university
      from
      to
      degree
      fieldOfStudy
      typeOfStudy
    }
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
    teamsReportIngManager {
      reportingManagerName
      reportingManagerType
      reportingManagerDepartment
      reportingManagerDesignation
    }
    teamsReportDirect {
      DirectReportName
      DirectReportType
      DirectReportDepartment
      DirectReportDesignation
    }
    workHistory {
      department
      designation
      clientHandled
      processHandled
     
      from
      to
    }
     

  }
}
}
`


// export const GET_EMPLOYEE_CLIENTS = gql`
//   query {
//     getEmployeeClients {
//       status
//       message
//       response{
//         id
//         clientName
        
//       }
//     }
//   }
// `;