import * as React from 'react';
import { useState, useEffect } from 'react';
import { Collapse, Table, Typography, Row, Col, Paper } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery, useMutation } from '@apollo/client';
import { GET_ALLWORKHISTORY_BY_ID, GET_ALLSTATUSWORKHISTORY_BY_ID, CREATE_ATTACHEMNTS, GET_ALLATTACHMENTS_BY_ID, UPLOAD_FILE, UPDATE_ATTACHMENTS, DELETE_ATTACHMENTS } from '../../../Queries/Orders';
import dayjs from 'dayjs';
import { GET_USER_DETAILS, GET_ALL_USERS } from '../../../Queries/Users';
import { GET_ALL_LEAD_WORKHISTORY_BY_ID } from '../../../Queries/Leads';
import { setSalesWorkHistory } from '../../../Slices/salesSlice';

function createData(name, calories, fat, carbs, protein) {
  return { name, calories, fat, carbs, protein };
}
const requestWorkHistory1 = [
  createData('01/01/2023 10:35:00 A.M.', 'Open Request', 'User Name'),
  createData('01/01/2023 10:25:00 A.M.', 'Email', 'User Name'),

];

export default function RequestWorkHistory(props) {

  const { SalesInfo, assignToUsers } = props;

  // const [workHistoryDetails, setWorkHistoryDetails] = useState([])
  // const [leadWorkHistoryDetails, setLeadWorkHistoryDetails] = useState([])

  const dispatch = useDispatch()
  const sales = useSelector((state) => state?.sales?.sale);

  const workHistoryObj = {
    leadId: SalesInfo?.id,
  }

  // const [getAllWorkHistoryByID, { }] = useMutation(GET_ALLWORKHISTORY_BY_ID, {
  //     onCompleted(data) {
  //         setWorkHistoryDetails(data.getAllWorkHistoryByID)
  //     },
  // });

  const [getAllLeadWorkHistoryByID, { }] = useMutation(GET_ALL_LEAD_WORKHISTORY_BY_ID, {
    onCompleted(data) {
      // setLeadWorkHistoryDetails(data.getAllLeadWorkHistoryByID)
      dispatch(setSalesWorkHistory(data.getAllLeadWorkHistoryByID))
    },
  });

  const leadWorkHistoryDetails = useSelector((state) => state.sales.salesWorkHistory)
  // console.log('workhistories',workhistories)

  useEffect(() => {
    // getAllWorkHistoryByID({ variables: workHistoryObj });
    getAllLeadWorkHistoryByID({ variables: workHistoryObj });

  }, []);


  const getDateTime = (timeValue) => {
    const startDate = new Date(timeValue).toLocaleDateString();
    const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

    const dateTimeString = `${startDate} ${startTimeString}`;

    return dateTimeString;
  }


  const getUserName = (userid) => {
    let userName = 'anonymous'
    assignToUsers?.map((userdetails) => {
      if (userdetails?.id == userid) {
        userName = userdetails?.firstname
      }
    })
    return userName;
  }

  const data = [
    ...leadWorkHistoryDetails.map((row, index) => ({
      key: index,
      dateTime: dayjs(parseInt(row.changeTime)).format('DD/MM/YYYY hh:mm:ss A'),
      status: row.leadStatus,
      userName: row.userName,
    })).reverse(), ,
    {
      key: "salesInfo",
      dateTime: dayjs(parseInt(SalesInfo.createdDate)).format('DD/MM/YYYY hh:mm:ss A'),
      status: "Created",
      userName: SalesInfo.createdBy,
    },
  ];

  const columns = [
    {
      title: "Date & Time",
      dataIndex: "dateTime",
      key: "dateTime",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
    {
      title: "UserName",
      dataIndex: "userName",
      key: "userName",
      align: "center",
      render: (text) => <div style={{ padding: "10px" }}>{text}</div>,
    },
  ];
  return (
    <div style={{ width: '100%', marginTop: '15px', backgroundColor: "#fff", padding: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <Typography.Title level={5} style={{ margin: 0, padding: '5px' }}>
        Sales Work History
      </Typography.Title>
      <Table
        columns={columns.map((column) => ({
          ...column,
          align: 'center', // Center align all columns
        }))}
        dataSource={data}
        pagination={false}
        bordered
        style={{ width: '100%', margin: '0 auto' }}
        size="small"
      />
    </div>
  );
}