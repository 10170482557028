// ** Third Party Imports
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  orders: [],
  order: {},
  singleOrder:{},
  reportData: [],
  summary:{},
  TotalCount: 0,
  requestInfo: [],
  requestDetails: [],
  refreshPage: null,
  instructionsOrders: [],
  getAllClients:[],
};
const ordersSlice = createSlice({
  name: "orders",
  initialState,
  reducers: {
    getOrders: (state, action) => {
      state.orders = action.payload;
      console.log("state",state,action)
    },
    getSingleOrder: (state,action)=>{
      state.singleOrder = action.payload
    },
    updateRequestInfo: (state, action) => {
      state.singleOrder = { ...state.singleOrder, ...action.payload };
    },
    getRequest: (state,action)=> {
      state.order = action.payload
    },
    updateOrders: (state, action) => {
      state.orders = [action.payload, ...state.orders];
    },
    getReportData: (state,action)=>{
      state.reportData = action.payload;
    },
    getSummaryData: (state,action)=>{
      state.summary = action.payload;
    },
    TotalCount: (state, action) => {
      state.TotalCount = action.payload;
    },
    editOrder: (state, action) => {
      state.orders = state.orders.map((order) =>
      order.id === action.payload.id ? action.payload : order
      );
    },
    requestInfoData: (state,action)=>{
      state.requestInfo = action.payload;
      console.log("action.payload",action.payload)
    },
    requestDetailsData: (state,action)=>{
      state.requestDetails = action.payload;
    },
    submitRefreshPage: (state,action)=>{
      state.refreshPage = action.payload;
    }
  },
  storeOrderUnderInstructions: (state, action) => {
    state.instructionsOrders.push(action.payload);
  },
  // Action to remove an order from instructions
  removeOrderFromInstructions: (state, action) => {
    state.instructionsOrders = state.instructionsOrders.filter(
      (order) => order.id !== action.payload.id
    );
  },

  getAllClientsData: (state,action)=>{
    console.log("dacadcdac",action.payload)
    state.getAllClients = action.payload
    console.log("dacadcdac2",state.getAllClients)
  },
});

export const { getOrders,getSingleOrder,updateRequestInfo,getReportData,editOrder,requestInfoData,requestDetailsData,TotalCount, getSummaryData,updateOrders,getRequest, submitRefreshPage,storeOrderUnderInstructions,removeOrderFromInstructions, getAllClientsData } = ordersSlice.actions;

export default ordersSlice.reducer;
