import { gql } from '@apollo/client';
 
export const GET_EMPLOYEE_DETAILS=gql`
query {
  EmployeeDetail {
    response{
    id
    EmpID
    role
    createdDate
    firstname
    lastname
    token
    designation
    department
    reportingManager
    signedIn
    employeestatus
    gender
    clients
    dob
    image
    maritalStatus
    bloodGroup
    currentAddress
    permanentAddress
    mobile
    alternativeNumber
    personalEmail
    email
    adharNumber
    PanNumber
    BankAccountNo
    BankName
    IFCcode
    Branch
    PfNo
    UAN
    EsiNo
    DateOfJoining
    EmploymentType
    ShiftTimings
    ProbationStatus
    Experience {
      years
      months
    }
    WorkType
    HealthInsuranceNo
    familyMembers {
      name
      relationship
      dob
      age
      dependent
    }
    emergencyContacts {
      name
      relationship
      phoneNumber
    }
    education {
      educationLevel
      college
      university
      from
      to
      degree
      fieldOfStudy
      typeOfStudy
    }
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
    teamsReportIngManager {
      reportingManagerName
      reportingManagerType
      reportingManagerDepartment
      reportingManagerDesignation
    }
    teamsReportDirect {
      DirectReportName
      DirectReportType
      DirectReportDepartment
      DirectReportDesignation
    }
    workHistory {
      department
      designation
      clientHandled
      processHandled
     
      from
      to
    }
     

  }
}
}
`

export const EDIT_WORKHISTORY=gql`
mutation EditemployeeDetail($input:EditEmployeeInputType!)
{
EditemployeeDetail(input:$input)
{
  status
  message
}
}
`

 export const CREATE_PROFILE_ATTACHMENTA=gql`
 mutation createProfileAttachment($input:AttachemntsInputType)
 { 
    createProfileAttachment(input:$input){
       status
       
    }
 }
 `
 export const INSERT_ATTACHMENT = gql`
  mutation insertAttachment($id: String!, $data: [AttachemntsInputType]!) {
    insertAttachment(id: $id, input: $data) {
      status
      message
    }
  }
`;

 
 
export const SINGLE_USER = gql`
  query SingleEmployee($id: String!) {  
    SingleEmployee(ID: $id) {
      status
      message
      count
      response{
    id
    EmpID
    firstname
    lastname
    createdTime
    designation
    department
    reportingManager
    image
    role
    clients
    employeestatus
    reportingTo
    signedIn
    gender
    dob
     personalEmail
    maritalStatus
    bloodGroup
    currentAddress
    permanentAddress
   workNote
  clientNote
  teamNote
  EducationNote
  documentNote
  familyNote
    alternativeNumber
    mobile
    email
      adharNumber
      PanNumber
      BankAccountNo
      BankName
      IFCcode
      Branch
      PfNo
      UAN
      EsiNo
      image
      DateOfJoining
        employmentStatus
  resignationStatus
  noticePeriod
  resignationDate
  lastWorkingDay
  RehireEligibility
    EmploymentType
    ShiftTimings
    ProbationStatus
    probationPeriod
    processSplization
    reportingTo
      HealthInsuranceNo
    Experience {
      years
      months
    }
    WorkType
    HealthInsuranceNo
    familyMembers {
      name
      relationship
      dob
      age
      dependent
    }
       menu {
        name
        status
        Subfields {
        displayName
          name
          status
        }
      }
    emergencyContacts {
      name
      relationship
      phoneNumber
    }
    education {
      educationLevel
      college
      university
      from
      to
      degree
      fieldOfStudy
      typeOfStudy
    }
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
    teamsReportIngManager {
      reportingManagerName
      reportingManagerType
      reportingManagerDepartment
      reportingManagerDesignation
    }
    teamsReportDirect {
      DirectReportName
      DirectReportType
      DirectReportDepartment
      DirectReportDesignation
    }
    workHistory {
      department
      designation
      clientHandled
      processHandled
      shiftTimings
      from
      to
    }
    EditEmployee {
      id
    userName
    EditStatus
    action
    changeTime
      }
    leavesSettings {
          year
          department
          casualLeaves
          medicalLeave
          CarryforwardLeaves
          carryFlag
          Key
        }
  }
}
  }
`;
 
export const GET_EMPLOYEES = gql`
  query {
    EmployeesDetails {
      id
      firstName
      lastName
      middleName
      designation
      department
      reportingManager
      email
      signIn
      gender
      dateOfBirth
      maritalStatus
      officialEmailId
      personalEmailId
      phoneNumber
      alterNatePhone
      clients
      currentAddress
      permanentAddress
      AadharNumber
      PanNumber
      BankAccount
      BranchName
      IFSCcode
      BankName
      PFnumber
      UANnumber
      image
      ESICnumber
       workNote
  clientNote
  teamNote
  EducationNote
  documentNote
  familyNote
      HealthInsuranceNumber
      educationDetails{
       collegeName
       courseName
       branchName
       onType
       duration
       universityName
      }
      familyRelationShip{
       name
       dateOfBirth
       relationShip
       age
       dependent
       phoneNumber
      }
      reportingManagerDetails{
      name
      type
      department
      designation
      }
      clientDetails{
      clientName
      department
      process
      role
      slotNo
      }
    attachemnts{
  userId
emailId
data
filename
mimetype
addDate
updateDate
status
}
    }
  }
`;
 

export const REMOVE_ATTACHMENT = gql`
  mutation removeAttachment($id: String!, $userId: String! ,$input:AttachemntsInputType! ) {
    removeAttachment(id: $id, attachmentId: $userId ,input:$input ) {
       status
       message
}
  }
`;

 export const UPDATE_EMPLOYEE = gql`
  mutation updateEmploye($id:String ,$input:UpdateEmployeeInput){
 
 UpdateEmploye( id:$id ,input: $input) {
    status
    message
        response{
    id
    EmpID
    firstname
    lastname
    createdTime
    designation
    department
    reportingManager
    image
    role
    clients
    employeestatus
    reportingTo
    signedIn
    gender
    dob
     personalEmail
    maritalStatus
    bloodGroup
    currentAddress
    permanentAddress
   workNote
  clientNote
  teamNote
  EducationNote
  documentNote
  familyNote
    alternativeNumber
    mobile
    email
      adharNumber
      PanNumber
      BankAccountNo
      BankName
      IFCcode
      Branch
      PfNo
      UAN
      EsiNo
      image
      DateOfJoining
        employmentStatus
  resignationStatus
  noticePeriod
  resignationDate
  lastWorkingDay
  RehireEligibility
    EmploymentType
    ShiftTimings
    punchInStatus
    ProbationStatus
    probationPeriod
    processSplization
    reportingTo
      HealthInsuranceNo
    Experience {
      years
      months
    }
    WorkType
    HealthInsuranceNo
    familyMembers {
      name
      relationship
      dob
      age
      dependent
    }
       menu {
        name
        status
        Subfields {
        displayName
          name
          status
        }
      }
    emergencyContacts {
      name
      relationship
      phoneNumber
    }
    education {
      educationLevel
      college
      university
      from
      to
      degree
      fieldOfStudy
      typeOfStudy
    }
    client {
      NameOfClient
      processes
      role
      SlotNo
    }
    teamsReportIngManager {
      reportingManagerName
      reportingManagerType
      reportingManagerDepartment
      reportingManagerDesignation
    }
    teamsReportDirect {
      DirectReportName
      DirectReportType
      DirectReportDepartment
      DirectReportDesignation
    }
    workHistory {
      department
      designation
      clientHandled
      processHandled
      shiftTimings
      from
      to
    }
    EditEmployee {
      id
    userName
    EditStatus
    action
    changeTime
      }

  }
}
}`

// attachemnts{
//   userId
// emailId
// data
// filename
// mimetype
// addDate
// updateDate
// status
// }

// Fetch all attachments by email ID
// Fetch all attachments by email ID

// Fetch all modified attachments by email ID
export const FETCH_MODIFIED_ATTACHMENTS_BY_EMAIL = gql`
mutation fetchModifiedAttachmentsByEmail($emailId: String!) {
  getAllModifiedAttachmentsByID(emailId: $emailId) {
    id
    filename
    data
    mimetype
    addDate
    updateDate
    status
  }
}
`;



// Fetch all attachment history by user ID and email ID
// export const FETCH_ALL_ATTACHMENTS_BY_EMAIL = gql`
//   mutation fetchAllAttachmentsByEmail($emailId: String!) {
//     fetchAllAttachmentsByEmail(emailId: $emailId) {
//       id
//       filename
//       data
//       mimetype
//       addDate
//       updateDate
//       status
//     }
//   }
// `;

// export const FETCH_ATTACHMENT_HISTORY_BY_USER_AND_EMAIL = gql`
//   mutation fetchAttachmentHistoryByUserAndEmail($userId: String!, $emailId: String!) {
//     fetchAttachmentHistoryByUserAndEmail(userId: $userId, emailId: $emailId) {
//       id
//       filename
//       addDate
//       updateDate
//       status
//     }
//   }
// `;



// Update attachment
export const MODIFY_ATTACHMENT_DETAILS = gql`
  mutation modifyAttachmentDetails( $id:String
    $input:AttachemntsInputType!
  ) {
    modifyAttachmentDetails(
    attachmentid:$id
     input:$input
    ) {
      id
      filename
      data
      mimetype
      addDate
      updateDate
      status
     
    }
  }
`;



// Delete attachment


// File upload
// export const CREATE_ATTACHMENT = gql`
//   mutation createAttachment($userId: String!, $emailId: String!, $data: String!, $filename: String!, $mimetype: String!, $addDate: String!, $updateDate: String!, $status: Boolean!) {
//     createAttachments(userId: $userId, emailId: $emailId, data: $data, filename: $filename, mimetype: $mimetype, addDate: $addDate, updateDate: $updateDate, status: $status) {
//       id
//       filename
//       data
//       mimetype
//       addDate
//       updateDate
//       status
//     }
//   }
// `;
export const GET_ALL_PROFILE_ATTACHMENTS = gql`
  query GetAllProfileHistoryAttachments($userid: String!, $empid: String!) {
    GetAllProfileistoryAttachmenta(usersid: $userid, empid: $empid) {
      userId
      emailId
      data
      filename
      mimetype
      status
      addDate
      updateDate
    }
  }
`;



//    mutation (
//     $id: String!,
//     $designation: String!,
//     $firstName: String!,
//     $lastName: String!,
//     $email: String!,
//     $signIn: String!,
//     $department: String!,
//     $reportingManager: String!,
//     $gender: String!,
//     $bloodGroup: String,
//     $dateOfBirth: String!,
//     $maritalStatus: String!,
//     $officialEmailId: String!,
//     $personalEmailId: String!,
//     $phoneNumber: String!,
//     $alterNatePhone: String!,
//     $currentAddress: String!,
//     $permanentAddress: String!,
//     $AadharNumber: String!,
//     $PanNumber: String!,
//     $BankAccount: String!,
//     $BranchName: String!,
//     $IFSCcode: String!,
//     $BankName: String!,
//     $PFnumber: String!,
//     $UANnumber: String!,
//     $ESICnumber: String!,
//     $HealthInsuranceNumber: String!,
//     $educationDetails: [EducationDetailsInput]
//     $familyRelationShip: [FamilyRelationShipInput]
//     $reportingManagerDetails:[ReportingManagerInput]
//     $clientDetails: [ClientDetailsInput]
//   ) {
//     updateEmployee(
//       id: $id,
//       designation: $designation,
//       firstName: $firstName,
//       lastName: $lastName,
//       email: $email,
//       signIn: $signIn,
//       department: $department,
//       reportingManager: $reportingManager,
//       gender: $gender,
//       bloodGroup: $bloodGroup,
//       dateOfBirth: $dateOfBirth,
//       maritalStatus: $maritalStatus,
//       officialEmailId: $officialEmailId,
//       personalEmailId: $personalEmailId,
//       phoneNumber: $phoneNumber,
//       alterNatePhone: $alterNatePhone,
//       currentAddress: $currentAddress,
//       permanentAddress: $permanentAddress
//       AadharNumber: $AadharNumber
//       PanNumber:  $PanNumber
//       BankAccount: $BankAccount
//       BranchName: $BranchName
//       IFSCcode: $IFSCcode
//       BankName: $BankName
//       PFnumber: $PFnumber
//       UANnumber: $UANnumber
//       ESICnumber: $ESICnumber
//       HealthInsuranceNumber: $HealthInsuranceNumber
//       educationDetails: $educationDetails
//       familyRelationShip: $familyRelationShip
//       reportingManagerDetails: $reportingManagerDetails
//       clientDetails: $clientDetails
//     ) {
//       id
//       firstName
//       middleName
//       lastName
//       dateOfBirth
//       officialEmailId
//       personalEmailId
//       phoneNumber
//       alterNatePhone
//       currentAddress
//       permanentAddress
//       AadharNumber
//       PanNumber
//       BankAccount
//       BranchName
//       IFSCcode
//       BankName
//       PFnumber
//       UANnumber
//       ESICnumber
//       HealthInsuranceNumber
//       educationDetails{
//        collegeName
//        courseName
//        branchName
//        onType
//        duration
//        universityName
//       }
//       familyRelationShip{
//        nameSh
//        dateOfBirth
//        relationShip
//        age
//        dependent
//        phoneNumber
//       }
//       reportingManagerDetails{
//       name
//       type
//       department
//       designation
//       }
//       clientDetails{
//       clientName
//       department
//       process
//       role
//       slotNo
//       }
//     }
//   }
// `;
 
// export const GET_EMPLOYEES = gql `
// query {
// EmployeesDetails {
//       id
//       firstName
//       lastName
//       middleName
//       designation
//       department
//       reportingManager
//       email
//       phone
//       signIn
//       gender
//       dateOfBirth
//       maritalStatus
//       officialEmailId
//       personalEmailId
//       phoneNumber
//       alterNatePhone
//       currentAddress
//       permanentAddress
//     }
//   }
// `;
 
// export const GET_EMPLOYEE_BY_ID = gql `
// query ($id: String!) {
// getEmployeeById(id:$id) {
//     gender
//     firstName
//     middleName
//     lastName
//     dateOfBirth
//     officialEmailId
//     personalEmailId
//     phoneNumber
//     alterNatePhone
//     currentAddress
//     permanentAddress
// }
// }
// `;
 
 
// export const ADD_EMPLOYEES = gql`
//   mutation(
//     $id:String!,
//     $designation: String!,
//     $firstName: String!,
//     $lastName: String!,
//     $email: String!,
//     $phone: String!,
//     $signIn: String!,
//     $department: String!,
//     $reportingManager: String!
//     $gender: String!
//     $bloodGroup: String
//     $dateOfBirth: String!
//     $maritalStatus: String!
//     $officialEmailId: String!
//     $personalEmailId: String!
//     $phoneNumber: String!
//     $alterNatePhone: String!
//     $currentAddress: String!
//     $permanentAddress: String!
//   ) {
//     createEmployee(
//       id:$id,
//       designation: $designation,
//       firstName: $firstName,
//       lastName: $lastName,
//       email: $email,
//       phone: $phone,
//       signIn: $signIn,
//       department: $department
//       reportingManager: $reportingManager
//       gender:$gender
//       bloodGroup: $bloodGroup
//       dateOfBirth: $dateOfBirth
//       officialEmailId: $officialEmailId
//       personalEmailId: $personalEmailId
//       phoneNumber: $phoneNumber
//       alterNatePhone:  $alterNatePhone
//       currentAddress:  $currentAddress
//       permanentAddress: $permanentAddress
//       maritalStatus: $maritalStatus
//     ) {
   
//       firstName
//       lastName
   
//     }
//   }
// `;
 
// export const UPDATE_EMPLOYEE = gql `
// mutation (
//     $designation: String!,
//     $firstName: String!,
//     $lastName: String!,
//     $email: String!,
//     $phone: String!,
//     $signIn: String!,
//     $department: String!,
//     $reportingManager: String!
//     $gender: String!
//     $bloodGroup: String
//     $dateOfBirth: String!
//     $maritalStatus: String!
//     $officialEmailId: String!
//     $personalEmailId: String!
//     $phoneNumber: String!
//     $alterNatePhone: String!
//     $currentAddress: String!
//     $permanentAddress: String!
// )
//     {
// updateEmployee(
//       id:$id,
//       designation: $designation,
//       firstName: $firstName,
//       lastName: $lastName,
//       email: $email,
//       phone: $phone,
//       signIn: $signIn,
//       department: $department
//       reportingManager: $reportingManager
//       gender:$gender
//       bloodGroup: $bloodGroup
//       dateOfBirth: $dateOfBirth
//       officialEmailId: $officialEmailId
//       personalEmailId: $personalEmailId
//       phoneNumber: $phoneNumber
//       alterNatePhone:  $alterNatePhone
//       currentAddress:  $currentAddress
//       permanentAddress: $permanentAddress
//     )
//       {
//     firstName
//     middleName
//     lastName
//     dateOfBirth
//     officialEmailId
//     personalEmailId
//     phoneNumber
//     alterNatePhone
//     currentAddress
//     permanentAddress
 
 
//     }
// }
// `;
 
 
 
export const ALL_ATTACHMENTS = gql`
  query FetchEmployeeAttachments($ID: String) {
  FetchAttachments(id: $ID) {
    response {
      attachemnts {
        id
        filename
        data
        userId
        emailId
        mimetype
        addDate
        updateDate
        status
        userName
        attachmetHistory{
        userId
       emailId
       userName
        replacedFile
       data
      filename
    mimetype
     deleteDate
    addDate
    updateDate
    status
        }
      }
    }
  }
}

`;

 
 
 
export const ADD_EMPLOYEES = gql`
  mutation createEmployee($input: CreateEmployeeInput) {
    createEmploye(input: $input) {
      status
      message
      response{
         id
         EmpID
  role
  firstname
  lastname
  designation
  department
  reportingManager
  signedIn
  gender
  dob
  status
  token
  employeestatus
  maritalStatus
  bloodGroup
  currentAddress
  permanentAddress
  mobile
  alternativeNumber
  personalEmail
  email
  processSplization
  adharNumber
  PanNumber
  BankAccountNo
  BankName
  reportingTo
  clients
  DateOfJoining
  
      
      }
    }
  }
`;
export const DELETE_ATTACHMENTS = gql`
mutation ($id: String!
$userId: String!
  $updateDate: String!) {
    deleteAttachments(id: $id
    userId: $userId
      updateDate: $updateDate) {
      emailId
      userId
      data
      status
      filename
      mimetype
      addDate
      updateDate
    }
  }
`;
export const UPDATE_ATTACHMENTS = gql`
mutation (
  $id: String!
  $data: String!
  $filename: String!
  $mimetype: String!
  $userId: String!
  $updateDate: String!) {
    updateAttachments(
    id: $id
    data: $data
    filename: $filename
    mimetype: $mimetype
    userId: $userId
    updateDate: $updateDate) {
      emailId
      userId
      data
      filename
      mimetype
      addDate
      updateDate
    }
  }
`;
export const CREATE_ATTACHEMNTS = gql`
mutation (
  $userId: String!
  $emailId: String!

  $data: String!
  $filename: String!
  $mimetype: String!
  $addDate: String!
  $updateDate: String!
  $status: Boolean!) {
    createAttachments(
      userId: $userId
      emailId: $emailId
      data: $data
      filename: $filename
      mimetype: $mimetype
      addDate: $addDate
      updateDate: $updateDate
      status: $status) {
        id
        userId
        emailId
        data
        filename
        mimetype
        addDate
        updateDate
        status
      }
    }
  `;

  export const GET_ALLATTACHMENTS_BY_ID = gql`
  mutation ($emailId: String!) {
    getAllAttachmentsByID(emailId: $emailId) {
      id
      userId
      emailId
      data
      filename
      mimetype
      addDate
      updateDate
      status
      attachmentHistory{
      userId
      emailId
      filename
      mimetype
      addDate
      updateDate
      status}
    }
  }
`;
export const GET_ALL_MODIFIED_ATTACHMENTS_BY_ID = gql`
mutation ($emailId: String!) {
  getAllModifiedAttachmentsByID(emailId: $emailId) {
    id
    userId
    emailId
    data
    filename
    mimetype
    addDate
    updateDate
    status
  }
}
`;

export const GET_ALLATTACHMENT_HISTORY_BY_ID = gql`
mutation ($userId: String!, $emailId: String!) {
  getAllAttachmentHistoryByID(userId: $userId, emailId: $emailId) {
    id
    userId
    emailId
    data
    filename
    mimetype
    addDate
    updateDate
    status
  }
}
`;


