import { gql } from "@apollo/client";
export const GET_HOLIDAY = gql`
  query GetHoliday($department: String!, $role: String!) {
    GetHoliday(department: $department,role:$role) {
      _id
      name
      department
      date
    }
  }
`;
export const CREATE_HOLIDAY = gql`
mutation CreateHoliday($name: String, $department: String, $date: String) {
  CreateHoliday(name: $name, department: $department, date: $date) {
    name
    department
  }
}
`;
export const DELETE_HOLIDAY = gql`
mutation DeleteHoliday($id: String!) {
  DeleteHoliday(id: $id) {
    name
  }
}`;
export const UPDATE_HOLIDAY = gql`
  mutation UpdateHoliday($id: String, $name: String, $department: String, $date: String) {
    UpdateHoliday(id: $id, name: $name, department: $department, date: $date) {
      _id
      name
      department
      date
    }
  }
`;
