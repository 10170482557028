import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { setIsRefetch } from '../../../Slices/salesSlice';
import { Row, Col, Form, Input } from 'antd';

const CommentField = ({ label, name }) => {
  const { values, handleChange } = useFormikContext();
  const [comment, setComment] = useState(values?.[name] || '');
  const dispatch = useDispatch();
  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setComment("")
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch]);

  return (
    <Row style={{ marginTop: '16px' }}>
      <Col span={24}>
        <Form.Item
          label={label}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input.TextArea
            name={name}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            onBlur={(e) => handleChange(e)} // Update Formik's state
            rows={4}
          />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default CommentField;
