import React, { useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { gql } from 'graphql-tag';

// GraphQL Queries and Mutations
const GET_ALL_SCRAPED_DATA = gql`
  query {
    getAllScrapedData {
      status
      message
      response {
        url
        parameters
        scrapedContent
        scrapedAt
      }
    }
  }
`;

const SCRAPE_WEBSITE = gql`
  mutation ($url: String!, $parameters: [String!]!) {
    scrapeAndStoreData(url: $url, parameters: $parameters) {
      status
      message
      url
      parameters
      scrapedContent
    }
  }
`;

export function ScrapeDataComponent(sidebarOpen) {
  const [url, setUrl] = useState('');
  const [parameters, setParameters] = useState('');
  const [scrapedData, setScrapedData] = useState([]);

  // Query to fetch scraped data
  const { data, loading, error } = useQuery(GET_ALL_SCRAPED_DATA);

  // Mutation to scrape and store data
  const [scrapeAndStoreData] = useMutation(SCRAPE_WEBSITE, {
    onCompleted: (data) => {
      if (data && data.scrapeAndStoreData) {
        console.log('Data Scraped and Stored:', data);
        alert('Data Scraped and Stored successfully!');
      }
    },
    onError: (err) => {
      console.error('Error in scraping:', err);
      alert('Error in scraping data!');
    },
  });

  // Handle scraping request
  const handleScrape = () => {
    const paramsArray = parameters.split(',').map(param => param.trim());
    scrapeAndStoreData({
      variables: { url, parameters: paramsArray },
    });
  };

  // Loading and error handling
  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  // Function to split content by newlines and return formatted text
  const formatContent = (content) => {
    if (Array.isArray(content)) {
      return content.map((line, index) => (
        <div key={index}>{line}</div>
      ));
    }
    return content.split('\n').map((line, index) => (
      <div key={index}>{line}</div>
    ));
  };

  return (
    <div className={`page-wrapper ${sidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="content container-fluid"></div>
    <div className="container" style={styles.container}>
      <h2 style={styles.heading}>Web Scraping Tool</h2>

      <div className="form-group" style={styles.formGroup}>
        <label htmlFor="urlInput" style={styles.label}>Website URL:</label>
        <input
          id="urlInput"
          type="text"
          className="form-control"
          style={styles.input}
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          placeholder="Enter the website URL"
        />
      </div>

      <div className="form-group" style={styles.formGroup}>
        <label htmlFor="parametersInput" style={styles.label}>Parameters (comma separated):</label>
        <input
          id="parametersInput"
          type="text"
          className="form-control"
          style={styles.input}
          value={parameters}
          onChange={(e) => setParameters(e.target.value)}
          placeholder="e.g., .title, .description"
        />
      </div>

      <button className="btn btn-primary" style={styles.button} onClick={handleScrape}>
        Start Scraping
      </button>

      {/* <h3 style={styles.heading}>Scraped Data:</h3> */}
      {data && data.getAllScrapedData && data.getAllScrapedData.response.length > 0 ? (
        <div style={styles.tableContainer}>
          <table className="table table-striped" style={styles.table}>
            <thead>
              <tr>
                <th style={styles.tableHeader}>Website URL</th>
                {/* <th style={styles.tableHeader}>Scraped At</th> */}
                <th style={styles.tableHeader}>Parameters</th>
                <th style={styles.tableHeader}>Scraped Content</th>
              </tr>
            </thead>
            <tbody>
              {data.getAllScrapedData.response.map((item, index) => (
                <tr key={index}>
                  <td style={styles.tableCell}>{item.url}</td>
                  {/* <td style={styles.tableCell}>{new Date(item.scrapedAt).toLocaleString()}</td> */}
                  <td style={styles.tableCell}>
                    {item.parameters.join(', ')}
                  </td>
                  <td style={styles.tableCell}>
                    {Object.entries(item.scrapedContent).map(([key, value], i) => (
                      <div key={i} style={styles.contentBlock}>
                        <strong>{key}:</strong>
                        {formatContent(value)}
                      </div>
                    ))}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p style={styles.noDataText}>No scraped data available</p>
      )}
    </div>
    </div>
   
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Ensure the container takes up the full height of the viewport
    padding: '20px',
    backgroundColor: '#f9f9f9',
    borderRadius: '8px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    overflow: 'hidden', // Hide vertical scrollbar
  },
  heading: {
    textAlign: 'center',
    marginBottom: '20px',
    color: '#333',
  },
  formGroup: {
    marginBottom: '15px',
    width: '100%', // Ensure the form controls are full-width inside the container
  },
  label: {
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '8px',
    display: 'block',
    color: '#555',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '16px',
    borderRadius: '4px',
    border: '1px solid #ddd',
    boxSizing: 'border-box',
  },
  buttonContainer: {
    width: '100%', // Full width for button container
    display: 'flex',
    justifyContent: 'flex-end', // Align button to the right
    marginTop: '20px',
  },
  button: {
    width: '150px', // Make the button smaller by setting a fixed width
    padding: '12px',
    backgroundColor: '#007bff',
    color: '#fff',
    fontSize: '16px',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  tableContainer: {
    width: '100%',
    marginTop: '20px',
    overflowY: 'auto', // Prevent vertical scrollbar inside the table container
    maxHeight: '60vh', // Set a maximum height for the table section
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    marginBottom: '20px',
  },
  tableHeader: {
    backgroundColor: '#007bff',
    color: '#fff',
    padding: '10px',
    textAlign: 'left',
    fontSize: '16px',
    border: '1px solid #ddd',
  },
  tableCell: {
    padding: '10px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    fontSize: '14px',
    wordWrap: 'break-word',
  },
  contentBlock: {
    marginBottom: '8px', // Added space between content blocks
  },
  noDataText: {
    textAlign: 'center',
    fontSize: '18px',
    color: '#888',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh', // Ensures that the page wrapper takes the full height
  },
};



export default ScrapeDataComponent;
