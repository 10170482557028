import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLoader } from "../../Slices/loaderSlice";
import { UPDATE_EMPLOYEE } from "../../Queries/Employees";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { setEditHistory, setClientHistorys } from "../../Slices/employeeslice";
import { UPDATE_CLIENT } from "../../Queries/Orders";
import { EDIT_WORKHISTORY } from "../../Queries/Employees";
import { useMutation } from "@apollo/client";
import { useErrorLogger } from "../../utils";
import { getAllClientsData } from "../../Slices/loginUserSlice";

export const ClientModelpopup = () => {
    const { logError } = useErrorLogger();
    const dispatch = useDispatch()
    const { Empid } = useParams();

    const token = localStorage.getItem("token");
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const id = parseJwt(token)?.id;
    const ids = Empid ? Empid : id;
    const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
    // const [updateclient] = useMutation(UPDATE_CLIENT);
    const [EditemployeeDetails] = useMutation(EDIT_WORKHISTORY);

    const [clientHistory, setClientHistory] = useState([]);
    const [clientErrors, setClientErrors] = useState({});
    const [ClientFormValid, setClientFormValid] = useState(false);
    const [submitBtnBlock, setSubmitBtnBlock] = useState(false);

    const [currClient, setcurrclient] = useState();
    // const [prevClient, setprevclient] = useState();
    // const [changedClients, setChangedClients] = useState([]); // Stores changed client names
    // const [newClients, setNewClients] = useState([]); // Stores new client names

    const Client = useSelector((state) => state.employee.clientHistory);
    const details = useSelector((state) => state.employee.AllEmployees);
    const clientOptions = useSelector(state => state.clients.clients);
    const employee = details.find((emp) => emp.id === id);
    useEffect(() => {
        setClientHistory(Client);
      console.log("sdvsdvdsv",Client,clientHistory)
    }, [Client]);
  

    const alphaRegex = /^[A-Za-z\s]+$/;
    useEffect(() => {
        setTimeout(() => {
            setSubmitBtnBlock(false);
        }, 5000);
    }, [submitBtnBlock])

    const handleClientChange = (index, e) => {
        const { name, value } = e.target;
    
        // Update the client history data
        const updatedClients = [...clientHistory];
        updatedClients[index] = { ...updatedClients[index], [name]: value };
        setClientHistory(updatedClients);
    
        // Clone the errors object
        const updatedErrors = { ...clientErrors };
    
        // Validation logic
        if (value.trim() === '') {
          // Add "required" error
          if (!updatedErrors[index]) {
            updatedErrors[index] = {};
          }
          updatedErrors[index][name] = `${name.replace(/([A-Z])/g, ' $1')} is required.`;
        } else {
          // Validate specific fields
          if (name === "processes" || name === "role") {
            const regex = /^[A-Za-z\s-]+$/; // Only letters, spaces, and hyphens
            if (!regex.test(value)) {
              if (!updatedErrors[index]) {
                updatedErrors[index] = {};
              }
              updatedErrors[index][name] = `Please enter a valid ${name}. Only letters, spaces, and hyphens are allowed.`;
            } else {
              // Remove error if input is valid
              if (updatedErrors[index]) {
                delete updatedErrors[index][name];
                if (Object.keys(updatedErrors[index]).length === 0) {
                  delete updatedErrors[index];
                }
              }
            }
          }
          if (name === "NameOfClient") {
            if (value) {
              // Ensure updatedErrors[index] is defined as an object
              if (updatedErrors[index] && typeof updatedErrors[index] === 'object') {
                delete updatedErrors[index][name];
              }
            }
          }
    
    
          if (name === "SlotNo") {
            const slotNoRegex = /^[0-9]+$/; // Numeric values only
            if (!slotNoRegex.test(value)) {
              if (!updatedErrors[index]) {
                updatedErrors[index] = {};
              }
              updatedErrors[index][name] = `Please enter a valid slot number (numeric only).`;
            } else {
              // Remove error if input is valid
              if (updatedErrors[index]) {
                delete updatedErrors[index][name];
                if (Object.keys(updatedErrors[index]).length === 0) {
                  delete updatedErrors[index];
                }
              }
            }
          }
        }
    
        setClientErrors(updatedErrors);
    
        // Check if the form is valid
        const allFieldsValid = updatedClients.every((client, i) => {
          const recordErrors = updatedErrors[i] || {};
          return (
            client.processes?.trim() &&
            client.role?.trim() &&
            client.SlotNo?.trim() &&
            client.NameOfClient &&
            Object.keys(recordErrors).length === 0
          );
        });
    
        setClientFormValid(allFieldsValid);
      };
    // Add Client Entry
    const addClient = () => {
        setClientHistory((prev) => [...prev, { NameOfClient: "", processes: "", role: "", SlotNo: "" }]);
        setClientFormValid(false);
    };
    // Delete Client Entry
    const handleDeleteClient = (index) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you really want to delete this call?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.isConfirmed) {
                const updatedClients = clientHistory?.filter((_, i) => i !== index);
                setClientHistory(updatedClients);
                // Remove errors for the deleted client entry
                const newErrors = { ...clientErrors };
                delete newErrors[index];

                // Re-index errors
                const reindexedErrors = {};
                Object.keys(newErrors).forEach((key) => {
                    const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
                    reindexedErrors[newKey] = newErrors[key];
                });

                setClientErrors(reindexedErrors);
                setClientFormValid(reindexedErrors);
            }
        })
    }

    const handleClientSubmit = async (e) => {
        e.preventDefault();
    
        const newErrors = {};
    
        // Validate each client entry on submit
    
        clientHistory.forEach((client, index) => {
          // Regex for letters and spaces only
    
          if (!client.NameOfClient?.trim()) {
            newErrors[index] = { ...newErrors[index], NameOfClient: "Name is required" };
          }
          //  else if (!alphaRegex.test(client.NameOfClient)) {
          //   newErrors[index] = { ...newErrors[index], NameOfClient: "Name must contain only letters" };
          // }
    
          if (!client.processes?.trim()) {
            newErrors[index] = { ...newErrors[index], processes: "Process is required" };
          } else if (!alphaRegex.test(client.processes)) {
            newErrors[index] = { ...newErrors[index], processes: "Process must contain only letters" };
          }
    
          if (!client.role?.trim()) {
            newErrors[index] = { ...newErrors[index], role: "Role is required" };
          } else if (!alphaRegex.test(client.role)) {
            newErrors[index] = { ...newErrors[index], role: "Role must contain only letters" };
          }
    
          if (!client.SlotNo?.trim()) {
            newErrors[index] = { ...newErrors[index], SlotNo: "Slot No is required" };
          } else if (!/^\d+$/.test(client.SlotNo)) {
            // Regex for numeric values only
            newErrors[index] = { ...newErrors[index], SlotNo: "Slot No must be numeric" };
          }
    
        });
    
        setClientErrors(newErrors);
        const noErrors = Object.keys(newErrors).length === 0;
        if (noErrors && !ClientFormValid) {
          message.error({
            content: "No Change Detected to Update",  // Error message
            duration: 2,  // Toast will disappear after 5 seconds
            className: 'alert alert-danger text-center',  // Danger toast with custom styling
          });
          setSubmitBtnBlock(true)
        }
        setClientFormValid(noErrors);
        try {
          const sanitizedClientHistory = clientHistory?.map(({ __typename, ...client }) => client);
          if (newErrors && ClientFormValid) {
            dispatch(setLoader(true))
            let { data } = await updateEmployee({
              variables: {
                id: ids,
                input: {
                  client: sanitizedClientHistory,
                  clientKey:true
                },
              },
            });
            if (data?.UpdateEmploye?.status) {
              EditemployeeDetails({
                variables: {
                  input: {
                    id: id,
                    empid: Empid,
                    userName: `${employee?.firstname}  ${employee?.lastname}`,
                    action: "ClientworkHistory",
                    EditStatus: "Updated",
                    changeTime: new Date().toISOString(), // Convierte la fecha a un formato ISO estándar
                  },
                }
              })
              dispatch(getAllClientsData(sanitizedClientHistory));
              dispatch(setEditHistory({
                id: id,
                empid: Empid,
                userName: `${employee?.firstname}  ${employee?.lastname}`,
                action: "ClientworkHistory",
                EditStatus: "Updated",
                changeTime: new Date().toISOString(),
              }))
              dispatch(setClientHistorys(sanitizedClientHistory));
              if(Empid==id)
                {
                  
                }
              dispatch(setLoader(false))
              message.success({
                content: "Updated Successfully",
                duration: 5, // Toast will disappear after 5 seconds
                className: 'custom-toast-center', // Custom class to center the toast
              });
              setClientFormValid(false);
            }
          }
        } catch (error) {
          // Log the error and show an error message
          await logError("PersonalInformationModelPopup", "handleClientSubmit", error.message);
          console.error("Error occurred during handleClientSubmit  update:", error);
          message.error({
            content: "Failed to update handleClientSubmit . Please try again.",
            duration: 5,
            className: "alert alert-danger text-center",
          });
        } finally {
          // Final steps regardless of success or failure
          setClientFormValid(false); // Ensure form state resets
        }
      };
    // Inline Validation on Change
  console.log("sadcsacac",clientHistory)
    const handleClose = () => {
        setClientHistory(Client)
        setClientErrors({})
        setClientFormValid(false);
    }
    return (
        <div id="client_history" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Client History</h5>
              <button type="button" className="btn-close" onClick={() => handleClose()} data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleClientSubmit}>
                <div className="form-scroll">
                  {clientHistory?.map((client, index) => (
                    <div className="card" key={index}>
                      <div className="card-body">
                        <h3 className="card-title">
                          Client Information {index + 1}
                          <Link to="#" className="delete-icon" onClick={() => handleDeleteClient(index)}>
                            <i className="fa-regular fa-trash-can" />
                          </Link>
                        </h3>
                        <div className="row">
                          <div className="col-md-6 mb-3">
                            <label className="col-form-label">Name<span className="text-danger">*</span></label>
                            <select
                              className={`form-control ${clientErrors[index]?.NameOfClient ? 'is-invalid' : ''}`}
                              name="NameOfClient"
                              value={client?.NameOfClient}
                              onChange={(e) => handleClientChange(index, e)}
                               >
                              <option value="">Select Client</option>
                              {clientOptions?.filter(clientOption => clientOption?.status === "Active")
                                .map((clientOption, idx) => (
                                  <option key={idx} value={clientOption?.clientName}>
                                    {clientOption?.clientName}
                                  </option>
                                ))}
                            </select>
                            {clientErrors[index]?.NameOfClient && (
                              <small className="text-danger mt-1 d-block">{clientErrors[index]?.NameOfClient}</small>
                            )}
                          </div>
                          <div className="col-md-6 mb-3">
                            <label className="col-form-label">Processes<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${clientErrors[index]?.processes ? 'is-invalid' : ''}`}
                              name="processes"
                              value={client?.processes}
                              onChange={(e) => handleClientChange(index, e)}
                            />
                            {clientErrors[index]?.processes && (
                              <small className="text-danger mt-1 d-block">{clientErrors[index].processes}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="col-form-label">Role<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${clientErrors[index]?.role ? 'is-invalid' : ''}`}
                              name="role"
                              value={client?.role}
                              onChange={(e) => handleClientChange(index, e)}
                            />
                            {clientErrors[index]?.role && (
                              <small className="text-danger mt-1 d-block">{clientErrors[index].role}</small>
                            )}
                          </div>

                          <div className="col-md-6 mb-3">
                            <label className="col-form-label">Slot No<span className="text-danger">*</span></label>
                            <input
                              type="text"
                              className={`form-control ${clientErrors[index]?.SlotNo ? 'is-invalid' : ''}`}
                              name="SlotNo"
                              value={client?.SlotNo}
                              onChange={(e) => handleClientChange(index, e)}
                            />
                            {clientErrors[index]?.SlotNo && (
                              <small className="text-danger mt-1 d-block">{clientErrors[index].SlotNo}</small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="add-more">
                    <Link to="#" onClick={addClient}>
                      <i className="fa-solid fa-plus-circle" /> Add More
                    </Link>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={submitBtnBlock}
                    {...(ClientFormValid ? { "data-bs-dismiss": "modal" } : {})}
                  >
                    Submit
                  </button>
                
                </div>
              </form>

            </div>
          </div>
        </div>
      </div>
    )

}

//conditional client code
 // if (matchingClients?.length > 0) {
                //     const updatedClients = await Promise.all(
                //         matchingClients.map(async (client) => {
                //             const { datas } = await updateclient({
                //                 variables: {
                //                     id: client?.id,
                //                     clientName: client?.clientName,
                //                     mobileNo: client?.mobileNo,
                //                     emailId: client?.emailId?.toLowerCase(),
                //                     status: client?.status,
                //                     timeZone: client?.timeZone,
                //                     department: client?.department,
                //                     flag: true,
                //                 },
                //             });
                //             return datas;
                //         })
                //     );

                //     console.log("Updated Clients:", updatedClients);
                // }
               
                // if (prevClient.length > 0) {
                //     const updatedClients = await Promise.all(
                //         prevClient.map(async (client) => {
                //             const { datas } = await updateclient({
                //                 variables: {
                //                     id: client?.id,
                //                     clientName: client?.clientName,
                //                     mobileNo: client?.mobileNo,
                //                     emailId: client?.emailId?.toLowerCase(),
                //                     status: client?.status,
                //                     timeZone: client?.timeZone,
                //                     department: client?.department,
                //                     flag: false,
                //                 },
                //             });
                //             return datas;
                //         })
                //     );

                //     console.log("Updated Clients:", updatedClients);
                // }
                // const handleClientSelection = (index, e) => {
    //     const newClientName = e.target.value;
    //     const prevClientName = clientHistory[index]?.NameOfClient;

    //     // Track changed clients
    //     setChangedClients(prev => {
    //         const updatedChangedClients = new Set(prev);

    //         if (newClients.includes(prevClientName)) {
    //             setNewClients(prev => prev.filter(client => client !== prevClientName));
    //             updatedChangedClients.add(prevClientName);
    //         } else if (prevClientName && prevClientName !== newClientName) {
    //             updatedChangedClients.add(prevClientName);
    //         }

    //         return [...updatedChangedClients]; // Convert back to array
    //     });

    //     // Track new clients
    //     if (!clientOptions.some(client => client.NameOfClient === newClientName)) {
    //         setNewClients(prev => [...new Set([...prev, newClientName])]);
    //     }

        

    //      handleClientChange(index, { target: { name: "NameOfClient", value: newClientName } });
    // };
      // console.log("sdcsdcdsc",changedClients , clientOptions);

        // // Convert both client.NameOfClient and the newClients array to lowercase and remove leading/trailing spaces to ensure consistent comparison
        // const matchingClients = clientOptions.filter(client =>
        //     newClients.some(newClient => {
        //         console.log("dcdsvdsvdv", newClient, client.clientName);
        //         return newClient === client.clientName; // Return the result of the comparison
        //     })
        // );

        // console.log("matchingClients", matchingClients);
        // const prevClient = clientOptions.filter(client =>
        // newClients.some(newClient => {
        //         console.log("dcdsvdsvdv", newClient, client.clientName);
        //         return newClient === client.clientName; // Return the result of the comparison
        //     })
        // );

        // console.log("matchingClients", prevClient);


        // console.log("matchingClients", matchingClients);
        // console.log("Is 'Jain Insurance Agency' in newClients?", newClients.includes("Jain Insurance Agency"));


        // console.log("matchingClients",matchingClients);
