import React, { useState, memo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CommentField from "./CommentField";
import { Formik, useFormikContext, FieldArray, Field } from 'formik';
import { Form, Button, Input, Select, DatePicker, TimePicker, Row, Col, Divider, Typography, Space } from 'antd';
import dayjs from 'dayjs';
import { useMutation } from '@apollo/client';
import * as DataBucket from '../../../databucket';
import { useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Starttask } from '../../../Slices/timerslice';
import { UPDATE_WORKHISTORY } from '../../../Queries/Orders';
import { timerHistoryPayloadChange } from '../../../Slices/user';
import { settaskStartTime } from "../../../Slices/user";
import { addLastContactedDate, removeLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import { setIsRefetch } from '../../../Slices/salesSlice';
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;
const { TextArea } = Input;


const LinkedinTrack = memo((props) => {
  const salesLeadId = useSelector((state) => state?.sales?.sale?.leadID);
  const { SalesInfo, assignToUsers, getBy, setFieldValue, id, leadID } = props;
  const WorkingId = useSelector((state) => state?.timer?.requestId);
  const CurrentTask = 'Linkedin'
  const task = useSelector((state) => state.timer.task);
  const dispatch = useDispatch();
  const [isRunning, setIsRunning] = useState(false);
  const [updateWorkHistory, { data, loading, error }] =
    useMutation(UPDATE_WORKHISTORY);
  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext();

  const [requestTo, setRequestTo] = useState(values.leadsLinkedins?.map(call => call.requestTo) || []);
  const [remarks, setRemarks] = useState(values.leadsLinkedins?.map(call => call.remarks) || []);

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setRequestTo([]);
      setRemarks([]);
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const options = useSelector((state) => state.dataBucket.databucket);
  const handleStartButtonClick = async () => {

    const payload = {
      department: "Sales",
      task: "Linkedin",
      time: Date.now(),
      requestId: SalesInfo?.leadID || salesLeadId,
    };

    try {
      const response = await updateWorkHistory({
        variables: {
          userId: parseJwt(token).id,
          emailId: "",
          requestId: SalesInfo?.leadID.toString() || salesLeadId,
          clientName: "Bluepalnit",
          processType: "",
          taskType: "Linkedin",
          email: "",
          subject: "",
          body: "",
          startTime: Date.now().toString(),
          endTime: "",
        },
      });
      // dispatch(addLatestReports(Reportpayload))
    } catch (err) {
      console.error("Error updating work history:", err);
    }
    localStorage.setItem("task", "LinkedIn");
    localStorage.setItem("time", Date.now());
    localStorage.setItem("requestId", SalesInfo?.leadID || salesLeadId);
    localStorage.setItem("department", "Sales");
    // dispatch(updateAReport(Reportpayload));
    dispatch(Starttask(payload));
  };
  const handleStop = async () => {
    setIsRunning(false);

    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),
      endTime: Date.now().toString(),
      requestId: "",
    };
    //setRefetch(true);
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
    await updateWorkHistory({
      variables: {
        userId: parseJwt(token).id,
        emailId: "",
        requestId: "",
        clientName: "Bluepalnit",
        processType: "",
        taskType: "Available",
        email: "",
        subject: "",
        body: "",
        startTime: Date.now().toString(),
        endTime: "",
      },
    });
    const payload = {
      department: "",
      task: "Available",
      time: Date.now(),
      requestId: '',
    };
    localStorage.setItem("StartTime", new Date().getTime());
    localStorage.setItem("task", 'Available');
    localStorage.removeItem("department");
    localStorage.removeItem("requestId");
    dispatch(settaskStartTime(new Date().getTime()));
    dispatch(Starttask(payload));
  };

  const [localRequestToErrors, setLocalRequestToErrors] = useState([]);

  const handleRequestToChange = (e, index) => {
    const { value } = e.target;
    const newRequestTo = [...requestTo];
    newRequestTo[index] = value;

    // // Local validation: only letters and spaces allowed
    // let errorMessage = "";
    // if (value && !/^[A-Za-z\s]+$/.test(value)) {
    //   errorMessage = "Request To must contain only letters and spaces";
    // }

    // const newErrors = [...localRequestToErrors];
    // newErrors[index] = errorMessage;

    setRequestTo(newRequestTo);
    // setLocalRequestToErrors(newErrors);
  };

  const handleAddRow = (arrayHelpers) => {

    const newDate = dayjs(new Date());
    setFieldValue("lastContactedDate", newDate);
    arrayHelpers.push({
      date: newDate || "",
      time: dayjs().set('second', 0).set('millisecond', 0) || '',
      status: 'Request Sent',
      requestTo: '',
      requestBy: getBy(null),
      remarks: '',
    });

    // Add the new date to Redux state
    dispatch(addLastContactedDate(newDate));
  };

  const lastContactedDates = useSelector(state => state.lastContactedDates);
  console.log("lastDates", lastContactedDates)

  const handleDeleteRow = (index, arrayHelpers) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this Linkedin record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        arrayHelpers.remove(index); // Remove the call record
        setRequestTo((prev) => prev.filter((_, i) => i !== index));
        setRemarks((prev) => prev.filter((_, i) => i !== index));

        setLocalRequestToErrors((prev) => prev.filter((_, i) => i !== index));

        // Get the date of the row being deleted from `leadsCalls`
        const deletedRowDate = arrayHelpers.form.values.leadsLinkedins[index].date;

        const lastContactedDatess = lastContactedDates.filter(date => dayjs(date).valueOf() !== dayjs(deletedRowDate).valueOf());

        const newDate = dayjs(new Date());
        const latestDate = lastContactedDatess.length > 0
          ? Math.max(...lastContactedDatess.map(date => dayjs(date).valueOf()))
          : null;

        dispatch(setLastContactedDates(lastContactedDatess));

        if (latestDate) {
          setFieldValue("lastContactedDate", dayjs(latestDate));
        }
      }
    });

  };
  const getTime = (time) => {
    let formatedTime = ''
    if (time != undefined && time != '') {
      if (typeof time === 'object') {
        formatedTime = time

      } else if (typeof time === 'string') {
        if (time?.includes('T')) {
          let tempdatetime = time?.split('T')
          let temptime = tempdatetime[1]?.split('.')
          time = temptime[0]
        }
        let timeArray = time?.split(':')
        formatedTime = dayjs().set('hour', timeArray[0]).set('minute', timeArray[1]).set('second', timeArray[2])
      }
    } // Set the desired time
    return formatedTime;
  }
  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != '') {
      if (typeof datevalue === 'string' && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue))
      } else if (typeof datevalue === 'object') {
        finalDate = datevalue
      }
    }
    return finalDate;
  }
  return (
    <div style={{ marginTop: '15px', backgroundColor: "#fff", padding: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <Typography.Title level={4}>LinkedIn Track
        {
          id !== undefined && (
            WorkingId === leadID && task === CurrentTask ? (
              <Button
                className="btn btn-info"
                style={{
                  float: "right",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleStop}
              >
                Stop
              </Button>
            ) : (
              <Button
                color="primary"
                variant="solid"
                style={{
                  float: "right",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleStartButtonClick}
              >
                Start
              </Button>
            )
          )
        }

      </Typography.Title>
      <FieldArray
        name="leadsLinkedins"
        render={(arrayHelpers) => (
          <>
            {values?.leadsLinkedins?.map((LinkedInInfo, index) => (
              <div key={index} style={{ marginBottom: '1rem' }}>
                <Row gutter={16}>
                  {/* Connection No. */}
                  <Col span={24} style={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                    // marginBottom:'20px'
                    marginTop: '15px'
                  }}>
                    <Typography.Text strong>Connection No. {index + 1}</Typography.Text>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        color: '#00c5fb',
                        cursor: 'pointer',
                        fontSize: '16px',
                      }}
                      onClick={() => handleDeleteRow(index, arrayHelpers)}
                    />
                  </Col>

                  {/* Date Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsLinkedins.${index}.date`}>
                        {({ field, form }) => (
                          <DatePicker
                            {...field}
                            allowClear={false}
                            inputReadOnly={true}
                            format="DD/MM/YYYY"
                            value={getDateValue(field.value)} // Helper to parse the value correctly
                            onChange={(date) =>
                              form.setFieldValue(field.name, date)
                            }
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Time Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Time"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsLinkedins.${index}.time`}>
                        {({ field, form }) => (
                          <TimePicker
                            {...field}
                            allowClear={false}
                            inputReadOnly={true}
                            value={
                              field.value
                                ? getTime(field.value)
                                : dayjs().set('second', 0).set('millisecond', 0)
                            }
                            onChange={(time) =>
                              form.setFieldValue(field.name, time)
                            }
                            format="h:mm A" // 12-hour time format
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Request Status Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Request Status"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      validateStatus={errors?.leadsLinkedins?.[index]?.status && touched?.leadsLinkedins?.[index]?.status ? 'error' : ''}
                      help={touched?.leadsLinkedins?.[index]?.status && errors?.leadsLinkedins?.[index]?.status}
                    >
                      <Field name={`leadsLinkedins.${index}.status`}>
                        {({ field, form }) => (
                          <Select
                            value={field.value || 'Request Sent'} // Default to 'Request Sent' if the value is undefined
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state
                            style={{ width: '100%' }}
                          >
                            {renderOptions(options, { name: "SALES_LINKEDIN_STATUS" }, DataBucket.SALES_LINKEDIN_STATUS)}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>


                  {/* Request To Field */}
                  <Col span={4}>
                    <Form.Item
                      label={
                        <span>
                          Request To
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // validateStatus={errors?.leadsLinkedins?.[index]?.requestTo && touched?.leadsLinkedins?.[index]?.requestTo ? 'error' : ''}
                      // help={touched?.leadsLinkedins?.[index]?.requestTo && errors?.leadsLinkedins?.[index]?.requestTo}
                      validateStatus={
                        (touched.leadsLinkedins?.[index]?.requestTo && errors.leadsLinkedins?.[index]?.requestTo) || localRequestToErrors[index]
                          ? "error"
                          : ""
                      }
                      help={
                        localRequestToErrors[index] ||
                        (touched.leadsLinkedins?.[index]?.requestTo && errors.leadsLinkedins?.[index]?.requestTo)
                      }
                    >
                      <Input
                        value={requestTo[index] || ''}
                        // onChange={(e) => {
                        //   const newRequestTo = [...requestTo];
                        //   newRequestTo[index] = e.target.value;
                        //   setRequestTo(newRequestTo);
                        // }}
                        onChange={(e) => handleRequestToChange(e, index)}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsLinkedins[${index}].requestTo`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/* Request By Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Request By"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsLinkedins.${index}.requestBy`}>
                        {({ field, form }) => (
                          <Select
                            showSearch
                            value={field.value || ''} // Use the Formik field value or an empty string
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state
                            style={{ width: '100%' }}
                          >
                            {assignToUsers?.map((user, key) => (
                              <Select.Option key={key} value={`${user.firstname} ${user.lastname}`}>
                                {`${user.firstname} ${user.lastname}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>


                  {/* Remarks Field */}
                  <Col span={22}>
                    <Form.Item
                      label="Remarks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.TextArea
                        value={remarks[index] || ''}
                        onChange={(e) => {
                          const newRemarks = [...remarks];
                          newRemarks[index] = e.target.value;
                          setRemarks(newRemarks);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsLinkedins[${index}].remarks`,
                              value: e.target.value,
                            },
                          });
                        }}
                        rows={2}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Divider style={{ backgroundColor: '#ccc', height: '1px' }} />
              </div>
            ))}

            {/* Add Row Button */}
            <Row justify="end" style={{ marginTop: '16px' }}>
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => handleAddRow(arrayHelpers)}
              >
                Add Row
              </Button>
            </Row>
          </>
        )}
      />
      <CommentField label="LinkedIn Comments" name="linkedinComments" />
    </div>

  );
});

export default LinkedinTrack;

