import React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";

const CreateCall = ({
  leadsCalls,
  handleDeleteCall,
  handleCallsInputChange,
  handleAddNewCall,
  formData,
  handleChange,
  dataBucket,
  userNames,
}) => {
  return (
    <>
      {leadsCalls?.map((call, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">
              Lead Call {index + 1}
              <Link
                to="#"
                className="delete-icon btn btn-sm float-end"
                onClick={() => handleDeleteCall(index)}
              >
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h3>

            <div className="row">
              {/* Call Date */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Date</label>
                <DatePicker
                  value={call.date ? dayjs(call.date, "DD/MM/YYYY") : null}
                  onChange={(date) =>
                    handleCallsInputChange(index, {
                      target: {
                        name: "date",
                        value: date ? dayjs(date).format("DD/MM/YYYY") : "",
                      },
                    })
                  }
                  className="form-control"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                />
              </div>

             {/* Call Time */}
             <div className="col-md-2 mb-3">
                <label className="col-form-label">Time</label>
                <TimePicker
                        id={`time-picker-${index}`} // Unique ID for each TimePicker
                        value={call.time ? dayjs(call.time, "HH:mm:ss") : null} // Convert stored time string to dayjs object
                        onChange={(time) => {
                          console.log('Selected time:', time); // Log the selected time for debugging
                          if (time) {
                            const formattedTime = time.format("HH:mm:ss"); // Format the time to "HH:mm:ss"
                            handleCallsInputChange(index, {
                              target: { name: 'time', value: formattedTime } // Pass only the time string to the backend
                            });
                            console.log('Formatted time to send:', formattedTime); // Log the formatted time
                          }
                        }}

                        className="form-control"
                        placeholder="Select time"
                />
              </div>

              {/* Call Status */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Status</label>
                <select
                  name="status"
                  className="form-control"
                  value={call.status}
                  onChange={(e) => handleCallsInputChange(index, e)}
                >
                  {dataBucket.SALES_CALL_STATUS?.map((status, key) => (
                    <option key={key} value={status}>
                      {status}
                    </option>
                  ))}
                </select>
              </div>

              {/* Call By */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call By</label>
                <select
                  name="callby"
                  className="form-control"
                  value={call.callby}
                  onChange={(e) => handleCallsInputChange(index, e)}
                >
                  {userNames?.map((name, idx) => (
                    <option key={idx} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Spoke With */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Spoke With</label>
                <input
                  type="text"
                  name="spokeWith"
                  className="form-control"
                  value={call.spokeWith}
                  onChange={(e) => handleCallsInputChange(index, e)}
                />
              </div>

              {/* Remarks */}
              <div className="col-md-12 mb-3">
                <label className="col-form-label">Remarks</label>
                <textarea
                  name="remarks"
                  className="form-control"
                  value={call.remarks}
                  onChange={(e) => handleCallsInputChange(index, e)}
                />
              </div>

              {/* Email Sent (Optional Checkbox) */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Email Sent</label>
                <input
                  type="checkbox"
                  name="emailSent"
                  className="form-check-input"
                  checked={call.emailSent}
                  onChange={(e) => handleCallsInputChange(index, { target: { name: 'emailSent', value: e.target.checked } })}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="mb-3 mt-2">
        <Link to="#" onClick={handleAddNewCall}>
          <i className="fa-solid fa-plus-circle" /> Add Calls
        </Link>
      </div>

      <div className="mb-3">
        <label className="col-form-label">Calling Info Comments</label>
        <textarea
          name="callingComments"
          className="form-control"
          value={formData.callingComments || ''}
          onChange={(e) => handleChange("callingComments", e.target.value)}
        ></textarea>
      </div>
    </>
  );
};

export default CreateCall;
