import { Table, Dropdown, Menu, Button } from "antd";
import { DownOutlined } from '@ant-design/icons'; // To add dropdown arrow icon
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useQuery, useMutation } from "@apollo/client";
import { FaCalendarAlt, FaSyringe, FaShieldAlt, FaBriefcaseMedical } from "react-icons/fa";
import { MdTimeToLeave } from "react-icons/md";
import { message } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "../../../components/modelpopup/EmployeeLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import { base_url } from "../../../base_urls";
import Swal from "sweetalert2";
import { renderOptionsformultiple } from "../../../utilsdata";
import { GET_LEAVE_REQUESTS, USER_LEAVES, GET_EMPLOYEE_LEAVE_DETAILS } from "../../../Leaves/Leaves";
// import { UPDATE_LEAVE_STATUS } from "../../../Queries/Leaves";
import { useDispatch, useSelector } from "react-redux";
import { addLeave, setAllLeaves,updateLeave } from "../../../Slices/LeavesSlice";
import { setLoader } from "../../../Slices/loaderSlice";
import dayjs from "dayjs";
import { gql } from "@apollo/client";
const UPDATE_LEAVE_STATUS = gql`
    mutation updateLeaveStatus($leaveUpdates: [LeaveUpdateInput!]!) {
      updateLeaveStatus(leaveUpdates: $leaveUpdates) {
       _id
        employeeId
        leaveType
        leaveDays
        startDate
        endDate
        leaveStatus
        leaveReason
        managerApproval
        createdAt
        status
        session1
      session2
      }
    }
  `;

const EmployeeLeave = () => {
  
  const [users, setUsers] = useState([]);
  const [leaveData, setLeaveData] = useState();
  const [allLeaves, setAllLeaves] = useState([]);
  
  const [updateLeaveStatus] = useMutation(UPDATE_LEAVE_STATUS, {
    onCompleted: (response) => {
      console.log("response", response);
      refetch();
      // dispatch(updateLeave(response?.UpdateLeaveStatus));
      
      message.success("Leave Deleted successfully!");
    },
    onError: (error) => {
      console.error("Error Deleted leave request:", error);
      message.error("Failed to delete leave request. Please try again.");
    },
  });
  const dispatch = useDispatch();
  // const allLeaves = useSelector(state => state?.leaves?.leaves);
  console.log("allLeaves", allLeaves);

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const userId = parseJwt(token)?.id;

  const { data, loading, error, refetch } = useQuery(GET_LEAVE_REQUESTS, {
    variables: {
      employeeId: userId,
    },
    fetchPolicy: "no-cache", // Ensures fresh data is fetched every time
  });
  
  dispatch(setLoader(loading));

  // const { data:userLeavesData,} = useQuery(USER_LEAVES, {
  //   variables: { id: userId },
  //   fetchPolicy: "no-cache",  // Always fetch from the server
  //   onCompleted: (data) => {
  //     console.log("User Leaves", data?.SingleEmployee?.response?.Leaves[0]?.year);

  //     const leaves = data?.SingleEmployee?.response?.Leaves || [];

  //     const currentYear = new Date().getFullYear().toString();
  //     const currentYearLeaves = leaves?.find((leave) => leave?.year === currentYear);

  //     if (currentYearLeaves) {
  //       setLeaveData(currentYearLeaves);
  //     }
  //   },
  // });
  const { data:userLeavesData } = useQuery(GET_EMPLOYEE_LEAVE_DETAILS, {
    variables: { employeeId:userId },
    fetchPolicy: "no-cache", 
    onCompleted: (data) => {
      console.log("Query completed successfully:", data?.CalculateLeaveDaysResolver[0]);
      setLeaveData(data?.CalculateLeaveDaysResolver[0])
      // Perform any additional actions here, like setting state or logging
    },
  });

  console.log("Users Leaves", leaveData);

  useEffect(() => {
    if (data?.getLeaveRequests) {
      // dispatch(setAllLeaves(data?.getLeaveRequests));
      setAllLeaves(data?.getLeaveRequests);
    }
  }, [data]);
// }, [data, dispatch]);

  useEffect(() => {
    axios
      .get(base_url + "/adminleaves.json")
      .then((res) => setUsers(res.data));
  }, []);

  const options = useSelector((state) => state.dataBucket.databucket);
  const typeOfLeaves = renderOptionsformultiple(options, { name: "TYPES_OF_LEAVES" });
  console.log("adcacascacs", typeOfLeaves);
  const leaves = typeOfLeaves.map((item) => item.value);

  const userElements = users.map((user, index) => ({
    key: index,
    leavetype: user.leavetype,
    from: user.from,
    to: user.to,
    noofdays: user.noofdays,
    reason: user.reason,
    role: user.role,
    status: user.status,
    approvedby: user.approvedby,
  }));

  const formatDate = (timestamp) => {
    return timestamp ? dayjs(Number(timestamp)).format("DD/MM/YYYY") : "N/A";
  };

  const calculateDays = (start, end) => {
    if (!start || !end) return "N/A";
    return dayjs(Number(end)).diff(dayjs(Number(start)), "day") + 1;
  };

  const sortedLeaves = [...allLeaves]?.sort((a, b) => Number(b.createdAt) - Number(a.createdAt))?.filter((leave) => leave?.status !== "false");

  const tableData = sortedLeaves?.map((leave, index) => ({
    key: index,
    leaveType: leave?.leaveType,
    leaveId: leave?._id,
    startDate: leave?.startDate,
    endDate: leave?.endDate,
    noOfDays: leave?.leaveDays,
    leaveReason: leave?.leaveReason,
    leaveStatus: leave?.leaveStatus,
    approvedBy:leave?.approvedBy,
    remarks:leave?.remarks,
    session1:leave?.session1,
    session2:leave?.session2,
  }));
  console.log("sortedLeaves", tableData)

  const columns = [
    {
      title: "Leave Type",
      dataIndex: "leaveType",
      key: "leaveType",
    },
    {
      title: "From",
      dataIndex: "startDate",
      key: "startDate",
      render: (date) => formatDate(date),
    },
    {
      title: "To",
      dataIndex: "endDate",
      key: "endDate",
      render: (date) => formatDate(date),
    },
    {
      title: "No Of Days",
      dataIndex: "noOfDays",
      key: "noOfDays",
    },
    {
      title: "Reason",
      dataIndex: "leaveReason",
      key: "leaveReason",
    },
    {
      title: "Leave Status",
      dataIndex: "leaveStatus",
      key: "leaveStatus",
    },
    {
      title: "Aprooved By",
      dataIndex: "approvedBy",
      key: "approvedBy",
    },
    {
      title: "Remarks",
      dataIndex: "remarks",
      key: "remarks",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => {
        const menu = (
          <Menu>
            <Menu.Item
              key="edit"
              disabled={record?.leaveStatus !== 'Pending'}
              onClick={() => handleEditClick(record)} // Handle edit action
            >
              Edit
            </Menu.Item>
            <Menu.Item
              key="delete"
              danger
              disabled={record?.leaveStatus !== 'Pending'} // Disable delete if not in 'Pending'
              onClick={() => handleDeleteClick(record)} // Handle delete action
            >
              Delete
            </Menu.Item>
          </Menu>
        );

        return (
          <Dropdown overlay={menu} trigger={['click']}>
            <Button>
              Actions <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
  ];

  // const leaveStats = [
  //   {
  //     id: 1,
  //     title: "Total Leaves",
  //     value: parseFloat(leaveData?.medicalLeaves) + parseFloat(leaveData?.AnnualLeaves),
  //   },
  //   {
  //     id: 2,
  //     title: "Medical Leaves",
  //     value: leaveData?.medicalLeaves,
  //   },
  //   {
  //     id: 3,
  //     title: "Used Leaves",
  //     value: leaveData?.usedLeaves,
  //   },
  //   {
  //     id: 4,
  //     title: "Remaining Leaves",
  //     value: leaveData?.availableLeaves,
  //   },
  // ];

  const leaveStats = [
    {
      id: 1,
      title: "Annual Leaves",
      // value: parseFloat(leaveData?.medicalLeaves) + parseFloat(leaveData?.AnnualLeaves),
      value:
        (isNaN(parseFloat(leaveData?.casualLeavesApplied))
          ? 0
          : parseFloat(leaveData?.casualLeavesApplied)) +
        (isNaN(parseFloat(leaveData?.medicalLeavesApplied))
          ? 0
          : parseFloat(leaveData?.medicalLeavesApplied)) -
        ((isNaN(parseFloat(leaveData?.medicalLeavesAvailable))
          ? 0
          : parseFloat(leaveData?.medicalLeavesAvailable)) +
          (isNaN(parseFloat(leaveData?.casualLeavesAvailable))
            ? 0
            : parseFloat(leaveData?.casualLeavesAvailable))),
 
      remainingValue:
        (isNaN(parseFloat(leaveData?.medicalLeavesAvailable))
          ? 0
          : parseFloat(leaveData?.medicalLeavesAvailable)) +
        (isNaN(parseFloat(leaveData?.casualLeavesAvailable))
          ? 0
          : parseFloat(leaveData?.casualLeavesAvailable)),
 
      color: "#23272E",
      badgeColor: "#E5F4FF",
      textColor: "rgb(35, 39, 46)",
      icon: <FaCalendarAlt color="white" size={24} />,
    },
    {
      id: 2,
      title: "Medical Leaves",
      // value: leaveData?.medicalLeaves,
      value:
        isNaN(parseFloat(leaveData?.medicalLeavesApplied)) ||
        isNaN(parseFloat(leaveData?.medicalLeavesAvailable))
          ? 0
          : parseFloat(leaveData?.medicalLeavesApplied) -
            parseFloat(leaveData?.medicalLeavesAvailable),
      remainingValue: leaveData?.medicalLeavesAvailable || 0,
      color: "#007BFF",
      badgeColor: "#E0EDFF",
      textColor: "rgb(0, 123, 255)",
      icon: <FaSyringe color="white" size={24} />,
    },
    {
      id: 3,
      title: "Casual Leaves",
      value:
        isNaN(parseFloat(leaveData?.casualLeavesApplied)) ||
        isNaN(parseFloat(leaveData?.casualLeavesAvailable))
          ? 0
          : parseFloat(leaveData?.casualLeavesApplied) -
            parseFloat(leaveData?.casualLeavesAvailable),
 
      remainingValue: leaveData?.casualLeavesAvailable || 0,
      color: "#9333EA",
      badgeColor: "#F3E8FF",
      textColor: "#6B21A8",
      icon: <FaShieldAlt color="white" size={24} />,
    },
    {
      id: 4,
      title: "Other Leaves",
      // value:  parseFloat(6) - parseFloat(leaveData?.OtherLeaves),
      value: 0,
      remainingValue: 0,
      color: "#EC4899",
      badgeColor: "rgb(243 220 231)",
      textColor: "rgb(236, 72, 153)",
      icon: <FaBriefcaseMedical color="white" size={24} />,
    },
  ];
 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);

  // Handle Edit button click
  const handleEditClick = (record) => {
    setSelectedLeave(record); // Set selected leave data for editing
    setIsModalOpen(true); // Open the modal
  };

  // Close modal function
  const handleModalClose = () => {
    setIsModalOpen(false); // Close the modal
    setSelectedLeave(null); // Clear selected leave data
  };

  // Handle Delete button click
  const handleDeleteClick = (record) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this leave record?",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
    const leaveUpdateInput = {
      leaveId: record.leaveId,
      status: "false", // Mark the leave as deleted
    };

    updateLeaveStatus({
      variables: {
        leaveUpdates: [leaveUpdateInput], // Pass the mutation input
      },
    })
    .then(() => {
      // Optionally refetch the data or update the local state after the deletion
      refetch();
    })
    .catch((err) => {
      console.error("Error updating leave status:", err);
    });
  }
});
  };

  console.log("selectedLeave",selectedLeave)

  return (
    <>
  
    <div id="Leaves_Tab" className="card profile-box flex-fill">
      <div className="page-wrapper">
        <div  className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Dashboard"
            subtitle="Leaves"
            modal="#add_leave"
            name="Add New"
          />
          <div className="row">
            {leaveStats.map((stat, index) => (
              <div className="col-md-3 mb-3" key={index}>
                <div
                  className="stats-card"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "15px",
                    borderRadius: "10px",
                    boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.2)",
                    background: "#fff",
                    position: "relative",
                  }}
                >
                  <div
                    className="icon-container"
                    style={{
                      width: "60px",
                      height: "60px",
                      borderRadius: "50%",
                      background: stat.color,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      position: "absolute",
                      right: "10px",
                      top: "50%",
                      transform: "translateY(-50%)",
                    }}
                  >
                    {stat.icon}
                  </div>
                  <div className="info" style={{ textAlign: "left" }}>
                    <h6 style={{ margin: 0, color: "#666" }}>{stat.title}</h6>
                    <h4 style={{ margin: "5px 0", fontWeight: "bold" }}>{stat.value}</h4>
                    <p
                      style={{
                        background: stat.badgeColor,
                        padding: "3px 8px",
                        borderRadius: "5px",
                        fontSize: "12px",
                        color: stat.textColor,
                        display: "inline-block",
                      }}
                    >
                      Remaining Leaves: {stat.remainingValue}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={tableData}
                  pagination={{ pageSize: 10 }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      
      {/* Employee Leave Modal */}
      <EmployeeLeaveModelPopup
        leaveData={selectedLeave} // Pass selected leave data
        isOpen={isModalOpen} // Modal open/close state
        onClose={handleModalClose} // Close function
        refetch={refetch}
        allLeaves={allLeaves}
        remainingLeaves={leaveData?.availableLeaves}
      />

      {/* Delete Modal */}
      <DeleteModal Name="Delete Leaves" />
      </div>
    </>
  );
};

export default EmployeeLeave;
