import React, { useEffect, useState } from "react";
import { Checkbox, Button, Collapse } from "antd";
import { useSelector ,useDispatch} from "react-redux";
import { UPDATE_EMPLOYEE } from "../../Queries/Employees";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { SideMenu } from "../../Slices/employeeslice";
import { SIDEMENU } from "../../databucket";
import { message } from "antd";
import {updateSidebarData} from "./updateSidebarData"
import { setLoader } from "../../Slices/loaderSlice";
const { Panel } = Collapse;
const ConditionMenu = () => {
  const dispatch=useDispatch();
    const userData = useSelector((state) => state.employee.userData);
    const route=useSelector((state)=>state.Route.route);
  const { Empid } = useParams();
  const token = localStorage.getItem("token");
  const [submitState, setSubmitState] = useState(false)
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const id = parseJwt(token)?.id;
  const ids = Empid ? Empid : id;
  const initialSidemenu = useSelector((state) => state.employee.sidemenu);
  console.log("addsacsc",initialSidemenu);
  const superAdminSidebarData = [
    {
      menu: initialSidemenu
        ? initialSidemenu
          ?.filter((item) => item.status) // Filter out items where status is false
          .map((item) => ({
            menuValue: item.name,
            hasSubRoute: item.Subfields.length > 1,
            showSubRoute: false,
            route: item.Subfields.length === 1 && item.Subfields[0].status ? item.Subfields[0].name : "#", // Check if the subfield has status true
            subMenus:
              item.Subfields.length > 1
                ? item.Subfields
                  .filter((sub) => sub.status) // Filter subfields where status is true
                  .map((sub) => ({
                    menuValue: formatMenuName(sub.name),
                    route: sub.name,
                  }))
                : undefined,
          }))
        : [], // Return an empty array if loginUser.menu is undefined
    },
  ];

  // console.log("efewfewfewfewffew", updateSidebarData(SIDEMENU, initialSidemenu), initialSidemenu)
  function formatMenuName(name) {
    return name?.replace("/", "")?.replace(/([A-Z])/g, " $1").trim();
   }
  
  const Routes = initialSidemenu ? updateSidebarData(SIDEMENU, initialSidemenu) : SIDEMENU
  const [updateEmployee] = useMutation(UPDATE_EMPLOYEE);
  const [sidemenu, setSidemenu] = useState(Routes);
  console.log("dcdscds", initialSidemenu)
  // Determine the maximum number of subroutes across all main routes
  const maxSubroutes = Math?.max(
    ...sidemenu?.map((menu) => (menu.Subfields ? menu.Subfields.length : 0))
  );
  // Handle sidemenu checkbox toggle
  const toggleSidemenuStatus = (index, subIndex = null) => {
    const updatedSidemenu = sidemenu?.map((menu, i) => {
      if (i === index) {
        if (subIndex === null) {
          // Toggle main sidemenu
          const newStatus = !menu.status;
          return {
            ...menu,
            status: newStatus,
            key: newStatus, // Set key based on status
            Subfields: menu.Subfields
              ? menu.Subfields?.map((sub) => ({
                  ...sub,
                  status: newStatus, // Enable or disable based on main status
                  key: newStatus, // Set key for subfields
                }))
              : [],
          };
        } else {
          // Toggle specific subfield
          const updatedSubfields = menu.Subfields?.map((sub, sIndex) =>
            sIndex === subIndex
              ? { ...sub, status: !sub.status, key: !sub.status } // Toggle subfield status & key
              : sub
          );
          // Check if all subfields are unchecked, and uncheck the main menu if so
          const allSubfieldsUnchecked = updatedSubfields?.every(sub => !sub.status);
          const updatedMenu = {
            ...menu,
            Subfields: updatedSubfields,
            status: allSubfieldsUnchecked ? false : menu.status, // Uncheck main menu if all subfields are unchecked
            key: allSubfieldsUnchecked ? false : menu.status, // Ensure key follows status
          };
  
          return updatedMenu;
        }
      }
      return menu;
    });
  console.log("sdvdsvdsv",updatedSidemenu)
    setSidemenu(updatedSidemenu);
  };
  
  
  
  const handleSubmit = async () => {
    console.log("Sidemenu:", sidemenu);
    setSubmitState(true)
    const cleanSubfields = (subfields) => {
      return subfields?.map((subfield) => {
        const { __typename, ...rest } = subfield;
        return rest; // Return cleaned subfield without __typename
      });
    };
    const cleanedSidemenu = sidemenu?.map((item) => {
      const { __typename, Subfields, ...rest } = item;

      // Clean subfields if they exist
      const cleanedSubfields = Subfields ? cleanSubfields(Subfields) : [];

      // Return the item with cleaned subfields
      return { ...rest, Subfields: cleanedSubfields };
    });
    try {
      dispatch(setLoader(true));
      const { data } = await updateEmployee({
        variables: { id: ids, input: { menu: cleanedSidemenu } },
      });

      if (data.UpdateEmploye.status) {
        dispatch(setLoader(false));
        message.success({
          content: "Updated Successfully",
          duration: 5, // Toast will disappear after 5 seconds
          className: 'custom-toast-center', // Custom class to center the toast
        });
        setSubmitState(false)
      }
    } catch (error) {
      message.error({
        content: "Failt to update ",  
        duration: 5,
        className: 'custom-toast-center',
      });
      
      console.error("Error updating employee:", error);
      dispatch(setLoader(false));
    }finally{
      setSubmitState(false)
      dispatch(setLoader(false));
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center", // Center horizontally
        alignItems: "center", // Center vertically
        minHeight: "100vh", // Full viewport height
        backgroundColor: "#f5f5f5", // Optional background color
      }}
    >
      <div
        style={{
          width: "100%",
          maxWidth: "100%", // Optional maximum width to limit size
          padding: "10px",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <h2 style={{ marginBottom: "20px", textAlign: "center", font: "bold" }}>
          Manage Routes and Sidemenu
        </h2>
        <div
          style={{
            padding: "20px",
            background: "#fff",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
          }}
        >
          <Collapse>
            {sidemenu?.map((menu, index) => (
              <Panel
                key={index}
                header={
                  <Checkbox
                    checked={menu.status}
                    onChange={() => toggleSidemenuStatus(index)}
                    style={{
                      fontFamily: "serif",
                      fontSize: "16px",
                    }}
                  >
                    {menu.name}
                  </Checkbox>
                }
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  {menu.Subfields &&
                    menu.Subfields.map((sub, subIndex) => (
                      <div
                        key={subIndex}
                        style={{
                          maxWidth: "200px",
                          padding: "5px",
                          display: "flex",
                          alignItems: "center",
                          border: "1px solid #ddd",
                          borderRadius: "5px",
                          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                        }}
                      >
                        <Checkbox
                          checked={sub.status}
                          disabled={!menu.status} // Disable if main route is unchecked
                          onChange={() => toggleSidemenuStatus(index, subIndex)}
                        >
                          {sub.displayName}
                        </Checkbox>
                      </div>
                    ))}

                  {/* Empty placeholders for remaining subroutes */}
                  {menu.Subfields &&
                    Array(maxSubroutes - menu.Subfields.length)
                      .fill(null)
                      .map((_, emptyIndex) => (
                        <div
                          key={emptyIndex}
                          style={{
                            visibility: "hidden",
                            maxWidth: "200px",
                            padding: "5px",
                            border: "1px solid #ddd",
                            borderRadius: "5px",
                          }}
                        >
                          Placeholder
                        </div>
                      ))}
                </div>
              </Panel>
            ))}
          </Collapse>
        </div>

        <Button
          type="primary"
          onClick={handleSubmit}
          style={{
            marginTop: "20px",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          disabled={submitState}
        >
          Submit
        </Button>
      </div>
    </div>


  );
};

export default ConditionMenu;

