
// export const SidebarData = [



//   {
//     tittle: 'EMPLOYEES',
//     showAsTab: false,
//     separateRoute: false,
//     menu: [
//       {
//         menuValue: 'Employees',
//         hasSubRoute: true,
//         showSubRoute: false,
//         route: "#",
//         icon: "la la-user",
//         subMenus: [
//           {
//             menuValue: 'All Employees',
//             route: "/employees",
//           },
//           {
//             menuValue: 'Holidays',
//             route: "/holidays",
//           },
//           {
//             menuValue: 'Leaves(Admin)',
//             route: "/adminleaves",
//           },
//           {
//             menuValue: 'Leaves(Employee)',
//             route: "/leaves-employee",
//           },
//           {
//             menuValue: 'Leave Settings',
//             route: "/leave-settings",
//           },
//           {
//             menuValue: 'Attendance (Admin)',
//             route: "/adminattendance",
//           },
//           {
//             menuValue: 'Attendance (Employee)',
//             route: "/attendance-employee",
//           },
//           {
//             menuValue: 'Departments',
//             route: "/departments",
//           },
//           {
//             menuValue: 'Designations',
//             route: "/designations",
//           },
//           {
//             menuValue: 'Timesheet',
//             route: "/timesheet",
//           },
//           {
//             menuValue: 'Shift & Schedule',
//             route: "/shift-scheduling",
//           },
//           {
//             menuValue: 'Overtime',
//             route: "/overtime",
//           },
//         ],
//       },
//       {
//         menuValue: 'Clients',
//         hasSubRoute: false,
//         showSubRoute: false,
//         route: "/clients",
//         icon: "la la-users",
//       },
//       {
//         menuValue: 'Sales',
//         hasSubRoute: false,
//         showSubRoute: false,
//         route: "/sales",
//         icon: "la la-bar-chart",
//       },

//       {
//         menuValue: 'Projects',
//         hasSubRoute: true,
//         showSubRoute: false,
//         icon: "la la-rocket",
//         subMenus: [
//           {
//             menuValue: 'Projects',
//             route: "/projects",
//           },
//         ],
//       },
//       {
//         menuValue: 'Tickets',
//         hasSubRoute: true,
//         showSubRoute: false,
//         icon: "la la-ticket",
//         subMenus: [
//           {
//             menuValue: 'Tickets',
//             route: "/tickets"
//           }

//         ],
//       },

//       {
//         menuValue: 'Requests',
//         hasSubRoute: true,
//         showSubRoute: false,
//         icon: "la la-envelope",
//         subMenus: [
//           {
//             menuValue: 'Requests',
//             route: "/request"
//           },
//           {
//             menuValue: 'Reports',
//             route: '/report'
//           }

//         ],
//       },
//       {
//         menuValue: 'CROptions',
//         hasSubRoute: true,
//         showSubRoute: false,
//         icon: "la la-cog",
//         subMenus: [
//           {
//             menuValue: 'CROptions',
//             route: "/customiseRequestOptions"
//           }

//         ],
//       },
//       {
//         menuValue: 'ReportsDesign',
//         hasSubRoute: true,
//         showSubRoute: false,
//         icon: "la la-cog",
//         subMenus: [
//           {
//             menuValue: 'EmployeeReport',
//             route: "/EmployeeReport"
//           }, 
//           {
//             menuValue: 'ClientReport',
//             route: "/ClientReport"
//           }

//         ],
//       },

//     ],
//   },



// ];




// const SidebarData = [];

// let role = 'User';
// let department = 'Sales';

// if (role == 'User'){
//   if( department == 'Sales'){
//     SidebarData.push({
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [

//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         },
//       ],
//     },)
//   } 
//   else if (department == 'Operations'){
//       SidebarData.push({
//         tittle: 'EMPLOYEES',
//         showAsTab: false,
//         separateRoute: false,
//         menu: [

//           {
//             menuValue: 'Requests',
//             hasSubRoute: true,
//             showSubRoute: false,
//             icon: "la la-envelope",
//             subMenus: [
//               {
//                 menuValue: 'Requests',
//                 route: "/request"
//               },
//             ],
//           },        
//         ],
//       },)
//     }
//   } else if (role == 'Admin'){
//     if (department === 'Operations') {
//       SidebarData.push({
//         tittle: 'EMPLOYEES',
//         showAsTab: false,
//         separateRoute: false,
//         menu: [
//           {
//             menuValue: 'Requests',
//             hasSubRoute: true,
//             showSubRoute: false,
//             icon: "la la-envelope",
//             subMenus: [
//               {
//                 menuValue: 'Requests',
//                 route: "/request",
//               },
//               {
//                 menuValue: 'Reports',
//                 route: '/report',
//               },
//             ],
//           },
//         ],
//       });
//     } else if (department === 'Sales') {
//       SidebarData.push({
//         tittle: 'EMPLOYEES',
//         showAsTab: false,
//         separateRoute: false,
//         menu: [
//           {
//             menuValue: 'Sales',
//             hasSubRoute: false,
//             showSubRoute: false,
//             route: "/sales",
//             icon: "la la-bar-chart",
//           },
//         ],
//       });
//     }

//   }else if (role == 'SuperAdmin'){


//   }else {

//   }

//   const userSidebarData = department == 'Operations' ? [
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [

//         {
//           menuValue: 'Requests',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-envelope",
//           subMenus: [
//             {
//               menuValue: 'Requests',
//               route: "/request"
//             },
//               ],
//         },        
//       ],
//     },

//   ] : department == 'Sales' ? [
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [

//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         },  
//       ],
//     },

//   ] : [ 
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [

//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         }, 

//         {
//           menuValue: 'Requests',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-envelope",
//           subMenus: [
//             {
//               menuValue: 'Requests',
//               route: "/request"
//             },
//           ],
//         },
//       ],
//     },
//   ];

//   const AdminSidebarData = department == 'Operations' ? [
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [
//         {
//           menuValue: 'Requests',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-envelope",
//           subMenus: [
//             {
//               menuValue: 'Requests',
//               route: "/request"
//             },
//             {
//               menuValue: 'Reports',
//               route: '/report'
//             }        
//           ],
//         },     
//       ],
//     },

//   ] : department == 'Sales' ? [ 
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [

//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         },  
//       ],
//     },

//   ] : [
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [
//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         },
//         {
//           menuValue: 'Requests',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-envelope",
//           subMenus: [
//             {
//               menuValue: 'Requests',
//               route: "/request"
//             },
//             {
//               menuValue: 'Reports',
//               route: '/report'
//             }        
//           ],
//         },     
//       ],
//     },

//   ]

//   const superAdminSidebarData = [ 
//     {
//       tittle: 'EMPLOYEES',
//       showAsTab: false,
//       separateRoute: false,
//       menu: [
//         {
//           menuValue: 'Employees',
//           hasSubRoute: true,
//           showSubRoute: false,
//           route: "#",
//           icon: "la la-user",
//           subMenus: [
//             {
//               menuValue: 'All Employees',
//               route: "/employees",
//             },
//           ],
//         },
//         {
//           menuValue: 'Clients',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/clients",
//           icon: "la la-users",
//         },
//         {
//           menuValue: 'Sales',
//           hasSubRoute: false,
//           showSubRoute: false,
//           route: "/sales",
//           icon: "la la-bar-chart",
//         },
//         {
//           menuValue: 'Requests',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-envelope",
//           subMenus: [
//             {
//               menuValue: 'Requests',
//               route: "/request"
//             },
//             {
//               menuValue: 'Reports',
//               route: '/report'
//             }
//           ],
//         },
//         {
//           menuValue: 'CROptions',
//           hasSubRoute: true,
//           showSubRoute: false,
//           icon: "la la-cog",
//           subMenus: [
//             {
//               menuValue: 'CROptions',
//               route: "/customiseRequestOptions"
//             }
//           ],
//         },
//       ]
//     }
//   ];


//   let SidebarDataN = role == 'User' ? userSidebarData : role == 'Admin' ? AdminSidebarData : superAdminSidebarData;



//   // export default SidebarData;









// console.log("sidebardataN.jsx file loaded");


const SidebarData = [];

const userSidebarData = {
  Operations: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Requests',
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-envelope",
          subMenus: [
            // {
            //   menuValue: 'Requests2',
            //   route: "/request"
            // },
            {
              menuValue: 'Requests',
              route: "/request"
            },
          ],
        },
      ],
    },
  ],
  Sales: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Sales',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-bar-chart",
          route: "/sales",
          // subMenus: [
          //   {
          //     menuValue: 'Sales 1.0',
          //     route: "/sales1",
          //   },
            // {
            //   menuValue: 'Sales 2.0',
            //   route: "/sales",
            // },
          // ]
        },
      ],
    },
  ],
  both: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Sales',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-bar-chart",
          route: "/sales",
          // subMenus: [
          //   {
          //     menuValue: 'Sales 1.0',
          //     route: "/sales1",
          //   },
          //   {
          //     menuValue: 'Sales 2.0',
          //     route: "/sales",
          //   },
          // ]
        },
        {
          menuValue: 'Requests',
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-envelope",
          subMenus: [
            // {
            //   menuValue: 'Requests2',
            //   route: "/request"
            // },
            {
              menuValue: 'Requests',
              route: "/request"
            },
          ],
        },
        {
          menuValue: 'Dashboards',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-dashboard",
          route: "/dashboard"
        },
      ],
    },
  ],
};

const adminSidebarData = {
  Operations: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Requests',
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-envelope",
          subMenus: [
            // {
            //   menuValue: 'Requests2',
            //   route: "/request"
            // },
            {
              menuValue: 'Requests',
              route: "/request"
            },
            {
              menuValue: 'Reports',
              route: '/reports'
            },
          ],
        },
      ],
    },
  ],
  Sales: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Sales',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-bar-chart",
          route: "/sales",
          // subMenus: [
          //   {
          //     menuValue: 'Sales 1.0',
          //     route: "/sales1",
          //   },
          //   {
          //     menuValue: 'Sales 2.0',
          //     route: "/sales",
          //   },
          // ]
        },
      ],
    },
  ],
  both: [
    {
      tittle: 'EMPLOYEES',
      showAsTab: false,
      separateRoute: false,
      menu: [
        {
          menuValue: 'Sales',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-bar-chart",
          route: "/sales",
          // subMenus: [
          //   {
          //     menuValue: 'Sales 1.0',
          //     route: "/sales1",
          //   },
          //   {
          //     menuValue: 'Sales 2.0',
          //     route: "/sales",
          //   },
          // ]
        },
        {
          menuValue: 'Requests',
          hasSubRoute: true,
          showSubRoute: false,
          icon: "la la-envelope",
          subMenus: [
            // {
            //   menuValue: 'Requests2',
            //   route: "/request"
            // },
            {
              menuValue: 'Requests',
              route: "/request"
            },
            {
              menuValue: 'Reports',
              route: '/report'
            },
          ],
        },
        {
          menuValue: 'Dashboards',
          hasSubRoute: false,
          showSubRoute: false,
          icon: "la la-dashboard",
          route: "/dashboard"
        },
      ],
    },
  ],
};

const superAdminSidebarData = [
  {
    tittle: 'EMPLOYEES',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Employees',
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-users",
        subMenus: [
          {
            menuValue: 'All Employees',
            route: "/employees",
          },
        ],
      },
      {
        menuValue: 'Clients',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/clients",
        icon: "la la-user-tie",
      },
      {
        menuValue: 'Sales',
        hasSubRoute: false,
        showSubRoute: false,
        icon: "la la-chart-line",
        route: "/sales",
        // subMenus: [
        //   {
        //     menuValue: 'Sales 1.0',
        //     route: "/sales1",
        //   },
        //   {
        //     menuValue: 'Sales 2.0',
        //     route: "/sales",
        //   },
        // ]
      },
      {
        menuValue: 'Requests',
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-tasks",
        subMenus: [
          // {
          //   menuValue: 'Requests2',
          //   route: "/request"
          // },
          {
            menuValue: 'Requests',
            route: "/request"
          },
          // {
          //   menuValue: 'Reports',
          //   route: '/report'
          // }
          {
            menuValue: 'Reports',
            route: '/reports'
          },
        ],
      },
      {
        menuValue: 'CROptions',
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-cog",
        subMenus: [
          {
            menuValue: 'CROptions',
            route: "/customiseRequestOptions"
          }
        ],
      },
      {
        menuValue: 'Work Report',
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-bar-chart",
        subMenus: [
          {
            menuValue: 'Employee Report',
            route: "/EmployeeReport"
          },
          {
            menuValue: 'Admin Report',
            route: "/ClientReport"
          },
          // {
          //   menuValue: 'Overwriting History',
          //   route: "/OverwritingHistory"
          // }
        ],
      },
      {
        menuValue: 'Email',
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-envelope",
        subMenus: [
          {
            menuValue: 'BulkEmail',
            route: "/Mail"
          },
        ],
      },
      {
        
          menuValue: 'Data Bucket',
          hasSubRoute: false,
          showSubRoute: false,
          route: "/dataBucket",
          icon: "la la-database",
        
      },
      {
        
        menuValue: 'Daynamic Route',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/DaynamicRoute",
        icon: "la la-database",
      
    },
     
    {
      menuValue: 'Scrapper',
      hasSubRoute: false,
      showSubRoute: false,
      route: "/WebScrapingConfig",
      icon: "la la-users",
    },
    {
      menuValue: 'Dashboards',
      hasSubRoute: false,
      showSubRoute: false,
      route: "/dashboard",
      icon: "la la-dashboard"
    },
    {
      menuValue: 'Holidays',
      hasSubRoute: false,
      showSubRoute: false,
      route: "/holidays",
      icon: "la la-dashboard"
    },
    {
      menuValue: 'Pay Slips',
      hasSubRoute: false,
      showSubRoute: false,
      route: "/payslips",
      icon: "la la-dashboard"
    },

    ]
  }
];


let role = '';
let department = '';

let SidebarDataN =
  role == 'User'
    ? (userSidebarData[department] || userSidebarData.both)
    : role == 'Admin'
      ? (adminSidebarData[department] || adminSidebarData.both)
      : superAdminSidebarData;


console.log('Role:', role);
console.log('Department:', department);
console.log('Sidebar Data', SidebarDataN);


export default SidebarDataN;