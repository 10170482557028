import { useState, useEffect } from 'react';
import { Modal, Button, Row, Col, Select, Form, Input, message, Checkbox } from 'antd';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_CARRIERS, GET_ALL_CURRENT_CARRIERS } from '../../../Queries/Users';
import { CREATE_CURRENT_CARRIER, UPDATE_CURRENT_CARRIER } from '../../../Queries/Orders';
import { useErrorLogger } from "../../../utils";

const { Option } = Select;

const CreateCurrentCarrier = ({ open, handleClose, data,refetch }) => {
    const [carrierName, setCarrierName] = useState('');
    const [status, setStatus] = useState('');
    const [flag, setFlag] = useState(false);
    const { logError } = useErrorLogger();
    const token = localStorage.getItem('token');
    // const { data: existingCarriersData } = useQuery(GET_ALL_CURRENT_CARRIERS);
    const { data: existingCarriersData } = useQuery(GET_ALL_CARRIERS);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const parseJwt = (token) => {
        try {
            return JSON.parse(atob(token.split(".")[1]));
        } catch (e) {
            return null;
        }
    };
    const handleFlagChange = (e) => {
        setFlag(e.target.checked); // Set flag to true/false based on checkbox status
    };
    useEffect(() => {
        setCarrierName(data?.carrierName || '');
        setStatus(data?.status || '');
        setFlag(data?.flag || false); // Set flag from data if available (for editing)
    }, [data]);

    const [createCurrentCarrier] = useMutation(CREATE_CURRENT_CARRIER, {
        onCompleted(data) {
            if (data?.createCurrentCarrier) {
                refetch()
                const messageContent = data.createCurrentCarrier.message || 'Successfully Created Current Carrier';
                message.success(messageContent);
                handleClose(true);
            } else {
                message.error('Error creating carrier');
            }
        },
        onError() {
            logError("CreateCurrentCarrier", "Create Mutation", "error while creatingcarrier")
        }
    });

    const [updateCurrentCarrier] = useMutation(UPDATE_CURRENT_CARRIER, {
        onCompleted(data) {
            if (data?.updateCurrentCarrier) {
                refetch()
                const messageContent = data.updateCurrentCarrier.message || 'Successfully Updated Current Carrier';
                message.success({
                    content: messageContent,
                    duration: 1,
                });
                handleClose(true);
            } else {
                message.error('Error updating carrier');
            }
        },
        onError() {
            logError("CreateCurrentCarrier", "Update Mutation", "error while updatingcarrier")
        }
    });

    const isDuplicateCarrierName = (newCarrierName) => {
        const existingCarriers = existingCarriersData?.getAllCarriers?.response || [];
        return existingCarriers.some(
            (carrier) => carrier.carrierName.toLowerCase() === newCarrierName.toLowerCase()
        );
    };

    const isDuplicateCarrier = (newCarrierName) => {
        const existingCarriers = existingCarriersData?.getAllCarriers?.response || [];
        return existingCarriers.some((carrier) => {
            if (data?.id && carrier.id === data.id) return false;
            return carrier.carrierName.toLowerCase() === newCarrierName.toLowerCase();
        });
    };

    const submit = () => {

        setIsButtonDisabled(true);


        if (!carrierName.trim()) {
            message.error('Please enter a valid Carrier Name');
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }
        if (!status) {
            message.error('Please select Status');
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }

        if (!data?.id && isDuplicateCarrierName(carrierName.trim())) {
            message.error('This carrier name already exists in the system');
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }

        if (data?.id && carrierName.trim() !== data?.carrierName.trim() && isDuplicateCarrier(carrierName.trim())) {
            message.error('A carrier with the same name and status already exists');
            setTimeout(() => {
                setIsButtonDisabled(false);
            }, 3000);
            return;
        }

        if (data?.id && carrierName.trim() === data?.carrierName.trim() && status === data?.status && flag === data?.flag) {
            message.warning("No Change Detected to Update");
            setTimeout(() => setIsButtonDisabled(false), 3000);
            return;
        }


        const token = localStorage.getItem('token');
        const parseJwt = (token) => {
            try {
                return JSON.parse(atob(token.split(".")[1]));
            } catch (e) {
                return null;
            }
        };

        const valuesObject = {
            id: data?.id,
            userId: parseJwt(token)?.id,
            carrierName,
            status,
            flag,
        };


        if (data?.id) {
            updateCurrentCarrier({ variables: valuesObject });
        } else {
            createCurrentCarrier({ variables: valuesObject });
        }


        handleClose(true);


        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 3000);
    };

    return (
        <Modal
            title={data?.id ? "Update Carrier" : "Create Carrier"}
            open={open}
            onCancel={() => handleClose(false)}
            footer={null}
        >
            <Form layout="vertical" onFinish={submit}>
                <Row gutter={16}>
                    <Col span={8}>
                        <Form.Item label="Carrier Name">
                            <Input
                                placeholder="Enter Carrier Name"
                                required
                                value={carrierName}
                                onChange={(e) => setCarrierName(e.target.value)}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item label="Status" rules={[{ required: true, message: 'Please select a Status' }]}>
                            <Select
                                value={status}
                                onChange={(value) => setStatus(value)}
                                placeholder="Select Status"
                            >
                                <Option value="Active">Active</Option>
                                <Option value="Inactive">Inactive</Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                    <Form.Item label="Is Current">
                        <Checkbox checked={flag} onChange={handleFlagChange} />
                    </Form.Item>
                </Col>
                </Row>


                <Form.Item>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            disabled={isButtonDisabled} // Disable the button while processing
                        >
                            {data?.id ? 'Update' : 'Save'}
                        </Button>
                        <Button color='danger' variant='solid' onClick={() => handleClose(false)} style={{ marginLeft: '8px' }}>
                            Cancel
                        </Button>
                    </div>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default CreateCurrentCarrier;
