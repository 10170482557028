import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false, // Default state for the loader
};

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    setLoader: (state, action) => {
      state.isLoading = action.payload;
    },

  },
});

export const { setLoader } = loaderSlice.actions;

export default loaderSlice.reducer;
