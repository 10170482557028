/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import LeaveSettingCustomPolicy from "./LeaveSettingCustomPolicy";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import LeaveSettingAddModelPopup from "../../../components/modelpopup/LeaveSettingAddModelPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import { gql } from "@apollo/client";
import { Select, Switch, message } from "antd";
//  import { UPDATE_LEAVES, GET_LEAVES_DATA } from "../../../Queries/leaves";
import { useMutation, useQuery } from "@apollo/client";
// import { UPDATE_LEAVES,GET_LEAVES_DATA } from "../../../Queries/leaves";
import { UPDATE_LEAVES, GET_LEAVES_DATA } from "../../../Leaves/Leaves";
import { UPDATE_LEAVE_SETTINGS } from "../../../Leaves/Leaves";

const { Option } = Select;

const LeaveSettings = () => {

  // const [carryFrwd, setCarryfrwd] = useState(false);
  // const [show, setShow] = useState(false);
  // const [carryEarned, setCarryearned] = useState(false);
  // const [sickDay, setSickday] = useState(false);
  // const [hosp, setHosp] = useState(false);
  // const [maternty, setMaternty] = useState(false);
  // const [paternty, setPaternty] = useState(false);
  // const [lopDays, setLopdays] = useState(false);
  // const [lopCarryfrwd, setLopcarryfrwd] = useState(false);
  // const [lopEarn, setLopearn] = useState(false);
  // const [carryEnabled, setCarryEnabled] = useState(false);


  const [department, setDepartment] = useState(null);
  const [year, setYear] = useState(null);

  const [createLeaves] = useMutation(UPDATE_LEAVES, {
    onCompleted: (data) => {
      message.success("Employee Leaves updated successfully", 3)
    }
  })

  const { data, loading, error, refetch } = useQuery(GET_LEAVES_DATA, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      // Handle the completed data
      // console.log("Fetched data:", data);
    },
  });

  // Using the mutation hook from Apollo Client
  const [updateLeaveSettings] = useMutation(UPDATE_LEAVE_SETTINGS, {
    onCompleted: (data) => {
      // console.log("data", data.updateLeaveSettings[0]?.response);
    },
    onError: (err) => {
      console.error(err);
    }
  });

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const userId = parseJwt(token)?.id;

  // const handleShow = () => {
  //   setShow(true);
  // };
  // const handleClose = () => {
  //   setShow(false);
  // };

  // const carryFrwdshow = () => {
  //   setCarryfrwd(true);
  // };

  // const carryFrwdclose = () => {
  //   setCarryfrwd(false);
  // };
  // const carryEarnedshow = () => {
  //   setCarryearned(true);
  // };
  // const carryEarnedclose = () => {
  //   setCarryearned(false);
  // };

  // const sickDayshow = () => {
  //   setSickday(true);
  // };
  // const sickDayclose = () => {
  //   setSickday(false);
  // };
  // const hospShow = () => {
  //   setHosp(true);
  // };

  // const hospClose = () => {
  //   setHosp(false);
  // };
  // const materntyShow = () => {
  //   setMaternty(true);
  // };

  // const materntyClose = () => {
  //   setMaternty(false);
  // };
  // const paterntyShow = () => {
  //   setPaternty(true);
  // };

  // const paterntyClose = () => {
  //   setPaternty(false);
  // };
  // const lopDayshow = () => {
  //   setLopdays(true);
  // };

  // const lopDayclose = () => {
  //   setLopdays(false);
  // };
  // const lopCarryshow = () => {
  //   setLopcarryfrwd(true);
  // };
  // const lopCarryclose = () => {
  //   setLopcarryfrwd(false);
  // };
  // const lopEarnshow = () => {
  //   setLopearn(true);
  // };

  // const lopEarnclose = () => {
  //   setLopearn(false);
  // };

  const [leaveData, setLeaveData] = useState({
    casualLeaves: "",
    CarryforwardLeaves: "",
    medicalLeave: "",
    department: "",
    year: "",
    carryFlag: false,
  });
  // const handleDepartmentChange = (value) => {
  //   console.log("Selected Department:", value); // Debugging
  //   setDepartment(value);
  //   setLeaveData((prev) => ({ ...prev, department: value })); // Correct key
  // };


  // const handleNameChange = (value) => {
  //   setYear(value);
  //   // const matchedData = data?.GetAllLeaves?.response.find(
  //   //   (item) => item.department === department && item.year === value
  //   // );

  //   // if (matchedData) {
  //   //   setLeaveData((prev) => ({
  //   //     ...prev,
  //   //     year: value,
  //   //     medicalLeave: matchedData.medicalLeave,
  //   //     CarryforwardLeaves: matchedData.CarryforwardLeaves,
  //   //     // carryFlag: matchedData.carryFlag,
  //   //   }));
  //   // } else {
  //   setLeaveData((prev) => ({ ...prev, year: value }));
  //   // }
  // };
  useEffect(() => {
    const matchedData = data?.GetAllLeaves?.response.find(
      (item) => item.department == leaveData.department && item.year == leaveData.year
    );
    console.log("Matched Data:", leaveData.department, leaveData.year, matchedData); // Debugging
    if (matchedData) {
      setLeaveData((prev) => ({
        ...prev,
        medicalLeave: matchedData?.medicalLeave,
        CarryforwardLeaves: matchedData?.carryFlag ? matchedData?.CarryforwardLeaves : "0",
        casualLeaves: matchedData?.casualLeaves,
        carryFlag: matchedData?.carryFlag,
      }));
      console.log("sdcdscdscsdcs", leaveData, matchedData)
    } else {
      setLeaveData((prev) => ({
        ...prev,
        medicalLeave: "",
        CarryforwardLeaves: "",
        casualLeaves: "",
        carryFlag: false,
      }));
    }
  }, [leaveData.department, leaveData.year])
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   let updatedValue = value;

  //   // if (name === "CarryforwardLeaves" && value === "Yes") {
  //   //   setCarryfrwd(true);
  //   //   setLeaveData({ ...leaveData, CarryforwardLeaves: value});
  //   // } else if (name === "CarryforwardLeaves" && value === "No") {
  //   //   setCarryfrwd(false);
  //   //   setLeaveData({ ...leaveData, CarryforwardLeaves: value});
  //   //} 
  //   setLeaveData({ ...leaveData, [name]: updatedValue })
  // };

  // Unified change handler for all inputs and select changes
  const handleChange = (name, value) => {
    setLeaveData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [isDisabled, setIsDisabled] = useState(false);

  const startTimer = () => {
    setIsDisabled(true); // Disable the button
    setTimeout(() => {
      setIsDisabled(false); // Enable the button after 5 seconds
    }, 3000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      if (!leaveData.year || !leaveData.department) {
        message.error("Year and Department are required.");
        startTimer();
        return;
      }

      if (!leaveData.casualLeaves || !leaveData.medicalLeave) {
        message.error("Casual Leaves and Medical Leave are required.");
        startTimer();
        return;
      }

      if (leaveData.carryFlag && !leaveData.CarryforwardLeaves) {
        message.error("Carry forward Leaves must be entered if Carry forward is Yes.");
        startTimer();
        return;
      }
      startTimer();
      await createLeaves({
        variables: {
          input: leaveData
        }
      });
      await updateLeaveSettings({
        variables: {
          ...leaveData,
          EmpID: userId,
          Key: false,
        }
      });
      refetch();
      console.log("Submitted Data:", leaveData);
    } catch (error) {
      console.error("Error submitting leave data:", error);
    }
  };

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Leave Settings"
            title="Dashboard"
            subtitle="Leave Settings"
            modal="#add_custom_policy"
            name="Add New"
          />
          {/* /Page Header */}

          <div className="row">
            <div className="col-md-12">
              {/* Annual Leave */}
              <form onSubmit={handleSubmit}>
                <div className="card leave-box " id="leave_annual">
                  <div className="card-body">
                    <div style={{ padding: "10px", background: "#fff" }}>
                      <div style={{ display: "flex", justifyContent: "space-around" }}>
                        <div style={{ marginBottom: "15px" }}>
                          <label style={{ marginRight: "10px" }}>Select Department:</label>
                          <Select
                            value={leaveData.department}  // Ensure it's controlled
                            style={{ width: 200 }}
                            onChange={(value) => handleChange("department", value)}
                          >
                            <Option value="Sales">Sales</Option>
                            <Option value="Operations">Operations</Option>
                            <Option value="Both">Both</Option>
                          </Select>

                        </div>

                        <div style={{ marginBottom: "15px" }}>
                          <label style={{ marginRight: "10px" }}>Select Year:</label>
                          <Select
                            value={leaveData.year}  // Ensure it's controlled
                            style={{ width: 200 }}
                            // onChange={handleNameChange}
                            onChange={(value) => handleChange("year", value)}
                          >
                            <Option value="2024">2024</Option>
                            <Option value="2025">2025</Option>
                            <Option value="2026">2026</Option>
                          </Select>
                        </div>
                      </div>
                    </div>
                    <div className="h3 card-title with-switch">
                      Annual
                      {/* <div className="onoffswitch">
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          className="onoffswitch-checkbox"
                          id="switch_custom01"
                          defaultChecked
                        />
                        <label
                          className="onoffswitch-label"
                          htmlFor="switch_custom01"
                        >
                          <span className="onoffswitch-inner" />
                          <span className="onoffswitch-switch" />
                        </label>
                      </div> */}
                    </div>
                    <div className="leave-item">
                      {/* Annual Days Leave */}
                      <div className="leave-row">
                        <div className="leave-left">
                          <div className="input-box">
                            <div className="input-block">
                              <label>Casual Leaves</label>
                              <input
                                value={leaveData.casualLeaves}
                                type="number"
                                name="casualLeaves"
                                onChange={(e) => handleChange("casualLeaves", e.target.value)}
                                className="form-control"
                              // disabled={!show ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        {/* {show ? (
                          <div className="leave-right">
                            <button className="btn btn-white leave-cancel-btn" type="button" onClick={handleClose}>
                              Cancel
                            </button>
                            <button className="btn btn-primary leave-save-btn" type="button">
                              Save
                            </button>

                          </div>
                        ) : (
                          <div className="leave-right">
                            <button
                              className="leave-edit-btn"
                              onClick={handleShow}
                            >
                              Edit
                            </button>
                          </div>
                        )} */}
                      </div>
                      <div className="leave-row">
                        <div className="leave-left">
                          <div className="input-box">
                            <div className="input-block">
                              <label>Medical Leaves</label>
                              <input
                                value={leaveData.medicalLeave}
                                type="number"
                                className="form-control"
                                name="medicalLeave"
                                onChange={(e) => handleChange("medicalLeave", e.target.value)}
                              // disabled={!show ? true : false}
                              />
                            </div>
                          </div>
                        </div>
                        {/* {show ? (
                          <div className="leave-right">
                            <button className="btn btn-white leave-cancel-btn" type="button" onClick={handleClose}>
                              Cancel
                            </button>
                            <button className="btn btn-primary leave-save-btn" type="button">
                              Save
                            </button>

                          </div>
                        ) : (
                          <div className="leave-right">
                            <button
                              className="leave-edit-btn"
                              onClick={handleShow}
                            >
                              Edit
                            </button>
                          </div>
                        )} */}
                      </div>
                      {/* /Annual Days Leave */}
                      {/* Carry Forward */}
                      <div className="leave-row">
                        <div className="leave-left">
                          <div style={{ display: "flex", flexDirection: "column", gap: "5px" }}>
                            <label style={{ color: '#1c8fbb' }}>Carry Forward</label>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '10px' }}>
                              <Switch
                                checked={leaveData.carryFlag === true}
                                onChange={(checked) =>
                                  handleChange("carryFlag", checked)
                                }
                              />
                              <span>{leaveData.carryFlag ? "Yes" : "No"}</span>
                            </div>
                          </div>

                        </div>

                        {/* {carryFrwd ? (
                          <div className="leave-right">
                            <button
                              className="btn btn-white leave-cancel-btn"
                              onClick={carryFrwdclose}
                            >
                              {" "}
                              Cancel
                            </button>
                            <button className="btn btn-primary leave-save-btn">
                              {" "}
                              Save
                            </button>
                          </div>
                        ) : (
                          <div className="leave-right">
                            <button
                              className="leave-edit-btn"
                              onClick={carryFrwdshow}
                            >
                              Edit
                            </button>
                          </div>
                        )} */}
                      </div>
                      {
                        leaveData.carryFlag &&
                        < div className="leave-row">
                          <div className="leave-left">
                            <div className="input-box">
                              <div className="input-block">
                                <label>Carry Forward Leaves</label>
                                <input
                                  value={leaveData.carryFlag ? leaveData.CarryforwardLeaves : 0}
                                  type="number"
                                  className="form-control"
                                  name="CarryforwardLeaves"
                                  onChange={(e) => handleChange("CarryforwardLeaves", e.target.value)}
                                // disabled={!leaveData.carryFlag} // Disable if carryFlag is "No"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                      {/* /Carry Forward */}
                      {/* Earned Leave */}

                      {/* <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <label className="d-block">Earned leave</label>
                          <div className="leave-inline-form">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                defaultValue="option1"
                                disabled={!carryEarned}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                No
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                defaultValue="option2"
                                disabled={!carryEarned}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {carryEarned ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={carryEarnedclose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={carryEarnedshow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div> */}
                      {/* /Earned Leave */}
                    </div>

                  </div>
                </div>
                <div className="text-right mt-3">
                  <button disabled={isDisabled} className="btn btn-primary" type="submit">Submit</button>
                </div>
              </form>
              {/* <LeaveSettingCustomPolicy /> */}
              {/* /Annual Leave */}
              {/* Sick Leave */}
              {/* <div className="card leave-box" id="leave_sick">
                <div className="card-body">
                  <div className="h3 card-title with-switch">
                    Sick
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="switch_sick"
                        defaultChecked
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="switch_sick"
                      >
                        <span className="onoffswitch-inner" />
                        <span className="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div className="leave-item">
                    <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <div className="input-block">
                            <label>Days</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!sickDay}
                            />
                          </div>
                        </div>
                      </div>

                      {sickDay ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={sickDayclose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={sickDayshow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <LeaveSettingCustomPolicy />
              </div> */}
              {/* /Sick Leave */}
              {/* Hospitalisation Leave */}
              <div id="leave_hospitalisation">
                <div className="card-body">
                  {/* <div className="h3 card-title with-switch">
                    Hospitalisation
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="switch_hospitalisation"
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="switch_hospitalisation"
                      >
                        <span className="onoffswitch-inner" />
                        <span className="onoffswitch-switch" />
                      </label>
                    </div>
                  </div> */}
                  <div className="leave-item">
                    {/* Annual Days Leave */}
                    <div className="leave-row">
                      {/* <div className="leave-left">
                        <div className="input-box">
                          <div className="input-block">
                            <label>Days</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!hosp}
                            />
                          </div>
                        </div>
                      </div> */}
                      {/* {hosp ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={hospClose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button className="leave-edit-btn" onClick={hospShow}>
                            Edit
                          </button>
                        </div>
                      )} */}
                    </div>
                    {/* /Annual Days Leave */}
                  </div>
                </div>
              </div>
              {/* /Hospitalisation Leave */}
              {/* Maternity Leave */}
              {/* <div className="card leave-box" id="leave_maternity">
                <div className="card-body">
                  <div className="h3 card-title with-switch">
                    Maternity{" "}
                    <span className="subtitle">Assigned to female only</span>
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="switch_maternity"
                        defaultChecked
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="switch_maternity"
                      >
                        <span className="onoffswitch-inner" />
                        <span className="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div className="leave-item">
                    <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <div className="input-block">
                            <label>Days</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!maternty}
                            />
                          </div>
                        </div>
                      </div>
                      {maternty ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={materntyClose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={materntyShow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Maternity Leave */}
              {/* Paternity Leave */}
              {/* <div className="card leave-box" id="leave_paternity">
                <div className="card-body">
                  <div className="h3 card-title with-switch">
                    Paternity{" "}
                    <span className="subtitle">Assigned to male only</span>
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="switch_paternity"
                        disabled={!paternty}
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="switch_paternity"
                      >
                        <span className="onoffswitch-inner" />
                        <span className="onoffswitch-switch" />
                      </label>
                    </div>
                  </div>
                  <div className="leave-item">
                    <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <div className="input-block">
                            <label>Days</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!paternty}
                            />
                          </div>
                        </div>
                      </div>
                      {paternty ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={paterntyClose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={paterntyShow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div> */}
              {/* /Paternity Leave */}
              {/* Custom Create Leave */}
              <div className id="leave_custom01">
                <div className="card-body">
                  {/* <div className="h3 card-title with-switch">
                    LOP
                    <div className="onoffswitch">
                      <input
                        type="checkbox"
                        name="onoffswitch"
                        className="onoffswitch-checkbox"
                        id="switch_custom01"
                        defaultChecked
                      />
                      <label
                        className="onoffswitch-label"
                        htmlFor="switch_custom01"
                      >
                        <span className="onoffswitch-inner" />
                        <span className="onoffswitch-switch" />
                      </label>
                    </div>
                    <button
                      className="btn btn-danger leave-delete-btn"
                      type="button"
                    >
                      Delete
                    </button>
                  </div> */}
                  <div className="leave-item">
                    {/* Annual Days Leave */}
                    {/* <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <div className="input-block">
                            <label>Days</label>
                            <input
                              type="text"
                              className="form-control"
                              disabled={!lopDays}
                            />
                          </div>
                        </div>
                      </div>
                      {lopDays ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={lopDayclose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={lopDayshow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div> */}
                    {/* /Annual Days Leave */}
                    {/* Carry Forward */}
                    <div className="leave-row">
                      {/* <div className="leave-left">
                        <div className="input-box">
                          <label className="d-block">Carry forward</label>
                          <div className="leave-inline-form">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="carryForward"
                                id="carry_no_01"
                                defaultValue="option1"
                                disabled={!lopCarryfrwd}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="carry_no_01"
                              >
                                No
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="carryForward"
                                id="carry_yes_01"
                                defaultValue="option2"
                                disabled={!lopCarryfrwd}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="carry_yes_01"
                              >
                                Yes
                              </label>
                            </div>
                            <div className="input-group">
                              <span className="input-group-text">Max</span>
                              <input
                                type="text"
                                className="form-control"
                                disabled={!lopCarryfrwd}
                              />
                            </div>
                          </div>
                        </div>
                      </div> */}

                      {/* {lopCarryfrwd ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={lopCarryclose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={lopCarryshow}
                          >
                            Edit
                          </button>
                        </div>
                      )} */}
                    </div>
                    {/* /Carry Forward */}
                    {/* Earned Leave */}
                    {/* <div className="leave-row">
                      <div className="leave-left">
                        <div className="input-box">
                          <label className="d-block">Earned leave</label>
                          <div className="leave-inline-form">
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio1"
                                defaultValue="option1"
                                disabled={!lopEarn}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio1"
                              >
                                No
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="inlineRadioOptions"
                                id="inlineRadio2"
                                defaultValue="option2"
                                disabled={!lopEarn}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="inlineRadio2"
                              >
                                Yes
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      {lopEarn ? (
                        <div className="leave-right">
                          <button
                            className="btn btn-white leave-cancel-btn"
                            onClick={lopEarnclose}
                          >
                            {" "}
                            Cancel
                          </button>
                          <button className="btn btn-primary leave-save-btn">
                            {" "}
                            Save
                          </button>
                        </div>
                      ) : (
                        <div className="leave-right">
                          <button
                            className="leave-edit-btn"
                            onClick={lopEarnshow}
                          >
                            Edit
                          </button>
                        </div>
                      )}
                    </div> */}
                    {/* /Earned Leave */}
                  </div>
                  {/* <LeaveSettingCustomPolicy /> */}
                </div>
              </div>
            </div>
            {/* /Custom Create Leave */}
          </div>
        </div >
      </div >
      {/* /Page Content */}

      {/* <LeaveSettingAddModelPopup />
      <DeleteModal Name="Custom Policy" /> */}
    </>
  );
};

export default LeaveSettings;
