import React, { useEffect, useState, useContext } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import "./Frames.scss";
import { Table, Button, Form, Select, Spin, Modal, message, Row, Col, Input, DatePicker, Card, AutoComplete } from "antd";
import { FilterOutlined, CloseOutlined } from '@ant-design/icons';
import { GET_ALL_ORDERS, CREATE_REQUEST } from "../../../Queries/Orders";
import { GET_ALL_CLIENTS } from '../../../Queries/Users.js';
import { useQuery, useMutation } from '@apollo/client';
import { useDispatch, useSelector } from "react-redux";
import { getOrders, getReportData, getSummaryData, TotalCount, updateOrders, addNewRequestAtTop, submitRefreshPage, getRequest } from "../../../Slices/orderSlice";
import { FaCloudDownloadAlt } from "react-icons/fa";
import dayjs from 'dayjs';
//import {Loader} from 'react-loader-spinner';
import { useErrorLogger } from "../../../utils";
//import { Form } from 'react-bootstrap';
import AddRequest from "./AddRequest";
import RequestDetails from "./RequestDetails";
import { setLoader } from "../../../Slices/loaderSlice";
import { setRefreshStatus } from "../../../Slices/salesSlice";
import Swal from 'sweetalert2';

const Requests = () => {
  const { id } = useParams();
  console.log("id", id)
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const orders = useSelector((state) => state?.orders?.orders || []);
  const tableData = useSelector((state) => state?.orders?.orders);
  console.log("orders", orders)
  const selectedOrder = orders?.find(item => item?.id === id);
  console.log("Selected Order:", selectedOrder);
  //const summary = useSelector((state) => state?.orders?.summary);
  // const summary = useSelector((state) => state.orders.summary);
  // console.log("summary", summary)
  const totalCount = useSelector((state) => state?.orders?.TotalCount);
  //const instructionsOrders = useSelector((state) => state.orders.instructionsOrders);
  const [filteredOrders, setFilteredOrders] = useState([...orders]);
  const [isAllOrders, setIsAllOrders] = useState(false);
  // const [filteredStatus, setFilteredStatus] = useState("Inbox");
  const [filteredStatus, setFilteredStatus] = useState("Inbox");
  const [filteredPriority, setFilteredPriority] = useState("");
  const [filter, setFilter] = useState({ filter: "Inbox" });
  const [dropDownOpen, setDropdownOpen] = useState(false)
  const [open, setOpen] = useState(false);
  const [pageChanged, setPageChanged] = useState(false);
  const [isRequestAdded, setIsRequestAdded] = useState(false);
  const [valuesToSubmit, setValuesToSubmit] = useState(null);
  //const [loaders, setLoaders] = useState(false);
  const [requestId, setRequestId] = useState("");
  const [clientName, setClientName] = useState("");
  const [requestStatus, setRequestStatus] = useState("");
  const [date, setDate] = useState(null);
  const [dueDate, setDueDate] = useState(null);
  //const [submitInProgress, setSubmitInProgress] = useState(false);
  const [radioFilter, setRadioFilter] = useState("requestId");
  const [prevVisibility, setDivVisibility] = useState(false);
  const [slide, setSlide] = useState(false);
  // const [clients, setClients] = useState([]);
  const clients = useSelector(state => state.clients.clients) || [];
  const { logError } = useErrorLogger();

  const refreshStatus = useSelector((state) => state.orders.refreshPage)
  console.log("refreshStatus", refreshStatus)

  const { data, refetch, loading, error } = useQuery(GET_ALL_ORDERS, {
    variables: { page: page, pageSize: pageSize, filters: filter, isAllOrders: isAllOrders },
    fetchPolicy: 'no-cache',
    skip: refreshStatus,
  })

  // const { } = useQuery(GET_ALL_CLIENTS, {
  //   onCompleted(data) {
  //     setClients(data?.getAllClients?.response)

  //     console.log("clients", clients)
  //   }
  // });
  dispatch(setLoader(loading))

  const datacontent = data && data?.getAllOrders
  console.log("datecontent", datacontent)

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setIsRequestAdded(!isRequestAdded)
  };
  const handleFilters = () => {
    setDivVisibility((prevVisibility) => !prevVisibility);
    // setSlide(!slide);
    // document.body.classList.toggle("container-sidebar");

  };

  const handleClientNameChange = (value) => {
    setClientName(value);
  };

  const handleSelectClient = (value) => {
    console.log('Selected Client:', value);
    setClientName(value);
  };

  const closeCard = () => {
    setDivVisibility(false);
    //document.body.classList.toggle("mini-sidebar");
  };
  const handleKeyDown = (e) => {
    let value = e.target.value.replace(/[^\d\/]/g, ''); // Remove non-numeric and non-slash characters

    if (value.length >= 10 && e.key !== "Backspace") {
      e.preventDefault(); // Prevent typing more than 10 characters
      return;
    }

    value = value
      .replace(/^(\d{2})(\d)$/g, '$1/$2') // Add '/' after first two digits (DD/)
      .replace(/^(\d{2}\/\d{2})(\d{1,4})$/g, '$1/$2'); // Add '/' after month (DD/MM/YYYY)

    e.target.value = value;
  };
  // const fetchData = async () => {
  //   try {
  //     //await refetch();
  //     // const datacontent = data && data.getAllOrders
  //     // console.log("datacontent", datacontent);
  //     if (datacontent?.response) {
  //       dispatch(getOrders(datacontent.response));
  //       dispatch(getReportData(datacontent.response));
  //       dispatch(TotalCount(datacontent.totalRecords));
  //       dispatch(getSummaryData(datacontent.summary));
  //     }
  //     //setLoader(false)
  //   }
  //   catch (error) {
  //     logError("Requests", "get all orders data successfully", error.message)
  //   }
  // };
  // useEffect(() => {
  //   // if (data) {
  //   fetchData();
  //   // }
  // }, [datacontent, dispatch]);

  useEffect(() => {
    if (data && !refreshStatus) {
      dispatch(getOrders(data.getAllOrders.response));
      dispatch(getReportData(data.getAllOrders.response));
      dispatch(TotalCount(data.getAllOrders.totalRecords));
      dispatch(getSummaryData(data.getAllOrders.summary));
      dispatch(setRefreshStatus(false));
    }
  }, [data, dispatch, refreshStatus]);


  useEffect(() => {
    setFilteredOrders(orders);
    //switchOrders(filteredStatus)
  }, [orders])

  const token = localStorage.getItem('token');
  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

  const [newRequest, setNewRequest] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const startTimer = () => {
    setIsDisabled(true);
    setTimeout(() => {
      setIsDisabled(false);
    }, 3000);
  };

  const requestSubmit = async (values) => {
    console.log(values)
    const userid = parseJwt(token)
    const { fromEmail, toEmail, Subject, ...newObject } = newRequest;
    const { fromEmal: from, toEmail: to, Subject: subject, ...createdObject } = values;

    if (JSON.stringify(newObject) === JSON.stringify(createdObject)) {
      startTimer();
      message.error({
        content: "You haven't changed anything compared to the last request. Please modify a value before submitting.",
        duration: 3,
        className: 'custom-toast-center',
      });
      return;
    }
    startTimer();
    setNewRequest(values)
    // const userConfirmed = await Swal.fire({
    //   title: 'Are you sure?',
    //   text: 'Do you want to submit this Request?',
    //   icon: 'question',
    //   showCancelButton: true,
    //   confirmButtonColor: '#3085d6',
    //   cancelButtonColor: '#d33',
    //   confirmButtonText: 'Yes,create!',
    //   cancelButtonText: 'Cancel',
    // });

    // if (!userConfirmed.isConfirmed) {
    //   return; // Exit if the user cancels
    // }
    dispatch(setLoader(true))
    try {
      const valuesObject = {
        requestId: values?.requestId,
        subject: values?.subject,
        body: values?.body,
        date: Date.now().toString(),
        requestStatus: "Open",
        radioInfo: "Inbox",
        client: values?.client,
        clientName: values?.clientName,
        priority: values?.priority,
        // priority: priorityHandler(values),
        createdBy: userid.id,
        //priority: values.priority,
        request_type: values.request_type,
        instructions: values.instructions,
        fromPending: values.fromPending,
        quoteType: values.quoteType,
        department: values.department,
        insuredName: values.insuredName,
        noofQuotes: parseInt(values.noofQuotes),
        holderName: values.holderName,
        insuredDelivery: values.insuredDelivery,
        holderDelivery: values.holderDelivery,
        accountManagerDelivery: values.accountManagerDelivery,
        otherDelivery: values.otherDelivery,
        radioValue: values.radioValue,
        Auto: values.Auto,
        MotorCycle: values.MotorCycle,
        Boat: values.Boat,
        DP3: values.DP3,
        HO3: values.HO3,
        HO4: values.HO4,
        HO5: values.HO5,
        HO6: values.HO6,
        comments: values.comments,
        processType: values.processType,
        assignedTo: values.assignedTo,
        assignTo: values.assignTo,
        from: values?.from,
        to: values?.to,
        requestStatus: values.requestStatus,
        Assign: values.Assign,
        radioInfo: values.radioInfo,
        emailSummary: values.emailSummary,
        requestor: values.requestor,
        sendTo: values.sendTo,
        newQuoteInformation: values.newQuoteInformation.map(({ __typename, ...rest }) => rest),
        existingPolicyInformation: values.existingPolicyInformation.map(({ __typename, ...rest }) => rest)


      }
      //setValuesToSubmit(valuesObject);
      createRequest({ variables: valuesObject })
      // Swal.close();
    }
    catch (error) {
      dispatch(setLoader(false))
      // Log the error using logError function for better tracking
      await logError("Requests", "submit", error.message);

      // Log the error in the console for development
      console.error("Error updating lead:", error);
      // Swal.close();
      message.error({
        content: "Failed to create lead. Please try again.",
        duration: 5,
        className: 'alert alert-danger text-center',
      });
    }

  }

  const priorityHandler = (values) => {
    let priority = values.priority;
    const { body, subject } = values;
    console.log('sdicdsv', body, subject)
    if (body?.toLowerCase().includes("urgent") || body?.toLowerCase().includes("rush") || subject?.toLowerCase().includes("urgent") || subject?.toLowerCase().includes("rush")) {
      priority = 'Rush';
    } else {

    }

    console.log('priority:', priority);
    return priority;
  };

  const [popupStatus, setSetPopupStatus] = useState(false);
  const [submitStatus, setSubmitStatus] = useState('')

  const handleDialogClose = async () => {
    setSetPopupStatus(false);
    // if (valuesToSubmit) {
    //   await createRequest({ variables: valuesToSubmit });
    //   setValuesToSubmit(null);
    // }
    handleClose()
  };

  const [createRequest] = useMutation(CREATE_REQUEST, {
    onCompleted: async (data) => {
      console.log("New Request Data:", data);
      dispatch(setLoader(false))
      dispatch(submitRefreshPage(true));
      dispatch(getRequest(data.createRequest));
      //dispatch(updateOrders([data?.createRequest, ...orders]));
      const newRequest = data?.createRequest;
      const updatedOrders = [newRequest, ...rowsWithSerialNumber];  // New request at the top
  
      dispatch(submitRefreshPage(true));
      //dispatch(getRequest(newRequest));  // Optionally, update the Redux state with the new request
      dispatch(updateOrders(updatedOrders));
      message.success({
        content: `Request created successfully ${data?.createRequest?.requestId}`,
        duration: 5,
        className: 'custom-toast-center', // Custom success styling
      });
    },
    onError(error) {
      dispatch(setLoader(false))
      setSetPopupStatus(true)
      setSubmitStatus('An error occurred. Please try again.'); // Set your error message

    }
  });

  const getDateTime = (timeValue) => {
    console.log('getDateTime', timeValue)
    const startDate = new Date(timeValue).toLocaleDateString();
    const startTimeString = new Date(timeValue).toLocaleTimeString(undefined, { hour12: true });

    const dateTimeString = `${startDate} ${startTimeString}`;

    return dateTimeString;
  }
  const getDueTime = (timeValue, typeValue) => {

    const formattedDateTime = moment(timeValue).format('YYYY-MM-DD HH:mm:ss');

    const newDateTime = moment(formattedDateTime).add(typeValue, 'hours').format('YYYY-MM-DD HH:mm:ss');

    return newDateTime;
  }
  const GetDueDateTemp = (obj) => {
    let priority = obj?.priority;
    let date = obj?.date;
    let dateValue;
    if (obj?.body?.toLowerCase().includes("urgent") || obj?.body?.toLowerCase().includes("rush") || obj?.subject?.toLowerCase().includes("urgent") || obj?.subject?.toLowerCase().includes("rush")) {
      priority = 'Rush';
    }

    if (priority == 'Rush') {

      dateValue = getDateTime(getDueTime(parseInt(date), 2))

    } else if (priority == 'Bulk') {
      dateValue = getDateTime(getDueTime(parseInt(date), 48));
    } else {
      dateValue = getDateTime(getDueTime(parseInt(date), 24));
    }
    return dateValue
  }

  const userid = parseJwt(token);

  const handleTableChange = (pagination) => {

    setPage(pagination.current);
    setPageSize(pagination.pageSize);
    console.log("Pagination changed", pagination);
  };

  const handleDetailPage = (record) => {
    navigate(`/request/${record}`)
  }
  function formatDateTime(timestamp) {
    const date = new Date(parseInt(timestamp));
    console.log("date", date)
    const formattedDate = date.toLocaleString('en-IN', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
      hour12: true,
    });
    return formattedDate;
  }

  const columns = [
    {
      title: "#",
      dataIndex: "sno",
      sorter: (a, b) => a.sno?.length - b.sno?.length,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Request #",
      dataIndex: "requestId",
      width:80,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Recieved Date",
      dataIndex: "date",
      width: 138,
      render: (text) => formatDateTime(text),
      ellipsis: {
        showTitle: false,
      },
    },

    {
      title: "Request Type",
      dataIndex: "processType",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Insured",
      dataIndex: "insuredName",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => text?.length > 10 ? `${text.slice(0, 10)}...` : text
    },
    {
      title: "Subject of Email",
      dataIndex: "subject",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => text?.length > 10 ? `${text.slice(0, 10)}...` : text
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      width:110,
      ellipsis: {
        showTitle: false,
      },
      render: (value, record) => {
        let backgroundColor = ' #32cd32';
        if (value === 'Rush') {
          backgroundColor = ' #ff0000';
        } else if (value === 'On Demand') {
          backgroundColor = ' #f59a73';
        } else if (value === 'Bulk') {
          backgroundColor = ' #add8e6';
        }

        return (
          <div
            style={{
              backgroundColor: backgroundColor,
              border: '1px solid #d6d6d6',
              paddingRight: '8px',
              display: 'flex',
              alignItems: 'center',
              color: 'black',
              borderRadius: 20,
              paddingLeft: '8px',
              paddingTop: '4px',
              paddingBottom: '4px',
            }}
          >
            {value || priorityHandler(record)}
          </div>
        );
      }
    },
    {
      title: "Status",
      dataIndex: "requestStatus",
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Assigned Staff",
      dataIndex: "assignedTo",
      width:140,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Requestor",
      dataIndex: "requestor",
      ellipsis: {
        showTitle: false,
      },
      selector: row => row.subject,
    },
    {
      title: "Due Date",
      dataIndex: "dueDate",
      width:138,
      ellipsis: {
        showTitle: false,
      },
      //sorter: (a, b) => a.dueDate?.length - b.dueDate?.length,
      // render: (text) => {
      //     if (!text) return ""; // Return an empty string if text is null or undefined
      //     const formattedDate = formatDateTime(text);
      //     return formattedDate !== "Invalid Date" ? formattedDate : GetDueDateTemp(text); // Return empty string if the date is invalid
      // }
      render: (value, record) => value ? formatDateTime(value) : GetDueDateTemp(record),
    },
    {
      title: "Count",
      dataIndex: "noofQuotes",
      width:60,
      ellipsis: {
        showTitle: false,
      },
    },

  ];


  const rowsWithSerialNumber = filteredOrders?.length > 0 && filteredOrders?.map((row, index) => ({
    sno: (page - 1) * pageSize + index + 1,
    //render: (value, record) => value ? formatDateTime(value) : GetDueDateTemp(record),
    DueDate: row?.dueDate ? formatDateTime(row?.dueDate) : GetDueDateTemp(row),
    Date: formatDateTime(row?.date),
    ...row,
  }));
  console.log("rowsWithSerialNumber", rowsWithSerialNumber)

  const generateDataForGrid = (columns, orders) => {

    // const final_columns = columns.map(obj => {
    //   const { name, ...rest } = obj;
    //   return {
    //     field: name, flex: 1,
    //     cellClassName: 'multiline-cell', ...rest
    //   };
    // });
    const final_columns = columns
      // .filter((column) => !hiddenFields.includes(column.name))
      .map(obj => {
        const { name, ...rest } = obj;
        return {
          field: name, flex: 1,
          cellClassName: 'multiline-cell', ...rest
        };
      });
    return { columns: final_columns, rows: orders }
  }

  const handleRowClick = (record) => {
    // Handle the row click event
    handleDetailPage(record.id)
    console.log('Clicked row:', record.id);
  };

  const statusHandler = (value) => {
    setFilter({ filter: value });
    setFilteredStatus(value);
    setPage(1);
    // if (value === "All") {
    //   setIsAllOrders(true); 
    //   handleSearch(); 
    // } else {
    //   setIsAllOrders(false); 
    //   setPage(1); 
    // }
  };
  const simulatePrintDialog = (dataToExport, selectedColumns) => {

    const printableContent = document.createElement("div");
    printableContent.style.width = "100%";
    printableContent.style.margin = "0 auto";
    printableContent.style.padding = "5px";
    printableContent.style.fontFamily = "Arial, sans-serif";
    printableContent.style.fontSize = "5px";


    const table = document.createElement("table");
    table.style.width = "100%";
    table.style.border = "1px solid #000";
    table.style.borderCollapse = "collapse";


    const thead = document.createElement("thead");
    const headerRow = document.createElement("tr");

    selectedColumns.forEach(col => {
      const th = document.createElement("th");
      th.style.padding = "2px";
      th.style.textAlign = "left";
      th.style.border = "1px solid #ddd";
      th.innerText = col.toUpperCase();
      headerRow.appendChild(th);
    });
    thead.appendChild(headerRow);
    table.appendChild(thead);


    const tbody = document.createElement("tbody");
    dataToExport.forEach(row => {
      const rowElement = document.createElement("tr");
      selectedColumns.forEach(col => {
        const td = document.createElement("td");
        td.style.padding = "2px";
        td.style.textAlign = "left";
        td.style.border = "1px solid #ddd";
        td.innerText = row[col];
        rowElement.appendChild(td);
      });
      tbody.appendChild(rowElement);
    });

    table.appendChild(tbody);
    printableContent.appendChild(table);


    const printStyle = document.createElement("style");
    printStyle.innerHTML = `
          body * {
            visibility: hidden;
          }
          #print-content, #print-content * {
            visibility: visible;
          }
          #print-content {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
          }
          table {
            margin-top: 5px;
            width: 100%;
            border: 1px solid black;
            border-collapse: collapse;
          }
          th, td {
            padding: 2px;
            text-align: left;
            border: 1px solid #ddd;
          }
        `;
    document.head.appendChild(printStyle);

    // Append the printable content to the body
    printableContent.id = "print-content"; // Add a unique ID for styling
    document.body.appendChild(printableContent);

    // Trigger print dialog
    window.print();

    // Remove the content from the DOM after printing
    document.body.removeChild(printableContent);
    document.head.removeChild(printStyle);
  };



  const handleExport = (fileFormat) => {
    const selectedColumns = [
      "sno",
      "requestId",
      "Date",
      "processType",
      "insuredName",
      "subject",
      "priority",
      "requestStatus",
      "assignedTo",
      "requestor",
      "DueDate",
      "noofQuotes"
    ];

    let dataToExport;


    dataToExport = rowsWithSerialNumber;
    console.log("rowswithserial", rowsWithSerialNumber)
    console.log("data exporting--->", dataToExport)

    if (dataToExport.length > 0) {
      if (fileFormat === "CSV") {
        // Format date fields before converting to CSV
        // const formattedData = dataToExport.map(row => {
        //   return {
        //     ...row,
        //     date: formatDateTime(row.date),    // Format the 'date' field
        //     dueDate: formatDateTime(row.dueDate)  // Format the 'dueDate' field
        //   };
        // });

        const csvData = convertToCSV(dataToExport, selectedColumns);
        console.log("csv", csvData)
        downloadCSV(csvData, `Report.csv`);
      } else if (fileFormat === "PDF") {
        // const formattedData = dataToExport.map(row => {
        //   return {
        //     ...row,
        //     date: formatDateTime(row.date),
        //     dueDate: formatDateTime(row.dueDate)
        //   };
        // });
        simulatePrintDialog(dataToExport, selectedColumns);
      }
      setDropdownOpen(false);
    } else {
      alert('No data available for export.');
    }
    // if (dataToExport.length > 0) {
    //     if (dataToExport.length > 0) {
    //         if (fileFormat === "CSV") {

    //             const csvData = convertToCSV(dataToExport, selectedColumns);
    //             downloadCSV(csvData, `Report.csv`);
    //         } else if (fileFormat === "PDF") {

    //             simulatePrintDialog(dataToExport, selectedColumns);
    //         }
    //         setDropdownOpen(false);
    //     } else {
    //         alert('No data available for export.');
    //     }
    // };

  }

  const convertToCSV = (data, selectedColumns) => {
    // Function to format date and time as 'DD/MM/YYYY, HH:MM:SS AM/PM'
    const formatDateTime = (date) => {
      if (date instanceof Date && !isNaN(date)) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();

        // Time formatting
        let hours = date.getHours();
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;

        // Format the date and time as 'DD/MM/YYYY, HH:MM:SS AM/PM'
        return `${day}/${month}/${year}, ${hours}:${minutes}:${seconds} ${ampm}`;
      }
      return date;
    };

    // Extract the headers from the selectedColumns array
    const headers = selectedColumns.join(',');

    // Convert each row into a CSV format
    const rows = data.map(row => {
      return selectedColumns.map(column => {
        // Format the field if it's a date
        let value = row[column] || '';
        if (value instanceof Date) {
          value = formatDateTime(value);
        }
        // Add quotes around each field to handle commas in the data
        return `"${value}"`;
      }).join(',');
    });

    // Join headers and rows to create the CSV content
    return [headers, ...rows].join('\n');
  };

  const downloadCSV = (csvData, filename) => {
    const blob = new Blob([csvData], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const clearFilters = () => {
    setRequestId("");
    setClientName("");
    setRequestStatus("");
    setDate("");
    setDueDate("");
    setFilteredPriority("");
    setFilteredStatus("Inbox");
    setFilter({ filter: "Inbox" });
    setPage(1);
    setIsAllOrders(false);
    setFilteredOrders(orders);
  };

  const handleSearch = () => {
    let filteredData = [...orders];
    console.log("filteredData",filteredData)
    if (requestId || clientName) {
      //setFilteredStatus("All");
      //setFilter({ filter: "" });
    }
    const applyFilters = () => {
      if (radioFilter === "requestId" && requestId) {
        filteredData = filteredData.filter(order =>
          order?.requestId?.includes(requestId)
        );
      }
      if (clientName) {
        filteredData = filteredData.filter(order =>
          order?.clientName?.toLowerCase().includes(clientName.toLowerCase().trim())
        );
      }

      if (filteredStatus && filteredStatus !== "All") {
        filteredData = filteredData.filter(order =>
          order?.radioInfo === filteredStatus
        );
      }
      if (requestStatus) {
        filteredData = filteredData.filter(order =>
          order?.requestStatus?.toLowerCase().includes(requestStatus.toLowerCase().trim())
        );
      }
      if (date) {
        const selectedDate = dayjs(date);
        filteredData = filteredData.filter(order =>
          dayjs(parseInt(order?.date)).startOf('day').isSame(selectedDate.startOf('day'), 'day')
        );
      }
      if (dueDate) {
        const selectedDueDate = dayjs(dueDate);
        filteredData = filteredData.filter(order =>
          dayjs(parseInt(order?.dueDate)).startOf('day').isSame(selectedDueDate.startOf('day'), 'day')
        );
      }
      if (filteredPriority) {
        filteredData = filteredData.filter(order =>
          order?.priority === filteredPriority
        );
      }

      setFilteredOrders(filteredData);
    };

    applyFilters();
  };



  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        {(id != undefined) ? <RequestDetails handleDetailPage={handleDetailPage} RequestInfo={selectedOrder} /> :
          (!loading && (
            <>
              {open && <AddRequest isDisabled={isDisabled} open={open} handleClose={handleClose} requestSubmit={requestSubmit} popupStatus={popupStatus} submitStatus={submitStatus} setSetPopupStatus={setSetPopupStatus} handleDialogClose={handleDialogClose} />}
              {/* {loading ? (
                <div className="loader-wrapper" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <Spin /> 
                </div>
              ) : null} */}
              {!open &&
                <>
               
                  <div className="frame-container">
                    {/* <div > */}
                      {/* {prevVisibility && ( */}
                        <div className={`left-frame ${prevVisibility ? "show" : "hide"}`}>
                          <Button
                            icon={<CloseOutlined />}
                            onClick={closeCard}
                            shape="circle"
                            className="card-close-btn"
                          />
                          {/* <div className="filter-title">
                            Request Filter Options
                          </div> */}
                          <div style={{ margin: "9px"}}>
                            <Row gutter={[{ xs: 8, sm: 16, md: 24, lg: 32 }]} style={{marginRight:"0px", marginLeft:"0px"}}>
                              {/* <Col span={24}>
                                <Row gutter={[8, 16]} justify="start" style={{ rowGap: "1px", height: '60vh', overflow: 'auto',marginLeft: "-4px",marginRight: "-4px" }}> */}
                                  <Col span={24}>
                                    <Form.Item label="RequestId" className="form-item-label">
                                      <Input
                                        className="form-input"
                                        type="text"
                                        name="requestId"
                                        value={requestId}
                                        placeholder="Select RequestId"
                                        onChange={(e) => setRequestId(e.target.value)}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col span={24} style={{marginTop:"-24px"}}>
                                    <Form.Item label="Client Name" labelCol={{ span: 24 }} className="form-item-label">
                                      <AutoComplete
                                        name="clientName"
                                        value={clientName}
                                        onChange={handleClientNameChange}
                                        onSelect={handleSelectClient}
                                        placeholder="Select Client's Name"
                                        style={{marginTop:"-12px"}}
                                        //className="form-input"
                                        filterOption={(inputValue, option) =>
                                          option && option.value && option.value.toLowerCase().includes(inputValue.toLowerCase())
                                        }
                                      >
                                        {clients
                                          .filter((item) => item.status === "Active")
                                          .map((item, key) => (
                                            <AutoComplete.Option key={key} value={item.clientName} className="auto-complete-option">
                                              {item.clientName}
                                            </AutoComplete.Option>
                                          ))}
                                      </AutoComplete>
                                    </Form.Item>
                                  </Col>

                                  <Col style={{marginTop:"-24px"}}>
                                    <Form.Item label="Received Date" name="receivedDate" className="form-item-label">
                                      <DatePicker
                                        className="form-datepicker"
                                        showTime={false}
                                        value={date ? dayjs(date) : null}
                                        onChange={(newDate) => setDate(newDate)}
                                        style={{width:"100%", paddingRight: "10px",}}
                                        format="DD/MM/YYYY"
                                        placeholder="DD/MM/YYYY"
                                        allowClear={true}
                                        onKeyDown={handleKeyDown}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col style={{marginTop:"-24px"}}>
                                    <Form.Item label="Due Date" name="dueDate" className="form-item-label">
                                      <DatePicker
                                        className="form-datepicker"
                                        showTime={false}
                                        value={dueDate ? dayjs(dueDate) : null}
                                        onChange={(newDate) => setDueDate(newDate)}
                                        format="DD/MM/YYYY"
                                        style={{width:"100%", paddingRight: "10px",}}
                                        allowClear={true}
                                        placeholder="DD/MM/YYYY"
                                        onKeyDown={handleKeyDown}
                                      />
                                    </Form.Item>
                                  </Col>

                                  <Col style={{marginTop:"-24px"}} span={24}>
                                    <Form.Item label="Status" labelCol={{ span: 24 }} className="form-item-label">
                                      <Select
                                        className="form-select"
                                        size="middle"
                                        value={requestStatus || undefined}
                                        onChange={(value) => setRequestStatus(value)}
                                        dropdownStyle={{ minWidth: '160px' }}
                                        style={{border:"none"}}
                                        placeholder="Select Status"
                                      >
                                        <Select.Option value="Open">Open</Select.Option>
                                        <Select.Option value="In Progress">In Progress</Select.Option>
                                        <Select.Option value="On Hold">On Hold</Select.Option>
                                        <Select.Option value="Completed">Completed</Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>

                                  <Col style={{marginTop:"-24px"}} span={24}>
                                    <Form.Item label="Priority" labelCol={{ span: 24 }} className="form-item-label">
                                      <Select
                                        className="form-select"
                                        size="middle"
                                        value={filteredPriority || undefined}
                                        onChange={(value) => setFilteredPriority(value)}
                                        dropdownStyle={{ minWidth: '160px' }}
                                        placeholder="Select Priority"
                                        style={{border:"none"}}
                                      >
                                        <Select.Option value="Rush">Rush</Select.Option>
                                        <Select.Option value="On Demand">On Demand</Select.Option>
                                        <Select.Option value="Bulk">Bulk</Select.Option>
                                      </Select>
                                    </Form.Item>
                                  </Col>
                                {/* </Row> */}
                                {/* <Row> */}
                                  <div className="form-row" style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                                    <Row gutter={16} className="form-row-center">
                                      <Col span={8} className="form-col">
                                        <Button
                                          onClick={clearFilters}
                                          className="button-action"
                                        >
                                          Clear
                                        </Button>
                                        <Button
                                          color="primary"
                                          variant="solid"
                                          htmlType="submit"
                                          onClick={handleSearch}
                                          className="button-primary"
                                        >
                                          Search
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                {/* </Row> */}
                              {/* </Col> */}
                            </Row>
                          </div>
                        </div>
                      {/* )} */}

                    {/* </div> */}

                    {/* </Spin> */}
                    <div className="right-frame">
                      <div style={{width:prevVisibility?'81%':'100%',marginLeft:prevVisibility&&'auto'}}>
                        <div>
                          <Select
                            size="small"
                            value={filteredStatus}
                            onChange={statusHandler}
                            style={{
                              width: '140px',
                              height: "39px",
                              marginBottom: "7px",
                              color: "white",
                              borderRadius: "5px",
                              backgroundColor: '#3275a8',
                              border: 'none',
                              boxShadow: 'none'
                            }}
                          >
                            <Select.Option value="Inbox">Inbox</Select.Option>
                            <Select.Option value="Email Needed">Email Needed</Select.Option>
                            <Select.Option value="Discard">Discard</Select.Option>
                            <Select.Option value="Pending">Pending</Select.Option>
                            <Select.Option value="Completed">Completed</Select.Option>
                            <Select.Option value="Instructions">Instructions</Select.Option>
                            <Select.Option value="">All</Select.Option>
                          </Select>
                        </div>
                        <div className="d-flex gap-2 justify-content-end" style={{ marginRight: '1px', marginTop: '-45px', marginBottom: "10px" }}>
                          {/* <Form.Item> */}
                          <Button color='primary' variant='solid' htmlType="submit"
                            // type="primary"
                            style={{ color: 'white' }}
                            icon={<FilterOutlined />}
                            onClick={handleFilters}
                          >
                            Filter
                          </Button>
                          {/* </Form.Item> */}
                          <Button color='primary' variant='solid' htmlType="submit" style={{ color: "white" }} onClick={handleOpen}>+Add Request</Button>
                          <div className="d-flex align-items-center">
                            <div className="btn-group">
                              <Button color='primary' variant='solid' htmlType="submit"
                                // type="primary"
                                style={{ roundColor: "#007bff", color: "white", marginBottom: "1.75", paddingRight: '1.5rem', borderRadius: '5px' }}
                                id="exportButton"
                                data-bs-toggle="dropdown"
                                aria-expanded={dropDownOpen}
                                onClick={() => setDropdownOpen(!dropDownOpen)}
                              //  / Smaller button size and no dropdown icon
                              >
                                <FaCloudDownloadAlt /> Export
                              </Button>
                              <ul className={`dropdown-menu ${dropDownOpen ? 'show' : ''}`} aria-labelledby="exportButton">
                                <li>
                                  <button className="dropdown-item" onClick={() => handleExport("CSV")}>
                                    Download as CSV
                                  </button>
                                </li>
                                <li>
                                  <button className="dropdown-item" href="#" onClick={() => handleExport("PDF")}>
                                    PDF
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        {/* Data Table */}
                        <div className="row">
                          <div className="col-md-12">
                            {/* {loading ? (
                <div className="loader-wrapper" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                  <Spin /> 
                </div>
              ) : null} */}
                            {!loading && (
                              <div className="table-responsive">
                                <Table
                                  rowKey={(record) => record.id}
                                  style={{ width: "100%", tableLayout: "auto" }}
                                  columns={columns}
                                  //dataSource={tableData.length > 0 ? tableData : []}
                                  dataSource={rowsWithSerialNumber || []}
                                  pagination={{
                                    //page: page,
                                    current: page,
                                    pageSize: pageSize,
                                    total: data?.getAllOrders?.totalRecords || 0,
                                    //onChange: handleTableChange,
                                    onChange: (page, pageSize) => handleTableChange({ current: page, pageSize }),
                                    showQuickJumper: true,
                                    showSizeChanger: false,
                                    count: totalCount,
                                    showTotal: (count, range) => `${range[0]}-${range[1]} of ${count}`,
                                  }}
                                  loading={false}
                                  scroll={{ x: false }}
                                  size="small"
                                  onRow={(record) => ({
                                    onClick: () => handleRowClick(record) // Handle row click
                                  })}
                                />
                              </div>
                            )}

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </>
             }
            </>
          )
          )

          // :
          // <div style={{ 
          //   position: "fixed", 
          //   top: "50%",              
          //   left: "50%",             
          //   transform: "translate(-50%, -50%)", 
          //   display: "flex",        
          //   justifyContent: "center", 
          //   alignItems: "center",    
          //   zIndex: 9999        
          //   }}>
          //    <Spin size="large" />
          // </div>

        }
      </div>

    </div>
  );
};

export default Requests;
