import React from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem } from "./ProfileContent";
import AttachemntsHistory from "./attachementHistory";
import AddNoteBox from "./addNote";
import { useSelector } from "react-redux";
const ProfileTab = () => {
  // Personal Information Data
  const employeeInfo=useSelector((state) => state.employee.personalInfo);
  const Bank=useSelector((state)=>state.employee.BankInfos)
  console.log('employeeedocuments', employeeInfo)
  const personalInfoData = [
    { id: 1, title: "Aadhar Number", text: employeeInfo?.adharNumber },
    { id: 2, title: "PF Number", text: employeeInfo?.PfNo },
    { id: 3, title: "UAN Number", text: employeeInfo?.UAN },
    { id: 4, title: "ESIC Number", text: employeeInfo?.EsiNo },
    { id: 5, title: "Health Insurance Number", text: employeeInfo?.HealthInsuranceNo },
    // { id: 6, title: "Marital Status", text: employeeInfo?.maritalStatus },
  ];

  // Bank Information Data
  const bankInfoData = [
    { id: 1, title: "Bank Name", text: Bank?.BankName },
    { id: 2, title: "Branch", text: Bank?.Branch },
    { id: 3, title: "Bank Account No.", text: Bank?.BankAccountNo },
    { id: 4, title: "IFSC Code", text: Bank?.IFCcode },
    { id: 5, title: "PAN No", text: Bank?.PanNumber },
  ];

  // Attachments and Experience Data
  
  return (
    <>
      <div className="tab-content">
        <div id="emp_profile" className="pro-overview tab-pane fade show active">
          <div className="row">
            {/* Personal Information Section */}
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    ID Proofs
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#personal_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {personalInfoData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>

            {/* Bank Information Section */}
            <div className="col-md-6 d-flex">
              <div className="card profile-box flex-fill">
                <div className="card-body">
                  <h3 className="card-title">
                    Bank Information
                    <Link
                      to="#"
                      className="edit-icon"
                      data-bs-toggle="modal"
                      data-bs-target="#bank_info_modal"
                    >
                      <i className="fa fa-pencil" />
                    </Link>
                  </h3>
                  <ul className="personal-info">
                    {bankInfoData.map((item, index) => (
                      <ListItem
                        id={item.id}
                        key={index}
                        title={item.title}
                        text={item.text}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>

          {/* Attachments History Section */}
          <AttachemntsHistory  />

          {/* Model Popup for Personal Information */}
        </div>
        <AddNoteBox name={"documentNote"}/>
        <PersonalInformationModelPopup  name={"documentNote"} />
        
      </div>
    </>
  );
};

export default ProfileTab;
