import { useState } from 'react';
import { Button, Row, Col, Table, Collapse , Card} from 'antd';
import { useQuery } from '@apollo/client';
import CreateInsuranceCarrier from './CreateInsuranceCarrier';
import { GET_ALL_INSURANCE_CARRIERS } from '../../../Queries/Users';

const { Panel } = Collapse;

const InsuranceCarrier = () => {
    const [open, setOpen] = useState(false); // State for modal visibility
    const [rowInfo, setRowInfo] = useState({});
    const [dropdownOpen, setDropdownOpen] = useState(true);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { data, refetch, loading, error } = useQuery(GET_ALL_INSURANCE_CARRIERS, {
        variables: { page: page, pageSize: pageSize },
        fetchPolicy: "cache-and-network"
    });

    const datacontent = data ;
   

   
    const handleOpen = () => {
        setRowInfo({});
        setOpen(true);
    };

   
    const handleEdit = (row) => {
        setRowInfo(row);
        setOpen(true);
    };

    
    const handleClose = (shouldRefetch = false) => {
        setOpen(false);
        if(shouldRefetch){
            refetch();
        }
    };

    const handleTableChange = (pagination) => {
        setPage(pagination);
       
    };

    

   
    const columns = [
        {
            title: "Name",
            dataIndex: "carrierName",
            key: "carrierName",
            width:"33%",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            width:"33%",
        },
        {
            title: "Action",
            key: "action",
            width:"33%",
            render: (text, row) => (
                <Button type="link" onClick={() => handleEdit(row)}>
                    Edit
                </Button>
            )
        }
    ];

    let insuranceCarriers = data?.getAllInsuranceCarriers?.response || [];

    return (
        <div>

            <div>
            {/* Card wrapper for the content */}
            <Card bordered={true} className='card-responsive'>
                
                {/* Title and Create Button in the same row */}
                <Row className="m-2" align="middle" justify="space-between">
                    <Col xs={20} sm={20} md={20} lg={20}>
                        <h5 
                            className='p-2' align="left" justify="space-between" 
                            style={{ cursor: 'pointer' }} 
                            
                        >
                            {/* {dropdownOpen ? '▼' : '►'}  */}
                            Insurance Carrier
                        </h5>
                    </Col>
                    <Col xs={4} sm={4} md={4} lg={4} style={{ textAlign: 'right' }}>
                        <Button type="primary" onClick={handleOpen}>
                            ADD
                        </Button>
                    </Col>
                </Row>

                {/* Conditionally render the table based on dropdown state */}
                {dropdownOpen && (
                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive">
                                <Table
                                    columns={columns}
                                    dataSource={insuranceCarriers}
                                    pagination={{
                                        page: page,
                                        pageSize: pageSize,
                                        total: datacontent?.totalRecords || 0,
                                        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
                                        onChange: handleTableChange,
                                        showQuickJumper: false,
                                        showSizeChanger: false
                                    }}
                                    loading={loading}
                                    rowKey="id"
                                    // Remove alternating row colors and make it a single background color
                                    rowClassName={() => "single-row-color"}
                                    style={{ backgroundColor: '#f9f9f9', marginTop: '20px' }}
                                />
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal for creating/updating a carrier */}
                {open && (
                    <CreateInsuranceCarrier
                        open={open}
                        handleClose={handleClose}
                        data={rowInfo}
                    />
                )}
            </Card>

            {/* Custom styling for single row color */}
            <style jsx>{`
                .single-row-color {
                    background-color: #ffffff !important;
                }
                     .responsive-card {
                    width: 100%; /* Full width */
                    max-width: 800px; /* Max width of the card */
                }
                @media (max-width: 768px) {
                    .responsive-card {
                        max-width: 95%; /* Adjust max width for smaller screens */
                    }
                }
            `}</style>
        </div>
        </div>
    );
};

export default InsuranceCarrier;
