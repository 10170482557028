import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { gql } from "@apollo/client";
import dayjs from "dayjs";
import { client } from "../index"; // Assuming you have your Apollo Client instance here

// Define the GraphQL query for Employee Report
const GET_EMPLOYEE_REPORT = gql`
  query GetEmployeeReport($filters: EmployeeReportInput) {
    EmployeeReport(filters: $filters) {
      _id
      emailId
      requestId
      clientName
      processType
      taskType
      email
      subject
      body
      startTime
      endTime
      createdAt
      updatedAt
    }
  }
`;

// Define the GraphQL query for Admin Report
const GET_EMPLOYEE_REPORT_FOR_ADMIN = gql`
  query GetEmployeeReportForAdmin($filters: EmployeeReportInput) {
    EmployeeReportForAdmin(filters: $filters) {
      _id
      userId
      timezone
      productivetype
      emailId
      requestId
      clientName
      processType
      taskType
      email
      subject
      body
      startTime
      endTime
      createdAt
      updatedAt
      __typename
      emailDetails {
      startDate
      priority
        assignTo
        assignedTo
        requestId
        subject
        body
        noofQuotes
        processType
        completedDate
        priority
        dueDate
        date
        quoteType
        department
        insuredName
      }
      employeeDetails {
        EmpID
        firstname
        lastname
        email
        reportingTo
      }
      leadDetails {
       agencyName
        createdBy
        assignedTo
        priority
        status
        stage
      }
      overrideHistory {
        overriddenBy
        previousstartTime
        previousendTime
        previousTask
      }
    }
  }
`;

const GET_OVERRIDEDATA = gql`
  query WorkHistoryWithOverrideHistory {
    WorkHistoryWithOverrideHistory {
      _id
      userId
      emailId
      requestId
      clientName
      processType
      taskType
      email
      subject
      body
      startTime
      endTime
      createdAt
      updatedAt
      __typename
      emailDetails {
        requestId
        subject
        body
        noofQuotes
        processType
        completedDate
        priority
        dueDate
        date
        quoteType
        department
        insuredName
      }
      employeeDetails {
        EmpID
        firstname
        lastname
        email
        reportingTo
      }
      overrideHistory {
        overriddenBy
        previousstartTime
        previousendTime
        previousTask
      }
    }
  }
`;

const GET_EMAILS_BY_REQUEST_IDS = gql`
  query GetEmailsByRequestIds($requestIds: [String!]!) {
    getEmailsByRequestIds(requestIds: $requestIds) {
      id
      date
      requestId
      from
      to
      subject
      body
      html
      TotalTime
      dueDate
      createdBy
      emailNeeded
      emailCompleted
      fromPending
      instructions
      hold
      taskComplete
      status
      message
      quoteType
      department
      insuredName
      noofQuotes
      Assign
      holderName
      insuredDelivery
      holderDelivery
      accountManagerDelivery
      otherDelivery
      radioValue
      Auto
      MotorCycle
      Boat
      DP3
      HO3
      HO4
      HO5
      HO6
      comments
      priority
      processType
      assignedTo
      assignTo
      startDate
      dueDate
      completedDate
      requestStatus
      radioInfo
      emailSummary
      requestor
      sendTo
      client
      clientName
    }
  }
`;
const UPDATE_WORK_HISTORY_BY_ADMIN = gql`
  mutation UpdateWorkHistoryByAdmin(
    $id: ID!
    $startTime: String
    $endTime: String
    $message: String
    $taskType: String
    $requestId: String
    $updatedBy: String!
  ) {
    updateWorkHistoryByAdmin(
      id: $id
      startTime: $startTime
      endTime: $endTime
      message: $message
      taskType: $taskType
      requestId: $requestId
      updatedBy:$updatedBy
    ) {
      _id
      startTime
      endTime
      message
      taskType
      requestId
      overrideHistory {
        overriddenBy
        previousstartTime
        previousendTime
        previousTask
      }
    }
  }
`;
const UPDATE_MULTIPLE_WORK_HISTORIES = gql`
  mutation updateMultipleWorkHistories($updates: [WorkHistoryUpdateInput]!) {
    updateMultipleWorkHistories(updates: $updates) {
      id
      startTime
      endTime
      taskType
      requestId
      overrideHistory {
        overriddenBy
        previousstartTime
        previousendTime
        previousTask
      }
    }
  }
`;

const formatDate = (timestamp) => {
  if (timestamp) {
    return new Date(Number(timestamp)).toLocaleString();
  }
  return null;
};
const GetDueDateTemp = (priority, date) => {
  try {
    let dateValue;

    // Convert the string timestamp to a number and then to a Date object
    const timestamp = parseInt(date); // date is in string format, so parse it to integer
    const dateObject = new Date(timestamp); // Convert timestamp to Date object

    // Assuming `priority` is one of 'Rush', 'On Demand', or 'Bulk'
    if (priority === 'Rush') {
      // Add 2 hours (2 * 60 * 60 * 1000 ms)
      dateValue = new Date(dateObject.getTime() + 2 * 60 * 60 * 1000);
    } else if (priority === 'On Demand') {
      // Add 24 hours
      dateValue = new Date(dateObject.getTime() + 24 * 60 * 60 * 1000);
    } else if (priority === 'Bulk') {
      // Add 48 hours
      dateValue = new Date(dateObject.getTime() + 48 * 60 * 60 * 1000);
    } else {
      throw new Error('Invalid priority');
    }

    // Use the formatDate function to format the calculated due date
    const formattedDate = formatDate(dateValue.getTime());
    console.log('GetDueDateTemp result', formattedDate);
    return formattedDate; // returns the formatted date string
  } catch (error) {
    console.log("RequestInformation", "GetDueDateTemp for date and time", error.message);
    return null;  // Return null if there's an error
  }
};


const calculateDuration = (start, end) => {
  if (start && end) {
    console.log("start,end",start,end)
    const startDate = new Date(Number(start));
    const endDate = new Date(Number(end));
    const durationMs = endDate - startDate;

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(durationMs / (1000 * 60 * 60)); // Convert ms to hours
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60)); // Remaining minutes
    const seconds = Math.floor((durationMs % (1000 * 60)) / 1000); // Remaining seconds
    
    const time =  `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    console.log("start,end",time)
    return time
  }
  return null;
};

export const fetchOverrideData = createAsyncThunk(
  "overrideTable/fetchOverrideTableReport",
  async (filters) => {
    console.log("filters", filters);
    try {
      // Perform the GraphQL query to fetch the data
      const { data } = await client.query({
        query: GET_OVERRIDEDATA,
        variables: { filters }, // Pass filters as variables if needed
      });

      const formattedReport = data.WorkHistoryWithOverrideHistory.flatMap((report) => {
   
        const id = report._id;
        const userId = report.userId;
    
        // Check if there are any overrides and map them
        if (report.overrideHistory && report.overrideHistory.length > 0) {
            return report.overrideHistory.map((override, index) => {
                // For taskChange, startDateChange, and endDateChange, compare the last and previous override
                const previousOverride = report.overrideHistory[index - 1]; // Previous override object
    
                // Check if it's the last override in the array
                const isLastOverride = index === report.overrideHistory.length - 1;
    
                // Task change logic
                const taskChange = isLastOverride
                    ? `${override.previousTask} ==> ${report.taskType}`
                    : previousOverride
                    ? `${previousOverride.previousTask} ==> ${override.previousTask}`
                    : `${override.previousTask} ==> ${report.taskType}`;
    
                // Start date and end date change logic
                const startDateChange = isLastOverride
                    ? formatDate(override.previousstartTime) // Last entry, compare with report startTime
                    : previousOverride
                    ? formatDate(override.previousstartTime) // Compare with previous start time
                    : formatDate(report.startTime); // For the first entry, compare with report startTime
    
                const endDateChange = isLastOverride
                    ? formatDate(override.previousendTime) // Last entry, compare with report endTime
                    : previousOverride
                    ? formatDate(override.previousendTime) // Compare with previous end time
                    : report.endTime ? formatDate(report.endTime) : "N/A"; // For the first entry, compare with report endTime
    
                // Return the fields for this specific override change
                return {
                
                    id,
                    taskChange,
                    startDateChange,
                    endDateChange,
                    userId
                };
            });
        }
      
        // If no override history exists, return the current report with a single entry
        return [{
            id,
            taskChange: `${report.taskType} ==> ${report.taskType}`,  // No task change if no override history
            startDateChange: formatDate(report.startTime),
            endDateChange: report.endTime ? formatDate(report.endTime) : "N/A",
            userId
        }];
    });
    


    

    
    
  
    const reportWithSerialNo = formattedReport.map((item, index) => ({
      ...item,
      sno: index + 1 // Serial number based on the position in the array
    }));

    

      // Optionally, you can sort the report based on any field, such as startTime
      const sortedReport = reportWithSerialNo.sort(
        (a, b) => new Date(b.startingTime) - new Date(a.startingTime)
      );

      return sortedReport;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

// Define the async thunk to fetch the employee report
export const fetchEmployeeReport = createAsyncThunk(
  "employeeReport/fetchEmployeeReport",
  async (filters) => {
    console.log("filters", filters);
    try {
      const { data } = await client.query({
        query: GET_EMPLOYEE_REPORT,
        variables: { filters },
      });

      // Format the fetched employee report in a similar way to the admin report
      const formattedReport = data.EmployeeReport.map((report) => {
        const duration = calculateDuration(report.startTime, report.endTime);
        const billable =
          report.requestId === null || report.requestId === "" ? "No" : "Yes";
        const status = report.endTime ? "Completed" : "Pending";
        const requestIds = report.requestId ? report.requestId : "N/A";
        const service = report.processType || "N/A"; // Default service value
        const requestCompleted = report.requestCompleted || "N/A"; // Default value for requestCompleted

        return {
          ...report,
          service,
          requestCompleted,
          requestIds,
          startingTime: formatDate(report.startTime), // Format startTime
          endingTime: formatDate(report.endTime), // Format endTime
          billable,
          duration, // Add duration to the report
          status, // Set status as "Completed" or "Pending"
        };
      });
      const sortedReport = formattedReport.sort(
        (a, b) => new Date(b.startingTime) - new Date(a.startingTime)
      );

      return sortedReport;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

export const fetchAdminReport = createAsyncThunk(
  "employeeReport/fetchAdminReport",
  async (filters = null) => {
    try {
      const { data } = await client.query({
        query: GET_EMPLOYEE_REPORT_FOR_ADMIN,
        variables: { filters },
      });

      // Helper function to calculate the duration in hours and minutes

      console.log("data.EmployeeReportForAdmin", data.EmployeeReportForAdmin);
      const formattedReport = data.EmployeeReportForAdmin.map((report) => {
        const duration = calculateDuration(report.startTime, report.endTime);
        const billable =
          report.requestId === null || report.requestId == "" ? "No" : "Yes";
        const status = report.endTime ? "Completed" : "Pending";
        const requestIds = report.requestId ? report.requestId : "N/A";
        const service = report.processType || "N/A";
        const requestCompleted = report.requestCompleted || "N/A";
        const employeeName =
          report.employeeDetails.length > 0
            ? `${report.employeeDetails[0].firstname} ${report.employeeDetails[0].lastname}`
            : "N/A";
        const agencyName = report?.leadDetails[0]?.agencyName;
        const emailId =
          report.employeeDetails.length > 0
            ? report.employeeDetails[0].email
            : "N/A";
        const employeeDetails =
          report.employeeDetails.length > 0 ? report.employeeDetails[0] : "N/A";
        const emailDetails =
          report.emailDetails.length > 0
            ? {
                ...report.emailDetails[0],
                date: formatDate(report.emailDetails[0].date),
               dueDate : report.emailDetails[0]?.dueDate ? formatDate(report.emailDetails[0]?.dueDate) : GetDueDateTemp(report.emailDetails[0]?.priority, report.emailDetails[0]?.date),
                completedDate: formatDate(report.emailDetails[0].completedDate),
                startDate: report.emailDetails[0]?.dueDate ?formatDate(report.emailDetails[0].startDate):formatDate(report.emailDetails[0].date),
              }
            : "N/A";
        const leadDetails =
          report.leadDetails.length > 0
            ? {
                ...report.leadDetails[0],
                
              }
            : "N/A";

        const reportingTo =
          report.employeeDetails.length > 0
            ? report.employeeDetails[0].reportingTo
            : "N/A";

        console.log("requestIds", requestIds);

        return {
          ...report,
          agencyName,
          leadDetails,
          employeeDetails,
          emailDetails,
          emailId,
          reportingTo,
          employeeName,
          service,
          requestCompleted,
          requestIds,
          startingTime: formatDate(report.startTime),
          endingTime: formatDate(report.endTime),
          billable,
          duration, // Add duration to the report
          status, // Set status as "Completed" or "Pending"
        };
      });

      return formattedReport;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);
// Define the async thunk to update work history by admin
export const updateWorkHistoryByAdmin = createAsyncThunk(
  "employeeReport/updateWorkHistoryByAdmin",
  async (
    { id, startTime, endTime, message, requestId, taskType,updatedBy },
    { rejectWithValue }
  ) => {
    try {
      const { data } = await client.mutate({
        mutation: UPDATE_WORK_HISTORY_BY_ADMIN,
        variables: { id, startTime, endTime, message, requestId, taskType,updatedBy },
      });

      return data.updateWorkHistoryByAdmin;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const updateMultipleWorkHistories = createAsyncThunk(
  "employeeReport/updateMultipleWorkHistories",
  async (updates, { rejectWithValue }) => {
    try {
      console.log("updates", updates);
      const { data } = await client.mutate({
        mutation: UPDATE_MULTIPLE_WORK_HISTORIES,
        variables: { ...updates },
      });

      // Return the updated work histories from the response
      return data.updateMultipleWorkHistories;
    } catch (error) {
      // If there's an error, return it as a rejected value
      return rejectWithValue(error.message);
    }
  }
);

export const fetchEmailsByRequestIds = createAsyncThunk(
  "employeeReport/fetchEmailsByRequestIds",
  async (requestIds) => {
    try {
      const { data } = await client.query({
        query: GET_EMAILS_BY_REQUEST_IDS,
        variables: { requestIds },
      });

      return data.getEmailsByRequestIds;
    } catch (error) {
      throw new Error(error.message);
    }
  }
);

// Create the slice
const employeeReportSlice = createSlice({
  name: "employeeReport",
  initialState: {
    report: null,
    adminReport: null,
    requestIds: null,
    overRidehistory: null,
  },
  reducers: {
    addLatestReports: (state, action) => {
      const newObject = action.payload;
      console.log("newObject", newObject);
      if (state.adminReport !== null) {
        const targetUserId = newObject.userId;

        const updatedReports = state.adminReport.map((report) => {
          if (report.userId === targetUserId) {
            return {
              ...report,
            };
          }
          return report;
        });

        const lastAdminReport = updatedReports[0];
        lastAdminReport.endTime = Date.now().toString();
        let duration = calculateDuration(lastAdminReport.startTime, Date.now());
        lastAdminReport.duration = duration;
        lastAdminReport.status = "Completed";

        state.adminReport = [newObject, ...updatedReports];
      }

      if (state.report !== null) {
        const lastAdminReport = state.report[0];
        lastAdminReport.endingTime = formatDate(Date.now().toString());
        let duration = calculateDuration(lastAdminReport.startTime, Date.now());
        lastAdminReport.duration = duration;
        lastAdminReport.status = "Completed";
        state.report = [newObject, ...state.report];
      }
    },

    updateAReport: (state, action) => {
      console.log("action.payload", action.payload);
      state.adminReport = {
        ...state.adminReport,
        ...action.payload,
      };
      state.report = {
        ...state.report,
        ...action.payload.report,
      };
    },
    updateFieldInSpecificAdminReport: (state, action) => {
      console.log("action.payload", action.payload);
      const { key, field, value } = action.payload;

      const reportIndex = state.adminReport.findIndex(
        (report) => report._id === key
      );

      if (reportIndex !== -1) {
        const updatedReport = {
          ...state.adminReport[reportIndex], // Copy the existing report
          [field]: value, // Update the specific field with the new value
        };

        // Replace the old report with the updated one in the array
        state.adminReport[reportIndex] = updatedReport;

        console.log(
          `Updated ${field} in Specific Admin Report with ${key}:`,
          updatedReport
        );
      } else {
        console.log("Report with", key, "not found.");
      }
    },

    updateAdminReport: (state, action) => {
      state.adminReport = action.payload;
    },
    addOverrideHistory: (state, action) => {

      action.payload.forEach(payloadItem => {
        // Find the index of the report in state.adminReport with the matching id
        const index = state.adminReport.findIndex(report => report._id == payloadItem.id);
    
        // If the report with the matching id is found, update the history
        if (index !== -1) {
          console.log("actionindex found")
          state.adminReport[index] = {
            ...state.adminReport[index],
            overrideHistory: payloadItem.overrideHistory 
          };
        }
      });
    },
    addSingleOverrideHistory: (state, action) => {
      // Assuming the payload is provided as part of the action
      const payloadItem = action.payload;
    
      // Find the index of the report in state.adminReport with the matching id
      const index = state.adminReport.findIndex(report => report._id == payloadItem._id);
    
      // If the report with the matching id is found, update the history
      if (index !== -1) {
        console.log("actionindex found");
        state.adminReport[index] = {
          ...state.adminReport[index],
          overrideHistory: payloadItem.overrideHistory,
        };
      }
    },
    
    
    // updateAdminReportonEdit: (state, action) => {
    //   const updatedReport = action.payload; // The new report object
    //   state.adminReport = state.adminReport.map(report =>
    //     report.id === updatedReport.id ? { ...report, ...updatedReport } : report
    //   );
    // },
    filterAdminReport: (state, action) => {
      const { startDate, endDate } = action.payload;
      const filtered = state.adminReport.filter((report) => {
        const reportDate = new Date(report.startTime);
        const dateMatch =
          startDate && endDate
            ? reportDate >= new Date(startDate) &&
              reportDate <= new Date(endDate)
            : true;

        return dateMatch;
      });

      state.filteredAdminReport = filtered;
    },
    filterEmployeeReport: (state, action) => {
      const { startDate, endDate } = action.payload;
      const filtered = state.report?.filter((report) => {
        const reportDate = new Date(report.startTime);

        const dateMatch =
          startDate && endDate
            ? reportDate >= new Date(startDate) &&
              reportDate <= new Date(endDate)
            : true;

        return dateMatch;
      });

      state.filteredEmployeeReport = filtered;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmployeeReport.fulfilled, (state, action) => {
        state.report = action.payload;
        state.report.sort((a, b) => {
          const endDateA = a.startTime;
          const endDateB = b.startTime;
          return endDateB - endDateA;
        });
      })
      .addCase(fetchAdminReport.fulfilled, (state, action) => {
        // Check if the payload contains data
        if (action.payload && action.payload.length > 0) {
          // If there is data, assign it to the state and sort
          state.adminReport = action.payload;
          state.adminReport.sort((a, b) => {
            const endDateA = a.startTime;
            const endDateB = b.startTime;
            return endDateB - endDateA;
          });
        } else {
          // If no data, clear the state
          state.adminReport = [];
        }
      })

      .addCase(fetchOverrideData.fulfilled, (state, action) => {
        state.overRidehistory = action.payload;
      })
      .addCase(fetchEmailsByRequestIds.fulfilled, (state, action) => {
        const emails = action.payload;

        // Convert the adminReport into a map (dictionary) for easier lookup by requestId
        const adminReportMap = state.adminReport.reduce((acc, report) => {
          acc[report.requestId] = report;
          return acc;
        }, {});

        // Iterate through emails and match their requestId with adminReportMap keys
        emails.forEach((email) => {
          const { requestId } = email; // Extract requestId from the email

          // Check if the current email's requestId exists in the adminReportMap
          if (adminReportMap[requestId]) {
            const updatedReport = { ...adminReportMap[requestId] }; // Create a shallow copy of the report

            // Merge the email fields into the corresponding report object
            Object.assign(updatedReport, email);

            // Optional: If you want to store emails in a specific array within the report:
            if (!updatedReport.emails) {
              updatedReport.emails = [];
            }

            // Add the email to the 'emails' field in the report object (optional)
            updatedReport.emails.push(email);
            updatedReport.status = email.endTime || "Pending";
            updatedReport.clientName = email.clientName || "N/A";
            updatedReport.service = email.processType || "N/A";
            updatedReport.requestCompleted = email.status || "Pending";

            const reportIndex = state.adminReport.findIndex(
              (report) => report.requestId === requestId
            );

            // Update only the specific report in the array
            if (reportIndex !== -1) {
              state.adminReport[reportIndex] = updatedReport;
            }
          }
        });

        // Optional: Log the updated admin reports
        console.log(
          "Updated Admin Reports with Email Fields:",
          state.adminReport
        );
      })
      .addCase(updateWorkHistoryByAdmin.fulfilled, (state, action) => {
        const updatedWorkHistory = action.payload;
        let { _id, startTime, endTime, taskType } = updatedWorkHistory;
        let startingTime = formatDate(startTime);
        let endingTime = formatDate(endTime);
        // Find the specific report by _id and update it with new data
        const reportIndex = state.adminReport.findIndex(
          (report) => report._id == _id
        );
        console.log("reportIndex", reportIndex);
        if (reportIndex !== -1) {
          // Update the report with the new work history
          const updatedReport = {
            ...state.adminReport[reportIndex],
            startTime: startTime,
            endTime: endTime,
            taskType: taskType,
            duration: calculateDuration(startTime, endTime),

          };
          state.adminReport[reportIndex] = updatedReport;
          console.log("updatedReport", state.adminReport[reportIndex]);
        }
      });
  },
});

export const {
  addSingleOverrideHistory,
  updateAdminReport,
  updateAReport,
  addOverrideHistory,
  updateFieldInSpecificAdminReport,
  addLatestReports,
  filterAdminReport,
  filterEmployeeReport,
} = employeeReportSlice.actions;
// export const {selectReport} = (state) => state?.employeeReports?.report;
// export const {selectAdminReport} = (state) => state?.employeeReports?.adminReport;

// Assuming the shape of the state is as follows:
export const selectReport = (state) => state?.employeeReports?.report;

export const selectAdminReport = (state) => state?.employeeReports?.adminReport;
export const selectoverRideData = (state) =>
  state?.employeeReports?.overRidehistory;

export default employeeReportSlice.reducer;
