import { gql } from "@apollo/client";

export const GET_ALL_ERROR_LOGS = gql`
  query {
    getAllErrorLogs {
     status
      message
      response {
        id
        userId
        componentName
        functionName
        lastError
        dateTime
      }
     
    }
  }
`;



export const LOG_ERROR = gql`
 mutation ($userId: String!, $componentName: String!, $functionName: String!, $dateTime: String!, $lastError: String!) {
  createErrorLog(userId: $userId, componentName: $componentName, functionName: $functionName, dateTime: $dateTime, lastError: $lastError) {  
  status
  message,  
  id
  }
}
`;

