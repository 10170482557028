import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import Link from 'antd/es/typography/Link';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import { SINGLE_USER } from '../../../Queries/Employees';
import { setNotes } from '../../../Slices/employeeslice';
import { useDispatch, useSelector } from 'react-redux';

const AddNoteBox = (props) => {
  const dispatch = useDispatch();
  const name = props.name ;
 const [Note ,setNote]=useState();
  const { Empid } = useParams();
  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };

 
  
  const notess=useSelector((state)=>state.employee.notes) 
  useEffect(() => {
    const targetNote = notess.find((note) => note.name === name);
    setNote(targetNote?.data)
  }, [name,Note,notess]);

  
 
  return (
    <div id="note_info_card" className="card profile-box flex-fill">
      <div className="card-body">
        <div className="d-flex justify-content-between align-items-center">
          <h3 className="card-title">Notes</h3>
          <Link
            to="#"
            className="edit-icon"
            data-bs-toggle="modal"
            data-bs-target="#note_modal_popup"
          >
            <i className="fa fa-pencil" />
          </Link>
        </div>

        {/* Display Note as a Non-Editable Textarea */}
        <Form.Group controlId="noteDisplay" className="mt-3">
          <Form.Control
            as="textarea"
            rows={4}
            value={Note ? Note :  "No notes available"}
            disabled
            className="w-100"
            style={{ backgroundColor: "#f8f9fa", cursor: "not-allowed", resize: "none" }}
          />
        </Form.Group>
      </div>
      {/* Uncomment to use PersonalInformationModelPopup */}
      {/* <PersonalInformationModelPopup name={"document test"} /> */}
    </div>
  );
};

export default AddNoteBox;
