import { createSlice } from '@reduxjs/toolkit';
import { useQuery } from '@apollo/client';
import { SINGLE_USER } from '../Queries/Employees'; // Replace with the actual path to your queries
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
// Initial state for the employee slice
const initialState = {
  userData: {},
  managersData: [],
  clientHistory: [],
  employmentData: {},
  sidemenu:[],
  route:[],
  fields: [],
  personalInfo: {},
  designation: {},
  workExperience: {},
  emergencyContacts: [],
  workHistory: [],
  reportsDatas: [],
  educationData: [],
  experienceData: [],
  familyMembers: [],
  attachmentsHistory: [],
  AllEmployees:[],
  clients:[],
  MainClient:[],
  id:true,
  editHistory: [],
  profileDate:[],
  filteredLeaveData: null, 

notes: [
  { name: "documentNote", status: true, data:"" },
  { name: "teamNote", status: true, data:""},
  { name: "familyNote", status: true, data:''},
  { name: "workNote", status: true, data:"" },
  { name: "clientNote", status: true, data:"" },
  { name: "EducationNote", status: true, data:"" }
],
};

// Create the employee slice
const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    setUserDatas: (state, action) => {  
      state.userData = action.payload;
     
    },
    
    setManagersDatas: (state, action) => {
      state.managersData = action.payload;
    },
    setClientHistorys: (state, action) => {
      state.clientHistory = action.payload;
    },
    setEmploymentDatas: (state, action) => {
   
      state.employmentData = action.payload;
    },
    setPersonalInfos: (state, action) => {
      state.personalInfo = action.payload;
    },
    setDesignations: (state, action) => {
      state.designation = action.payload;
    },
    setWorkExperiences: (state, action) => {
      state.workExperience = action.payload;
    },
    setEmergencyContactes: (state, action) => {
      state.emergencyContacts = action.payload;
    },
    setBankInfos :(state ,action)=>{
      console.log("bank",action.payload);
      state.BankInfos=action.payload;
    },
    setWorkHistorys: (state, action) => {
      state.workHistory = action.payload;
    },
    setReportsDatas: (state, action) => {
      state.reportsDatas = action.payload;
    },
    setEducationDatas: (state, action) => {
      console.log("dispatch", action.payload);
      state.educationData = action.payload;
      console.log(state.educationData);
    },
    setExperienceDatas: (state, action) => {
      state.experienceData = action.payload;
    },
    setFamilyMemberses: (state, action) => {
      state.familyMembers = action.payload;
    },
    setAttachmentsHistorys: (state, action) => {
      state.attachmentsHistory = action.payload instanceof Array ? action.payload : [...state.attachmentsHistory, action.payload];
    },
    // setNotes :(state ,action)=>
    // {
    //   console.log("sffs",action.payload);
    //   state.note=action.payload
    // },
    // SetAllEmployees:(state ,action)=>{
    //  console.log("brbfbfr",action.payload);
    //   state.AllEmployees = action.payload; 
      
    // },
    SetAllEmployees: (state, action) => {
      console.log("brbfbfr", action.payload);
    
      // Sort the employees based on the 'firstname' field
      state.AllEmployees = action.payload
      .map(emp => ({
        ...emp,
        token: emp.token || "N/A" 
      }))
      .sort((a, b) => a.firstname.localeCompare(b.firstname));
    },
    updateEditHistory: (state, action) => {
      console.log("Action payload:", action.payload);
      state.editHistory = [...action.payload]; // Replace the array with the new one
  },
  
     setEditHistory:(state ,action)=>
    {
      state.editHistory=[action.payload,...state.editHistory]
      console.log("rvffsgfd",state.editHistory);
    },
    SetNewEmployees: (state, action) => {
     
      state.AllEmployees = [action.payload,...state.AllEmployees];
      console.log("bfgdd",state.AllEmployees);
     // console.log("After update:", JSON.parse(JSON.stringify(state.AllEmployees)));   
  },
  setClients: (state ,action)=>{
    console.log("client",action.payload);
    state.clients =action.payload;
  },
  setId:(state ,action)=>{
    state.id = action.payload;
  },
  setAllNotes(state, action) {
    const { name, data } = action.payload;
    console.log("bfbdfd",action.payload)
    state.notes = state.notes.map((note) => {
      if (note.name === name) {
       
        return {
          ...note,
          data: data, 
          status: false, 
        };
      }
      // If the note name does not match, return it unchanged
      return note;
    });
  },  
  updateNoteData(state, action) {
    const { name, data } = action.payload;
   
    const note = state.notes.find((item) => item.name === name);
    if (note) {
      note.data = data;
      console.log("FBFDBFD",note.data);
    }
  },
  setAllNote(state, action) {
    // Reset status to true for all notes when switching tabs
    state.notes = state.notes.map((note) => ({
      ...note,
      status: true, // Reset status to true when switching
      data: null, // Update data from backend if available
    }));
  },
  setProfileData(state,action){
   
    state.profileDate = action.payload;
    console.log("grr",action.payload);
   
  },
  setFields(state, action) {
    state.fields = action.payload;
  },
  addField(state, action) {
    state.fields.push(action.payload);
  },
  updateField(state, action) {
    const { fieldId, docId, newData } = action.payload;
    const field = state.fields.find((f) => f._id === fieldId);
    if (field) {
      const document = field.data.find((doc) => doc._id === docId);
      if (document) {
        Object.assign(document, newData);
      }
    }
  },
  deleteField(state, action) {
    const { fieldId, docId } = action.payload;
    const field = state.fields.find((f) => f._id === fieldId);
    if (field) {
      field.data = field.data.filter((doc) => doc._id !== docId);
    }
  },
  SideMenu(state, action) {
    console.log("Updating sidemenu with payload:", action.payload);
    // Update the sidemenu state
    state.sidemenu = action.payload;
    // Log the updated sidemenu
    console.log("Updated sidemenu state:", state.sidemenu);
  },
  setRoutes(state,action){
    state.route=action.payload
    console.log("evfevfdv route",state.route)
  },
  setFilteredLeaveData(state, action) {
    state.filteredLeaveData = action.payload; // Store the object in state
  },
},
});
// Exporting actions to be used in components
export const {
  setUserDatas,
  SideMenu,
  setRoutes,
  setProfileData,
  setManagersDatas,
  setClientHistorys,
  setBankInfos,
  setId,
  setFields, 
  addField, 
  updateField,
   deleteField, 
   setLoading,
  setAllNote,
  setAllNotes,
  setNoteStatus,
  setEmploymentDatas,
  setPersonalInfos,
  setDesignations,
  updateEditHistory,
  setWorkExperiences,
  setEmergencyContactes,
  setWorkHistorys,
  setMainClient,
  setReportsDatas,
  setEducationDatas,
  setExperienceDatas,
  setFamilyMemberses,
  updateNoteData,
  setAttachmentsHistorys,
  setNotes,
  updateStatus,
  SetAllEmployees,
  SetNewEmployees,
  setClients,
  setEditHistory,
  updateEmployeeToken,
  setFilteredLeaveData, 

} = employeeSlice.actions;
// Exporting the reducer to be used in the store
export default employeeSlice.reducer;

