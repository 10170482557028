import React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";

const CreateScheduledCall = ({
  leadsScheduledCalls,
  handleDeleteScheduledCall,
  handleScheduledCallsInputChange,
  handleAddNewScheduledCall,
  formData,
  handleChange,
  dataBucket,
  userNames,
}) => {
  return (
    <>

      {leadsScheduledCalls?.map((call, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">
              Scheduled Call {index + 1}
              <Link
                to="#"
                className="delete-icon  float-end"
                onClick={() => handleDeleteScheduledCall(index)}
              >
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h3>
            <div className="row">
              {/* Scheduled Call Date */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Date</label>
                <DatePicker
                  value={call.date ? dayjs(call.date) : null}
                  onChange={(date) =>
                    handleScheduledCallsInputChange(index, {
                      target: { name: "date", value: date },
                    })
                  }
                  className="form-control"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                />
              </div>

              {/* Scheduled Call Time */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Time</label>
                <TimePicker
                  id={`time-picker-${index}`}
                  value={call.time ? dayjs(call.time, "HH:mm:ss") : null}
                  onChange={(time) => {
                    if (time) {
                      const formattedTime = time.format("HH:mm:ss");
                      handleScheduledCallsInputChange(index, {
                        target: { name: "time", value: formattedTime },
                      });
                    }
                  }}
                  className="form-control"
                  placeholder="Select time"
                />
              </div>

              {/* Call Type */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Type</label>
                <select
                  name="callType"
                  className='form-control'
                  value={call.callType || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                >
                  <option value="" disabled>Select Call Type</option>
                  {dataBucket.SALES_SCHEDULED_CALL_TYPE?.map((type, key) => (
                    <option key={key} value={type}>
                      {type}
                    </option>
                  ))}
                </select>
                
              </div>

              {/* Emails */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Email(s)</label>
                <input
                  type="text"
                  name="emails"
                  className='form-control'
                  value={call.emails || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                />
                
              </div>

              {/* Phone Number */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Phone Number</label>
                <input
                  type="text"
                  name="phonenumber"
                  className='form-control'
                  value={call.phonenumber || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                />
              </div>

              {/* Call Scheduled By */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Call Scheduled By</label>
                <select
                  name="callScheduledBy"
                  className="form-control"
                  value={call.callScheduledBy || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                >
                  {userNames?.map((name, idx) => (
                    <option key={idx} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Time Zone */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Time Zone</label>
                <select
                  name="timeZone"
                  className="form-control"
                  value={call.timeZone || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                >
                  {dataBucket.SALES_TIMEZONES?.map((zone, key) => (
                    <option key={key} value={zone}>
                      {zone}
                    </option>
                  ))}
                </select>
              </div>

              {/* Remarks */}
              <div className="col-md-12 mb-3">
                <label className="col-form-label">Remarks</label>
                <textarea
                  name="remarks"
                  className="form-control"
                  value={call.remarks || ''}
                  onChange={(e) => handleScheduledCallsInputChange(index, e)}
                ></textarea>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* Add Scheduled Call Button */}
      <div className="mb-3 mt-2">
        <Link to="#" onClick={handleAddNewScheduledCall}>
          <i className="fa-solid fa-plus-circle" /> Add Scheduled Call
        </Link>
      </div>

      {/* Comments Section */}
      <div className="mb-3">
        <label className="col-form-label">Scheduled Call Info Comments</label>
        <textarea
          name="scheduledCallComments"
          className="form-control"
          value={formData.scheduledCallComments || ""}
          onChange={(e) => handleChange("scheduledCallComments", e.target.value)}
        ></textarea>
      </div>
    </>
  );
};

export default CreateScheduledCall;
