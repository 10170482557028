import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { GET_LAST_ATTENDENCE_RECORD } from "../Queries/attendence";
import { client } from "..";


export const fetchLastAttendanceRecord = createAsyncThunk(
    'attendence/fetchLastAttendanceRecord',
    async (userId, { rejectWithValue }) => {
      try {
        const { data } = await client.query({
          query: GET_LAST_ATTENDENCE_RECORD,
          variables: { id: userId },
          fetchPolicy: "network-only",
        });
  
        // Return the data for further processing in the fulfilled reducer
        return data?.LastAttendence;
      } catch (error) {
        return rejectWithValue(error.message); // Handle error case
      }
    }
  );


const initialState = {
    attendence: {},
    lastRecord: {},
    exit: false,
    AllRecords:[],
};

const attendenceSlice = createSlice({
    name: "attendence",
    initialState,
    reducers: {
        setAttendence(state, action) {
            console.log("Payload for Attendence:", action.payload);
            state.attendence = action.payload;
            console.log("payload2 ",  state.attendence)
        },
        setLastRecord(state, action) {
           
            state.lastRecord = action.payload;
            console.log("payload2 ",  state.lastRecord)
        },
        setCondition(state, action) {
            state.exit = action.payload;
        },
        setAllRecords(state,action){
            console.log("adddc",action.payload)
            state.AllRecords=action.payload
            console.log("adddcss",state.AllRecords)
        }
    },
    extraReducers: (builder) => {
        builder

          .addCase(fetchLastAttendanceRecord.fulfilled, (state, action) => {
            // Handle fulfilled case for fetchLastAttendanceRecord
            const lastAttendance = action.payload;
    
            // Update lastRecord with the fetched data
            state.lastRecord = lastAttendance;
    
            // If the status is true, update attendence
            if (lastAttendance?.response?.status) {
              state.attendence = lastAttendance;
            }
          })

      },
});

export const { setAttendence, setLastRecord, setCondition,setAllRecords } = attendenceSlice.actions;
export default attendenceSlice.reducer;
