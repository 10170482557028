// src/views/pages/Profile/CombinedInfoComponent.jsx
import React from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { ListItem } from "./ProfileContent";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
const CombinedInfoComponent = () => {
  const workExperience =useSelector((state)=>state.employee.workExperience)
const { Empid } = useParams();
  // Basic Info Data
  const basicInfoData = [
    { title: "Employment Type", text: workExperience.EmploymentType || "N/A" },
    { title: "Work Type", text: workExperience.WorkType || "N/A" },
    { title: "Experience", text: `${workExperience.Experience?.years || 0} years and ${workExperience.Experience?.months || 0} months` },
    { title: "Probation Status", text: workExperience.ProbationStatus || "N/A" },
    { title: "Probation Period", text: workExperience.probationPeriod || "N/A" },
    { title: "Shift Timings", text: workExperience.ShiftTimings || "N/A" }
  ];

  const employestatus=useSelector((state)=>state.employee.employmentData)
  console.log(employestatus);
  const employmentStatusInfo = workExperience ? [
    { title: "Employment Status", text: employestatus.employmentStatus || "N/A"  },
    { title: "Resignation Status", text: employestatus.resignationStatus || "N/A"  },
    { title: "Notice Period", text: employestatus.noticePeriod || "N/A"  },
    { 
      title: "Resignation Date", 
      text: employestatus.resignationDate 
        ? dayjs(employestatus.resignationDate).format('DD/MM/YYYY') 
        : "N/A" 
    },
    { 
      title: "Last Working Day", 
      text: employestatus.lastWorkingDay 
        ? dayjs(employestatus.lastWorkingDay).format('DD/MM/YYYY') 
        : "N/A" 
    },
    
    { title: "Rehire Eligibility", text: employestatus.RehireEligibility || "N/A"  }
  ] : [{ title: "Status", text: "No employment status found" }];

  return (
    <div className="tab-content">
      <div id="emp_profile" className="pro-overview tab-pane fade show active">
        <div className="row">
          {/* Basic Info Section */}
          <div className="col-md-6 d-flex">
            <div className="card profile-box flex-fill">
              <div className="card-body">
                <h3 className="card-title">
                  Basic Info
                 {Empid ? <Link to="#" className="edit-icon" data-bs-toggle="modal" data-bs-target="#basic_info_model">
                    <i className="fa fa-pencil" />
                  </Link> :""}
                </h3>
                <ul className="personal-info">
                  {basicInfoData.map((item, index) => (
                    <ListItem key={index} title={item.title} text={item.text} />
                  ))}
                </ul>
              </div>
            </div>
          </div>

          {/* Employment Status Section */}
          <div className="col-md-6 d-flex">
            <div className="card profile-box flex-fill">
              <div className="card-body">
                <h3 className="card-title">
                  Employment Status
                  {Empid ?<Link to="#" className="edit-icon" data-bs-toggle="modal" data-bs-target="#employment_status_modal">
                    <i className="fa fa-pencil" />
                  </Link> : null}
                </h3>
                <ul className="personal-info">
                  {employmentStatusInfo.map((item, index) => (
                    <ListItem key={index} title={item.title} text={item.text} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Include Modal Popup */}
      <PersonalInformationModelPopup  name={"workNote"} />
    </div>
  );
};

export default CombinedInfoComponent;
