import React, { useState, useEffect } from 'react';
// import DatePicker from 'react-datepicker';
import Select from 'react-select';
// import 'react-datepicker/dist/react-datepicker.css';
import { useQuery,useMutation } from '@apollo/client';
import { useParams , Link} from "react-router-dom";
import dayjs from 'dayjs';
import { TimePicker,DatePicker, message } from 'antd';

import { GET_LEAD_BY_ID, UPDATE_LEAD, CREATE_LEAD_WORKHISTORY } from '../../Queries/Leads';
import { GET_ALL_USERS } from '../../Queries/Users';
import { use } from 'i18next';
import { useDispatch, useSelector } from "react-redux";
import  * as DataBucket from '../../databucket';

import Swal from 'sweetalert2';
import { useErrorLogger } from '../../utils';

import { setLeadsInfo, setAgencyInfo, setContactsInfo, setCallsInfo,setEmailsInfo, setLinkedinInfo,
  setSchedulingCallsInfo, setOtcInfo, setComments, setContactComments
 } from '../../Slices/salesSlice';


const SalesInformationPopup = (props) => {
  const name=props?.name
  const [leadsData, setLeadsData] = useState({
        id: '',
        leadID: '',
        createdDate: null, 
        createdBy: '',
        lastUpdatedDate: null,
        lastContactedDate: null,
        followup: false,
        followupDate: null,
        callscheduledwithOPs: false,
        assign: false,
        assignedTo: '',
        assignTo: '',
        priority: '',
        status: '',
        stage: '',
        channel: '',
        successfull: false,
      });
  const [agencyData,setAgencyData]=useState({
      agencyName: '',
      address: '',
      city: '',
      state: '',
      timeZone: '',
      webURL: '',
      contactInfoURL: '',
      linkedinURL: '',
    })   

    const dispatch = useDispatch();
  const [leadsContacts, setLeadsContacts] = useState([]);
  const [leadsCalls, setLeadsCalls] = useState([]);
  const [leadsEmails,setLeadsEmails]=useState([])
  const [leadsLinkedins, setLeadsLinkedins]=useState([])
  const [leadsScheduledCalls, setLeadsScheduledCalls]=useState([])
  const [leadsOPS, setLeadsOPS]=useState([])

  const [comment, setComment] = useState();
  const [contactComment, setContactComment]=useState()

  // const [assignToUsers, setAssignToUsers] = useState([]);
  const assignToUsers=useSelector((state)=>state.loginUserInfo.allUsers)
  const { logError } = useErrorLogger();

  const { id } = useParams();

  const token = localStorage.getItem('token');

  const parseJwt = (token) => {
      try {
          return JSON.parse(atob(token.split(".")[1]));
      } catch (e) {
          return null;
      }
  };

  

const formatDateTime = (timestamp) => {
  if (!timestamp) return ''; // Handle null or undefined
  try {
    // Ensure the timestamp is parsed as a number and in milliseconds
    const parsedDate = dayjs(Number(timestamp));
    // Format to include both date and time (24-hour format)
    return parsedDate.isValid() ? parsedDate: '';
  } catch (error) {
    console.error('Date formatting error:', error);
    return '';
  }
};

const formatDate = (timestamp) => {
  if (!timestamp) return ''; // Handle null or undefined
  try {
    // Ensure the timestamp is parsed as a number and in milliseconds
    const parsedDate = dayjs(Number(timestamp));
    // Format to include both date and time (24-hour format)
    return parsedDate.isValid() ? parsedDate : null;
  } catch (error) {
    console.error('Date formatting error:', error);
    return '';
  }
};

  const [updateLead] = useMutation(UPDATE_LEAD)

  const [getLeadById, { }] = useMutation(GET_LEAD_BY_ID, {
    onCompleted(data) {
      const SalesInfo=data?.getLeadById[0]
      setLeadsData({
        id: SalesInfo?.id || '',
        leadID: SalesInfo?.leadID || '',
        createdDate: SalesInfo?.createdDate,
        createdBy: SalesInfo?.createdBy || '',
        lastUpdatedDate: SalesInfo?.lastUpdatedDate,
        lastContactedDate: SalesInfo?.lastContactedDate,
        followup: SalesInfo?.followup || false,
        followupDate: SalesInfo?.followupDate || null,
        callscheduledwithOPs: SalesInfo?.callscheduledwithOPs || false,
        assign: SalesInfo?.assign || false,
        assignedTo: SalesInfo?.assignedTo || '',
        assignTo: SalesInfo?.assignTo || '',
        priority: SalesInfo?.priority || '',
        status: SalesInfo?.status || '',
        stage: SalesInfo?.stage || '',
        channel: SalesInfo?.channel || '',
        successfull: SalesInfo?.successfull || false,
      })
      dispatch(setLeadsInfo({
        id: SalesInfo?.id || '',
        leadID: SalesInfo?.leadID || '',
        createdDate: SalesInfo?.createdDate,
        createdBy: SalesInfo?.createdBy || '',
        lastUpdatedDate: SalesInfo?.lastUpdatedDate,
        lastContactedDate:SalesInfo?.lastContactedDate,
        followup: SalesInfo?.followup || false,
        followupDate: SalesInfo?.followupDate|| null,
        callscheduledwithOPs: SalesInfo?.callscheduledwithOPs || false,
        assign: SalesInfo?.assign || false,
        assignedTo: SalesInfo?.assignedTo || '',
        assignTo: SalesInfo?.assignTo || '',
        priority: SalesInfo?.priority || '',
        status: SalesInfo?.status || '',
        stage: SalesInfo?.stage || '',
        channel: SalesInfo?.channel || '',
        successfull: SalesInfo?.successfull || false,
      }))

      setAgencyData({
        agencyName: SalesInfo?.agencyName || '',
        address: SalesInfo?.address || '',
        city: SalesInfo?.city || '',
        state: SalesInfo?.state || '',
        timeZone: SalesInfo?.timeZone || '',
        webURL: SalesInfo?.webURL || '',
        contactInfoURL: SalesInfo?.contactInfoURL || '',
        linkedinURL: SalesInfo?.linkedinURL || '',
      });
      dispatch(setAgencyInfo({
        agencyName: SalesInfo?.agencyName || '',
        address: SalesInfo?.address || '',
        city: SalesInfo?.city || '',
        state: SalesInfo?.state || '',
        timeZone: SalesInfo?.timeZone || '',
        webURL: SalesInfo?.webURL || '',
        contactInfoURL: SalesInfo?.contactInfoURL || '',
        linkedinURL: SalesInfo?.linkedinURL || '',
      }))

      if(SalesInfo?.leadsContacts){
        setLeadsContacts(SalesInfo?.leadsContacts || [])
        dispatch(setContactsInfo(SalesInfo?.leadsContacts || []))
      }
      if(SalesInfo?.leadsCalls){
        setLeadsCalls(SalesInfo?.leadsCalls || [])
        dispatch(setCallsInfo(SalesInfo?.leadsCalls || []))
      }
      if(SalesInfo?.leadsEmails){
        setLeadsEmails(SalesInfo?.leadsEmails || [])
        dispatch(setEmailsInfo(SalesInfo?.leadsEmails || []))
      }
      if(SalesInfo?.leadsLinkedins){
        setLeadsLinkedins(SalesInfo?.leadsLinkedins || [])
        dispatch(setLinkedinInfo(SalesInfo?.leadsLinkedins || []))
      }
      if(SalesInfo?.leadsScheduledCalls){
        setLeadsScheduledCalls(SalesInfo?.leadsScheduledCalls || [])
        dispatch(setSchedulingCallsInfo(SalesInfo?.leadsScheduledCalls || []))
      }
      if(SalesInfo?.leadsOPS){
        setLeadsOPS(SalesInfo?.leadsOPS || [])
        dispatch(setOtcInfo(SalesInfo?.leadsOPS || []))
      }
      setComment(SalesInfo?.[name])
      dispatch(setComments(SalesInfo?.[name]))

      setContactComment(SalesInfo?.['contactInfoComments'])
      dispatch(setContactComments(SalesInfo?.['contactInfoComments']))
    },
  });

  

  const [createLeadWorkHistory, { }] = useMutation(CREATE_LEAD_WORKHISTORY, {
    onCompleted(data) {
        console.log(data.createLeadWorkHistory?.id, 'CREATE WORK HISTORY')

    },
});

  const userid = parseJwt(token)?.id;

  // const { data, } = useQuery(GET_ALL_USERS,{
  //     variables: { loginUserId:userid },
  //   });

  // let userscontent = data ? data.getAllUsers : [];

  // useEffect(() => {
  //     if (userscontent.status == 200) {
  //         if (userscontent.response?.length > 0) {
  //             const usersList=userscontent.response
  //             setAssignToUsers(usersList)
  //           //   console.log("filtered data",usersList)

  //         }
  //     }

  // }, [userscontent]);

  const userNames = assignToUsers?.map(user => `${user.firstname} ${user.lastname}`);
  const loginUser=assignToUsers.filter(user=>user?.id===userid)[0]
  
  useEffect(() => {
    const valuesObject = {
        id: id,
    }
    getLeadById({ variables: valuesObject })
  }, []);

  const [current, setCurrent] = useState(true)
  const [close, setClose] = useState(leadsData.lastContactedDate ? true : false);

  const getBy = (userby) => {
    // console.log('callbycallby', userby, userby == undefined, userby == null, userby == '')
    let callbyuser = '';
    if (userby == '') {
        return callbyuser;
    } else if (userby == undefined) {
        let calluser = assignToUsers.filter(item => item?.id == parseJwt(token)?.id)
        // console.log('callbycallby', calluser, assignToUsers, parseJwt(token)?.id)
        if (calluser?.length > 0) {
            callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
        }
        // console.log('callbycallby', callbyuser)
        return callbyuser
    } else {
        // let calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
        let calluser = assignToUsers.filter(item => (item.firstname.toLowerCase() + " " + item.lastname.toLowerCase()) == userby?.toLowerCase())
        if (calluser?.length == 0) {
            calluser = assignToUsers.filter(item => item.firstname.toLowerCase() == userby?.toLowerCase())
        }
        if (calluser?.length > 0) {
            callbyuser = calluser[0]?.firstname + " " + calluser[0]?.lastname
        }
        // console.log('callusercalluser', calluser, userby, callbyuser, parseJwt(token))

        return callbyuser
    }
}



const [leadErrors, setleadErrors] = useState({});
const [isLeadValid, setLeadValid] = useState(false); 

  const handleDateChange = (date, fieldName) => {
    console.log("dates",fieldName,date)
    setLeadsData((prevData) => ({
      ...prevData,
      [fieldName]: date,
    }));
    
    setClose(false);
    setCurrent(true);
  };
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLeadsData({ ...leadsData, [name]: value });
  };

  const handleSelectChange = (key, value) => {


    setLeadsData((prev) => ({
      ...prev,
      [key]: value,
    }));
    let newErrors = { ...leadErrors };

    switch (key) {
      case "priority":
        newErrors.priority = value.trim() ? null : "Priority is required.";
        break;
      case "status":
        newErrors.status = value.trim() ? null : "Status is required.";
        break;
      case "stage":
        newErrors.stage = value.trim() ? null : "Stage is required.";
        break;
      case "channel":
        newErrors.channel = value.trim() ? null : "Channel is required.";
        break;
      default:
        break;
    }
    
    

    setleadErrors(newErrors);

    const requiredFields=[
      "priority", 'status', "stage", "channel"
    ]

    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = leadsData[field]; // Value from the leadsData object
      const fieldError = newErrors[field]; // Check if there's an error for the field
      return fieldValue && !fieldError; // Valid if field has value and no error
    });

    setLeadValid(allFieldsValid);
  };

  
  const handleLeadsSubmit = async (e) => {
    console.log("dates",leadsData)
    e.preventDefault();
  
    // Initialize an empty errors object
    let newErrors = {};
  
    // Validate required fields
    if (!leadsData.priority) newErrors.priority = "Priority is required.";
    if (!leadsData.status) newErrors.status = "Status is required.";
    if (!leadsData.stage) newErrors.stage = "Stage is required.";
    if (!leadsData.channel) newErrors.channel = "Channel is required.";

  
    // Update state with errors
    setleadErrors(newErrors);

    // Stop submission if there are any errors
    if (Object.values(newErrors).some(error => error !== null)) {
      return; // Do not proceed further if errors are present
    }
    const noErrors = Object.keys(newErrors).length === 0;

    setLeadValid(noErrors)
  
    try {
      if(isLeadValid){
        const finalLeadsData={ lastUpdatedDate: Date.now() + '',...leadsData}
      const response = await updateLead({ variables: finalLeadsData });
      dispatch(setLeadsInfo(finalLeadsData));
  
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Leads Updated",
        leadStatus: "Updated",
        changeTime: Date.now().toString(),
      };
      await createLeadWorkHistory({ variables: historyObject });
       // Reset state after successful submission
       setLeadValid(false);
      message.info({
        content: "Updated Successfully",
        duration: 5,
        className: "custom-toast-center",
      });
  
      console.log("Update successful:", response.data.updateLead);
    }
  
    } catch (error) {
      await logError("SalesInformationPopup", "handleSubmit", error.message);
      console.error("Error occurred during lead update:", error);

      message.error({
        content: "Failed to update lead. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center",
      });
      
    }
    finally {
      // Reset state after successful submission
      setLeadValid(false);
}
  };

  const [errors, setErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);


  const handleAgencyInputChange = (e) => {
    const { name, value } = e.target;
    console.log("time zone ",value);
    
    setAgencyData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    
    let newErrors = { ...errors };

    switch (name) {
      case "agencyName":
        if (!value.trim()) {
          newErrors.agencyName = "Agency Name is required.";
        // } else if (!/^[a-zA-Z\s]+$/.test(value)) { // Allow only letters and spaces
        //   newErrors.agencyName = "Agency Name must contain only letters and spaces.";
        // } else {
          newErrors.agencyName = null; // Clear error if valid
        }
        break;
      case "address":
        newErrors.address = value.trim() ? null : "Address is required.";
        break;
        case "state":
        newErrors.state = value.trim() ? null : "state is required.";
        break;
      case "city":
        newErrors.city = value.trim() ? null : "City is required.";
        break;
      case "timeZone":
        newErrors.timeZone = value.trim() ? null : "Time Zone is required.";
        break;
      default:
        break;
    }
    
    setErrors(newErrors);
    
    const requiredFields = ["agencyName", "address","state", "city", "timeZone"];
    
    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = agencyData[field]; // Value from the agencyData object
      const fieldError = newErrors[field]; // Check if there's an error for the field
      return fieldValue && !fieldError; // Valid if field has value and no error
    });
    
    setIsFormValid(allFieldsValid);
      };
  
  // const handleAgencySelectChange = (key, value) => {
  //   setAgencyData((prev) => ({
  //     ...prev,
  //     [key]: value,
  //   }));
  //   setErrors((prevErrors) => ({
  //     ...prevErrors,
  //     [key]: !value ? `${key} is required` : '',
  //   }));
  // };
  
  const handleAgencySubmit = async (e) => {
    e.preventDefault();
  
    // Validation logic
    const { agencyName, address, city, state, timeZone } = agencyData;
    const newErrors = {};
    if (!agencyName) newErrors.agencyName = 'Agency Name is required';
    if (!address) newErrors.address = 'Address is required';
    if (!city) newErrors.city = 'City is required';
    if (!state) newErrors.state = 'State is required';
    if (!timeZone) newErrors.timeZone = 'Time Zone is required';
  
    setErrors(newErrors)

    if (Object.values(newErrors).some(error => error !== null)) {
      return; // Do not proceed further if errors are present
    }
    const noErrors = Object.keys(newErrors).length === 0;

    setIsFormValid(noErrors)
  
    console.log('Form Submitted', agencyData);
  
    try {
      if(isFormValid){
      const response = await updateLead({
        variables: {
          id: id,
          ...agencyData,
        },
      });
      dispatch(setAgencyInfo(agencyData));
  
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Agency Updated",
        leadStatus: 'Updated',
        changeTime: Date.now().toString(),
      };
      createLeadWorkHistory({ variables: historyObject });
      setIsFormValid(false);
      message.info({
        content: "Updated Successfully",
        duration: 5,
        className: "custom-toast-center",
      });
      console.log('Update successful:', response.data.updateLead);
    }
      // Set form validity to true to allow modal to close

    } catch (error) {
      await logError("SalesInformationPopup", "handleAgencySubmit", error.message);
      console.error("Error occurred during handleAgencySubmit:", error);

      message.error({
        content: "Failed to update agency data. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center",
      });
    }
    finally {
      // Reset form validity state
      setIsFormValid(false);
    }
  };
  
  

  const [contactErrors, setContactErrors] = useState({});
  const [isContactFormValid, setContactFormValid]=useState(false)

  // Handle input changes and validation for each field
  const handleContactsInputChange = (index, e) => {
    const { name, value } = e.target;
    setLeadsContacts((prev) => {
      const updatedContacts = [...prev];
      updatedContacts[index] = { ...updatedContacts[index], [name]: value };
      return updatedContacts;
    });
    validateContactField(index, name, value);
  };
  
  // Inline validation for specific contact field
  const validateContactField = (index, name, value) => {
    const newErrors = { ...contactErrors };
  
    if (name === "contactPerson") {
      if (!value.trim()) {
        newErrors[index] = {
          ...newErrors[index],
          contactPerson: "Contact person is required",
        };
      } else if (!/^[a-zA-Z\s]+$/.test(value)) { // Only letters and spaces
        newErrors[index] = {
          ...newErrors[index],
          contactPerson: "Contact person must contain only letters and spaces",
        };
      } else {
        delete newErrors[index]?.contactPerson; // Clear error if valid
      }
    } else if (name === 'email' && value.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      newErrors[index] = { ...newErrors[index], email: "Invalid email format" };
    } else if (name === 'phone' && value.trim() && !/^\d{10}$/.test(value)) {
      newErrors[index] = { ...newErrors[index], phone: "Phone must be 10 digits" };
    }else if (name === "title") {
      if (!value.trim()) {
        newErrors[index] = {
          ...newErrors[index],
          title: "Title is required",
        };
      } else if (!/^[a-zA-Z\s]+$/.test(value)) { // Only letters and spaces
        newErrors[index] = {
          ...newErrors[index],
          title: "Title must contain only letters and spaces",
        };
      } else {
        delete newErrors[index]?.title; // Clear error if valid
      }
    }else {
      if (newErrors[index]) {
        delete newErrors[index][name];
        if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
      }
    }
  
    setContactErrors(newErrors);
    const requiredFields = ['contactPerson', 'email', 'phone', 'title'];
   // Check if all required fields are valid
  const allFieldsValid = requiredFields.every((field) => {
    const fieldValue = leadsContacts[index][field] || value; // Get updated value from state or current value
    const fieldError = newErrors[index] && newErrors[index][field]; // Check if there's an error for this field
    return fieldValue && !fieldError; // Valid if there's a value and no error
  });

  // Update form validity state
  setContactFormValid(allFieldsValid);
  };
  
  // Handle adding a new contact with initial validation errors if fields are empty
  const handleAddNewContact = () => {
    const newContact = { contactPerson: '', email: '', phone: '', remarks: '', title: '' };
    setLeadsContacts((prev) => [...prev, newContact]);
  };
  
// Handle deleting a contact
const handleDeleteContact = (index) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you really want to delete this contact?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      // Delete the contact if confirmed
      setLeadsContacts((prev) => prev.filter((_, i) => i !== index));

      // Handle errors
      const newErrors = { ...contactErrors };
      delete newErrors[index];

      // Reindex errors
      const reindexedErrors = {};
      Object.keys(newErrors).forEach((key) => {
        const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
        reindexedErrors[newKey] = newErrors[key];
      });

      setContactErrors(reindexedErrors);
      setContactFormValid(reindexedErrors);
    }
  });
};

  // Handle form submission with validation for each contact
  const handleContactsSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
    leadsContacts.forEach((contact, index) => {
      if (!contact.contactPerson?.trim()) {
        newErrors[index] = { ...newErrors[index], contactPerson: "Contact person is required" };
      }
      if (!contact.email?.trim() && !contact.phone?.trim()) {
        newErrors[index] = {
          ...newErrors[index],
          email: "Either email or phone is required",
          phone: "Either email or phone is required",
        };
      }
      if (contact.email?.trim() && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(contact.email)) {
        newErrors[index] = { ...newErrors[index], email: "Invalid email format" };
      }
      if (contact.phone?.trim() && !/^\d{10}$/.test(contact.phone)) {
        newErrors[index] = { ...newErrors[index], phone: "Phone must be 10 digits" };
      }
      if (!contact.title?.trim()) {
        newErrors[index] = { ...newErrors[index], title: "Title is required" };
      }
    });
  
    setContactErrors(newErrors);
  
    const noErrors = Object.keys(newErrors).length === 0;
    if (!noErrors) {

      return; // Do not proceed with submission
    }
  
    const sanitizedLeadsContacts = leadsContacts?.map(({ __typename, ...contact }) => contact);
  
    try {
      if(isContactFormValid){
      const response = await updateLead({
        variables: {
          id: id,
          leadsContacts: sanitizedLeadsContacts,
        },
      });

      dispatch(setContactsInfo(sanitizedLeadsContacts));
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Contacts Updated",
        leadStatus: "Updated",
        changeTime: Date.now().toString(),
      };
      createLeadWorkHistory({ variables: historyObject });
      setContactFormValid(false)
      
      message.info({
        content: "Conatcts updated",
        duration: 5, // Toast will disappear after 5 seconds
        className: 'custom-toast-center', // Custom class to center the toast
      });
    } 

      // console.log("Contacts update successful:", response.data);
    } catch (error) {
      // Log the error using logError function
      await logError("SalesInformationPopup", "handleContactsSubmit", error.message);
  
      console.error("Error updating contacts:", error);
  
      // Display error message
      message.error({
        content: "Failed to update contacts. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center",
      });
    }
    finally{
      setContactFormValid(false)
    }
  };



  const [callsErrors, setCallsErrors] = useState({});
  const [isCallFormValid,setCallsFormValid]=useState()

  const handleDeleteCall = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with deletion if confirmed
        setLeadsCalls((prev) => prev.filter((_, i) => i !== index));
  
        const newErrors = { ...callsErrors };
        delete newErrors[index]; // Remove error for this index
  
        const reindexedErrors = {};
        Object.keys(newErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = newErrors[key]; // Re-index errors
        });
  
        setCallsErrors(reindexedErrors); // Update the errors state
        setCallsFormValid(reindexedErrors); // Update form validity
      }
    });
  };
  
  const handleCallsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsCalls((prev) => {
      const updatedCalls = [...prev];
      updatedCalls[index] = { ...updatedCalls[index], [name]: value };
      return updatedCalls;
    });
    validateCallField(index, name, value);
  };
  
  const validateCallField = (index, name, value) => {
    const newErrors = { ...callsErrors };
  
    if (name === 'status' && !value.trim()) {
      newErrors[index] = { ...newErrors[index], status: "Status is required" };
    } else if (name === 'spokeWith' && !value.trim()) {
      newErrors[index] = { ...newErrors[index], spokeWith: "Spoke with is required" };
    } else if (name === 'spokeWith' && /[^a-zA-Z\s]/.test(value)) {
      newErrors[index] = { ...newErrors[index], spokeWith: "Spoke with should only contain letters and spaces" };
    }
    else {
      if (newErrors[index]) {
        delete newErrors[index][name];
        if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
      }
    }
  
    setCallsErrors(newErrors);

    const requiredFields = ['status', 'spokeWith'];
  // Check if all required fields are valid
  const allFieldsValid = requiredFields.every((field) => {
    const fieldValue = leadsCalls[index][field] || value; // Get updated value from state or current value
    const fieldError = newErrors[index] && newErrors[index][field]; // Check if there's an error for this field
    return fieldValue && !fieldError; // Valid if there's a value and no error
  });

  // Update form validity state
  setCallsFormValid(allFieldsValid);
  };
  
  
  // Handle adding a new call with initial validation errors
  const handleAddNewCall = () => {
    setLeadsCalls((prev) => [
      ...prev,
      {
        date: dayjs(new Date()) || '',
        time: dayjs().set('second', 0).set('millisecond', 0) || '',
        status: "Attempt",
        spokeWith: '',
        callby: getBy(null),
        emailSent: false,
        remarks: '',
      },
    ]);
  
  };
  
  // Handle form submission with validation for each call
  const handleCallsSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
  
    leadsCalls.forEach((call, index) => {
      if (!call.status?.trim()) {
        newErrors[index] = { ...newErrors[index], status: "Status is required" };
      }
      if (!call.spokeWith?.trim()) {
        newErrors[index] = { ...newErrors[index], spokeWith: "Spoke with is required" };
      }
    });
  
    setCallsErrors(newErrors);
    const noErrors = Object.keys(newErrors).length === 0;
    if (!noErrors) {

      return; // Do not proceed with submission
    }
  
    if (Object.keys(newErrors).length > 0) return;
  
    const sanitizedLeadsCalls = leadsCalls?.map(({ __typename, ...call }) => call);
  
    try {
      if(isCallFormValid){
      const response = await updateLead({
        variables: {
          id: id,
          lastContactedDate:dayjs(new Date()) || '',
          leadsCalls: sanitizedLeadsCalls,
        },
      });
      dispatch(setCallsInfo(sanitizedLeadsCalls));
      setLeadsData({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      });
      dispatch(setLeadsInfo({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      }));
  
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Calls Updated",
        leadStatus: 'Updated',
        changeTime: Date.now().toString(),
      };
      createLeadWorkHistory({ variables: historyObject });
      setCallsFormValid(false)
      message.info({
        content: "Calls updated successfully!",
        duration: 5, // Toast will disappear after 5 seconds
        className: 'custom-toast-center', // Custom class to center the toast
      });
    }
      // console.log("Calls update successful:", response.data);
    } catch (error) {
      // Log the error for debugging
      await logError("SalesInformationPopup", "handleCallsSubmit", error.message);
  
      console.error("Error updating calls:", error);
  
      // Show error message to user
      message.error({
        content: "Failed to update calls. Please try again.",
        duration: 5, // Toast disappears after 5 seconds
        className: "alert alert-danger text-center",
      });
    }
    finally{
      setCallsFormValid(false)
    }
  }; 


  const [emailsErrors, setEmailsErrors] = useState({});
  const [isEmailFormValid, setEmailFormValid]=useState(false)

  const handleDeleteEmail = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this email?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Proceed with deletion if confirmed
        setLeadsEmails((prev) => prev.filter((_, i) => i !== index));
  
        // Remove the corresponding error entry
        const updatedErrors = { ...emailsErrors };
        delete updatedErrors[index];
  
        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });
  
        setEmailsErrors(reindexedErrors);
  
        // Check if form is valid
        setEmailFormValid(reindexedErrors);
      }
    });
  };
  
  const handleEmailsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsEmails((prev) => {
      const updatedEmails = [...prev];
      updatedEmails[index] = { ...updatedEmails[index], [name]: value };
      return updatedEmails;
    });
    validateEmailField(index, name, value);
  };
  
  // Inline validation for specific email field
  const validateEmailField = (index, name, value) => {
    const newErrors = { ...emailsErrors };
  
    if (name === 'emailedto' && !value.trim()) {
      newErrors[index] = { ...newErrors[index], emailedto: "Emailed To is required" };
    } else if (name === 'status' && !value.trim()) {
      newErrors[index] = { ...newErrors[index], status: "Status is required" };
    } else {
      if (newErrors[index]) {
        delete newErrors[index][name];
        if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
      }
    }
  
    setEmailsErrors(newErrors);

    const requiredFields = ['emailedto', 'status'];

    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = leadsEmails[index][field] || value; // Get updated value from state or current value
      const fieldError = newErrors[index] && newErrors[index][field]; // Check if there's an error for this field
      return fieldValue && !fieldError; // Valid if there's a value and no error
    });
  
    // Update form validity state
    setEmailFormValid(allFieldsValid);
  };
  
  // Handle adding a new email with initial validation errors
  const handleAddNewEmail = () => {
    setLeadsEmails((prev) => [
      ...prev,
      {
        date: dayjs(new Date()) || '',
        time: dayjs().set('second', 0).set('millisecond', 0) || '',
        status: 'Sent',
        emailedto: '',
        emailedy: getBy(null),
        remarks: ''
      },
    ]);
  
  };
  
  // Handle form submission with validation for each email
  const handleEmailsSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
  
    leadsEmails.forEach((email, index) => {
      if (!email.emailedto?.trim()) {
        newErrors[index] = { ...newErrors[index], emailedto: "Emailed To is required" };
      }
      if (!email.status?.trim()) {
        newErrors[index] = { ...newErrors[index], status: "Status is required" };
      }
    });
  
    setEmailsErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) return; // Don't submit if there are errors
  
    const sanitizedLeadsEmails = leadsEmails?.map(({ __typename, ...email }) => email);
  
    try {
      if(isEmailFormValid){
      const response = await updateLead({
        variables: {
          id: id,
          lastContactedDate:dayjs(new Date()) || '',
          leadsEmails: sanitizedLeadsEmails
        }
      });
      dispatch(setEmailsInfo(sanitizedLeadsEmails));
      setLeadsData({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      });
      dispatch(setLeadsInfo({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      }));
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Emails Updated",
        leadStatus: 'Updated',
        changeTime: Date.now().toString(),
      };
      createLeadWorkHistory({ variables: historyObject });
      setEmailFormValid(false)
      message.info({
        content: "Emails updated successfully!",
        duration: 5, // Toast will disappear after 5 seconds
        className: 'custom-toast-center', // Custom class to center the toast
      });
    }

      // console.log('Emails update successful:', response.data);
    } catch (error) {
      // Log the error for debugging
      await logError("SalesInformationPopup", "handleEmailsSubmit", error.message);
  
      console.error("Error updating emails:", error);
  
      // Show error message to user
      message.error({
        content: "Failed to update emails. Please try again.",
        duration: 5,
        className: "alert alert-danger text-center",
      });
    }
    finally{
      setEmailFormValid(false)
    }
  };

  const [linkedinsErrors, setLinkedinsErrors] = useState({});
  const [isLNFormValid,setLNFormValid]=useState(false)

  const validateLinkedinField = (index, name, value) => {
    const newErrors = { ...linkedinsErrors };
  
    // Validate `requestTo` field
    if (name === 'requestTo') {
      if (!value.trim()) {
        newErrors[index] = { ...newErrors[index], requestTo: "Request To is required" };
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {  // Allow only letters and spaces
        newErrors[index] = { ...newErrors[index], requestTo: "Request To must contain only letters" };
      } else {
        if (newErrors[index]) delete newErrors[index][name];
      }
    } 
    // Validate `status` field
    else if (name === 'status' && !value.trim()) {
      newErrors[index] = { ...newErrors[index], status: "Status is required" };
    } else {
      if (newErrors[index]) {
        delete newErrors[index][name];
        if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
      }
    }
  
    setLinkedinsErrors(newErrors);

    const requiredFields = ['requestTo', 'status'];

    const allFieldsValid = requiredFields.every((field) => {
      const fieldValue = leadsLinkedins[index]?.[field] || value; // Get updated value from state or current value
      const fieldError = newErrors[index] && newErrors[index][field]; // Check for error for this field
      return fieldValue && !fieldError; // Valid if there's a value and no error
    });
  
    // Update form validity state
    setLNFormValid(allFieldsValid);
  };
  
  const handleDeleteLinkedin = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this LinkedIn record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the record from leadsLinkedins if confirmed
        setLeadsLinkedins((prev) => prev.filter((_, i) => i !== index));
  
        // Remove the corresponding error entry
        const updatedErrors = { ...linkedinsErrors };
        delete updatedErrors[index];
  
        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });
  
        // Update the errors state
        setLinkedinsErrors(reindexedErrors);
  
        // Check if form is valid
        setLNFormValid(reindexedErrors);
      }
    });
  };
  
  

  const handleAddNewLinkedin = () => {
    setLeadsLinkedins((prev) => [
      ...prev,
      {
        date: dayjs(new Date()) || '',
        time: dayjs().set('second', 0).set('millisecond', 0) || '',
        status: 'Request Sent',
        requestTo: '',
        requestBy: getBy(null),
        remarks: '',
      },
    ]);
  
  };
  
  const handleLinkedinsInputChange = (index, event) => {
    const { name, value } = event.target;
    setLeadsLinkedins((prev) => {
      const updatedLinkedins = [...prev];
      updatedLinkedins[index] = { ...updatedLinkedins[index], [name]: value };
      return updatedLinkedins;
    });
    validateLinkedinField(index, name, value);
  };
  const handleLinkedinsSubmit = async (e) => {
    e.preventDefault();
  
    const newErrors = {};
  
    leadsLinkedins.forEach((linkedin, index) => {
      if (!linkedin.requestTo?.trim()) {
        newErrors[index] = { ...newErrors[index], requestTo: "Request To is required" };
      }
      if (!linkedin.status?.trim()) {
        newErrors[index] = { ...newErrors[index], status: "Status is required" };
      }
    });
  
    setLinkedinsErrors(newErrors);
  
    if (Object.keys(newErrors).length > 0) return; // Prevent submission if there are errors
  
    const sanitizedLeadsLinkedins = leadsLinkedins?.map(({ __typename, ...linkedin }) => linkedin);
  
    try {

      if(isLNFormValid){

      const response = await updateLead({
        variables: {
          id: id,
          lastContactedDate:dayjs(new Date()) || '',
          leadsLinkedins: sanitizedLeadsLinkedins
        }
      });
      dispatch(setLinkedinInfo(sanitizedLeadsLinkedins));
      setLeadsData({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      });
      dispatch(setLeadsInfo({
        ...leadsData,
        lastContactedDate:dayjs(new Date()) || '',
      }));
      const historyObject = {
        leadId: id,
        userName: getBy(undefined),
        action: "Linkedins Updated",
        leadStatus: 'Updated',
        changeTime: Date.now().toString(),
      };
      createLeadWorkHistory({ variables: historyObject });
      setLNFormValid(false)
      message.info({
        content: "Linkedins updated successfully!",
        duration: 5, // Toast will disappear after 5 seconds
        className: 'custom-toast-center', // Custom class to center the toast
      });
    }

      // console.log('Linkedins update successful:', response.data);
    }catch (error) {
      // Log the error for debugging
      await logError("SalesInformationPopup", "handleLinkedinsSubmit", error.message);
  
      console.error("Error updating Linkedins:", error);
  
      // Show error message to user
      message.error({
        content: "Failed to update Linkedins. Please try again.",
        duration: 5,
        className: 'alert alert-danger text-center',
      });
    }
    finally{
      setLNFormValid(false)
    }
  };

  const [scheduledCallErrors, setScheduledCallErrors] = useState({});
  const [isSCformValid,setSCformValid]=useState(false)
  
  const handleDeleteScheduledCall = (index) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this scheduled call?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
    }).then((result) => {
      if (result.isConfirmed) {
        // Remove the record from leadsScheduledCalls if confirmed
        setLeadsScheduledCalls((prev) => prev.filter((_, i) => i !== index));
  
        // Remove the corresponding error entry
        const updatedErrors = { ...scheduledCallErrors };
        delete updatedErrors[index];
  
        // Reindex errors after deletion
        const reindexedErrors = {};
        Object.keys(updatedErrors).forEach((key) => {
          const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
          reindexedErrors[newKey] = updatedErrors[key];
        });
  
        // Update the errors state
        setScheduledCallErrors(reindexedErrors);
  
        // Check if form is valid
        setSCformValid(reindexedErrors);
      }
    });
  };
  
  
// Handle input changes and validation for each scheduled call
const handleScheduledCallsInputChange = (index, e) => {
  const { name, value } = e.target;
  setLeadsScheduledCalls((prev) => {
    const updatedCalls = [...prev];
    updatedCalls[index] = { ...updatedCalls[index], [name]: value };
    return updatedCalls;
  });
  validateScheduledCallField(index, name, value);
};

// Inline validation for specific scheduled call field
const validateScheduledCallField = (index, name, value) => {
  const newErrors = { ...scheduledCallErrors };

  if (name === 'callType' && !value.trim()) {
    newErrors[index] = { ...newErrors[index], callType: "Call Type is required" };
  } else if (name === 'emails' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    newErrors[index] = { ...newErrors[index], emails: "Invalid email format" };
  } else if (name === 'phonenumber' && !/^\d{10}$/.test(value)) {
    newErrors[index] = { ...newErrors[index], phonenumber: "Phone number must be 10 digits" };
  } else {
    if (newErrors[index]) {
      delete newErrors[index][name];
      if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
    }
  }

  setScheduledCallErrors(newErrors);
  const requiredFields = ['callType', 'emails', 'phonenumber'];

  const allFieldsValid = requiredFields.every((field) => {
    const fieldValue = leadsScheduledCalls[index]?.[field] || value; // Get updated value from state or current value
    const fieldError = newErrors[index] && newErrors[index][field]; // Check for error for this field
    return fieldValue && !fieldError; // Valid if there's a value and no error
  });

  // Update form validity state
  setSCformValid(allFieldsValid);
};

// Handle adding a new scheduled call with initial validation errors if fields are empty
const handleAddNewScheduledCall = () => {
  const newScheduledCall = {date: dayjs(new Date()) || '',
    time: dayjs().set('second', 0).set('millisecond', 0) || '',
    callType: '',
    emails: '',
    phonenumber: '',
    callScheduledBy: getBy(null),
    timeZone: null,
    remarks: '',};
  setLeadsScheduledCalls((prev) => [...prev, newScheduledCall]);

};

// Handle form submission with validation for each scheduled call
const handleScheduledCallsSubmit = async (e) => {
  e.preventDefault();

  const newErrors = {};

  leadsScheduledCalls.forEach((call, index) => {
    if (!call.callType?.trim()) {
      newErrors[index] = { ...newErrors[index], callType: "Call Type is required" };
    }
    if (!call.emails?.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(call.emails)) {
      newErrors[index] = { ...newErrors[index], emails: "Invalid email format" };
    }
    
    if (!call.phonenumber?.trim() || !/^\d{10}$/.test(call.phonenumber)) {
      newErrors[index] = { ...newErrors[index], phonenumber: "Phone number must be 10 digits" };
    }
  });

  setScheduledCallErrors(newErrors);

  if (Object.keys(newErrors).length > 0) return;

  const sanitizedLeadsScheduledCalls = leadsScheduledCalls?.map(({ __typename, ...call }) => call);

  try {

    if(isSCformValid){
    const response = await updateLead({
      variables: {
        id: id,
        lastContactedDate:dayjs(new Date()) || '',
        leadsScheduledCalls: sanitizedLeadsScheduledCalls,
      },
    });
    dispatch(setSchedulingCallsInfo(sanitizedLeadsScheduledCalls));
    setLeadsData({
      ...leadsData,
      lastContactedDate:dayjs(new Date()) || '',
    });
    dispatch(setLeadsInfo({
      ...leadsData,
      lastContactedDate:dayjs(new Date()) || '',
    }));
    const historyObject = {
      leadId: id,
      userName: getBy(undefined),
      action: "Scheduled Calls Updated",
      leadStatus: "Updated",
      changeTime: Date.now().toString(),
    };
    createLeadWorkHistory({ variables: historyObject });
    setSCformValid(false);
    message.info({
      content: "Schedule Calls updated successfully!",
      duration: 5, // Toast will disappear after 5 seconds
      className: 'custom-toast-center', // Custom class to center the toast
    });
  }

    // console.log("Scheduled calls update successful:", response.data);
  } catch (error) {
    // Log the error for debugging purposes
    await logError("SalesInformationPopup", "handleScheduledCallsSubmit", error.message);

    // Log the error in the console
    console.error("Error updating scheduled calls:", error);

    // Show error message to the user
    message.error({
      content: "Failed to update scheduled calls. Please try again.",
      duration: 5,
      className: 'alert alert-danger text-center', // Error toast styling
    });
  }
  finally{
    setSCformValid(false)
  }
};

const [opsErrors, setOpsErrors] = useState({});
const [isOTCFormValid, setOTCFormValid] = useState(false);

const handleDeleteOPS = (index) => {
  Swal.fire({
    title: 'Are you sure?',
    text: 'Do you really want to delete this OPS record?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes, delete it!',
    cancelButtonText: 'No, cancel!',
  }).then((result) => {
    if (result.isConfirmed) {
      // Remove the record from leadsOPS if confirmed
      setLeadsOPS((prev) => prev.filter((_, i) => i !== index));

      // Remove the corresponding error entry
      const updatedErrors = { ...opsErrors };
      delete updatedErrors[index];

      // Reindex errors after deletion
      const reindexedErrors = {};
      Object.keys(updatedErrors).forEach((key) => {
        const newKey = parseInt(key) > index ? parseInt(key) - 1 : parseInt(key);
        reindexedErrors[newKey] = updatedErrors[key];
      });

      // Update the errors state
      setOpsErrors(reindexedErrors);

      // Check if form is valid
      setOTCFormValid(reindexedErrors);
    }
  });
};

const validateOPSField = (index, name, value) => {
  const newErrors = { ...opsErrors };

  if (name === 'callType' && !value.trim()) {
    newErrors[index] = { ...newErrors[index], callType: "Call type is required" };
  } else if (name === 'emails' && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
    newErrors[index] = { ...newErrors[index], emails: "Invalid email format" };
  } else if (name === 'phonenumber' && !/^\d{10}$/.test(value)) {
    newErrors[index] = { ...newErrors[index], phonenumber: "Phone must be 10 digits" };
  } else {
    if (newErrors[index]) {
      delete newErrors[index][name];
      if (Object.keys(newErrors[index]).length === 0) delete newErrors[index];
    }
  }

  setOpsErrors(newErrors);

  const requiredFields = ['callType', 'emails', 'phonenumber'];
  const allFieldsValid = requiredFields.every((field) => {
    const fieldValue = leadsOPS[index]?.[field] || value; // Get updated value from state or current input
    const fieldError = newErrors[index] && newErrors[index][field]; // Check for error
    return fieldValue && !fieldError; // Valid if field has value and no error
  });

  // Update overall form validity state
  setOTCFormValid(allFieldsValid);
};

const handleOPSInputChange = (index, event) => {
  const { name, value } = event.target;
  setLeadsOPS((prev) => {
    const updatedOPS = [...prev];
    updatedOPS[index] = { ...updatedOPS[index], [name]: value };
    return updatedOPS;
  });
  validateOPSField(index, name, value);
};

const handleAddNewOPS = () => {
  setLeadsOPS((prev) => [
    ...prev,
    {
      date: dayjs(new Date()) || '',
      time: dayjs().set('second', 0).set('millisecond', 0) || '',
      callType: '',
      emails: '',
      phonenumber: '',
      callScheduledBy: getBy(null),
      remarks: '',
      callConnected: false,
      clientAttendees: '',
      blueplanitAttendees: '',
      amsusedbyClient: '',
      clientRequirements: '',
      pros: '',
      cons: '',
      emailSent: false,
    }
  ]);
  
};

const handleOpsSubmit = async (e) => {
  e.preventDefault();

  const newErrors = {};

  leadsOPS.forEach((call, index) => {
    if (!call.callType?.trim()) {
      newErrors[index] = { ...newErrors[index], callType: "Call type is required" };
    }
    if (!call.emails?.trim() || !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(call.emails)) {
      newErrors[index] = { ...newErrors[index], emails: "Invalid email format" };
    }
    if (!call.phonenumber?.trim() || !/^\d{10}$/.test(call.phonenumber)) {
      newErrors[index] = { ...newErrors[index], phonenumber: "Phone must be 10 digits" };
    }
  });

  setOpsErrors(newErrors);
  if (Object.keys(newErrors).length > 0) return;

  const sanitizedLeadsOPS = leadsOPS?.map(({ __typename, ...call }) => call);

  try {

    if(isOTCFormValid){
    const response = await updateLead({
      variables: {
        id: id,
        lastContactedDate:dayjs(new Date()) || '',
        leadsOPS: sanitizedLeadsOPS
      }
    });
    dispatch(setOtcInfo(sanitizedLeadsOPS));
    setLeadsData({
      ...leadsData,
      lastContactedDate:dayjs(new Date()) || '',
    });
    dispatch(setLeadsInfo({
      ...leadsData,
      lastContactedDate:dayjs(new Date()) || '',
    }));
    const historyObject = {
      leadId: id,
      userName: getBy(undefined),
      action: "OPS Updated",
      leadStatus: 'Updated',
      changeTime: Date.now().toString(),
    };
    createLeadWorkHistory({ variables: historyObject });
    setOTCFormValid(false)
    message.info({
      content: "OTC updated successfully!",
      duration: 5, // Toast will disappear after 5 seconds
      className: 'custom-toast-center', // Custom class to center the toast
    });
    
  }

    // console.log('OPS update successful:', response.data);
  } catch (error) {
    // Log the error for debugging purposes
    await logError("SalesInformationPopup", "handleOpsSubmit", error.message);

    // Log the error in the console
    console.error("Error updating OPS:", error);

    // Show error message to the user
    message.error({
      content: "Failed to update OPS. Please try again.",
      duration: 5,
      className: 'alert alert-danger text-center', // Error toast styling
    });
  }
  finally{
    setOTCFormValid(false)
  }
};

  const handleCommentChange = (event) => {
    console.log("hshfds",event.target.value)
    setComment(event.target.value);
  }; 


  const handleCommentSubmit = async (event) => {
    try {
      console.log("nameSubmit",comment,name)
      event.preventDefault();
      updateLead({
        variables: {
          id: id,
          [name]: comment,
          
        },
      });
      dispatch(setComments(comment));
  }catch (error) {
    await logError("SalesInformationPopup",`handleCommentSubmit ${name}`, error.message);

    console.error(`Error updating comment:  ${name}`, error);

    message.error({
      content: "Failed to update comment. Please try again.",
      duration: 5,
      className: 'alert alert-danger text-center', // Error toast styling
    });
  }
  }; 

  const handleContactsCommentChange = (event) => {
    console.log("hshfds",event.target.value)
    setContactComment(event.target.value);
  };   
  
  const handleContatctsCommentSubmit =async (event) => {
    try{
    event.preventDefault();
    updateLead({
      variables: {
        id: id,
        'contactInfoComments': contactComment,
        
      },
    });
    dispatch(setContactComments(contactComment));
  }catch (error) {
    await logError("SalesInformationPopup", "handleContatctsCommentSubmit", error.message);

    console.error("Error updating contact comment:", error);
    message.error({
      content: "Failed to update contact comment. Please try again.",
      duration: 5,
      className: 'alert alert-danger text-center', // Error toast styling
    });
  }
  }; 

  const {
    leadx,
    agencyx,
    contactsx,
    callingx,
    emailx,
    linkedinx,
    schedulingx,
    otcx
  } = useSelector((state) => ({
    leadx: state.sales.leadsInfo,
    agencyx: state.sales.agencyInfo,
    contactsx: state.sales.contactsInfo || [],
    callingx: state.sales.callsInfo || [],
    emailx: state.sales.emailsInfo || [],
    linkedinx: state.sales.linkedinInfo || [],
    schedulingx: state.sales.schedulingCallsInfo || [],
    otcx:  state.sales.otcInfo || []
  }));
  
  const handleClose = (name) => {
    switch (name) {
      case 'Leads':
        setLeadsData(leadx);
        setleadErrors({});
        break;
      case 'Agency':
        setAgencyData(agencyx);
        setErrors({});
        break; 
      case 'Contacts':
        setLeadsContacts(contactsx);
        setContactErrors({});
        break;          
      case 'Calls':
        setLeadsCalls(callingx);
        setCallsErrors({});
        break;          
      case 'Emails':
        setLeadsEmails(emailx);
        setEmailsErrors({});
        break;          
      case 'Linkedins':
        setLeadsLinkedins(linkedinx);
        setLinkedinsErrors({});
        break;  
        case 'Schedules':
          setLeadsLinkedins(linkedinx);
          setLinkedinsErrors({});
          break;                  
      case 'OPS':
        setLeadsOPS(otcx);
        setOpsErrors({});                          
        break;      
      default:
        break;
    }
  };

  const assignLeads=leadx.assignedTo

  const assignHandler = (event) => {
    
    const isChecked = event.target.checked;
    setLeadsData({ ...leadsData, assign: isChecked });
  
    if (isChecked) {
      // Set 'assignedTo' and 'assignTo' fields to the logged-in user's full name when checked
      setLeadsData({
        ...leadsData,
        assign: isChecked,
        assignedTo: loginUser?.firstname + " " + loginUser?.lastname,
        assignTo: loginUser?.firstname + " " + loginUser?.lastname,
      });
    } else {
      // Reset 'assignedTo' and 'assignTo' fields when unchecked
      setLeadsData({
        ...leadsData,
        assign: isChecked,
        assignedTo: assignLeads,
        assignTo: assignLeads,
      });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      borderRadius: '4px',
      borderColor: '#ced4da',
    }),
  };

  const priorityOptions = [
    { value: 'High', label: 'High' },
    { value: 'Medium', label: 'Medium' },
    { value: 'Low', label: 'Low' },
  ];
  
  const statusOptions = [
    { value: 'New', label: 'New' },
    { value: 'In Progress', label: 'In Progress' },
    { value: 'Closed', label: 'Closed' },
  ];
  
  const stageOptions = [
    { value: 'Operation team', label: 'Operation team' },
    { value: 'Sales team', label: 'Sales team' },
  ];
  
  const channelOptions = [
    { value: 'Email', label: 'Email' },
    { value: 'Phone', label: 'Phone' },
    { value: 'In-person', label: 'In-person' },
    {value: 'Cold Calling', label:'Cold Calling'}
  ];

  const yesNoOptions = [
    { value: true, label: 'Yes' },
    { value: false, label: 'No' },
  ];

  const timeZoneOptions=['Eastern Time', 'Central Time', 'Mountain Time', 'Pacific Time', 'Hawaii Time'];

// console.log("finalleads data",leadsData)
  return (
    <>
      <div id="edit_lead" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Lead Information</h5>
              <button type="button" onClick={() => handleClose('Leads')}  className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleLeadsSubmit}>
                <div className="row">
                  {/* Last Contacted Date */}
                  <div className="col-md-6 mb-3">
                    <div className="d-flex justify-content-between align-items-center mb-2">
                      <label className="col-form-label mb-0">Last Contacted Date</label>
                      <div>
                        {close ? (
                          <button
                            className="btn btn-link p-0"
                            style={{ textDecoration: 'none', fontSize: '12px' }}
                            onClick={() => {
                              handleDateChange(dayjs(new Date()), 'lastContactedDate');
                              setClose(false);
                              setCurrent(false);
                            }}
                          >
                            Current
                          </button>
                        ) : (
                          <>
                            {current && (
                              <button
                                className="btn btn-link p-0"
                                style={{ textDecoration: 'none', fontSize: '12px' }}
                                onClick={() => {
                                  handleDateChange(dayjs(new Date()), 'lastContactedDate');
                                  setCurrent(false);
                                }}
                              >
                                Current
                              </button>
                            )}
                            <button
                              className="btn btn-link p-0 mx-2"
                              style={{ textDecoration: 'none', fontSize: '12px' }}
                              onClick={() => {
                                handleDateChange(null, 'lastContactedDate');
                                setClose(true);
                              }}
                            >
                              Clear
                            </button>
                          </>
                        )}
                      </div>
                    </div>
                    <DatePicker
                      value={leadsData.lastContactedDate ? formatDateTime(leadsData.lastContactedDate) : null}
                      onChange={(date) => { 
                        console.log("dates", date); // Logs the selected date for debugging
                        handleDateChange(date, 'lastContactedDate'); // Updates the state with the selected date
                    }}
                    // Pass native Date object
                      className="form-control"
                      format="DD/MM/YYYY hh:mm A" // Custom format
                      placeholder="Select date"
                      showTime
                    />
                  </div>

                {/* Follow Up */}
                <div className="col-md-2 mb-3">
                  <label className="col-form-label">Follow Up</label>
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="followUpSwitch"
                      style={{ width: '40px', height: '20px' }} // Increase the size of the switch
                      checked={leadsData.followup} // Set the state based on leadsData
                      onChange={(e) => {
                        const checked = e.target.checked;
                        handleSelectChange('followup', checked); // Update the state on change
                        if (!checked) {
                          handleDateChange(null, 'followupDate'); // Clear follow-up date if switch is turned off
                        }
                      }}
                    />
                  </div>
                </div>

                  {/* Follow Up Date */}
                  <div className="col-md-4 mb-3">
                    <label className="col-form-label">Follow Up Date</label>
                    <DatePicker
                      value={leadsData.followup ? formatDateTime(leadsData.followupDate) : null} // Show date only if followup is true
                      onChange={(date) => handleDateChange(date, 'followupDate')}  // Handle date change
                      className="form-control floating datetimepicker"
                      format="DD/MM/YYYY hh:mm A" // Custom date format
                      placeholder="Select date" // Placeholder text
                      disabled={!leadsData.followup} // Disable the date picker if followup is false
                      showTime={{
                        format: 'hh:mm A', // Time format
                      }}
                    />
                  </div>

                  {/* Call Scheduled with Ops */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Call Scheduled with Ops</label>
                    <Select
                      options={yesNoOptions}
                      value={yesNoOptions.find((option) => option.value === leadsData.callscheduledwithOPs)}
                      onChange={(option) => handleSelectChange('callscheduledwithOPs', option.value)}
                      styles={customStyles}
                    />
                  </div>

                {/* Assign */}
                <div className="col-md-6 mb-3">
                  <label className="col-form-label" htmlFor="assignCheckbox">Assign</label>
                  <input
                    type="checkbox"
                    id="assignCheckbox"
                    className="form-check-input"
                    checked={leadsData.assign}
                    onChange={assignHandler}
                  />
                </div>

                  {/* Assigned To */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Assigned To</label>
                    <input
                      type="text"
                      name="assignedTo"
                      className="form-control floating"
                      value={leadsData.assignedTo}
                      onChange={handleInputChange}
                      disabled={true}
                    />
                  </div>

                  
                  {/* Assigned To */}
                  <div className="col-md-6 mb-3">
                      <label className="col-form-label">Assign To</label>
                      <select
                        name="assignTo"
                        className="form-control"
                        value={leadsData.assignTo}
                        onChange={(e) => handleSelectChange('assignTo', e.target.value)} // Use e.target.value
                      >
                        <option value="">Select an option</option>
                        {userNames?.map((name, index) => (
                          <option key={index} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                      </div>


                  {/* Priority */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Priority</label>
                    <Select
                      options={priorityOptions}
                      value={priorityOptions.find((option) => option.value === leadsData.priority)}
                      onChange={(option) => handleSelectChange('priority', option.value)} // Use option.value
                      styles={customStyles}
                    />
                    {leadErrors.priority && (
                      <small className="text-danger mt-1 d-block">{leadErrors.priority}</small>
                    )}
                  </div>

                  {/* Status */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Status</label>
                    <Select
                    options={statusOptions}
                    value={statusOptions.find((option) => option.value === leadsData.status)}
                    onChange={(option) => handleSelectChange('status', option.value)} // Use option.value
                    styles={customStyles}
                  />
                  {leadErrors.status && (
                    <small className="text-danger mt-1 d-block">{leadErrors.status}</small>
                  )}
                  </div>

                  {/* Stage */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Stage</label>
                    <Select
                      options={stageOptions}
                      value={stageOptions.find((option) => option.value === leadsData.stage)}
                      onChange={(option) => handleSelectChange('stage', option.value)} // Use option.value
                      styles={customStyles}
                    />
                    {leadErrors.stage && (
                      <small className="text-danger mt-1 d-block">{leadErrors.stage}</small>
                    )}
                  </div>

                  {/* Channel */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Channel</label>
                    <Select
                      options={channelOptions}
                      value={channelOptions.find((option) => option.value === leadsData.channel)}
                      onChange={(option) => handleSelectChange('channel', option.value)} // Use option.value
                      styles={customStyles}
                    />
                    {leadErrors.channel && (
                      <small className="text-danger mt-1 d-block">{leadErrors.channel}</small>
                    )}
                  </div>

                  {/* Successful */}
                  <div className="col-md-6 mb-3">
                    <label className="col-form-label">Successful</label>
                    <Select
                      options={yesNoOptions}
                      value={yesNoOptions.find((option) => option.value === leadsData.successfull)}
                      onChange={(option) => handleSelectChange('successfull', option.value)}
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="submit-section" style={{ margin: "auto" }}>
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  data-bs-dismiss={isLeadValid ? "modal" : undefined}
                >
                  Submit
                </button>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div id="edit_agency" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Agency Information</h5>
            <button type="button" onClick={() => handleClose('Agency')}  className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
          <form onSubmit={handleAgencySubmit}>
            <div className="row">
              {/* Agency Name */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">Agency Name</label>
                <input
                  type="text"
                  name="agencyName"
                  className="form-control"
                  value={agencyData.agencyName}
                  onChange={handleAgencyInputChange}
                />
                {errors.agencyName && <div className="text-danger">{errors.agencyName}</div>}
              </div>

              {/* Address */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">Address</label>
                <input
                  type="text"
                  name="address"
                  className="form-control"
                  value={agencyData.address}
                  onChange={handleAgencyInputChange}
                />
                {errors.address && <div className="text-danger">{errors.address}</div>}
              </div>

              {/* City */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">City</label>
                <input
                  type="text"
                  name="city"
                  className="form-control"
                  value={agencyData.city}
                  onChange={handleAgencyInputChange}
                />
                {errors.city && <div className="text-danger">{errors.city}</div>}
              </div>

              {/* State */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">State</label>
                <input
                  type="text"
                  name="state"
                  className="form-control"
                  value={agencyData.state}
                  onChange={handleAgencyInputChange}
                />
                {errors.state && <div className="text-danger">{errors.state}</div>}
              </div>

              {/* Time Zone */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">Time Zone</label>
                <select
                  name="timeZone"
                  className="form-control"
                  value={agencyData.timeZone}
                  onChange={ handleAgencyInputChange}
                >
                  <option value="">Select Time Zone</option>
                  {timeZoneOptions?.map((zone, index) => (
                    <option key={index} value={zone}>
                      {zone}
                    </option>
                  ))}
                </select>
                {errors.timeZone && <div className="text-danger">{errors.timeZone}</div>}
              </div>

              {/* Web URL */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">Web URL</label>
                <input
                  type="url"
                  name="webURL"
                  className="form-control"
                  value={agencyData.webURL}
                  onChange={handleAgencyInputChange}
                />
              </div>

              {/* Contact Info URL */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">Contact Info URL</label>
                <input
                  type="url"
                  name="contactInfoURL"
                  className="form-control"
                  value={agencyData.contactInfoURL}
                  onChange={handleAgencyInputChange}
                />
              </div>

              {/* LinkedIn URL */}
              <div className="col-md-6 mb-3">
                <label className="col-form-label">LinkedIn URL</label>
                <input
                  type="url"
                  name="linkedinURL"
                  className="form-control"
                  value={agencyData.linkedinURL}
                  onChange={handleAgencyInputChange}
                />
              </div>
            </div>
            <div className="submit-section" style={{ margin: "auto" }}>
            <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  data-bs-dismiss={isFormValid ? "modal" : undefined}
                >
                  Submit
                </button>
            </div>
          </form>
          </div>
        </div>
      </div>
      </div>
      <div id="edit_contacts" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Lead Contact Information</h5>
            <button type="button"  onClick={() => handleClose('Contacts')} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
          <form onSubmit={handleContactsSubmit}>
            {leadsContacts?.map((contact, index) => (
              <div key={index} className="card mb-3">
                <div className="card-body">
                  <h3 className="card-title">
                    Lead Contact {index + 1}
                    <Link 
                      to="#" 
                      className="delete-icon" 
                      onClick={() => handleDeleteContact(index)}
                    >
                      <i className="fa-regular fa-trash-can" />
                    </Link>
                  </h3>
                  <div className="row">
                    {/* Contact Person */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Contact Person</label>
                      <input
                        type="text"
                        name="contactPerson"
                        className={`form-control ${contactErrors[index]?.contactPerson ? 'is-invalid' : ''}`}
                        value={contact.contactPerson || ''}
                        onChange={(e) => handleContactsInputChange(index, e)}
                      />
                      {contactErrors[index]?.contactPerson && (
                        <small className="text-danger">{contactErrors[index].contactPerson}</small>
                      )}
                    </div>

                    {/* Email */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Email</label>
                      <input
                        type="email"
                        name="email"
                        className={`form-control ${contactErrors[index]?.email ? 'is-invalid' : ''}`}
                        value={contact.email || ''}
                        onChange={(e) => handleContactsInputChange(index, e)}
                      />
                      {contactErrors[index]?.email && (
                        <small className="text-danger">{contactErrors[index].email}</small>
                      )}
                    </div>

                    {/* Phone */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        className={`form-control ${contactErrors[index]?.phone ? 'is-invalid' : ''}`}
                        value={contact.phone || ''}
                        onChange={(e) => handleContactsInputChange(index, e)}
                      />
                      {contactErrors[index]?.phone && (
                        <small className="text-danger">{contactErrors[index].phone}</small>
                      )}
                    </div>

                    {/* Title */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Title</label>
                      <input
                        type="text"
                        name="title"
                        className={`form-control ${contactErrors[index]?.title ? 'is-invalid' : ''}`}
                        value={contact.title || ''}
                        onChange={(e) => handleContactsInputChange(index, e)}
                      />
                      {contactErrors[index]?.title && (
                        <small className="text-danger">{contactErrors[index].title}</small>
                      )}
                    </div>

                    {/* Remarks */}
                    <div className="col-md-12 mb-3">
                      <label className="col-form-label">Remarks</label>
                      <textarea
                        name="remarks"
                        className="form-control"
                        value={contact.remarks || ''}
                        onChange={(e) => handleContactsInputChange(index, e)}
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            ))}

          <div className="mb-3">
            <Link to="#" onClick={handleAddNewContact}>
              <i className="fa-solid fa-plus-circle" /> Add more
            </Link>
          </div>

            <div className="submit-section" style={{ margin: 'auto' }}>
              <button className="btn btn-primary submit-btn" 
              data-bs-dismiss={isContactFormValid ? "modal" : undefined}
               type="submit">
                Submit
              </button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    <div id="call_tracking_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Lead Call Information</h5>
            <button type="button" onClick={() => handleClose('Calls')} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
           <form onSubmit={handleCallsSubmit}>
            {leadsCalls?.map((call, index) => (
              <div key={index} className="card mb-3">
                <div className="card-body">
                  <h3 className="card-title">
                    Lead Call {index + 1}
                    <Link
                      to="#"
                        className="delete-icon btn btn-sm float-end"
                        onClick={() => handleDeleteCall(index)}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </Link>


                  </h3>

                  <div className="row">
                    {/* Call Date */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Date</label>
                      <DatePicker
                        value={call.date ? formatDate(call.date) : null}// Convert to dayjs object for Ant Design
                        onChange={(date) =>
                          handleCallsInputChange(index, {
                            target: { name: 'date', value: date }, // Convert to Date object or null
                          })
                        }
                        className="form-control"
                        format="DD/MM/YYYY" // Specify the date format
                        placeholder="Select date"
                      />
                    </div>

                      {/* Call Time */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Time</label>
                      <TimePicker
                      id={`time-picker-${index}`} // Unique ID for each TimePicker
                        value={call.time ? dayjs(call.time, "HH:mm:ss") : null} // Convert stored time string to dayjs object
                        onChange={(time) => {
                          console.log('Selected time:', time); // Log the selected time for debugging
                          if (time) {
                            const formattedTime = time.format("HH:mm:ss"); // Format the time to "HH:mm:ss"
                            handleCallsInputChange(index, {
                              target: { name: 'time', value: formattedTime } // Pass only the time string to the backend
                            });
                            console.log('Formatted time to send:', formattedTime); // Log the formatted time
                          }
                        }}

                        className="form-control"
                        placeholder="Select time"
                      />
                    </div>

                    {/* Call Status */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Status</label>
                      <select
                        name="status"
                        className={`form-control ${callsErrors[index]?.status ? 'is-invalid' : ''}`}
                        value={call.status || ''}
                        onChange={(e) => handleCallsInputChange(index, e)}
                      >
                      {DataBucket.SALES_CALL_STATUS?.map((carrierItem, key) => (
                        <option key={key} value={carrierItem}>
                            {carrierItem}
                        </option>
                      ))}                                          
                      </select>
                      {callsErrors[index]?.status && (
                        <small className="text-danger">{callsErrors[index].status}</small>
                      )}
                    </div>


                      {/* Call By */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call By</label>
                        <select
                            name="callby"
                            className="form-control"
                            value={call.callby || ''}
                            onChange={(e) => handleCallsInputChange(index, e)}
                        >
                            {userNames?.map((name, idx) => (
                                <option key={idx} value={name}>
                                    {name}
                                </option>
                            ))}
                        </select>
                    </div>


                    {/* Spoke With */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Spoke With</label>
                      <input
                        type="text"
                        name="spokeWith"
                        className={`form-control ${callsErrors[index]?.spokeWith ? 'is-invalid' : ''}`}
                        value={call.spokeWith || ''}
                        onChange={(e) => handleCallsInputChange(index, e)}
                      />
                      {callsErrors[index]?.spokeWith && (
                        <small className="text-danger">{callsErrors[index].spokeWith}</small>
                      )}
                    </div>

                     {/* Remarks */}
                      <div className="col-md-12 mb-3">
                        <label className="col-form-label">Remarks</label>
                        <textarea
                          name="remarks"
                          className={`form-control ${callsErrors[index]?.remarks ? 'is-invalid' : ''}`}
                          value={call.remarks || ''}
                          onChange={(e) => handleCallsInputChange(index, e)}
                        ></textarea>
                      </div>
                      {/* Email Sent (Optional Checkbox) */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Email Sent</label>
                        <input
                          type="checkbox"
                          name="emailSent"
                          className="form-check-input"
                          checked={call.emailSent || false}
                          onChange={(e) => handleCallsInputChange(index, {
                            target: { name: 'emailSent', value: e.target.checked }
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div className="mb-3">
              <Link to="#" onClick={handleAddNewCall}>
                <i className="fa-solid fa-plus-circle" /> Add more
              </Link>
            </div>


            <div className="submit-section" style={{ margin: 'auto' }}>
              <button className="btn btn-primary submit-btn"
              data-bs-dismiss={isCallFormValid ? "modal" : undefined}
               type="submit">
                Submit
              </button>
            </div>
          </form>
          </div>
        </div>
      </div>
    </div>
    <div id="email_tracking_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Lead Email Information</h5>
            <button type="button" onClick={() => handleClose('Emails')} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
          <form onSubmit={handleEmailsSubmit}>
            {leadsEmails?.map((email, index) => (
              <div key={index} className="card mb-3">
                <div className="card-body">
                  <h3 className="card-title">
                    Lead Email {index + 1}
                    <Link
                      to="#"
                      className="delete-icon float-end"
                      onClick={() => handleDeleteEmail(index)}
                    >
                      <i className="fa-regular fa-trash-can" />
                    </Link>

                  </h3>
                  <div className="row">
                    {/* Email Date */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Date</label>
                      <DatePicker
                        value={email.date ? formatDate(email.date) : null}
                        onChange={(date) => handleEmailsInputChange(index, { target: { name: 'date', value: date } })}
                        className="form-control"
                        dateFormat="DD/MM/YYYY"
                        placeholderText="Select date"
                      />
                    </div>

                    {/* Email Time */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Time</label>
                      <TimePicker
                      id={`time-picker-${index}`} 
                        value={email.time ? dayjs(email.time, "HH:mm:ss") : null} // Convert stored time string to dayjs object
                        onChange={(time) => {
                          if (time) {
                            const formattedTime = time.format("HH:mm:ss");
                            handleEmailsInputChange(index, {
                              target: { name: 'time', value: formattedTime }
                            });
                          }
                        }}
                        className="form-control"
                        placeholder="Select time"
                      />
                    </div>

                    {/* Email Status */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Status</label>
                      <select
                        name="status"
                        className="form-control"
                        value={email.status || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      >
                        {DataBucket.SALES_EMAIL_STATUS?.map((statusItem, key) => (
                          <option key={key} value={statusItem}>
                            {statusItem}
                          </option>
                        ))}
                      </select>
                      {emailsErrors[index]?.status && (
                        <small className="text-danger">{emailsErrors[index].status}</small>
                      )}
                    </div>

                    {/* Emailed To */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Emailed To</label>
                      <input
                        type="text"
                        name="emailedto"
                        className="form-control"
                        value={email.emailedto || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      />
                       {emailsErrors[index]?.emailedto && (
                      <small className="text-danger">{emailsErrors[index].emailedto}</small>
                    )}

                    </div>
                   
                    {/* Emailed By */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Emailed By</label>
                      <select
                        name="emailedy"
                        className="form-control"
                        value={email.emailedy || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      >
                        {userNames?.map((name, idx) => (
                          <option key={idx} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Remarks */}
                    <div className="col-md-12 mb-3">
                      <label className="col-form-label">Remarks</label>
                      <textarea
                        name="remarks"
                        className="form-control"
                        value={email.remarks || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      ></textarea>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mb-3">
              <Link to="#" onClick={handleAddNewEmail}>
                <i className="fa-solid fa-plus-circle" /> Add more
              </Link>
            </div>
            <div className="submit-section" style={{ margin: 'auto' }}>
              <button className="btn btn-primary submit-btn"
              data-bs-dismiss={isEmailFormValid ? "modal" : undefined}
               type="submit">
                Submit
              </button>
            </div>
          </form>

          </div>
        </div>
      </div>
    </div>
    <div id="linkedin_tracking_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Linkedin Information</h5>
            <button type="button" onClick={() => handleClose('Linkedins')} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleLinkedinsSubmit}>
              {leadsLinkedins?.map((linkedin, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body">
                    <h3 className="card-title">
                      Linkedin Request {index + 1}
                      <Link
                        to="#"
                        className="delete-icon float-end"
                        onClick={() => handleDeleteLinkedin(index)}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </Link>

                    </h3>
                    <div className="row">
                      {/* Request Date */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Date</label>
                        <DatePicker
                          value={linkedin.date ? formatDate(linkedin.date) : null}
                          onChange={(date) => handleLinkedinsInputChange(index, { target: { name: 'date', value: date } })}
                          className="form-control"
                          dateFormat="DD/MM/YYYY"
                          placeholderText="Select date"
                        />
                      </div>

                      {/* Request Time */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Time</label>
                        <TimePicker
                        id={`time-picker-${index}`} 
                          value={linkedin.time ? dayjs(linkedin.time, "HH:mm:ss") : null}
                          onChange={(time) => {
                            if (time) {
                              const formattedTime = time.format("HH:mm:ss");
                              handleLinkedinsInputChange(index, {
                                target: { name: 'time', value: formattedTime }
                              });
                            }
                          }}
                          className="form-control"
                          placeholder="Select time"
                        />
                      </div>

                      {/* Request Status */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Status</label>
                        <select
                          name="status"
                          className={`form-control ${linkedinsErrors[index]?.status ? 'is-invalid' : ''}`}
                          value={linkedin.status || ''}
                          onChange={(e) => handleLinkedinsInputChange(index, e)}
                        >
                          {DataBucket.SALES_LINKEDIN_STATUS?.map((statusItem, key) => (
                            <option key={key} value={statusItem}>
                              {statusItem}
                            </option>
                          ))}
                        </select>
                        {linkedinsErrors[index]?.status && (
                          <small className="text-danger">{linkedinsErrors[index].status}</small>
                        )}
                      </div>

                      {/* Requested By */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Requested By</label>
                        <select
                          name="requestBy"
                          className="form-control"
                          value={linkedin.requestBy || ''}
                          onChange={(e) => handleLinkedinsInputChange(index, e)}
                        >
                          {userNames?.map((name, idx) => (
                            <option key={idx} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>


                      {/* Requested To */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Request To</label>
                        <input
                          type="text"
                          name="requestTo"
                          className={`form-control ${linkedinsErrors[index]?.requestTo ? 'is-invalid' : ''}`}
                          value={linkedin.requestTo || ''}
                          onChange={(e) => handleLinkedinsInputChange(index, e)}
                        />
                        {linkedinsErrors[index]?.requestTo && (
                          <small className="text-danger">{linkedinsErrors[index].requestTo}</small>
                        )}
                      </div>

                      {/* Remarks */}
                      <div className="col-12 mb-3">
                        <label className="col-form-label">Remarks</label>
                        <textarea
                          name="remarks"
                          className={`form-control ${linkedinsErrors[index]?.remarks ? 'is-invalid' : ''}`}
                          value={linkedin.remarks || ''}
                          onChange={(e) => handleLinkedinsInputChange(index, e)}
                        />
                       
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div className="mb-3">
              <Link to="#" onClick={handleAddNewLinkedin}>
                <i className="fa-solid fa-plus-circle" /> Add more
              </Link>
            </div>
              <div className="submit-section" style={{ margin: 'auto' }}>
                <button className="btn btn-primary submit-btn"
                data-bs-dismiss={isLNFormValid ? "modal" : undefined}
                 type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="scheduling_tracking_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Scheduled Call Information</h5>
            <button type="button" onClick={() => handleClose('Schedules')} className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleScheduledCallsSubmit}>
              {leadsScheduledCalls?.map((call, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body">
                    <h3 className="card-title">
                      Scheduled Call {index + 1}
                      <Link
                        to="#"
                        className="delete-icon  float-end"
                        onClick={() => handleDeleteScheduledCall(index)}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </Link>

                    </h3>
                    <div className="row">
                      {/* Scheduled Call Date */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Date</label>
                        <DatePicker
                          value={call.date ? formatDate(call.date) : null}
                          onChange={(date) => handleScheduledCallsInputChange(index, { target: { name: 'date', value: date } })}
                          className="form-control"
                          dateFormat="DD/MM/YYYY"
                          placeholderText="Select date"
                        />
                      </div>

                      {/* Scheduled Call Time */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Time</label>
                        <TimePicker
                        id={`time-picker-${index}`} 
                          value={call.time ? dayjs(call.time, "HH:mm:ss") : null}
                          onChange={(time) => {
                            if (time) {
                              const formattedTime = time.format("HH:mm:ss");
                              handleScheduledCallsInputChange(index, {
                                target: { name: 'time', value: formattedTime }
                              });
                            }
                          }}
                          className="form-control"
                          placeholder="Select time"
                        />
                      </div>
                      {/* Call Type */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call Type</label>
                        <select
                          name="callType"
                          className={`form-control ${scheduledCallErrors[index]?.callType ? 'is-invalid' : ''}`}
                          value={call.callType || ''}
                          onChange={(e) => handleScheduledCallsInputChange(index, e)}
                        >
                          <option value="" disabled>Select Call Type</option>
                          {DataBucket.SALES_SCHEDULED_CALL_TYPE?.map((type, key) => (
                            <option key={key} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        {scheduledCallErrors[index]?.callType && (
                          <small className="text-danger">{scheduledCallErrors[index].callType}</small>
                        )}
                      </div>

                    {/* Email(s) */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Email(s)</label>
                      <input
                        type="text"
                        name="emails"
                        className={`form-control ${scheduledCallErrors[index]?.emails ? 'is-invalid' : ''}`}
                        value={call.emails || ''}
                        onChange={(e) => handleScheduledCallsInputChange(index, e)}
                      />
                      {scheduledCallErrors[index]?.emails && (
                        <small className="text-danger">{scheduledCallErrors[index].emails}</small>
                      )}
                    </div>

                    {/* Phone Number */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Phone Number</label>
                      <input
                        type="text"
                        name="phonenumber"
                        className={`form-control ${scheduledCallErrors[index]?.phonenumber ? 'is-invalid' : ''}`}
                        value={call.phonenumber || ''}
                        onChange={(e) => handleScheduledCallsInputChange(index, e)}
                      />
                      {scheduledCallErrors[index]?.phonenumber && (
                        <small className="text-danger">{scheduledCallErrors[index].phonenumber}</small>
                      )}
                    </div>

                      {/* Call Scheduled By */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call Scheduled By</label>
                        <select
                          name="callScheduledBy"
                          className="form-control"
                          value={call.callScheduledBy || ''}
                          onChange={(e) => handleScheduledCallsInputChange(index, e)}
                        >
                          {userNames?.map((name, idx) => (
                            <option key={idx} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>

                    {/* Time Zone */}
                    <div className="col-md-6 mb-3">
                      <label className="col-form-label">Time Zone</label>
                      <select
                        name="timeZone"
                        className="form-control"
                        value={call.timeZone || ''}
                        onChange={(e) => handleScheduledCallsInputChange(index, e)}
                      >
                        {DataBucket.SALES_TIMEZONES?.map((zone, key) => (
                          <option key={key} value={zone}>
                            {zone}
                          </option>
                        ))}
                      </select>
                    </div>


                      {/* Remarks */}
                      <div className="col-md-12 mb-3">
                        <label className="col-form-label">Remarks</label>
                        <textarea
                          name="remarks"
                          className="form-control"
                          value={call.remarks || ''}
                          onChange={(e) => handleScheduledCallsInputChange(index, e)}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

            <div className="mb-3">
              <Link to="#" onClick={handleAddNewScheduledCall}>
                <i className="fa-solid fa-plus-circle" /> Add more
              </Link>
            </div>
              <div className="submit-section" style={{ margin: 'auto' }}>
                <button className="btn btn-primary submit-btn"
                data-bs-dismiss={isSCformValid ? "modal" : undefined}
                type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <div id="otc_tracking_modal" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">OPS Information</h5>
            <button type="button" onClick={() => handleClose('OPS')} className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleOpsSubmit}>
              {leadsOPS?.map((call, index) => (
                <div key={index} className="card mb-3">
                  <div className="card-body">
                    <h3 className="card-title">
                      Lead OPS {index + 1}
                      <Link
                        to="#"
                        className="delete-icon btn btn-sm float-end"
                        onClick={() => handleDeleteOPS(index)}
                      >
                        <i className="fa-regular fa-trash-can" />
                      </Link>

                    </h3>
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Date</label>
                        <DatePicker
                          value={call.date? formatDate(call.date) : null}
                          onChange={(date) => handleOPSInputChange(index, { target: { name: 'date', value: date } })}
                          className="form-control"
                          dateFormat="DD/MM/YYYY"
                          placeholderText="Select date"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Time</label>
                        <TimePicker
                        id={`time-picker-${index}`} 
                          value={call.time ? dayjs(call.time, "HH:mm:ss") : null}
                          onChange={(time) => {
                            if (time) {
                              const formattedTime = time.format("HH:mm:ss");
                              handleOPSInputChange(index, { target: { name: 'time', value: formattedTime } });
                            }
                          }}
                          className="form-control"
                          placeholder="Select time"
                        />
                      </div>
                      {/* Call Type */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call Type</label>
                        <select
                          name="callType"
                          className={`form-control ${opsErrors[index]?.callType ? 'is-invalid' : ''}`}
                          value={call.callType || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        >
                          <option value="" disabled>Select Call Type</option>
                          {DataBucket.SALES_SCHEDULED_CALL_TYPE?.map((type, key) => (
                            <option key={key} value={type}>
                              {type}
                            </option>
                          ))}
                        </select>
                        {opsErrors[index]?.callType && (
                          <small className="text-danger">{opsErrors[index].callType}</small>
                        )}
                      </div>

                      {/* Emails */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Emails</label>
                        <input
                          type="text"
                          name="emails"
                          className={`form-control ${opsErrors[index]?.emails ? 'is-invalid' : ''}`}
                          value={call.emails || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                        {opsErrors[index]?.emails && (
                          <small className="text-danger">{opsErrors[index].emails}</small>
                        )}
                      </div>

                      {/* Phone Number */}
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Phone Number</label>
                        <input
                          type="text"
                          name="phonenumber"
                          className={`form-control ${opsErrors[index]?.phonenumber ? 'is-invalid' : ''}`}
                          value={call.phonenumber || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                        {opsErrors[index]?.phonenumber && (
                          <small className="text-danger">{opsErrors[index].phonenumber}</small>
                        )}
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call Scheduled By</label>
                        <select
                          name="callScheduledBy"
                          className="form-control"
                          value={call.callScheduledBy || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        >
                          <option value="" disabled>Select User</option>
                          {userNames?.map((name, idx) => (
                            <option key={idx} value={name}>
                              {name}
                            </option>
                          ))}
                        </select>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">AMS Used By Client</label>
                        <input
                          type="text"
                          name="amsusedbyClient"
                          className="form-control"
                          value={call.amsusedbyClient || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Client Requirements</label>
                        <textarea
                          name="clientRequirements"
                          className="form-control"
                          value={call.clientRequirements || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Pros</label>
                        <textarea
                          name="pros"
                          className="form-control"
                          value={call.pros || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Cons</label>
                        <textarea
                          name="cons"
                          className="form-control"
                          value={call.cons || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>

                      <div className="col-md-12 mb-3">
                        <label className="col-form-label">Remarks</label>
                        <textarea
                          name="remarks"
                          className="form-control"
                          value={call.remarks || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        ></textarea>
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Call Connected</label>
                        <input
                          type="checkbox"
                          name="callConnected"
                          className="form-check-input"
                          checked={call.callConnected || false}
                          onChange={(e) => handleOPSInputChange(index, {
                            target: { name: 'callConnected', value: e.target.checked }
                          })}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Client Attendees</label>
                        <input
                          type="text"
                          name="clientAttendees"
                          className="form-control"
                          value={call.clientAttendees || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">BluePlanit Attendees</label>
                        <input
                          type="text"
                          name="blueplanitAttendees"
                          className="form-control"
                          value={call.blueplanitAttendees || ''}
                          onChange={(e) => handleOPSInputChange(index, e)}
                        />
                      </div>

                      <div className="col-md-6 mb-3">
                        <label className="col-form-label">Email Sent</label>
                        <input
                          type="checkbox"
                          name="emailSent"
                          className="form-check-input"
                          checked={call.emailSent || false}
                          onChange={(e) => handleOPSInputChange(index, {
                            target: { name: 'emailSent', value: e.target.checked }
                          })}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              <div className="mb-3">
                <Link to="#" onClick={handleAddNewOPS}>
                  <i className="fa-solid fa-plus-circle" /> Add more
                </Link>
              </div>
              <div className="submit-section" style={{ margin: 'auto' }}>
                <button className="btn btn-primary submit-btn"
                 data-bs-dismiss={isOTCFormValid ? "modal" : undefined}
                type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    {/* addcomment */}
    <div
      id="comment_modal_popup"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between align-items-center" style={{ height: "80px" }}>
            <h3 className="modal-title">Add Comment</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pt-3">
            <form
              onSubmit={handleCommentSubmit} // Handle form submission
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                minHeight: "300px", // Adjust height for the main box
                width: "100%" // Ensure full width
              }}
            >
              <div className="input-block mb-3 form-focus" style={{ width: "100%" }}>
                <textarea
                  rows={5} // Set the number of rows
                  value={comment} // Allow for user input
                  onChange={handleCommentChange} // Change handler
                  className="w-100"
                  style={{
                    backgroundColor: "#ffffff", // Change to white for user input
                    cursor: "text", // Change cursor to text for enabled textarea
                    resize: "none",
                    minHeight: "200px", // Minimum height for the textarea
                    height: "auto",
                    outline: 'none' // Remove outline for focused textarea
                  }}
                />
              </div>
              {/* Ensure space between textarea and submit button */}
            </form>
            <div className="submit-section" style={{ marginTop: "20px", width: "100%", textAlign: "center" }}>
              <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" onClick={handleCommentSubmit} style={{ width: "200px" }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* add contact comment */}
    <div
      id="conatct_comment_modal_popup"
      className="modal custom-modal fade"
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header d-flex justify-content-between align-items-center" style={{ height: "80px" }}>
            <h3 className="modal-title">Add Comment</h3>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body pt-3">
            <form
              onSubmit={handleContatctsCommentSubmit} // Handle form submission
              style={{
                backgroundColor: "white",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "flex-start",
                minHeight: "300px", // Adjust height for the main box
                width: "100%" // Ensure full width
              }}
            >
              <div className="input-block mb-3 form-focus" style={{ width: "100%" }}>
                <textarea
                  rows={5} // Set the number of rows
                  value={contactComment} // Allow for user input
                  onChange={handleContactsCommentChange} // Change handler
                  className="w-100"
                  style={{
                    backgroundColor: "#ffffff", // Change to white for user input
                    cursor: "text", // Change cursor to text for enabled textarea
                    resize: "none",
                    minHeight: "200px", // Minimum height for the textarea
                    height: "auto",
                    outline: 'none' // Remove outline for focused textarea
                  }}
                />
              </div>
              {/* Ensure space between textarea and submit button */}
            </form>
            <div className="submit-section" style={{ marginTop: "20px", width: "100%", textAlign: "center" }}>
              <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" onClick={handleContatctsCommentSubmit} style={{ width: "200px" }}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>
  );
};

export default SalesInformationPopup;
