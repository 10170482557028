import React from "react";
import { DatePicker, TimePicker } from "antd";
import dayjs from "dayjs";
import "antd/dist/reset.css";
import { Link } from "react-router-dom";

const CreateEmail = ({
  leadsEmails,
  handleDeleteEmail,
  handleEmailsInputChange,
  handleAddNewEmail,
  formData,
  handleChange,
  dataBucket,
  userNames,
}) => {
  return (
    <>
      {leadsEmails?.map((email, index) => (
        <div key={index} className="card mb-3">
          <div className="card-body">
            <h3 className="card-title">
              Lead Email {index + 1}
              <Link
                to="#"
                className="delete-icon btn btn-sm float-end"
                onClick={() => handleDeleteEmail(index)}
              >
                <i className="fa-regular fa-trash-can" />
              </Link>
            </h3>

            <div className="row">
              {/* Email Date */}
              <div className="col-md-2 mb-3">
                <label className="col-form-label">Date</label>
                <DatePicker
                  value={email.date ? dayjs(email.date, "DD/MM/YYYY") : null}
                  onChange={(date) =>
                    handleEmailsInputChange(index, {
                      target: {
                        name: "date",
                        value: date ? dayjs(date).format("DD/MM/YYYY") : "",
                      },
                    })
                  }
                  className="form-control"
                  format="DD/MM/YYYY"
                  placeholder="Select date"
                />
              </div>

{/* Email Time */}
<div className="col-md-2 mb-3">
                      <label className="col-form-label">Time</label>
                      <TimePicker
                      id={`time-picker-${index}`} 
                        value={email.time ? dayjs(email.time, "HH:mm:ss") : null} // Convert stored time string to dayjs object
                        onChange={(time) => {
                          if (time) {
                            const formattedTime = time.format("HH:mm:ss");
                            handleEmailsInputChange(index, {
                              target: { name: 'time', value: formattedTime }
                            });
                          }
                        }}
                        className="form-control"
                        placeholder="Select time"
                      />
                    </div>

                    {/* Email Status */}
                    <div className="col-md-2 mb-3">
                      <label className="col-form-label">Status</label>
                      <select
                        name="status"
                        className="form-control"
                        value={email.status || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      >
                        {dataBucket.SALES_EMAIL_STATUS?.map((statusItem, key) => (
                          <option key={key} value={statusItem}>
                            {statusItem}
                          </option>
                        ))}
                      </select>
                     
                    </div>

                    {/* Emailed To */}
                    <div className="col-md-2 mb-3">
                      <label className="col-form-label">Emailed To</label>
                      <input
                        type="email"
                        name="emailedto"
                        className="form-control"
                        value={email.emailedto || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      />
                       
                    </div>
                   
                    {/* Emailed By */}
                    <div className="col-md-2 mb-3">
                      <label className="col-form-label">Emailed By</label>
                      <select
                        name="emailedy"
                        className="form-control"
                        value={email.emailedy || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      >
                        {userNames?.map((name, idx) => (
                          <option key={idx} value={name}>
                            {name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* Remarks */}
                    <div className="col-md-12 mb-3">
                      <label className="col-form-label">Remarks</label>
                      <textarea
                        name="remarks"
                        className="form-control"
                        value={email.remarks || ''}
                        onChange={(e) => handleEmailsInputChange(index, e)}
                      ></textarea>
                      
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <div className="mb-3 mt-2">
              <Link to="#" onClick={handleAddNewEmail}>
                <i className="fa-solid fa-plus-circle" /> Add Emails
              </Link>
            </div>

            <div className="mb-3">
              <label className="col-form-label">Email Info Comments</label>
              <textarea
                name="emailComments"
                className="form-control"
                value={formData.emailComments || ''}
                onChange={(e) => handleChange("emailComments", e.target.value)}
              ></textarea>
            </div>
    </>
  );
};

export default CreateEmail;
