//export const SERVER_URL = 'http://54.153.30.228:5001';
// export const SERVER_URL = 'http://3.101.33.99:5001';
export const SERVER_URL = 'http://13.57.151.230:5001';
//export const SERVER_URL = 'http://localhost:5001';
//export const SERVER_URL = 'http://54.215.90.157:5001';
export const QUOTE_TYPE_CONFIG = ['New', 'Revision', 'Resend'];
export const DEPARTMENT_CONFIG = ['Personal Line', 'Commercial Lines'];
export const POLICY_TYPE_CONFIG = ['Home', 'Auto', 'Umbrella', 'Collectibles'];
export const PROPERTY_TYPE_CONFIG = ['Home Owners(HO3)', 'Renters(HO4)', 'Condo(HO6)', 'Dwelling Fire(DP3)', 'Home Owners(HO5)', 'Motor Cycle', 'Recreational Vehicle', 'Boat', 'Personal Auto', 'Builders Risk', 'Personal Umbrella'];
export const PROPERTY_TYPE_CONFIG_POLICY_QUOTE = ['BOP', 'Business Interruption', 'Commercial General Liability', 'Commercial Property', 'Commercial Umbrella', 'Commercial Auto', 'Workers Compensation', 'Inland Marine', 'Commercial Package'];
// export const CARRIERS_CONFIG = ['Chubb', 'AIG', 'Berkley One', 'Nationwide Private Client (NWPC)', 'PURE', 'Travelers',
//   'Guard', 'Hartford', 'Hanover', 'Progressive', 'Kemper', 'Liberty Mutual ', 'Nationwide Group', 'Auto-Owners', 'Foremost', 'Safeco',
//   'Hippo', 'State Auto', 'StillWater', 'NatGen360', 'National General', 'Mercury', 'Lemonade', 'All-State', 'Main Street American', 'Trexis'];
export const CARRIERS_CONFIG = ['Aegis Security Ins', 'AIG', 'American Modern', 'AmTrust', 'AmWINS (formally Networked)', 'Assurant Flood', 'Auto Owners', 'Allied - Nationwide', 'Allstate', 'Arrowhead', 'Berkley One', 'Bristol West', 'CATcoverage.com', 'Chubb', 'Citizens', 'CNA', 'Donegal Insurance Companies', 'Encompass', 'First Connection', 'Foremost Business', 'Foremost Star', 'Foremost Star- Me', 'Foremost/Bristol', 'Grange', 'Guard', 'Hanover', 'Hagerty', 'Haulers', 'Hippo', 'Liberty Mutual', 'Lamonade', 'Kemper', 'Main Street America Group Carrie logins', 'Main Street America Group', 'Mercury', 'Nationwide', 'NBS Agent Login', 'Openly', 'Personal Umbrella', 'Pinnacol', 'Private Client NW/Crestbrook', 'Progressive', 'PURE', 'Safeco', 'State Auto', 'Stillwater', 'Travelers', 'Trexis', 'USLI', 'National General', 'Westfield Insurance', 'The Hartford']
export const TEST_TYPE_CONFIG = ['New', 'Renewal'];
export const NO_OF_QUOTES = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
export const PRIORITY_CONFIG = ['Rush', 'On Demand', 'Bulk'];
export const PROCESS_TYPE_CONFIG = ['Accords', 'Audits', 'BORs', 'Cancellations', 'Certificate Issuance', 'DBRs', 'Endorsements', 'Loss Runs', 'LPRs', 'PFAs', 'Policy Checking', 'Policy Quoting', 'Policy Renewals', 'Proposals', 'Special Projects', 'Supplemental', 'MVRs', 'EOP Template', 'Pre-Renewal Letters', 'Auto ID Cards', 'Invoicing', 'Direct Bill Reconciliation', 'New Account Creation', 'Renewals Processing'];
export const ASSIGNED_TO_CONFIG = ['User One', 'User Two', 'User Three'];
export const ASSIGN_TO_CONFIG = ['User One', 'User Two', 'User Three'];
export const REQUEST_STATUS_CONFIG = ['Open', 'In Progress', 'On Hold', 'Completed'];
// export const FILTER_STATUS_CONFIG = ['Open', 'In Progress', 'On Hold'];
export const FILTER_STATUS_CONFIG = ['Inbox', 'Email Needed', 'Pending', 'Discard', 'Completed'];

export const REPORT_TASKS = ['Processing', 'Email', 'Quality', 'Management']
export const SALE_TASKS = ['Data Processing', 'Call', 'Linkedin', 'Email', 'Quality', 'Management']
export const DEPARTMENTS = ['Sales', 'Operations'];
// export const tasks = [
//     'Available',
//     'Break',
//     'Meeting',
//     'Admin',
//     'Management',
//     'Training',
//     'Cross-Training',
//     'No-work Assigned'
// ];
export const TASKS_TO_CONFIG = ['Processing', 'Email', 'Quality', 'Management'] // 'Communication', 'Agency meeting'

// export const TIMER_DEPARTMENT = [name='Operations', name='Sales', name='Both']
export const STATUS = [
  { label: "Active", value: "Active" },
  { label: "InActive", value: "InActive" }
]
export const TIMER_DEPARTMENT = [
  { label: 'Operations', value: 'Operations' },
  { label: 'Sales', value: 'Sales' },
  { label: 'Both', value: 'Both' },
];
export const HOLIDAYS_DEPARTMENT = [
  { label: 'Operations', value: 'Operations' },
  { label: 'Sales', value: 'Sales' },
  { label: 'IT', value: 'IT' },
];
export const ROLE = [
  { label: 'User', value: 'User' },
  { label: 'Admin', value: 'Admin' },
  { label: 'Super Admin', value: 'Super Admin' },
];
export const Rehire = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" }
];

export const DOMAIN = [
  { value: "Web Development", label: "Web Development" },
  { value: "IT Management", label: "IT Management" },
  { value: "Marketing", label: "Marketing" },
];
const developer = DOMAIN;

export const TYPES_OF_LEAVES = ["payleaves"]

export const genderOptions = [
  { value: "Male", label: "Male" },
  { value: "Female", label: "Female" },
];
export const employmentOptions = [
  { value: "Full Time", label: "Full Time" },
  { value: "Part Time", label: "Part Time" },
];

export const workOptions = [
  { value: "WFH", label: "Work From Home (WFH)" },
  { value: "WFO", label: "Work From Office (WFO)" },
];

export const TIMER_TASKS = ['Available', 'Break', 'Meeting', 'Admin', 'Management', 'Training', 'Cross-Training', 'No-work Assigned']
export const REQUEST_SUBMIT_KEYS = [
  'ExistingPolicyInformation',
  'NewQuoteInformation',
];
export const bloodGroups = [
  { value: "A+", label: "A+" },
  { value: "A-", label: "A-" },
  { value: "B+", label: "B+" },
  { value: "B-", label: "B-" },
  { value: "AB+", label: "AB+" },
  { value: "AB-", label: "AB-" },
  { value: "O+", label: "O+" },
  { value: "O-", label: "O-" }
];
export const SIDEMENU = [
  {
    name: "Employees",
    status: false,
    Subfields: [
      {
        displayName: "Employees",
        name: "/employees",
        status: false,
        __typename: "SidemenuType",
      },
     
     
      {
        displayName: "Employee Leaves",
        name: "/employee-leaves",
        status: false,
        __typename: "SideMainType",
      },
      
      {
        displayName: "Admin Attendance",
        name: "/adminattendance",
        status: false,
        __typename: "SidemenuType",
      },
      {
        displayName: "Employee Attendance",
        name: "/employeeattendance",
        status: false,
        __typename: "SidemenuType",
      },
      {
        displayName: "Holidays",
        name: "/holidays",
        status: false,
        __typename: "SidemenuType",
      },
      {
        displayName: "Leave Settings",
        name: "/leave-settings",
        status: false,
        __typename: "SideMainType",
      },
      {
        displayName: "Admin Leaves",
        name: "/adminleaves",
        status: false,
        __typename: "SideMainType",
      },
      {
        displayName: "Pay Slips",
        name: "/payslips",
        status: false,
        __typename: "SideMainType",
      },
      {
        displayName: "Reset Token",
        name: "/reset-token",
        status: false,
        __typename: "SideMainType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Clients",
    status: false,
    Subfields: [
      {
        displayName: "Clients",
        name: "/clients",
        status: false,
        __typename: "SidemenuType",
      },
     
    ],
    __typename: "SideMainType",
  },
  {
    name: "Sales",
    status: false,
    Subfields: [
      {
        displayName: "Sales",
        name: "/sales",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Requests",
    status: false,
    Subfields: [
      {
        displayName: "Requests",
        name: "/request",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "CROptions",
    status: false,
    Subfields: [
      {
        displayName: "Customize Request Options",
        name: "/customiseRequestOptions",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Work Reports",
    status: false,
    Subfields: [
      {
        displayName: "Employee Report",
        name: "/EmployeeReport",
        status: false,
        __typename: "SidemenuType",
      },
      {
        displayName: "Client Report",
        name: "/ClientReport",
        status: false,
        __typename: "SidemenuType",
      },
      {
        displayName: "Sales Report",
        name: "/SalesReport",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Email",
    status: false,
    Subfields: [
      {
        displayName: "Mail",
        name: "/Mail",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Data Bucket",
    status: false,
    Subfields: [
      {
        displayName: "Data Bucket",
        name: "/dataBucket",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "Scrapper",
    status: false,
    Subfields: [
      {
        displayName: "Web Scraping Config",
        name: "/webScrapingConfig",
        status: false,
        __typename: "SidemenuType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name: "DashBoard",
    status: false,
    Subfields: [
      {
        displayName: "Dashboard",
        name: "/Dashboard",
        status: false,
        __typename: "SideMainType",
      },
      {
        displayName: "Sales Stats",
        name: "/SalesStats",
        status: false,
        __typename: "SideMainType",
      },
      {
        displayName: "Request Stats",
        name: "/RequestStats",
        status: false,
        __typename: "SideMainType",
      },
    ],
    __typename: "SideMainType",
  },
  {
    name:"Daynamic Route",
    status: false,
    Subfields: [
      {
        displayName: "Dynamic Route",
        name: "/DaynamicRoute",
        status: false,
        __typename: "SideMainType",
      },
    ],
    __typename: "SideMainType",
  },

  
];


export const INSURANCE_CARRIERS_CONFIG = ['Aegis Security Ins', 'AIG', 'American Modern', 'AmTrust', 'AmWINS (formally Networked)', 'Assurant Flood', 'Auto Owners', 'Allied - Nationwide', 'Allstate', 'Arrowhead', 'Berkley One', 'Bristol West', 'CATcoverage.com', 'Chubb', 'Citizens', 'CNA', 'Donegal Insurance Companies', 'Encompass', 'First Connection', 'Foremost Business', 'Foremost Star', 'Foremost Star- Me', 'Foremost/Bristol', 'Grange', 'Guard', 'Hanover', 'Hagerty', 'Haulers', 'Hippo', 'Liberty Mutual', 'Lamonade', 'Kemper', 'Main Street America Group Carrie logins', 'Main Street America Group', 'Mercury', 'Nationwide', 'NBS Agent Login', 'Openly', 'Personal Umbrella', 'Pinnacol', 'Private Client NW/Crestbrook', 'Progressive', 'PURE', 'Safeco', 'State Auto', 'Stillwater', 'Travelers', 'Trexis', 'USLI', 'National General', 'Westfield Insurance', 'The Hartford']
// export const INSURANCE_CARRIERS_CONFIG = ['Chubb', 'AIG', 'Berkley One', 'Nationwide Private Client (NWPC)', 'PURE', 'Travelers',
//   'Hippo', 'State Auto', 'StillWater', 'NatGen360', 'National General', 'Mercury', 'Lemonade', 'All-State', 'Main Street American', 'Trexis'];

export const POLICY_CONFIG = ['Home Owners(HO3)', 'Renters(HO4)', 'Condo(HO6)', 'Dwelling Fire(DP3)', 'Home Owners(HO5)', 'Motor Cycle', 'Recreational Vehicle', 'Boat', 'Personal Auto', 'Builders Risk', 'Personal Umbrella'];
export const SALES_PRIORITY = ['High', 'Medium', 'Low'];
export const SALES_STATUS = ['New', 'Open', 'On Hold', 'In Progress', 'Reopened', 'Closed'];
export const SALES_STAGE = ['Sales team', 'Operation team', 'Closing team', 'Data team'];
export const SALES_CHANNAL = ['Cold Calling', 'Email', 'LinkedIn', 'Reference'];
export const SALES_TIMEZONES = ['Eastern Time', 'Central Time', 'Mountain Time', 'Pacific Time', 'Hawaii Time'];

export const SALES_CALL_STATUS = ['Attempt', 'Call Back', 'Call-in Future', 'Contacted', 'Follow-up', 'Front-Desk', 'Qualified', 'Unqualified'];
export const SALES_EMAIL_STATUS = ['Sent', 'Delivered', 'Client Read', 'undelivered', 'Wrong email', 'Response received', 'Not interested'];
export const SALES_LINKEDIN_STATUS = ['Request Sent', 'Accepted', 'Communicated', 'Not-accepted'];
export const SALES_SCHEDULED_CALL_TYPE = ['Phone Call', 'Zoom Call'];

// export const REQUEST_SUBMIT_KEYS = ['QuoteType',
//   'Department',
//   'InsuredName',
//   'NoofQuotes',
//   'RadioValue',
//   // 'ExistingPolicyInformation',
//   // 'NewQuoteInformation',
//   'Priority',
//   'ProcessType',
//   'AssignedTo',
//   'AssignTo',
//   'StartDate',
//   'DueDate',
//   'RequestStatus',
//   'RadioInfo',
//   'EmailSummary',
//   'Requestor',
//   'SendTo',
//   'Attachments',
//   'ExistingPolicyInformation',
//   'NewQuoteInformation',
// ];





// RequestForm
export const Quote_Type = 'QuoteType'
export const Department = 'Department'
export const Insured_Name = 'InsuredName'
export const Number_of_Quotes = 'NoofQuotes'
export const Radio_Value = 'RadioValue'

//-------------
export const ExistingPolicyInformation = 'ExistingPolicyInformation'
//-------------
export const Current_Carrier = 'Current_Carrier'
export const Property_Type = 'Property_Type'
export const Property_New = 'Property_New'
export const Policy_Number = 'Policy_Number'
export const Effective_Date = 'Effective_Date'
export const Current_Premium = 'Current_Premium'

//-------------
export const NewQuoteInformation = 'NewQuoteInformation'
//-------------
export const Policy = 'Policy'
export const Insurance_Carrier = 'Insurance_Carrier'
export const Quoted_Premium = 'Quoted_Premium'

//Request Information----------------------------------------
export const Priority = 'Priority'
export const ProcessType = 'ProcessType'
export const AssignedTo = 'AssignedTo'
export const AssignTo = 'AssignTo'
export const StartDate = 'StartDate'
export const DueDate = 'DueDate'
export const RequestStatus = 'RequestStatus'
export const RadioInfo = 'RadioInfo'
export const EmailSummary = 'EmailSummary'
export const Requestor = 'Requestor'
export const SendTo = 'SendTo'

export const COMPANY_NAME_CONFIG = ['Blueplaint', 'The DeLuca Agency', 'BTJ Insurance, Inc', 'Heidi Kussurelis Agency', 'CharSan Insurance & Consulting LLC', 'Jain Insurance Agency', 'Lincoln Insurance Group, LLC', 'Scott Held Insurance Agency', 'Laurie Insurance Group LLC', 'Aloha Insurance Services, Inc', 'SJL Insurance Service, Inc']
export const DEPARTMENT_CONFIG_CLIENT = ['Personal Line', 'Commercial Lines', "Both"];



//Request Work History

