export const updateSidebarData = (staticMenu, backendMenu) => {
    const backendMenuMap = new Map(
      backendMenu.map(item => [item.name, { ...item, Subfields: [...item.Subfields] }]) // Clone the object and Subfields
    );
  
    staticMenu.forEach(staticItem => {
      let backendItem = backendMenuMap.get(staticItem.name);
  
      if (backendItem) {
        backendItem.name = staticItem.name;
  
        // Ensure backend submenus exist
        if (!backendItem.Subfields) {
          backendItem.Subfields = [];
        }
  
        // Add missing subroutes from staticMenu to backendMenu, without updating their status
        staticItem.Subfields?.forEach(staticSub => {
          let backendSub = backendItem.Subfields.find(sub => sub.name === staticSub.name);
          if (!backendSub) {
            backendItem.Subfields.push({
              displayName:staticSub.displayName,
              name: staticSub.name,
              status: staticSub.status,
              __typename: staticSub.__typename
            });
          }
        });
  
        // ✅ Remove subroutes that exist in backend but not in staticMenu
        backendItem.Subfields = backendItem.Subfields.filter(sub =>
          staticItem.Subfields?.some(staticSub => staticSub.name === sub.name)
        );
  
      } else {
        // ✅ Add missing menu objects from staticMenu to backendMenu
        backendMenuMap.set(staticItem.name, {
          name: staticItem.name,
          status: staticItem.status,
          __typename: staticItem.__typename,
          Subfields: staticItem.Subfields?.map(sub => ({
            displayName:sub.displayName,
            name: sub.name,
            status: sub.status,
            __typename: sub.__typename
          })) || []
        });
      }
    });
  
    // ✅ Remove main routes that exist in backend but not in staticMenu
    return Array.from(backendMenuMap.values()).filter(menu =>
      staticMenu.some(staticItem => staticItem.name === menu.name)
    );
  };