import React, { useState, memo, useEffect } from "react";
import { Formik, useFormikContext, FieldArray, Field } from "formik";
import CommentField from "./CommentField";
import {
  Button,
  Typography,
  Form,
  Row,
  Col,
  Input,
  Select,
  Divider,
  DatePicker,
  TimePicker,
} from "antd";
import { timerHistoryPayloadChange } from "../../../Slices/user";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import * as DataBucket from "../../../databucket";
import Swal from "sweetalert2";
import { UPDATE_WORKHISTORY } from "../../../Queries/Orders";
import { useMutation } from "@apollo/client";
import { useDispatch } from "react-redux";
import { Starttask } from "../../../Slices/timerslice";
import { settaskStartTime } from "../../../Slices/user";
import { setIsRefetch } from "../../../Slices/salesSlice";
import { addLastContactedDate, removeLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import { renderOptions } from '../../../utilsdata';

const EmailTrack = memo((props) => {
  const task = useSelector((state) => state.timer.task);
  const salesLeadId = useSelector((state) => state?.sales?.sale?.leadID);
  const WorkingId = useSelector((state) => state?.timer?.requestId);
  const CurrentTask = 'Email';
  const dispatch = useDispatch()
  const [isRunning, setIsRunning] = useState(false);
  const [updateWorkHistory, { data, loading, error }] = useMutation(UPDATE_WORKHISTORY);
  const { SalesInfo, assignToUsers, getBy, setFieldValue, id, leadID } = props;
  const { values, handleChange, errors, handleBlur, touched } = useFormikContext();

  const [emailedto, setEmailedTo] = useState(values.leadsEmails?.map(call => call.emailedto) || []);
  const [remarks, setRemarks] = useState(values.leadsEmails?.map(call => call.remarks) || []);
  const options = useSelector((state) => state.dataBucket.databucket);


  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setEmailedTo([]);
      setRemarks([]);
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const [localEmailErrors, setLocalEmailErrors] = useState([]);

  const handleEmailInputChange = (e, index) => {
    const value = e.target.value.trim();
    const newEmailedTo = [...emailedto];
    newEmailedTo[index] = value;

    // Local validation: simple email format check
    let errorMessage = "";
    // Updated email validation (only for '.com' domain)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
    if (value && !emailRegex.test(value.trim())) {
      errorMessage = "Invalid email format";
    }

    const newErrors = [...localEmailErrors];
    newErrors[index] = errorMessage;

    setEmailedTo(newEmailedTo);
    setLocalEmailErrors(newErrors);
  };

  const handleAddRow = (arrayHelpers) => {

    const newDate = dayjs(new Date());
    setFieldValue("lastContactedDate", newDate);

    arrayHelpers.push({
      date: newDate || "",
      time: dayjs().set("second", 0).set("millisecond", 0) || "",
      status: "Sent",
      emailedto: "",
      emailedy: getBy(null),
      remarks: "",
      emailFollowup: "FollowUp",
    });

    dispatch(addLastContactedDate(newDate));
  };

  const lastContactedDates = useSelector(state => state.lastContactedDates);
  console.log("lastDates", lastContactedDates)

  const handleDeleteRow = (index, arrayHelpers) => {

    Swal.fire({
      title: "Are you sure?",
      text: "Do you really want to delete this email record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        arrayHelpers.remove(index); // Remove the call record

        // Update local state: Remove the corresponding index
        setEmailedTo((prev) => prev.filter((_, i) => i !== index));
        setRemarks((prev) => prev.filter((_, i) => i !== index));

        setLocalEmailErrors((prev) => prev.filter((_, i) => i !== index));

        // Get the date of the row being deleted from `leadsCalls`
        const deletedRowDate = arrayHelpers.form.values.leadsEmails[index].date;

        const lastContactedDatess = lastContactedDates.filter(date => dayjs(date).valueOf() !== dayjs(deletedRowDate).valueOf());

        const newDate = dayjs(new Date());
        const latestDate = lastContactedDatess.length > 0
          ? Math.max(...lastContactedDatess.map(date => dayjs(date).valueOf()))
          : null;

        dispatch(setLastContactedDates(lastContactedDatess));

        if (latestDate) {
          setFieldValue("lastContactedDate", dayjs(latestDate));
        }
      }
    });

  }
  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const handleStartButtonClick = async () => {

    const payload = {
      department: "Sales",
      task: "Email",
      time: Date.now(),
      requestId: SalesInfo?.leadID || salesLeadId,
    };

    try {
      const response = await updateWorkHistory({
        variables: {
          userId: parseJwt(token).id,
          emailId: "",
          requestId: SalesInfo?.leadID.toString() || salesLeadId,
          clientName: "Bluepalnit",
          processType: "",
          taskType: "Email",
          email: "",
          subject: "",
          body: "",
          startTime: Date.now().toString(),
          endTime: "",
        },
      });

      // dispatch(addLatestReports(Reportpayload))
    } catch (err) {
      console.error("Error updating work history:", err);
    }
    localStorage.setItem("task", "Email");
    localStorage.setItem("time", Date.now());
    localStorage.setItem("department", "sales");
    localStorage.setItem("requestId", SalesInfo?.leadID || salesLeadId);
    // dispatch(updateAReport(Reportpayload));
    dispatch(Starttask(payload));
  };
  const handleStop = async () => {
    setIsRunning(false);

    const valuesObject = {
      id: Math.random().toString(),
      userId: parseJwt(token)?.id,
      title: "",
      description: "",
      clientName: "Blueplaint",
      department: "Operations",
      taskType: "Available",
      startTime: Date.now().toString(),
      endTime: Date.now().toString(),
      requestId: "",
    };
    //setRefetch(true);
    dispatch(timerHistoryPayloadChange({ data: valuesObject }));
    await updateWorkHistory({
      variables: {
        userId: parseJwt(token).id,
        emailId: "",
        requestId: "",
        clientName: "Bluepalnit",
        processType: "",
        taskType: "Available",
        email: "",
        subject: "",
        body: "",
        startTime: Date.now().toString(),
        endTime: "",
      },
    });
    const payload = {
      department: "",
      requestId: "",
      task: "Available",
      time: Date.now(),
    };
    localStorage.setItem("StartTime", new Date().getTime());
    localStorage.setItem("task", "Available");
    localStorage.removeItem("department");
    localStorage.removeItem("requestId");
    dispatch(settaskStartTime(new Date().getTime()));
    dispatch(Starttask(payload));
  };

  const getTime = (time) => {
    let formatedTime = "";
    if (time != undefined && time != "") {
      if (typeof time === "object") {
        formatedTime = time;
      } else if (typeof time === "string") {
        if (time?.includes("T")) {
          let tempdatetime = time?.split("T");
          let temptime = tempdatetime[1]?.split(".");
          time = temptime[0];
        }
        let timeArray = time?.split(":");
        formatedTime = dayjs()
          .set("hour", timeArray[0])
          .set("minute", timeArray[1])
          .set("second", timeArray[2]);
      }
    } // Set the desired time
    return formatedTime;
  };
  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != "") {
      if (typeof datevalue === "string" && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue));
      } else if (typeof datevalue === "object") {
        finalDate = datevalue;
      }
    }
    return finalDate;
  };
  return (
    <div
      style={{
        marginTop: "15px",
        backgroundColor: "#fff",
        padding: "20px",
        boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        borderRadius: "8px",
      }}
    >
      <Typography.Title level={4}>Email Track
        {
          id !== undefined && (
            WorkingId === leadID && task === CurrentTask ? (
              <Button
                className="btn btn-danger"
                style={{
                  float: "right",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleStop}
              >
                Stop
              </Button>
            ) : (
              <Button
                color="primary"
                variant="solid"
                style={{
                  float: "right",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleStartButtonClick}
              >
                Start
              </Button>
            )
          )
        }

      </Typography.Title>
      <FieldArray
        name="leadsEmails"
        render={(arrayHelpers) => (
          <>
            {values?.leadsEmails?.map((Emailinfo, index) => (
              <div key={index} style={{ marginBottom: "1rem" }}>
                <Row gutter={16}>
                  {/* Email Label */}
                  <Col
                    span={24}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      // marginBottom: "20px",
                      marginTop: '15px'
                    }}
                  >
                    <Typography.Text strong>
                      {index === 0 ? "First Email" : "Follow up " + index}
                    </Typography.Text>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        color: "#00c5fb",
                        cursor: "pointer",
                        fontSize: "16px",
                      }}
                      onClick={() => handleDeleteRow(index, arrayHelpers)}
                    />
                  </Col>

                  {/* Date Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsEmails.${index}.date`}>
                        {({ field }) => (
                          <DatePicker
                            allowClear={false}
                            inputReadOnly={true}
                            format="DD/MM/YYYY"
                            name={`leadsEmails.${index}.date`}
                            value={getDateValue(field?.value)}
                            onChange={(date) =>
                              field.onChange({
                                target: { value: date, name: field.name },
                              })
                            }
                            style={{ width: "100%" }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Time Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Time"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsEmails.${index}.time`}>
                        {({ field }) => (
                          <TimePicker
                            format="h:mm A"
                            allowClear={false}
                            inputReadOnly={true}
                            name={`leadsEmails.${index}.time`}
                            value={
                              (field.value && getTime(field.value)) ||
                              dayjs().set("second", 0).set("millisecond", 0)
                            }
                            onChange={(time) =>
                              field.onChange({
                                target: { value: time, name: field.name },
                              })
                            }
                            // format="HH:mm"
                            style={{ width: "100%" }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Email Status Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Email Status"
                      labelCol={{ span: 24 }} // Full-width label
                      wrapperCol={{ span: 24 }} // Full-width field
                      help={
                        touched?.leadsEmails?.[index]?.status &&
                        errors?.leadsEmails?.[index]?.status
                      }
                      validateStatus={
                        touched?.leadsEmails?.[index]?.status &&
                          errors?.leadsEmails?.[index]?.status
                          ? "error"
                          : "success"
                      }
                    >
                      <Field name={`leadsEmails.${index}.status`}>
                        {({ field, form }) => (
                          <Select
                            value={field.value || "Sent"} // Default to 'Sent' if no value
                            onChange={(value) =>
                              form.setFieldValue(field.name, value)
                            } // Explicitly update Formik state
                            style={{ width: "100%" }}
                          >
                            {renderOptions(options, { name: "SALES_EMAIL_STATUS" }, DataBucket.SALES_EMAIL_STATUS)}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Emailed To Field */}
                  <Col span={4}>
                    <Form.Item
                      label={
                        <span>
                          Emailed to
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // help={
                      //   touched?.leadsEmails?.[index]?.emailedto &&
                      //   errors?.leadsEmails?.[index]?.emailedto
                      // } // Show error message
                      // validateStatus={
                      //   touched?.leadsEmails?.[index]?.emailedto &&
                      //   errors?.leadsEmails?.[index]?.emailedto
                      //     ? "error"
                      //     : "success"
                      // }
                      validateStatus={
                        (touched?.leadsEmails?.[index]?.emailedto && errors?.leadsEmails?.[index]?.emailedto) || localEmailErrors[index]
                          ? "error"
                          : ""
                      }
                      help={
                        localEmailErrors[index] ||
                        (touched?.leadsEmails?.[index]?.emailedto && errors?.leadsEmails?.[index]?.emailedto)
                      }
                    >
                      <Input
                        // onChange={(e) => {
                        //   const newSpokeWith = [...emailedto];
                        //   newSpokeWith[index] = e.target.value;
                        //   setEmailedTo(newSpokeWith);
                        // }}
                        onChange={(e) => handleEmailInputChange(e, index)}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsEmails[${index}].emailedto`,
                              value: e.target.value.trim(),
                            },
                          });
                        }}
                        value={emailedto[index]}
                      />

                    </Form.Item>
                  </Col>

                  {/* Emailed By Field */}
                  <Col span={4}>
                    <Form.Item
                      label="Emailed by"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsEmails.${index}.emailedy`}>
                        {({ field, form }) => (
                          <Select
                            showSearch
                            value={field.value || ""} // Bind the Formik field's value
                            onChange={(value) =>
                              form.setFieldValue(field.name, value)
                            } // Update Formik state on selection
                            style={{ width: "100%" }}
                          >
                            {assignToUsers?.map((user, key) => (
                              <Select.Option
                                key={key}
                                value={`${user.firstname} ${user.lastname}`}
                              >
                                {`${user.firstname} ${user.lastname}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Remarks Field */}
                  <Col span={24}>
                    <Form.Item
                      label="Remarks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Input.TextArea
                        onChange={(e) => {
                          const newRemarks = [...remarks];
                          newRemarks[index] = e.target.value;
                          setRemarks(newRemarks);
                        }}
                        value={remarks[index]}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsEmails[${index}].remarks`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider style={{ backgroundColor: "#ccc", height: "1px" }} />
              </div>
            ))}
            <Row justify="end" style={{ marginTop: "16px" }}>
              <Button
                type="primary"
                style={{ marginTop: "10px" }}
                onClick={() => handleAddRow(arrayHelpers)}
              >
                Add Row
              </Button>
            </Row>
          </>
        )}
      />
      <CommentField label="Email Comments" name="emailComments" />

    </div>
  );
});

export default EmailTrack;
