import React from 'react';
import { Table } from 'antd';
 
export const ReusableTable = ({
  columns,
  dataSource,
  loading,
  currentPage,
  pageSize,
  totalRecords,
  onPageChange,
  rowKey = 'id',
  rowClassName = () => "single-row-color",
  showQuickJumper = true,
  showSizeChanger = false,
  size = 'small',
  showTotal,
  scrollX = false,
  isPaginationEnabled = true,
  serverValidate = true, 
  ...props
}) => {

  const defaultPagination = {
    pageSize: 10, 
    showQuickJumper: true,
  };
 
  return (
    <Table
      rowKey={rowKey}
      style={{ width: '100%', tableLayout: 'auto' }}
      columns={columns}
      dataSource={dataSource}
      pagination={
        isPaginationEnabled
          ? serverValidate
            ? {
                current: currentPage,
                pageSize: pageSize,
                total: totalRecords,
                onChange: onPageChange,
                showQuickJumper: showQuickJumper,
                showSizeChanger: showSizeChanger,
                showTotal: showTotal || ((count, range) => `${range[0]}-${range[1]} of ${count}`),
              }
            : defaultPagination 
          : false
      }
      loading={loading}
      scroll={{ x: scrollX }}
      size={size}
      rowClassName={rowClassName}
      {...props}
    />
  );
};
 
export default ReusableTable;