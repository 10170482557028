import React, { useState, useEffect } from "react";
import { useQuery, useMutation, from } from "@apollo/client";
import { Link, useParams, useNavigate } from "react-router-dom";
import { UPDATE_EMPLOYEE } from "../../../Queries/Employees";
import dayjs from "dayjs";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import CombinedInfoComponent from "./basicInfo";
import AddNoteBox from "./addNote";
import { useSelector } from "react-redux";
const WorkHistory = () => {
   const { Empid } = useParams();
  const workHistory=useSelector((state)=>state.employee.workHistory)
    return (
      <>
        <div>
            <CombinedInfoComponent />
            <div id="work_history_modal_popup" className="card profile-box flex-fill">
  <div className="card-body">
    <div className="d-flex justify-content-between align-items-center">
      <h3 className="card-title">Work History</h3>
    {Empid?<Link
      to="#"
      className="edit-icon"
      data-bs-toggle="modal"
      data-bs-target="#work_history_modal_popup"
    >
      <i className="fa fa-pencil" />
    </Link> : null}
    </div>

    <div className="table-responsive">
      <table className="table table-nowrap text-center">
        <thead>
          <tr>
            <th>Department</th>
            <th>Designation</th>
            <th>Client Handled</th>
            <th>Process Handled</th>
            <th>Shift Timings</th>
            <th>From</th>
            <th>To</th>
          </tr>
        </thead>
        <tbody>
          {workHistory.length > 0 ? (
            workHistory.map((item, index) => (
              <tr key={index}>
                <td>{item.department}</td>
                <td>{item.designation}</td>
                <td>{item.clientHandled}</td>
                <td>{item.processHandled}</td>
                <td>{item.shiftTimings}</td>
                <td>{dayjs(item.from).format("DD/MM/YYYY")}</td>
                <td>{dayjs(item.to).format("DD/MM/YYYY")}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="7">No work history found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  </div>
  
</div>
<PersonalInformationModelPopup  name={"workNote"} />
  <AddNoteBox name={"workNote"}/>
</div >

</>
    );
};

export default WorkHistory;
