import React, { useState } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";

function BulkEmail() {
    const [emails, setEmails] = useState([{ email: '', subject: '', body: '' }]);

    const handleInputChange = (index, e) => {
        const newEmails = [...emails];
        newEmails[index][e.target.name] = e.target.value;
        setEmails(newEmails);
    };

    const addRecipient = () => {
        setEmails([...emails, { email: '', subject: '', body: '' }]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Normalize field names to lowercase (optional)
        const normalizedEmails = emails.map(email => ({
            email: email.email.toLowerCase(),
            subject: email.subject.toLowerCase(),
            body: email.body.toLowerCase()
        }));

        try {
            await axios.post('http://localhost:5001/send-emails', { emails: normalizedEmails });
            alert('Emails sent successfully!');
        } catch (error) {
            alert('Failed to send emails');
        }
    };

    const handleDelete = (index) => {
        const confirmDelete = window.confirm('Are you sure you want to delete this contact?');
        if (confirmDelete) {
            setEmails((prev) => prev.filter((_, i) => i !== index));
        }
    };

    return (
        <>
            <div className="page-wrapper">
                <div className="content container-fluid">
                    <form onSubmit={handleSubmit}>
                        {emails.map((item, index) => (
                            <div key={index} className="card mb-3">
                                <div className="card-body">
                                    <h1 className="card-title">
                                        Email {index + 1}
                                        <Link to="#" className="delete-icon" onClick={() => handleDelete(index)}>
                                            <i className="fa-regular fa-trash-can" />
                                        </Link>
                                    </h1>
                                    <div className="row">
                                        {['email', 'subject', 'body'].map((field) => (
                                            <div className="col-md-3" key={field}>
                                                <label className="col-form-label">{field.charAt(0).toUpperCase() + field.slice(1)}</label>
                                                {field === 'body' ? (
                                                    <textarea
                                                        name={field}
                                                        className='form-control'
                                                        value={item[field] || ''}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        required
                                                    />
                                                ) : (
                                                    <input
                                                        type={field === 'email' ? 'email' : 'text'}
                                                        name={field}
                                                        className='form-control'
                                                        value={item[field] || ''}
                                                        onChange={(e) => handleInputChange(index, e)}
                                                        required
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        ))}
                        <button type="button" onClick={addRecipient}>
                            Add Recipient
                        </button>
                        <button type="submit">Send Emails</button>
                    </form>
                </div>
            </div>
        </>
    );
}

export default BulkEmail;
