import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    holiday : []
}
const holidaySlice = createSlice({
    name: "holiday",
    initialState,
    reducers:{
        addHoliday: (state,action)=>{
            state.holiday.push(action.payload)
        },
        getHoliday: (state, action) => {
            console.log("action.payload",action.payload)
            state.holiday = action.payload;
        }

    }
});

export const {addHoliday,getHoliday} = holidaySlice.actions;

export default holidaySlice.reducer;