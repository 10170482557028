import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { updateWorkHistoryByAdmin } from "../../Slices/employeereportslice";
import dayjs from "dayjs";
import { isSameDay } from "date-fns";
import moment from "moment";
import { useSelector } from "react-redux";
import { addSingleOverrideHistory } from "../../Slices/employeereportslice";

export const EmployeeReportModal = ({ show, handleClose, data, isEdit }) => {
  console.log("data in employeereportModal", data);
  let Task = data.taskType;
  const dispatch = useDispatch();
  const employees = useSelector((state) => state.loginUserInfo.allUsers);
  console.log("employees", employees);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [message, setMessage] = useState(data.message || "");
  const [requestId, setRequestId] = useState(data.requestId || "");
  const [billable, setBillable] = useState(data.billable || "");
  const [taskType, setTask] = useState(data.taskType);

  console.log("taskType", taskType);
  const token = localStorage.getItem("token");

  const parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      return null;
    }
  };
  const userid = parseJwt(token)?.id.toString();
  const employee = employees.find((emp) => emp.id === userid);
  useEffect(() => {
    setTask(data.taskType);
  }, [data.taskType]);
  const handleChange = (e) => {
    const newTaskType = e.target.value;
    // setTaskType(newTaskType);  // Update local state
    setTask(newTaskType); // Update the parent component's state through the prop function
  };
  const employeename = employee
    ? `${employee.firstname} ${employee.lastname}`
    : "Employee not found";

  const specificDate = moment(startDate);
  const disabledDate = (current) => {
    console.log("i am called");
    return current.isBefore(
      moment(specificDate)
        .startOf("day")
        .add(moment(specificDate).hours(), "hours")
        .add(moment(specificDate).minutes(), "minutes"),
      "second"
    );
  };
  const handleSaveChanges = () => {
    const startTime = startDate ? startDate.toString() : null;
    const endTime = endDate ? endDate.toString() : null;

    if (endTime < startTime) {
      alert("end date is smaller than the startdate");
      return;
    }

    dispatch(
      updateWorkHistoryByAdmin({
        id: data._id,
        startTime,
        endTime,
        message,
        requestId,
        taskType,
        updatedBy: employeename,
      })
    ).then((res) => {
      dispatch(addSingleOverrideHistory(res.payload));
      console.log("res", res);
    });

    handleClose();
  };

  // Populate fields from data on component mount
  useEffect(() => {
    if (data?.startTime) setStartDate(data.startTime);
    if (data?.endTime) setEndDate(data.endTime);
  }, [data, setTask]);

  const getMinTime = () => {
    if (
      startDate &&
      endDate &&
      isSameDay(new Date(startDate), new Date(endDate))
    ) {
      const start = new Date(startDate);
      return new Date(start).setHours(start.getHours(), start.getMinutes());
    } else if (startDate) {
      const start = new Date(startDate);
      return new Date(start).setHours(start.getHours(), start.getMinutes());
    }
    return new Date().setHours(0, 0, 0, 0);
  };

  const handleClear = () => {
    // Clear all form fields
    setStartDate(data.startTime);
    setEndDate(data.endTime);
    setRequestId(data.requestId);
    setBillable(data.billable);
    setTask(data.taskType);

    handleClose();
  };

  const fields = [
    // Existing Fields for 
    { label: "Employee Name", value: data.employeeName },
    { label: "Email", value: data.emailId },
    { label: "Reporting To", value: data.reportingTo },
  ];

  const clientFields = [
    { label: "Client Name", value: data.clientName },
    { label: "Service", value: data.service },
    { label: "Task", value: data.taskType },
    { label: "Billable", value: data.billable },
  ];

  const requestFields = [
    { label: "Request", value: data?.emailDetails?.requestId },
    { label: "Request Type", value: data?.emailDetails?.quoteType },
    {
      label: "Processed By",
      value: data?.emailDetails?.assignTo || data?.emailDetails?.assignedTo,
    },
    { label: "Process Type", value: data?.emailDetails?.processType },
    { label: "No. of Quotes Received", value: data?.emailDetails?.noofQuotes },
    { label: "Request Received Date", value: data?.emailDetails?.date },
    { label: "Completed Date", value: data.emailDetails?.completedDate },
    { label: "Due Date", value: data.emailDetails?.dueDate },
    {
      label: "Start Date Override",
      value: data.emailDetails?.startDate,
    },
    {
      label: "Due Date Override",
      value: data.emailDetails?.dueDate,
    },
    { label: "Department", value: data.emailDetails?.department },
    { label: "Insured Name", value: data.emailDetails?.insuredName },
  ];
  const leadFields = [
    { label: "createdBy", value: data?.leadDetails?.createdBy },
    { label: "assignedTo", value: data?.leadDetails?.assignedTo },
    { label: "priority", value: data?.leadDetails?.priority },
    { label: "status", value: data?.leadDetails?.status },
    { label: "stage", value: data?.leadDetails?.stage },
  ];

  const timerFields = [
    { label: "Start Date & Time", value: startDate },
    { label: "End Date & Time", value: endDate },
    { label: "Duration", value: data.duration },
    { label: "Status", value: data.status },
  ];

  function formatDateTime(timestamp) {
    const date = new Date(parseInt(timestamp));
    if (isNaN(date.getTime())) {
      return "--";
    }
    const options = {
      month: "2-digit",
      day: "2-digit",
      year: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
      hour12: true,
      timeZone: "Asia/Kolkata",
    };
    return date.toLocaleString("en-IN", options);
  }

  return (
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header>
        <Modal.Title style={{ marginTop: "40px", textAlign: "right" }}>
          {/* <b>EmpId:</b> */}
          {data.employeeDetails?.EmpID}
        </Modal.Title>
        <div style={{ width: "100%", textAlign: "right" }}>
          {isEdit && (
            <>
              {/* Save Button (Green) */}
              <Button
                // variant="success"
                onClick={handleSaveChanges}
                style={{
                  marginRight: "7px",
                  backgroundColor: "#1677ff",
                  // borderRadius: '20px',
                  padding: "2px 7px",
                  color: "white",
                  // border: 'none',
                  // cursor: 'pointer'
                }}
              >
                Save
              </Button>
            </>
          )}

          {/* Clear Button (Grey) */}

          {/* Close Button (Red) */}
          <Button
            onClick={handleClear}
            style={{
              // marginRight: "70px",
              backgroundColor: "rgb(254 60 60)",
              // borderRadius: '20px',
              padding: "2px 7px",
              color: "white",
              // border: 'none',
              // cursor: 'pointer'
            }}
          >
            X
          </Button>
        </div>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <div>
            <Row
              className="profile-view"
              style={{
                marginTop: "10px",
                position: "relative",
                marginBottom: "25px",
              }}
            >
              <div
                style={{
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  margin: "30px 0px",
                  borderRadius: "10px",
                  padding: "20px",
                }}
              >
                <h5 className="side-heading">Employee Information</h5>
                {fields.map((field, index) => (
                  <Col key={index} md={6} className="field-container">
                    <Form.Label className="field-label">
                      {field.label}
                    </Form.Label>
                    <div className="field-wrapper">
                      {field.label === "Employee Name" ||
                      field.label === "Email" ||
                      field.label === "Reporting To" ? (
                        // Non-editable fields (just display the value)
                        <span className="field-value">{field.value || ""}</span>
                      ) : null}
                    </div>
                  </Col>
                ))}
              </div>
              {/* Client Information */}
              <div
                style={{
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  margin: "30px 0px",
                  borderRadius: "10px",
                }}
              >
                <h5 className="side-heading">Client Information</h5>
                {clientFields.map((field, index) => (
                  <Col key={index} md={6} className="field-container">
                    <Form.Label className="field-label">
                      {field.label}
                    </Form.Label>
                    <div className="field-wrapper">
                      {isEdit && field.label === "Task" ? (
                        // Editable Task field (dropdown)
                        <select
                          className="form-control"
                          value={taskType}
                          onChange={handleChange}
                        >
                          <option value="">Select Task</option>
                          <option value="Available">Available</option>
                          <option value="Break">Break</option>
                          <option value="Meeting">Meeting</option>
                          <option value="Admin">Admin</option>
                          <option value="Management">Management</option>
                          <option value="Training">Training</option>
                          <option value="Cross-Training">Cross-Training</option>
                          <option value="No-work Assigned">
                            No-work Assigned
                          </option>
                          <option value="Processing">Processing</option>
                          <option value="Quality">Quality</option>
                          <option value="Email">Email</option>
                        </select>
                      ) : (
                        <span className="field-value">{field.value || ""}</span>
                      )}
                    </div>
                  </Col>
                ))}
              </div>
       
            { data?.emailDetails !=="N/A" && <div
                style={{
                  padding: "20px",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  margin: "30px 0px",
                  borderRadius: "10px",
                }}
              >
                <h5 className="side-heading">Request Information</h5>
                {requestFields.map((field, index) => (
                  <Col key={index} md={6} className="field-container">
                    {console.log("field", field)}
                    <Form.Label className="field-label">
                      {field.label}
                    </Form.Label>
                    <div className="field-wrapper">
                      {isEdit && field.label === "RequestId" ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={data.requestId}
                          value={requestId}
                          onChange={(e) => setRequestId(e.target.value)}
                        />
                      ) : field.label === "Request Received Date" ||
                        field.label === "Completed Date" ||
                        field.label === "Due Date" ? (
                        <span className="field-value">
                          {field.value}
                          {/* {dayjs(field.value).isValid() ? dayjs(field.value).format("DD/MM/YYYY hh:mm A") : ""} */}
                        </span>
                      ) : (
                        <span className="field-value">{field.value || ""}</span>
                      )}
                    </div>
                  </Col>
                ))}
              </div>}

              {/* Lead Information */}
             { data?.leadDetails !== "N/A" && <div
                style={{
                  padding: "20px",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  margin: "30px 0px",
                  borderRadius: "10px",
                }}
              >
                <h5 className="side-heading">Lead Information</h5>
                {leadFields.map((field, index) => (
                  <Col key={index} md={6} className="field-container">
                    <Form.Label className="field-label">
                      {field.label}
                    </Form.Label>
                    <div className="field-wrapper">
                      {/* Just display the value for lead fields */}
                      <span className="field-value">{field.value || ""}</span>
                    </div>
                  </Col>
                ))}
              </div>}

              <div
                style={{
                  padding: "20px",
                  boxShadow: "0px 0px 15px rgba(0, 0, 0, 0.2)",
                  margin: "30px 0px",
                  borderRadius: "10px",
                }}
              >
                <h5 className="side-heading">Timer Information</h5>
                {timerFields.map((field, index) => (
                  <Col key={index} md={6} className="field-container">
                    <Form.Label className="field-label">
                      {field.label}
                    </Form.Label>
                    <div className="field-wrapper">
                      {field.label === "Start Date & Time" && isEdit ? (
                        // Editable Start Date and Time field
                        <div style={{ marginBottom: "15px" }}>
                          <DatePicker
                            disabled={!startDate}
                            selected={
                              startDate && dayjs(parseInt(startDate)).isValid()
                                ? dayjs(parseInt(startDate)).toDate()
                                : null
                            }
                            onChange={(date) =>
                              setStartDate(dayjs(date).valueOf())
                            }
                            showTimeSelect
                            timeIntervals={15} // Time intervals for time picker
                            className="form-control"
                            placeholderText="Select Start Date and Time"
                            dateFormat="dd/MM/yyyy hh:mm a" // Desired date and time format
                            maxDate={new Date()} // Today's date as max date
                          />
                        </div>
                      ) : field.label === "End Date & Time" && isEdit ? (
                        // Editable End Date and Time field
                        <div style={{ marginBottom: "15px" }}>
                          <DatePicker
                            disabled={!endDate}
                            selected={
                              endDate && dayjs(parseInt(endDate)).isValid()
                                ? dayjs(parseInt(endDate)).toDate()
                                : null
                            }
                            onChange={(date) =>
                              setEndDate(dayjs(date).valueOf())
                            }
                            showTimeSelect
                            timeIntervals={15}
                            className="form-control"
                            placeholderText="Select End Date and Time"
                            dateFormat="dd/MM/yyyy hh:mm a"
                            minDate={
                              startDate
                                ? dayjs(parseInt(startDate)).toDate()
                                : null
                            }
                            minTime={
                              startDate &&
                              dayjs(parseInt(startDate)).isSame(
                                dayjs(endDate),
                                "day"
                              )
                                ? dayjs(parseInt(startDate)).toDate()
                                : new Date(0, 0, 0, 0, 0)
                            }
                            maxTime={new Date(0, 0, 0, 23, 59)}
                            maxDate={new Date()}
                          />
                        </div>
                      ) : field.label === "Start Date & Time" ||
                        field.label === "End Date & Time" ? (
                        // Non-editable fields (just display the value)
                        <span className="field-value">
                          {formatDateTime(field.value) || " "}
                        </span>
                      ) : (
                        // Default handling for non-date fields
                        <span className="black-text">
                          {field.value === null ||
                          field.value === undefined ||
                          field.value === "InvalidDate"
                            ? ""
                            : field.value}
                        </span>
                      )}
                    </div>
                  </Col>
                ))}
              </div>
              {/* Conditional Section Rendering */}
              {data.overrideHistory && data.overrideHistory.length > 0 && (
                <div
                  style={{
                    borderRadius: "10px",
                    boxShadow: "10px 10px 15px rgba(0, 0, 0, 0.2)",
                    marginTop: "30px",
                    marginBottom: "30px",
                    padding: "20px",
                    borderTop: "2px solid #ddd",
                  }}
                >
                  <h5 className="side-heading">Override History</h5>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th>Overridden By</th>
                        <th> Task</th>
                        <th> Start Time</th>
                        <th> End Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.overrideHistory.length > 0 &&
                        data.overrideHistory.map((override, index) => {
                         
                          const previousOverride =
                            data.overrideHistory[index - 1] || {};

                          return (
                            <tr key={index}>
                              <td>{override.overriddenBy}</td>

                   
                              <td>{override.previousTask}</td>

                            
                              <td>
                                {override.previousstartTime.includes(" ==> ")
                                  ? override.previousstartTime
                                      .split(" ==> ")
                                      .map((part, index) => (
                                        <>
                                          {part}
                                          {index === 0 && " ==> "}
                                          {index !== 1 && <br />}
                                        </>
                                      ))
                                  : override.previousstartTime}
                              </td>

                              {/* Display Previous End Time Change or "Not changed" */}
                              <td>
                                {override.previousendTime.includes(" ==> ")
                                  ? override.previousendTime
                                      .split(" ==> ")
                                      .map((part, index) => (
                                        <>
                                          {part}
                                          {index === 0 && " ==> "}
                                          {index !== 1 && <br />}
                                        </>
                                      ))
                                  : override.previousendTime}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
              )}
            </Row>
          </div>
        </Form>

        <style jsx>{`
          .side-heading {
            color: #0260d2;
            font-weight: bold;
            margin-bottom: 15px; /* Adds space between the heading and the fields */
          }

          .field-label {
            color: #5a5e61;
            font-weight: bold;
            width: 200px; /* A fixed width to ensure label alignment */
            text-align: left; /* Aligns the labels to the left */
            margin-right: 15px; /* Adds space between the label and the value */
          }

          .field-value {
            flex-grow: 1; /* Takes up remaining space to align with label */
          }

          .field-container {
            display: flex;
            align-items: center;
            margin-bottom: 10px; /* Adds space between fields */
          }

          .field-wrapper {
            display: flex;
            flex-direction: row; /* Aligns label and value side by side */
            width: 100%; /* Ensures full width of the container is used */
          }
        `}</style>
      </Modal.Body>
    </Modal>
  );
};

export default EmployeeReportModal;
