import React from "react";
import { Link } from "react-router-dom";
import PersonalInformationModelPopup from "../../../components/modelpopup/PersonalInformationModelPopup";
import { useSelector } from "react-redux";
import AddNoteBox from "./addNote";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
// EducationItem component to render individual education details
const EducationItem = ({ item }) => {
 
  return (
    <li>
      <div className="experience-user">
        <div className="before-circle" />
      </div>
      <div className="experience-content">
        <div className="timeline-content">
          <Link to="/" className="name">
            {item.university}
          </Link>
          <div>{item.college}</div>
          <div>{item.degree} - {item.educationLevel}</div>
          <div>{item.fieldOfStudy}</div>
          <div>{item.typeOfStudy}</div>
          <span className="time">
            {dayjs(item.from).format("DD/MM/YYYY")} - {dayjs(item.to).format("DD/MM/YYYY")}
          </span>
        </div>
      </div>
    </li>
  );
};

// EducationComponent to display the list of education data
const EducationComponent = () => {
  const { Empid } = useParams();
  const employeeInfo=useSelector((state)=>state.employee.educationData)
  console.log("education",employeeInfo);
  return (
    <div id="education_data" className=" flex-fill">
      <div className="card profile-box flex-fill">
        <div className="card-body">
          <h3 className="card-title">
            Education Info
          {Empid ?  <Link
              to="#"
              className="edit-icon"
              data-bs-toggle="modal"
              data-bs-target="#education_info"
            >
              <i className="fa fa-pencil" />
            </Link> : null}
          </h3>
          <div className="experience-box">
            <ul className="experience-list">
              {employeeInfo?.map((item, index) => (
                <EducationItem key={index} item={item} />
              ))}
            </ul>
          </div>
        </div>
      </div>
     
      <PersonalInformationModelPopup name={"EducationNote"}/>
      <AddNoteBox name={"EducationNote"}/>
    </div>
  );
};

export default EducationComponent;
