import React from 'react';
import { Spin } from 'antd';
import { useSelector } from 'react-redux';

const Loader = () => {
    const loading=useSelector((state)=>state?.loader?.isLoading)
   
    return (
        <div
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                display: loading ? "flex" : "none", // Hide loader when spinning is false
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: "rgba(0, 0, 0, 0.34)",
                zIndex: 9999,
            }}
        >
            <Spin size="large" spinning={loading} />
        </div>
    );
};

export default Loader;
