import React, { useState, useRef, useEffect, } from "react";
import { message } from 'antd';
import { useMutation } from "@apollo/client";
import { CREATE_CLIENT } from "../../Queries/Orders";
import { useDispatch } from "react-redux";
import { addClient } from "../../Slices/clientSlice";
import { useErrorLogger } from "../../utils";
import { renderOptions } from "../../utilsdata";
import { DEPARTMENT_CONFIG_CLIENT } from "../../databucket";
import { useSelector } from "react-redux";
import InputMask from "react-input-mask";
export const AllClientAddPopup = () => {
  const formRef = useRef();
  const dispatch = useDispatch();
  const [addClientMutation] = useMutation(CREATE_CLIENT);
  const [isUserDataValid, setIsUserDataValid] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // const masks = {
  //   US: "(999) 999-9999",  // US format: (415) 555-0132
  //   IN: "+91 99999-99999", // India format: +91 98765-43210
  //   UK: "+44 9999 999999", // UK format: +44 7123 456789
  // };
  const { logError } = useErrorLogger();
  console.log(DEPARTMENT_CONFIG_CLIENT,"DEPARTMENT_CONFIG_CLIENT")
  const [formData, setFormData] = useState({
    clientName: "",
    mobileNo: "",
    countryCode:"+1",
    emailId: "",
    status: "",
    timeZone: "",
    department: "",
    userId: "",
    pointofContact: "",
    extension:"",
  });
  console.log(formData.document,"format")
  const [errors, setErrors] = useState({});
 
  const namepattern =/^[A-Za-z0-9\s-]+$/;
 
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
  const mobilePattern = /^\(?\d{3}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/;
 
 
 
  const resetErrors = () => {
    setErrors({});
  };
 
  const handleChange = (e) => {
    console.log("mobile",e.target)
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    setFormData((prevData) => ({
      ...prevData,
      [name]: trimmedValue
    }));
    validateField(name, trimmedValue);
  };
 
  const handleClose = () => {
    setFormData({
      clientName: "",
      mobileNo: "",
      countryCode:"+1",
      emailId: "",
      status: "",
      timeZone: "",
      department: "",
      userId: "",
      extension:"",
    });
    resetErrors();
  };
 
  // Define validation regex for different countries
// const validationPatterns = {
//   1: /^[0-9]{3}[-\s]?[0-9]{3}[-\s]?[0-9]{4}$/, // US format: 415-555-0132 or 415 555 0132
//   91: /^[0-9]{5}[-\s]?[0-9]{5}$/, // India format: 98765-43210 or 98765 43210
//   44: /^[0-9]{4}[-\s]?[0-9]{6}$/, // UK format: 7123 456789 or 7123-456789
// };
 
const validationPatterns = {
  '91': /^[6-9]\d{9}$/, // India mobile number (starts with 6-9, followed by 9 digits)
  '1': /^[2-9]\d{9}$/, // USA mobile number (10 digits, starting with 2-9)
  '44': /^(?:\+44|0)7\d{9}$/ // UK mobile number (starts with 07 or +44, followed by 9 digits)
};
// Normalize input by removing non-numeric characters for validation
const normalizeInput = (value) => {
  return value.replace(/\D/g, ""); // Removes all non-digit characters
};
 
  const validateField = (name, value) => {
    switch (name) {
      case "clientName":
        if (!value) return "Client name is required";
        if (!namepattern.test(value)) return "Name must be characters";
        return "";
       
      case "emailId":
        if (!value) return "Email is required";
        if (!emailPattern.test(value)) return "Invalid email format";
        return "";
 
        case "mobileNo":
          if (!value) {
            return "Mobile number is required";
          } else {
            const country = formData.countryCode?.slice(1); // Remove the "+" sign
            const pattern = validationPatterns[country];
            const normalizedValue = normalizeInput(value);
            console.log("normalizedValue",normalizedValue,pattern,country)
            if (!pattern?.test(normalizedValue)) {
              return `Invalid phone number format for ${country}`;
            }
   
            // Check if the entered number matches the correct country format
            // if (country === '91' && !/^[6-9]\d{9}$/.test(normalizedValue)) {
            //   return 'Invalid Indian mobile number format';
            // } else if (country === '1' && !/^\d{10}$/.test(normalizedValue)) {
            //   return 'Invalid USA mobile number format';
            // } else if (country === '44' && !/^(?:\+44|0)7\d{9}$/.test(normalizedValue)) {
            //   return 'Invalid UK mobile number format';
            // }
          }
          return ""; // No error found, valid number  
      case "status":
        if (!value) return "Status is required";
        return "";
       
      case "timeZone":
        if (!value) return "Time zone is required";
        return "";
       
      case "department":
        if (!value) return "Department is required";
        return "";
       
      case "userId":
        if (!value) return "Client Code is required";
        return "";
       
      case "pointofContact":
        if (!value) return "Point of Contact is required";
        return "";
 
      default:
        return "";
    }
  };
 
  const { countryCode, mobileNo } = formData;
  const country = countryCode?.slice(1);
 
  const options = useSelector((state) => state.dataBucket.databucket);
 console.log("yfyyyf",options)
  const handleSubmit = async (e) => {
    setIsButtonDisabled(true);
    e.preventDefault();
    console.log("asdascsac",formData)
    const newErrors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) {
        newErrors[key] = error;
      }
    });
 
    setErrors(newErrors);
 
    if (Object.keys(newErrors).length === 0) {
      setIsUserDataValid(true);
      try {
        const { data } = await addClientMutation({
          variables: {
            clientName: formData?.clientName,
            mobileNo: `${formData?.countryCode.replace(/\s/g, '')}${formData?.mobileNo.replace(/[\s()\-]/g, '')}`,
            emailId: formData?.emailId.toLowerCase(),
            status: formData?.status,
            timeZone: formData?.timeZone,
            department: formData?.department,
            userId: formData?.userId,
            extension:formData?.extension,

          },
        });
        // const newClient = data?.createclient;
        // const addClient = [newClient,...clien]
        setIsUserDataValid(false);
        setTimeout(() => {
          setIsButtonDisabled(false);
        }, 3000);
 
        if (data?.createclient.status === "100") {
          message.error(data?.createclient?.message);
        } else {
          message.success(data?.createclient?.message);
 
          dispatch(addClient({
            id: data?.createclient?.id,
            clientName: formData?.clientName,
            mobileNo: formData?.mobileNo,
            emailId: formData?.emailId.toLowerCase(),
            status: formData?.status,
            timeZone: formData?.timeZone,
            department: formData?.department,
            userId: formData?.userId,
          }));
          setIsUserDataValid(true);
        }
        const modalElement = document.querySelector('[data-bs-dismiss="modal"]');
        modalElement && modalElement.click();
        formRef.current.reset();
      } catch (error) {
        logError("AddClientModal", "submit-handling", error.message);
        message.error("Client already exists with the details");
        setIsUserDataValid(true);
        const modalElement = document.querySelector('[data-bs-dismiss="modal"]');
        modalElement && modalElement.click();
        formRef?.current?.reset();
      }
    }
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 3000);
  };
 
  return (
    <div id="add_client" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Client</h5>
            <button type="button" className="btn-close custom-close-btn" data-bs-dismiss="modal" aria-label="Close" onClick={handleClose}>x</button>
          </div>
          <div className="modal-body">
            <form ref={formRef} onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Client Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    name="clientName"
                    className={`form-control ${errors.clientName ? 'is-invalid' : ''}`}
                    value={formData.clientName}
                    onChange={handleChange}
                  />
                  {errors.clientName && <small className="text-danger">{errors.clientName}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    name="emailId"
                    className={`form-control ${errors.emailId ? 'is-invalid' : ''}`}
                    type="email"
                    value={formData.emailId}
                    onChange={handleChange}
                  />
                  {errors.emailId && <small className="text-danger">{errors.emailId}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Mobile Number <span className="text-danger">*</span>
                  </label>
                  <div className="input-group">
                    <select
                      name="countryCode"
                      // className="form-control"
                      value={formData.countryCode}
                      onChange={handleChange}
                    >
                      <option value="+1">+1 (USA)</option>
                      <option value="+91">+91 (India)</option>
                      <option value="+44">+44 (UK)</option>
                    </select>
 
                    {/* Mobile Number Input */}
                    <InputMask
                    mask={
                      // country === '1' ? '999-999-9999' : // USA (XXX-XXX-XXXX)
                      country === '1' ? ['999-999-9999', '(999) 999-9999'] :
                      country === '44' ? '99999 999999' : // UK (XXXXX XXXXXX)
                      '9999999999' // India (XXXXXXXXXX - No dashes)
                    }
                    maskChar={null} // Prevents placeholder characters
                    alwaysShowMask={false}
                    // mask={masks[country]}
                      name="mobileNo"
                      className={`form-control ${errors.mobileNo ? 'is-invalid' : ''}`}
                      type="text"
                      value={formData.mobileNo}
                      onChange={handleChange}
                      placeholder="Enter mobile number"
                    />
                     <input
                      type="text"
                      name="extension"
                      className={`form-control ${errors.extension ? 'is-invalid' : ''}`}
                      placeholder="Extension"
                      value={formData.extension}
                      onChange={handleChange}
                      // Assuming extension number is short, you can adjust the maxLength if needed
                    />
                  </div>
                  {errors.mobileNo && <small className="text-danger">{errors.mobileNo}</small>}
                </div>
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Status <span className="text-danger">*</span>
                  </label>
                  <select
                    name="status"
                    className={`form-control ${errors.status ? 'is-invalid' : ''}`}
                    value={formData.status}
                    onChange={handleChange}
                  >
                    <option value="">Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Inactive">Inactive</option>
                  </select>
                  {errors.status && <small className="text-danger">{errors.status}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Time Zone <span className="text-danger">*</span>
                  </label>
                  <div className="dropdown">
                    <select
                      name="timeZone"
                      className={`form-control ${errors.timeZone ? 'is-invalid' : ''}`}
                      value={formData.timeZone}
                      onChange={handleChange}
                    >
                      <option value="">Select Time Zone</option>
                      <option value="Eastern Time">Eastern Time</option>
                      <option value="Central Time">Central Time</option>
                      <option value="Mountain Time">Mountain Time</option>
                      <option value="Pacific Time">Pacific Time</option>
                      <option value="Hawaii Time">Hawaii Time</option>
                    </select>
                    <i className="dropdown-icon"></i>
                  </div>
                  {errors.timeZone && <small className="text-danger">{errors.timeZone}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">
                    Department <span className="text-danger">*</span>
                  </label>
                  <div className="dropdown">
                    <i className="dropdown-icon"></i>
                    <select
                      name="department"
                      className={`form-control ${errors.department ? 'is-invalid' : ''}`}
                      value={formData.department}
                      onChange={handleChange}
                    >
                      {renderOptions(options, { name: "DEPARTMENT_CONFIG_CLIENT" }, DEPARTMENT_CONFIG_CLIENT)}
                    </select>
                    <i className="dropdown-icon"></i>
                  </div>
                  {errors.department && <small className="text-danger">{errors.department}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">Client Code</label>
                  <input
                    name="userId"
                    className={`form-control ${errors.userId ? 'is-invalid' : ''}`}
                    value={formData.userId}
                    onChange={handleChange}
                  />
                  {errors.userId && <small className="text-danger">{errors.userId}</small>}
                </div>
 
                <div className="col-sm-6">
                  <label className="col-form-label">Point of Contact</label>
                  <input
                    name="pointofContact"
                    className={`form-control ${errors.pointofContact ? 'is-invalid' : ''}`}
                    value={formData.pointofContact}
                    onChange={handleChange}
                  />
                  {errors.pointofContact && <small className="text-danger">{errors.pointofContact}</small>}
                </div>
 
              </div>
              <div className="submit-section">
                <button className="btn btn-primary submit-btn" type="submit" disabled={isButtonDisabled}>Add</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};