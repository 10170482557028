import React, { useState, memo, useEffect } from 'react';
import { Row, Col, Button, Divider, Form, Input, Select, TimePicker, DatePicker, Typography, Stack, FormLabel } from 'antd';
import CommentField from './CommentField';
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import { Formik, FieldArray, Field, useFormikContext } from 'formik';
import { useDispatch } from "react-redux";
import { addLastContactedDate, removeLastContactedDate, setLastContactedDates } from "../../../Slices/lastContactedDatesSlice";
import { setIsRefetch } from '../../../Slices/salesSlice';
import * as DataBucket from '../../../databucket';
import Swal from 'sweetalert2';
import { renderOptions } from '../../../utilsdata';
const { Option } = Select;
const { TextArea } = Input;

const SchedulingCall = memo((props) => {
  const dispatch = useDispatch();
  const { SalesInfo, assignToUsers, getBy, setFieldValue } = props;
  const { values, handleChange, handleBlur, errors, touched } = useFormikContext();
  const options = useSelector((state) => state.dataBucket.databucket);

  const [emails, setEmails] = useState(values.leadsScheduledCalls?.map(call => call.emails) || []);
  const [phonenumbers, setPhonenumbers] = useState(values.leadsScheduledCalls?.map(call => call.phonenumber) || []);
  const [remarks, setRemarks] = useState(values.leadsScheduledCalls?.map(call => call.remarks) || []);

  const isRefetch = useSelector((state) => state.sales.isRefetch);

  useEffect(() => {
    if (isRefetch) {
      setEmails([]);
      setPhonenumbers([]);
      setRemarks([]);
    }
    dispatch(setIsRefetch(false))
  }, [isRefetch])

  const [localErrors, setLocalErrors] = useState([]);
  const handleAddRow = (arrayHelpers) => {

    const newDate = dayjs(new Date());
    setFieldValue("lastContactedDate", newDate);
    arrayHelpers.push({
      date: newDate || "",
      time: dayjs().set('second', 0).set('millisecond', 0) || '',
      callType: '',
      emails: '',
      phonenumber: '',
      callScheduledBy: getBy(null),
      timeZone: null,
      remarks: '',
    });

    // Add the new date to Redux state
    dispatch(addLastContactedDate(newDate));
  };

  const lastContactedDates = useSelector(state => state.lastContactedDates);
  console.log("lastDates", lastContactedDates)

  const handleDeleteRow = (index, arrayHelpers) => {

    Swal.fire({
      title: 'Are you sure?',
      text: 'Do you really want to delete this Scheduling record?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        arrayHelpers.remove(index); // Remove the call record
        setEmails((prev) => prev.filter((_, i) => i !== index));
        setPhonenumbers((prev) => prev.filter((_, i) => i !== index));
        setRemarks((prev) => prev.filter((_, i) => i !== index));

        // Remove the corresponding local errors
        setLocalErrors((prev) => prev.filter((_, i) => i !== index));

        // Get the date of the row being deleted from `leadsCalls`
        const deletedRowDate = arrayHelpers.form.values.leadsScheduledCalls[index].date;

        const lastContactedDatess = lastContactedDates.filter(date => dayjs(date).valueOf() !== dayjs(deletedRowDate).valueOf());

        const newDate = dayjs(new Date());
        const latestDate = lastContactedDatess.length > 0
          ? Math.max(...lastContactedDatess.map(date => dayjs(date).valueOf()))
          : null;

        dispatch(setLastContactedDates(lastContactedDatess));

        if (latestDate) {
          setFieldValue("lastContactedDate", dayjs(latestDate));
        }
      }
    });

  };
  const getTime = (time) => {
    let formatedTime = ''
    if (time != undefined && time != '') {
      if (typeof time === 'object') {
        formatedTime = time

      } else if (typeof time === 'string') {
        if (time?.includes('T')) {
          let tempdatetime = time?.split('T')
          let temptime = tempdatetime[1]?.split('.')
          time = temptime[0]
        }
        let timeArray = time?.split(':')
        formatedTime = dayjs().set('hour', timeArray[0]).set('minute', timeArray[1]).set('second', timeArray[2])
      }
    } // Set the desired time
    return formatedTime;
  }
  const getDateValue = (datevalue) => {
    let finalDate = null;
    if (datevalue != undefined || datevalue != '') {
      if (typeof datevalue === 'string' && datevalue?.length == 13) {
        finalDate = dayjs(parseInt(datevalue))
      } else if (typeof datevalue === 'object') {
        finalDate = datevalue
      }
    }
    return finalDate;
  }

  const getTimeZoneFinal = (timezonecode) => {
    let finalTimeZone = timezonecode;
    DataBucket.SALES_TIMEZONES?.map((SALES_TIMEZONE) => {
      if (SALES_TIMEZONE?.toLowerCase() == timezonecode?.toLowerCase()) {
        finalTimeZone = SALES_TIMEZONE;
      }
    })
    return finalTimeZone;
  }
  const getTimeZone = (timezonecode) => {
    if (timezonecode?.length == 3) {
      switch (timezonecode) {
        case 'EST':
          return "Eastern Time";
        case 'CST':
          return "Central Time";
        case 'MST':
          return "Mountain Time";
        case 'PST':
          return "Pacific Time";
        case 'HST':
          return "Hawaii Time";
      }
    } else {
      return getTimeZoneFinal(timezonecode);
    }
  }

  // Local Validation for Email (onChange)
  const handleEmailChange = (e, index) => {
    const email = e.target.value.trim();
    const newEmails = [...emails];
    newEmails[index] = email;

    let errorMessage = "";

    // Updated email validation (only for '.com' domain)
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z0-9]{2,}$/;
    if (email && !emailRegex.test(email.trim())) {
      errorMessage = "Invalid email format";
    }

    // Remove phone error if email is provided
    const newErrors = [...localErrors];
    newErrors[index] = {
      ...newErrors[index],
      emails: errorMessage,
      phonenumber: email ? "" : newErrors[index]?.phonenumber, // Clear phone error if email is valid
    };

    setEmails(newEmails);
    setLocalErrors(newErrors);
  };

  // Local Validation for Phone Number (onChange)
  const handlePhoneNumberChange = (e, index) => {
    // let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
    // if (value.length <= 10) {
    const newPhonenumbers = [...phonenumbers];
    newPhonenumbers[index] = e.target.value;

    // // Local validation for phone number
    // let errorMessage = "";
    // if (value && value.length > 10) {
    //   errorMessage = "Phone number must be exactly 10 digits";
    // } else if (value && !/^[1-9][0-9]{9}$/.test(value)) {
    //   errorMessage = "Phone number cannot start with 0";
    // }

    // // Remove email error if phone number is provided
    // const newErrors = [...localErrors];
    // newErrors[index] = {
    //   ...newErrors[index],
    //   phonenumber: errorMessage,
    //   emails: value ? "" : newErrors[index]?.emails, // Clear email error if phone is valid
    // };

    setPhonenumbers(newPhonenumbers);
    //   setLocalErrors(newErrors);
    // }
  };

  return (
    <div style={{ marginTop: '15px', backgroundColor: "#fff", padding: '20px', boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '8px' }}>
      <Typography.Title level={4} >Schedule Track</Typography.Title>


      <FieldArray
        name="leadsScheduledCalls"
        render={(arrayHelpers) => (
          <>
            {values?.leadsScheduledCalls?.map((ScheduledCallInfo, index) => (
              <div key={index} style={{ marginBottom: '1rem' }}>
                <Row gutter={16}>
                  <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
                    <Typography.Text strong>Call Schedule No. {index + 1}</Typography.Text>
                    <i
                      className="fa-regular fa-trash-can"
                      style={{
                        color: '#00c5fb',
                        cursor: 'pointer',
                        fontSize: '16px',
                      }}
                      onClick={() => handleDeleteRow(index, arrayHelpers)}
                    />
                  </Col>


                  {/* Date Field */}
                  <Col span={6}>
                    <Form.Item
                      name={`leadsScheduledCalls.${index}.date`}
                      label="Date"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsScheduledCalls.${index}.date`}>
                        {({ field }) => (
                          <DatePicker
                            allowClear={false}
                            inputReadOnly={true}
                            format="DD/MM/YYYY"
                            name={`leadsScheduledCalls.${index}.date`}
                            onChange={(date) =>
                              field.onChange({ target: { value: date, name: field.name } })
                            }
                            value={getDateValue(field?.value)}
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Time Field */}
                  <Col span={6}>
                    <Form.Item
                      name={`leadsScheduledCalls.${index}.time`}
                      label="Time"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <Field name={`leadsScheduledCalls.${index}.time`}>
                        {({ field }) => (
                          <TimePicker
                            inputReadOnly={true}
                            allowClear={false}
                            name={`leadsScheduledCalls.${index}.time`}
                            onChange={(time) =>
                              field.onChange({ target: { value: time, name: field.name } })
                            }
                            value={
                              (field.value && getTime(field.value)) ||
                              dayjs().set('second', 0).set('millisecond', 0)
                            }
                            format="h:mm A"
                            style={{ width: '100%' }}
                          />
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* Type of Call */}
                  <Col span={6}>
                    <Form.Item
                      label="Type of Call"
                      labelCol={{ span: 24 }} // Ensure label spans the full width
                      wrapperCol={{ span: 24 }} // Ensure the field spans the full width
                    >
                      <Field name={`leadsScheduledCalls.${index}.callType`}>
                        {({ field, form }) => (
                          <Select
                            value={field.value || 'Phone Call'} // Default value fallback
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state
                            style={{ width: '100%' }}
                          >
                            {renderOptions(options, { name: "SALES_SCHEDULED_CALL_TYPE" }, DataBucket.SALES_SCHEDULED_CALL_TYPE)}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>


                  {/* Emails */}
                  <Col span={6}>
                    <Form.Item
                      label={
                        <span>
                          Emails
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      help={(localErrors[index]?.emails || touched?.leadsScheduledCalls?.[index]?.emails && errors?.leadsScheduledCalls?.[index]?.emails)}
                      validateStatus={localErrors[index]?.emails || (touched?.leadsScheduledCalls?.[index]?.emails && errors?.leadsScheduledCalls?.[index]?.emails) ? 'error' : ''}
                    >
                      <Input
                        name={`leadsScheduledCalls.${index}.emails`}
                        value={emails[index] || ''}
                        // onChange={(e) => {
                        //   const newEmails = [...emails];
                        //   newEmails[index] = e.target.value;
                        //   setEmails(newEmails);
                        // }}
                        onChange={(e) => {
                          handleEmailChange(e, index); // Local validation for email
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsScheduledCalls[${index}].emails`,
                              value: e.target.value.trim(),
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/* Phone Number */}
                  <Col span={6}>
                    <Form.Item
                      label={
                        <span>
                          Phone Number
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </span>
                      }
                      // required
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                      // help={touched?.leadsScheduledCalls?.[index]?.phonenumber && errors?.leadsScheduledCalls?.[index]?.phonenumber}
                      // validateStatus={touched?.leadsScheduledCalls?.[index]?.phonenumber && errors?.leadsScheduledCalls?.[index]?.phonenumber ? 'error' : ''}
                      help={localErrors[index]?.phonenumber || (touched?.leadsScheduledCalls?.[index]?.phonenumber && errors?.leadsScheduledCalls?.[index]?.phonenumber)}
                      validateStatus={localErrors[index]?.phonenumber || (touched?.leadsScheduledCalls?.[index]?.phonenumber && errors?.leadsScheduledCalls?.[index]?.phonenumber) ? 'error' : ''}
                    >
                      <Input
                        name={`leadsScheduledCalls.${index}.phonenumber`}
                        value={phonenumbers[index] || ''}
                        // onChange={(e) => {
                        //   let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                        //   if (value.length <= 10) {
                        //     const newPhonenumbers = [...phonenumbers];
                        //     newPhonenumbers[index] = value;
                        //     setPhonenumbers(newPhonenumbers);
                        //   }
                        // }}
                        onChange={(e) => {
                          handlePhoneNumberChange(e, index); // Local validation for phone number
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsScheduledCalls[${index}].phonenumber`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>

                  {/* Call Scheduled by */}
                  <Col span={6}>
                    <Form.Item
                      label="Call Scheduled by"
                      labelCol={{ span: 24 }} // Ensure label spans the full width
                      wrapperCol={{ span: 24 }} // Ensure the field spans the full width
                    >
                      <Field name={`leadsScheduledCalls.${index}.callScheduledBy`}>
                        {({ field, form }) => (
                          <Select
                            showSearch
                            value={field.value || getBy(ScheduledCallInfo?.callScheduledBy)} // Default value handling
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state
                            style={{ width: '100%' }}
                          >
                            {assignToUsers?.map((user, key) => (
                              <Select.Option key={key} value={`${user?.firstname} ${user?.lastname}`}>
                                {`${user?.firstname} ${user?.lastname}`}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>

                  {/* TimeZone */}
                  <Col span={6}>
                    <Form.Item
                      label="TimeZone"
                      labelCol={{ span: 24 }} // Label takes full width
                      wrapperCol={{ span: 24 }} // Field takes full width
                    >
                      <Field name={`leadsScheduledCalls.${index}.timeZone`}>
                        {({ field, form }) => (
                          <Select
                            value={field.value || getTimeZone(ScheduledCallInfo?.timeZone)} // Default value handling
                            onChange={(value) => form.setFieldValue(field.name, value)} // Update Formik state on change
                            style={{ width: '100%' }}
                          >
                            {DataBucket.SALES_TIMEZONES?.map((Item, key) => (
                              <Select.Option key={key} value={Item}>
                                {Item}
                              </Select.Option>
                            ))}
                          </Select>
                        )}
                      </Field>
                    </Form.Item>
                  </Col>


                  {/* Remarks */}
                  <Col span={24}>
                    <Form.Item
                      label="Remarks"
                      labelCol={{ span: 24 }}
                      wrapperCol={{ span: 24 }}
                    >
                      <TextArea
                        name={`leadsScheduledCalls.${index}.remarks`}
                        value={remarks[index] || ''}
                        onChange={(e) => {
                          const newRemarks = [...remarks];
                          newRemarks[index] = e.target.value;
                          setRemarks(newRemarks);
                        }}
                        onBlur={(e) => {
                          handleChange({
                            target: {
                              name: `leadsScheduledCalls[${index}].remarks`,
                              value: e.target.value,
                            },
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>


                {/* Divider */}
                <Divider style={{ backgroundColor: '#d9d9d9', margin: '16px 0' }} />
              </div>
            ))}

            <Row justify="end" style={{ marginTop: '16px' }}>
              <Button
                type="primary"
                style={{ marginTop: '10px' }}
                onClick={() => handleAddRow(arrayHelpers)}
              >
                Add Row
              </Button>
            </Row>
          </>
        )}
      />
      <CommentField label="Comments" name="scheduledCallComments" />
    </div>
  );
});

export default SchedulingCall;

