import React, { useState, useEffect } from "react";
import { Input, Form } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { setleadfilterObjectt, } from "../../../Slices/salesSlice";
import { setRefreshStatus } from "../../../Slices/salesSlice";

const SearchInput = () => {
    const dispatch = useDispatch();
    const [filterSearch, setFilterSearch] = useState('');
    const existingFilterObject = useSelector((state) => state.sales.leadfilterObject);
    const refreshStatus = useSelector((state) => state.sales.refreshStatus);
    console.log('existingFilterObject', existingFilterObject, refreshStatus)
    const handleSearchChange = (event) => {
        let searchText = event.target.value
        setFilterSearch(searchText)

    }

    useEffect(() => {
        if (refreshStatus) {
            setFilterSearch(existingFilterObject?.search)
        }
    }, [refreshStatus])

    useEffect(() => {
        let salesFilter = sessionStorage.getItem('salesFilter')
        if (salesFilter) {
            salesFilter = JSON.parse(salesFilter)
            dispatch(setleadfilterObjectt(salesFilter))
            if (salesFilter?.search != undefined && salesFilter?.search != '') {
                setFilterSearch(salesFilter?.search)
            }
        }
    }, []);

    const handleClear = () => {
        setFilterSearch("") // Clear the search input field and reset state
        dispatch(setleadfilterObjectt({ ...existingFilterObject, search: "" }));
    };

    const handleSearchClick = () => {
        if (!filterSearch.trim()) {
            alert("Please enter a value before searching."); // Show error message
            return; // Stop execution if the input is empty
        }
        dispatch(setRefreshStatus(null))
        dispatch(setleadfilterObjectt({ ...existingFilterObject, search: filterSearch }));
    }
    return (
        <Form.Item>
            <Input.Search
                placeholder="Search"
                value={filterSearch}
                onSearch={handleSearchClick}
                onChange={(e) => setFilterSearch(e.target.value)}
                onBlur={(e) => handleSearchChange(e)}
                allowClear
                onClear={handleClear}
                style={{ width: 200 }}
                enterButton={<i className="fa fa-search" />}
            />
        </Form.Item>
    );
};

export default SearchInput;
