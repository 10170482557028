import { gql } from '@apollo/client';

export const GET_ADMIN_LEAVES = gql`
query getLeaveRequests(
  $employeeId: String,
  $leaveType: String,
  $leaveStatus: String,
  $fromDate: String,
  $toDate: String
) {
  getLeaveRequests(
    employeeId: $employeeId
    leaveType: $leaveType
    leaveStatus: $leaveStatus
    fromDate: $fromDate
    toDate: $toDate
  ) {
    _id
    employeeId
    leaveType
    leaveDays
    startDate
    endDate
    leaveStatus
    leaveReason
    managerApproval
    status
    leaveRemaining
    createdAt
    updatedAt
    session1
    session2
    remarks
  }
}
`
export const UPDATE_LEAVE_STATUS = gql(`
    mutation updateLeaveStatus($leaveUpdates: [LeaveUpdateInput!]!) {
      updateLeaveStatus(leaveUpdates: $leaveUpdates) {
       _id
      leaveStatus
      remarks
      updatedAt
      status
      }
    }`
);

export const UPDATE_LEAVES = gql(`
  mutation($input:LeavesDataBucketInputType){
   createLeaves(input:$input){
     status
     message
   }
  }
  
  `)

export const GET_LEAVES_DATA = gql(`
   query{
GetAllLeaves {
 message
  status
  response{
    year
    department
    medicalLeave
    carryFlag
    CarryforwardLeaves
    AnnualLeaves
  }
}
}
  `)
