import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { avatar1, } from "../../../Routes/ImagePath"; // Assuming avatar1 is the default image path
import { useQuery } from "@apollo/client";
import Input from "antd/es/input/Input";
import { GET_EMPLOYEE_DETAILS } from "../../../Queries/Employees";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import { SetAllEmployees, setId, setAllNote } from "../../../Slices/employeeslice";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setLoader } from "../../../Slices/loaderSlice";
const AllEmployee = () => {
  const [id, setIds] = useState();
  const [load,setLoad]=useState(true);
  const dispatch = useDispatch();
  dispatch((setId(true)))
  const [empdata, setempdata] = useState(true);
  let details = useSelector((state) => state.employee.AllEmployees)
  const [filteredEmployees, setFilteredEmployees] = useState();
  console.log("efewfwff",details)
  useEffect(() => {
      dispatch(setLoader(details.length > 0 ? false : true));
      setFilteredEmployees(details);
    }, [details]);
  const employeeData = filteredEmployees?.map((employee) => ({
    id: employee?.EmpID,
    _id: employee?.id,
    firstname: employee?.firstname, // Make sure to match these keys with the GraphQL response
    lastname: employee?.lastname, // firstname and lastname instead of firstName, lastName
    designation: employee?.designation,
    department: employee?.department,
    image: employee?.image,
    employeestatus: employee?.employeestatus
  })) || []; 
  console.log("employeeData",employeeData)// Fallback to an empty array if data is undefined
  function getInitials(name) {
    if (!name) return 'N/A'; // Handle case where name is empty or undefined
    const names = name.split(' ');  // Split by spaces to separate first and second names.
    // If there's only one word, return the first letter of the first word
    if (names.length === 1) {
      return names[0].charAt(0).toUpperCase();// First letter of the first word
    }
    // If there are two or more words, return the first letter of the first word and the second letter of the second word.
    const firstInitial = names[0].charAt(0).toUpperCase();  // First letter of the first word
    const secondInitial = names[1] ? names[1].charAt(0).toUpperCase() : '';  // Second letter of the second word (if exists)
    return firstInitial + secondInitial;  // Combine the initials
  }
  const handleChange = (e) => {
    const val = e.target.value; // Getting the input value
    // If the input value is empty, set the filtered data to the original details
    if (!val) {
      setFilteredEmployees(details); // Reset to all employees
      return;
    }
    // Filter the employees based on firstName or lastName
    const filteredData = details.filter((item) =>
      item.firstname?.toLowerCase()?.includes(val?.toLowerCase()) || // Check firstName
      item.lastname?.toLowerCase()?.includes(val?.toLowerCase()) // Check lastName
    );
    // Update the filtered employees state
    setFilteredEmployees(filteredData);
   
  };
  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Breadcrumbs */}
          <Breadcrumbs
            maintitle="Employee"
            title="Dashboard"
            subtitle="Employee"
            modal="#add_employee"
            name="Add Employee"
            Linkname="/employees"
            Linkname1="/employees-list"
            view="grid"
          />
          <Input
            type="text"
            placeholder="Search By Name..."
            onChange={handleChange}
            style={{
              marginLeft: '20px', // Adjust the gap between the Breadcrumbs and Input
              padding: '5px',
              marginBottom: "20px", // Adjust padding
              width: '200px', // Set the width of the input
              border: '1px solid #d9d9d9', // Optional: border color
              outline: 'none', // Remove the outline
              borderRadius: '4px', // Optional: rounded corners
            }}
          />
          {/* Employee List Filter */}
          {/* Display Employee Cards */}
          <div className="row">
            {employeeData?.length > 0 ? (
              employeeData?.map((employee) => {
                return (
                  <Link to={`/employees/${employee?._id}`} className="col-md-4 col-sm-6 col-12 col-lg-3 col-xl-3 h-300px" key={employee?._id}>
                    <div className="profile-widget" style={{ minHeight: "200px" }}>
                      {/* Profile Image */}
                      <div
                        className="profile-img"
                        onClick={() => {
                          // Optional: Add any specific logic here
                        }}
                      >
                        {employee?.image ? (
                           <>
                            <img
                              src={employee?.image}
                              alt="User"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                width: "70px",
                                height: "70px",
                                borderRadius: "50%",
                                backgroundColor: "#ccc",
                                color: "#333",
                                fontSize: "20px",
                                position: "relative",
                                border: "2px solid #ddd", // Optional border for styling
                              }}
                            />
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: employee?.employeestatus != "InActive" ? 'green' : 'red',
                                position: 'absolute',
                                bottom: '8px',
                                right: '12px',
                                marginRight: "5px",
                                border: '2px solid white',
                              }}
                            ></div>
                          </>
                        ) : (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              width: "70px",
                              height: "70px",
                              borderRadius: "50%",
                              backgroundColor: "rgb(137,132,132)",
                              color: "white",
                              fontSize: "20px",
                            }}
                            >
                            <div
                              style={{
                                width: '12px',
                                height: '12px',
                                borderRadius: '50%',
                                backgroundColor: employee?.employeestatus != "InActive" ? 'green' : 'red',
                                position: 'absolute',
                                bottom: '8px',
                                right: '12px',
                                marginRight: "5px",
                                border: '2px solid white',
                              }}
                            ></div>
                            {/* Generate initials or display 'N/A' */}
                            {employee?.firstname ? getInitials(employee.firstname) : "N/A"}
                          </div>
                        )}
                      </div>
                      {/* Name */}
                      <div className="small text-muted">
                        {employee?.department || "N/A"}
                      </div>
                      <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                        <div>
                          {employee?.firstname
                            ? employee.firstname.length > 15
                              ? `${employee.firstname.slice(0, 15)}...`
                              : employee.firstname
                            : "N/A"}{" "}
                          {employee?.lastname || "N/A"}
                        </div>
                      </h4>
                    </div>
                  </Link>
                );
              })
            ) : (
              <p style={{textAlign:"center", margin:"auto"}}>No employees found</p> 
            )}
          </div>
        </div>
      </div>
      {/* Add Employee Popup */}
      <AllEmployeeAddPopup id={id} />
      {/* Delete Modal */}
      <DeleteModal Name="Delete Employee" />
    </div>
  );
};

export default AllEmployee;
