import React, { useEffect, useState } from "react";
import { Checkbox, Button, Collapse, Select, Spin, Alert } from "antd";
import { useSelector, useDispatch } from "react-redux";
import { SIDEMENU } from "../../databucket";
import { DAYNAMIC_ROUTES, GET_DYNAMIC_ROUTES } from "../../Queries/options";
import { useMutation, useQuery } from "@apollo/client";
import { setLoader } from "../../Slices/loaderSlice";
import { setempRoute } from "../../Slices/DyanamicRoute";
import { updateSidebarData } from "./updateSidebarData";
import { message } from "antd";
const { Panel } = Collapse;
const { Option } = Select;

const ConditionMenuRoute = () => {
  const [submitState, setSubmitState] = useState(false);
  const [department, setDepartment] = useState(null);
  const [name, setName] = useState(null);
  const [sidemenu, setSidemenu] = useState(SIDEMENU);
  const [openDepartment, setOpenDepartment] = useState(false);
  const [SideMENU] = useMutation(DAYNAMIC_ROUTES);
  const dispatch = useDispatch();

  const { data, loading, error, refetch } = useQuery(GET_DYNAMIC_ROUTES, {
    onCompleted: (data) => {
    },
    onError: (error) => {
  
    },
  });

  const initialSidemenu = useSelector((state) => state.Route.route);
  const handleDepartmentChange = (value) => {
    setDepartment(value);
    setOpenDepartment(false); // Close dropdown after selection
  };

  const handleNameChange = (value) => {
    setName(value);
  };

  useEffect(() => {
    if (data && data.getSideMenu) {
      dispatch(setempRoute(data?.getSideMenu));
      const foundItem = data.getSideMenu.find(
        (item) => item.name === name && item.department === department
      );
      if (foundItem) {
        const mergedData = updateSidebarData(SIDEMENU, foundItem.fields);
        setSidemenu(mergedData);
        dispatch(setempRoute(mergedData));
      
      } else {
        setSidemenu(SIDEMENU);
    
      }
    }
  }, [name, department, data]);

  const handleSubmit = async () => {
    setSubmitState(true);

    const formattedData = {
      name: name,
      department: department,
      fields: sidemenu.map((menu) => ({
        name: menu.name,
        status: menu.status,
        Subfields: menu.Subfields?.map((sub) => ({
          displayName:sub.displayName,
          name: sub.name,
          status: sub.status,
        })) || [],
      })),
    };

 

    try {
      dispatch(setLoader(true))
      const { data } = await SideMENU({ variables: { input: formattedData } });
     
      if (data?.SideMENU?.status) {
        dispatch(setLoader(false))
        message.success({
          content: data?.SideMENU?.message,
          duration: 5, // Toast will disappear after 5 seconds
          className: 'custom-toast-center', // Custom class to center the toast
        });
        await refetch();
      }
     
    } catch (error) {
      dispatch(setLoader(false))
      message.error({
        content: error,
        duration: 5, // Toast will disappear after 5 seconds
        className: 'custom-toast-center', // Custom class to center the toast
      });
    
    } finally {
      setSubmitState(false)
      dispatch(setLoader(false))
      setSubmitState(false);
    }

    setSubmitState(false);
  };

  const handleParentCheckboxChange = (index) => {
    setSidemenu((prevMenu) =>
      prevMenu.map((menu, i) =>
        i === index
          ? {
            ...menu,
            status: !menu.status,
            Subfields: menu.Subfields.map((subfield) => ({
              ...subfield,
              status: !menu.status,
            })),
          }
          : menu
      )
    );
  };

  const handleSubfieldCheckboxChange = (parentIndex, subIndex) => {
    setSidemenu((prevMenu) =>
      prevMenu.map((menu, i) =>
        i === parentIndex
          ? (() => {
            // Toggle subfield status first
            const updatedSubfields = menu.Subfields.map((subfield, j) =>
              j === subIndex ? { ...subfield, status: !subfield.status } : subfield
            );

            // Check if all subfields are now false
            const allSubfieldsFalse = updatedSubfields.every(sub => !sub.status);

            return {
              ...menu,
              Subfields: updatedSubfields,
              status: allSubfieldsFalse ? false : menu.status, // If all false, uncheck main route
            };
          })()
          : menu
      )
    );
  };


  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <div id="document_manager" style={{ display: "flex", flexDirection: "column", flex: "1 1 auto" }}>
          <div
            className="card-body"
            style={{
              margin: "0 auto",
              padding: "20px",
              background: "#fff",
              borderRadius: "8px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
              maxWidth: "100%",
              width: "90%",
            }}
          >
            <h2 style={{ marginBottom: "20px", textAlign: "center", font: "bold" }}>Manage Routes and Sidemenu</h2>

            <div style={{ padding: "20px", background: "#fff", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", borderRadius: "8px" }}>
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <div style={{ marginBottom: "15px" }}>
                  <label style={{ marginRight: "10px" }}>Select Department:</label>
                  <Select
                    value={department}
                    open={openDepartment}
                    onDropdownVisibleChange={(open) => setOpenDepartment(open)}
                    style={{ width: 200 }}
                    onChange={handleDepartmentChange}
                  >
                    <Option value="Sales">Sales</Option>
                    <Option value="Operations">Operations</Option>
                    <Option value="Both">Both</Option>
                  </Select>
                </div>

                <div style={{ marginBottom: "15px" }}>
                  <label style={{ marginRight: "10px" }}>Select Role:</label>
                  <Select
                    style={{ width: 200 }}
                    onChange={handleNameChange}
                  >
                    <Option value="User">User</Option>
                    <Option value="Admin">Admin</Option>
                    <Option value="Super Admin">Super Admin</Option>
                  </Select>
                </div>
              </div>

              <Collapse>
                {sidemenu?.map((menu, index) => (
                  <Panel
                    key={index}
                    header={
                      <Checkbox
                        checked={menu.status}
                        onChange={() => handleParentCheckboxChange(index)}
                        style={{ fontFamily: "serif", fontSize: "16px" }}
                      >
                        {menu.name}
                      </Checkbox>
                    }
                  >
                    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                      {menu.Subfields &&
                        menu.Subfields.map((sub, subIndex) => (
                          <div
                            key={subIndex}
                            style={{
                              maxWidth: "200px",
                              padding: "5px",
                              display: "flex",
                              alignItems: "center",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
                            }}
                          >
                            <Checkbox
                              checked={sub.status}
                              disabled={!menu.status}
                              onChange={() => handleSubfieldCheckboxChange(index, subIndex)}
                            >
                              {sub.displayName}
                            </Checkbox>
                          </div>
                        ))}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </div>

            <Button
              type="primary"
              onClick={handleSubmit}
              style={{
                marginTop: "20px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              disabled={submitState}
            >
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConditionMenuRoute;
